import React, { useState, useEffect } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Image, Avatar, Tooltip, Pagination } from 'antd'
import type { PaginationProps } from 'antd'
import { Row, Col } from 'antd'
import { Menu, Dropdown, Button, Space } from 'antd'
import { Card } from 'antd'
import { useHistory } from 'react-router'
import moment from 'moment'
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons'
import CustomCalender from '../../../../helper/Calendar/Eventscalendar/CustomCalender'
import Actionicon from '../../../assets/Images/Action.svg'
import deleteIcon from '../../../assets/Images/delete_red.svg'
import PencilButtonIcon from '../../../assets/Images/PencilButtonIcon.svg'
import pinNewIcon from '../../../assets/Images/pinNewIcon.svg'
import EventCard from '../evetnsCards/EventCard'

import {
  DeleteOutlined,
  PushpinOutlined,
  EditOutlined,
} from '@ant-design/icons'
import eventsAction from '../../../../Redux/Events/action'
import timeSvg from '../../../assets/Images/time.svg'
import { setPaginationObject } from '../../../../helper/utility'
import actions from '../../../../Redux/Events/action'

const CardViewOrg = ({
  data,
  setData,
  events,
  mentorEvents,
  totalCount,
  categories,
  categorySort,
  statusSort,
  searchQuery,
  mentorId,
  mentorEventsTotalCount,
}: any) => {
  // console.log('mentorID', mentorId)
  const history = useHistory()
  const location = useLocation()
  const organizationKey = location.state
  const dispatch = useDispatch()
  let initPaginationInfo = {
    total: mentorId ? mentorEventsTotalCount : totalCount,
    current: 1,
    defaultPageSize: 5,
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: false,
  }
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)
  //   const [isModalVisible, setModalVisible] = useState(false);
  //   const [currentData, setCurrentData] = useState<any>([]);

  // console.log(organizationKey, 'orgKey is')

  useEffect(() => {
    dispatch({
      type: mentorId
        ? eventsAction.GET_MENTOR_EVENTS
        : actions.GET_EVENT_REQUEST,
      [mentorId ? 'payload' : 'params']: mentorId
        ? { mentorId: mentorId }
        : { orgId: organizationKey },
    })
    dispatch({
      type: eventsAction.GET_EVENT_CATEGORIES,
    })
  }, [])

  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: mentorId ? mentorEventsTotalCount : totalCount,
    })
  }, [totalCount, mentorEventsTotalCount])

  useEffect(() => {
    if (categorySort !== '') {
      dispatch({
        type: mentorId
          ? eventsAction.GET_MENTOR_EVENTS
          : eventsAction.GET_EVENT_REQUEST,
        [mentorId ? 'payload' : 'params']: mentorId
          ? { mentorId: mentorId, id: categorySort }
          : { id: categorySort, orgId: organizationKey },
      })
    } else {
      dispatch({
        type: mentorId
          ? eventsAction.GET_MENTOR_EVENTS
          : eventsAction.GET_EVENT_REQUEST,
        [mentorId ? 'payload' : 'params']: mentorId
          ? { id: null, mentorId: mentorId }
          : { id: null, orgId: organizationKey },
      })
    }
  }, [categorySort])

  useEffect(() => {
    if (statusSort !== '') {
      dispatch({
        type: mentorId
          ? eventsAction.GET_MENTOR_EVENTS
          : eventsAction.GET_EVENT_REQUEST,
        [mentorId ? 'payload' : 'params']: mentorId
          ? { mentorId: mentorId, status: statusSort }
          : { status: statusSort, orgId: organizationKey },
      })
    } else {
      dispatch({
        type: mentorId
          ? eventsAction.GET_MENTOR_EVENTS
          : eventsAction.GET_EVENT_REQUEST,
        [mentorId ? 'payload' : 'params']: mentorId
          ? { mentorId: mentorId, status: null }
          : { status: null, orgId: organizationKey },
      })
    }
  }, [statusSort])

  const onTableChange: PaginationProps['onChange'] = page => {
    let newPaginationInfo = {
      ...paginationInfo,
      current: page,
    }

    getMentors(newPaginationInfo)
    setPaginationInfo({
      ...paginationInfo,
      current: page,
    })
  }

  const getMentors = (newPaginationInfo: any) => {
    dispatch({
      type: actions.GET_EVENT_REQUEST,
      params: {
        current: newPaginationInfo.current,
        orgId: organizationKey,
        status: statusSort,
        id: categorySort,
      },
    })
  }

  const actualEvents = mentorId ? mentorEvents : events

  return (
    <Row gutter={[12, 24]}>
      <>
        <Col xs={{ span: 12 }} xl={{ span: 16 }}>
          <>
            {actualEvents &&
              actualEvents
                ?.filter((e: any) =>
                  e.name.toLowerCase().includes(searchQuery.toLowerCase()),
                )
                .map((e: any) => {
                  return (
                    <EventCard
                      key={e._id}
                      eventImg={e?.coverPhotos[0]?.post}
                      eventName={e.name}
                      eventLoc={e?.location?.title}
                      eventStartDateMonth={moment(e?.datesOfEvent[0]).format(
                        'MMM',
                      )}
                      eventStartDateDay={moment(e?.datesOfEvent[0]).format('D')}
                      eventEndDateMonth={moment(e?.datesOfEvent[1]).format(
                        'MMM',
                      )}
                      eventEndDateDay={moment(e?.datesOfEvent[1]).format('D')}
                      eventId={e._id}
                      categoryImg={
                        categories.find(
                          (cate: any) => cate._id === e.category._id,
                        )?.icon
                      }
                      seatsStatus={e?.seatsStatus}
                      venueType={e?.venueType}
                    />
                  )
                })}
            <Row className="justify-end mt-10">
              <Pagination
                className="pb-20"
                style={{ width: '100%', display: 'flex' }}
                {...paginationInfo}
                onChange={onTableChange}
              />
            </Row>
          </>
        </Col>
        <Col xl={{ span: 8 }} xs={{ span: 12 }}>
          <Row justify="center" className="justify-end">
            <Col lg={21} span={24}>
              <CustomCalender />
            </Col>
          </Row>
        </Col>
      </>
    </Row>
  )
}

const mapStateToProps = (state: any) => ({
  events: state.events.events,
  mentorEvents: state.events.mentorEvents,
  totalCount: state.events.totalCount,
  mentorEventsTotalCount: state.events.mentorEventsTotalCount,
  categories: state.events.eventCategoryData,
})
export default connect(mapStateToProps)(CardViewOrg)
