import { Typography, Form, Row, Col, Input, Button, Space } from 'antd'
import { Redirect } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import bamreclogo from '../../assets/Images/bamrec-logo.svg'
import facebookIcon from '../../assets/Images/facebook.svg'
import appleIcon from '../../assets/Images/appleIcon.svg'
import googleIcon from '../../assets/Images/google.svg'
import passwordView from '../../assets/Images/passwordview.svg'
import actions from '../../Redux/Auth/action'
import { history } from '../../Redux/app-store'
import phone from '../../assets/Images/Phone.svg'
import './styles.scss'
import { failureMessage } from 'api/global'

const Login = ({ authenticated, user }: any) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const onFinish = (values: any) => {
    dispatch({
      type: actions.LOGIN_REQUEST,
      payload: values,
    })
  }

  if (authenticated) {
    const previousRoute = localStorage.getItem('previousRoute')
    if (
      previousRoute === undefined ||
      previousRoute === 'undefined' ||
      previousRoute === null ||
      previousRoute === 'null'
    ) {
    } else {
      return <Redirect to={previousRoute} />
    }
  }
  // if (authenticated) {
  //   const previousRoute = localStorage.getItem('previousRoute')
  //   const currentRoute = localStorage.getItem('currentRoute')

  //   if (previousRoute !== '/dashboard' && currentRoute === '/dashboard') {
  //     return <Redirect to="/dashboard" />
  //   } else if (previousRoute === '/' && currentRoute === '/dashboard') {
  //     return <Redirect to="/dashboard" />
  //   } else if (previousRoute === '/dashboard' && currentRoute === '/') {
  //     return <Redirect to={previousRoute} />
  //   } else if (previousRoute && previousRoute !== '/previousRoute') {
  //     return <Redirect to={previousRoute} />
  //   }
  // }

  return (
    <>
      <div className={'login-form-container'}>
        <Row style={{ width: '100%' }}>
          <Col md={24} lg={10} xl={10} style={{ background: '#fff' }}>
            <div className="left-login-form">
              <div>
                <Row>
                  <Col span={6}>
                    <Typography className={'title-fontStyle text-center'}>
                      <img alt="img" src={bamreclogo} />
                    </Typography>
                  </Col>
                </Row>
              </div>
              <div className={'margin-20'}>
                <span>
                  <h1 className="login-with-email">
                    <b className="b1">Hi, Welcome back!</b>
                  </h1>
                </span>
                <p className="dont-have-and-account">
                  Don’t have an account? <a href="#">Contact admin</a>
                </p>
                <div>
                  <Form form={form} name="register" onFinish={onFinish}>
                    <label className="input-label">Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                    <label className="input-label">Password</label>
                    <div className="password-login-input">
                      <img
                        src={passwordView}
                        alt="icon"
                        className="passwordview-icon"
                      />
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your password!',
                          },
                        ]}
                      >
                        <Input.Password
                          className="input-password"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </div>
                    <p className="forgot-password">
                      Forgot password?
                      <a
                        onClick={() => {
                          history.push('/phonelogin')
                        }}
                      >
                        Recover
                      </a>
                    </p>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        <span
                          style={{
                            color: 'white',
                            fontSize: '14px',
                            alignItems: 'center',
                          }}
                        >
                          Login
                        </span>
                      </Button>
                    </Form.Item>
                    <div className="break-line">
                      or Sign up with social accounts
                    </div>
                    <Row className="social-btn-group">
                      <div className="social-btn">
                        <Space size={30}>
                          <img alt="img" src={phone} />
                          <span
                            className="cursor_pointer"
                            onClick={() => {
                              history.push('/phonelogin')
                            }}
                          >
                            Sign up with phone
                          </span>
                        </Space>
                      </div>
                      <div className="social-btn">
                        <Space size={30}>
                          <img alt="img" src={googleIcon} />
                          <span
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: actions.GOOGLE_SIGNIN,
                              })
                            }}
                          >
                            Sign up with Google
                          </span>
                        </Space>
                      </div>
                      <div className="social-btn">
                        <Space size={50}>
                          <img alt="img" src={appleIcon} />
                          <span
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: actions.APPLE_SIGNIN,
                              })
                            }}
                          >
                            Sign up with Apple
                          </span>
                        </Space>
                      </div>
                      <div className="social-btn">
                        <Space size={30}>
                          <img alt="img" src={facebookIcon} />
                          <span
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: actions.FACEBOOK_SIGNIN,
                              })
                            }}
                          >
                            Sign up with Facebook{' '}
                          </span>
                        </Space>
                      </div>
                    </Row>
                    <Row
                      className="text-center signup-link phone-login-dont-acct"
                      justify="center"
                    >
                      <p>
                        Don’t have account? <a href="/Signup">Sign Up</a>
                      </p>
                      <Button></Button>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col md={24} lg={14} xl={14}>
            <div className="right-col-blue"></div>
          </Col>
        </Row>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
})
export default connect(mapStateToProps)(Login)
