import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Row,
  Input,
  Col,
  Avatar,
  Radio,
  DatePicker,
  Dropdown,
  Menu,
  Select,
} from 'antd'
import AllergyList from 'helper/AllergyList'
import FileUploader from 'helper/FileUploader'
import moment from 'moment'
import dayjs from 'dayjs'
import { useDispatch, connect } from 'react-redux'
import actions from 'Redux/Family/action'
import {
  gradeDropdown,
  kidFeetDropdown,
  kidInchesDropdown,
  feetToMeters,
  inchesToMeters,
  feetAndInchesToMeters,
  metersToFeetAndInches,
} from '../Misc/DropDowns'
import { getRecentCheckIn } from 'api/family'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { convertToUtc } from 'helper/utility'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import CustomAllergyList from '../../../../../helper/allergy/AllergyList'
const BasicInfo = ({
  data,
  handleNext,
  kidvalue,
  kidId,
  setKidId,
  recentCheckIn,
}: any) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState(
    recentCheckIn?.dropInAttendee?.photo?.post || null,
  )
  const [allergies, setAllergies] = useState<any>(
    kidvalue?.allergies || data?.allergies || [],
  )

  dayjs.extend(weekday)
  dayjs.extend(localeData)
  dayjs.locale('en')
  dayjs.locale('vi')
  const currentDate = moment().startOf('day').format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)
  const { id }: any = useParams()

  const [grade, setGrade] = useState<any>()
  const [feet, setFeet] = useState<any>()
  const [inches, setInches] = useState<any>()
  const [meter, setMeter] = useState<any>()
  const [weightInKg, setWeightInKg] = useState<any>()
  const [wightinPound, setWeightInPounds] = useState<any>()
  const [allergyModal, setAllergyModal] = useState(false)
  useEffect(() => {
    kidId === undefined && setKidId(kidvalue?.id)
    const formSchema = {
      photoId: kidvalue?.photoId,
      relationId: kidvalue?.relation?._id,
      firstName: kidvalue?.firstName,
      lastName: kidvalue?.lastName,
      nickName: kidvalue?.nickName,
      middleName: kidvalue?.middleName,
      gender: kidvalue?.gender,
      dob: kidvalue?.dob && moment(kidvalue?.dob).format('YYYY-MM-DD'),
      // dob: kidvalue?.dob,
      school: kidvalue?.school,
      grade: kidvalue?.grade,
      height: kidvalue?.height,
      weight: kidvalue?.weight,
      // allergies: kidvalue?.allergies,
      medicalNotes: kidvalue?.medicalNotes,
    }
    if (kidvalue?.id) {
      handleNext(formSchema)
    }
  }, [kidvalue])

  const formSchema = {
    photoId: data?.photoId || undefined,
    relationId: data?.relation?._id,
    firstName: data?.firstName || recentCheckIn?.dropInAttendee?.firstName,
    lastName: data?.lastName || recentCheckIn?.dropInAttendee?.lastName,
    nickName: data?.nickName,
    middleName: data?.middleName,
    email: data?.email,
    gender: data?.gender || recentCheckIn?.dropInAttendee?.gender,
    phone: data?.phone,
    dob:
      data?.dob || recentCheckIn?.dropInAttendee?.dob
        ? dayjs(data?.dob || recentCheckIn?.dropInAttendee?.dob)
        : undefined,

    school: data?.school,
    grade: data?.grade || recentCheckIn?.dropInAttendee?.grade,
    height: data?.height,
    weight: data?.weight,
    medicalNotes: data?.medicalNotes,
  }

  const onFinish = (formData: any) => {
    // const dataValues = { ...formData };
    const dataValues = { ...data, ...formData }
    const id = ''
    let allergyData = allergies.map((alergy: any) => {
      return {
        _id: alergy._id || alergy.key,
        name: alergy.name || alergy.value,
      }
    })
    dataValues.allergies = allergyData
    dataValues.dob = formData.dob.format('YYYY-MM-DD')
    dataValues.weight = weightInKg
    dataValues.height = meter && meter
    if (!kidId) {
      // dispatch({
      //   type: actions.ADD_KID_REQUEST,
      //   payload: dataValues,
      // })
    }
    handleNext(dataValues)
  }
  const handleFeetChange = (value: any) => {
    setFeet(value)
  }
  const handleInchesChange = (value: any) => {
    setInches(value)
  }
  const handleWeightChange = (value: any) => {
    setWeightInPounds(value)
    const weightInKgs = value * 0.453592
    setWeightInKg(weightInKgs)
  }
  useEffect(() => {
    if (data?.height) {
      const feetandInch = metersToFeetAndInches(data?.height)
      setFeet(feetandInch.feet)
      setInches(feetandInch.inches.toFixed(0))
    }
  }, [data])
  useEffect(() => {
    if (feet && inches) {
      setMeter(feetAndInchesToMeters(feet, inches))
    } else if (!feet) {
      setMeter(inchesToMeters(inches))
    } else if (!inches) {
      setMeter(feetToMeters(feet))
    }
  }, [feet, inches])
  // const dobMoment = dayjs(dobDayjs).toDate()
  return (
    <div>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <div className="form_chil_h_block " style={{ overflowX: 'hidden' }}>
          <Row gutter={24} align="middle">
            <Col span={4} className="mb-16">
              <Avatar
                size={80}
                src={avatar ? avatar : '/assets/userAvatar.svg'}
              ></Avatar>
            </Col>
            <Col span={18} className="mb-16">
              <Form.Item
                name="photoId"
                // rules={[{ required: true }]}
                className="mb-0"
              >
                <FileUploader
                  setAvatar={setAvatar}
                  name="guardian"
                  form={form}
                  fileId={data?.photoId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label required-input">
                First Name
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input
                  placeholder="First Name"
                  name="firstname"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label required-input">
                Last Name
              </label>
              <Form.Item
                name="lastName"
                colon={false}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input
                  placeholder="Last Name"
                  name="lastname"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                Middle Name
              </label>
              <Form.Item colon={false} name="middleName">
                <Input
                  placeholder="Middle Name"
                  name="middleName"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                Nick Name
              </label>
              <Form.Item name="nickName" colon={false}>
                <Input
                  placeholder="Nick Name"
                  name="nickname"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="custom-field-label required-input">
                Gender
              </label>
              <Form.Item
                name="gender"
                colon={false}
                rules={[{ required: true, message: 'Gender is required' }]}
              >
                <Radio.Group name="gender" style={{ width: '100%' }}>
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="female"
                  >
                    Female
                  </Radio.Button>
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="male"
                  >
                    Male
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label className="custom-field-label required-input">
                Date of Birth
              </label>
              <Form.Item
                name="dob"
                rules={[{ required: true, message: 'dob is required' }]}
              >
                <DatePicker
                  placeholder="Date Of Birth"
                  format={'YYYY-MM-DD'}
                  className="date-picker custom-input-field"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                School
              </label>
              <Form.Item name="school">
                <Input
                  placeholder="School"
                  name="school"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                Grade
              </label>
              <Form.Item name="grade" colon={false}>
                <Select
                  className="selector"
                  placeholder={'Grade'}
                  onChange={(v, item) => {
                    setGrade(v)
                  }}
                >
                  {gradeDropdown.map((grade: any) => (
                    <Select.Option
                      key={grade.key}
                      value={grade.key}
                      style={{ color: 'black' }}
                      className="custom-select-field"
                    >
                      {grade.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                Weight (lb's)
              </label>
              <Form.Item colon={false} name="weight">
                <Input
                  placeholder="Weight"
                  name="weight"
                  className="custom-input-field"
                  type="number"
                  onChange={e => handleWeightChange(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="custom-field-label">
                Height
              </label>
              <Form.Item name="height" colon={false}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item name="height" colon={false}>
                      <div className="combined-input-field d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <Select
                            className=" combined-select"
                            value={feet}
                            onChange={handleFeetChange}
                            placeholder="ft"
                          >
                            {kidFeetDropdown.map(option => (
                              <Select.Option
                                key={option.key}
                                value={option.key}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                          <span className="separator">
                            {'\u00A0'}
                            {'feet'}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <Select
                            className="custom-select-form combined-select"
                            value={inches}
                            onChange={handleInchesChange}
                            placeholder="in"
                          >
                            {kidInchesDropdown.map(option => (
                              <Select.Option
                                key={option.key}
                                value={option.key}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                          <span className="separator">
                            {'\u00A0'}
                            {'inches'}
                          </span>
                        </div>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <label htmlFor="" className="custom-field-label">
                Allergies
              </label>
              <Form.Item name="allergies" className="custom-select-form">
                {/* <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                /> */}
                <Input
                  className="custom-input-field"
                  placeholder="Allergies"
                  onClick={() => {
                    setAllergyModal(true)
                  }}
                  value={
                    allergies.length > 0
                      ? allergies.map(item => item.name)
                      : ' '
                  }
                />
                {allergyModal && (
                  <CustomAllergyList
                    isModalOpen={allergyModal}
                    setModalOpen={setAllergyModal}
                    // data={data?.allergies || dataAuthorizedAdults?.allergies}
                    data={allergies}
                    setAllergies={setAllergies}
                  />
                )}{' '}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="custom-field-label">
                Medical Notes
              </label>
              <Form.Item
                name="medicalNotes"
                colon={false}
                className="phone-number-field"
              >
                <Input
                  name="medicalnotes"
                  placeholder="Ex.: Asthma, Lactose intolerant"
                  className="custom-input-field"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row justify="end" align="bottom" className="mt-30 mb-10 ">
          <button
            className=" prim-btn  pointer "
            type="submit"
            style={{ marginRight: 20 }}
          >
            Next
          </button>
        </Row>
      </Form>
    </div>
  )
}
// export default BasicInfo;
const mapStateToProps = (state: any) => ({
  kidvalue: state.family?.kidsValue?.data,
  recentCheckIn: state.events.recentCheckIn,
})

export default connect(mapStateToProps)(BasicInfo)
