import React, { useState, useEffect } from 'react'
import Spinner from 'components/Spinner'
import Map from './Map'

const App = (props: any) => {
  const [latitude, setLatitude] = useState<number | null>(null)
  const [longitude, setLongitude] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude)
        setLongitude(position.coords.longitude)
        setLoading(false)
      },
      error => {
        console.error('Error getting geolocation:', error)
        setLoading(false)
      },
    )
      console.log("lat , long : ", latitude , longitude)
  }, [])

    useEffect(() => {

        console.log("lat , long : ", latitude , longitude)
    }, [latitude , longitude])

  if (loading) {
    return (
      <div className="h-20 flex justify-center align-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div style={{ marginTop: '50px' }}>
      <Map
        next={props.next}
        google={props.google}
        center={{
          lat: latitude !== null ? latitude : 37.2428698,
          lng: longitude !== null ? longitude : -121.9231999,
        }}
        mapData={props.mapData}
        height="300px"
        zoom={15}
      />
    </div>
  )
}

export default App
