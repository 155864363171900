import info from '../../../../../assets/Images/Info.svg'
import { useState } from 'react'
import { Column } from '@ant-design/plots'
import { Dropdown } from 'antd'
import Down from '../../../../../helper/Icons/DownIcon'

const AgeOverview = ({ totalAgeGroup, maleGroup, femaleGroup }: any) => {
  const [open, setOpen] = useState(0)
  const [selectedData, setSelectedData] = useState(totalAgeGroup)
  const [current, setCurrent] = useState('All')
  const chartData = selectedData?.map((ageGroup: any, index: any) => ({
    ageGroup: `${ageGroup.above}-${ageGroup.below}`,
    peopleCount: ageGroup.peopleCount,
  }))

  const config = {
    data: chartData,
    columnStyle: {
      radius: [10, 10, 10, 10],
    },

    xField: 'ageGroup',
    yField: 'peopleCount',
    xAxis: {
      title: {
        text: 'Age Group',
      },
    },
    yAxis: {
      title: {
        text: 'Number of Participants',
      },
    },

    label: {
      style: {
        fill: '#ABC5ED',
      },
    },
    interactions: [{ type: 'element-active' }],
    tooltip: {
      width: 800,
      fields: ['peopleCount'],
      formatter: (value: any) => {
        return {
          name: 'Number of Participants',
          value: value.peopleCount,
        }
      },
    },
  }

  const Age = [
    {
      key: '1',
      label: 'All',
    },
    {
      key: '2',
      label: 'Male',
    },
    {
      key: '3',
      label: 'Female',
    },
  ]
  const onClick = ({ key }: any) => {
    if (key === '1') {
      setSelectedData(totalAgeGroup)
      setCurrent('All')
    } else if (key === '2') {
      setSelectedData(maleGroup)
      setCurrent('Male')
    } else if (key === '3') {
      setSelectedData(femaleGroup)
      setCurrent('Female')
    }
    setOpen(0)
  }

  return (
    <div className="border-1px radius-8 mt-30px p-15px">
      <div className="flex justify-between">
        <div>
          <h3 className="text-16">Age overview</h3>
          <div className="flex align-center ">
            <p className="grey-txt text-12 ">
              Monitoring the age of participants over a period of time
            </p>
            <img src={info} alt="info" className="pl-5px" />
          </div>
        </div>
        <Dropdown
          menu={{
            items: Age,
            onClick,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 1}
          overlayStyle={{ width: '200px' }}
        >
          <button
            className={` ${
              open === 1 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(1)}
          >
            Gender:{' ' + current} <Down />
          </button>
        </Dropdown>
      </div>
      <div className="mt-40px">
        <Column color={'#ABC5ED'} {...config} />
      </div>
    </div>
  )
}
export default AgeOverview
