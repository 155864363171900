import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Input, Col, Slider, Popover } from 'antd'
import moment from 'moment'
import {
  strengthList,
  listAreaImprovement,
  howComfort,
  ChildsReading,
} from '../Misc/PopoverContent'
import sad from '../../../../../assets/Images/sad.svg'
import happy from '../../../../../assets/Images/happy.svg'
import smile from '../../../../../assets/Images/smile.svg'
import { Info } from '@phosphor-icons/react'
import Loader from 'helper/Loader'
const Abilities = ({
  onSubmit,
  data,
  handleBack,
  loading,
  setModalVisible,
}: any) => {
  const [form] = Form.useForm()
  const onFinish = (formData: any) => {
    const dataValues = { abilities: formData }
    onSubmit({
      ...data,
      dob: data?.dob && moment(data?.dob).format('YYYY-MM-DD'),
      abilities: { ...formData, kidMood: kidMood, readingLevel: readingLevel },
    })
  }
  const marks = {
    0: 'Bad',
    25: 'Average',
    50: 'Good',
    75: 'Very Good',
    100: 'Excellent',
  }
  const mood = {
    0: <img src={sad} alt="sad" />,
    50: <img src={happy} alt="happy" />,
    100: <img src={smile} alt="smile" />,
  }
  // setting the mood and the readinglevel
  const [readingLevel, setReadingLevel] = useState<string>(
    data?.abilities?.readingLevel || undefined,
  )
  const [kidMood, setKidMood] = useState<string>(
    data?.abilities?.kidMood || undefined,
  )
  const setReadingNumberByString = (readingLevel: string) => {
    if (readingLevel === 'bad') {
      return 0
    } else if (readingLevel === 'average') {
      return 25
    } else if (readingLevel === 'good') {
      return 50
    } else if (readingLevel === 'very-good') {
      return 75
    } else if (readingLevel === 'excellent') {
      return 100
    }
  }
  const setKidMoodByString = (kidMood: string) => {
    switch (kidMood) {
      case 'sad':
        return 0
      case 'natural':
        return 50
      case 'happy':
        return 100
    }
  }
  const handleReadingLevel = (value: any) => {
    if (value === 0) {
      setReadingLevel('bad')
    } else if (value === 25) {
      setReadingLevel('average')
    } else if (value === 50) {
      setReadingLevel('good')
    } else if (value === 75) {
      setReadingLevel('very-good')
    } else if (value === 100) {
      setReadingLevel('excellent')
    }
  }
  const handleKidMood = (value: any) => {
    if (value === 0) {
      setKidMood('sad')
    } else if (value === 50) {
      setKidMood('natural')
    } else if (value === 100) {
      setKidMood('happy')
    }
  }
  // updating the states after adding
  useEffect(() => {
    setReadingLevel(readingLevel)
    setKidMood(kidMood)
  }, [handleReadingLevel, handleKidMood])
  var formSchema = {
    strength: data?.abilities?.strength || '',
    areaOfImprovements: data?.abilities?.areaOfImprovements || '',
    howToComfort: data?.abilities?.howToComfort || '',
    readingLevel: data?.abilities?.readingLevel || null,
    kidMood: data?.abilities?.kidMood || null,
  }
  return (
    <Form
      name="nest-messages"
      onFinish={onFinish}
      initialValues={formSchema}
      form={form}
      layout={'vertical'}
      autoComplete="off"
    >
      <div className="modal-h-element">
        <Row>
          <Col span={24}>
            <label htmlFor="" className="flex-center">
              Lists of strengths{' '}
              <Popover content={strengthList} title="" placement="bottom">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name={'strength'}>
              <Input className="custom-input-field" name="strength" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="flex-center">
              Lists of areas of improvement{' '}
              <Popover content={listAreaImprovement} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name={'areaOfImprovements'}>
              <Input className="custom-input-field" name="areaOfImprovements" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mb-16">
            <label htmlFor="" className="flex-center">
              How to comfort if hurt, scared, worried{' '}
              <Popover content={howComfort} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name={'howToComfort'}>
              <Input className="custom-input-field" name="howToComfort" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="reading-level">
          <Col span={8} className="mb-16">
            <label htmlFor="" className="flex-center">
              Reading Level{' '}
              <Popover content={ChildsReading} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
          </Col>
          <Col span={16} className="mb-16" style={{ marginTop: '-10px' }}>
            <Form.Item>
              <Slider
                className="ability pb-10"
                marks={marks}
                value={setReadingNumberByString(data?.abilities?.readingLevel)}
                included={false}
                step={25}
                onChange={(value: any) => {
                  handleReadingLevel(value)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} className="mb-16">
            <label htmlFor="" className="custom-field-label">
              Child's mood on an average day
              <span className="information-icon">
                {/* <img alt="" src={informationIcon} /> */}
              </span>
            </label>
          </Col>
          <Col span={16} className="mt-16">
            <Form.Item>
              <Slider
                className="ability emoji-items"
                marks={mood}
                step={50}
                value={setKidMoodByString(data?.abilities?.kidMood)}
                included={false}
                onChange={(v: any) => {
                  handleKidMood(v)
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="flex justify-end w-full">
        <div className="px-30px py-15px bg-grey radius-8 pointer mr-30px">
          <h3 className="text-14" onClick={() => handleBack(data)}>
            Back
          </h3>
        </div>

        <button className="prim-btn" type="submit">
          {loading ? <Loader /> : 'Create kid'}
        </button>
      </div>
    </Form>
  )
}
export default Abilities
