import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Layout } from 'antd'
import { Steps, message } from 'antd'
import { connect, useDispatch } from 'react-redux'
import actions from '../../../../Redux/Family/action'
import PersonalDetails from './PersonalDetails'
import CreatePassword from './CreatePassword'
import familyAction from '../../../../Redux/Family/action'
const { Step } = Steps
const GuardiansModal = ({
  isModalVisible,
  guardianData,
  currentFamilyData,
  setModalVisible,
  familyId,
  operation,
  setMembersModalVisible,
  fetchAgain,
}: any) => {
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState(guardianData)
  const dispatch = useDispatch()
  const steps = [
    {
      title: 'Personal Details',
    },
    {
      title: 'Create password',
    },
  ]
  const prev = () => {
    setCurrent(current - 1)
  }
  useEffect(() => {
    console.log('currentFamilyData:', currentFamilyData)
  }, [])
  const next = (data: any) => {
    setData(data)
    setCurrent(current + 1)
  }
  const handleOk = () => {
    // setModalVisible(false);
    dispatch({
      type: familyAction.FAMILY_MODAL_VISIBLE,
      payload: false,
    })
  }
  const handleCancel = () => {
    setModalVisible(false)

    dispatch({
      type: familyAction.FAMILY_MODAL_VISIBLE,
      payload: false,
    })
  }
  const onSubmit = (formData: any) => {
    if (operation === 'create') {
      dispatch({
        type: actions.CREATE_GUARDIAN,
        payload: formData,
      })
      dispatch({
        type: actions.FAMILY_MODAL_VISIBLE,
        payload: false,
      })
    } else {
      dispatch({
        type: actions.ADD_GUARDIAN,
        payload: formData,
        familyId: familyId,
      })
      setMembersModalVisible(false)
      // setModalVisible(false);
      dispatch({
        type: familyAction.FAMILY_MODAL_VISIBLE,
        payload: false,
      })
      fetchAgain()
    }
  }

  return (
    <Layout>
      <Modal
        className="organization_model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>{currentFamilyData?._id ? 'Edit Guardian' : 'Add Guardian'} </h4>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} className="stepper" title={item.title} />
            ))}
          </Steps>
        </div>
        <div>
          {current === 0 && <PersonalDetails data={data} handleNext={next} />}
          {current === 1 && (
            <CreatePassword
              data={data}
              handleSubmit={onSubmit}
              handleBack={prev}
            />
          )}
        </div>
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state: any) => ({
  currentFamilyData: state.family.currentFamilyData,
})

export default connect(mapStateToProps)(GuardiansModal)
