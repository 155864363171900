/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  loading: false,
  relationsList: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.GET_RELATIONS: {
      return {
        ...state,
        relationsList: [],
        loading: true,
      };
    }

    case actions.GET_RELATIONS_SUCCESS: {
      return {
        ...state,
        relationsList: action.payload,
        loading: false,
      };
    }

    case actions.GET_RELATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
