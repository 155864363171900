import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosGet, axiosPost } from 'Redux/axiosHelper'
import { EmailTemplate, EmailTemplateResp } from 'types/globalTypes'

const getEmailTemplates = async (orgId: string) => {
  const response = await axiosGet(`v1/email-templates/all-templates/${orgId}`)
  return response.data
}
export const useGetEmailTemplates = (orgId: string) => {
  const eventEmailTemplates = useQuery<EmailTemplateResp[]>(
    ['event-email-template'],
    () => getEmailTemplates(orgId),
    { enabled: false },
  )
  return eventEmailTemplates
}

const createEmailTemplate = async ({
  payload,
  orgId,
}: {
  payload: EmailTemplate
  orgId: string
}) => {
  const response = await axiosPost(payload, `v1/email-templates/${orgId}`)
  return response.data
}

export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation(createEmailTemplate, {
    onSuccess: newTemplate => {
      queryClient.setQueryData(
        ['event-email-template'],
        (oldData: EmailTemplateResp[] | undefined) => {
          return oldData ? [...oldData, newTemplate] : [newTemplate]
        },
      )
    },
  })
}
