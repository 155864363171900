import { Tabs, Row, Col, Button } from "antd";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getOrganizers } from "../../../../../api/Organizers";
import OrganizerListing from "./OrganizersListing";

const OrganizerInfo = ({ onSuccess }: any) => {
  const [selectedOrganizer, setSelectedOrganizer] = useState();
  const [parms, setParms] = useState({
    page: 1,
    status: "active",
    limit: 10,
    searchQuery: "",
  });

  const { isLoading, data } = useQuery(["getOrganizers", parms], () =>
    getOrganizers(parms)
  );
  const TabChange = (key: string) => {
    if (key === "1") {
      setParms({ ...parms, status: "active" });
    }
    if (key === "2") {
      setParms({ ...parms, status: "inactive" });
    }
  };
  const items = [
    {
      key: "1",
      label: "Active",
      children: (
        <OrganizerListing
          data={data}
          isLoading={isLoading}
          parms={parms}
          setParms={setParms}
          setClickedData={setSelectedOrganizer}
        />
      ),
    },
    {
      key: "2",
      label: "Pending",
      children: (
        <OrganizerListing
          data={data}
          isLoading={isLoading}
          parms={parms}
          setParms={setParms}
          setClickedData={setSelectedOrganizer}
        />
      ),
    },
  ];
  return (
    <div className="mt-10 pb-30px">
      <Tabs
        defaultActiveKey="1"
        items={items}
        className="org-tab"
        onChange={TabChange}
      />
      <Row justify="end" className="mr-40px">
        <Col span={24} className="text-end">
          <Button
            className="stepper-button mr-20px"
            onClick={() => {
              onSuccess(selectedOrganizer);
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default OrganizerInfo;
