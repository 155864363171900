import React, { useState } from 'react'
import { Row, Col, Button, Space, Dropdown, Menu } from 'antd'
import CustomCal from '../../../helper/Calendar/SessionCalendar/CustomCal'
import FullCalendar from '../../../helper/Calendar/SessionCalendar/FullCalendar'
import Schedule from '../../../helper/Calendar/SessionCalendar/Schedule'
import PencilLine from '../../../assets/Images/PencilLine.svg'
import { DownOutlined } from '@ant-design/icons'
import SessionDetail from './SessionDetail'
import DayCalendar from './DayCalendar'

const SessionSchedule = () => {
  const [view, setView] = useState('Day')

  const menu = (
    <Menu onClick={e => setView(e.key)}>
      <Menu.Item key="Day">Day</Menu.Item>
      <Menu.Item key="Week">Week</Menu.Item>
      <Menu.Item key="Schedule">Schedule</Menu.Item>
    </Menu>
  )
  return (
    <>
      <div>
        <Row justify="space-between" className="session-header ">
          <Col span={12} className="align-self-center">
            <h6 className="b1 mb-0">Session schedule</h6>
          </Col>
          <Col span={4} offset={4} className="text-right">
            <Dropdown overlay={menu} trigger={['click']}>
              <Button
                className="gry-btn br-8 pl-20 pr-20"
                style={{ width: '126px' }}
              >
                {view} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <div className="pr pl-30">
          <Row>
            <Col>
              <div>
                {view === 'Day' && <DayCalendar />}
                {/* {view === "Week" && <SessionSchedule />}
                {view === "Schedule" && <SessionDetail />} */}
              </div>
            </Col>
          </Row>
        </div>
        {/* <Row className="session_container">
            <Col span={8} className="border_container">
              <div className="profile_container">
                <CustomCal setCurrentDate={setCurrentDate} />
                <Button 
                  className="edit-shedule-btn"
                  onClick={() => setIsData(true)}
                >
                  <Space size={14}>
                    <img alt="" src={PencilLine}></img>
                    Edit schedule
                  </Space>
                </Button>
              </div>
            </Col>
            <Col span={16}>
              <div className="profile_container">
                <Schedule curDate={curDate} />
              </div>
            </Col>
          </Row> */}
      </div>
    </>
  )
}

export default SessionSchedule
