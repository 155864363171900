import Modal from 'antd/lib/modal/Modal'
import { Form, Row, Input, Col, Avatar, Space, DatePicker } from 'antd'
import { useState } from 'react'
import userAvatarSvg from '../../../assets/Images/userAvatar.svg'
import FileUploader from '../../../helper/FileUploader'
import RelationsList from '../../../helper/RelationsList'
import AllergyList from '../../../helper/AllergyList'
import Cancel from '../../../assets/Images/close.svg'
import { useMutation } from '@tanstack/react-query'
import { updateGuardianProfile } from '../../../api/family'
import moment from 'moment'
const EditGuardianProfile = ({
  data,
  modalVisble,
  setModalVisible,
  update,
}: any) => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState()
  const [allergies, setAllergies] = useState<any>(data.allergies)
  const formSchema = {
    photoId: data?.photoId || '',
    relationId: data?.relation?._id || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    phone: data?.phone || '',
    medicalNotes: data?.medicalNotes || '',
  }
  const updateMutation = useMutation({
    mutationFn: async ({ id, data }: any) => {
      return await updateGuardianProfile(id, data)
    },
  })
  const onFinish = (values: any) => {
    let val = {
      ...values,
      allergies,
      dob: moment(values.dob).format('YYYY-MM-DD'),
    }
    updateMutation.mutate({ id: data._id, data: val })
    update(true)
    setModalVisible(false)
  }
  const handleOk = () => {
    setModalVisible(false)
  }
  const handleCancel = () => {
    setModalVisible(false)
  }

  return (
    <Modal
      open={modalVisble}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <div className="popup-header">
        <h2>Edit Profile</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <div className="modal-h-element">
          <Space size={[24, 0]} wrap className="mb-12">
            <Form.Item>
              <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
            </Form.Item>
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="guardian"
                form={form}
                fileId={data?.photoId}
              />
            </Form.Item>
            <p className="b3 mb-0 mt-12 text-color-black font12 position-relative required-field-star pl-10">
              For security purposes, please only use a real photo
            </p>
          </Space>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="inp-label required-input">
                First Name
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                className="my-input-box"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input placeholder="First Name" name="firstname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="inp-label required-input">
                Last Name
              </label>
              <Form.Item
                name="lastName"
                className="my-input-box"
                colon={false}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input placeholder="Last Name" name="lastname" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label htmlFor="" className="inp-label required-input">
                Relationship
              </label>
              <Form.Item
                name="relationId"
                rules={[
                  { required: true, message: 'Relationship is required' },
                ]}
                className="custom-select-field h-3 position my-input-box"
              >
                <RelationsList
                  data={null}
                  form={form}
                  relationId={formSchema.relationId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <label htmlFor="" className="inp-label required-input">
              Date of Birth
            </label>
            <Form.Item
              name="dob"
              colon={false}
              rules={[{ required: true, message: 'Date of birth is required' }]}
            >
              <DatePicker className="w-full h-3 position" />
            </Form.Item>
          </Col>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="inp-label mt-15px">
                Allergies
              </label>
              <Form.Item
                name="allergies"
                className="form-control allergies-field"
              >
                <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="mb-16">
              <label htmlFor="" className="inp-label">
                Medical Notes
              </label>
              <Form.Item
                name="medicalNotes"
                className="my-input-box"
                rules={[
                  { required: true, message: 'Medical notes is required' },
                ]}
              >
                <Input placeholder="Ex.: Asthma, Lactose intolerant" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="popup-footer">
          <button className="btn-sec" onClick={handleCancel}>
            Cancel
          </button>
          <button className="btn-prim" type="submit">
            Update profile
          </button>
        </div>
      </Form>
    </Modal>
  )
}
export default EditGuardianProfile
