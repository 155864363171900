const actions = {
  ADD_FAMILY_DATA: 'ADD_FAMILY_DATA',
  ADD_FAMILY_MEMBERS: 'ADD_FAMILY_MEMBERS',
  DELETE_FAMILY_MEMBERS_DATA: 'DELETE_FAMILY_MEMBERS_DATA',
  FAMILY_MODAL_VISIBLE: 'FAMILY_MODAL_VISIBLE',
  DELETE_FAMILY_DATA: 'DELETE_FAMILY_DATA',
  CURRENT_FAMILY_DATA: 'CURRENT_FAMILY_DATA',
  CURRENT_AVAILABLE_MENTORS: 'CURRENT_AVAILABLE_MENTORS',

  GET_FAMILIES: 'GET_FAMILIES',
  GET_FAMILIES_SUCCESS: 'GET_FAMILIES_SUCCESS',
  GET_FAMILIES_FAILURE: 'GET_FAMILIES_FAILURE',
  GET_FAMILY: 'GET_FAMILY',
  GET_FAMILY_SUCCESS: 'GET_FAMILY_SUCCESS',
  GET_FAMILY_FAILURE: 'GET_FAMILY_FAILURE',
  GET_FAMILY_GUARDIAN: 'GET_FAMILY_GUARDIAN',
  GET_FAMILY_GUARDIAN_SUCCESS: 'GET_FAMILY_GUARDIAN_SUCCESS',
  GET_FAMILY_GUARDIAN_FAILURE: 'GET_FAMILY_GUARDIAN_FAILURE',
  UPDATE_FAMILY: 'UPDATE_FAMILY',
  UPDATE_FAMILY_SUCCESS: 'UPDATE_FAMILY_SUCCESS',
  UPDATE_FAMILY_FAILURE: 'UPDATE_FAMILY_FAILURE',

  //Authorized adult actions

  AUTHORIZED_ADULT_MODAL_VISIBLE: 'AUTHORIZED_ADULT_MODAL_VISIBLE',
  GET_AUTHORIZED_ADULT: 'GET_AUTHORIZED_ADULT',
  GET_AUTHORIZED_ADULT_SUCCESS: 'GET_AUTHORIZED_ADULT_SUCCESS',
  GET_AUTHORIZED_ADULT_FAILURE: 'GET_AUTHORIZED_ADULT_FAILURE',
  ADD_AUTHORIZED_ADULT: 'ADD_AUTHORIZED_ADULT',
  ADD_AUTHORIZED_ADULT_SUCCESS: 'ADD_AUTHORIZED_ADULT_SUCCESS',
  ADD_AUTHORIZED_ADULT_FAILURE: 'ADD_AUTHORIZED_ADULT_FAILURE',
  GET_FAMILY_KIDS: 'GET_FAMILY_KIDS',
  GET_FAMILY_KIDS_SUCCESS: 'GET_FAMILY_KIDS_SUCCESS',
  GET_FAMILY_KIDS_FAILURE: 'GET_FAMILY_KIDS_FAILURE',
  AUTHORIZED_ADULTS_ASSIGN_KIDS: 'AUTHORIZED_ADULTS_ASSIGN_KIDS',
  AUTHORIZED_ADULTS_ASSIGN_KIDS_SUCCESS:
    'AUTHORIZED_ADULTS_ASSIGN_KIDS_SUCCESS',
  AUTHORIZED_ADULTS_ASSIGN_KIDS_FAILURE:
    'AUTHORIZED_ADULTS_ASSIGN_KIDS_FAILURE',

  AUTHORIZED_ADULT_UPDATE: 'AUTHORIZED_ADULT_UPDATE',
  AUTHORIZED_ADULT_UPDATE_SUCCESS: 'AUTHORIZED_ADULT_UPDATE_SUCCESS',
  AUTHORIZED_ADULT_UPDATE_FAILURE: 'AUTHORIZED_ADULT_UPDATE_FAILURE',
  DELETE_AUTHORIZED_ADULT: ' DELETE_AUTHORIZED_ADULT',
  DELETE_AUTHORIZED_ADULT_SUCCESS: ' DELETE_AUTHORIZED_ADULT_SUCCESS',
  DELETE_AUTHORIZED_ADULT_FAILURE: ' DELETE_AUTHORIZED_ADULT_FAILURE',

  //Guardian actions
  CREATE_GUARDIAN: ' CREATE_GUARDIAN',
  ADD_GUARDIAN: ' ADD_GUARDIAN',
  ADD_GUARDIAN_SUCCESS: 'ADD_GUARDIAN_SUCCESS',
  ADD_GUARDIAN_FAILURE: 'ADD_GUARDIAN_FAILURE',
  GET_GURADINA: 'GET_GURADINA',
  GET_GURADINA_SUCCESS: 'GET_GURADINA_SUCCESS',
  GET_GURADINA_FAILURE: 'GET_GURADINA_FAILURE',

  UPDATE_FAMILY_STATUS: 'UPDATE_FAMILY_STATUS',
  UPDATE_FAMILY_STATUS_SUCCESS: 'UPDATE_FAMILY_STATUS_SUCCESS',
  UPDATE_FAMILY_STATUS_FAILURE: 'UPDATE_FAMILY_STATUS_FAILURE',

  ADD_KID: 'ADD_KID',
  ADD_KID_SUCCESS: 'ADD_KID_SUCCESS',
  ADD_KID_FAILURE: 'ADD_KID_FAILURE',

  DELETE_KID: 'DELETE_KID',
  DELETE_KID_SUCCESS: 'DELETE_KID_SUCCESS',
  DELETE_KID_FAILURE: 'DELETE_KID_FAILURE',

  UPDATE_KID: 'UPDATE_KID',
  UPDATE_KID_SUCCESS: 'UPDATE_KID_SUCCESS',
  UPDATE_KID_FAILURE: 'UPDATE_KID_FAILURE',

  GET_KID_DETAIL: 'GET_KID_DETAIL',
  GET_KID_DETAIL_SUCCESS: 'GET_KID_DETAIL_SUCCESS',
  GET_KID_DETAIL_FAILURE: 'GET_KID_DETAIL_FAILURE',

  ASSIGN_KIDS_TO_ADULT: 'ASSIGN_KIDS_TO_ADULT',
  ASSIGN_KIDS_TO_ADULT_SUCCESS: 'ASSIGN_KIDS_TO_ADULT_SUCCESS',
  ASSIGN_KIDS_TO_ADULT_FAILURE: 'ASSIGN_KIDS_TO_ADULT_FAILURE',

  ADD_NOTE_SUCCESS: 'ADD_NOTE_SUCCESS',
  CURRENT_PAGE: 'CURRENT_PAGE',
  DEFAULT_ACTIVE_PAGE: 'DEFAULT_ACTIVE_PAGE',

  getFamilies: params => ({
    type: actions.GET_FAMILIES,
    params,
  }),

  getFamiliesSuccess: payload => ({
    type: actions.GET_FAMILIES_SUCCESS,
    payload,
  }),

  getFamiliesFailure: () => ({
    type: actions.GET_FAMILIES_FAILURE,
  }),

  getFamily: (payload, params) => ({
    type: actions.GET_FAMILY,
    payload,
  }),

  getFamilySuccess: payload => ({
    type: actions.GET_FAMILY_SUCCESS,
    payload,
  }),

  getFamilyFailure: () => ({
    type: actions.GET_FAMILY_FAILURE,
  }),

  getFamilyGuardian: payload => ({
    type: actions.GET_FAMILY_GUARDIAN,
    payload,
  }),

  getFamilyGuardianSuccess: payload => ({
    type: actions.GET_FAMILY_GUARDIAN_SUCCESS,
    payload,
  }),

  getFamilyGuardianFailure: () => ({
    type: actions.GET_FAMILY_GUARDIAN_FAILURE,
  }),

  updateFamily: (payload, familyId) => ({
    type: actions.UPDATE_FAMILY,
    payload,
    familyId,
  }),

  updateFamilySuccess: payload => ({
    type: actions.UPDATE_FAMILY_SUCCESS,
    payload,
  }),

  updateFamilyFailure: () => ({
    type: actions.UPDATE_FAMILY_FAILURE,
  }),

  updateFamilyStatus: (payload, params) => ({
    type: actions.UPDATE_FAMILY_STATUS,
    payload,
    params,
  }),

  updateFamilyStatusSuccess: payload => ({
    type: actions.UPDATE_FAMILY_STATUS_SUCCESS,
    payload,
  }),

  updateFamilyStatusFailure: () => ({
    type: actions.UPDATE_FAMILY_STATUS_FAILURE,
  }),

  //Authorized adult

  getAuthorizedAdult: payload => ({
    type: actions.GET_AUTHORIZED_ADULT,
    payload,
  }),

  getAuthorizedAdultSuccess: payload => ({
    type: actions.GET_AUTHORIZED_ADULT_SUCCESS,
    payload,
  }),

  getAuthorizedAdultFailure: () => ({
    type: actions.GET_AUTHORIZED_ADULT_FAILURE,
  }),

  addAuthorizedAdult: (payload, familyId) => ({
    type: actions.ADD_AUTHORIZED_ADULT,
    payload,
    familyId,
  }),

  addAuthorizedAdultSuccess: payload => ({
    type: actions.ADD_AUTHORIZED_ADULT_SUCCESS,
    payload,
  }),

  addAuthorizedAdultFailure: () => ({
    type: actions.ADD_AUTHORIZED_ADULT_FAILURE,
  }),

  deleteAuthorizedAdult: adultId => ({
    type: actions.DELETE_AUTHORIZED_ADULT,
    adultId,
  }),

  deleteAuthorizedAdultSuccess: payload => ({
    type: actions.DELETE_AUTHORIZED_ADULT_SUCCESS,
    payload,
  }),

  deleteAuthorizedAdultFailure: () => ({
    type: actions.DELETE_AUTHORIZED_ADULT_FAILURE,
  }),

  getFamilyKids: payload => ({
    type: actions.GET_FAMILY_KIDS,
    payload,
  }),

  getFamilyKidsSuccess: payload => ({
    type: actions.GET_FAMILY_KIDS_SUCCESS,
    payload,
  }),

  getFamilyKidsFailure: () => ({
    type: actions.GET_FAMILY_KIDS_FAILURE,
  }),

  authorizedAdultsAssignKids: payload => ({
    type: actions.AUTHORIZED_ADULTS_ASSIGN_KIDS,
    payload,
  }),

  authorizedAdultsAssignKidsSuccess: payload => ({
    type: actions.AUTHORIZED_ADULTS_ASSIGN_KIDS_SUCCESS,
    payload,
  }),

  authorizedAdultsAssignKidsFailure: () => ({
    type: actions.AUTHORIZED_ADULTS_ASSIGN_KIDS_FAILURE,
  }),

  authorizedAdultUpdate: (payload, adultId, familyId) => ({
    type: actions.AUTHORIZED_ADULT_UPDATE,
    payload,
    adultId,
    familyId,
  }),

  authorizedAdultUpdateSuccess: payload => ({
    type: actions.AUTHORIZED_ADULT_UPDATE_SUCCESS,
    payload,
  }),

  authorizedAdultUpdateFailure: () => ({
    type: actions.AUTHORIZED_ADULT_UPDATE_FAILURE,
  }),

  //Gurdian

  createGuardian: payload => ({
    type: actions.CREATE_GUARDIAN,
    payload,
  }),

  addGuardian: (payload, familyId) => ({
    type: actions.ADD_GUARDIAN,
    payload,
    familyId,
  }),

  addGuardianSuccess: payload => ({
    type: actions.ADD_GUARDIAN_SUCCESS,
    payload,
  }),

  addGuardianFailure: () => ({
    type: actions.ADD_GUARDIAN_FAILURE,
  }),

  getGuardian: guardianId => ({
    type: actions.GET_GURADINA,
    guardianId,
  }),

  getGuardianSuccess: payload => ({
    type: actions.GET_GURADINA_SUCCESS,
    payload,
  }),

  getGuardianFailure: () => ({
    type: actions.GET_GURADINA_FAILURE,
  }),

  modalVisible: (payload = false) => ({
    type: actions.FAMILY_MODAL_VISIBLE,
    payload,
  }),

  addFamily: payload => ({
    type: actions.ADD_FAMILY_DATA,
    payload,
  }),

  addKid: (payload, familyId) => ({
    type: actions.ADD_KID,
    payload,
    familyId,
  }),

  addKidSuccess: payload => ({
    type: actions.ADD_KID_SUCCESS,
    payload,
  }),

  addKidFailure: payload => ({
    type: actions.ADD_KID_FAILURE,
  }),

  updateKid: (payload, kidId, familyId) => ({
    type: actions.UPDATE_KID,
    payload,
    kidId,
    familyId,
  }),

  updateKidSuccess: payload => ({
    type: actions.UPDATE_KID_SUCCESS,
    payload,
  }),

  updateKidFailure: () => ({
    type: actions.UPDATE_KID_FAILURE,
  }),

  deleteKid: (payload, kidId) => ({
    type: actions.DELETE_KID,
    payload,
    kidId,
  }),

  deleteKidSuccess: payload => ({
    type: actions.DELETE_KID_SUCCESS,
    payload,
  }),

  deleteKidFailure: () => ({
    type: actions.DELETE_KID_FAILURE,
  }),

  getKidDetailData: kidId => ({
    type: actions.GET_KID_DETAIL,
    kidId,
  }),

  getKidDetailDataSuccess: payload => ({
    type: actions.GET_KID_DETAIL_SUCCESS,
    payload,
  }),

  getKidDetailDataFailure: () => ({
    type: actions.GET_KID_DETAIL_FAILURE,
  }),

  assignKidsToAdult: (adultId, payload) => ({
    type: actions.ASSIGN_KIDS_TO_ADULT,
    adultId,
    payload,
  }),

  assignKidsToAdultSuccess: payload => ({
    type: actions.ASSIGN_KIDS_TO_ADULT_SUCCESS,
    payload,
  }),

  assignKidsToAdultFailure: () => ({
    type: actions.ASSIGN_KIDS_TO_ADULT_FAILURE,
  }),

  addNoteSuccess: () => ({
    type: actions.ADD_NOTE_SUCCESS,
  }),
  currentPage: payload => ({
    type: actions.CURRENT_PAGE,
    payload,
  }),
  defaultActivePage: payload => ({
    type: actions.DEFAULT_ACTIVE_PAGE,
    payload,
  }),
}

export default actions
