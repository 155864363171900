import React from 'react'
import { Card, Row, Col, Button, Avatar } from 'antd'
import { getNameSignature } from 'helper/utility'
import { EnvironmentOutlined } from '@ant-design/icons'
import './ItemCard.scss'
import { convertToLocal } from 'helper/utility'
import Line from 'assets/Images/dotedLine.svg'
import noImage from 'assets/Images/noImage.jpg'
import moment from 'moment'
const ItemCard = ({ item, hidCols }) => {
  const hasLocations = item?.locations && item.locations.length > 0
  // const timeAgo = timestamp => {
  //   return moment(timestamp).fromNow()
  // }
  const timeAgo = utcTimestamp => {
    return moment.utc(utcTimestamp).local().fromNow()
  }
  return (
    <Card className="item-card">
      <Row>
        <Col span={4}>
          {item.photo === null ? (
            <div>
              <img
                src={noImage}
                alt=""
                className="item-image"
                // style={{ width: '230px', height: '130px', borderRadius: '8px' }}
              />
            </div>
          ) : (
            <img
              src={item?.photo?.post}
              alt={item.title}
              className="item-image"
              // style={{ width: '230px', height: '130px', borderRadius: '8px' }}
            />
          )}
        </Col>
        <Col span={10}>
          <div className="item-details">
            {hidCols.name && <h2 className="item-title">{item.name}</h2>}
            {hidCols.location && (
              <p className="item-location">
                <EnvironmentOutlined className="mr-5" />
                {hasLocations
                  ? `${item.locations[0].title}, ${item.locations[0].country}`
                  : 'Location not available'}
              </p>
            )}
            {/* {hasLocations ? (
              <p className="item-location">
                <EnvironmentOutlined className="mr-5" />
                {`${item.locations[0].title}, ${item.locations[0].country}`}
              </p>
            ) : (
              <p className="item-location">
                <EnvironmentOutlined className="mr-5" />
                Location not available
              </p>
            )} */}
            {/* <p className="item-location ">
              <EnvironmentOutlined className="mr-5" />
              {`${item?.locations[0].title}, ${item.locations[0].country}`}
            </p> */}
            <div className="flex">
              {hidCols.Quantity && item.totalQuantity && (
                <p className="item-price">{item?.totalQuantity} unit</p>
              )}
              {hidCols.Price && item.price && (
                <p className="priceStyle">
                  <span
                    style={{ display: 'inline', marginLeft: '5px' }}
                    className="dollarRate"
                  >
                    ${item.price}
                  </span>
                </p>
              )}
              {/* {item.price && (
                <p className="priceStyle ">
                  <span
                    style={{ display: 'inline', marginLeft: '5px' }}
                    className="dollarRate"
                  >
                    ${item.price}
                  </span>
                </p>
              )} */}
            </div>
            <div className="item-buttons">
              {item.group.map((item, index) => {
                return (
                  <div key={index}>
                    <Button className="item-button"> {item}</Button>
                  </div>
                )
              })}
              {/* <Button className="item-button">Banrec</Button>
              <Button className="item-button">Group title</Button> */}
              {/* <img src={Line} alt="line" /> */}
            </div>
            {/* <p className="item-updated">Updated: {item.updated}</p> */}
          </div>
        </Col>
        <Col span={10}>
          <div className="item-actions">
            {/* <Button className="item-button" type="text">Banrec</Button>
            <Button className="item-button" type="text">Group title</Button> */}

            <div>
              <p className="updateTime">
                <span className="item-updated">Updated:</span>

                {/* {convertToLocal(item.updatedAt, 'h:mm A')} */}
                {timeAgo(item.updatedAt)}
                {/* {item.updatedAt} */}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default ItemCard

// import React from 'react'
// import { Card, Row, Col, Button } from 'antd'
// import { EnvironmentOutlined } from '@ant-design/icons'
// import './ItemCard.scss'
// import noImage from 'assets/Images/noImage.jpg'
// import moment from 'moment'

// const ItemCard = ({ item, hidCols }) => {
//   const hasLocations = item?.locations && item.locations.length > 0

//   const timeAgo = utcTimestamp => {
//     return moment.utc(utcTimestamp).local().fromNow()
//   }

//   return (
//     <Card>
//       <Row gutter={[16, 16]}>
//         <Col xs={24} sm={24} md={5} lg={3}>
//           <div>
//             {item.photo === null ? (
//               <img src={noImage} alt="" className="item-image" />
//             ) : (
//               <img
//                 src={item?.photo?.post}
//                 alt={item.title}
//                 className="item-image"
//               />
//             )}
//           </div>
//         </Col>
//         <Col xs={24} sm={24} md={12} lg={13}>
//           <div className="item-details">
//             {hidCols.name && <h2 className="item-title">{item.name}</h2>}
//             {hidCols.location && (
//               <p className="item-location">
//                 <EnvironmentOutlined className="mr-5" />
//                 {hasLocations
//                   ? `${item.locations[0].title}, ${item.locations[0].country}`
//                   : 'Location not available'}
//               </p>
//             )}
//             <div className="flex">
//               {hidCols.Quantity && item.totalQuantity && (
//                 <p className="item-price">{item?.totalQuantity} unit</p>
//               )}
//               {hidCols.Price && item.price && (
//                 <p className="priceStyle">
//                   <span className="dollarRate">${item.price}</span>
//                 </p>
//               )}
//             </div>
//             <div className="item-buttons">
//               {item.group.map((groupItem, index) => (
//                 <Button key={index} className="item-button">
//                   {groupItem}
//                 </Button>
//               ))}
//             </div>
//           </div>
//         </Col>
//         <Col xs={24} sm={24} md={6} lg={6}>
//           <div className="item-actions">
//             <p className="updateTime">
//               <span className="item-updated">Updated:</span>{' '}
//               {timeAgo(item.updatedAt)}
//             </p>
//           </div>
//         </Col>
//       </Row>
//     </Card>
//   )
// }

// export default ItemCard
