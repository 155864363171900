const actions = {
  MENTORS_MODAL_VISIBLE: "MENTORS_MODAL_VISIBLE",
  CURRENT_MENTOR_DATA: "CURRENT_MENTOR_DATA",

  CREATE_MENTOR: "CREATE_MENTOR",
  CREATE_MENTOR_SUCCESS: "CREATE_MENTOR_SUCCESS",
  CREATE_MENTOR_FAILURE: "CREATE_MENTOR_FAILURE",

  GET_MENTORS: "GET_MENTORS",
  GET_MENTORS_SUCCESS: "GET_MENTORS_SUCCESS",
  GET_MENTORS_FAILURE: "GET_MENTORS_FAILURE",

  UPDATE_MENTOR_STATUS: "UPDATE_MENTOR_STATUS",
  UPDATE_MENTOR_STATUS_SUCCESS: "UPDATE_MENTOR_STATUS_SUCCESS",
  UPDATE_MENTOR_STATUS_FAILURE: "UPDATE_MENTOR_STATUS_FAILURE",

  GET_MENTOR: "GET_MENTOR",
  GET_MENTOR_SUCCESS: "GET_MENTOR_SUCCESS",
  GET_MENTOR_FAILURE: "GET_MENTOR_FAILURE",

  UPDATE_MENTOR_SKILLS: "UPDATE_MENTOR_SKILLS",
  UPDATE_MENTOR_SKILLS_SUCCESS: "UPDATE_MENTOR_SKILLS_SUCCESS",
  UPDATE_MENTOR_SKILLS_FAILURE: "UPDATE_MENTOR_SKILLS_FAILURE",

  GET_MENTOR_ANALYTICS: "GET_MENTOR_ANALYTICS",
  GET_MENTOR_ANALYTICS_SUCCESS: "GET_MENTOR_ANALYTICS_SUCCESS",
  GET_MENTOR_ANALYTICS_FAILURE: "GET_MENTOR_ANALYTICS_FAILURE",

  GET_MENTOR_REVIEWS: "GET_MENTOR_REVIEWS",
  GET_MENTOR_REVIEWS_SUCCESS: "GET_MENTOR_REVIEWS_SUCCESS",
  GET_MENTOR_REVIEWS_FAILURE: "GET_MENTOR_REVIEWS_FAILURE",

  modalVisible: (payload = false) => ({
    type: actions.MENTORS_MODAL_VISIBLE,
    payload,
  }),

  createMentor: (payload) => ({
    type: actions.CREATE_MENTOR,
    payload,
  }),

  createMentorSuccess: (payload) => ({
    type: actions.CREATE_MENTOR_SUCCESS,
    payload,
  }),

  createMentorFailure: () => ({
    type: actions.CREATE_MENTOR_FAILURE,
  }),

  getMentorsRequest: (params) => ({
    type: actions.GET_MENTORS,
    params
  }),

  getMentorsSuccess: (payload) => ({
    type: actions.GET_MENTORS_SUCCESS,
    payload,
  }),

  getMentorsFailure: () => ({
    type: actions.GET_MENTORS_FAILURE,
  }),

  getMentor: (payload, id) => ({
    type: actions.GET_MENTOR,
    payload,
    id,
  }),

  getMentorSuccess: (payload) => ({
    type: actions.GET_MENTOR_SUCCESS,
    payload,
  }),

  getMentorFailure: () => ({
    type: actions.GET_MENTOR_FAILURE,
  }),

  getMentorAnalytics: (payload, id) => ({
    type: actions.GET_MENTOR_ANALYTICS,
    payload,
    id,
  }),

  getMentorAnalyticsSuccess: (payload) => ({
    type: actions.GET_MENTOR_ANALYTICS_SUCCESS,
    payload,
  }),

  getMentorAnalyticsFailure: () => ({
    type: actions.GET_MENTOR_ANALYTICS_FAILURE,
  }),


  getMentorReviews: (payload,id) => ({
    type: actions.GET_MENTOR_REVIEWS, payload, id
  }),

  getMentorReviewsSuccess: (payload) => ({
    type: actions.GET_MENTOR_REVIEWS_SUCCESS, payload,
  }),

  getMentorReviewsFailure: () => ({
    type: actions.GET_MENTOR_REVIEWS_FAILURE,
  }),


  updateMentorSkills: (payload, mentorId) => ({
    type: actions.UPDATE_MENTOR_SKILLS,
    payload,
    mentorId,
  }),

  updateMentorSkillsSuccess: (payload) => ({
    type: actions.UPDATE_MENTOR_SKILLS_SUCCESS,
    payload,
  }),

  updateMentorSkillsFailure: () => ({
    type: actions.UPDATE_MENTOR_SKILLS_FAILURE,
  }),

  updateMentorStatus: (payload, params) => ({
    type: actions.UPDATE_MENTOR_STATUS,
    payload,
    params,
  }),

  updateMentorStatusSuccess: (payload) => ({
    type: actions.UPDATE_MENTOR_STATUS_SUCCESS,
    payload,
  }),

  updateMentorStatusFailure: () => ({
    type: actions.UPDATE_MENTOR_STATUS_FAILURE,
  }),



};

export default actions;
