import React, { useState } from 'react'
import { Form, Input, Select } from 'antd'

interface ConditionsToSendProps {
  setIsCustomTime: React.Dispatch<React.SetStateAction<boolean>>
}

const ConditionsToSend: React.FC<ConditionsToSendProps> = ({
  setIsCustomTime,
}) => {
  const hanldeChange = (value: string) => {
    if (value === 'customTimeToSend') {
      setIsCustomTime(true)
    } else {
      setIsCustomTime(false)
    }
  }
  return (
    <div className="flex gap-16px align-center w-full">
      <div style={{ width: '20%' }}>
        <Form.Item className="form-control" name="timeQuantity">
          <Input type="number" className="custom-input-field" placeholder="0" />
        </Form.Item>
      </div>

      <div style={{ width: '25%' }}>
        <Form.Item className="pointer selector-input" name="timePeriod">
          <Select
            className="select-input-field type-select-field"
            placeholder="Select"
            optionLabelProp="label"
            options={[
              {
                value: 'mins',
                label: 'Minutes',
              },
              {
                value: 'days',
                label: 'Days',
              },
              {
                value: 'weeks',
                label: 'Weeks',
              },
            ]}
          />
        </Form.Item>
      </div>

      <div style={{ width: '25%' }}>
        <Form.Item className="pointer selector-input" name="timeFrame">
          <Select
            className="select-input-field type-select-field"
            placeholder="Select"
            optionLabelProp="label"
            options={[
              {
                value: 'before',
                label: 'Before',
              },
              {
                value: 'after',
                label: 'After',
              },
            ]}
          />
        </Form.Item>
      </div>

      <div style={{ width: '30%' }}>
        <Form.Item className="pointer selector-input" name="conditionType">
          <Select
            className="select-input-field type-select-field"
            placeholder="Select"
            optionLabelProp="label"
            onChange={hanldeChange}
            options={[
              {
                value: 'event-registration',
                label: 'Event registration',
              },
              {
                value: 'registration-edit',
                label: 'Registration edit',
              },
              {
                value: 'ticket-start-date',
                label: 'Start date',
              },
              {
                value: 'ticket-end-date',
                label: 'End date',
              },
              {
                value: 'customTimeToSend',
                label: 'Custom Time',
              },
            ]}
          />
        </Form.Item>
      </div>
    </div>
  )
}

export default ConditionsToSend
