import KidAuthorizedAdults from './KidAuthorizedAdults'
import KidGeneralDetails from './KidGeneralDetails'

const KidBasicInfo = ({ data }: any) => {
  return (
    <div className="bg-grey  padding-top-10 ">
      <div className="grid grid-50 ">
        <KidGeneralDetails GeneralData={data} />
        <KidAuthorizedAdults />
      </div>
    </div>
  )
}
export default KidBasicInfo
