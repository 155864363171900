import {
  Button,
  Col,
  Form,
  Layout,
  Radio,
  Row,
  Select,
  Space,
  Avatar,
  Input,
  AutoComplete,
  DatePicker,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import familyAction from "../../../../../Redux/Family/action";
import categoryAction from "../../../../../Redux/Events/action";
import MapModal from "../../../../../helper/Map/MapModal";
import AddFamily from "../../../../../assets/Images/AddFamily.svg";
import Item from "antd/lib/list/Item";

const GeneralDetails = ({
  handleNext,
  familyData,
  eventCategoryData,
  kids,
  data,
}: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [sessionType, setSessionType] = useState(
    data?.isSessionOnline ? "online" : "inperson"
  );
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [familyVal, setFamilyVal] = useState<any>({ _id: null, name: "" });
  const [newFamilyName, setNewFamilyName] = useState("");
  const [isNewFamily, setIsNewFamily] = useState(false);
  const [addNewFamilyField, setAddNewFamilyField] = useState(false);
  const [kidVal, setKidVal] = useState({ _id: "", name: "" });
  const [categoryVal, setCategoryVal] = useState({ _id: "", name: "" });
  const [mapData, setMapModalData] = useState<any>(data?.address || null);

  useEffect(() => {
    dispatch({
      type: familyAction.GET_FAMILIES,
    });
    dispatch({
      type: categoryAction.GET_EVENT_CATEGORIES,
    });
    if(data?.family) {
      form.setFieldsValue({ familyIds: data?.family?.name });
      setFamilyVal(data?.family);
    }
    
  }, []);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const formSchema = {
    familyIds: data?.familyId ? data?.familyId : "",
    kidsIds: data?.kidsIds && data?.kidsIds[0] ? data?.kidsIds[0] : "",
    categorysId: data?.categoryId ? data?.categoryId : "",
    genderPreference: data?.genderPreference ? data?.genderPreference : "",
    location: data?.address ? data?.address : "",
    // sessionType: data?.isSessionOnline ? "online" : "inperson",
  };

  const handleFamily = (value: any) => {
    // console.log("handle:", value);
    if (value && value.key) {
      setIsNewFamily(false);
      dispatch({
        type: familyAction.GET_FAMILY_KIDS,
        payload: value.key,
      });
    } else {
      setIsNewFamily(true);
    }
  };
  const handleSessionType = (e: any) => {
    setSessionType(e.target.value);
  };

  const setFieldsValue = (data: any) => {
    if (data?.address) form.setFieldsValue({ location: data?.address });
  };

  const handleFilterFamily = (inputValue: any, option: any) => {
    // console.log("ch:",inputValue, option);
    // console.log("opt:",option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1)
    // setIsNewFamily(true);
    // if(option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1) {
    //   setIsNewFamily(false);
    // }
    return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
  };

  const onFinish = (values: any) => {
    // console.log("values session:", values);
    const dataValues = {
      familyId: familyVal._id,
      categoryId: values.categorysId,
      familyVal: familyVal,
      family: familyVal,
      kidVal: kidVal,
      categoryVal: categoryVal,
      kidsIds: [values.kidsIds],
      genderPreference: values.genderPreference,
      isSessionOnline: sessionType === "online" ? true : false,
      address: mapData,
      // childName: values.childName,
      // dob: values.dob,
      // email: values.email,
      // phoneNumber: values.phoneNumber
    };
    // console.log("valu:", dataValues);
    handleNext({
      ...dataValues,
    });
  };

  return (
    <Layout>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="modal-h-element">
          <Row align="middle">
            {
              <Col>
                <label className="input-label required-input">Family</label>
                <Form.Item
                  className="position-relative"
                  name="familyIds"
                  rules={[{ required: true, message: "Family is required" }]}
                >
                  <AutoComplete
                    className="select-family-field"
                    placeholder={"Enter family name"}
                    disabled={data?.categoryId}
                    // disabled={data?.familyVal?.name}
                    filterOption={(inputValue: any, option: any) =>
                      handleFilterFamily(inputValue, option)
                    }
                    onChange={(val: any, option: any) => (
                      form.setFieldsValue({ familyIds: val }),
                      setNewFamilyName(val),
                      handleFamily(option),
                      setFamilyVal(option)
                    )}
                    // defaultValue={familyVal?.name}
                    value={familyVal?.name}
                  >
                    {familyData.map((item: any) => (
                      <AutoComplete.Option
                        value={item.name}
                        key={item._id}
                        name={item.name}
                      >
                        <Space>
                          <Avatar size={30} src={item.photo?.post}></Avatar>
                          {item.name}
                        </Space>
                      </AutoComplete.Option>
                    ))}
                  </AutoComplete>
                  {isNewFamily && (
                    <div className="add-family-plus">
                      <Row>
                        <Space>
                          <img
                            alt="add_icon"
                            src={AddFamily}
                            onClick={() => (
                              setAddNewFamilyField(true), setIsNewFamily(false)
                            )}
                          />
                          <span className="new-family-txt">
                            Add "{newFamilyName}" family
                          </span>
                        </Space>
                      </Row>
                    </div>
                  )}
                </Form.Item>
              </Col>
            }
            {addNewFamilyField && (
              <>
                <Row
                  className="w-100"
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                  <Col span={12}>
                    <label className="input-label required-input">Email</label>
                    <Form.Item
                      name="email"
                      rules={[
                        { type: "email", message: "Enter valid email id" },
                        { required: true, message: "Email is required" },
                      ]}
                    >
                      <Input placeholder="Enter email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="input-label required-input">
                      Phone number
                    </label>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        { required: true, message: "Phone number is required" },
                      ]}
                    >
                      <Input type="number" placeholder="Enter phone number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  className="w-100"
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                >
                  <Col span={12}>
                    <label className="input-label required-input">
                      Children name
                    </label>
                    <Form.Item
                      name="childName"
                      rules={[
                        {
                          required: true,
                          message: "Children name is required",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Children name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <label className="input-label required-input">
                      Date of Birth
                    </label>
                    <Form.Item
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "Date of Birth is required",
                        },
                      ]}
                    >
                      <DatePicker className="w-100" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {!addNewFamilyField && !data?.categoryId && (
              <Col>
                <label className="input-label required-input">Children</label>
                <Form.Item
                  name="kidsIds"
                  rules={[{ required: true, message: "Children is required" }]}
                >
                  <Select
                    placeholder="Select"
                    className="custom-select-field"
                    onChange={(val: any, elm: any) => setKidVal(elm)}
                  >
                    {kids?.map((kid: any) => {
                      return (
                        <Select.Option key={kid._id} value={kid._id} name={kid}>
                          <Space>
                            <Avatar src={kid.photo?.post}></Avatar>{" "}
                            {kid.firstName}
                          </Space>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col>
              <label className="input-label required-input">Category</label>
              <Form.Item
                name="categorysId"
                rules={[{ required: true, message: "Category is required" }]}
              >
                <Select
                  placeholder="Select"
                  className="custom-select-field"
                  onChange={(val: any, elm: any) => setCategoryVal(elm)}
                >
                  {eventCategoryData &&
                    eventCategoryData.map((item: any) => (
                      <Option name="categoryId" value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Gender Preferences
              </label>
              <Form.Item
                name="genderPreference"
                rules={[
                  { required: true, message: "Gender preference is required" },
                ]}
              >
                <Select className="custom-select-field">
                  <Select.Option value="male" name="genderPreference">
                    Male
                  </Select.Option>
                  <Select.Option value="female" name="genderPreference">
                    Female
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              {/* <Row className="d-flex"> */}
              <label htmlFor="" className="input-label required-input">
                Session Type
              </label>
              <Radio.Group
                className="d-flex"
                value={sessionType}
                onChange={handleSessionType}
              >
                <Form.Item name="sessionType">
                  <Radio className="radio" value="inperson">
                    In person
                  </Radio>
                </Form.Item>
                <Form.Item>
                  <Radio className="radio" value="online">
                    Online
                  </Radio>
                </Form.Item>
              </Radio.Group>
              {/* </Row> */}
            </Col>
            <Col>
              <label className="input-label required-input">Location</label>
              <Form.Item
                name="location"
                rules={[{ required: true, message: "Location is required" }]}
              >
                <Input
                  onClick={() => {
                    setMapModalVisible(true);
                  }}
                  value={mapData ? mapData.city + "," + mapData.state : ""}
                ></Input>
                {mapModalVisible && (
                  <MapModal
                    mapData={mapData}
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Row justify="end">
              <Button className="stepper-button" htmlType="submit">
                Next
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  familyData: state.family.familyData,
  kids: state.family.kids,
  eventCategoryData: state.events.eventCategoryData,
});

export default connect(mapStateToProps)(GeneralDetails);
