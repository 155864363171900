import { Button, Dropdown, Menu, Space } from "antd";
import deleteSvg from "../assets/Images/delete_white.svg";
import pencilLine from "../assets/Images/PencilLine.svg";
import LockKey from "../assets/Images/LockKey.svg";
import { PlusOutlined } from "@ant-design/icons";
import organizerAction from "../Redux/Organizer/action";
import familyActions from "../Redux/Family/action";

import React from "react";
import { useDispatch } from "react-redux";
import AlertModal from "./AlertModal";

const ActionButton = ({ name, setModalVisible, setAlertModal }: any) => {
  const dispatch = useDispatch();

  const actionMenu = (
    <Menu>
      <Menu.Item key="changePassword">
        <Space>
          <img alt="img" src={LockKey}></img> Change Password
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Editprofile"
        onClick={() => {
          if (name === "organizer")
            dispatch({
              type: organizerAction.ORGANIZER_MODAL_VISIBLE,
              payload: true,
            });

          if ((name = "adult" || "guardian")) {
            setModalVisible(true);
          }
        }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          if ((name = "authorizedAdult")) {
            setAlertModal(true);
          }
        }}
      >
        <Space>
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={actionMenu}>
        <Button type="primary" icon={<PlusOutlined />} className="actionButton">
          Action
        </Button>
      </Dropdown>
    </div>
  );
};

export default ActionButton;
