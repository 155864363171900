import { useEffect, useRef, useState } from 'react'
import { getFilterList } from 'api/Activity'
import { Checkbox, Dropdown, Radio, Input } from 'antd'
import { Select } from 'antd'
import Down from 'helper/Icons/DownIcon'
import { useQuery } from '@tanstack/react-query'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Spinner from 'components/Spinner'
import {
  ArrowUp,
  CirclesThreePlus,
  MagnifyingGlass,
} from '@phosphor-icons/react'
const ActivityFilter = ({ setParms, parms }: any) => {
  const [orderBy, setOrderBy] = useState('desc')
  const [sortBy, setSortBy] = useState('')
  const getSortByCheckBoxes = () => [
    {
      label: 'Updated date',
      value: 'updatedAt',
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Quantity',
      value: 'totalQuantity',
    },
    {
      label: 'Min Level',
      value: 'minLevel',
    },
  ]

  const [selectedOrganization, setSelectedOrganization] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedElement, setSelectedElements] = useState([])

  const [open, setOpen] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [searchloc, setSearchloc] = useState('')

  // MoreFilter
  const [selectedGoals, setSelectedGoals] = useState([])
  const [selectedEnvironments, setSelectedEnvironments] = useState([])
  const [selectedTimes, setSelectedTimes] = useState([])
  const [selectedEnergyLevels, setSelectedEnergyLevels] = useState([])

  // Temporary state for the "More Filters" dropdown
  const [tempGoals, setTempGoals] = useState([])
  const [tempEnvironments, setTempEnvironments] = useState([])
  const [tempTimes, setTempTimes] = useState([])
  const [tempEnergyLevels, setTempEnergyLevels] = useState([])

  const handleDocumentClick = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const handleDropdownClick = e => {
    e.stopPropagation()
  }
  const { data, refetch } = useQuery(['getFilterList'], () => getFilterList(), {
    enabled: false,
  })
  useEffect(() => {
    refetch()
  }, [])
  const checkboxOrganization = data?.organizations?.map(item => ({
    label: (
      <div>
        <h4 className="text-14 ml-20px">{item.name}</h4>
      </div>
    ),
    value: item.id,
  }))
  const checkboxCategory = data?.activityCagegories?.map(item => ({
    label: (
      <div className="flex-center">
        {item.icon !== '' ? (
          <img src={item.icon} alt={item.name} className="w-30px" />
        ) : (
          <CirclesThreePlus size={32} />
        )}
        {/* <img src={item.icon} alt="icon" /> */}
        <h4 className="text-14 ml-20px">{item.name}</h4>
      </div>
    ),
    value: item._id,
  }))
  const checkboxElement = data?.highlightedElements?.map(item => ({
    label: (
      <div>
        <h4 className="text-14 ml-20px">{item.name}</h4>
      </div>
    ),
    value: item._id,
  }))
  const handleOrganizationChange = selectedValues => {
    setSelectedOrganization(selectedValues)
  }
  const handleCategoryChange = selectedValues => {
    setSelectedCategory(selectedValues)
  }
  const handleElementChange = selectedValues => {
    setSelectedElements(selectedValues)
  }
  const filteredCheckboxOrganization = checkboxOrganization?.filter(option =>
    option.label.props.children.props.children
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  //   const filteredCheckboxCategory = checkboxCategory?.filter(option =>
  //     option.label.props.children.props.children
  //       .toLowerCase()
  //       .includes(searchloc.toLowerCase()),
  //   )
  const filteredCheckboxCategory = checkboxCategory?.filter(option => {
    const labelText = option?.label?.props?.children?.[1]?.props?.children || ''
    return labelText.toLowerCase().includes(searchloc.toLowerCase())
  })
  const filteredCheckboxElement = checkboxElement?.filter(option => {
    const labelText = option?.label?.props?.children?.[1]?.props?.children || ''
    return labelText.toLowerCase().includes(searchTerm.toLowerCase())
  })
  const clear = (item, setFn) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const Organization: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <Checkbox.Group
                options={filteredCheckboxOrganization}
                className="category-checkbox"
                value={selectedOrganization}
                onChange={handleOrganizationChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('organizationIds', setSelectedOrganization)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedOrganization &&
                      setParms(prevParms => ({
                        ...prevParms,
                        organizationIds: selectedOrganization,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]
  const Category: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Checkbox.Group
                options={filteredCheckboxCategory}
                className="category-checkbox"
                value={selectedCategory}
                onChange={handleCategoryChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('categoryIds', setSelectedCategory)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedCategory &&
                      setParms(prevParms => ({
                        ...prevParms,
                        categoryIds: selectedCategory,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]
  const highlightedElements: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Checkbox.Group
                options={filteredCheckboxElement}
                className="category-checkbox"
                value={selectedElement}
                onChange={handleElementChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('highlightedElementIds', setSelectedElements)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedCategory &&
                      setParms(prevParms => ({
                        ...prevParms,
                        highlightedElementIds: selectedElement,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]
  const clearMoreFilter = (item, setFn) => {
    if (item === 'moreFilters') {
      setParms(prevParms => ({
        ...prevParms,
        goalId: [],
        environment: [],
        time: [],
        energyLevelId: [],
      }))
      setSelectedGoals([])
      setSelectedEnvironments([])
      setSelectedTimes([])
      setSelectedEnergyLevels([])

      setTempGoals([])
      setTempEnergyLevels([])
      setTempEnvironments([])
      setTempTimes([])
    } else if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const MoreFilters: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <div className="mb-10px">
                <h4 className="text-14  ">Goals</h4>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Goals"
                  style={{ width: '100%', marginTop: '10px' }}
                  value={tempGoals}
                  options={data?.goals?.map(item => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  onChange={setTempGoals}
                />
              </div>

              <div className="mb-10px">
                <h4 className="text-14 ">Preferred Environment</h4>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Environment"
                  style={{ width: '100%', marginTop: '10px' }}
                  value={tempEnvironments}
                  options={data?.environments?.map(item => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={setTempEnvironments}
                />
              </div>

              <div className="mb-10px">
                <h4 className="text-14 ">Time Needed</h4>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Time Needed"
                  style={{ width: '100%', marginTop: '10px' }}
                  value={tempTimes}
                  options={data?.times?.map(item => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={setTempTimes}
                />
              </div>

              <div>
                <h4 className="text-14 ">Energy Level</h4>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Energy Level"
                  style={{ width: '100%', marginTop: '10px' }}
                  value={tempEnergyLevels}
                  options={data?.energyLevels?.map(item => ({
                    label: (
                      <div className="flex-center">
                        {/* <img
                          src={item.icon}
                          alt={item.name}
                          className="w-20px mr-10px"
                        /> */}
                        {item.name}
                      </div>
                    ),
                    value: item._id,
                  }))}
                  onChange={setTempEnergyLevels}
                />
              </div>

              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clearMoreFilter('moreFilters', null)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    setSelectedGoals(tempGoals)
                    setSelectedEnvironments(tempEnvironments)
                    setSelectedTimes(tempTimes)
                    setSelectedEnergyLevels(tempEnergyLevels)
                    setParms(prevParms => ({
                      ...prevParms,
                      goalId: tempGoals,
                      environment: tempEnvironments,
                      time: tempTimes,
                      energyLevelId: tempEnergyLevels,
                    }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]

  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={getSortByCheckBoxes()}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={val => setSortBy(val.target.value)}
          />
          <div className="border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600">Sort Results</h2>
              <Radio.Group
                onChange={val => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                value={orderBy}
              >
                <Radio value="asc">Ascending</Radio>
                <Radio value="desc">Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('orderBy', setSortBy)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sortBy && orderBy
                ? setParms(prevParms => ({
                    ...prevParms,
                    sortBy: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : orderBy
                ? setParms(prevParms => ({
                    page: 1,
                  }))
                : setParms(prevParms => ({
                    sortBy: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  return (
    <div className="flex justify-between align-center flex-wrap">
      <div
        className="flex align-center flex-wrap mt-20px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
        <Dropdown
          menu={{
            items: Organization,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 2}
          overlayStyle={{ width: '280px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 2 ? 0 : 2)
            }}
          >
            Organization <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{ items: Category }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10"
          open={open === 3}
          overlayStyle={{ width: '250px' }}
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 3 ? 0 : 3)
            }}
          >
            Category <Down />
          </button>
        </Dropdown>

        <Dropdown
          menu={{ items: highlightedElements }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10"
          open={open === 4}
          overlayStyle={{ width: '250px' }}
        >
          <button
            className={` ${
              open === 4 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 4 ? 0 : 4)
            }}
          >
            Highlighted Elements <Down />
          </button>
        </Dropdown>

        <Dropdown
          menu={{ items: MoreFilters }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10"
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 5 ? 0 : 5)
            }}
          >
            More Filter <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: SortBy,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 6}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 6 ? 'filter-btn-click' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(6)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            Name
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}
export default ActivityFilter
