import { Button, Col, Row, Select } from "antd";
import React from "react";
import plusBlue from "../../../../assets/Images/plusBlue.svg";
import SearchBar from "../../../../helper/SearchBar";

const SpecificTab = ({setDefaultTabKey, setModalVisible}:any) => {
  const { Option } = Select;
  const allLocations = [
    "Indoor",
    "Outdoor"
  ];
  const allAges = [
    "1-3 y.o.",
    "4-6 y.o.",
    "7-9 y.o.",
    "10-12 y.o.",
  ];
  const allTypes = [
    "Social",
    "Physical",
    "Cognitive"
  ];
  const data = [
    "Activity 1",
    "Activity 2",
    "Activity 3",
    "Activity 4",
    "Activity 5",
  ];

  return (
    <div>
      <div style={{ height: "400px", marginTop: "20px" }}>      
        <SearchBar />
        <Row style={{ marginTop: "20px" }}>
          <Col span={6}>
            <Select placeholder="Locations: All">
              {allLocations.map((item)=><Option>{item}</Option>)}
            </Select>
          </Col>
          <Col span={6}>
            <Select placeholder="Ages: All">{allAges.map((item)=><Option>{item}</Option>)}</Select>
          </Col>
          <Col span={6}>
            <Select placeholder="Types: All">{allTypes.map((item)=><Option>{item}</Option>)}</Select>
          </Col>
        </Row>
        {data.map((item: any) => {
          return (
            <Row justify="space-between" style={{ marginTop: "20px" }}>
              <Col span={20}>
                {" "}
                <h4>
                  <b className="b2">{item}</b>{" "}
                </h4>
              </Col>
              <Col span={4}>
                <Button className="primary-transparent br-8" onClick={()=>(setModalVisible(false),setDefaultTabKey('2'))}>Views</Button>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row justify="space-between" align="middle">
        <h5 className="text-primary">
          <b className="b2"><img src={plusBlue}></img> Create new activity</b>
        </h5>
        <Button className="stepper-button">Next</Button>
      </Row>
    </div>
  );
};

export default SpecificTab;
