/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import AppLayout from '../../components/layout/layout'
import { Tabs } from 'antd'
import MentorsModel from '../../components/modals/newMentorsModal'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Statusmodal from '../../helper/Statusmodal'
import MentorTable from './Table/MentorTable'
import {
  changeMentorStatus,
  deleteMentors,
  getMentors,
} from '../../api/mentors'
import Spinner from '../../components/Spinner'
import PageNameHeader from './Headers/PageNameHeader'
import type { SorterResult } from 'antd/es/table/interface'
import { GetRequestParams, RequestParms } from '../../types/globalTypes'
import ActionHeaders from './Headers/ActionsHeader'
import { forceRefetch } from '../../helper/utility'

const { TabPane } = Tabs
const Mentors = () => {
  const [activeData, setActiveData] = useState<any[]>([])
  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [newMentor, setNewMentor] = useState(false)
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [selectedItem, setSelectedItem] = useState()
  const [parms, setParms] = useState<RequestParms & GetRequestParams>({
    page: 1,
    status: 'active',
    limit: 10,
  })
  const { isLoading, data, refetch } = useQuery(
    ['getMentors', parms],
    () => getMentors(parms),
    {
      enabled: false,
    },
  )
  const queryClient = useQueryClient()
  function callback(key: any) {
    if (key == 1) {
      setParms({ ...parms, status: 'active', page: 1 })
    } else if (key == 2) {
      setParms({ ...parms, status: 'inactive', page: 1 })
    } else if (key == 3) {
      setParms({ ...parms, status: 'archived', page: 1 })
    }
  }
  useEffect(() => {
    refetch()
  }, [parms])
  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await changeMentorStatus(params, payload)
    },
    onSuccess: () => {
      forceRefetch('getMentors', queryClient)
      refetch()
    },
  })

  const mutation = useMutation({
    mutationFn: async (id: string) => {
      return await deleteMentors(id)
    },
    onSuccess: () => {
      forceRefetch('getMentors', queryClient)
      refetch()
    },
  })
  const handleDelete = (id: string) => {
    mutation.mutate(id)
  }
  const alertModalData = {
    heading: 'Status',
    message1: 'Status Changed',
  }
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  })

  const handleSearch = (query: string) => {
    setParms({
      ...parms,
      searchQuery: query,
    })
  }
  const handleSort = (
    sortInfo: 'createdAt' | 'updatedAt' | '-createdAt' | 'name' | 'email',
  ) => {
    setParms({
      ...parms,
      sort: sortInfo,
    })
  }

  const deleteMultipleRecords = () => {
    let _data = [...activeData]
    selectedRows.forEach((row: any) => {
      _data = _data.filter(t => t.personalDetails.id !== row.key)
    })
    setActiveData(_data)
  }
  const editMentorModal = (item: any) => {
    setNewMentor(true)
    setSelectedItem(item)
  }
  return (
    <AppLayout>
      <PageNameHeader
        Rows={selectedRows}
        setAlert={setAlertModalVisible}
        data={activeData}
        fullData={data?.records}
        deleteMulti={deleteMultipleRecords}
        newMentor={setNewMentor}
      />
      <Row>
        <Col style={{ width: '100%' }}>
          <Tabs className="orgn-tabs" defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Active" key="1" style={{}}>
              <ActionHeaders
                setCols={setHiddenColumns}
                hidCols={hiddenColumns}
                search={handleSearch}
                sort={handleSort}
              />

              <Row>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <MentorTable
                      activeData={data}
                      refetchMentors={refetch}
                      setSelectedRows={setSelectedRows}
                      sortedInfo={sortedInfo}
                      hidCols={hiddenColumns}
                      modalVisible={editMentorModal}
                      parmsData={parms}
                      setParms={setParms}
                      status="active"
                      changeStatus={changeMutation}
                      deleteM={handleDelete}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={'Pending'} key="2">
              <ActionHeaders
                setCols={setHiddenColumns}
                hidCols={hiddenColumns}
                search={handleSearch}
                sort={handleSort}
              />

              <Row>
                <Col style={{ width: '100%' }}>
                  {isLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <MentorTable
                      activeData={data}
                      refetchMentors={refetch}
                      setSelectedRows={setSelectedRows}
                      sortedInfo={sortedInfo}
                      hidCols={hiddenColumns}
                      modalVisible={editMentorModal}
                      parmsData={parms}
                      setParms={setParms}
                      status="inactive"
                      changeStatus={changeMutation}
                      deleteM={handleDelete}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Archive" key="3" style={{}}>
              <ActionHeaders
                setCols={setHiddenColumns}
                hidCols={hiddenColumns}
                search={handleSearch}
                sort={handleSort}
              />

              <Row>
                <Col style={{ alignItems: 'center', width: '100%' }}>
                  {isLoading ? (
                    <div
                      className="flex justify-center aligin-center items-center "
                      style={{ height: '80vh' }}
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <MentorTable
                      activeData={data}
                      refetchMentors={refetch}
                      setSelectedRows={setSelectedRows}
                      sortedInfo={sortedInfo}
                      hidCols={hiddenColumns}
                      modalVisible={editMentorModal}
                      parmsData={parms}
                      setParms={setParms}
                      status="archived"
                      changeStatus={changeMutation}
                      deleteM={handleDelete}
                    />
                  )}
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {newMentor ? (
        <MentorsModel
          modalVisible={newMentor}
          closeModal={setNewMentor}
          refetchData={refetch}
          editdata={selectedItem}
          removeData={setSelectedItem}
        />
      ) : (
        <></>
      )}
      {alertModalVisible && (
        <Statusmodal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModalVisible}
          data={alertModalData}
          name="mentor"
          selectedRows={selectedRows}
        ></Statusmodal>
      )}
    </AppLayout>
  )
}

export default Mentors
