import { Row, Table, Space } from 'antd'
import usePagination from 'helper/usePagination'
import editblackPencil from 'assets/Images/editblackPencil.svg'
import deleteBlackIcon from 'assets/Images/deleteBlackIcon.svg'
const ActivityTable = ({
  data,
  params,
  setParams,
  hidCols,
  setSelectedRows,
}: any) => {
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams,
  })
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 358.67,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={8}>{record.name}</Space>
        </div>
      ),
      className: hidCols.name === false ? 'display-none' : '',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 358.67,
      render: (text: any, record: any) => {
        const firstCategory = record.categories[0]
        if (firstCategory) {
          return (
            <div style={{ cursor: 'pointer' }} className="py-10px">
              <Space size={8}>{`${firstCategory.name}`}</Space>
            </div>
          )
        } else {
          return ''
        }
      },
      className: hidCols.category === false ? 'display-none' : '',
    },

    {
      title: 'Organization',
      dataIndex: 'organization',
      key: 'organization',
      width: 358.67,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={8}>{record.organization.name}</Space>
        </div>
      ),

      className: hidCols.organization === false ? 'display-none' : '',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space className="orgn-action-btn">
            <img
              src={editblackPencil}
              alt="edit"
              width={20}
              //   onClick={() => {
              //     setEdit(true)
              //     setData(item)
              //   }}
              className="cursor_pointer"
            />
            <img
              src={deleteBlackIcon}
              alt="delete"
              //   onClick={e => {
              //     setAlertModal(true)
              //     setUserId((item as any).id)
              //   }}
              className="cursor_pointer"
            />
          </Space>
        </div>
      ),

      className: hidCols.action === false ? 'display-none' : '',
    },
  ]
  return (
    <div className="mt-20">
      <Row align={'middle'}>
        <Table
          className="organizations_table"
          style={{ width: '100%' }}
          dataSource={data?.records}
          rowSelection={rowSelection}
          columns={columns}
          scroll={{ x: 400 }}
          pagination={paginationInfo}
          onChange={onTableChange}
        />
      </Row>
    </div>
  )
}
export default ActivityTable
