import React, { useState, useEffect } from 'react'
import { Modal, Form, Avatar, Pagination } from 'antd'
import Cancel from 'assets/Images/close.svg'
import Spinner from 'components/Spinner'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { getEvents } from 'api/Events'
import moment from 'moment'
interface EventModalProps {
  open: boolean
  setOpen: (value: boolean) => void
}
const EventModal: React.FC<any> = ({
  open,
  setOpen,
  selectedEvent,
  setSelectedEvent,
}) => {
  const [parms, setParms] = useState({
    page: 1,
    limit: 10,
    searchQuery: '',
    timeStatus: 'current',
  })
  const handleCancel = () => {
    setOpen(false)
    setSelectedEvent(undefined)
  }

  const handleSearchChange = (e: any) => {
    setParms({ ...parms, searchQuery: e })
  }
  const debounced = debounce(handleSearchChange, 300)

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['all-events'],
    queryFn: () => getEvents(parms),
    enabled: false,
  })

  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setParms({ ...parms, page: pageNumber, limit: pageSize })
  }

  useEffect(() => {
    refetch()
  }, [parms])
  return (
    <>
      <Modal
        open={open}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        className="select-event-modal"
      >
        <div className="flex-center  pt-40px justify-between ">
          <h2 className="w-600 text-24">Select Event</h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>

        {/* modal content  */}
        <div className="h-full my-20px px-20px">
          <div className="search-input-box">
            <input
              type="search"
              className="search-input-group"
              placeholder="Search"
              onChange={e => {
                e.preventDefault()
                debounced(e.target.value)
              }}
            />
            <SearchOutlined />
          </div>
          <div className="overflow-auto pointer mt-20 h-25rem px-20px">
            {isLoading ? (
              <div className="flex justify-center align-center h-full">
                <Spinner />
              </div>
            ) : (
              data?.records?.map((eventData: any, index: number) => {
                return (
                  <div
                    className={`flex items-center gap-20px mt-20px px-20px py-20px radius-12  ${
                      eventData?._id === selectedEvent?._id
                        ? 'bg-grey'
                        : 'bg-white'
                    }`}
                    onClick={() => setSelectedEvent(eventData)}
                  >
                    <div style={{ width: '150px', height: '100px' }}>
                      {eventData?.coverPhotos.length > 0 ? (
                        <img
                          src={eventData?.coverPhotos[0].post}
                          alt="event-img"
                          className="radius-12 w-full"
                          style={{ height: '100px' }}
                        />
                      ) : (
                        <div
                          className="radius-12 w-full"
                          style={{ background: '#cdcdcd', height: '100px' }}
                        ></div>
                      )}
                    </div>
                    <div>
                      <h3>{eventData?.name}</h3>
                    </div>
                  </div>
                )
              })
            )}
          </div>
          <div className="mt-40px flex justify-center align-center align-center">
            <Pagination
              defaultCurrent={parms?.page}
              total={data?.count}
              onChange={PaginationChanger}
              showSizeChanger={false}
            />
          </div>
        </div>
        {/* modal content  */}
        <div className="flex align-center justify-end gap-30px mt-30px pb-30px">
          <button className="prim-btn" onClick={() => setOpen(false)}>
            Select
          </button>
        </div>
      </Modal>
    </>
  )
}

export default EventModal
