import { useEffect, useRef } from 'react'

interface CloseFn {
  dropdownRef: React.RefObject<HTMLDivElement> | null
  setOpen: React.Dispatch<React.SetStateAction<number>>
}

export const CloseDropeDown = ({ dropdownRef, setOpen }: CloseFn) => {
  const handleDocumentClick = (e: MouseEvent) => {
    if (dropdownRef && dropdownRef.current) {
      if (!dropdownRef.current.contains(e.target as Node)) {
        setOpen(0)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return handleDropdownClick
}
