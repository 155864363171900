const actions = {
  GET_ALLERGIES: "GET_ALLERGIES",
  GET_ALLERGIES_SUCCESS: "GET_ALLERGIES_SUCCESS",
  GET_ALLERGIES_FAILURE: "GET_ALLERGIES_FAILURE",

  getAllergiesRequest: () => ({
    type: actions.GET_ALLERGIES
  }),

  getAllergiesSuccess: (payload) => ({
    type: actions.GET_ALLERGIES_SUCCESS,
    payload,
  }),

  getAllergiesFailure: () => ({
    type: actions.GET_ALLERGIES_FAILURE,
  }),
};

export default actions;
