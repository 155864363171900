import React, { useState } from 'react'
import { Avatar, Pagination } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Spinner from '../../../../../../../components/Spinner'
import { debounce } from 'lodash'
import { getNameSignature } from 'helper/utility'
import { OrganizationResp } from 'types/globalTypes'
import { useGetOrganizations } from 'api/EventCreation/EventCreation'
import { OrganizationParams } from 'types/events'

interface OrganizationListingprops {
  organizationData: OrganizationResp[]
  parms: OrganizationParams
  setParms: React.Dispatch<React.SetStateAction<OrganizationParams>>
  setSelectedOrganization: React.Dispatch<
    React.SetStateAction<OrganizationResp>
  >
}

const OrganizationListing: React.FC<OrganizationListingprops> = ({
  organizationData,
  parms,
  setParms,
  setSelectedOrganization,
}) => {
  const handleSearchChange = (e: string) => {
    setParms({ ...parms, queryString: e, page: 1 })
  }
  const debounced = debounce(handleSearchChange, 300)
  const [clickedButtons, setClickedButtons] = useState<number>()
  const [id, setId] = useState<string>()
  const ClickedFam = (data: OrganizationResp, index: number) => {
    setClickedButtons(index)
    setId(data._id)
    setSelectedOrganization(data)
  }
  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setParms({ ...parms, page: pageNumber, limit: pageSize })
  }
  const { data, isLoading, isFetching } = useGetOrganizations(parms)

  return (
    <div className="h-full my-20px px-20px">
      <div className="search-input-box">
        <input
          type="search"
          className="search-input-group"
          placeholder="Search"
          onChange={e => {
            e.preventDefault()
            debounced(e.target.value)
          }}
        />
        <SearchOutlined />
      </div>
      <div className="overflow-auto pointer mt-20 h-25rem">
        {isLoading || isFetching ? (
          <div className="flex justify-center align-center h-full">
            <Spinner />
          </div>
        ) : organizationData?.length > 0 ? (
          organizationData?.map((OrgData: any, index: number) => {
            return (
              <div
                className={
                  clickedButtons === index
                    ? 'org-active flex align-center justify-between  mt-15px border-1 pb-10px pointer'
                    : 'flex align-center mt-15px border-1 pb-10px  pointer ml-20px'
                }
                key={index}
                onClick={() => {
                  ClickedFam(OrgData, index)
                }}
              >
                <div className="flex align-center">
                  <Avatar
                    size={34}
                    src={OrgData?.photo ? OrgData?.photo?.post : ''}
                  >
                    {getNameSignature(OrgData?.name)}
                  </Avatar>
                  <h4 className="ml-10">{OrgData.name}</h4>
                </div>
              </div>
            )
          })
        ) : (
          <div className="pb-40 pt-40 flex align-center justify-center">
            <h3 className="text-14 w-1 text-center">No orgainzations</h3>
          </div>
        )}
      </div>
      <div className="mt-40px flex justify-center align-center align-center">
        <Pagination
          defaultCurrent={parms?.page}
          total={data?.count}
          onChange={PaginationChanger}
          showSizeChanger={false}
        />
      </div>
    </div>
  )
}

export default OrganizationListing
