import { Row, Col, Avatar, Space, Dropdown, Menu, Button, Tooltip } from 'antd'
import checkin from '../../assets/Images/Calendar.svg'
import StatusDropdown from '../../helper/StatusDropdown'
import pencilLine from '../../assets/Images/PencilLine.svg'
import deleteSvg from '../../assets/Images/delete_red.svg'
import RedStar from '../../assets/Images/RedStar.png'
import { PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import DeleteAlert from '../../components/modals/deleteModal'
import history from '../../helper/history'
import { getNameSignature } from 'helper/utility'
import { useQuery } from '@tanstack/react-query'
import { getAllergyList } from '../../api/Lists'
import icon from 'assets/Images/otherCategory.svg'
const ProfileHeader = ({
  data,
  refetch,
  name,
  updateStatus,
  deleteMutation,
  showEdit,
}: any) => {
  const { data: AllergyData, isLoading } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )
  const [deletefn, setShowDelete] = useState(false)
  const AlertData = {
    heading: `Do you want to delete this ${name}`,
    message: `This ${name} will be deleted and if this is the only guardian whole family will be deleted`,
  }

  const menu = (
    <Menu>
      <Menu.Item key="checkin">
        <Space>
          <img alt="img" src={checkin}></img> Check-in to Event
        </Space>
      </Menu.Item>

      <Menu.Item
        key="Editprofile"
        onClick={() => {
          showEdit(true)
        }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          setShowDelete(true)
        }}
      >
        <Space className="text-color-red">
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )
  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <Tooltip placement="bottom" title={allergy?.name || data?.name}>
          <Avatar
            src={allergy?.icon || icon}
            size={20}
            className="allergy-icon"
          />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip placement="bottom" title={data?.name}>
          <Avatar src={icon} size={20} className="allergy-icon" />
        </Tooltip>
      )
    }
  }
  return (
    <div className="org-profile-header">
      <Row gutter={10}>
        <Col xl={{ span: 10 }}>
          <Row align="middle">
            <Col span={24} lg={{ span: 24 }}>
              <div className="d-flex align-center ">
                <div className="flex justify-start ">
                  <Avatar src={data?.photo?.post} size={80}>
                    {getNameSignature(data?.firstName + ' ' + data?.lastName)}
                  </Avatar>
                  <div className=" avatar-name align-items-center">
                    <span className="profile_title">
                      {data?.firstName + ' ' + data?.lastName}
                    </span>
                    {data?.relation && (
                      <>
                        <div className="d-flex align-items-center">
                          <h3 className="grey-txt text-14">
                            {data?.relation?.name}
                          </h3>
                          {data?.isEmergencyContact === true && (
                            <>
                              <img
                                alt="img"
                                src={RedStar}
                                style={{ marginLeft: '5px' }}
                              ></img>
                              <span
                                style={{ color: '#FF6961', marginLeft: '5px' }}
                              >
                                Emergency contact
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {isLoading ||
                      (data?.allergies?.length > 0 && (
                        //  className="mt-15px"
                        <div>
                          <div className="flex  flex-wrap w-full align-items-center">
                            {data?.allergies?.map((data: any) => {
                              return (
                                <div
                                  key={data?._id}
                                  className="mt-10px allergyChips "
                                  style={{ marginRight: '10px' }}
                                >
                                  {getAllergywithIcon(data)}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 14 }}>
          <Row>
            <Col span={24} className="Organization-header">
              <Space>
                <h5 className="status-title">
                  <b className="b1">Status</b>
                </h5>
                <div className="profile-head-action-dropdown">
                  <StatusDropdown
                    id={data?.id}
                    name="guardian"
                    status={data?.status}
                    changeFn={updateStatus}
                    refetchData={refetch}
                  />
                </div>
              </Space>
              <Dropdown overlay={menu} className="status_dropdown">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="actionButton actionButton-org-profile"
                  shape="round"
                >
                  Manage
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>

      {deletefn && (
        <DeleteAlert
          setIsModalVisible={setShowDelete}
          data={AlertData}
          onYesClick={() => {
            deleteMutation.mutate({
              params: data.id,
              payload: { status: 'archived' },
            })
            history.push(`/kid-profile/${data?.family?._id}`)
          }}
        />
      )}
    </div>
  )
}
export default ProfileHeader
