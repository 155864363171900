import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPatch, axiosPost } from "../axiosHelper";
import failureAction from "../Failure/action";

export function* getSessionData({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 10,
      sort: params?.sort?.order || "desc",
      populate: '[{"path":"family","populate":"photo"},{"path":"mentor","populate":"photo"}]',
    };
    const { data } = yield axiosGet(`v1/admins/mentor-requests`,currentParams);
    yield put(actions.getSessionDataRequestSuccess(data));
  }catch (error) {
    yield put(actions.getSessionDataRequestFailure());
    yield put(failureAction.failure(error));
  }
}

export function* createSession({ payload }) {
  try {
    const { data } = yield axiosPost(payload,`v1/mentor-request/create-request`);
    yield put(actions.addSessionDataSuccess(data));
  }catch (error) {
    yield put(actions.addSessionDataFailure());
    yield put(failureAction.failure(error));
  }
}

export function* editSession({ payload }) {
  try {
    const requestId = payload._id;
    const { data } = yield axiosPatch(payload,`v1/mentor-request/edit-request/${requestId}`);
    yield put(actions.getSessionDataRequest());
    yield put(actions.updateSessionDataSuccess(data));
  }catch (error) {
    yield put(actions.updateSessionDataFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getSessionDetailData({ payload }) {
  try {
    const requestId = payload._id;
    const { data } = yield axiosGet(`v1/mentor-request/${requestId}`);
    yield put(actions.getSessionDetailDataRequestSuccess(data));
  } catch (error) {
    yield put(actions.getSessionDetailDataRequestFailure());
    yield put(failureAction.failure(error));
  }
}

export function* createLiveUpdateData({ payload }) {
  try {
    const { data } = yield axiosPost(payload,`v1/live-updates/add-update`);
    yield put({
      type: actions.GET_LIVE_UPDATE_DATA,
      payload: { _id: payload?.contractRoomId },
    });
    yield put(actions.addLiveUpdateDataSuccess(data));
  } catch (error) {
    yield put(actions.addLiveUpdateDataFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getLiveUpdateData({ payload }) {
  try {
    const contractRoomId = payload._id;
    const { data } = yield axiosGet(`v1/live-updates/contract-room/${contractRoomId}`);
    yield put(actions.getLiveUpdateDataSuccess(data));
  } catch (error) {
    yield put(actions.getLiveUpdateDataFailure());
    yield put(failureAction.failure(error));
  }
}

export function* createNotesData({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/notes/add-note`);
    yield put(actions.addNotesDataSuccess(data));
  } catch (error) {
    yield put(actions.addNotesDataFailure());
    yield put(failureAction.failure(error));
  }
}

export function* getNotesData({ payload }) {
  try {
    const contractRoomId = payload._id;
    const { data } = yield axiosGet(`v1/notes/${contractRoomId}`);
    yield put(actions.getNotesDataSuccess(data));
  } catch (error) {
    yield put(actions.getNotesDataFailure());
    yield put(failureAction.failure(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_SESSION_DATA_REQUEST, getSessionData)]);
  yield all([takeLatest(actions.GET_SESSION_DETAIL_DATA_REQUEST, getSessionDetailData)]);
  yield all([takeLatest(actions.ADD_SESSION_DATA, createSession)]);
  yield all([takeLatest(actions.UPDATE_SESSION_DATA, editSession)]);
  yield all([takeLatest(actions.ADD_LIVE_UPDATE_DATA, createLiveUpdateData)]);
  yield all([takeLatest(actions.GET_LIVE_UPDATE_DATA, getLiveUpdateData)]);
  yield all([takeLatest(actions.ADD_NOTES_DATA, createNotesData)]);
  yield all([takeLatest(actions.GET_NOTES_DATA, getNotesData)]);
}
