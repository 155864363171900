import React from 'react'
import Calendar from 'react-calendar'
import { creationEventStore } from 'state/eventCreationStore'
import moment from 'moment'

interface MonthlyCalendarProps {
  selectedDate: Date
  setSelectedDate: any
}

const MonthlyCalendar: React.FC<MonthlyCalendarProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const { datesOfEvent } = eventData
  const bDates = []
  datesOfEvent.map((date: string) => {
    const utcDate = new Date(date)
    utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset())
    bDates.push(utcDate)
  })

  return (
    <div className="schedule-calendar">
      <Calendar
        calendarType="US"
        onChange={setSelectedDate}
        defaultActiveStartDate={selectedDate}
        minDetail="month"
        minDate={datesOfEvent && moment(datesOfEvent[0], 'YYYY-MM-DD').toDate()}
        maxDate={
          datesOfEvent &&
          moment(datesOfEvent[datesOfEvent.length - 1], 'YYYY-MM-DD').toDate()
        }
        tileDisabled={({ date }) =>
          !bDates.some(
            bDate =>
              bDate.getDate() === date.getDate() &&
              bDate.getMonth() === date.getMonth(),
          )
        }
        tileClassName={({ date, view }) => {
          let classes = []
          if (
            datesOfEvent.find(
              x =>
                moment(x).format('YYYY-MM-DD') ===
                moment(date).format('YYYY-MM-DD'),
            )
          ) {
            classes.push('availalbe-date')
          }
          if (
            moment(selectedDate).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD')
          ) {
            classes.push('current-selected')
          }
          return classes.join(' ')
        }}
      />
    </div>
  )
}

export default MonthlyCalendar
