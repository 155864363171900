import { Row } from 'antd'
import { Tabs } from 'antd'
import AppLayout from '../../../components/layout/layout'
import { useLocation, useParams } from 'react-router-dom'
import HistoryTab from './HistoryTab'
import { useQuery } from '@tanstack/react-query'
import { getMentorSessionStats, getRequestDetails } from 'api/mentors'
import { RequestDetailResp } from '../types'
import Details from './Details'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { convertToUtc } from 'helper/utility'
import Schedule from './Schedule'
import GetSkillsIco from 'components/global/GetSkillsIco'
import CategoryImage from 'helper/CategoryImageLink'
import ReturnBreadCrumbs from 'components/breadCrumb/NewBreadCrumbs'
import { Button } from 'antd'

const { TabPane } = Tabs

const RequestDetails = () => {
  const today = moment().startOf('day')
  const { id }: any = useParams()
  const [activeTab, setActiveTab] = useState('1')
  const { data, isLoading } = useQuery<RequestDetailResp>(
    ['getRequestDetails'],
    () => {
      return getRequestDetails(id)
    },
  )
  const { data: sessionData, refetch: refetchSession } = useQuery(
    ['getSessionStats'],
    () => {
      return getMentorSessionStats(id, data?.mentor._id!, convertToUtc(today))
    },
    { enabled: false },
  )

  useEffect(() => {
    data && data?.mentor?._id && refetchSession()
  }, [data, isLoading])
  const changeTab = (key: '1' | '2' | '3') => {
    setActiveTab(key)
  }
  return (
    <AppLayout>
      <div className="container bg-grey ">
        <div className="bg-white">
          <div className='px-30px flex justify-between align-center pt-15px'>
            <ReturnBreadCrumbs
              links={[
                { name: 'Request', link: '/jobrequest' },
                { name: data?.title || "", link: `#` },
              ]}
            />
            <div className='flex align-center gap-16px'>
              <h3 className='text-15 w-500 text-prim'>Participants (8)</h3>
              <button className="bordered-prim-btn ">Propose Mentor</button>
              <button className="prim-btn ">Manage Request</button>
            </div>
          </div>
          <Row
            justify="space-between"
            style={{ padding: '30px', paddingBottom: '24px' }}
          >
            {data?.type === 'parent-request' || data?.type === 'organization-request' ? (
              <GetSkillsIco id={data?.categoryId!} />
            ) : (
              <div className="flex-center">
                <CategoryImage id={data?.event?.category?._id!} />
                <h4 className="text-14 w-500 ml-10px">
                  {data?.event?.category?.name}
                </h4>
              </div>
            )}
          </Row>
        </div>
        <Tabs
          activeKey={activeTab}
          onChange={key => setActiveTab(key)}
          className="bg-white profile-tabs"
        >
          <TabPane tab="Details" key="1">
            <Details
              Requestdata={data}
              Loading={isLoading}
              session={sessionData}
              changeTab={changeTab}
            />
          </TabPane>
          <TabPane tab="History" key="2">
            <HistoryTab />
          </TabPane>

          {data?.timesPerWeek !== 'flexible' && (
            <TabPane tab="Schedule" key="3">
              <Schedule data={data} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </AppLayout>
  )
}

export default RequestDetails
