import { Row } from 'antd'
import OrganizerContactInfo from './organizerContactInfo'
import OrganizerGeneralInfo from './organizerGeneral'
const OrganizerBasicInfo = ({ parent, refetch }: any) => {
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <OrganizerContactInfo data={parent} refetchData={refetch} />
      <OrganizerGeneralInfo data={parent} refetch={refetch} />
    </Row>
  )
}
export default OrganizerBasicInfo
