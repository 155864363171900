/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Avatar, Checkbox, Col, Select } from 'antd'
import { Menu, Dropdown, Space } from 'antd'
import { Row } from 'antd'
import { Table } from 'antd'
import { history } from '../../../Redux/app-store'
import actions from '../../../Redux/Organization/action'
import { useDispatch } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import filterSvg from '../../../assets/Images/filter.svg'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import type { ColumnsType, SorterResult } from 'antd/es/table/interface'
import AlertModal from '../../../helper/AlertModal'
import StatusDropdown from '../../../helper/StatusDropdown'
import OrganazationsDataType from '../types'
import { setPaginationObject } from '../../../helper/utility'
import organzationActions from '../../../Redux/Organization/action'
import { getNameSignature } from '../../../helper/utility'
import { debounce } from 'lodash'
import OrganizationalModal from '../../../components/modals/organizationModal'

const OrganizationTable = ({
  activeData,
  setParms,
  parms,
  setSelectedRows,
  someState,
  onChangeSomeState,
  status,
  changeStatus,
  refetchOrg,
}: any) => {
  const dispatch = useDispatch()
  let initPaginationInfo = {
    total: activeData?.count,
    current: parms?.page,
    sort: 'name',
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)
  const [visible, setVisible] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [userId, setUserId] = useState()

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [data, setData] = useState(activeData)
  // const [searchQuery, setSearchQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState(parms?.queryString || '')
  const [sortVariable, setSortvariable] = useState('name')
  const [updatePage, setUpdatePage] = useState(0)
  const [showEdit, setEdit] = useState(false)
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    organizationName: true,
    owner: true,
    referralType: true,
    dateApplied: true,
    status: true,
    action: true,
  })
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    }

    setHiddenColumns(updatedHiddenColumns)
    localStorage.setItem(
      'OrganizationCols',
      JSON.stringify(updatedHiddenColumns),
    )
  }
  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: activeData.count,
    })
  }, [activeData.count])
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('OrganizationCols')
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns))
    }
  }, [])

  // const handleSearchChange = (e: any) => {
  //   setSearchQuery(e)
  //   dispatch({
  //     type: organzationActions.GET_ORGANIZATIONS,
  //     params: paginationInfo,
  //     payload: { status: status },
  //     searchQuery: e,
  //   })
  // }
  const handleSearchChange = (searchValue: string) => {
    setSearchQuery(searchValue)
    setParms((prevParms: any) => ({
      ...prevParms,
      queryString: searchValue,
    }))

    dispatch({
      type: organzationActions.GET_ORGANIZATIONS,
      params: { ...parms, queryString: searchValue },
      payload: { status: status },
    })
  }
  const debounced = debounce(handleSearchChange, 300)

  const alertModalData = {
    heading: 'Are you sure want to delete this',
    name: 'organizations',
    id: userId,
  }
  const OrganizationData = activeData?.records?.map(
    (item: OrganazationsDataType) => {
      return {
        key: item?.id,
        organizationName: item.name,
        organizationAvatar: item.photo?.post,
        owner: item.owner?.firstName,
        ownerAvatar: item.owner?.photo?.post,
        email: item.email,
        address: item.address,
        phone: item.phone,
        EstablishedDate: item.establishedOn,
        about: item.about,
        ownerid: item.ownerId,
        referralType: 'Person Name',
        dateApplied: moment(item.establishedOn).format('DD/MMM/YYYY'),
        createdAt: moment(item.createdAt).format('MMM DD,YYYY'),
        status: item.status === 'approved' ? 'active' : 'pending',
        action: (
          <Space className="orgn-action-btn">
            <img
              src={editblackPencil}
              alt="edit"
              width={20}
              onClick={() => {
                setEdit(true)
                setData(item)
              }}
              className="cursor_pointer"
            />
            <img
              src={deleteBlackIcon}
              alt="delete"
              onClick={e => {
                setAlertModal(true)
                setUserId((item as any).id)
              }}
              className="cursor_pointer"
            />
          </Space>
        ),
      }
    },
  )

  const columns: ColumnsType<OrganazationsDataType> = [
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'organizationName',

      render: (text, organizations, record: any) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push({
              pathname: `/organization-profile/${organizations?.key}`,
              state: organizations?.key,
            })
          }}
        >
          <Space size={8}>
            <Avatar size={24} src={organizations?.organizationAvatar}>
              {getNameSignature(text)}
            </Avatar>
            {text}
          </Space>
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.organizationName?.length - b.organizationName?.length,
      sortOrder:
        sortedInfo.columnKey === 'organizationName' ? sortedInfo.order : null,

      className: hiddenColumns.organizationName === false ? 'display-none' : '',
    },

    {
      title: 'Admin Name',
      dataIndex: 'owner',
      key: 'owner',
      render: (text, record: any, index) => (
        <Space size={8}>
          <Avatar size={24} src={record.ownerAvatar}></Avatar>
          {text}
        </Space>
      ),
      sorter: (a: any, b: any) => a.owner?.length - b.owner?.length,
      sortOrder: sortedInfo.columnKey === 'owner' ? sortedInfo.order : null,
      className: hiddenColumns.owner === false ? 'display-none' : '',
    },
    {
      title: 'Date CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a: any, b: any) => a.createdAt?.length - b.createdAt?.length,
      sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
      className: hiddenColumns.createdAt === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      className: hiddenColumns.status === false ? 'display-none' : '',
      render: (status, record: any, title) => (
        <StatusDropdown
          id={record?.key}
          name="organization"
          status={status}
          changeFn={changeStatus}
          refetchData={refetchOrg}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }

  const menu2 = (
    <Menu
      // onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.organizationName}
                  name="organizationName"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Organization Name</Col>
            </Row>
          ),
          key: 1,
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.owner}
                  name="owner"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Admin Name </Col>
            </Row>
          ),
          key: 2,
        },

        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.dateApplied}
                  name="createdAt"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Date Created At</Col>
            </Row>
          ),
          key: 4,
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.status}
                  name="status"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>status</Col>
            </Row>
          ),
          key: 5,
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: 6,
        },
      ]}
    />
  )

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleChangeSortName = (value: string) => {
    setParms({ ...parms, sort: value })
    setSortvariable(value)
    onChangeSomeState()
  }

  const handleChangeStateClick = () => {
    onChangeSomeState(someState + 1)
  }

  const onTableChange = (...rest: any) => {
    const newObject: any = [...rest]
    let newPaginationInfo = setPaginationObject.apply(paginationInfo, newObject)
    handleChangeStateClick()
    getOrganizations(newPaginationInfo)
    setPaginationInfo({ ...newPaginationInfo, page: newPaginationInfo.current })
  }

  const getOrganizations = (newPaginationInfo: any) => {
    setParms((prev: any) => ({
      ...prev,
      page: newPaginationInfo.current,
      status: status,
    }))
    setUpdatePage(updatePage + 1)
  }

  return (
    <>
      <div>
        <Space
          size={20}
          align="center"
          className="mentors-table-header"
          style={{ width: '100%' }}
        >
          <Dropdown
            overlay={menu2}
            onOpenChange={handleVisibleChange}
            open={visible}
          >
            <a onClick={e => e.preventDefault()}>
              <Space>
                <img src={filterSvg} alt=""></img>
              </Space>
            </a>
          </Dropdown>
          {/* <Select
            className="custom-select-field"
            defaultValue="Sort by: Name"
            placeholder="sort by"
            allowClear
            onChange={handleChangeSortName}
          >
            <Select.Option value="name">Sort by: Name</Select.Option>
            <Select.Option value="-createdAt">Sort by: Date</Select.Option>
          </Select> */}
          <div className="search-box">
            {/* <SearchBar
            currentTab={1}
            data={data}
            setData={setData}
            dataSource={activeData}
            searchKey="name"
          /> */}
            <div
              className="right-search-block w-100 d-inline-block"
              style={{ maxWidth: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <div
                  className={'search-input-box'}
                  style={{ marginRight: '15px' }}
                >
                  {/* <input
                    type="search"
                    className="search-input-group"
                    placeholder="Search by name"
                    onChange={e => debounced(e.target.value)}
                  /> */}
                  <input
                    type="search"
                    className="search-input-group"
                    placeholder="Search by name"
                    value={searchQuery} // <-- add this line to keep the input value
                    // onChange={e => debounced(e.target.value)}
                    onChange={e => {
                      setSearchQuery(e.target.value) // Set the input value immediately
                      debounced(e.target.value) // Debounce the actual search operation
                    }}
                  />

                  <SearchOutlined />
                </div>
              </div>
            </div>
          </div>
        </Space>
        <Row className="container">
          <Table
            className="organizations_table organization_pagination"
            rowSelection={rowSelection}
            style={{ width: '100%' }}
            columns={columns}
            dataSource={OrganizationData}
            rowKey="key"
            pagination={paginationInfo}
            scroll={{ x: 400 }}
            onChange={onTableChange}
          />
        </Row>
        {alertModal && (
          <AlertModal
            isModalVisible={alertModal}
            setIsModalVisible={setAlertModal}
            data={alertModalData}
            // deleting the organization
            onYesClick={() => {
              dispatch({
                type: organzationActions.DELETE_ORGANIZATION_REQUEST,
                payload: userId,
              })
              // getting updated organization
              dispatch({
                type: actions.GET_ORGANIZATIONS,
                params: paginationInfo,
                payload: { status: status },
              })
            }}
          />
        )}
        {showEdit && (
          <OrganizationalModal
            visible={showEdit}
            setVisblie={setEdit}
            prevdata={data}
          />
        )}
      </div>
    </>
  )
}

export default OrganizationTable
