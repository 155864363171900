import { Button, Col, Form, Input, Layout, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../../../../Redux/Organizer/action";

const { Option } = Select;

const OrganizerInfo = ({ data, next, onSuccess, organizers }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [organizer, setorganizer] = useState<any>(data?.owner?.id);
  const [designation, setDesignation] = useState(data?.owner?.designation);
  const [value, setValue] = useState<string>(data?.owner?.firstName);
  const [reason, setReason] = useState<string>(data?.owner?.reason);

  const [query, setQuery] = useState<string>("");

  const formSchema = {
    ownerId: data?.ownerId ? data?.ownerId : [],
    email: data?.email ? data?.email : "",
  };

  useEffect(() => {
    dispatch({
      type: actions.GET_ORGANIZERS,
      params: {
        queryString: query,
      },
    });
  }, [dispatch, query]);

  const handleChange = (newValue: any) => {
    setorganizer(newValue);
    const filteredData = organizers.filter((organizer: any) => {
      return organizer.id == newValue ? organizer.firstName : "no organization";
    });
    setDesignation(filteredData[0].designation);
  };

  const options = organizers?.map((organizer: any) => {
    return <Option key={organizer.id}>{organizer.firstName}</Option>;
  });

  const onFinish = (values: any) => {
    onSuccess({
      ...data,
      designation: designation,
      ownerId: organizer,
    });
  };

  return (
    <Layout>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={formSchema}
      >
        <div className="modal-h-element ">
          <Row gutter={[24, 15]}>
            <Col span={24}>
              <label className="input-label">Select organizer</label>
              <Form.Item
                name="ownerId"
                rules={[{ required: true, message: "Organizer is required" }]}
              ></Form.Item>
            </Col>
            {/* <Col span={24}>
              <label className="input-label">Designation</label>
              <Form.Item>
                <Input
                  placeholder="Designation"
                  defaultValue={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                ></Input>
              </Form.Item>
            </Col> */}
          </Row>
        </div>
        <Row justify="end">
          <Col span={24} className="text-end">
            <Button className="stepper-button" htmlType="submit">
              Next{" "}
            </Button>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  organizers: state.organizer.organizerData,
});

export default connect(mapStateToProps)(OrganizerInfo);
