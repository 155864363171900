import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet } from "../axiosHelper";

export function* getAllergiesRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/allergies`);
    yield put(actions.getAllergiesSuccess(data));
  } catch (error) {
    yield put(actions.getAllergiesFailure());
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_ALLERGIES, getAllergiesRequest)]);
}
