import React, { useState, useEffect } from 'react'
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from 'react-google-maps'
import Geocode from 'react-geocode'
import { Button, Row, Col } from 'antd'
import Pin from '../../../assets/Images/pin.svg'

Geocode.setApiKey(process.env.REACT_APP_googleMapsAPI)
Geocode.enableDebug()

const Map = props => {
  const [mapState, setMapState] = useState({
    title: props.mapData.title,
    address: '',
    city: '',
    area: '',
    state: '',
    zipCode: '',
    apartment: '',
    country: '',
    mapPosition: {
      lat: props.center.lat,
      lng: props.center.lng,
    },
    markerPosition: {
      lat: props.center.lat,
      lng: props.center.lng,
    },
  })

  useEffect(() => {
    if (props.mapData) {
      setMapState(prevState => ({
        ...prevState,
        mapPosition: {
          lat: props?.mapData.coordinates.latitude,
          lng: props.mapData.coordinates.longitude,
        },
        markerPosition: {
          lat: props.mapData.coordinates.latitude,
          lng: props.mapData.coordinates.longitude,
        },
        state: props.mapData.state,
        zipCode: props.mapData.zip,
        area: props.mapData.street,
        city: props.mapData.city,
        country: props.mapData.country,
        address: props.mapData.address,
        apartment: props.mapData.apartment,
      }))
    }
  }, [props.mapData])

  const onInfoWindowClose = event => {
    // Handle info window close event
  }

  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        google={props.google}
        defaultZoom={props.zoom}
        defaultCenter={{
          lat: mapState.mapPosition.lat,
          lng: mapState.mapPosition.lng,
        }}
      >
        <InfoWindow
          onClose={onInfoWindowClose}
          position={{
            lat: mapState.markerPosition.lat + 0.0018,
            lng: mapState.markerPosition.lng,
          }}
        >
          <div>
            <span style={{ padding: 0, margin: 0 }}>{mapState.address}</span>
          </div>
        </InfoWindow>
        <Marker
          google={props.google}
          name={'Dolores park'}
          draggable={false}
          position={{
            lat: mapState.markerPosition.lat,
            lng: mapState.markerPosition.lng,
          }}
          icon={Pin}
        />
        <Marker />
      </GoogleMap>
    )),
  )

  let map

  if (props.center.lat !== undefined) {
    map = (
      <div>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_googleMapsAPI}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: props.height }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
        {mapState.area && (
          <>
            <Row className="mt-10px">
              <h5>
                <b>Name of the location</b>
              </h5>
              <Col span={24}>{mapState.title}</Col>
            </Row>
            <Row className="mt-10px">
              <h5>
                <b>street</b>
              </h5>
              <Col span={24}>
                {mapState.area} {mapState.city} {mapState.state}
              </Col>
            </Row>
            <Row className="mt-10px">
              {mapState.apartment && (
                <Col span={6}>
                  <Row>
                    <h5>
                      <b>House / Apartment</b>
                    </h5>
                  </Row>
                  <Row>{mapState.apartment}</Row>
                </Col>
              )}
              <Col span={2}></Col>
              {mapState.zipCode && (
                <Col span={6}>
                  <Row>
                    <h5>
                      <b>ZIP Code</b>
                    </h5>
                  </Row>
                  <Row>{mapState.zipCode}</Row>
                </Col>
              )}
              <Col span={10}>
                {props.onlyView ? (
                  <></>
                ) : (
                  <Button
                    type="primary"
                    className="primary br-8"
                    size="large"
                    onClick={() => {
                      props.onSubmit({
                        title: mapState.title,
                        country: mapState.country,
                        street: mapState.area,
                        city: mapState.city,
                        zip: mapState.zipCode,
                        apartment: mapState.apartment,
                        state: mapState.state,
                        coordinates: {
                          latitude: mapState.mapPosition.lat,
                          longitude: mapState.mapPosition.lng,
                        },
                        address: mapState.address,
                      })
                    }}
                  >
                    Ask Bamrec to approve
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
    )
  } else {
    map = <div style={{ height: props.height }} />
  }

  return map
}

export default Map
