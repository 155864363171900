import { Checkbox, Col, Dropdown, Menu, Row, Space } from 'antd'
import React, { useState } from 'react'
import filterSvg from 'assets/Images/filter.svg'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import FilterOption from './FilterOption'
import { useEffect } from 'react'
export const Filter = ({ hidCols, setCols, search, parms, setParms }: any) => {
  const [visible, setVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hidCols,
      [e.target.name]: e.target.checked, // Use e.target.name to correctly reference the column key
    }

    setCols(updatedHiddenColumns)
    localStorage.setItem('PaymentCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('PaymentCols')
    if (storedHiddenColumns) {
      setCols(JSON.parse(storedHiddenColumns)) // Parse JSON correctly
    }
  }, [])

  const menu2 = (
    <Menu
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.payment}
                  name="payment"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20} style={{ paddingLeft: '10px' }}>
                Payment
              </Col>
            </Row>
          ),
          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.amount}
                  name="amount"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20} style={{ paddingLeft: '10px' }}>
                Amount
              </Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.date}
                  name="date"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20} style={{ paddingLeft: '10px' }}>
                Date
              </Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.status}
                  name="status"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20} style={{ paddingLeft: '10px' }}>
                Status
              </Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20} style={{ paddingLeft: '10px' }}>
                Action
              </Col>
            </Row>
          ),
          key: '5',
        },
      ]}
    />
  )
  const handleSearchChange = (e: any) => {
    setSearchQuery(e)
    search(e)
  }
  const debounced = debounce(handleSearchChange, 300)
  return (
    <div>
      <Space size={20} align="center" className="mentors-table-header">
        <Dropdown
          overlay={menu2}
          onOpenChange={handleVisibleChange}
          open={visible}
        >
          <h5 onClick={e => e.preventDefault()}>
            <Space>
              <img src={filterSvg} alt=""></img>
            </Space>
          </h5>
        </Dropdown>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <FilterOption setParms={setParms} parms={parms}></FilterOption>
      </Space>
    </div>
  )
}
export default Filter
