import BigCustomCalender from '../../../../helper/Calendar/Eventscalendar/BigCustomCalender'

const CalendarView = ({ events }: any) => {
  return (
    <>
      <div>
        <BigCustomCalender events={events} />
      </div>
    </>
  )
}

export default CalendarView
