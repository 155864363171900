import { Button, Col, Divider, Input, Layout, Modal, Row, Space } from "antd";
import AvailablityCalendar from "./AvailablityCalendar";
import plusCircle from "../../../assets/Images/PlusCircle.svg";
import deleteSvg from "../../../assets/Images/delete_red.svg";
const CalendarModal = ({ isModalVisible, setIsModalVisible, data }: any) => {
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        footer={null}
        width={486}
        closable={false}
      >
        <Layout>
          <Row gutter={[0, 20]} justify="center">
            <Col span={20} className="text-center">
              <h1 className="b1">
                Select the date(s) you want to assign a specific hours{" "}
              </h1>
            </Col>
            <Col span={20} className="calendar-b-0">
              <AvailablityCalendar />
              <Divider className="m-0" />
            </Col>
            <Col span={20}>
              <Row gutter={[0, 20]}>
                <Col>
                  <h5 className="b1">What hours are you available?</h5>
                </Col>
                <Col>
                  <Space size={20}>
                    <Input value="08:00 AM" className="text-center"></Input>
                    <h5>-</h5>
                    <Input value="12:00 PM" className="text-center"></Input>
                    <img
                      src={deleteSvg}
                      alt="deleteSvg"
                      className="cursor_pointer"
                    ></img>
                    <img
                      src={plusCircle}
                      alt="plusCircle"
                      className="cursor_pointer"
                    ></img>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={20} className="mt-20">
              <Space size={20} align="center" className="justify-center w-100">
                <Button
                  className="br-8 w-155 btn-h-50 hl-50 gry-btn py-0"
                  onClick={setIsModalVisible(false)}
                >
                  Cancel
                </Button>
                <Button className="br-8 w-155 btn-h-50 hl-50 primary-btn py-0">
                  Apply
                </Button>
              </Space>
            </Col>
          </Row>
        </Layout>
      </Modal>
    </>
  );
};

export default CalendarModal;
