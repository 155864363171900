import { Avatar, Form, Input, Layout, Space, notification } from "antd";
import { Row, Col, Button } from "antd";
import { Radio } from "antd";
import { ConfigProvider } from "antd-country-phone-input";
import CountryPhoneCode from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import "antd-country-phone-input/dist/index.css";
import { useDispatch, connect } from "react-redux";
import actions from "../../Redux/Auth/action";
import userAvatarSvg from "../../assets/Images/userAvatar.svg";
import { useState, ChangeEvent } from "react";
import FileUploader from "../../helper/FileUploader";

const Signup = ({ fileData }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState("");
  const [phone, setPhone] = useState<any>({});
  const openNotification = () => {
    notification.open({
      message: "Please accept Terms and Services",
      description:
        "You have to accept terms and services before creating new account",
    });
  };
  const [acceptTerms, setacceptTerms] = useState(false);
  const handleRadioChange = (e: any) => {
    setacceptTerms(e.target.checked);
  };
  const signUpFormSchema = {
    photoId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "male",
  };
  const onFinish = (values: any) => {
    acceptTerms
      ? dispatch({
          type: actions.SIGNUP_REQUEST,
          payload: {
            ...values,
            phone: phone,
          },
        })
      : openNotification();
  };
  return (
    <Layout>
      <ConfigProvider locale={en}>
        <Space
          direction="horizontal"
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "100px",
          }}
        >
          <Row justify="center">
            <Col span={24} style={{ alignItems: "center" }}>
              <div>
                <h1>Sign Up</h1>
              </div>
              <div>
                <span>Create an account to start your application</span>
              </div>
              <Form
                form={form}
                name="nest-messages"
                layout={"vertical"}
                onFinish={onFinish}
                initialValues={signUpFormSchema}
              >
                <Row gutter={24} align="middle">
                  <Col span={4} className="mb-16">
                    <Form.Item>
                      <Avatar
                        size={80}
                        src={avatar ? avatar : userAvatarSvg}
                      ></Avatar>
                    </Form.Item>
                  </Col>
                  <Col span={4} className="mb-16">
                    <Form.Item name="photoId">
                      <FileUploader
                        name="admin"
                        setAvatar={setAvatar}
                        form={form}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <label>First Name</label>
                <Form.Item rules={[{ required: true }]} name="firstName">
                  <Input name="firstName" />
                </Form.Item>
                <label>Last Name</label>
                <Form.Item name="lastName">
                  <Input name="lastName" />
                </Form.Item>
                <label>Email</label>
                <Form.Item rules={[{ type: "email" }]} name="email">
                  <Input name="email" />
                </Form.Item>
                <label>Password</label>
                <Form.Item name="password">
                  <Input name="password" />
                </Form.Item>
                <Form.Item name="gender">
                  <Radio.Group name="gender">
                    <Row>
                      <Radio.Button className="radioButton" value="male">
                        <h5>Male</h5>
                      </Radio.Button>
                      <Radio.Button className="radioButton" value="female">
                        <h5>Female</h5>
                      </Radio.Button>
                    </Row>
                  </Radio.Group>
                </Form.Item>
                <Row>
                  <Col span={12} className="mb-16">
                    <label htmlFor="" className="input-label">
                      Phone number
                    </label>
                    <Form.Item>
                      <CountryPhoneCode
                        onChange={(v) => {
                          setPhone({
                            dialCode: String(v.code),
                            number: v.phone,
                            isoCode: v.short,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Radio onChange={handleRadioChange} checked={acceptTerms}>
                    I have read, understand and agree to Estating's{" "}
                    <h5 className="terms"> Terms of Use </h5> and{" "}
                    <h5 className="terms">Privacy Policy</h5>.
                  </Radio>
                </Form.Item>
                <Form.Item>
                  {/* wrapperCol={{ ...layout.wrapperCol, offset: 8 }} */}
                  <Button
                    className="signup-next-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Space>
      </ConfigProvider>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
  fileData: state.fileUpload.fileData,
});

export default connect(mapStateToProps)(Signup);
