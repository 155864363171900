import { Space } from 'antd'
import React, { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import NewsFilter from './NewsFilter'
import { debounce } from 'lodash'
export const ActionHeader = ({ search, parms, setParms }: any) => {
  const [searchQuery, setSearchQuery] = useState('')
  const handleSearchChange = (e: any) => {
    setSearchQuery(e)
    search(e)
  }
  const debounced = debounce(handleSearchChange, 300)
  return (
    <div>
      <Space size={20} align="center" className="mentors-table-header">
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <NewsFilter setParms={setParms} parms={parms} />
      </Space>
    </div>
  )
}
export default ActionHeader
