import { Form, Input, Modal } from 'antd'
import { useState } from 'react'
import Cancel from 'assets/Images/close.svg'
import MapModal from 'helper/Map/MapModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addNewLocation } from 'api/Location'
import { forceRefetch } from 'helper/utility'
interface locProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}
const AddNewLocation = ({ setOpen, open }: locProps) => {
  const [mapModalVisible, setMapModalVisible] = useState<boolean>(false)
  const [mapModalData, setMapModalData] = useState<any>()
  const handleCancel = () => {
    setOpen(false)
  }
  const query = useQueryClient()
  const addMutation = useMutation({
    mutationFn: async ({ locationData }: any) => {
      return await addNewLocation(locationData)
    },
    onSuccess: () => forceRefetch('getLocations', query),
  })
  const addLocation = () => {
    addMutation.mutateAsync({
      locationData: { ...mapModalData, type: 'admin' },
    })
    setOpen(false)
  }
  return (
    <Modal open={open} onCancel={handleCancel} className="popup-modal ">
      <div className="py-40px">
        <div className="flex justify-between mb-20px">
          <h4>Add new Location</h4>
          <img src={Cancel} alt="closebtn" onClick={handleCancel} />
        </div>
        <div className="mt-20px">
          <label className="input-label mb-10px">Select Location</label>

          <Form.Item rules={[{ required: true }]}>
            <Input
              placeholder="Location"
              onClick={() => {
                setMapModalVisible(true)
              }}
              value={
                mapModalData ? mapModalData.city + ',' + mapModalData.state : ''
              }
            />
            {mapModalVisible && (
              <MapModal
                setMapModalVisible={setMapModalVisible}
                setMapModalData={setMapModalData}
                mapData={mapModalData}
              />
            )}
          </Form.Item>
        </div>
        <div className="flex justify-end mt-30px">
          <button onClick={handleCancel} className="btn-sec pointer">
            Cancel
          </button>
          <button onClick={addLocation} className="prim-btn">
            Add Location
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default AddNewLocation
