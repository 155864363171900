// import { Button, Col, Modal, Row, Space } from 'antd'
// import ButtonSpinner from 'components/global/ButtonSpinner'
// import { verifyPhone } from 'api/family'
// import { useMutation } from '@tanstack/react-query'
// const VerifiedPhone = ({
//   isModalVisible,
//   setIsModalVisible,
//   data,
//   loading,
// }: any) => {
//   const handleOk = () => {
//     setIsModalVisible(false)
//   }
//   const handleCancel = () => {
//     setIsModalVisible(false)
//   }
//   const changeMutation = useMutation({
//     mutationFn: async ({ payload }: any) => {
//       return await verifyPhone(payload)
//     },
//     onSuccess: resp => {
//       if (resp) {
//         setIsModalVisible(false)
//       }
//     },
//   })
//   const handleBlock = () => {
//     changeMutation.mutate({
//       payload: {
//       "phoneNumber": {
//     "dialCode": "strin",
//     "isoCode": "str",
//     "number": "string"
//   }
//       },
//     })
//   }

//   return (
//     <>
//       <Modal
//         className="delete-modal"
//         visible={isModalVisible}
//         cancelButtonProps={{ style: { display: 'none' } }}
//         onOk={handleOk}
//         footer={null}
//         centered={true}
//         onCancel={handleCancel}
//         width={438}
//         closable={data?.closeIcon}
//       >
//         <Row gutter={[0, 40]} justify="center">
//           <Col span={24}>
//             <div className="text-center">
//               <Space direction="vertical">
//                 <h3 className="b1">{data?.heading}</h3>
//                 <div
//                   className="b3"
//                   dangerouslySetInnerHTML={{ __html: data?.message1 }}
//                 ></div>
//                 <h5 className="b1">{data?.message2}</h5>
//               </Space>
//             </div>
//           </Col>
//           <Col>
//             <div>
//               <Space size={20}>
//                 <Button
//                   onClick={handleCancel}
//                   className="alert_delete_button bg-grey"
//                 >
//                   Cancel
//                 </Button>
//                 <Button
//                   className="alert_delete_button text-white"
//                   onClick={() => {
//                     handleBlock()
//                   }}
//                 >
//                   {loading ? (
//                     <ButtonSpinner size={20} color="#000" />
//                   ) : data?.okText ? (
//                     data?.okText
//                   ) : (
//                     'Yes, Phone'
//                   )}
//                 </Button>
//               </Space>
//             </div>
//           </Col>
//         </Row>
//       </Modal>
//     </>
//   )
// }

// export default VerifiedPhone
import { Button, Col, Modal, Row, Space } from 'antd'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { verifyPhone } from 'api/family'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

const VerifiedPhone = ({
  isModalVisible,
  setIsModalVisible,
  data,
  loading,
  refetchData,
}: any) => {
  const { id }: any = useParams()
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const changeMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await verifyPhone(payload)
    },
    onSuccess: resp => {
      if (resp) {
        setIsModalVisible(false)
        refetchData()
      }
    },
  })

  const handleVerifyPhone = () => {
    const { dialCode, isoCode, number } = data?.message2 || {}

    if (dialCode && isoCode && number) {
      changeMutation.mutate({
        payload: {
          guardianId: id,
          phoneNumber: {
            dialCode,
            isoCode,
            number,
          },
        },
      })
    }
  }

  return (
    <Modal
      className="delete-modal"
      visible={isModalVisible}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleVerifyPhone}
      footer={null}
      centered={true}
      onCancel={handleCancel}
      width={438}
      closable={data?.closeIcon}
    >
      <Row gutter={[0, 40]} justify="center">
        <Col span={24}>
          <div className="text-center">
            <Space direction="vertical">
              <h3 className="b1">{data?.heading}</h3>
              <div
                className="b3"
                dangerouslySetInnerHTML={{ __html: data?.message1 }}
              ></div>
              <h5 className="b1">
                {data?.message2?.dialCode} {data?.message2?.number}
              </h5>
            </Space>
          </div>
        </Col>
        <Col>
          <div>
            <Space size={20}>
              <Button
                onClick={handleCancel}
                className="alert_delete_button bg-grey"
              >
                Cancel
              </Button>
              <Button
                className="alert_delete_button-blue text-white"
                onClick={handleVerifyPhone}
              >
                {changeMutation.isLoading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : data?.okText ? (
                  data?.okText
                ) : (
                  'Yes, Verify'
                )}
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default VerifiedPhone
