import { Avatar, Button, Row, Space, Table, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import mentorsAction from "../../../Redux/mentors/action";
import actions from "../../../Redux/mentors/action";
import StatusDropdown from "../../../helper/StatusDropdown";
import moment from "moment";
import { history } from "../../../Redux/app-store";
import { connect, useDispatch } from "react-redux";
import { SorterResult } from "antd/es/table/interface";
const HistoryTab = ({ activeData, mentorsData }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: actions.GET_MENTORS,
    });
  }, []);
  console.log(mentorsData, "ss");
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const onChangeColumnCheckBox = (e: any) => {
    console.log(`checked = ${e.target.name}`);
    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    });
  };
  const newArray = mentorsData?.map((item: any, record: any, index: any) => {
    return {
      key: item.id,
      firstname: item.firstName,
      mentorAvatar: item.photo?.post,
      dob: moment(item.dob).format("MM/DD/YYYY"),
      totalhours: "4hr",
      status: item.status,
      action: [
        <Space key={item.id}>
          <Button
            type="primary"
            className="br-10"
          >
            View Profile
          </Button>
        </Space>,
      ],
    };
  });
  const columnss = [
    {
      dataIndex: "firstname",
      key: "firstname",
      render: (text: any, record: any, index: any) => (
        <div
          className="cursor_pointer d-flex"
          onClick={() => {
            history.push({
              pathname: `/mentor-profile/${record.key}`,
              state: JSON.stringify(record.key),
            });
          }}
        >
          <Tooltip placement="bottom" title={text} color="blue">
            <Space size={8}>
              <Avatar
                size={24}
                icon={<img alt="img" src={record.mentorAvatar}></img>}
              ></Avatar>
             <b>{text}</b>
            </Space>
          </Tooltip>
          <span style={{marginRight:'8px', marginLeft:'8px'}}>hired</span>
          <span style={{color:'#9CA5B0'}}>5 mins ago</span>
        </div>
      ),
    },
  ];
  const rowSelection = {};
  return (
    <Row>
      <Table
        rowKey="key"
        style={{ width: "100%" }}
        columns={columnss}
        dataSource={newArray}
        pagination={false}
        className="request-stats"
/>
    </Row>
  );
};
const mapStateToProps = (state: any) => ({
  mentorsData: state.mentor.mentorsData,
});
export default connect(mapStateToProps)(HistoryTab);
