import { Popover, Button, Col, Row, Select, Switch, Typography } from "antd";
import Amin from "../../assets/Images/Amin.png";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PasswordIco from "../../assets/Images/LockKeyBlack.svg";
import User from "../../assets/Images/UserProfileblack.svg";
import { connect, useDispatch } from "react-redux";
import actions from "../../Redux/Admin/action";
import history from "../../helper/history";
import AdminsMain from "./Admin/AdminsMain";
const { Option } = Select;
const onChangetoggle = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};
const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const { Title } = Typography;
const GeneralSetting = ({ admins }: any) => {
  return (
    <div>
      <h1 className="general-s-heading">General Settings</h1>
      <div className="switch-div mb-0 b-0 pl-0">
        <p className="switch-pra">Set a phone number as a mandatory field</p>
        <div className="switch-btn">
          <Switch
            defaultChecked
            onChange={onChangetoggle}
            className="small-custom-switch-btn"
          />
        </div>
      </div>

      <div className="switch-div mb-0 b-0 pl-0">
        <p className="switch-pra">Set an email as a mandatory field</p>
        <div className="switch-btn">
          <Switch
            defaultChecked
            onChange={onChangetoggle}
            className="small-custom-switch-btn"
          />
        </div>
      </div>
      <div
        style={{ maxWidth: "430px" }}
        className="w-100 pr-20 position-relative"
      >
        <p className="pra-heading mt-10">Default phone country code:</p>

        <Select
          className="dropdown-setting default-phone-country-field text-color-black"
          placeholder="Identify country code by user’s IP address"
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          <Option value="jack">181.20.20.123</Option>
          <Option value="lucy">186.53.30.303</Option>
          <Option value="tom">110.20.30.454</Option>
        </Select>
      </div>
      <AdminsMain />
    </div>
  );
};
export default GeneralSetting;
