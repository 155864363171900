import React, { useState } from 'react'
import { Col, Row, Button } from 'antd'
import { List } from 'antd'
import { Tabs } from 'antd'
import BluePencil from '../../assets/Images/bluePencil.svg'
import Draggable from '../../assets/Images/Draggable.svg'
import editblackPencil from '../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../assets/Images/deleteBlackIcon.svg'
import AppLayout from './SettingLayout/layout'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AskQuestionModal from '../Modals/SettingsModal/AskQuestionModal'
const { TabPane } = Tabs
const onChange = (key: string) => {
  console.log(key)
}
const data1 = [
  {
    title: 'Question goes here #1',
    action: <Button placeholder="Hello" />,
  },
  {
    title: 'Question goes here #2',
    action: 'edit',
  },
  {
    title: 'Question goes here #3',
    action: 'edit',
  },
  {
    title: 'Question goes here #4',
    action: 'edit',
  },
]
const AskQuestions = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isModalVisibleDelete, setModalVisibleDelete] = useState(false)
  console.log('setModalVisibleDelete', setModalVisibleDelete)
  return (
    // <AppLayout>
    <div>
      <div className="ps-30 pt-60">
        <div className="frequently-asked-ques">
          <h2 className="f-semibold">Frequently asked questions</h2>
        </div>
        <Row>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              onChange={onChange}
              className="orgn-tabs"
            >
              <TabPane tab="Mentor" key="1" className="tabpane-area">
                <Row>
                  <Col span={16}>
                    <List
                      itemLayout="horizontal"
                      dataSource={data1}
                      // const draggble = Item={data}
                      renderItem={item => (
                        <List.Item
                          className="question-list"
                          actions={[
                            <img
                              src={editblackPencil}
                              alt="edit-icon"
                              className="cursor_pointer"
                              width={20}
                            />,
                            <img
                              src={deleteBlackIcon}
                              onClick={() => {
                                setModalVisibleDelete(true)
                                // setCurrentData([]);
                              }}
                              alt="delete-icon"
                              className="cursor_pointer"
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            avatar={<img alt="img" src={Draggable} />}
                            title={
                              <a
                                href="https://ant.design"
                                className="b2 text-color-black mb-16 d-block"
                              >
                                {item.title}
                              </a>
                            }
                            description="Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be
                                                Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be "
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col span={8}>
                    <div
                      className="add-btn cursor_pointer b3 text-color-black pt-0 create-new-question d-flex"
                      onClick={() => {
                        setModalVisible(true)
                        // setCurrentData([]);
                      }}
                    >
                      <img alt="img" src={BluePencil} className="mr-10" />
                      Create new question
                    </div>
                  </Col>
                </Row>
                ,
              </TabPane>
              <TabPane tab="Parent" key="2" className="tabpane-area">
                <Row>
                  <Col span={16}>
                    {' '}
                    <List
                      itemLayout="horizontal"
                      dataSource={data1}
                      // const draggble = Item={data}
                      renderItem={item => (
                        <List.Item
                          className="question-list"
                          actions={[
                            <img
                              src={editblackPencil}
                              alt="edit-icon"
                              className="cursor_pointer"
                              width={20}
                            />,
                            <img
                              src={deleteBlackIcon}
                              onClick={() => {
                                setModalVisibleDelete(true)
                                // setCurrentData([]);
                              }}
                              alt="delete-icon"
                              className="cursor_pointer"
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            avatar={<img alt="img" src={Draggable} />}
                            title={
                              <a
                                href="https://ant.design"
                                className="b2 text-color-black mb-16 d-block"
                              >
                                {item.title}
                              </a>
                            }
                            description="Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be
                                                Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be "
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col span={8}>
                    <div
                      className="add-btn cursor_pointer b3 text-color-black pt-0 create-new-question d-flex"
                      onClick={() => {
                        setModalVisible(true)
                        // setCurrentData([]);
                      }}
                    >
                      <img alt="img" src={BluePencil} className="mr-10" />
                      Create new question
                    </div>
                  </Col>
                </Row>
                ,
              </TabPane>
              <TabPane tab="Organizer" key="3" className="tabpane-area">
                <Row>
                  <Col span={16}>
                    <List
                      itemLayout="horizontal"
                      dataSource={data1}
                      // const draggble = Item={data}
                      renderItem={item => (
                        <List.Item
                          className="question-list"
                          actions={[
                            <img
                              src={editblackPencil}
                              alt="edit-icon"
                              className="cursor_pointer"
                              width={20}
                            />,
                            <img
                              src={deleteBlackIcon}
                              onClick={() => {
                                setModalVisibleDelete(true)
                                // setCurrentData([]);
                              }}
                              alt="delete-icon"
                              className="cursor_pointer"
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            avatar={<img alt="img" src={Draggable} />}
                            title={
                              <a
                                href="https://ant.design"
                                className="b2 text-color-black mb-16 d-block"
                              >
                                {item.title}
                              </a>
                            }
                            description="Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be
                                                Here will be the answer, it can be Here will be the answer, it can be Here will be the answer, it can be "
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col span={8}>
                    <div
                      className="add-btn cursor_pointer b3 text-color-black pt-0 create-new-question d-flex"
                      onClick={() => {
                        setModalVisible(true)
                        // setCurrentData([]);
                      }}
                    >
                      <img alt="img" src={BluePencil} className="mr-10" />
                      Create new question
                    </div>
                  </Col>
                </Row>
                ,
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
      {isModalVisible ? (
        <AskQuestionModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
        />
      ) : (
        <></>
      )}
      {isModalVisibleDelete ? (
        <AskQuestionModal
          isModalVisibleDelete={isModalVisibleDelete}
          setModalVisibleDelete={setModalVisibleDelete}
        />
      ) : (
        <></>
      )}
    </div>
    // </AppLayout>
  )
}
export default AskQuestions
