import React, { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import AddNoteModal from '../Modal/AddNoteModal'

const TopBar: React.FC<any> = ({ id, familyId }) => {
  const [noteModalVisible, setNoteModalVisible] = useState<boolean>(false)

  const handleSearch = (e: any) => {}

  return (
    <div className="p-20px flex justify-between">
      <div className="flex justify-between align-center w-35p">
        <div
          className="right-search-block w-100 d-inline-block"
          style={{ maxWidth: '100%' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div className={'search-input-box'} style={{ marginRight: '15px' }}>
              <input
                type="search"
                className="search-input-group"
                placeholder="Search by Note name"
                onChange={handleSearch}
              />
              <SearchOutlined />
            </div>
          </div>
        </div>
        <Select
          className="custom-select-field  "
          value="Type:All"
          placeholder="sort by"
          style={{ width: 150 }}
        >
          <Select.Option value="firstname">Name</Select.Option>
        </Select>
      </div>

      <button className="borderd-btn" onClick={() => setNoteModalVisible(true)}>
        Add note
      </button>

      {noteModalVisible && (
        <AddNoteModal
          open={noteModalVisible}
          setOpen={setNoteModalVisible}
          familyId={familyId}
        />
      )}
    </div>
  )
}

export default TopBar
