const actions = {
  ADD_REWARDSDATA_DATA: "ADD_REWARDSDATA_DATA",
  ADD_PAYMENT_DATA:"ADD_PAYMENT_DATA",
  DELETE_REWARDSDATA_DATA: "DELETE_REWARDSDATA_DATA",
  // CURRENT_ORGANIZATION_DATA:"CURRENT_ORGANIZATION_DATA",
  CURRENT_MENU:"CURRENT_MENU",
  MODAL_VISIBLE:"MODAL_VISIBLE",
  PAYMENT_MODAL_VISIBLE : "PAYMENT_MODAL_VISIBLE",
  
  modalVisible:(payload=false)=>({
    type:actions.MODAL_VISIBLE,
    payload    
  }),

  paymentModalVisible:(payload=false)=>({
    type:actions.PAYMENT_MODAL_VISIBLE,
    payload
    
  }),
  addPayment: (payload) => ({
    type: actions.ADD_PAYMENT_DATA,
    payload
  }),
  addOrganization: (payload) => ({
    type: actions.ADD_REWARDSDATA_DATA,
    payload
  }),
  addOrganization: (payload) => ({
    type: actions.ADD_REWARDSDATA_DATA,
    payload
  }),

};

export default actions;