import actions from './action'

const initState = {
  user: {},
  adminObject: {},
  errorData: {},
  action: null,
  token: null,
  message: null,
  loading: false,
  authenticated: false,
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case actions.SIGNUP_REQUEST:
      return {
        ...state,
        errorData: {},
        action: action.type,
        token: null,
        loading: true,
        message: null,
      }
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        errorData: {},
        action: action.type,
        token: null,
        loading: true,
        message: null,
        authenticated: false,
      }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        action: action.type,
        loading: false,
        token: action.payload.token,
        authenticated: true,
      }

    case actions.LOGIN_ERROR:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        token: null,
        loading: false,
        message: action.payload,
      }
    case actions.LOGOUT_REQUEST:
      return {
        ...initState,
        action: action.type,
      }
    case actions.LOGOUT_SUCCESS:
      return {
        ...initState,
        user: {},
        token: null,
        action: action.type,
        authenticated: false,
      }
    case actions.LOGOUT_ERROR:
      return {
        ...state,
        action: action.type,
        loading: false,
      }

    default:
      return state
  }
}

export default auth
