import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Input, Col, Avatar, Radio, DatePicker } from 'antd'
import { Layout } from 'antd'
import userAvatarSvg from '../../../../assets/Images/userAvatar.svg'
import FileUploader from '../../../../helper/FileUploader'
import AllergyList from '../../../../helper/AllergyList'
import RelationsList from '../../../../helper/RelationsList'
import PhoneField from '../../../../helper/PhoneField'
import MapModal from 'helper/Map/MapModal'
import moment from 'moment'
import { convertToUtc } from 'helper/utility'
import { useDispatch, connect } from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Phone } from '@phosphor-icons/react'
import { boolean } from 'yup'
// import parsePhoneNumber from 'lib-phone-number'
const PersonalDetails = ({ handleNext, data, recentCheckIn }: any) => {
  const [phone, setPhone] = useState<any>({
    dialCode: '',
    isoCode: '',
    number: '',
  })
  const [touched, setTouched] = useState<boolean>(false)
  const [number, setNumber] = useState<string>()
  const [valid, setValid] = useState<any>()

  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState(
    recentCheckIn?.dropInAttendee?.photo?.post || undefined,
  )
  const [allergies, setAllergies] = useState(data?.allergies || [])
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<any>(data?.address)

  useEffect(() => {}, [])
  const formSchema = {
    photoId:
      data?.photoId || recentCheckIn?.dropInAttendee?.photoId || undefined,
    relationId: data?.relation?._id || data?.relationId || '',
    firstName:
      data?.firstName || recentCheckIn?.dropInAttendee?.firstName || '',
    lastName: data?.lastName || recentCheckIn?.dropInAttendee?.lastName || '',
    email: data?.email || undefined,
    // ...(data.email !== null && { email: data.email }),
    phone: data?.phone || '',
    allergies: data?.allergies || '',
    medicalNotes: data?.medicalNotes || '',
    gender: data?.gender || recentCheckIn?.dropInAttendee?.gender || '',
    dob:
      recentCheckIn?.dropInAttendee?.dob !== undefined
        ? moment(data?.dob || recentCheckIn?.dropInAttendee?.dob)
        : '',
  }
  const onFinish = (values: any) => {
    setTouched(true)
    let allergyData = allergies.map((alergy: any) => {
      return {
        _id: alergy._id || alergy.key,
        name: alergy.name || alergy.value,
      }
    })
    const phoneObject = {
      dialCode: phone.dialCode,
      isoCode: phone.isoCode,
      number: phone.number,
    }
    handleNext({
      ...values,
      dob: convertToUtc(values?.dob, 'YYYY-MM-DD'),
      isEmergencyContact: true,
      address: mapModalData,
      allergies: allergyData,
      phone: phoneObject,
    })
  }
  const onHandlePhone = value => {
    setPhone(value)
    // setValid(validatePhoneNumber(value?.number))
    setTouched(true)
  }
  const validatePhoneNumber = (_, phoneNumber: any) => {
    const phoneRegex = /^\d{9,}$/
    const isValid = phoneRegex.test(phoneNumber)
    if (!isValid) {
      return Promise.reject('Enter valid phone number')
    }
    return Promise.resolve()
  }

  return (
    <Layout>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <div className="modal-h-element">
          <Row gutter={24} align="middle">
            <Col span={4} className="mb-16">
              <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
            </Col>
            <Col span={18} className="mb-16">
              <Form.Item name="photoId" rules={[{ required: false }]}>
                <FileUploader
                  setAvatar={setAvatar}
                  name="adult"
                  form={form}
                  fileId={data?.photoId}
                />
              </Form.Item>
            </Col>
          </Row>
          <label htmlFor="" className="input-label required-input">
            Relationship
          </label>
          <Form.Item
            className="custom-select-field"
            name="relationId"
            rules={[{ required: true, message: 'Relationship is required' }]}
          >
            <RelationsList
              data={data?.relation}
              relationId={formSchema.relationId}
              form={form}
            />
          </Form.Item>

          <Row gutter={24} className="mt-30px">
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                FirstName
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                rules={[{ required: true, message: 'First Name required' }]}
              >
                <Input placeholder="FirstName" required name="firstname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Last Name
              </label>
              <Form.Item
                colon={false}
                name="lastName"
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input placeholder="Last Name" name="lastname" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label ">
                Email
              </label>
              <Form.Item
                name="email"
                rules={[
                  { required: false, message: 'Email is required' },
                  { type: 'email', message: 'Please enter valid email' },
                ]}
              >
                <Input placeholder="example@gmail.com" name="email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Phone number
              </label>
              <Form.Item
                className="customPhoneCode"
                name="phone"
                required
                // validateStatus={!valid ? 'error' : ''}
                // help={!valid && 'Enter valid phone number'}
                rules={[
                  { required: true, message: 'Phone number is required' },
                  { validator: validatePhoneNumber },
                ]}
              >
                <PhoneInput
                  country={'us'}
                  // disabled={number ? true : false}
                  value={phone}
                  // onChange={onHandlePhone}
                  onChange={(value, data: any, event, formattedValue) => {
                    // Call your custom function to handle phone input changes
                    onHandlePhone({
                      dialCode: data?.dialCode,
                      isoCode: data.countryCode,
                      number: value,
                    })
                  }}
                  inputProps={{ required: true }}

                  // onChange={(
                  //   value: any,
                  //   data: any,
                  //   event: any,
                  //   formattedValue: any,
                  // ): any => {
                  //   form.setFieldsValue({
                  //     phone: {
                  //       dialCode: data?.dialCode,
                  //       number: '+' + value,
                  //       isoCode: data.countryCode,
                  //     },
                  //   })
                  // }}
                />

                {/* <PhoneField form={form} data={data?.phone} /> */}
              </Form.Item>
              {/* {!valid && <p>Enter valid phone number</p>} */}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Date of Birth
              </label>
              <Form.Item
                name="dob"
                required
                rules={[{ required: true, message: 'Date is required' }]}
              >
                <DatePicker name="dob" className="selector w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mt-10px">
              <label htmlFor="" className="custom-field-label required-input">
                Gender
              </label>
              <Form.Item
                name="gender"
                colon={false}
                rules={[{ required: true, message: 'Gender is required' }]}
              >
                <Radio.Group name="gender" style={{ width: '100%' }}>
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="female"
                  >
                    Female
                  </Radio.Button>
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="male"
                  >
                    Male
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Home address
              </label>
              <Form.Item rules={[{ required: true }]}>
                <Input
                  placeholder="Location"
                  onClick={() => {
                    setMapModalVisible(true)
                  }}
                  value={
                    mapModalData
                      ? mapModalData.city + ',' + mapModalData.state
                      : ''
                  }
                />
                {mapModalVisible && (
                  <MapModal
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    mapData={mapModalData}
                  />
                )}{' '}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label ">
                Allergies
              </label>
              <Form.Item
                name="allergies"
                className="form-control allergies-field "
              >
                <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <label htmlFor="" className="input-label">
                Medical Notes
              </label>
              <Form.Item name="medicalNotes">
                <Input placeholder="Ex.: Asthma, Lactose intolerant" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="stepperNextButton">
          <Button type="primary" className="stepper-button" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </Layout>
  )
}
const mapRecentCheckIn = (state: any) => ({
  recentCheckIn: state.events.recentCheckIn,
})

export default connect(mapRecentCheckIn)(PersonalDetails)
