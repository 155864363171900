import { Row, Col, Form, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";

const FlexibleSession = ({ option }: any) => {
  return (
    <div>
      {option === "flexible" && (
        <Row gutter={[10, 0]}>
          <Col span={24}>
            <label className="input-label">Date (optional)</label>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  name="flexibleStartDate"
                  rules={[
                    {
                      required: true,
                      message: "Start date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="Start date"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="flexibleEndDate"
                  rules={[{ required: true, message: "End date is required" }]}
                >
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="End date"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Form.Item
              name="flexibleRestrictions"
              rules={[{ required: true, message: "Restrictions is required" }]}
            >
              <TextArea
                placeholder="Tell us more about your restrictions"
                rows={4}
              ></TextArea>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default FlexibleSession;
