import { Modal, Form, notification } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";

interface ChangeNameProps {
  id: any;
  notvisible: any;
}
const ChangeName: React.FC<ChangeNameProps> = ({ id, notvisible }) => {
  const [open, setOpen] = useState<boolean>(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });

  const dispatch = useDispatch();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const openNotification = (message: string, description: string) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  const onFinish = (event: any) => {
    event.preventDefault();
    formData.firstName && formData.lastName !== ""
      ? dispatch({
          type: actions.PATCH_ADMIN_DETAILS,
          payload: {
            userId: id,
            formData: {
              firstName: `${formData.firstName}`,
              lastName: `${formData.lastName}`,
            },
          },
        })
      : openNotification(
          "Please add new name",
          "You can't submit the form with out filling it"
        );
    formData.firstName && formData.lastName !== "" ? notvisible(false) : <></>;
  };
  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };

  return (
    <div className="changeModal">
      <Modal visible={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <h2>Change your name</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <form onSubmit={onFinish}>
            <div className="inputGroup">
              <label className="inp-label">First Name</label>
              <input
                type="string"
                name="firstName"
                className="my-input"
                placeholder="Enter your First name"
                onChange={handleInputChange}
                value={formData.firstName}
              />
            </div>
            <div className="inputGroup">
              <label className="inp-label">Last Name</label>
              <input
                type="string"
                name="lastName"
                className="my-input"
                placeholder="Enter your Last name"
                onChange={handleInputChange}
                value={formData.lastName}
              />
            </div>
            <div className="popup-footer">
              <button className="btn-sec pointer" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-prim pointer" type="submit">
                Change name
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
export default ChangeName;
