import { Modal } from "antd";
import { Layout } from "antd";
import DetailForm from "./DetailForm";
import NoteForm from "./NoteForm";

const NoteDetails = ({ isModalVisible, setModalVisible, handleModalVisible }: any) => {
  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    handleModalVisible(false);
  };

  return (
    <Layout>
      <Modal
        title="Note details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <DetailForm onCancel={handleCancel} />
      </Modal>
    </Layout>
  );
};
export default NoteDetails;
