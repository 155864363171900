import { useState, useEffect } from 'react'
import { Row, Col, Avatar } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import AgeGroupImg from 'assets/Images/ageGroup.svg'
import CategoryImageLink from 'helper/CategoryImageLink'
import { CaretRight, Question } from '@phosphor-icons/react'
import { useHistory } from 'react-router-dom'
import FaqModal from './FaqModel'
import { getEventById } from '../../../../api/Events'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
const EventDetails = ({ event }) => {
  // const { event } = props
  // previous code
  // const location = useLocation()
  // const event: any = location.state
  // console.log(event,'index')
  const { id }: any = useParams()
  const history = useHistory()
  // console.log(location,'location')
  const [model, setModel] = useState(false)
  // const eventId = id
  // const { data:event, isLoading, refetch } = useQuery(
  //   ['getEventById', id],
  //   () => {
  //     return getEventById(id)
  //   },
  //   { enabled: false },
  // )

  // useEffect(() => {
  //   if (eventId) {
  //     refetch()
  //   }
  // }, [eventId, refetch])

  //  console.log(event,'event2')

  const agesFunc = (minAge: number | null, maxAge: number | null) => {
    if (
      (minAge === null || minAge === 0) &&
      (maxAge === null || maxAge === 0)
    ) {
      return 'No age limits'
    } else if (minAge > 0 && maxAge > 0) {
      return `Ages ${minAge} - ${maxAge}`
    } else if ((minAge === null || minAge === 0) && maxAge > 0) {
      return `Age ${maxAge} and below`
    } else if ((maxAge === null || maxAge === 0) && minAge > 0) {
      return `Age ${minAge} and above`
    }
  }

  const gnederPreferenceFucn = (gender: 'all' | 'boys' | 'girls') => {
    if (gender === 'all') {
      return 'All allowed'
    } else if (gender === 'boys') {
      return 'Only boys'
    } else if (gender === 'girls') {
      return 'Only girls'
    }
  }

  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <Col span={12}>
        <div className="profile_container">
          <h5 className="text-20 w-600">About this event</h5>
          <p className="text-14 w-400 mt-10px">{event?.description}</p>
          <div className="flex justify-between mt-20px w-80p ">
            <div className="flex">
              <div>
                <CategoryImageLink id={event?.category?._id} />
              </div>
              <div className="ml-20px">
                <h4 className="text-12 grey-txt">Category</h4>
                <h4 className="text-12 w-500">{event?.category?.name}</h4>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={AgeGroupImg} alt="ageGroup" />
              </div>
              <div className="ml-20px">
                <h4 className="text-12 grey-txt">Age groups</h4>
                <h3 className="text-12 w-500">{`${agesFunc(
                  event.allowedAgeGroup?.minAge,
                  event.allowedAgeGroup?.maxAge,
                )}, ${gnederPreferenceFucn(
                  event.allowedAgeGroup?.gender,
                )}`}</h3>
              </div>
            </div>
            {event?.faqIds?.length > 0 && (
              <div
                className="flex"
                onClick={() => {
                  setModel(true)
                }}
              >
                <Question size={40} color="#000000" />
                <div className="ml-20px">
                  <h4 className="text-12 grey-txt">Event FAQs</h4>

                  <h3 className="text-12 w-500">{event?.faqIds?.length}</h3>
                </div>
              </div>
            )}
          </div>
          <div className="my-30px">
            <h5 className="text-20 w-600 ">Items to bring</h5>
            <ul className="mt-20px">
              {event?.itemsToBring[0] ? (
                event?.itemsToBring?.map((items: string, index: number) => {
                  return <li key={index}>{items}</li>
                })
              ) : (
                <h4 className="text-14 w-400">No items added yet</h4>
              )}
            </ul>
          </div>
          <div className="mb-30px">
            <h5 className="text-20 w-600 ">Disclaimer</h5>
            <p className="text-14 mt-10px">
              All refunds will provided as credit.
              <br /> Late pickup begins 15 minutes after the end of the event
              which may constitute in a $1 / minute late fee.
            </p>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div className="profile_container">
          <h5 className="text-20 w-600">Organization</h5>
          <Link to={`/organization-profile/${event?.organization?._id}`}>
            <div
              className="flex justify-between mt-20px align-center pointer"
              // onClick={() => {
              //   history.push(`/organization-profile/${event?.organization?._id}`)
              // }}
            >
              <div className="flex align-center">
                <Avatar src={event?.organization?.photo?.thumb} size={32}>
                  M
                </Avatar>
                <h4 className="text-14 w-500 ml-10px">
                  {event?.organization?.name}
                </h4>
              </div>
              <CaretRight />
            </div>
          </Link>
          <div className="flex justify-between mt-20px">
            <h5 className="text-20 w-500">Event Managers</h5>
            <h5 className="text-14 w-500 prim-txt">Manage</h5>
          </div>
          {event?.organizers?.map((item: any, index: number) => {
            return (
              <Link to={`/organizer-profile/${item?._id}`}>
                <div
                  className="flex justify-between mt-20px align-center pointer"
                  // onClick={() => {
                  //   history.push(`/organizer-profile/${item?._id}`)
                  // }}
                >
                  <div className="flex align-center">
                    <Avatar src={item?.photo?.thumb} size={32}>
                      M
                    </Avatar>
                    <h4 className="text-14 w-500 ml-10px">
                      {item?.firstName + ' ' + item?.lastName}
                    </h4>
                  </div>
                  <CaretRight />
                </div>
              </Link>
            )
          })}
        </div>
      </Col>
      {model && (
        <FaqModal eventId={event._id} setVisible={setModel} visible={model} />
      )}
    </Row>
  )
}
export default EventDetails
