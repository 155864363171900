import { Modal } from "antd";
import { Layout } from "antd";
import NoteForm from "./NoteForm";

const AddNoteModal = ({ Visible, isModalVisible, setModalVisible, contractRoomId }: any) => {
  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Layout>
      <Modal
        title="Add a note"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <NoteForm contractRoomId={contractRoomId} handleModalVisible={setModalVisible}/>
      </Modal>
    </Layout>
  );
};
export default AddNoteModal;
