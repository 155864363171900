/* eslint-disable import/no-anonymous-default-export */
import actions from './action'

const initState = {
  isModalVisible: false,
  familyData: [],
  currentFamilyData: {},
  activeFamilyData: [],
  pendingFamilyData: [],
  blockedFamilyData: [],
  family: null,
  parent: null,
  kids: [],
  guardian: null,
  authorizedAdultModalVisible: false,
  familyMembers: [],
  familyGuardian: [],
  kidsDataValue: {},
  kidsDetailData: [],
  totalCount: 0,
  assignkidsIds: [],
  noteAdded: false,
  currentPage: 1,
  defaultActivePage: '1',
  status: 'active',
}

export default (state = initState, action) => {
  switch (action.type) {
    case actions.FAMILY_MODAL_VISIBLE:
      return {
        ...state,
        isModalVisible: action.payload,
        currentFamilyData: {},
      }

    case actions.GET_FAMILIES:
      return {
        ...state,
        familyData: [],
        loading: true,
      }

    case actions.GET_FAMILIES_SUCCESS:
      return {
        ...state,
        totalCount: action.payload.count,
        familyData: action.payload.records,
        activeFamilyData: action.payload.records.filter(family => {
          console.log(action.payload.records + ' from redux')
          return family.status === 'active'
        }),
        pendingFamilyData: action.payload.records.filter(family => {
          return family.status === 'pending'
        }),
        blockedFamilyData: action.payload.records.filter(family => {
          return family.status === 'blocked'
        }),
        loading: false,
      }

    case actions.GET_FAMILIES_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.GET_FAMILY_GUARDIAN:
      return {
        ...state,
        familyGuardian: [],
        loading: true,
      }

    case actions.GET_FAMILY_GUARDIAN_SUCCESS:
      return {
        ...state,
        familyGuardian: action.payload,
        loading: false,
      }

    case actions.GET_FAMILY_GUARDIAN_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.GET_FAMILY:
      return {
        ...state,
        family: {},
        loading: true,
      }

    case actions.GET_FAMILY_SUCCESS:
      return {
        ...state,
        family: action.payload,
        activeFamilyData: action.payload.records.filter(family => {
          return family.status === 'active'
        }),
        pendingFamilyData: action.payload.records.filter(family => {
          return family.status === 'pending'
        }),
        blockedFamilyData: action.payload.records.filter(family => {
          return family.status === 'blocked'
        }),
        loading: false,
      }

    case actions.GET_FAMILY_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.UPDATE_FAMILY:
      return {
        ...state,
        family: {},
        loading: true,
      }

    case actions.UPDATE_FAMILY_SUCCESS:
      return {
        ...state,
        family: action.payload,
        loading: false,
        activeFamilyData:
          action.payload.status === 'active'
            ? state.family.map(family => {
                return family.id === action.payload.id ? action.payload : family
              })
            : state.activeFamilyData,
        pendingFamilyData: action.payload.status === 'pending',
      }

    case actions.UPDATE_FAMILY_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.UPDATE_FAMILY_STATUS:
      return {
        ...state,
        loading: true,
      }

    case actions.UPDATE_FAMILY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        activeFamilyData:
          action.payload.status === 'active'
            ? [...state.activeFamilyData, action.payload]
            : state.activeFamilyData.filter(family => {
                return family.id !== action.payload.id
              }),
        pendingFamilyData:
          action.payload.status === 'pending'
            ? [...state.pendingFamilyData, action.payload]
            : state.pendingFamilyData.filter(family => {
                return family.id !== action.payload.id
              }),
        blockedFamilyData:
          action.payload.status === 'blocked'
            ? [...state.blockedFamilyData, action.payload]
            : state.blockedFamilyData.filter(family => {
                return family.id !== action.payload.id
              }),
      }

    case actions.UPDATE_FAMILY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.AUTHORIZED_ADULT_MODAL_VISIBLE: {
      return {
        ...state,
        authorizedAdultModalVisible: action.payload,
      }
    }
    case actions.GET_AUTHORIZED_ADULT:
      return {
        ...state,
        parent: {},
        loading: true,
      }

    case actions.GET_AUTHORIZED_ADULT_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        loading: false,
      }

    case actions.GET_AUTHORIZED_ADULT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.ADD_AUTHORIZED_ADULT:
      return {
        ...state,
        parent: {},
        loading: true,
      }

    case actions.ADD_AUTHORIZED_ADULT_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        loading: false,
      }

    case actions.ADD_AUTHORIZED_ADULT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.AUTHORIZED_ADULT_UPDATE:
      return {
        ...state,
        parent: null,
        loading: true,
      }

    case actions.AUTHORIZED_ADULT_UPDATE_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        loading: false,
      }

    case actions.AUTHORIZED_ADULT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.DELETE_AUTHORIZED_ADULT:
      return {
        ...state,
        loading: true,
      }

    case actions.DELETE_AUTHORIZED_ADULT_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case actions.DELETE_AUTHORIZED_ADULT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.ADD_FAMILY_DATA: {
      localStorage.setItem('family', JSON.stringify(action.payload))
      return {
        ...state,
        familyData: action.payload,
        activeFamilyData:
          action.payload.status === 'active'
            ? [...state.activeFamilyData, action.payload]
            : state.activeFamilyData,
        pendingFamilyData:
          action.payload.status === 'pending'
            ? [...state.pendingFamilyData, action.payload]
            : state.pendingFamilyData,
        blockedFamilyData:
          action.payload.status === 'blocked'
            ? [...state.blockedFamilyData, action.payload]
            : state.blockedFamilyData,
      }
    }

    case actions.GET_FAMILY_KIDS:
      return {
        ...state,
        kids: [],
        loading: true,
      }

    case actions.GET_FAMILY_KIDS_SUCCESS:
      return {
        ...state,
        kids: action.payload,
        loading: false,
      }

    case actions.GET_FAMILY_KIDS_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.ADD_GUARDIAN:
      return {
        ...state,
        guardian: {},
        loading: true,
      }

    case actions.CREATE_GUARDIAN:
      return {
        ...state,
        guardian: {},
        loading: true,
      }

    case actions.ADD_GUARDIAN_SUCCESS:
      return {
        ...state,
        guardian: action.payload,
        loading: false,
        familyData: [...state.familyData, action.payload],
      }

    case actions.ADD_GUARDIAN_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.GET_GURADINA:
      return {
        ...state,
        parent: {},
        loading: true,
      }

    case actions.GET_GURADINA_SUCCESS:
      return {
        ...state,
        parent: action.payload,
        loading: false,
      }

    case actions.GET_GURADINA_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.CURRENT_FAMILY_DATA: {
      return {
        ...state,
        currentFamilyData: action.payload,
      }
    }

    case actions.ADD_KID:
      return {
        ...state,
        kidsDataValue: {},
        loading: true,
      }

    case actions.ADD_KID_SUCCESS:
      return {
        ...state,
        kidsDataValue: action.payload,
        loading: false,
      }

    case actions.ADD_KID_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.UPDATE_KID:
      return {
        ...state,
        kidsDataValue: {},
        loading: true,
      }

    case actions.UPDATE_KID_SUCCESS:
      return {
        ...state,
        kidsDataValue: action.payload,
        loading: false,
      }

    case actions.UPDATE_KID_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.DELETE_KID:
      return {
        ...state,
        loading: true,
      }

    case actions.DELETE_KID_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case actions.DELETE_KID_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.GET_KID_DETAIL:
      return {
        ...state,
        kidsDetailData: [],
        loading: true,
      }

    case actions.GET_KID_DETAIL_SUCCESS:
      return {
        ...state,
        kidsDetailData: action.payload,
        loading: false,
      }

    case actions.GET_KID_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.ASSIGN_KIDS_TO_ADULT:
      return {
        ...state,
        assignkidsIds: [],
        loading: true,
      }

    case actions.ASSIGN_KIDS_TO_ADULT_SUCCESS:
      return {
        ...state,
        assignkidsIds: action.payload,
        loading: false,
      }

    case actions.ASSIGN_KIDS_TO_ADULT_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case actions.ADD_NOTE_SUCCESS:
      return {
        ...state,
        noteAdded: !state.noteAdded,
      }

    case actions.CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }

    case actions.DEFAULT_ACTIVE_PAGE:
      return {
        ...state,
        defaultActivePage: action.payload.key,
        status: action.payload.status,
      }

    default:
      return state
  }
}
