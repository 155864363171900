import { Modal } from 'antd'
import { connect } from 'react-redux'
import { notification } from 'antd'
import Decrease from '../../../../../assets/Images/decrease.svg'
import Increase from '../../../../../assets/Images/increase.svg'
import React, { useEffect, useState } from 'react'
import Cancel from '../../../../../assets/Images/close.svg'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  AddFamilyCredits,
  getOrganizationCredits,
  DeductFamilyCredits,
} from '../../../../../api/family'
import OrganizationListing from '../../../../../components/ListingModals/OrganizationListingModal'
const ManageCredits = ({ closeModel, fId, fetchAgain }: any) => {
  const [error, setError] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(true)
  const [formData, setFormData] = useState<any>({
    credits: 0,
    newBalance: 0,
    orgData: null,
    notes: '',
  })
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target
    if (parseFloat(value) === 0) {
      event.target.value = ''
    }
  }
  const handleDataChange = (event: any) => {
    if (formData?.orgData == null) {
      notification['info']({
        message: 'Please select an organization',
      })
      return
    }

    const { value } = event.target
    if (value.trim() === '') {
      setFormData({
        ...formData,
        credits: '',
        newBalance: data?.credit,
      })
      return
    }

    if (value.startsWith('0') && value.length > 1) {
      const significantValue = parseFloat(value).toString()
      setFormData({
        ...formData,
        credits: significantValue,
        newBalance:
          parseFloat(significantValue) > 0
            ? data.credit + parseFloat(significantValue)
            : parseFloat(significantValue) + data.credit,
      })
    } else {
      setFormData({
        ...formData,
        credits: parseFloat(value),
        newBalance:
          parseFloat(value) > 0
            ? data.credit + parseFloat(value)
            : parseFloat(value) + data.credit,
      })
    }
  }
  const Intparser = (pdata: any) => {
    return parseInt(pdata?.toString(), 10)
  }
  const { data, refetch } = useQuery(
    ['getCredits', fId, formData?.orgData],
    () => getOrganizationCredits(fId, formData?.orgData),
    {
      enabled: false,
    },
  )
  useEffect(() => {
    formData?.orgData !== null ? refetch() : <></>
  }, [formData?.orgData])
  const mutation = useMutation({
    mutationFn: (mutData: any) => {
      return AddFamilyCredits(fId, mutData)
    },
    onSuccess: () => {
      fetchAgain()
    },
  })
  const deductCredits = useMutation({
    mutationFn: (mutData: any) => {
      return DeductFamilyCredits(fId, mutData)
    },
    onSuccess: () => {
      fetchAgain()
    },
  })
  const onFinish = (event: any) => {
    event.preventDefault()
    if (formData.credits === 0) {
      notification['info']({
        message: 'Credits must be greater than 0',
      })
      return
    }
    if (formData.notes.trim().length === 0) {
      setError(true)
      return
    }
    if (formData.credits > 0) {
      mutation.mutate({
        credit: formData?.credits,
        organizationId: formData?.orgData,
        adminId: localStorage.getItem('adminId'),
        notes: formData.notes,
      })
      closeModel(false)
      setOpen(false)
    } else {
      deductCredits.mutate({
        credit: data.credit - formData?.newBalance,
        organizationId: formData?.orgData,
        adminId: localStorage.getItem('adminId'),
        notes: formData.notes,
      })
      setOpen(false)
      closeModel(false)
    }
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, notes: event.target.value })
    setError(false) // Setting error to false when textarea value changes
  }
  const incre = () => {
    if (formData?.orgData == null) {
      notification['info']({
        message: 'Please select an organization',
      })
      return
    }
    const updatedValue = parseFloat(formData.credits) + 10
    setFormData({
      ...formData,
      credits: updatedValue,
      newBalance: parseFloat(data?.credit) + updatedValue,
    })
  }
  const decre = () => {
    if (formData?.orgData == null) {
      notification['info']({
        message: 'Please select an organization',
      })
      return
    }
    const updatedValue = parseFloat(formData.credits) - 10
    setFormData({
      ...formData,
      credits: updatedValue,
      newBalance: Intparser(data?.credit) + updatedValue,
    })
  }
  const handleCancel = () => {
    setOpen(false)
    closeModel(false)
  }

  return (
    <div className="changeModal">
      <Modal visible={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <h2 className="w-600">Manage Credits</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <form onSubmit={onFinish}>
            <div className="inputGroup">
              <label className="text-12 font-600">Select Organization</label>

              <div className="mt-10 ">
                <OrganizationListing
                  setData={setFormData}
                  prevData={formData}
                />
              </div>
            </div>
            <div className="inputGroup">
              <label className="text-12 font-600">Deduct or add credits</label>
              <div className="countergroup   mt-10px">
                <div className="counter flex">
                  <div className="dec pointer" onClick={decre}>
                    <img src={Decrease} alt="decrease" />
                  </div>
                  <input
                    type="number"
                    id="credits"
                    name="credits"
                    className="inp-counter"
                    value={formData.credits}
                    onChange={handleDataChange}
                    onFocus={handleFocus}
                  />
                  <div className="inc pointer" onClick={incre}>
                    <img src={Increase} alt="decrease" />
                  </div>
                </div>
                <div></div>
                <div className="">
                  <div className="flex justify-between">
                    <h4 className="text-14 w-600">Current balance: </h4>
                    <h4 className="text-14 w-400">${data?.credit}</h4>
                  </div>
                  <div className="flex justify-between">
                    <h4 className="text-14 w-600">New balance: </h4>
                    <h4 className="text-14 w-400">${formData.newBalance}</h4>
                  </div>
                </div>
              </div>
              <textarea
                placeholder="Leave a notes (optional)"
                className="mt-30px p-15px radius-12"
                style={{ height: '120px' }}
                id="notes"
                name="notes"
                onChange={handleNotesChange}
              />
              {error && (
                <p className="danger-txt mt-5px text-14">Note is required</p>
              )}
            </div>
            <div className="flex justify-center py-40px">
              <button className="btn-sec pointer" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-prim" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  loginUserId: state.auth.user,
  Uid: state.auth.userId,
})
export default connect(mapStateToProps)(ManageCredits)
