import { Modal, Button, Input } from "antd";
import { Layout } from "antd";
import TextArea from "antd/lib/input/TextArea";

const AskQuestionModal = ({
  isModalVisible,
  setModalVisible,
}: any) => {

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Layout>
      <Modal
        title="Add new question"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
      >
        <div>
          <p>Question</p>
          <Input placeholder="Question" />
        </div>
        <div>
          <p>Answer</p>
          <TextArea
            style={{ height: "194px", width: "600px" }}
            rows={4}
            placeholder="Answer goes here"
            maxLength={7}
          />
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "#2BA7CA",
              borderRadius: "20px",
              marginTop: "144px",
              width: "155px",
              height: "51px",
              left: "300px",
              color: "white",
              fontSize: "15px",
              fontWeight: 500,
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};
export default AskQuestionModal;
