import { all, put, takeLatest } from 'redux-saga/effects'
import actions from './action'
import { axiosGet, axiosPost, axiosPatch, axiosPut } from '../axiosHelper'
import failureAction from '../Failure/action'

export function* createMentor({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create-mentor`)
    yield put(actions.createMentorSuccess(data))
  } catch (error) {
    yield put(actions.createMentorFailure())
    yield put(failureAction.failure(error))
  }
}

export function* updateMentorSkills({ payload }) {
  try {
    const { data } = yield axiosPut(`v1/mentors/mentor`, payload)
    yield put(actions.updateMentorSkillsSuccess(data))
  } catch (error) {
    yield put(actions.updateMentorSkillsFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getMentorsRequest({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 10,
      populate: '[{"path":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/admins/mentors`, currentParams)
    yield put(actions.getMentorsSuccess(data))
  } catch (error) {
    yield put(actions.getMentorsFailure())
    yield put(failureAction.failure(error))
  }
}

export function* updateMentorStatus({ payload, params }) {
  try {
    const { data } = yield axiosPatch(payload, `v1/mentors/${params}/status`)
    yield put(actions.updateMentorStatusSuccess(data))
  } catch (error) {
    yield put(actions.updateMentorStatusFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getMentor({ payload, id }) {
  try {
    let params = {
      populate: '[{"path":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/mentors/${id}`, params)
    yield put(actions.getMentorSuccess(data))
  } catch (error) {
    yield put(actions.getMentorFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getMentorAnalytics({ payload, id }) {
  try {
    const { data } = yield axiosGet(`v1/mentors/${id}/analytics`)
    yield put(actions.getMentorAnalyticsSuccess(data))
  } catch (error) {
    yield put(actions.getMentorAnalyticsFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getMentorReviews({ payload, id }) {
  try {
    const { data } = yield axiosGet(`v1/reviews/get-mentor-reviews/${id}`)
    yield put(actions.getMentorReviewsSuccess(data))
  } catch (error) {
    console.log('analytics', error)
    yield put(actions.getMentorReviewsFailure())
    yield put(failureAction.failure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CREATE_MENTOR, createMentor),
    takeLatest(actions.UPDATE_MENTOR_SKILLS, updateMentorSkills),
    takeLatest(actions.GET_MENTORS, getMentorsRequest),
    takeLatest(actions.UPDATE_MENTOR_STATUS, updateMentorStatus),
    takeLatest(actions.GET_MENTOR, getMentor),
    takeLatest(actions.GET_MENTOR_ANALYTICS, getMentorAnalytics),
    takeLatest(actions.GET_MENTOR_REVIEWS, getMentorReviews),
  ])
}
