import React from "react";
import NavBar from "../sidebar/navbar";

interface propsType {
  id: String;
}

const AppLayout = ({ id, children }: any) => {
  return (
    <>
      <NavBar id={id}>{children}</NavBar>
    </>
  );
};
export default AppLayout;
