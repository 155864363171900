import { Avatar, Row, Space, Table, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import actions from "../../../Redux/mentors/action";
import DateIcon from "../../../assets/Images/date15.svg";
import Datemon from "../../../assets/Images/date22.svg";
import Datejul from "../../../assets/Images/date29.svg";
import DateTime from "../../../assets/Images/date11.svg";
import { connect, useDispatch } from "react-redux";
import { SorterResult } from "antd/es/table/interface";

const DayCalendar = ({ activeData, mentorsData }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: actions.GET_MENTORS,
    });
  }, []);

  console.log(mentorsData, "ss");
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const onChangeColumnCheckBox = (e: any) => {
    console.log(`checked = ${e.target.name}`);

    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    });
  };
  const dataSource = [
    {
      key: "1",
      name: <img src={DateIcon} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "2",
      name: <img src={Datemon} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "1",
      name: <img src={Datejul} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "2",
      name: <img src={Datemon} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "1",
      name: <img src={Datejul} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "2",
      name: <img src={Datejul} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
    {
      key: "1",
      name: <img src={Datejul} />,
      age: <img src={DateTime} />,
      address: "Math tutor (Amyra Nath)",
    },
  ];
  const columns = [
    {
      dataIndex: "name",
      key: "name",
    },
    {
      dataIndex: "age",
      key: "age",
    },
    {
      dataIndex: "address",
      key: "address",
    },
  ];

  const rowSelection = {};

  return (
    <Row>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="schedule-table"
      />
    </Row>
  );
};

export default DayCalendar;
