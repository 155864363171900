import { Modal } from "antd";
import { Layout } from "antd";
import PostAdd from "./Screens/PostAdd";
import React from "react";

const NewsModal = ({
  Visible,
  isModalVisible,
  setModalVisible,
  organizationData,
  currentData,
}: any) => {
  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <Layout>
      <Modal
        className="organization_model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>{Array.isArray(currentData) ? "Add  a News" : "Edit a News"} </h4>
        </div>
        <PostAdd organizationData={organizationData} editData={currentData} handleCancel = {handleCancel} />
      </Modal>
    </Layout>
  );
};
export default NewsModal;
