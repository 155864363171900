import React from "react";
import { Modal } from "antd";
import { Layout } from "antd";
import ActivityForm from "./ActivityForm";

const ActivityModal = ({
  Visible,
  isModalVisible,
  setModalVisible,
  setDefaultTabKey,
}: any) => {
  const handleOk = () => {
    Visible(false);
    setModalVisible(false);
  };
  return (
    <Layout>
      <Modal
        visible={isModalVisible}
        className="organization_model"
        onOk={handleOk}
        onCancel={() => {
          setModalVisible(false);
        }}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>Select Activity</h4>
        </div>
        <ActivityForm
          setModalVisible={setModalVisible}
          setDefaultTabKey={setDefaultTabKey}
        />
      </Modal>
    </Layout>
  );
};
export default ActivityModal;
