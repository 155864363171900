import { Col, Pagination, Row } from 'antd'
import AppLayout from 'components/layout/layout'
import React, { useEffect, useState } from 'react'
import PageHeaderNews from './Headers/PageHeaderNews'
import ActionHeader from './Headers/ActionHeader'
import BasicNewsLayout from './BasicNewsLayout'
import { useQuery } from '@tanstack/react-query'
import { getNews } from 'api/News'
import Spinner from 'components/Spinner'
import usePagination from 'helper/usePagination'
import Loader from 'helper/Loader'
export const News = () => {
  const {
    data: newsData,
    isLoading: newsLoading,
    isFetching: newsFetching,
    refetch: newsRefetch,
  } = useQuery<any>({
    queryKey: ['all-news'],
    queryFn: () => getNews(params),
    enabled: false,
  })
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
  })
  useEffect(() => {
    newsRefetch()
  }, [params])
  const { pinned, news } = newsData || {}

  const { count, totalPages, hasPrevPage, hasNextPage, records } = news || {}
  const handleSearch = (query: string) => {
    setParams({
      ...params,
      search: query,
    })
  }
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: news?.count || 0,
    currentPage: params.page,
    setParams: setParams,
  })
  return (
    <AppLayout>
      <PageHeaderNews data={news}></PageHeaderNews>
      <Row>
        <Col>
          <ActionHeader
            search={handleSearch}
            parms={params}
            setParms={setParams}
          ></ActionHeader>
        </Col>
        <Col>
          {newsLoading || newsFetching ? (
            <div
              className="flex justify-center align-center items-center"
              style={{ height: '80vh' }}
            >
              <Loader />
            </div>
          ) : (
            records?.map((item, index) => (
              <BasicNewsLayout key={index} newsData={item} parms={params} />
            ))
          )}
        </Col>
        <Col className="justify-center flex mr-20px ml-20px ">
          <Pagination
            className="pb-20"
            style={{ width: '100%', display: 'flex' }}
            {...paginationInfo}
            onChange={PaginationNoTabel}
          />
        </Col>
      </Row>
    </AppLayout>
  )
}
export default News
