import { Col, Row } from "antd";
const AvailablityData = [
  {
    id: 1,
    day: "Mon",
    availablity: "08:00 AM - 3:00 PM",
  },
  { id: 2, day: "Tue", availablity: "08:00 AM - 3:00 PM" },
  { id: 3, day: "Wed", availablity: "08:00 AM - 3:00 PM" },
  { id: 4, day: "Thu", availablity: "08:00 AM - 3:00 PM" },
  { id: 5, day: "Fri", availablity: "08:00 AM - 3:00 PM" },
  { id: 6, day: "Sat", availablity: "Not available" },
  { id: 7, day: "Sun", availablity: "Not available" },
];
const Availablity = () => {
  return (
    <div className="container" style={{ paddingTop: "40px" }}>
      {AvailablityData.map((item: any, index: any) => {
        return (
          <Row id={item.id} gutter={[24, 24]}>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 4 }}
              style={{ marginBottom: "24px" }}
            >
              <h3>
                <b className="b2">{item.day}</b>
              </h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <h4>
                <b className="b3">{item.availablity}</b>
              </h4>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};
export default Availablity;
