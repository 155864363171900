import { all, takeEvery, put, call, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPatch, axiosPost } from "../axiosHelper";
import { message } from "antd";

export function* getAdmins() {
  try {
    const req = yield axiosGet(`v1/admins/get-all-admins`);
    yield put(actions.getAllAdminsSuccess(req.data));
    yield put(message.success("got the data"));
  } catch (error) {
    yield put(actions.getAllAdminsFailure(error.message, error.data || {}));
    message.error(error.message);
  }
}

export function* getAdminDetails({ payload }) {
  try {
    const parms = { sendSessions: true };
    const req = yield axiosGet(`v1/admins/${payload}`, parms);
    yield put(actions.getAdminDetailsSuccess(req.data));
    yield put(message.success("Admin data found!!"));
  } catch (error) {
    yield put(actions.getAdminDetailsFailure(error.message, error.data || {}));
    message.error(error.message);
  }
}

export function* patchAdminDetails({ payload }) {
  try {
    const { userId, formData } = payload;
    console.log(userId, formData + "from redux");
    const req = yield axiosPatch(formData, `v1/admins/update/${userId}`);
    console.log(req + "from redux");
    yield put(actions.patchAdminSuccess(req));
    yield put(message.success("got the data"));
  } catch (error) {
    yield put(actions.patchAdminFailure(error.message, error.data || {}));
    message.error(error.message);
  }
}
export function* changeAdminPassword({ payload }) {
  try {
    const { password, firebaseId } = payload;
    const req = yield axiosPost(
      { password, firebaseId },
      `v1/users/set-new-password`
    );
    yield put(actions.changeAdminPasswordSuccess(req));
    yield put(message.success("Password changed successfully!!"));
  } catch (error) {
    yield put(
      actions.changeAdminPasswordFailure(error.message, error.data || {})
    );
    message.error(error.message);
  }
}
export function* deactivateAccount({ payload }) {
  try {
    const { firebaseId } = yield payload;
    const data = "";
    const req = yield axiosPost(data, `v1/users/${firebaseId}/deactivate`);
    yield put(actions.deactivateAdminSuccess(req));
    yield put(message.success("Account deactivated successfully!!"));
  } catch (error) {
    yield put(actions.deactivateAdminFailure(error.message, error.data || {}));
    message.error(error.message);
  }
}
export function* createNewAdmin({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create`);
    if (data) {
      yield put(actions.CreatenewAdminSuccess(data));
      yield put(message.success("New Admin created successfully!!"));
    } else {
      throw new Error("Invalid credentials provided.");
    }
  } catch (error) {
    yield put(actions.CreatenewAdminFailure(error.message, error.data || {}));
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ALL_ADMINS_REQUEST, getAdmins),
    takeLatest(actions.GET_ADMIN_DETAILS, getAdminDetails),
    takeLatest(actions.PATCH_ADMIN_DETAILS, patchAdminDetails),
    takeLatest(actions.CHANGE_ADMIN_PASSWORD_REQUEST, changeAdminPassword),
    takeLatest(actions.DEACTIVATE_ADMIN_REQUEST, deactivateAccount),
    takeLatest(actions.CREATE_NEW_ADMIN_REQUEST, createNewAdmin),
  ]);
}
