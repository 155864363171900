import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import Home from '.'
import Address from './Address'
import ViewAddress from './ViewAddress/index'

const MapModal = ({
  setMapModalVisible,
  setMapModalData,
  mapData,
  setFieldsValue,
}: any) => {
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState(mapData || null)

  const next = (childData: any) => {
    setCurrent(current + 1)
    setData({
      ...data,
      ...childData,
    })
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const onSubmit = (formData: any) => {
    setFieldsValue && setFieldsValue(formData)
    setMapModalData(formData)
    setMapModalVisible(false)
  }
  return (
    <div>
      <Modal
        title={current === 2 ? 'View address' : current === 0 ? data ? 'Change Address' : 'Add New address' : 'Set Location'}
        open={true}
        className="map-modal location-map-modal"
        footer={null}
        centered={true}
        width={600}
        onCancel={() => {
          setMapModalVisible(false)
        }}
      >
        {current === 0 && <Home next={next} mapData={data} />}
        {current === 1 && <Address next={next} prev={prev} mapData={data} />}
        {current === 2 && (<ViewAddress prev={prev} mapData={data} onSubmit={onSubmit} />)}
      </Modal>
    </div>
  )
}

export default MapModal
