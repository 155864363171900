import React, { useState, useEffect } from 'react'
import { Modal, Input, Button, Form, Row } from 'antd'
import { v4 as uuidv4 } from 'uuid'
function AddCustomAllergy({
  modalVisible,
  setModalVisible,
  setCustomAllergy,
  editingAllergy,
  customAllergy,
  setEditingAllergy,
}) {
  const [customAllergyData, setCustomAllergyData] = useState({
    _id: null,
    name: '',
  })
  const [form] = Form.useForm()

  useEffect(() => {
    if (editingAllergy) {
      setCustomAllergyData(editingAllergy)
      form.setFieldsValue({ custom_allergy: editingAllergy.name })
    } else {
      setCustomAllergyData({ _id: null, name: '' })
      form.resetFields()
    }
  }, [editingAllergy, modalVisible])

  const handleCancel = () => {
    form.resetFields()
    setEditingAllergy()
    setModalVisible(false)
    setCustomAllergyData({ _id: null, name: '' })
  }
  const hanldeFinish = values => {
    const newId = customAllergyData._id || uuidv4()
    const newAllergy = { _id: newId, name: values.custom_allergy }
    if (customAllergyData._id) {
      const updatedCustomAllergy = customAllergy.map(item =>
        item._id === customAllergyData._id
          ? { ...item, name: values.custom_allergy }
          : item,
      )
      setCustomAllergy(updatedCustomAllergy)
    } else {
      setCustomAllergy([...customAllergy, newAllergy])
    }
    setCustomAllergyData({ _id: null, name: '' })
    handleCancel()
  }
  return (
    <div>
      <Modal
        className="common_model"
        open={modalVisible}
        onCancel={handleCancel}
        centered={true}
        width={600}
        footer={null}
      >
        <div className="px-45 py-30 mt-20">
          <h3 className="mt-20">Add custom allergy</h3>
          <div className="mt-40">
            <Form
              form={form}
              onFinish={hanldeFinish}
              initialValues={{ custom_allergy: customAllergyData.name }}
            >
              <Form.Item name="custom_allergy" required>
                <Input
                  placeholder="e.g: Dust"
                  className="custom-input-field"
                  name="custom-allergy"
                />
              </Form.Item>
              <Row justify="space-between" align="bottom" className="mt-30">
                <Button
                  type="primary"
                  className="flex justify-center align-center prim-btn pointer"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="flex justify-center align-center prim-btn pointer"
                  htmlType="submit"
                >
                  {editingAllergy?.length !== 0 && editingAllergy !== undefined
                    ? 'Update'
                    : 'Save'}
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddCustomAllergy
