/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  amenitiesData: [],
  amenitiesDataValue: {},
  loading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.ADD_AMENITIES_DATA: {
      return {
        ...state,
        amenitiesDataValue: action.payload,
        loading: true,
      };
    }

    case actions.ADD_AMENITIES_DATA_SUCCESS: {
      return {
        ...state,
        amenitiesDataValue: action.payload,
        loading: false,
      };
    }

    case actions.ADD_AMENITIES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_AMENITIES_REQUEST: {
      return {
        ...state,
        amenitiesData: [],
        loading: true,
      };
    }

    case actions.GET_AMENITIES_REQUEST_SUCCESS: {
      return {
        ...state,
        amenitiesData: action.payload,
        loading: false,
      };
    }

    case actions.GET_AMENITIES_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};
