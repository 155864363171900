import { axiosGet, axiosPatch, axiosPatchCheckout } from '../Redux/axiosHelper'
import axios from 'axios'
import { BASE_URL, failureMessage, successMessage } from './global'
import { GetRequestParams, Populate, RequestParms } from '../types/globalTypes'
import { guardianProfile } from '../types/family'
import { parseParams } from '../helper/utility'

export const converArrayToQueryParms = async (key: string, arr: string[]) => {
  return arr.map(a => `${key}=${a}`).join('&')
}
export const getFamilies = async (
  params: RequestParms & Populate & GetRequestParams,
) => {
  try {
    const p = {
      ...params,
    }
    delete p.populate

    const response = await axiosGet(
      `v1/admins/families?${await converArrayToQueryParms(
        'populate',
        params.populate,
      )}`,
      p,
    )
    return response.data as any
  } catch (error) {
    throw error
  }
}
export const detletFamily = async (familyId: any) => {
  try {
    const response = await axios.delete(`${BASE_URL}v1/families/${familyId}`)
    if (response.data && response.status === 200) {
      successMessage('Family Deleted Successfully !')
    }
    return response
  } catch (error) {
    throw error
  }
}
export const changeFamilyStatus = async (params: string, payload: string) => {
  try {
    const response = await axiosPatchCheckout(
      { status: payload },
      `v1/admins/update-status/${params}`,
      `Family moved to ${payload} successfully`,
    )
    return response
  } catch (error) {
    throw error
  }
}
export const getFamilyCredits = async (familyId: string) => {
  const response = await axiosGet(`v1/families/${familyId}/all-credits`)
  return response.data
}
export const getOrganizationCredits = async (
  familyId: string,
  orgId: string,
) => {
  const response = await axiosGet(
    `v1/families/${familyId}/${orgId}/credits-info`,
  )
  return response.data
}
export const AddFamilyCredits = async (familyId: any, data: any) => {
  const response = await axios.patch(
    `${BASE_URL}v1/families/${familyId}/add-credits`,
    data,
  )

  return response?.data
}

export const DeductFamilyCredits = async (familyId: any, data: any) => {
  const response = await axios.patch(
    `${BASE_URL}v1/families/${familyId}/deduct-credits`,
    data,
  )
  return response.data
}

export const updateFamilyProfile = async (familyId: string, data: any) => {
  const response = await axiosPatch(data, `v1/families/${familyId}`)
  response.status === 200
    ? successMessage('Profile updated Successfully !')
    : failureMessage('Error while updating Profile')
}
export const GetFamily = async (familyId: string) => {
  const response = await axiosGet(`v1/families/${familyId}`)
  return response.data
}

export const GetStripeCustomer = async (familyId: string) => {
  const response = await axiosGet(`v1/stripe/stripe-customers/${familyId}`)
  return response.data
}
export const getFamilyHistory = async (familyId: any, params: any) => {
  const response = await axiosGet(`v1/families/${familyId}/history`, params)
  return response.data
}
export const updateFamilyMemberPass = async (
  firebaseId: string,
  password: string,
) => {
  const data = { firebaseId, password }
  const response = await axios.post(
    `${BASE_URL}v1/users/set-new-password`,
    data,
  )
  response.status === 200
    ? successMessage('Password changed Successfully !')
    : failureMessage('Error while updating Password')
  return response
}
export const getGuardian = async (id: string) => {
  const response = await axiosGet(`v1/guardians/${id}`)
  return response.data
}
export const updateGuardianProfile = async (
  params: string,
  payload: guardianProfile,
) => {
  const response = await axios.patch(
    `${BASE_URL}v1/guardians/${params}`,
    payload,
  )
  response.status === 200
    ? successMessage('Profile updated Successfully !')
    : failureMessage('Error while updating Profile')
}
export const getGuardianMedia = async (id: string) => {
  const response = await axiosGet(`v1/guardians/${id}/media`)
  return response.data
}
export const addNewKid = async (familyId: string, body: any) => {
  const response = await axios.post(
    `${BASE_URL}v1/kids/${familyId}/create-kid`,
    body,
  )
  return response.data
}
export const getKidbyId = async (id: string) => {
  const response = await axiosGet(`v1/kids/${id}`)
  return response.data
}

export const getAuthorizedAdultById = async (id: string) => {
  const response = await axiosGet(`v1/authorized-adults/${id}`)
  return response.data
}

export const getKidAuthorizedAdults = async (id: string) => {
  const response = await axiosGet(`v1/kids/${id}/authorized-adults`)
  return response.data
}
export const getRecentCheckIn = async (recentParams: any) => {
  const response = await axiosGet(
    `v1/check/recent-check-in/member/${recentParams?.memberId}`,
    recentParams,
  )
  return response.data
}
export const importFamilyFile = async (payload: any) => {
  try {
    const response = await axios.post(`${BASE_URL}v1/imports/guardian`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const AddFamilyCustomer = async (familyId, customerId: any) => {
  try {
    const response = await axios.post(
      `${BASE_URL}v1/stripe/add-customer/${customerId}/to-family/${familyId}`,
    )
    if (response.status === 200) {
      successMessage('Customer Added Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'AddCustomer error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(
        error.response.data.message || 'AddCustomer error occurred',
      )
    } else {
      failureMessage('AddCustomer error occurred')
    }
  }
}
export const confirmImportData = async payload => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/guardian`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}

export const getPaymentHistory = async (familyId: any, params: any) => {
  const pars = parseParams(params)
  const response = await axiosGet(
    `v1/payments/family-payment-history/${familyId}?${pars}`,
  )
  return response.data
}
export const getStripHistory = async (familyId: any, params: any) => {
  const pars = parseParams(params)
  const response = await axiosGet(
    `v1/payments/family-customers-payment-history/${familyId}?${pars}`,
  )
  return response.data
}
export const blockEmail = async (payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/users/block-email`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email block Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const removeCustomer = async (familyId: string, customerId: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/stripe/remove-customer/${customerId}/to-family/${familyId}`,
    )
    if (response.status === 200) {
      successMessage('Customer Remove Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const verifyEmail = async payload => {
  try {
    const response = await axios.put(
      `${BASE_URL}v1/users/verify-email`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Email verified Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const verifyPhone = async payload => {
  try {
    const response = await axios.put(
      `${BASE_URL}v1/users/verify-phone`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Phone verified Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
