import { Modal, Radio, Skeleton } from 'antd'

import { Attendees } from 'pages/Events/types'
import { getUserName } from 'helper/utility'
import org from 'assets/Images/organization.png'
import fam from 'assets/Images/family.png'
import Cancel from 'assets/Images/close.svg'
import { RadioButton } from '@phosphor-icons/react'
import { useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { checkOutParticipant } from 'api/Events'
import { useMutation } from '@tanstack/react-query'
import { failureMessage, successMessage } from 'api/global'
import Loader from 'helper/Loader'
// import { ConicGradient } from '@skeletonlabs/skeleton'
// import type { ConicStop } from '@skeletonlabs/skeleton'
interface checkoutModel {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  participant: Attendees
  body: any
  closePrev: () => void
}

const Checkout: React.FC<checkoutModel> = ({
  open,
  setOpen,
  participant,
  body,
  closePrev,
}) => {
  const handleCancel = () => {
    setOpen(false)
  }
  const [checkoutType, setCheckType] = useState<string>()
  const radioFn = (e: CheckboxChangeEvent) => {
    e.target.checked && setCheckType(e.target.name)
  }
  const mutation = useMutation({
    mutationFn: async ({ body }: any) => {
      return await checkOutParticipant(body)
    },
    onSuccess: () => {
      // successMessage(`Participant checkout successfully`)
      handleCancel()
      closePrev()
    },
  })
  const handleSubmit = async () => {
    if (checkoutType == 'direct' || checkoutType == 'request') {
      const data = body
      data.checkOutType = checkoutType
      mutation.mutateAsync({
        body,
      })
    } else {
      failureMessage('Please select the checkout type')
    }
  }
  return (
    <Modal open={open} onCancel={handleCancel} className="checkout-model">
      <div className="flex-center  pt-40px justify-between ">
        <h2 className="w-600 text-18">Checkout {getUserName(participant)} </h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="mt-30px text-center ">
        <div className="flex justify-center">
          <h2 className="text-24 w-600 w-80p">
            Chose how you want to checkout
          </h2>
        </div>
        <div className="grid-50 mt-30px">
          <div>
            <img src={org} alt="organization" />
            <h2 className="text-16 mt-20px w-500">Direct Checkout</h2>
            <h4 className="text-12 mt-10px">
              Direct checkout as a organizer. No need of approvel from family
            </h4>
            <Radio
              name="direct"
              className="mt-10px"
              checked={checkoutType == 'direct'}
              onChange={radioFn}
            ></Radio>
          </div>

          <div>
            <img src={fam} alt="organization" />
            <h2 className="text-16 mt-20px w-500">Request Checkout</h2>
            <h4 className="text-12 mt-10px">
              Send request to family to allow checkout from app
            </h4>
            <Radio
              name="request"
              className="mt-10px"
              checked={checkoutType == 'request'}
              onChange={radioFn}
            ></Radio>
          </div>
        </div>
        <button className="form-btn pointer mt-30px" onClick={handleSubmit}>
          {mutation.isLoading ? <Loader /> : 'Checkout'}
        </button>
      </div>
    </Modal>
  )
}
export default Checkout
