import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Layout } from 'antd'
import { Steps, message } from 'antd'
import { useDispatch } from 'react-redux'
import actions from '../../../../Redux/Family/action'
import fileActions from '../../../../Redux/FileUpload/action'

import PersonalDetails from './PersonalDetails'
import SelectChild from './SelectChild'
import Cancel from 'assets/Images/close.svg'
const { Step } = Steps

const AdultsModal = ({
  isModalVisible,
  setModalVisible,
  familyId,
  adultData,
  setMembersModalVisible,
  fetchAgain,
}: any) => {
  const dispatch = useDispatch()
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState(adultData)

  useEffect(() => {
    console.log('adultData:', adultData)
  }, [])

  const steps = [
    {
      title: 'Personal Details',
    },
    {
      title: 'Select child',
    },
  ]

  const next = (data: any) => {
    setData(data)
    setCurrent(current + 1)
    onSubmit(data)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const handleOk = () => {
    setModalVisible(false)
  }

  const handleCancel = () => {
    setModalVisible(false)
    dispatch({
      type: fileActions.CLEAR_FILE_DATA,
    })
  }

  const onSubmit = (formData: any) => {
    if (adultData) {
      dispatch({
        type: actions.AUTHORIZED_ADULT_UPDATE,
        payload: formData,
        adultId: adultData.id,
        familyId: familyId,
      })
    } else {
      dispatch({
        type: actions.ADD_AUTHORIZED_ADULT,
        payload: formData,
        familyId: familyId,
      })
    }
    // setMembersModalVisible(false);
    // setModalVisible(false);
    dispatch({
      type: fileActions.CLEAR_FILE_DATA,
    })
    fetchAgain()
  }

  return (
    <Layout>
      <Modal
        className="organization_model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>
            {adultData ? 'Edit Authorized Adult' : 'Add Authorized Adult'}{' '}
          </h4>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} className="stepper" title={item.title} />
            ))}
          </Steps>
        </div>
        <div>
          {current === 0 && <PersonalDetails data={data} handleNext={next} />}
          {current === 1 && (
            <SelectChild
              data={data}
              handleSubmit={onSubmit}
              handleBack={prev}
              familyId={familyId}
              setMembersModalVisible={setMembersModalVisible}
              setModalVisible={setModalVisible}
            />
          )}
        </div>
      </Modal>
    </Layout>
  )
}
export default AdultsModal
