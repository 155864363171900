import { Avatar, Card, Col, Progress, Rate, Row, Space, Tooltip } from "antd";
import Meta from "antd/lib/card/Meta";
import deleteSvg from "../../../assets/Images/delete_red.svg";

const Reviews = ({ reviews}: any) => {
  interface ReviewDataType {
    name: String;
    star: number;
    date: String;
    comment: String;
  }

  const profile = [
    {
      name: "Hugh Jackman",
      star: 3,
      date: "19 Dec 2020",
      comment: "Very good organized events! ",
    },
    {
      name: "Hugh Jackman",
      star: 2,
      date: "19 Dec 2020",
      comment: "Very good organized events! ",
    },
    {
      name: "Hugh Jackman",
      star: 4,
      date: "19 Dec 2020",
      comment: "Very nice ",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
      comment: "Very good organized events! ",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
      comment: "Very good organized events! ",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
      comment: "",
    },
  ];

  return (
    <div className=" bg-grey padding-top-10">
      <div className="review-card-main-bg">
            <Row gutter={10}>
              {reviews.length !== 0 ? <Col lg={12} span={24}>
                <Card
                    style={{ borderRadius: "0px" }}
                    className="review-progress-card"
                >
                  <Row align="middle">
                    <Col span={8} className="text-center">
                      <Space direction="vertical" size={1}>
                        <h1 style={{ fontSize: "40px", marginBottom: "0px" }}>
                          <b className="b1">4.8</b>
                        </h1>
                        <Rate defaultValue={5} style={{ fontSize: "20px" }}></Rate>
                        <h5>
                          <b className="b3">12 Reviews</b>
                        </h5>
                      </Space>
                    </Col>
                    <Col span={16}>
                      <div>
                        <Space
                            direction="vertical"
                            size={1}
                            style={{ width: "100%" }}
                        >
                          <div className="d-flex align-center reviews-progress-bar">
                            <span>5 Star</span>
                            <Progress
                                size="small"
                                strokeWidth={12}
                                strokeColor="#8CC73F"
                                percent={70}
                            />
                          </div>
                          <div className="d-flex align-center reviews-progress-bar">
                            <span>4 Star</span>
                            <Progress
                                size="small"
                                strokeWidth={12}
                                strokeColor="#8CC73F"
                                percent={50}
                            ></Progress>
                          </div>
                          <div className="d-flex align-center reviews-progress-bar">
                            <span>3 Star</span>
                            <Progress
                                size="small"
                                strokeWidth={12}
                                strokeColor="#8CC73F"
                                percent={30}
                            ></Progress>
                          </div>
                          <div className="d-flex align-center reviews-progress-bar">
                            <span>2 Star</span>
                            <Progress
                                size="small"
                                strokeWidth={12}
                                strokeColor="#8CC73F"
                                percent={20}
                            ></Progress>
                          </div>
                          <div className="d-flex align-center reviews-progress-bar">
                            <span>1 Star</span>
                            <Progress
                                size="small"
                                strokeWidth={12}
                                strokeColor="#8CC73F"
                                percent={10}
                            ></Progress>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col> :
                  <Card
                      style={{ borderRadius: "0px" , alignSelf : "center" }}
                      className="review-progress-card"
                  >
                  <b className="b1">No Reviews Yet</b>
                  </Card>
              }
          <Col lg={12} span={24}>
            {reviews.map((profileItem: ReviewDataType) => {
              return (
                  <Card
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        borderRadius: "0px",
                      }}
                  >
                    <Row justify="space-between">
                      <Meta
                          avatar={
                            <Avatar
                                size={40}
                                src="https://joeschmoe.io/api/v1/random"
                            />
                          }
                          title={
                            <Tooltip
                                title={profileItem.name}
                                color={"blue"}
                                placement="bottom"
                            >
                              <h4>
                                <b className="b2">{profileItem.name}</b>
                              </h4>
                            </Tooltip>
                          }
                          description={
                            <Tooltip
                                title={profileItem.date}
                                color={"blue"}
                                placement="bottom"
                            >
                              <h5>
                                <b className="b3"> {profileItem.date}</b>
                              </h5>
                            </Tooltip>
                          }
                      />
                      <Tooltip title="Delete" color={"blue"} placement="bottom">
                        <img
                            alt=""
                            src={deleteSvg}
                            className="delete-icon-reviews"
                        ></img>
                      </Tooltip>
                    </Row>
                    <Row>
                      <Rate value={profileItem.star}></Rate>
                    </Row>
                    <Row>
                      <h5>
                        <b className="b3">{profileItem.comment}</b>{" "}
                      </h5>{" "}
                    </Row>
                  </Card>
              );
            })}
          </Col>
        </Row>

      </div>
    </div>
  );
};

export default Reviews;
