import React, { useState, useEffect } from "react";
import { Area } from "@ant-design/plots";

const AreaPlot = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  
  const config = {
    data,
    height: 400,
    xField: "Date",
    yField: "scales",
    point: {
      size: 3,
      shape: "diamond | circule",
    },
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#c4bfe3 1:#8676E8",
      };
    },
  };

  return <Area {...config} />;
};

export default AreaPlot;
