import { Form, Row, Input, Col, Popover } from 'antd'
import {
  personalityTrait,
  sensitiveTopic,
  favoriteSubj,
  dislikeSubj,
} from '../Misc/PopoverContent'

import { Info } from '@phosphor-icons/react'
const Personality = ({ handleNext, handleBack, handleCancel, data }: any) => {
  const [form] = Form.useForm()
  // useEffect(() => {
  //     console.log("data is:", data);
  // }, [data]);
  var formSchema = {
    favoriteColor: data?.personalities?.favoriteColor || '',
    personality: data?.personalities?.personality || '',
    sensitiveSubject: data?.personalities?.sensitiveSubject || '',
    favoriteSubject: data?.personalities?.favoriteSubject || '',
    leastFavoriteSubject: data?.personalities?.leastFavoriteSubject || '',
  }
  const onFinish = (formData: any) => {
    // const dataValues = { personalities: formData }
    handleNext({ ...data, personalities: formData })
  }
  return (
    <Form
      name="nest-messages"
      initialValues={formSchema}
      form={form}
      onFinish={onFinish}
      layout={'vertical'}
      autoComplete="off"
    >
      <div className="modal-h-element">
        <Row>
          <Col span={12}>
            <label htmlFor="" className="custom-field-label">
              Favorite Color{' '}
            </label>
            <Form.Item name="favoriteColor" colon={false}>
              <Input className="custom-input-field" name="favoriteColor" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor="" className="flex items-center">
              Personality Trait{' '}
              <Popover content={personalityTrait} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name="personality" colon={false}>
              <Input className="custom-input-field" name="personality" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor="" className="flex items-center">
              Senitive Subjects{' '}
              <Popover content={sensitiveTopic} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name="sensitiveSubject" colon={false}>
              <Input className="custom-input-field" name="sensitiveSubject" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor="" className="flex items-center">
              Favorite School Subject{' '}
              <Popover content={favoriteSubj} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name="favoriteSubject" colon={false}>
              <Input className="custom-input-field" name="favoriteSubject" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <label htmlFor="" className="flex items-center">
              Least Favorite School Subject{' '}
              <Popover content={dislikeSubj} title="" placement="right">
                <Info size={24} className="ml-10px" />
              </Popover>
            </label>
            <Form.Item name="leastFavoriteSubject" colon={false}>
              <Input
                className="custom-input-field"
                name="leastFavoriteSubject"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="flex justify-end w-full">
        <div className="px-30px py-15px bg-grey radius-8 pointer mr-30px">
          <h3
            // onClick={() => handleBack(data)}
            onClick={handleCancel}
            className="text-14"
          >
            Continue Later
          </h3>
        </div>
        <button className="prim-btn" type="submit">
          Next
        </button>
      </div>
    </Form>
  )
}
export default Personality
