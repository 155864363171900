import { Avatar, Modal } from "antd";
import Cancel from "../../../../../assets/Images/close.svg";
import { Steps } from "antd";
import { useState } from "react";
import ChangePassword from "./ChangePasswordModal";

const { Step } = Steps;
const FamilyMembersModal = ({ visbleModel, data }: any) => {
  const handleCancel = () => {
    visbleModel(false);
  };
  const [click, setClick] = useState<number>();
  const [current, setCurrent] = useState<number>(0);
  const [clickData, setClickData] = useState();
  const handleContinue = () => {
    if (click || click === 0) {
      setCurrent(1);
    }
  };

  return (
    <Modal onCancel={handleCancel} className="popup-modal" visible={true}>
      <div>
        <div className="popup-header">
          <h2 className="w-600">Change Password</h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>

        <Steps size="small" current={current}>
          <Step title="Select member" />
          <Step title="Change Password" />
        </Steps>
        {current === 0 ? (
          <div>
            <div className="pb-30px h-20 overflow-auto">
              {data?.guardians[0] ? (
                <h5 className="text-14 w-600 mt-20 ">Guardians</h5>
              ) : (
                <></>
              )}
              {data?.guardians?.map((gData: any, index: number) => {
                return (
                  <div
                    className={
                      click === index
                        ? "active-family"
                        : "flex  item-center align-center mt-15px pointer"
                    }
                    key={gData.id}
                    onClick={() => {
                      setClick(index);
                      setClickData(gData);
                    }}
                  >
                    <Avatar src={gData?.photo?.post} size={45}>
                      M
                    </Avatar>
                    <div className="ml-10px">
                      <h4 className="b2 ">
                        {gData?.firstName} {gData?.lastName}
                      </h4>
                      <h5 className="b2 text-secondary">
                        {gData?.relation?.name}
                      </h5>
                    </div>
                  </div>
                );
              })}
              {data?.kids[0] ? (
                <h5 className="text-14 w-600 mt-20 ">Kids</h5>
              ) : (
                <></>
              )}
              {data?.kids?.map((kData: any, index: number) => {
                return (
                  <div
                    className={
                      click === index + 10
                        ? "active-family"
                        : "flex  item-center align-center mt-15px pointer"
                    }
                    onClick={() => {
                      setClick(index + 10);
                      setClickData(kData);
                    }}
                    key={kData.id}
                  >
                    <Avatar src={kData?.photo?.post} size={45}>
                      M
                    </Avatar>
                    <div className="ml-10px">
                      <h4 className="b2 ">
                        {kData?.firstName} {kData?.lastName}
                      </h4>
                      <h5 className="b2 text-secondary">
                        {kData?.relation?.name}
                      </h5>
                    </div>
                  </div>
                );
              })}
              {data?.adults[0] ? (
                <h5 className="text-14 w-600 mt-20 mb-20">Adults</h5>
              ) : (
                <></>
              )}
              {data?.adults?.map((aData: any, index: number) => {
                return (
                  <div
                    className={
                      click === index + 20
                        ? "active-family"
                        : "flex  item-center align-center mt-15px pointer"
                    }
                    onClick={() => {
                      setClick(index + 20);
                      setClickData(aData);
                    }}
                    key={aData.id}
                  >
                    <Avatar src={aData?.photo?.post} size={45}>
                      M
                    </Avatar>
                    <div className="ml-10px">
                      <h4 className="b2 ">
                        {aData?.firstName} {aData?.lastName}
                      </h4>
                      <h5 className="b2 text-secondary">
                        {aData?.relation?.name}
                      </h5>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center py-40px">
              <button className="btn-sec pointer" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className={
                  click === 0 || click ? "btn-prim" : "disabled-button"
                }
                type="submit"
                onClick={handleContinue}
              >
                Continue
              </button>
            </div>
          </div>
        ) : (
          <div>
            <ChangePassword
              data={clickData}
              back={setCurrent}
              close={visbleModel}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default FamilyMembersModal;
