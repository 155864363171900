import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { Layout } from "antd";
import { Steps, message } from "antd";
import { SERVFAIL } from "dns";
import { useDispatch, connect } from "react-redux";
import settingAction from "../../../../Redux/Settings/action";
import PaymentDataForm from "./PaymentDataForm";

const AddPaymentModal = ({ match, modalVisible, currentData }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setIsModalVisible(modalVisible);
  });
  const handleOk = () => {
        dispatch({
      type: settingAction.PAYMENT_MODAL_VISIBLE,
      payload: false,
    });
  };

  const handleCancel = () => {
    // setIsModalVisible(false)
    dispatch({
      type: settingAction.PAYMENT_MODAL_VISIBLE,
      payload: false,
    });
  };
  const onSubmit = (data: any) => {
    console.log("data is ", data);
    setFormData(data);

    var a = [];
    a = JSON.parse(localStorage.getItem("events") || "[]") || [];
    a.push(data);
    console.log(a);

    dispatch({ type: settingAction.ADD_PAYMENT_DATA, payload: a });
    message.success("completed");
    setIsModalVisible(false);
    dispatch({
      type: settingAction.PAYMENT_MODAL_VISIBLE,
      payload: false,
    });
  };
  return (
    <Layout>
      <Modal
        className="organization_modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <PaymentDataForm />
      </Modal>
    </Layout>
  );
};
export default AddPaymentModal;
