import auth from "./Auth/reducer";
import organization from "./Organization/reducer";
import mentor from "./mentors/reducer";
import family from "./Family/reducer";
import newsFeed from "./NewsFeed/reducer";
import jobRequest from "./JobRequest/reducer";
import events from "./Events/reducer";
import settings from "./Settings/reducer";
import organizer from "./Organizer/reducer";
import fileUpload from "./FileUpload/reducer";
import failure from "./Failure/reducer";
import allergies from "./Allergies/reducer";
import relations from "./Relations/reducer";
import skills from "./Skills/reducer";
import eventVenue from "./EventVenue/reducer";
import amenities from "./Amenities/reducer";
import session from "./Session/reducer";

import admin from "./Admin/reducer";
import locations from "./Locations/reducer";
import lists from "./Lists/reducer";
export default {
  auth,
  organization,
  mentor,
  family,
  newsFeed,
  jobRequest,
  events,
  settings,
  organizer,
  fileUpload,
  failure,
  allergies,
  relations,
  skills,
  amenities,
  eventVenue,
  session,
  admin,
  locations,
  lists,
};
