// import ParticpantCard from './ParticipantsCard'

// const Participants = ({ ungrouped, grouped }: any) => {
//   let AllGroupedParticpents: any[] = []
//   let UnGroupedParticpents = [
//     ...(ungrouped?.guardians || []),
//     ...(ungrouped?.kids || []),
//     ...(ungrouped?.authorizedAdults || []),
//   ]

//   grouped.map((data: any) => {
//     AllGroupedParticpents = [
//       ...(data?.guardianParticipants || []),
//       ...(data?.kidsParticipants || []),
//       ...(data?.authorizedAdultParticipants || []),
//     ]
//   })
//   const groupedParticipants = AllGroupedParticpents.filter((item: any) => item)
//   return (
//     <div>
//       {UnGroupedParticpents[0] && (
//         <h2 className="text-16 w-600 mt-20px">Not assigned to a group</h2>
//       )}

//       {UnGroupedParticpents.map((item: any, index: number) => {
//         return <ParticpantCard key={index} data={item} />
//       })}

//       {groupedParticipants[0] && (
//         <h2 className="text-16 w-600 mt-20px">grouped</h2>
//       )}
//       {groupedParticipants.map((item: any, index: number) => (
//         <ParticpantCard key={index} data={item} />
//       ))}
//     </div>
//   )
// }
// export default Participants

import ParticipantCard from './ParticipantsCard'

const Participants = ({ ungrouped, grouped }: any) => {
  let AllGroupedParticipants: any[] = []

  // Combine ungrouped participants
  let UnGroupedParticipants = [
    ...(ungrouped?.guardians?.map((g: any) => ({ ...g, type: 'Guardian' })) ||
      []),
    ...(ungrouped?.kids?.map((k: any) => ({ ...k, type: 'Kid' })) || []),
    ...(ungrouped?.authorizedAdults?.map((a: any) => ({
      ...a,
      type: 'AuthorizedAdult',
    })) || []),
  ]

  // Combine grouped participants
  grouped.map((data: any) => {
    AllGroupedParticipants = [
      ...(data?.guardianParticipants?.map((g: any) => ({
        ...g,
        type: 'Guardian',
      })) || []),
      ...(data?.kidsParticipants?.map((k: any) => ({ ...k, type: 'Kid' })) ||
        []),
      ...(data?.authorizedAdultParticipants?.map((a: any) => ({
        ...a,
        type: 'AuthorizedAdult',
      })) || []),
    ]
  })

  const groupedParticipants = AllGroupedParticipants.filter((item: any) => item)

  return (
    <div>
      {/* Ungrouped Participants */}
      {UnGroupedParticipants[0] && (
        <h2 className="text-16 w-600 mt-20px">Not assigned to a group</h2>
      )}
      {UnGroupedParticipants.map((item: any, index: number) => (
        <ParticipantCard key={index} data={item} />
      ))}

      {/* Grouped Participants */}
      {groupedParticipants[0] && (
        <h2 className="text-16 w-600 mt-20px">grouped</h2>
      )}
      {groupedParticipants.map((item: any, index: number) => (
        <ParticipantCard key={index} data={item} />
      ))}
    </div>
  )
}

export default Participants
