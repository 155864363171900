import { Row, Table } from 'antd'
import { convertMinutesToLocalTime, convertToLocal } from 'helper/utility'
import { useEffect, useState } from 'react'
import { RequestDetailResp } from '../types'

interface ScheduleInteface {
  data: RequestDetailResp | undefined
}
const Schedule: React.FC<ScheduleInteface> = ({ data }) => {
  const [schedule, setSchedule] = useState<any>([])

  useEffect(() => {
    if (!data) return

    let dataSource = []

    if (data.timesPerWeek === 'one-time') {
      dataSource.push({ ...data.oneTimeSessionSchedule, type: 'one-time' })
    } else if (data.timesPerWeek === 'recurring') {
      dataSource.push({ ...data.recurringSessionSchedule, type: 'recurring' })
    } else if (data.timesPerWeek === 'flexible') {
      dataSource.push({ ...data.flexibleSessionSchedule, type: 'flexible' })
    }

    setSchedule(dataSource)
  }, [data])
  return (
    <div className="mx-40px py-40px h-80vh overflow-auto">
      
      {schedule ? (
        schedule.map((time: any, index: number) => {
          return (
            <div key={index} className=" ">
              {time.type === 'one-time' && (
                <div className="flex justify-between">
                  <div className="flex-center">
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50px',
                        backgroundColor: '#2ba7ca',
                      }}
                      className="flex-center justify-center"
                    >
                      <h2 style={{ color: '#fff' }} className="text-14 w-500">
                        {convertToLocal(time.startTime, 'DD')}
                      </h2>
                    </div>
                    <h2 className="text-16 ml-10px">
                      {convertToLocal(time.startTime, 'MMM, dddd')}
                    </h2>
                  </div>
                  <div className="flex-center">
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#2ba7ca',
                        borderRadius: '40px',
                        marginRight: '10px',
                      }}
                    ></div>
                    <h2 className="text-14">
                      {convertToLocal(time.startTime, 'h:mm A')} -
                      {'  ' + convertToLocal(time.endTime, 'h:mm A')}
                    </h2>
                  </div>
                  <h2 className="text-16">One Time</h2>
                </div>
              )}
              {time.type === 'recurring' && (
                <div className="mt-20px">
                  {Object.keys(time).map(key => {
                    if (key !== 'type') {
                      const dateInfo = time[key]
                      return (
                        <div key={key} className="flex justify-between mt-20px">
                          <div className="flex-center w-200px">
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50px',
                                backgroundColor: '#2ba7ca',
                              }}
                              className="flex-center justify-center"
                            >
                              <h2
                                style={{ color: '#fff' }}
                                className="text-14 w-500"
                              >
                                {convertToLocal(dateInfo.date, 'DD')}
                              </h2>
                            </div>
                            <h2 className="text-16 ml-10px">
                              {convertToLocal(dateInfo.date, 'MMM, dddd')}
                            </h2>
                          </div>
                          <div className="w-200px">
                            {dateInfo.slots.map((slot: any, index: any) => (
                              <div key={index} className=" text-start">
                                {convertMinutesToLocalTime(slot.start) +
                                  ' - ' +
                                  convertMinutesToLocalTime(slot.end)}
                              </div>
                            ))}
                          </div>
                          <h2 className="text-16 w-200px">Reccuring</h2>
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              )}
              {time.type === 'flexible' && (
                <div className="flex justify-between">
                  <div className="flex-center">
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50px',
                        backgroundColor: '#2ba7ca',
                      }}
                      className="flex-center justify-center"
                    >
                      <h2 style={{ color: '#fff' }} className="text-14 w-500">
                        {convertToLocal(
                          time.flexibleSessionSchedule?.startDate,
                          'DD',
                        )}
                      </h2>
                    </div>
                    <h2 className="text-16 ml-10px">
                      {convertToLocal(
                        time.flexibleSessionSchedule?.startDate,
                        'MMM, dddd',
                      )}
                    </h2>
                  </div>
                  <div className="flex-center">
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        backgroundColor: '#2ba7ca',
                        borderRadius: '40px',
                        marginRight: '10px',
                      }}
                    ></div>
                    <h2 className="text-14">
                      {convertToLocal(
                        time.flexibleSessionSchedule?.startDate,
                        'h:mm A',
                      )}{' '}
                    </h2>
                  </div>
                  <h2 className="text-16">Flexible</h2>
                </div>
              )}
            </div>
          )
        })
      ) : (
        <h2>No schedule found</h2>
      )}
    </div>
  )
}

export default Schedule
