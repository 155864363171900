import { Form, Button, Col, Layout, Row, Space } from "antd";
import React, { useState } from "react";

import moment from "moment";
import rightArrowIcon from "../../../../../assets/Images/backarrow.svg";
import OneTimeSession from "./OneTime";
import FlexibleSession from "./FlexibleTime";
import RecurringSession from "./Recurring";

const TimeAndFrequency = ({ data, handleBack, handleNext }: any) => {
  const [form] = Form.useForm();
  const [option, setOption] = useState(data?.timesPerWeek || "");
  const [reccuringData, setRecurringData] = useState();
  const formSchema = {
    oneTimeDate:
      data?.timesPerWeek === "one_time" &&
      data?.oneTimeSessionSchedule?.startTime
        ? moment(data?.oneTimeSessionSchedule?.startTime)
        : "",
    oneTimeStart:
      data?.timesPerWeek === "one_time" &&
      data?.oneTimeSessionSchedule?.startTime
        ? moment(data?.oneTimeSessionSchedule?.startTime)
        : "",
    oneTimeEnd:
      data?.timesPerWeek === "one_time" && data?.oneTimeSessionSchedule?.endTime
        ? moment(data?.oneTimeSessionSchedule?.endTime)
        : "",
    flexibleStartDate:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.startDate
        ? moment(data?.flexibleSessionSchedule?.startDate)
        : "",
    flexibleEndDate:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.endDate
        ? moment(data?.flexibleSessionSchedule?.endDate)
        : "",
    flexibleRestrictions:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.restrictions
        ? data?.flexibleSessionSchedule?.restrictions
        : "",
    recurringStartDate:
      data?.timesPerWeek === "recurring" &&
      data?.recurringSessionSchedule?.startDate
        ? moment(data?.recurringSessionSchedule?.startDate)
        : "",
    recurringEndDate:
      data?.timesPerWeek === "recurring" &&
      data?.recurringSessionSchedule?.endDate
        ? moment(data?.recurringSessionSchedule?.endDate)
        : "",
  };

  const onFinish = (values: any) => {
    const dataValues = {
      timesPerWeek: option,
      recurringSessionSchedule: reccuringData,
    };
    handleNext({ ...dataValues });
  };

  return (
    <Layout>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <div className="modal-h-element ">
          <Row gutter={[0, 16]}>
            <Col>
              <h4 className="b1">How often do you need this service?</h4>
            </Col>
            <Col>
              <label className="input-label d-block mb-0">Times per week</label>
            </Col>
            <Col>
              <Space size={12}>
                <Button
                  className={
                    option === "one_time"
                      ? "bg-dark br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("one_time");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  One time
                </Button>
                <Button
                  className={
                    option === "flexible"
                      ? "bg-dark br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("flexible");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  Flexible
                </Button>
                <Button
                  className={
                    option === "recurring"
                      ? "bg-secondary br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("recurring");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  Recurring
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              <OneTimeSession option={option} />
              <FlexibleSession option={option} />
              <RecurringSession option={option} setdata={setRecurringData} />
            </Col>
          </Row>
        </div>
        <Row justify="space-between" align="middle">
          <h5 className="stepperBackh5" onClick={() => handleBack()}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} alt="back" />
            </span>
            Back
          </h5>
          <Button className="stepper-button" htmlType="submit">
            Next
          </Button>
        </Row>
      </Form>
    </Layout>
  );
};

export default TimeAndFrequency;
