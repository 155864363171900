import React from "react";
import "antd/dist/antd.css";
import {
  Calendar,
  Select,
  Col,
  Row,
  Space,
  Badge,
  Card,
  DatePicker,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import { useState } from "react";

const CustomCalender = ({ events }: any) => {
  const [currentDate, setCurrentDate] = useState("");

  console.log("events", events);
  const dateCellRender = (value: any) => {
    const stringValue = value.format("DD/MM/yyyy");
    console.log("stringValue", stringValue);

    const listData = events.filter(
      (event: any) => moment(event.date).format("DD/MM/yyyy") === stringValue
    );
    return (
      <ul>
        {listData.length > 1 ? (
          <>
            {currentDate !== stringValue ? (
              <>
                <Card className="calender-card">
                  <Row>{listData[0]?.name} </Row>
                  <Row>{moment(listData[0]?.timeRange).format("HH:mm")}</Row>
                </Card>

                <Card className="showmore-card">
                  <h5
                    onClick={() => {
                      setCurrentDate(stringValue);
                    }}
                  >
                    + {listData.length - 1} more
                  </h5>
                </Card>
              </>
            ) : (
              <>
                {listData.map((item: any, index: any) => (
                  <li key={item.content}>
                    <Badge status={"success"} text={item.eventName} />
                  </li>
                ))}
                <h5
                  onClick={() => {
                    setCurrentDate("");
                  }}
                >
                  show less
                </h5>
              </>
            )}
          </>
        ) : (
          listData.map((item: any, index: any) => (
            <li key={item.content}>
              <Badge status={"success"} text={item.name} />
            </li>
          ))
        )}
      </ul>
    );
  };
  const onPanelChange = (value: any, mode: any) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  return (
    <div
      className="site-calendar-customize-header-wrapper "
      style={{ width: "100%" }}
    >
      <Calendar
        fullscreen={true}
        className="big-calender"
        dateCellRender={dateCellRender}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];
          const current = value.clone();
          const localeData = value.localeData();
          const months = [];

          for (let i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
          }

          for (let index = start; index < end; index++) {
            monthOptions.push(
              <Select.Option className="month-item" key={`${index}`}>
                {months[index]}
              </Select.Option>
            );
          }

          const month = value.month();
          const year = value.year();
          const options = [];

          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            );
          }

          return (
            <div
              style={{
                padding: 2,
                marginTop: 0,
              }}
            >
              <Row justify="space-between">
                <Col span={18}>
                  <Space align="center">
                    <LeftOutlined className="cursor_pointer" />
                    <h3 style={{ margin: "0px" }}>
                      <b className="b1"></b>
                      {moment(String(month), "MM").format("MMMM")} {year}
                    </h3>
                    <RightOutlined className="cursor_pointer" />
                  </Space>
                </Col>
                <Col span={3}>
                  <Select
                    style={{ width: "100%" }}
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    onChange={(newYear) => {
                      const now = value.clone().year(Number(newYear));
                      onChange(now);
                    }}
                    value={String(year)}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
        onPanelChange={onPanelChange}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  events: state.events.events,
});

export default connect(mapStateToProps)(CustomCalender);
