import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import ArrowRightw from '../../../../assets/Images/ArrowRightw.svg'
import location from '../../../../assets/Images/locationw.svg'
import dropdownw from '../../../../assets/Images/dropdownw.svg'
import cardImage from './cardImage.png'
import eventDetailImg from './eventDetailImg.png'
import { Button } from 'antd'
import './style.css'
import { getEventCategories } from 'api/Events'
import { useQuery } from '@tanstack/react-query'
import spotAvailable from 'assets/Images/spotAvailable.svg'
import waitList from 'assets/Images/waitList.svg'
import limitedSeats from 'assets/Images/limitedSeats.svg'
import handing from 'assets/Images/handimg.svg'
import circularClock from 'assets/Images/circularClock.svg'
import almostFull from 'assets/Images/almostFull.svg'
function EventCard({
  eventImg,
  eventName,
  eventLoc,
  eventStartDateMonth,
  eventStartDateDay,
  eventEndDateMonth,
  eventEndDateDay,
  eventId,
  categoryImg,
  seatsStatus,
  venueType,
}: any) {
  const { data, refetch } = useQuery(
    ['getEventCategories'],
    () => getEventCategories(),
    { enabled: false },
  )
  const history = useHistory()
  
  return (
    <>
      <>
        {/* <div className="date">
          <span>
            {eventStartDateMonth} <b className="bold">{eventStartDateDay}</b>
          </span>
          <img className="arrow" src={ArrowRightw} alt="" />
          <span>
            {eventEndDateMonth} <b className="bold">{eventEndDateDay}</b>
          </span>
        </div> */}
      </>
      <>
        <div className="event-card">
          <div className="details-event-card">
            <div
              className="org-event-img cursor_pointer"
              // onClick={() => {
              //   history.push({
              //     pathname: `/events-details/${eventId}`,
              //     state: eventId,
              //   })
              // }}
            >
              {/* <img src={eventImg} alt="" /> */}
              <div className="flex justify-center">
                <span className="w-500">
                  {eventStartDateMonth} <br />{' '}
                  <b className="bold">{eventStartDateDay}</b>
                </span>
                <img
                  style={{
                    width: '15px',
                    height: '15px',
                    marginTop: '25px',
                    padding: '1px',
                    marginRight: '3px',
                  }}
                  src={ArrowRightw}
                  alt=""
                />
                <span className="w-500">
                  {eventEndDateMonth} <br />
                  <b className="bold">{eventEndDateDay}</b>
                </span>
              </div>
            </div>
            <div className="data">
              <h3>{eventName}</h3>
              {venueType === 'physical' ? (
                <div className="location">
                  <img src={location} alt="" />
                  <span>{eventLoc}</span>
                </div>
              ) : (
                <div className="grey-txt text-capitalize mt-5px">
                  <span>{venueType}</span>
                </div>
              )}

              <div className="event-detail">
                <div className="img">
                  <img src={categoryImg} alt="" />
                </div>
                {/* <div className="event-details " style={{ padding: '6px' }}> */}
                <div
                  className="br-6 d-flex  justify-content-center mt-5px"
                  // onClick={() => setStatusInfoVisible(true)}
                  style={{
                    backgroundColor:
                      seatsStatus === 'Pending Confirmation'
                        ? '#FEF4E8'
                        : seatsStatus === 'Limited seats left'
                        ? '#E7F0F8'
                        : seatsStatus === 'Almost Full'
                        ? '#E8E9F7'
                        : seatsStatus === 'Waitlist'
                        ? '#FFE2E0'
                        : seatsStatus === 'Spots Available'
                        ? '#F1F8E7'
                        : '',
                    padding: '4px 24px 4px 24px',
                    borderRadius: '20px',
                    height: '30px',
                  }}
                >
                  <img
                    src={
                      seatsStatus === 'Pending Confirmation'
                        ? `${handing}`
                        : seatsStatus === 'Limited seats left'
                        ? `${limitedSeats}`
                        : seatsStatus === 'Almost Full'
                        ? `${almostFull}`
                        : seatsStatus === 'Waitlist'
                        ? `${waitList}`
                        : seatsStatus === 'Spots Available'
                        ? `${spotAvailable}`
                        : ''
                    }
                    width={20}
                    height={20}
                    alt="status-seats"
                  />
                  <p className="font12 d-flex ml-10">
                    <b className="b1">{seatsStatus}</b>
                  </p>
                </div>

                {/* <div className="heading d-flex align-items-center mt-8">
                    <h1>{eventsDetails?.data?.name}</h1>
                    <div
                      className={`d-flex justify-content-center align-items-center category rounded-full px-5 border-1 ml-15 ${Styles.sizeCategory}`}
                    >
                      <img
                        src={categoryImg}
                        width={35}
                        height={35}
                        alt="status-seats"
                      />
                    </div>
                  </div> */}

                {/* <div
                    className={`location-time d-flex mt-40 ${Styles.width80percent}`}
                  >
                    <div className={`time ${Styles.w_half}`}>
                      <div className="d-flex">
                        <img
                          src={circularClock}
                          width={40}
                          height={40}
                          alt="clock"
                        />
                        <div className="details ml-10">
                          <h4 className="font10 text-color-gray b2">Time</h4>
                          <h3 className="font14 text-color-black b2 mt-8">
                            Start on{' '}
                            {moment(eventsDetails?.data?.startDate).format(
                              'MMM DD,YYYY',
                            )}
                            <br></br>{' '}
                            {convertOnlyMinutesToTime(
                              eventsDetails?.data?.timings?.startTime,
                            )}{' '}
                            -
                            {convertOnlyMinutesToTime(
                              eventsDetails?.data?.timings?.endTime,
                            )}
                          </h3>
                          <Button
                            type="link"
                            className="d-flex align-items-center mt-10"
                            style={{ padding: '0' }}
                            onClick={() => setModalVisible(true)}
                          >
                            More options{' '}
                            <img
                              src={'/assets/dropdown.svg'}
                              width={20}
                              height={20}
                              alt="chevron"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className={`location ${Styles.w_half}`}>
                      <div className="d-flex align-items-center">
                        {eventsDetails?.data?.venueType === 'virtual' ? (
                          <img
                            src={'/assets/video.svg'}
                            width={50}
                            height={50}
                            alt="clock"
                          />
                        ) : eventsDetails?.data?.venueType === 'physical' ? (
                          <img
                            src={'/assets/locationpinline.svg'}
                            width={40}
                            height={40}
                            alt="clock"
                          />
                        ) : (
                          <img
                            src={'/assets/question.svg'}
                            width={40}
                            height={40}
                            alt="clock"
                          />
                        )}
                        {eventsDetails?.data?.venueType === 'physical' ? (
                          <div className="details ml-10">
                            <h4 className="font10 text-color-gray b1">
                              Location
                            </h4>
                            <h3 className="font14 text-color-black b2 mt-8">
                              {eventsDetails?.data?.location?.city
                                ? `${eventsDetails?.data?.location?.city}, ${eventsDetails?.data?.location?.street} ${eventsDetails?.data?.location?.apartment}`
                                : 'no location is given'}
                            </h3>
                          </div>
                        ) : eventsDetails?.data?.venueType === 'virtual' ? (
                          <h4 className="font14 b1 ml-10">Online</h4>
                        ) : (
                          <h4 className="font14 b1 ml-10">To be determined</h4>
                        )}
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
              <div
                className="view-more"
                onClick={() => {
                  history.push({
                    pathname: '/events-details',
                    state: eventId,
                  })
                }}
              >
                {/* <button>view detail</button> */}
              </div>
            </div>
          </div>
          <div className="button mt-20 ">
            <div className="flex">
              <button
                className="event-btn mr-10px"
                onClick={() => {
                  history.push({
                    pathname: '/events-details',
                    state: eventId,
                  })
                }}
              >
                View details
              </button>
              <button className="prim-btn ">Register</button>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default EventCard
