import { CaretDown } from '@phosphor-icons/react'

const Down = ({ top }: any) => {
  return (
    <CaretDown
      color="#000229"
      size={18}
      style={{
        position: 'relative',
        top: top ? top : '4px',
        marginLeft: '4px',
      }}
    />
  )
}
export default Down
