import { uuidv4 } from "@antv/xflow-core";
import { Avatar, Button, Col, Form, Input, Row, Space } from "antd";
import React, { useState } from "react";
import userAvatarSvg from "../../../../assets/Images/userAvatar.svg";
import FileUploader from "../../../../helper/FileUploader";

const GeneralDetails = ({ handleSubmit, data }: any) => {
  const [avatar, setAvatar] = useState("");
  const [form] = Form.useForm();

  const formSchema = {
    photoId: data?.photoId || data.family?.photoId || "",
    name: data?.name || data?.family?.name || "",
  };
  console.log(data, formSchema.name + "genral");
  const onFinish = (values: any) => {
    handleSubmit(values);
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout={"vertical"}
        initialValues={formSchema}
        form={form}
        className="pb-30px"
      >
        <Row>
          <Col>
            <Space>
              <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
              <Form.Item name="photoId">
                <FileUploader
                  setAvatar={setAvatar}
                  name="family"
                  form={form}
                  fileId={data?.photoId || data.family?.photoId}
                />
              </Form.Item>
            </Space>
          </Col>
          <Col span={12} className="mt-20">
            <label htmlFor="" className="input-label required-input">
              Family Name
            </label>
            <Form.Item name="name">
              <Input placeholder="Ex.: Robertson"></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" align="bottom">
          <Button htmlType="submit" className="stepper-button">
            Update Family
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default GeneralDetails;
