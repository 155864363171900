import { Table } from 'antd'
import { Tickets } from '..'
import info from '../../../../../assets/Images/Info.svg'
interface Board {
  tickets: Tickets[]
}
const GeneralBoard = ({ tickets }: Board) => {
  const columns = [
    {
      title: 'Ticket name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ticket price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'No of participants',
      dataIndex: 'attendees',
      key: 'attendees',
      render: (item: any) => {
        return item?.length
      },
    },
    {
      title: 'Purchase rate',
      dataIndex: 'percentage',
      key: 'percentage',
    },
    // {
    //   title: 'Average purch. time',
    //   dataIndex: 'address',
    //   key: 'address',
    // },
  ]
  return (
    <div className="border-1px radius-8 mt-30px p-15px">
      <div>
        <h3 className="text-16">General board</h3>
        <div className="flex align-center ">
          <p className="grey-txt text-12 ">
            Monitoring the age of participants over a period of time
          </p>
          <img src={info} alt="info" className="pl-5px" />
        </div>
      </div>
      <Table dataSource={tickets} columns={columns} pagination={false} />;
    </div>
  )
}
export default GeneralBoard
