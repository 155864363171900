import React from "react";
import { Checkbox, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/lib/table";
interface DataType {
  key: React.Key;
  Modules: string;
  checkbox: boolean;
  address: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: "Modules",
    dataIndex: "modules",
  },
  {
    title: "Guardians",
    dataIndex: "guardians",
  },
  {
    title: "Mentors",
    dataIndex: "mentors",
  },
  {
    title: "Full Admin",
    dataIndex: "admin",
  },
  {
    title: "Organizer",
    dataIndex: "organizer",
  },
  {
    title: "Staff Member",
    dataIndex: "staffmember",
  },
  {
    title: "View Only",
    dataIndex: "viewonly",
  },
  {
    title: "Event Manager",
    dataIndex: "eventmanager",
  },
];
const data: any = [
  {
    key: "1",
    modules: "Edit Profile",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "2",
    modules: "Edit news",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "3",
    modules: "Delete news",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "4",
    modules: "Edit event",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "5",
    modules: "Delete event",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "6",
    modules: "Duplicate event",
    admin: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox defaultChecked className="settings-checkbox"></Checkbox>,
    guardians: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    organizer: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    staffmember: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    viewonly: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
    eventmanager: (
      <Checkbox defaultChecked className="settings-checkbox"></Checkbox>
    ),
  },
  {
    key: "7",
    modules: "Register event",
    admin: <Checkbox className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox className="settings-checkbox"></Checkbox>,
    guardians: <Checkbox className="settings-checkbox"></Checkbox>,
    organizer: <Checkbox className="settings-checkbox"></Checkbox>,
    staffmember: <Checkbox className="settings-checkbox"></Checkbox>,
    viewonly: <Checkbox className="settings-checkbox"></Checkbox>,
    eventmanager: <Checkbox className="settings-checkbox"></Checkbox>,
  },
  {
    key: "8",
    modules: "Add members",
    admin: <Checkbox className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox className="settings-checkbox"></Checkbox>,
    guardians: <Checkbox className="settings-checkbox"></Checkbox>,
    organizer: <Checkbox className="settings-checkbox"></Checkbox>,
    staffmember: <Checkbox className="settings-checkbox"></Checkbox>,
    viewonly: <Checkbox className="settings-checkbox"></Checkbox>,
    eventmanager: <Checkbox className="settings-checkbox"></Checkbox>,
  },
  {
    key: "9",
    modules: "Edit organizations",
    admin: <Checkbox className="settings-checkbox"></Checkbox>,
    mentors: <Checkbox className="settings-checkbox"></Checkbox>,
    guardians: <Checkbox className="settings-checkbox"></Checkbox>,
    organizer: <Checkbox className="settings-checkbox"></Checkbox>,
    staffmember: <Checkbox className="settings-checkbox"></Checkbox>,
    viewonly: <Checkbox className="settings-checkbox"></Checkbox>,
    eventmanager: <Checkbox className="settings-checkbox"></Checkbox>,
  },
];
const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};
const Permission = ({ match }: any) => {
  return (
    <div>
      <div>
        <h1 className="general-s-heading">Permissions</h1>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        className="permission-table"
        style={{ width: "100%", overflowX: "auto", marginBottom: "30px" }}
        pagination={false}
      />{" "}
    </div>
  );
};
export default Permission;
