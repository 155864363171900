import { Avatar, Button, Col, Dropdown, Menu, Row, Space } from "antd";
import phone from "../../../../assets/Images/Phone.svg";
import envelopSimple from "../../../../assets/Images/EnvelopeSimple.svg";
import pencilLine from "../../../../assets/Images/PencilLine.svg";
import warning from "../../../../assets/Images/Warning.svg";
import { useState } from "react";
import AlertModal from "../../../../helper/AlertModal";
import StatusDropdown from "../../../../helper/StatusDropdown";

const BasicInfo = ({ mentor }: any) => {
  const [alertModalVisible, setAlertModalVisible] = useState(false);

  const alertModalData = {
    heading: "Block email",
    message1: "Are you sure you want to block email",
    message2: "example@gmail.com",
    okText: "Yes, block email",
    closeIcon: false,
  };

  const manage = (
    <Menu>
      <Menu.Item>
        <Space size={10}>
          <img src={pencilLine} alt=""></img>
          <h5>Change email</h5>
        </Space>
      </Menu.Item>
      <Menu.Item>
        <Space size={10}>
          <img src={warning} alt=""></img>

          <h5
            className="cursor_pointer"
            onClick={() => {
              setAlertModalVisible(true);
            }}
          >
            Block email
          </h5>
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="container"
      style={{ paddingTop: "40px", paddingLeft: "40px" }}
    >
      <Row gutter={[12, 40]}>
        <Col>
          <Space direction="vertical" size={10}>
            <h5>
              <b className="b1">Status</b>
            </h5>
            <StatusDropdown
              id={mentor.id}
              name="mentor"
              status={mentor.status}
            />
          </Space>
        </Col>
        <Col>
          <Space size={30} direction="vertical">
            <h5>
              <b className="b1">Contact Information</b>
            </h5>
            <div className="contact-information">
              <Space direction="vertical" size={20}>
                <Space align="center">
                  <img alt="img" src={phone}></img>
                  <h4>
                    <b className="b3">{` ${mentor?.phone?.dialCode} ${mentor?.phone?.number}`}</b>
                  </h4>
                </Space>
                <Space align="center" size={10}>
                  <img alt="img" src={envelopSimple}></img>
                  <h4>
                    <b className="b3">{mentor?.email}</b>
                  </h4>
                  <Dropdown overlay={manage}>
                    <Button className="outlined-button br-8">Manage</Button>
                  </Dropdown>
                </Space>
              </Space>
            </div>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical" size={16}>
            <h5>
              <b className="b1">Specific Services</b>
            </h5>
            <h6>
              <b>Tutor</b>
            </h6>
            <p>
              Working with kids to help them understand key concepts, especially
              those learned in the classroom.
            </p>
          </Space>
        </Col>

        <Col>
          <Space size={16} direction="vertical">
            <h5>
              <b className="b1">Skills</b>
            </h5>
            <Space size={14}>
              {mentor?.skills?.map((skill: any) => {
                return (
                  <Avatar
                    key={skill._id}
                    size={56}
                    icon={<img alt="img" src={`img/${skill.name}.svg`}></img>}
                  />
                );
              })}
            </Space>
          </Space>
        </Col>
      </Row>
      {alertModalVisible && (
        <AlertModal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModalVisible}
          data={alertModalData}
        ></AlertModal>
      )}
    </div>
  );
};

export default BasicInfo;
