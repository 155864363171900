import { Button, Col, Modal, Row, Space } from 'antd'
import ButtonSpinner from 'components/global/ButtonSpinner'
import React, { useState } from 'react'
import { deleteTicket } from 'api/Events'
import { useMutation } from '@tanstack/react-query'

const DeleteTicket = ({
  isVisible,
  onClose,
  name,
  type,
  loading,
  addmissionId,
  additionalId,
  refetch,
}) => {
  const changeMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await deleteTicket(payload)
    },
    onSuccess: resp => {
      if (resp) {
        onClose()
        refetch()
      }
    },
  })
  const handleDeleteTicket = async () => {
    let payload: any = {
      id: addmissionId,
    }

    if (additionalId !== null) {
      payload.additionalId = additionalId
    }

    changeMutation.mutate({ payload })
  }
  return (
    <Modal
      className="delete-modal"
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      width={450}
    >
      <Row gutter={[0, 40]} justify="center">
        <Col span={24}>
          <div className="text-center">
            <Space direction="vertical">
              <h3 className="b1">
                Delete {type === 'ticket' ? 'ticket' : 'additional option'}?
              </h3>
              <div className="b3">Are you sure you want to delete the</div>
              <h5 className="b1">{name}?</h5>
            </Space>
          </div>
        </Col>
        <Col className="justify-center">
          <div className="justify-center">
            <Space size={20}>
              <Button onClick={onClose} className="alert_delete_button bg-grey">
                No, keep it
              </Button>
              <Button
                className="alert_delete_button text-white"
                onClick={handleDeleteTicket}
                disabled={loading}
              >
                {loading ? (
                  <ButtonSpinner size={20} color="#000" />
                ) : (
                  'Yes, delete'
                )}
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Modal>
  )
}

export default DeleteTicket
