import { all } from "redux-saga/effects";
import authSaga from "./Auth/saga";
import organizationSaga from "./Organization/saga";
import organizerSaga from "./Organizer/saga";
import fileUploadSaga from "./FileUpload/saga";
import mentorsSaga from "./mentors/saga";
import jobRequestsSaga from "./JobRequest/saga";
import familySaga from "./Family/saga";
import allergiesSaga from "./Allergies/saga";
import relationsSaga from "./Relations/saga";
import skillsSaga from "./Skills/saga";
import eventsSaga from "./Events/saga";
import eventsVenueSaga from "./EventVenue/saga";
import amenitiesSaga from "./Amenities/saga";
import sessionSaga from "./Session/saga";
import newsfeedSaga from "./NewsFeed/saga";
import adminSaga from "./Admin/saga";
import locationsSaga from "./Locations/saga";
import listsSaga from "./Lists/saga";
export default function* rootSaga() {
  yield all([
    authSaga(),
    organizationSaga(),
    organizerSaga(),
    familySaga(),
    fileUploadSaga(),
    mentorsSaga(),
    newsfeedSaga(),
    jobRequestsSaga(),
    allergiesSaga(),
    relationsSaga(),
    skillsSaga(),
    eventsSaga(),
    eventsVenueSaga(),
    amenitiesSaga(),
    sessionSaga(),
    adminSaga(),
    locationsSaga(),
    listsSaga(),
  ]);
}
