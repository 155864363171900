import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, Avatar, Checkbox, Dropdown } from 'antd'
import { OrgOrganizerType } from 'types/organization'
import { Person } from 'types/globalTypes'
import '../styles/general-details.scss'
import { getNameSignature } from 'helper/utility'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { infoMessage } from 'api/global'
const { Option } = Select

interface EventManagerProps {
  selectedOrganizer: string[]
  setSelectedOrganizer: React.Dispatch<React.SetStateAction<string[]>>
  orgOrganizers: OrgOrganizerType[]
  defaultOrganizer: any
}

const EventManager: React.FC<EventManagerProps> = ({
  selectedOrganizer,
  setSelectedOrganizer,
  orgOrganizers,
  defaultOrganizer,
}) => {
  const [isDefault, setIsDefaul] = useState<boolean>()
  const [filteredOrganizer, setFilteredOrganizer] = useState<
    OrgOrganizerType[]
  >([])

  console.log(selectedOrganizer, 'selected organizer...', defaultOrganizer)

  const onChange = (e: CheckboxChangeEvent, selectedItem: string) => {
    const orgOwner = orgOrganizers.find(org => org.accessLevel === 'owner')
    if (
      selectedItem === orgOwner.organizerId &&
      selectedOrganizer?.length === 1
    ) {
      return infoMessage('Atleast one event manager is required')
    }
    setSelectedOrganizer(prev =>
      e.target.checked
        ? [...prev, selectedItem]
        : prev.filter(orgId => orgId !== selectedItem),
    )
  }

  useEffect(() => {
    if (selectedOrganizer?.length > 0) {
      setIsDefaul(false)
    } else {
      setIsDefaul(true)
      setSelectedOrganizer([defaultOrganizer?.id])
    }
    const filteredData = orgOrganizers.filter(
      org => selectedOrganizer?.some(data => data === org.organizerId),
    )
    setFilteredOrganizer(filteredData)
  }, [selectedOrganizer, defaultOrganizer])

  const items =
    orgOrganizers?.length > 0 &&
    orgOrganizers.map((organizer: OrgOrganizerType) => {
      return {
        key: organizer._id,
        label: (
          <div className="flex align-center gap-16px">
            <div className="flex align-center gap-10px">
              <Avatar src={organizer.organizer.photo?.post} size={34}>
                {getNameSignature(organizer.organizer.firstName)}
              </Avatar>
              <span>{organizer.organizer.firstName}</span>
            </div>
            <Checkbox
              onChange={e => onChange(e, organizer.organizerId)}
              name={organizer.organizer.firstName}
              checked={selectedOrganizer?.includes(organizer.organizerId)}
              className="custom-category-checkbox"
            ></Checkbox>
          </div>
        ),
      }
    })

  return (
    <>
      <Row gutter={10}>
        <Col>
          <label className="input-label required-input">Event Manager</label>
        </Col>
        <Col>
          <Form.Item colon={false} className="form-control" name="organizers">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              trigger={['click']}
              className="event-input-block"
              overlayStyle={{
                minWidth: '350px',
                height: 'auto',
                maxHeight: '400px',
                overflowY: 'auto',
                background: '#fff',
                borderRadius: '8px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            >
              <Col style={{ height: '50px' }}>
                <div className="w-full border-1px radius-10 flex align-center px-12px pointer h-full">
                  {filteredOrganizer.length > 0 ? (
                    filteredOrganizer.map((organizer: OrgOrganizerType) => (
                      <>
                        <p
                          key={organizer._id}
                          className="ml-5px text-space-nowrap overflow-hidden-x"
                        >
                          {organizer.organizer.firstName},
                        </p>
                      </>
                    ))
                  ) : (
                    <p className="text-gray">Select Event manager</p>
                  )}
                </div>
              </Col>
            </Dropdown>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default EventManager
