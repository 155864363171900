import { all, put, takeLatest } from 'redux-saga/effects'
import actions from './action'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../axiosHelper'
import failureAction from '../Failure/action'
import { myStore } from 'state/roleStore'
/**
 * post organization data
 *
 */
export function* addOrganizationRequest({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create-organization`)
    yield put(actions.getOrganizationsRequest())
    console.log(data)
  } catch (error) {}
}

/**
 * get organizations data
 *
 */

export function* getOrganizationsRequest({ params }) {
  try {
    let currentParams = {
      page: params?.page || 1,
      limit: params?.limit || 10,
      sort: params?.sort || 'name',
      status: params?.status || null,
      queryString: params?.queryString || '',
      organizationId: myStore.getState().organizationId,
      populate: '[{"path":"photo"}, {"path":"owner","populate":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/admins/organizations`, currentParams)
    yield put(actions.getOrganizationsSuccess(data))
  } catch (error) {
    yield put(actions.getOrganizationsFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getOrganization({ payload, id }) {
  try {
    let params = {
      populate: '[{"path":"photo"}, {"path":"owner","populate":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/organizations/${id}`, params)
    yield put(actions.getOrganizationSuccess(data))
  } catch (error) {
    yield put(actions.getOrganizationFailure())
    yield put(failureAction.failure(error))
  }
}

/**
 * get organizer data
 *
 */
export function* getOrganizersOfOrganizationRequest({ params, id }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 10,
      sort: params?.sort?.order || 'desc',
      populate:
        '[{"path":"photo"}, {"path":"organization","populate":"photo"}]',
    }
    const { data } = yield axiosGet(
      `v1/organizations/${id}/get-members`,
      currentParams,
    )
    yield put(actions.getOrganizersOfOrganizationSuccess(data))
  } catch (error) {
    console.log('error here', error)
    yield put(actions.getOrganizersOfOrganizationFailure())
  }
}

export function* updateOrganizationRequest({ payload, params }) {
  try {
    const { data } = yield axiosPatch(payload, `v1/organizations/${params}`)
    yield put(actions.updateOrganizationSuccess(data))
  } catch (error) {
    yield put(actions.updateOrganizationFailure())
    yield put(failureAction.failure(error))
  }
}
export function* updateOrganizationsStatus({ payload, params }) {
  try {
    const { data } = yield axiosPatch(
      payload,
      `v1/organizations/${params}/status`,
    )
    yield put(actions.updateOrganizationsStatusSuccess(data))
  } catch (error) {
    yield put(actions.updateOrganizationsStatusFailure())
    yield put(failureAction.failure(error))
  }
}
export function* getOrganizationAnalytics({ payload, id }) {
  try {
    const { data } = yield axiosGet(`v1/organizations/${id}/analytics`)
    yield put(actions.getOrganizationAnalyticsSuccess(data))
  } catch (error) {
    console.log('analytics', error)
    yield put(actions.getOrganizationAnalyticsFailure())
    yield put(failureAction.failure(error))
  }
}

// requesting the deleting api
export function* deleteOrganizationRequest({ payload }) {
  try {
    const { data } = yield axiosDelete(`v1/admins/organizations/${payload}`)
    yield put(actions.deleteOrganizationSuccess(data))
  } catch (error) {
    yield put(actions.deleteOrganizationFailure(error))
  }
}

export function* getOrganizationReviews({ payload, id }) {
  try {
    const { data } = yield axiosGet(`v1/reviews/get-organization-reviews/${id}`)
    yield put(actions.getOrganizationReviewsSuccess(data))
  } catch (error) {
    console.log('analytics', error)
    yield put(actions.getOrganizationReviewsFailure())
    yield put(failureAction.failure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.ADD_ORGANIZATION_DATA, addOrganizationRequest),
    takeLatest(actions.GET_ORGANIZATIONS, getOrganizationsRequest),
    takeLatest(actions.UPDATE_ORGANIZATION, updateOrganizationRequest),
    takeLatest(actions.UPDATE_ORGANIZATIONS_STATUS, updateOrganizationsStatus),
    takeLatest(actions.GET_ORGANIZATION, getOrganization),
    takeLatest(
      actions.GET_ORGANIZERS_OF_ORGANIZATION,
      getOrganizersOfOrganizationRequest,
    ),
    takeLatest(actions.DELETE_ORGANIZATION_REQUEST, deleteOrganizationRequest),
    takeLatest(actions.GET_ORGANIZATION_ANALYTICS, getOrganizationAnalytics),
    takeLatest(actions.GET_ORGANIZATION_REVIEWS, getOrganizationReviews),
  ])
}
