import React, { useState, useEffect } from 'react'
import { Button, Row, Col, TimePicker } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import moment, { Moment } from 'moment'
import Calendar from 'react-calendar'
import {
  convertMinutesToLocalTime,
  convertTimeToUtcMinutes,
} from 'helper/utility'
import { SpecialSchedule } from 'types/globalTypes'

const AddSpecialScheduleModal = ({
  addSpecialScheduleModalVisible,
  setAddSpecialScheduleModalVisible,
  specialScheduleData,
  setSpecialScheduleData,
  selectedDates,
}: any) => {
  const [specialScheduleInputData, setSpecialScheduleInputData] = useState([])

  useEffect(() => {
    setSpecialScheduleInputData([...specialScheduleData])
    console.log(specialScheduleData)
  }, [specialScheduleData])

  const [selectedDate, setSelectedDate] = useState<any>('')

  const handleTileClick = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedDate(formattedDate)
  }

  const tileDisabled = ({ date }: { date: Date }) =>
    !selectedDates.find((d: string) => d === moment(date).format('YYYY-MM-DD'))

  const addSpecialscheduleInput = (date: String) => {
    // @ts-ignore
    setSpecialScheduleInputData([
      ...specialScheduleInputData,
      {
        day: date,
        startTime: '',
        endTime: '',
      },
    ])
  }

  const handleStartTimeChange = (time: Moment | null, timeString: string) => {
    let objIndex = specialScheduleInputData.findIndex(
      (obj: any) => obj.day == selectedDate,
    )
    specialScheduleInputData[objIndex].startTime =
      convertTimeToUtcMinutes(timeString)
  }
  const handleEndTimeChange = (time: Moment | null, timeString: string) => {
    let objIndex = specialScheduleInputData.findIndex(
      (obj: any) => obj.day == selectedDate,
    )
    specialScheduleInputData[objIndex].endTime =
      convertTimeToUtcMinutes(timeString)
  }

  const removeSpecialscheduleInput = (index: number) => {
    const current: any = specialScheduleInputData[index]
    setSpecialScheduleInputData([
      ...specialScheduleInputData.filter(
        (data: any) => data.day !== current.day,
      ),
    ])
  }

  const timeFormat = 'HH:mm a'

  return (
    <div>
      <Modal
        title="Add Special Schedule"
        open={addSpecialScheduleModalVisible}
        className="map-modal"
        footer={null}
        centered={true}
        width={450}
        onCancel={() => {
          setAddSpecialScheduleModalVisible(false)
        }}
      >
        <Col>
          <div>
            <div className="h-27rem overflow-auto-y scroller pb-20px">
              <Row className="mt-20">
                <div style={{ marginBottom: 20 }}>
                  <Calendar
                    onClickDay={handleTileClick}
                    tileDisabled={tileDisabled}
                    tileClassName={({ date, view }) => {
                      if (selectedDate === moment(date).format('YYYY-MM-DD')) {
                        return 'selected'
                      }
                    }}
                  />
                </div>
                <Col span={24}>
                  <span style={{ marginBottom: 10 }}>
                    <b>Special schedule</b>
                  </span>
                  <PlusCircleOutlined
                    onClick={() => {
                      if (selectedDate) {
                        console.log(specialScheduleInputData)
                        if (
                          specialScheduleInputData.find(
                            (d: any) => d.day === selectedDate,
                          )
                        ) {
                          console.log('do nothing')
                        } else {
                          addSpecialscheduleInput(selectedDate)
                        }
                      }
                    }}
                  />
                </Col>
                <Col span={24}>
                  {specialScheduleInputData.map((s: SpecialSchedule, index) => {
                    return (
                      <Row className="d-flex justify-between align-items-center mb-10 w-full">
                        <Col span={24}>
                          <span style={{ marginBottom: 10 }}>
                            <b>{s?.day}</b>
                          </span>
                        </Col>
                        <div className="flex justify-between align-center w-full">
                          <div className="flex align-center gap-16px mt-15px">
                            <TimePicker
                              className="w-100 form-control-field"
                              placeholder="Start Time"
                              format="h:mm a"
                              showNow={false}
                              clearIcon={false}
                              defaultValue={moment(
                                convertMinutesToLocalTime(s.startTime),
                                'hh:mm A',
                              )}
                              onChange={handleStartTimeChange}
                            />
                            <div>-</div>
                            <TimePicker
                              className="w-100 form-control-field"
                              placeholder="End Time"
                              format="h:mm a"
                              showNow={false}
                              clearIcon={false}
                              defaultValue={moment(
                                convertMinutesToLocalTime(s.endTime),
                                'hh:mm A',
                              )}
                              onChange={handleEndTimeChange}
                            />
                          </div>

                          <DeleteOutlined
                            onClick={() => removeSpecialscheduleInput(index)}
                            style={{
                              color: '#FF6961',
                              paddingLeft: '5px',
                            }}
                          />
                        </div>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="d-flex">
                <Button
                  className="route_button-events br-8"
                  onClick={() => setAddSpecialScheduleModalVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="venue_button-events br-8"
                  onClick={() => {
                    setSpecialScheduleData([
                      ...specialScheduleInputData.filter(
                        (data: any) =>
                          data.day && data.startTime && data.endTime,
                      ),
                    ])
                    setAddSpecialScheduleModalVisible(false)
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Modal>
    </div>
  )
}

export default AddSpecialScheduleModal
