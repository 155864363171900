import moment from 'moment'
import { useEffect, useState } from 'react'
import info from 'assets/Images/Info.svg'
import { useLocation } from 'react-router-dom'
import {
  capitalizeFirstLetter,
  convertToLocal,
  convertToUtc,
  getUserName,
} from 'helper/utility'
import { useQuery } from '@tanstack/react-query'
import { getAttendieByDate } from 'api/Events'
import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
import GetAvatar from 'components/global/GetAvatar'
import { Person } from 'types/globalTypes'

const ParticipantsCalendar = ({event}) => {
  // const location = useLocation()
  // const event: any = location.state
  const bDates: Date[] = []
  const currentDate = moment().startOf('day')
  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)

  const [eventAttendies, setEventAttendies] = useState<any[]>()
  const [selectedDateAttendies, setSelectedAttendies] = useState<Person[]>([])
  event?.datesOfEvent.map((date: any) => {
    const utcDate = new Date(date)
    utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset())
    bDates.push(utcDate)
  })
  useEffect(() => {
    setEventAttendies([
      ...(event?.attendees?.guardians || []),
      ...(event?.attendees?.kids || []),
      ...(event?.attendees?.authorizedAdults || []),
    ])
  }, [event])
  const { data, refetch, isLoading } = useQuery(['getEventAttendiesByDate'], {
    queryFn: () => getAttendieByDate(event._id, convertToUtc(selectedDate)),
    enabled: false,
  })

  useEffect(() => {
    selectedDate && refetch()
  }, [selectedDate])
  useEffect(() => {
    if (!isLoading && data) {
      setSelectedAttendies([
        ...(data?.attendies?.guardians || []),
        ...(data?.attendies?.kids || []),
        ...(data?.attendies?.authorizedAdults || []),
      ])
    }
  }, [data])
  const onCalenderSelect = (e: any) => {
    var inputDate = new Date(e)
    setSelectedDate(inputDate)
  }
  const renderPerson = (person: Person[]) => {
    return person.map((p, index) => (
      <div key={index} className="mt-10px">
        <div className="flex">
          <GetAvatar person={p} />

          <div className="ml-10px flex flex-col justify-start">
            <h4 className="text-14 w-500">{getUserName(p)}</h4>
            <p className="text-12 w-400 grey-txt">
              {capitalizeFirstLetter(p?.gender) +
                '.' +
                moment().diff(p?.dob, 'years') +
                ' y.o.'}
            </p>
          </div>
        </div>
      </div>
    ))
  }
  return (
    <div className="border-1px radius-6  mt-15px mb-15px">
      <div className="grid grid-50">
        <div className="py-20px">
          <div className="px-15px mb-30px">
            <h3 className="text-16 ">Particpant Calender</h3>
            <div className="flex align-center ">
              <p className="grey-txt text-12 ">
                Monitoring the age of participants over a period of time
              </p>
              <img src={info} alt="info" className="pl-5px" />
            </div>
          </div>
          <div className="calendar-customzation w-60p mx-auto">
            <MultiDateSelectCalendar
              dates={event?.datesOfEvent}
              selectedDate={selectedDate}
              setSelectedDate={onCalenderSelect}
            />
          </div>
        </div>

        <div className="border-left">
          <div className="px-15px py-15px">
            {selectedDateAttendies[0] ? (
              <div className="mb-20px">
                <h3 className="text-16 ">
                  Particpants attending on{' '}
                  {convertToLocal(selectedDate, 'YYYY-MM-DD')}
                </h3>
                <div className="mt-30px">
                  {renderPerson(selectedDateAttendies)}
                </div>
              </div>
            ) : (
              <></>
            )}
            <h3 className="text-16 ">All Particpants</h3>
            <div className="mt-30px">
              {eventAttendies && renderPerson(eventAttendies)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ParticipantsCalendar
