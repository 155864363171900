import { Button, Col, Row } from "antd";
import { useState } from "react";
import AppLayout from "../../components/layout/layout";
import SessionTable from "./Table/SessionTable";
import CreateSession from "../Modals/Sessions/CreateSession";
function Sessions(props: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sessionValues, setSessionValues] = useState({});
  return (
    <AppLayout>
      <>
        <Row className="page-header" justify="space-between">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <h1 className="table-head mb-0">Sessions</h1>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }} className="text-end">
            <div className="right-search-block justify-end">
              <Button
                className="btn-new primary br-8"
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                New session
              </Button>
            </div>
          </Col>
        </Row>
        <SessionTable />
        {isModalVisible && (
          <CreateSession
            sessionValues={sessionValues}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        )}
      </>
    </AppLayout>
  );
}
export default Sessions;
