const actions = {
  FAILURE: "FAILURE",
  CLEAR_ERROR_DATA: "CLEAR_ERROR_DATA",


  failure: (payload) => ({
    type: actions.FAILURE,
    payload,
  }),
  clearEroorData: () => ({
    type: actions.CLEAR_ERROR_DATA,
  }),
};
export default actions;
