import { Avatar, Button, Col, Dropdown, Modal, Row, Space } from 'antd'
import { capitalizeFirstLetter, getNameSignature } from 'helper/utility'
import moment from 'moment'
import { useState } from 'react'
import Ticket from 'assets/Images/Ticket.svg'
import Edit from 'assets/Images/rename.svg'
import X from 'assets/Images/XCircle.svg'
import ViewTicketModal from './ViewTicketModal'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { cancelRegistration, getEventParticipants } from 'api/Events'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { successMessage } from 'api/global'
import EditRegistration from './EditRegistration/index'
const ParticipantCard = ({ data }: any) => {
  const { id }: any = useParams()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [isEditVisible, setIsEditVisibale] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    data: eventParticipants,
    isLoading: isEventParticipantsLoading,
    refetch: refetchEventParticipants,
  } = useQuery(
    ['getEventParticipant', id],
    () => {
      return getEventParticipants(id)
    },
    { enabled: false },
  )
  const handleCancelRegistration = async () => {
    let payload: any = { eventId: id }

    if (data?.type === 'Kid') {
      payload.kidId = data._id
    } else if (data?.type === 'AuthorizedAdult') {
      payload.authorizedAdultId = data._id
    } else if (data?.type === 'Guardian') {
      payload.guardianId = data._id
    }

    changeMutation.mutate({ payload })
  }
  const changeMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await cancelRegistration(payload)
    },
    onSuccess: resp => {
      if (resp) {
        successMessage(resp?.responseMessage)
        refetchEventParticipants()
        setIsCancelModal(false)
      }
    },
  })
  const ManageOptions = [
    {
      key: 'tickets',
      label: (
        <div onClick={() => setIsModalVisible(true)}>
          <div className="flex align-center">
            <img src={Ticket} alt="view-tickets" />
            <h4 className="w-500 text-14 ml-10px">View tickets</h4>
          </div>
        </div>
      ),
    },
    {
      key: 'edit',
      label: (
        <div onClick={() => setIsEditVisibale(true)}>
          <div className="flex align-center">
            <img src={Edit} alt="edit-registration" />
            <h4 className="w-500 text-14 ml-10px">Edit Registration</h4>
          </div>
        </div>
      ),
    },
    {
      key: 'cancel',
      label: (
        <div onClick={() => setIsCancelModal(true)}>
          <div className="flex align-center">
            <img src={X} alt="cancel-registration" />
            <h4 className="w-500 text-14 ml-10px danger-txt">
              Cancel Registration
            </h4>
          </div>
        </div>
      ),
    },
  ]

  const handleMenuItemClick = (label: any) => {}

  const handleCancel = () => {
    setIsCancelModal(false)
  }

  return (
    <div className="flex justify-between mt-10px">
      <div className="flex">
        <Avatar src={data?.photo?.thumb} size={40}>
          {getNameSignature(data?.firstName)}
        </Avatar>
        <div className="ml-10px flex flex-col justify-start">
          <h4 className="text-14 w-500">
            {data?.firstName + ' ' + data?.lastName}
          </h4>
          <p className="text-12 w-400 grey-txt">
            {capitalizeFirstLetter(data?.gender) +
              '.' +
              moment().diff(data?.dob, 'years') +
              ' y.o.'}
          </p>
        </div>
      </div>
      <Dropdown
        menu={{
          items: ManageOptions,
          onClick: label => handleMenuItemClick(label),
        }}
        placement="bottom"
        arrow={false}
        className="ml-20px mr-10px"
        overlayStyle={{ width: '200px' }}
      >
        <button className="w-500 px-35px py-10px h-fit bg-white border-prim radius-6 text-edit">
          Manage
        </button>
      </Dropdown>
      <ViewTicketModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        data={data}
      />
      <EditRegistration
        isVisible={isEditVisible}
        onClose={() => setIsEditVisibale(false)}
        attendie={data}
      />
      <Modal
        className="delete-modal"
        open={isCancelModal}
        footer={null}
        onCancel={handleCancel}
        width={500}
      >
        <Row gutter={[0, 40]} justify="center">
          <Col span={24}>
            <div className="text-center">
              <Space direction="vertical">
                <h3 className="b1">Cancel Registration</h3>
                <div className="b3">
                  Are you sure you want to cancel registration?
                </div>
                <h5 className="b1">
                  {' '}
                  {data?.firstName + ' ' + data?.lastName}
                </h5>
              </Space>
            </div>
          </Col>
          <Col>
            <div>
              <Space size={20}>
                <Button
                  onClick={handleCancel}
                  className="alert_cancel_reg_button bg-grey"
                >
                  Cancel
                </Button>
                <Button
                  className="alert_cancel_reg_button text-white"
                  onClick={handleCancelRegistration}
                  disabled={loading}
                >
                  {loading ? (
                    <ButtonSpinner size={20} color="#000" />
                  ) : (
                    'Yes, Cancel Registration'
                  )}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default ParticipantCard
