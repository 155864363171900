import { connect } from 'react-redux'
import actions from '../../../Redux/Lists/actions'
export const TimeStatusCheckBoxes = [
  {
    label: 'Current',
    value: 'current',
  },
  {
    label: 'Past',
    value: 'past',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
]
export const SharingStatusCheckBoxes = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Private',
    value: 'archived',
  },
]

export const SortByCheckBoxes = [
  {
    label: 'Creation Date',
    value: 'create',
  },
  {
    label: 'Start Date',
    value: 'start',
  },
  {
    label: 'End Date',
    value: 'end',
  },
]
export const CategoriesCheckBoxes = [
  {
    label: 'Camp',
    value: '63286df7f0e6031bafcb6e80',
  },
  {
    label: 'Field Trip',
    value: '63286e03721c1a5feed95274',
  },
  {
    label: 'Course',
    value: '63286e08f05b4cb149ca380d',
  },
  {
    label: 'Club',
    value: '63286e0dcc70349899c6d3b8',
  },
  {
    label: 'Community',
    value: '63286e42bab7a941518ce6b2',
  },
  {
    label: 'Childcare',
    value: '63286e49b6dfacfd5c6f6def',
  },
  {
    label: 'Preschool',
    value: '63286e4fe0f9a8912cb2f633',
  },
  {
    label: 'Afterschool',
    value: '63286e543fb9d509a583ff20',
  },
  {
    label: 'Homeschool',
    value: '63286e5d33b446524e24294e',
  },
  {
    label: 'Sport',
    value: '6462004486ef5486e902d225',
  },
  {
    label: 'Other',
    value: '63286e66bd76daa24d9097e5',
  },
]
