import { useState } from "react";
import {
  Button,
  Col,
  Radio,
  Row,
  Select,
  Tabs,
  Avatar,
  Form,
  Input,
  TimePicker,
  DatePicker,
  Space,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import GreenCircle from "../../../../../assets/Images/GreenCircle.svg";

const DetailForm = ({onCancel}:any) => {
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [value, setValue] = useState("comment");
  const [noteType, setNoteType] = useState("comment");
  const formSchema = {
    title: "",
    apartment: "",
    zip: "",
  };
  const onFinish = (values: any) => {
    console.log("values:", values);

    // onSubmit({
    //   ...values
    // });
  };
  const onChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    setNoteType(e.target.value);
  };
  return (
    <div>
      <Row>
        <Tabs
          defaultActiveKey="1"
          //   onChange={(e)=>onChange(e)}
          //   style={{ width: "100%", height: "100%" }}
          //   className="bg-white profile-tabs"
        >
          <TabPane tab="Details" key="1">
            <Row className="mt-20">
              <Col span={24}>
                <span>Note created</span>
              </Col>
              <Col span={24}>
                <h5>
                  <Avatar size={18} src={GreenCircle}></Avatar>{" "}
                  <b className="b2"> Amin Mirza</b>
                </h5>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24}>
                <span>Note type</span>
              </Col>
              <Col span={24}>
                <h5>
                  <b className="b3">Comment</b>
                </h5>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24}>
                <span>Last updated date</span>
              </Col>
              <Col span={24}>
                <h5>
                  <b className="b3">5 Aug, 2020 at 6:10 PM</b>
                </h5>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24}>
                <span>Note</span>
              </Col>
              <Col span={24}>
                <h5>
                  <b className="b3">
                    Some note will go here, and it can be a mention of the user
                    @username and the list of all tagged people goes below
                  </b>
                </h5>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24}>
                <span>People tagged</span>
              </Col>
              <Col span={24}>
                {<Avatar size={18} src={GreenCircle}></Avatar>}
                {""} {"Allen Joe"}
              </Col>
              <Col span={24}>
                {<Avatar size={18} src={GreenCircle}></Avatar>}
                {""} {"Mark Dicker"}
              </Col>
            </Row>
            <Row className="d-flex justify-between mt-20">
            <Button icon={<EditOutlined />}>Edit note</Button>
              <Button
                className="bg-text-secondary br-8"
                style={{ width: "132px" }}
                onClick={()=>onCancel()}
              >
                Close
              </Button>
            </Row>
          </TabPane>
          <TabPane tab="History" key="2">
            <Row className="mt-20">
              <Col span={24} className="d-flex">
                <Space>
                  <h5>
                    <b>Amin Mirza</b>
                  </h5>
                  <span> changed note type to “Comment”</span>
                  <span> 2 days ago</span>
                </Space>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24} className="d-flex">
                <Space>
                  <h5>
                    <b>Amin Mirza</b>
                  </h5>{" "}
                  <span>changed note</span>
                  <span> 2 days ago</span>
                </Space>
              </Col>
              <Col span={12}></Col>
            </Row>

            <Row className="mt-20">
              <Col span={24} className="d-flex">
                <div className="details-tag br-8">
                  <span>
                    Some note will go here, and it can be a mention of the user
                    @username and the list of all tagged people goes below
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-20">
              <Col span={24} className="d-flex">
                <Space>
                  <h5>
                    <b>Amin Mirza</b>
                  </h5>{" "}
                  <span>changed note</span>
                  <span> 2 days ago</span>
                </Space>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row className="mt-20">
              <Col span={24} className="d-flex">
                <div className="details-tag br-8">
                  <span>
                    Ali Khalil, Khan Saad, Farhat Qadir, Bashar Momin, Ekram
                    Reza, Fatin Rass
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-between mt-20">
            <Button icon={<EditOutlined />}>Edit note</Button>
              <Button
                className="bg-text-secondary br-8"
                style={{ width: "132px" }}
                onClick={()=>onCancel()}
              >
                Close
              </Button>
            </Row>
          </TabPane>
        </Tabs>
      </Row>
    </div>
  );
};

export default DetailForm;
