import React from 'react'
import ItemstoBring from './AdditionalSettings/ItemstoBring'
import FAQ from './AdditionalSettings/FAQ'
import ConfirmationEmail from './AdditionalSettings/ConfirmationEmail'
import MentorRequest from './AdditionalSettings/MentorRequest'
import RequiredDocuments from './AdditionalSettings/RequiredDocuments'
import RequiredDetails from './AdditionalSettings/RequiredDetails'
import Security from './AdditionalSettings/Security'

const AdditionalSettings: React.FC<any> = () => {
  return (
    <div>
      <h1 className="text-14 w-600">Additional Settings</h1>
      <div className="mt-20px">
        <ItemstoBring />
      </div>
      <div className="mt-20px">
        <FAQ />
      </div>
      <div className="mt-20px">
        <ConfirmationEmail />
      </div>
      <div className="mt-20px">
        <MentorRequest />
      </div>
      <div className="mt-20px">
        <RequiredDocuments />
      </div>
      <div className="mt-20px">
        <RequiredDetails />
      </div>
      <div className="mt-20px">
        <Security />
      </div>
    </div>
  )
}

export default AdditionalSettings
