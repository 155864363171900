import { ArrowUp } from '@phosphor-icons/react'
import { Dropdown, Radio } from 'antd'
import Down from 'helper/Icons/DownIcon'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import {
  AttendeeAge,
  Grades,
  ParticpentsGender,
  Schools,
  TicketsArr,
} from './filterArry'

const KidsFilters = () => {
  const [open, setOpen] = useState(0)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])
  const bDates: Date[] = []
  const [selectedDates, setSelectedDates] = useState<any>([])

  const handleTileClick = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates.includes(formattedDate)) {
      if (selectedDates.length + 1 > 10) {
        const updatedSelectedDates = selectedDates.slice(1)
        setSelectedDates([...updatedSelectedDates, formattedDate])
      } else {
        setSelectedDates([...selectedDates, formattedDate])
      }
    } else {
      setSelectedDates(
        selectedDates.filter(
          (selectedDate: any) => selectedDate !== formattedDate,
        ),
      )
    }
  }
  const Tickets = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={TicketsArr}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const Attendie = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={AttendeeAge}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const Gender = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={ParticpentsGender}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const School = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={Schools}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const Grade = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={Grades}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const RegisteredDate = [
    {
      key: 'checkboxes',
      label: (
        <Calendar
          onClickDay={handleTileClick}
          tileClassName={({ date, view }) => {
            if (
              selectedDates.find(
                (x: any) => x === moment(date).format('YYYY-MM-DD'),
              )
            ) {
              return 'selected'
            }
          }}
        />
      ),
    },
  ]
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  return (
    <div className="flex justify-between align-center mt-25px">
      <div
        className="flex align-center"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Filter by:</h2>
        <Dropdown
          menu={{
            items: Tickets,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 1}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 1 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(1)}
          >
            Ticket <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Attendie,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 2}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(2)}
          >
            Attendee age <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Gender,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 3}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(3)}
          >
            Gender
            <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: School,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 4}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 4 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(4)}
          >
            School
            <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Grade,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(5)}
          >
            Grade
            <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: RegisteredDate,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 6}
          overlayStyle={{ width: '450px' }}
        >
          <button
            className={` ${
              open === 6 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(6)}
          >
            Registered Date
            <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: Grade,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 7}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 7 ? 'filter-btn-click ' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(7)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            First Name
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}
export default KidsFilters
