import { Row, Table, Space, Dropdown } from 'antd'
import React from 'react'
import './stripeStyle.scss'
import Actionicon from 'assets/Images/Action.svg'
import { convertToLocalWithAt } from 'helper/utility'
import viseIcon from 'assets/Images/Visa.svg'
import usePagination from 'helper/usePagination'
import SimpleCard from 'assets/Images/CreditCard.svg'

export const StripeTable = ({
  data,
  parms,
  setParms,
  setSelectedRows,
  hidCols,
}) => {
  const spaceSize = 8 // Define a constant for space size
  const items = [
    {
      key: 'edit',
      label: <p>Edit Amount</p>,
    },
    {
      key: 'delete',
      label: <p>Refund</p>,
    },
  ]
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0, // Assuming data is an array now
    currentPage: parms.page,
    setParams: setParms,
  })
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 320,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={spaceSize}>{record.description || ''}</Space>
        </div>
      ),
      className: hidCols?.description === false ? 'display-none' : '',
    },
    {
      title: 'Payment method',
      dataIndex: 'cardBrand',
      key: 'cardBrand',
      width: 246,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={spaceSize}>
            <div>
              {record?.cardBrand === 'visa' ? (
                <img src={viseIcon} alt="Visa Card" />
              ) : (
                <img src={SimpleCard} alt="Simple Card" width="32px" />
              )}
            </div>
            {record?.cardBrand}
            {record?.last4 && (
              <>
                <img src={Actionicon} alt="Action Icon" />
                {record?.last4}
              </>
            )}
          </Space>
        </div>
      ),
      className: hidCols?.cardBrand === false ? 'display-none' : '',
    },
    {
      title: 'Amount',
      dataIndex: 'amountCharged',
      key: 'amountCharged',
      width: 120,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }}>
          <Space size={spaceSize}>{`$${record.amountCharged}`}</Space>
        </div>
      ),
      className: hidCols?.amount === false ? 'display-none' : '',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: (text: any, record: any) => (
        <div className="py-10px">
          <Space size={spaceSize}>
            {convertToLocalWithAt(record?.createdAt, 'DD MMM YYYY [at] h:mm A')}
          </Space>
        </div>
      ),
      className: hidCols?.createdAt === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 190,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={spaceSize} className="status-bg">
            {record.status}
          </Space>
        </div>
      ),
      className: hidCols?.status === false ? 'display-none' : '',
    },
    // {
    //   title: 'Actions',
    //   dataIndex: 'action',
    //   key: 'action',
    //   width: 80,
    //   render: (text: any, record: any) => (
    //     <div style={{ cursor: 'pointer' }} className="py-10px">
    //       <Dropdown
    //         menu={{
    //           items: items,
    //         }}
    //         placement="bottom"
    //       >
    //         <img alt="img" src={Actionicon} />
    //       </Dropdown>
    //     </div>
    //   ),
    //   className: hidCols?.action === false ? 'display-none' : '',
    // },
  ]

  return (
    <div className="mt-20">
      <Row>
        <Table
          className="organizations_table"
          style={{ width: '100%' }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data?.records}
          rowKey="metadata.orderId"
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
    </div>
  )
}

export default StripeTable
