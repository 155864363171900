/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  isModalVisible: false,
  mentorsData: [],
  currentMentorData: [],
  totalCount: 0,
  activeMentors: [],
  pendingMentors: [],
  mentor: null,
  mentorAnalytics : null,
  mentorReviews : []
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.MENTORS_MODAL_VISIBLE:
      return {
        ...state,
        isModalVisible: action.payload,
      };

    case actions.CREATE_MENTOR: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CREATE_MENTOR_SUCCESS: {
      return {
        ...state,
        mentor: action.payload,
        loading: false,
        mentorsData: [...state.mentorsData, action.payload],
        activeMentors:
          action.payload.status === "active"
            ? [...state.activeMentors, action.payload]
            : state.activeMentors,
        pendingMentors:
          action.payload.status === "inactive"
            ? [...state.pendingMentors, action.payload]
            : state.pendingMentors,
      };
    }

    case actions.CREATE_MENTOR_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_MENTORS: {
      return {
        ...state,
        mentorsData: [],
        loading: true,
      };
    }
    case actions.GET_MENTORS_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        mentorsData: action.payload.records,
        activeMentors: action.payload.records.filter((mentor) => {
          return mentor.status === "active";
        }),
        pendingMentors: action.payload.records.filter((mentor) => {
          return mentor.status === "inactive";
        }),
        loading: false,
      };
    }

    case actions.GET_MENTORS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_MENTOR: {
      return {
        ...state,
        mentor: {},
        loading: true,
      };
    }

    case actions.GET_MENTOR_SUCCESS: {
      return {
        ...state,
        mentor: action.payload,
        loading: false,
      };
    }

    case actions.GET_MENTOR_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_MENTOR_ANALYTICS: {
      return {
        ...state,
        mentorAnalytics: {},
        loading: true,
      };
    }

    case actions.GET_MENTOR_ANALYTICS_SUCCESS: {
      return {
        ...state,
        mentorAnalytics: action.payload,
        loading: false,
      };
    }

    case actions.GET_MENTOR_ANALYTICS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ADD_MENTORS_DATA: {
      localStorage.setItem("mentors", JSON.stringify(action.payload));
      return {
        ...state,
        mentorsData: action.payload,
      };
    }

    case actions.CURRENT_MENTOR_DATA: {
      return {
        ...state,
        currentMentorData: action.payload,
      };
    }

    case actions.DELTE_MENTOR_DATA: {
      let filteredData = state.mentorsData.filter((item) => {
        return item.id !== action.payload.id;
      });
      localStorage.setItem("mentors", JSON.stringify(filteredData));
      return {
        ...state,
        mentorsData: filteredData,
      };
    }
    case actions.UPDATE_MENTOR_STATUS: {
      return {
        ...state,
        mentor: {},
        loading: true,
      };
    }

    case actions.UPDATE_MENTOR_STATUS_SUCCESS: {
      return {
        ...state,
        activeMentors:
          action.payload.status === "active"
            ? [...state.activeMentors, action.payload]
            : state.activeMentors.filter((mentor) => {
                return mentor.id !== action.payload.id;
              }),
        pendingMentors:
          action.payload.status === "inactive"
            ? [...state.pendingMentors, action.payload]
            : state.pendingMentors.filter((mentor) => {
                return mentor.id !== action.payload.id;
              }),
        loading: false,
      };
    }

    case actions.UPDATE_MENTOR_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_MENTOR_REVIEWS: {
      return {
        ...state,
        mentorReviews: [],
        loading: true,
      };
    }

    case actions.GET_MENTOR_REVIEWS_SUCCESS: {
      return {
        ...state,
        mentorReviews: action.payload,
        loading: false,
      };
    }

    case actions.GET_MENTOR_REVIEWS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }






    default:
      return state;
  }
};
