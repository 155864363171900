import axios from 'axios'
import { notification } from 'antd'
import authActions from '../Redux/Auth/action'
import failreAction from '../Redux/Failure/action'
import { authentication } from '../Firebase'

import { store } from './app-store'
import { myStore } from 'state/roleStore'
const BASE_URL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(async req => {
  req.headers.Accept = 'application/json'
  req.headers['Content-Type'] = 'application/json'
  req.headers.Accept = '*/*'
  const user = authentication.currentUser
  if (user != null) {
    const authToken = await user.getIdToken()
    req.headers.authorization = authToken
    // req.headers['bam_app'] = 'admin'
    // const role = myStore.getState().role ?? 'admin'
    // const role = localStorage.getItem('role') ?? 'admin'
    // console.log(role, 'role')
    // console.log(localStorage.getItem('role'), 'role2')
    req.headers['bam_app'] = localStorage.getItem('role') ?? 'admin'
  }
  return req
})
/**
 * Gets the headers.
 *
 */

const getHeaders = params => {
  let config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    params,
  }

  return config
}

const getFilePostHeaders = () => {
  let authToken = localStorage.auth_token
    ? JSON.parse(localStorage.auth_token)
    : null

  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  if (authToken) {
    config.headers.authorization = authToken
  }

  return config
}

const checkError = error => {
  if (error.response && error.response.data) {
    let { data } = error.response
    if (data.message) {
      notification['error']({
        message: data.message,
      })
    }
  } else {
    notification['error']({
      message: error.message,
    })
  }
  if (error.response) {
    if (error.response.status === 401) {
      store.dispatch(authActions.logout())
      store.dispatch(failreAction.failure())
    } else if (error.response.status === 403) {
      // store.dispatch(authActions.logout());
    }
  }
}

const successMessage = (message = 'Success') => {
  notification.success({
    message,
  })
}

/**
 * Get call from Axios
 */
const axiosGet = async (url, params) => {
  try {
    let request = await axios.get(`${BASE_URL}/${url}`, { params })
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

/**
 * Post request from axios
 */

const axiosPost = async (data, url, message) => {
  try {
    let request = await axios.post(`${BASE_URL}/${url}`, data)
    if (request.data && request.status === 200) {
      await successMessage(message ? message : 'Data Added Successfully !')
    }

    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

const FileEntry = async (data, url) => {
  try {
    let request = await axios.post(`${BASE_URL}/${url}`, data, getHeaders())
    // if (request.data && request.status === 200) {
    //   await successMessage('Data Changed Successfully !')
    // }
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

const axiosFilePost = async (data, url) => {
  try {
    console.log(data)
    let request = await axios.post(
      `${BASE_URL}/${url}`,
      data,
      getFilePostHeaders(),
    )
    if (request.data && request.data.message) {
      await successMessage('File uploaded')
    }
    return request
  } catch (error) {
    console.log(error)
    checkError(error)
    throw error.response.data
  }
}

/**
 * Update request from axios.
 */
const axiosPut = async (data, url) => {
  try {
    let request = await axios.put(`${BASE_URL}/${url}`, data, getHeaders())

    if (request.data) {
      await successMessage(request.data.message || 'Data updated succeessfully')
    }
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

// const axiosPatch = async (data, url) => {
//   try {
//     let request = await axios.patch(`${BASE_URL}/${url}`, data)

//     if (request.data && request.status === 200) {
//       await successMessage('Data Updated Successfully !')
//     }
//     return request
//   } catch (error) {
//     checkError(error)
//     throw error.response.data
//   }
// }
const axiosPatch = async (
  data,
  url,
  message,
  isParticipantCheckout = false,
) => {
  try {
    let request = await axios.patch(`${BASE_URL}/${url}`, data)

    if (request.status === 200) {
      if (isParticipantCheckout) {
        await successMessage('Participant checkout successfully')
      } else {
        await successMessage(message || 'Data Updated Successfully !')
      }
    }
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

const axiosPatchCheckout = async (data, url, message) => {
  try {
    let request = await axios.patch(`${BASE_URL}/${url}`, data)

    if (request.status === 200) {
      await successMessage(message || 'Data Updated Successfully !')
    }
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

/**
 * Save request from axios
 */
const axiosSave = async (oldTask, newTask, url) => {
  try {
    let request = await axios.put(
      `${BASE_URL}/${url}`,
      {
        id: oldTask.id,
        task: newTask,
      },
      getHeaders(),
    )
    if (request.data && request.data.message) {
      await successMessage(request.data.message)
    }
    return request
  } catch (error) {
    checkError(error)
    throw error.response.data
  }
}

/**
 * Delete call from axios
 */
const axiosDelete = async url => {
  let request = await axios.delete(`${BASE_URL}/${url}`)
  if (request.data && request.status === 200) {
    await successMessage('Deleted Successfully!')
  } else {
  }
  return request
}

const axiosDeleteWithBody = async (payload, url) => {
  let request = await axios.delete(`${BASE_URL}/${url}`, { data: payload })
  if (request.data && request.status === 200) {
    await successMessage('Deleted Successfully!')
  }
  return request
}

export {
  axiosGet,
  axiosPost,
  axiosDelete,
  axiosDeleteWithBody,
  axiosSave,
  axiosPut,
  axiosFilePost,
  axiosPatch,
  axiosPatchCheckout,
  FileEntry,
}
