import React from 'react'
import * as phosphorIcon from '@phosphor-icons/react'
import { CaretRight } from '@phosphor-icons/react'

interface IconsTextNavigationProps {
  iconName: string
  heading: string
  details?: string | number
}

const IconsTextNavigation: React.FC<IconsTextNavigationProps> = ({
  iconName,
  heading,
  details,
}) => {
  const ActualIcon = phosphorIcon[iconName]
  if (!ActualIcon) {
    return <div>icon not found</div>
  }
  return (
    <div className="flex align-center justify-between pointer">
      <div className="flex gap-10px align-center">
        <div
          className="flex align-center justify-center radius-rounded"
          style={{ width: '48px', height: '48px', backgroundColor: '#F5F6F8' }}
        >
          <ActualIcon size={24} color={'#000'} />
        </div>
        <div>
          <h3 className="w-500 text-16">{heading}</h3>
          <p className="w-400 text-13 text-gray">{details}</p>
        </div>
      </div>

      <div>
        <CaretRight size={18} color="#000" />
      </div>
    </div>
  )
}

export default IconsTextNavigation
