import { Row, Col, Button, Tooltip, Space, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useState } from 'react'
import ExportActivity from './ExportActivity'
import downloadImg from 'assets/Images/Download.svg'
import FilePlus from '../../../assets/Images/FilePlus.svg'
import ImportActivity from './ImportActivity'
const PageHeaderInventory = ({ data }: any) => {
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const handleImportClick = () => {
    setIsImportModalVisible(true)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
        <Space className="mt-10" onClick={handleImportClick}>
          <img alt="importIcon" src={FilePlus} /> Import
        </Space>
      </Menu.Item>
    </Menu>
  )
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleImportModalClose = () => {
    setIsImportModalVisible(false)
  }
  return (
    <Row className="page-header" justify="space-between">
      <Col lg={{ span: 6 }} xs={{ span: 24 }}>
        <h1 className="table-head mb-0">Activity</h1>
      </Col>
      <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
        <div className="right-search-block">
          <div
            className="table-action-btn new-organizations-export-btn"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Dropdown
              overlay={menu}
              placement="bottom"
              className="bg-grey mx-20px"
            >
              <button className=" prim-sm-btn" style={{ color: 'black' }}>
                More <DownOutlined />
              </button>
            </Dropdown>

            <Tooltip placement="bottom" title="Create">
              <Button className={'btn-new-activity primary br-8'}>
                Add activity
              </Button>
            </Tooltip>
          </div>
        </div>
      </Col>
      <ExportActivity
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={data?.records}
      />
      {isImportModalVisible && (
        <ImportActivity
          visible={isImportModalVisible}
          onClose={handleImportModalClose}
        />
      )}
    </Row>
  )
}
export default PageHeaderInventory
