import React, { useState } from "react";
import { Modal, Button, Upload } from "antd";
import { Layout } from "antd";
import ReactQuill from "react-quill";
import getBase64 from "../../../helper/getBase64";
import ImageSquareSvg from "../../../assets/Images/ImageSquare.svg";
const PrivacyPolicyModal = ({
  Visible,
  isModalVisible,
  setModalVisible,
  props,
}: any) => {
  const [value, setValue] = useState();
  const handleOk = () => {
    Visible(false);
    setModalVisible(false);
  };
  // const handleCancel = () => {
  //   Visible(false);
  //   setModalVisible(false);
  // };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "link",
    "blockquote",
  ];
  const handleProcedureContentChange = (content: any) => {
    setValue(content);
    setTimeout(() => {
      props.editorDataChange(content);
    }, 1000);
  };
  const [imageUrl, setImageUrl] = useState();
  const handleChangePhoto = (info: any) => {
    getBase64(info.file.originFileObj, (url: any) => {
      setImageUrl(url);
    });
  };
  const uploadButton = (
    <div>
      {<img alt="" src={ImageSquareSvg}></img>}
      <div style={{ marginTop: 8 }}>Add a picture</div>
    </div>
  );
  return (
    <Layout>
      <Modal
        title="Privacy Policy"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setModalVisible(false);
        }}
        centered={true}
        footer={null}
      >
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={value || ""}
          onChange={handleProcedureContentChange}
        >
          <div className="my-editing-area" />
        </ReactQuill>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={handleChangePhoto}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        <div>
          <Button
            style={{
              backgroundColor: "#2BA7CA",
              borderRadius: "20px",
              marginTop: "144px",
              left: "400px",
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};
export default PrivacyPolicyModal;
