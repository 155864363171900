import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPost } from "../axiosHelper";
import failureAction from "../Failure/action";

export function* createMentorRequest({ payload }) {
  try {
    const { data } = yield axiosPost(
      payload,
      `v1/mentor-request/create-request`
    );
    // yield put(actions.createMentorRequestSuccess(data.records));
  } catch (error) {
    console.log(error);
    yield put(failureAction.failure(error));
  }
}

export function* getMentorsRequests({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 10,
      sort: params?.sort?.order || "name",
      populate: "guardianRequester",
    };
    const { data } = yield axiosGet(`v1/admins/mentor-requests`, currentParams);
    yield put(actions.getMentorsRequestsSuccess(data));
  } catch (error) {
    yield put(actions.getMentorsRequestsFailure());
    yield put(failureAction.failure(error));
  }
}

export function* proposeMentor({ payload }) {
  console.log(payload);
  try {
    const { data } = yield axiosPost(payload, `v1/admins/propose-mentor`);
    yield put(actions.proposeMentorSuccess(data.records));
  } catch (error) {
    console.log(error);
    yield put(failureAction.failure(error));
  }
}

export function* getJobProposed({ payload }) {
  try {
    const { data } = yield axiosGet(
      `v1/mentors/proposed-jobs/62d84be0a3f124fc06977aa1`
    );
    yield put(actions.getJobProposedSuccess(data.records));
  } catch (error) {
    yield put(actions.getJobProposedFailure());
    yield put(failureAction.failure(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.MENTOR_REQUESTS, getMentorsRequests),
    takeLatest(actions.PROPOSE_MENTOR, proposeMentor),
    takeLatest(actions.GET_JOB_PROPOSED, getJobProposed),
    takeLatest(actions.CREATE_MENTOR_REQUEST, createMentorRequest),
  ]);
}
