import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Layout } from "antd";
import { Steps, message } from "antd";
import Basicinfo from "./Screens/Basicinfo";
import OrganizationDetails from "./Screens/OrganizationDetails";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Organization/action";
import OrganizerInfo from "./Screens/OrganizerInfo";
import moment from "moment";

const { Step } = Steps;

const OrganizationModal = ({ currentData, modalVisible }: any) => {
  const [current, setCurrent] = React.useState(currentData ? 1 : 0);
  const [data, setData] = useState(currentData || {});
  const dispatch = useDispatch();

  const next = (childData: any) => {
    setData(childData);
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(modalVisible);
  }, [modalVisible]);

  const handleOk = () => {
    setIsModalVisible(false);
    dispatch({ type: actions.MODAL_VISIBLE, payload: false });
    dispatch({ type: actions.CURRENT_ORGANIZATION_DATA, payload: null });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch({ type: actions.MODAL_VISIBLE, payload: false });
    dispatch({ type: actions.CURRENT_ORGANIZATION_DATA, payload: null });
  };

  const steps = {
    create: [
      {
        title: "Organizer Info",
        content: <OrganizerInfo data={data} onSuccess={next} />,
      },
      {
        title: "Basic Info",
        content: <Basicinfo data={data} onSuccess={next} />,
      },
      {
        title: "organization details",
        content: <OrganizationDetails />,
      },
    ],
    edit: [
      {
        title: "Basic Info",
        content: <Basicinfo data={data} onSuccess={next} />,
      },
      {
        title: "organization details",
        content: <OrganizationDetails />,
      },
    ]
  };

  const onSubmit = (organization: any) => {
    if (organization.id) {
      let updatePayload = {
        photoId: organization.photoId,
        name: organization.name,
        phone: organization.phone,
        isLandlinePhone: true,
        email: organization.email,
        establishedOn: moment(organization.establishedOn).format("YYYY-MM-DD"),
        nonProfit: true,
        address: organization.address,
        website: organization.website,
        about: organization.about,
        social: organization.social,
        designation: organization.designation,
      };
      console.log("hashim", updatePayload);
      dispatch({
        type: actions.UPDATE_ORGANIZATION,
        payload: updatePayload,
        params: organization.id,
      });
    } else {
      dispatch({ type: actions.ADD_ORGANIZATION_DATA, payload: organization });
    }
    dispatch({ type: actions.MODAL_VISIBLE, payload: false });
  };

  return (
    <Layout>
      <Modal
        className="organization_model"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>
            {currentData
              ? "Edit Organization"
              : "New Organization"}
          </h4>
          <Steps current={current}>
            {steps[currentData ? 'edit' : 'create'].map((item) => (
              <Step key={item.title} className="stepper" title={item.title} />
            ))}
          </Steps>
        </div>

        <div>
          {current === 0 && <OrganizerInfo data={data} onSuccess={next} />}
          {current === 1 && (
            <Basicinfo data={data} onSuccess={next} handleBack={prev} />
          )}
          {current === 2 && (
            <OrganizationDetails
              data={data}
              onSubmit={onSubmit}
              handleBack={prev}
            />
          )}
        </div>
      </Modal>
    </Layout>
  );
};
export default OrganizationModal;
