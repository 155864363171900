import { Row, Col, Form, DatePicker, TimePicker } from "antd";
const OneTimeSession = ({ option }: any) => {
  return (
    <div>
      {option === "one_time" && (
        <Row gutter={[20, 0]}>
          <Col>
            <label className="input-label">Date & Time</label>
            <Form.Item
              name="oneTimeDate"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker
                className="w-100 form-control-field"
                placeholder="Date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="oneTimeStart"
              rules={[{ required: true, message: "Start time is required" }]}
            >
              <TimePicker
                className="w-100 form-control-field"
                placeholder="Start Time"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="oneTimeEnd"
              rules={[{ required: true, message: "End time is required" }]}
            >
              <TimePicker
                className="w-100 form-control-field"
                placeholder="End  Time"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default OneTimeSession;
