import { Button, Col, Layout, List, Radio, RadioChangeEvent, Row } from "antd";
import { useState } from "react";
import Suitcase from "../../../../../assets/Images/Suitcase.svg";
import LockSimple from "../../../../../assets/Images/LockSimple.svg";
import Users from "../../../../../assets/Images/Users.svg";
import Eye from "../../../../../assets/Images/Eye.svg";
import rightArrowIcon from "../../../../../assets/Images/rightarrowicon.svg";
import CalendarBlank from "../../../../../assets/Images/CalendarBlank.svg";

const accessLevelData = [
  {
    icon: <img src={Suitcase} alt=""></img>,
    title: "Full Admin",
    value: "full admin",
  },
  {
    icon: <img src={LockSimple} alt=""></img>,
    value: "organizer",

    title: "Organizer",
  },
  {
    icon: <img src={Users} alt=""></img>,
    value: "staff-member",

    title: "Staff Member",
    description: "Cannot edit organization profile or create events",
  },
  {
    icon: <img src={Eye} alt=""></img>,
    value: "view-only",

    title: "View Only",
    description: "Cannot edit organization profile or create or edit events",
  },
  {
    icon: <img src={CalendarBlank} alt=""></img>,
    value: "event-manager",

    title: "Event Manager",
    description:
      "Only some specific events that are assigned to them can be viewed",
  },
];

const AccessLevel = ({ handleBack, handleNext, data }: any) => {
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const onFinish = () => {
    value > 1 ? setValue(value - 1) : setValue(0);

    handleNext({
      accessLevel: accessLevelData[value].value,
    });
  };
  return (
    <Layout>
      <List
        itemLayout="horizontal"
        dataSource={accessLevelData}
        renderItem={(item) => (
          <List.Item className="accesslecel-list-items">
            <Row justify="space-between" className="w-100">
              <Col span={22}>
                <div className="access-items-box d-flex">
                  <span className="access-icon-box">{item.icon}</span>
                  <div>
                    <h4>
                      <b className="b2">{item.title}</b>
                    </h4>
                    <h5>
                      <b className="b3 text-secondary">{item.description}</b>
                    </h5>
                  </div>
                </div>
              </Col>
              <Col span={2} className="text-right">
                <Radio
                  onChange={onChange}
                  value={value}
                  name="accesslevel"
                  className="radio"
                ></Radio>
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <Row justify="space-between" align="middle">
        <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
          <span className="mr-10 d-inline-block">
            <img src={rightArrowIcon} alt="right " />
          </span>
          Back
        </h5>
        <Button
          className="stepper-button"
          htmlType="submit"
          onClick={() => {
            onFinish();
          }}
        >
          Next
        </Button>
      </Row>
    </Layout>
  );
};

export default AccessLevel;
