import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet } from "../axiosHelper";
import failureAction from "../Failure/action";

 export function* getAmenitiesRequest() {
    try {
      const { data } = yield axiosGet(`v1/lists/amenities`);
      console.log("da:",data);
      yield put(actions.getAmenitiesRequestSuccess(data));
    } catch (error) {
      yield put(actions.getAmenitiesRequestFailure());
      yield put(failureAction.failure(error));
    }
  }

  export function* addAmenitiesRequest({ payload }) {
    try {
      console.log("payload:",payload)
      // const organizationId = payload.organizationId
      // const { data } = yield axiosPost(payload, `v1/organization-events/${organizationId}/event-step1`);
      // yield put(actions.createEventSuccess(data.records));
      // console.log("da:",data);
    } catch (error) {
      yield put(failureAction.failure(error));
    }
  }


export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_AMENITIES_REQUEST, getAmenitiesRequest),
    takeLatest(actions.ADD_AMENITIES_DATA, addAmenitiesRequest),
  ]);
}
