import { Typography, Row, Col, Button, notification } from "antd";
import { useHistory } from "react-router";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import bamreclogo from "../../assets/Images/bamrec-logo.svg";
import ArrowCounterClockwise from "../../assets/Images/ArrowCounterClockwise.svg";
import { useState } from "react";
import OtpInput from "react-otp-input";
import {useDispatch } from "react-redux";
import actions from "../../Redux/Auth/action";

const PhoneVerify = ({ confirmObj }: any) => {
  const history = useHistory();
  const [otp, setOtp] = useState();
  const dispatch = useDispatch();

  const verifyOtp = () => {
    if (otp !== "" || !otp === null) {
      try {
        confirmObj
          .confirm(otp)
          .then((result: any) => {
            // User signed in successfully.
            const user = result.user;
            console.log(user);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem(
              "auth_token",
              JSON.stringify(user.accessToken)
            );
            dispatch({
              type: actions.LOGIN_SUCCESS,
              payload: user,
            });

            history.push("/dashboard");
            // ...
          })
          .catch((error: any) => {
            // User couldn't sign in (bad verification code?)
            // ...
            notification["error"]({
              message: "something went wrong",
            });
          });
      } catch (error) {
        notification["error"]({
          message: "please enter valid otp",
        });
      }
    }
  };

  return (
    <>
      <div className={"login-form-container"}>
        <Row style={{ width: "100%" }}>
          <Col md={24} lg={10} xl={10} style={{ background: "#fff" }}>
            <div className="left-login-form">
              <div>
                <Row>
                  <Col>
                    <Typography className={"title-fontStyle"}>
                      <img alt="img" src={bamreclogo} />
                    </Typography>
                  </Col>
                </Row>
              </div>
              <div className={"margin-20"} style={{ paddingTop: "100px" }}>
                <div>
                  <Row>
                    <Col>
                      <h1>OTP Verification</h1>
                    </Col>
                    <Col>
                      <div className="enter-otp-text">
                        <p>Enter the OTP you received to</p>
                        <label>+1 (305) 1234 567</label>
                      </div>
                    </Col>
                    <Col
                      className="otp-for-phone"
                      style={{ marginRight: "80px" }}
                    >
                      {/*<OtpInput*/}
                      {/*  onChange={(otp: any) => setOtp(otp)}*/}
                      {/*  // value={this.state.otp}*/}
                      {/*  // onChange={this.handleChange}*/}
                      {/*  value={otp}*/}
                      {/*  numInputs={6}*/}
                      {/*  renderSeparator={<span>-</span>}*/}
                      {/*/>*/}
                    </Col>
                    <Col className="text-center">
                      <Button
                        className="primary"
                        onClick={() => {
                          verifyOtp();
                        }}
                      >
                        Verify Otp
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <img alt="img" src={ArrowCounterClockwise} />
                      <a>Resend code</a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col md={24} lg={14} xl={14}>
            <div className="right-col-blue"></div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PhoneVerify;
