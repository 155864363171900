import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import EventView from '../../../components/Events/Views'

const OrganizerEvents: React.FC<{}> = ({
  eventsData,
  organizationData,
}: any) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(eventsData)
  }, [eventsData])

  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        {/* needs some work */}
        {/* <EventView
          data={data}
          setData={setData}
          eventsData={eventsData}
          organizationData={organizationData}
        /> */}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  eventsData: state.events.eventsData,
  modalVisible: state.events.ismodelVisible,
  organizationData: state.organization.organizationData,
})
export default connect(mapStateToProps)(OrganizerEvents)
