import React, { useState } from 'react'
import { ConfigProvider } from 'antd-country-phone-input'
import CountryPhoneCode from 'antd-country-phone-input'
import en from 'world_countries_lists/data/countries/en/world.json'
import 'antd-country-phone-input/dist/index.css'

const PhoneField = ({ phone, form, data }: any) => {
  const [phoneValue, setPhoneValue] = useState({
    code: data?.dialCode,
    phone: data?.number,
    short: data?.isoCode || 'US',
  })

  return (
    <div>
      <ConfigProvider locale={en}>
        <CountryPhoneCode
          className="customPhoneCode"
          value={phoneValue}
          // className="your-custom-class"
          onChange={(v: any) => {
            setPhoneValue(v)
            form.setFieldsValue({
              phone: {
                dialCode: String(v.code),
                number: '+' + v.code + v.phone,
                isoCode: v.short,
              },
            })
          }}
        />
      </ConfigProvider>
    </div>
  )
}

export default PhoneField
