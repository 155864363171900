import { Button, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import plusBlue from '../../../../../../../assets/Images/plusBlue.svg'
import ViewLocationModal from './ViewLocationModal'
import { useGetOrgLocations } from 'api/EventCreation/EventCreation'
import Loader from 'helper/Loader'
import { Address } from 'types/globalTypes'

interface OrganizationLocationListingProps {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  organizationId: string
  setMapModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setMapModalData: React.Dispatch<React.SetStateAction<Address>>
  setFieldsValue: any
  setIsFromPreviousLoc: React.Dispatch<React.SetStateAction<boolean>>
}

const OrganizationLocationListing: React.FC<
  OrganizationLocationListingProps
> = ({
  setModalVisible,
  organizationId,
  setMapModalVisible,
  setMapModalData,
  setFieldsValue,
  setIsFromPreviousLoc,
}) => {
  const { data, isLoading, isFetching, refetch } =
    useGetOrgLocations(organizationId)
  const [viewAddressVisible, setViewAddressVisible] = useState(false)
  const [isLocationSelected, setIsLocationSelected] = useState(false)
  const [mapData, setMapData] = useState(null)
  const [selectedLocationDetailItem, setSelectedLocationDetailItem] =
    useState<any>({})

  useEffect(() => {
    refetch()
  }, [])

  const onConfirm = () => {
    setFieldsValue(selectedLocationDetailItem)
    setMapModalData(selectedLocationDetailItem)
    setIsFromPreviousLoc(true)
    setModalVisible(false)
  }

  const handleShowMapModal = () => {
    setMapModalVisible(true)
    setIsFromPreviousLoc(false)
  }

  return (
    <div className="used-users-list">
      <div
        style={{
          maxHeight: '400px',
          marginTop: '20px',
          overflowY: 'auto',
          marginBottom: '15px',
        }}
      >
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          data.length > 0 &&
          data.map((item: Address) => {
            return (
              <Row justify="space-between" style={{ marginTop: '20px' }}>
                <Col span={16}>
                  {' '}
                  <h5 className="text-14 w-600">
                    {`${item.title}:`}
                    <span
                      className="w-400"
                      style={{ display: 'inline' }}
                    >{` ${item?.street} , ${item?.city} , ${item?.country} `}</span>
                  </h5>
                </Col>
                <Col span={8} className="d-flex gap-15">
                  <Button
                    className="primary-transparent br-8"
                    onClick={() => {
                      setViewAddressVisible(true)
                      setMapData(item)
                    }}
                  >
                    View
                  </Button>
                  <Button
                    className={
                      selectedLocationDetailItem == item && isLocationSelected
                        ? 'btn-blue'
                        : 'primary-transparent br-8'
                    }
                    onClick={() => {
                      setSelectedLocationDetailItem(item)
                      if (selectedLocationDetailItem == item) {
                        setIsLocationSelected(!isLocationSelected)
                      } else {
                        setIsLocationSelected(true)
                      }
                    }}
                  >
                    {selectedLocationDetailItem == item && isLocationSelected
                      ? 'selected'
                      : 'select'}
                  </Button>
                </Col>
              </Row>
            )
          })
        )}
      </div>
      <Row justify="space-between" align="middle">
        <h5
          className="text-primary cursor_pointer"
          onClick={handleShowMapModal}
        >
          <b className="b2">
            <img src={plusBlue} alt="plus" /> Add custom Location
          </b>
        </h5>
        {viewAddressVisible && (
          <ViewLocationModal
            isVisible={viewAddressVisible}
            setIsVisible={setViewAddressVisible}
            mapData={mapData}
          />
        )}
        <Button
          disabled={!isLocationSelected}
          className="stepper-button"
          onClick={() => {
            onConfirm()
          }}
        >
          Confirm
        </Button>
      </Row>
    </div>
  )
}

export default OrganizationLocationListing
