import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import moment from 'moment'
import Calendar from 'react-calendar'
import { RequestParms } from 'types/globalTypes'
import 'react-calendar/dist/Calendar.css'
import { convertToLocal } from './utility'

interface calanderProps {
  setSelectedDate: React.Dispatch<React.SetStateAction<RequestParms | any>>
  selectedDate: Date | string
  dates: Date[]
  classVal?: string
}
const MultiDateSelectCalendar = ({
  setSelectedDate,
  selectedDate,
  dates,
  classVal,
}: calanderProps) => {
  const bDates: Date[] = []
  dates?.map((date: any) => {
    const utcDate = new Date(convertToLocal(date))
    bDates.push(utcDate)
  })
  const onCalenderSelect = (e: any) => {
    var inputDate = new Date(e).setHours(0, 0, 0, 0)
    setSelectedDate(inputDate)
  }
  let calenderCalass = classVal ? classVal : 'event-dates'
  return (
    <Calendar
      onChange={onCalenderSelect}
      tileDisabled={({ date }) =>
        !bDates.some(
          bDate =>
            bDate.getDate() === date.getDate() &&
            bDate.getMonth() === date.getMonth(),
        )
      }
      value={selectedDate}
      prevLabel={<ArrowLeft />}
      nextLabel={<ArrowRight />}
      tileClassName={({ date, view }) => {
        if (dates?.find((x: any) => x === moment(date).format('YYYY-MM-DD'))) {
          return 'event-dates'
        }
      }}
    />
  )
}
export default MultiDateSelectCalendar
