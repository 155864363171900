import React, { useState } from 'react'
import { Modal, Input } from 'antd'
import closeIcon from 'assets/Images/close.svg'
import { Trash } from '@phosphor-icons/react'

interface AddGroupProps {
  modalOpen: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  groups: string[]
  setGroups: React.Dispatch<React.SetStateAction<string[]>>
}

const AddGroup: React.FC<AddGroupProps> = ({
  modalOpen,
  closeModal,
  groups,
  setGroups,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const handleCancel = () => {
    closeModal(false)
  }

  const handleAdd = () => {
    if (inputValue.trim() !== '') {
      setGroups([...groups, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleDeleteGroup = (index: number) => {
    const updatedGroups = groups.filter((_, i) => i !== index)
    setGroups(updatedGroups)
  }

  const handleRemoveAndCancel = () => {
    setGroups([])
    handleCancel()
  }

  return (
    <div>
      <Modal
        open={modalOpen}
        onCancel={handleCancel}
        className="add-supplyform-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Add group</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-30px flex align-center justify-between gap-20px">
          <Input
            placeholder="Add group"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
          <button
            className="border-1px-blue px-30px py-12px radius-8 pointer"
            onClick={handleAdd}
          >
            Add
          </button>
        </div>

        <div className="h-15 overflow-auto-y scroller mt-10px">
          {groups.map((item, index) => {
            return (
              <div
                key={index}
                className="flex align-center justify-between mt-10px"
              >
                <p>{item}</p>
                <Trash
                  size={20}
                  color="#ff6961"
                  onClick={() => handleDeleteGroup(index)}
                  className="pointer"
                />
              </div>
            )
          })}
        </div>
        <div className="mt-10px flex align-center justify-between">
          <button
            className="create-activity-btn"
            onClick={handleRemoveAndCancel}
          >
            Cancel
          </button>
          <button className="confirm-btn" onClick={handleCancel}>
            Apply
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default AddGroup
