
import { connect } from 'react-redux';
import AddPaymentModal from '../../Modals/SettingsModal/PaymentModal/AddPaymentModal';
import PaymentMethod from './PaymentMethod';

const Payment = ({ match, modalVisible, eventsData, organizationData }: any) => {

  return (
    <div>
      <div>
        <PaymentMethod />
      </div>
      {modalVisible ? (
        <AddPaymentModal
          modalVisible={modalVisible}
        // currentData={currentData}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  modalVisible: state.settings.isModalVisible,  
});
export default connect(mapStateToProps)(Payment);


