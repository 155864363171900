/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  isModalVisible: false,
  organizer: {},
  organizerData: [],
  activeOrganizers: [],
  pendingOrganizers: [],
  currentOrganizer: null,
  currentMenu: "",
  loading: false,
  allergiesList: [],
  updateFlag: false,
  organizations: [],
  accessLevel: [],
  totalCount: 0,
  isChangingPassword: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.OPEN_ORGANIZER_CHANGE_PASSWORD:
      return {
        ...state,
        isModalVisible: true,
        isChangingPassword: true,
      }
    case actions.CLOSE_ORGANIZER_CHANGE_PASSWORD:
      return {
        ...state,
        isModalVisible: false,
        isChangingPassword: false,
      }
    case actions.ORGANIZER_MODAL_VISIBLE:
      return {
        ...state,
        isModalVisible: action.payload,
      };

    case actions.ADD_ORGANIZER: {
      return {
        ...state,
        organizer: {},
        loading: true,
      };
    }

    case actions.ADD_ORGANIZER_DATA_SUCCESS: {
      return {
        ...state,
        organizer: action.payload,
        loading: false,
        activeOrganizers:
          action.payload.status === "active"
            ? [...state.activeOrganizers, action.payload]
            : state.activeOrganizers,
        pendingOrganizers:
          action.payload.status === "inactive"
            ? [...state.pendingOrganizers, action.payload]
            : state.pendingOrganizers,
      };
    }

    case actions.ADD_ORGANIZER_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZERS: {
      return {
        ...state,
        organizerData: [],
        loading: true,
      };
    }

    case actions.GET_ORGANIZERS_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        organizerData: action.payload.records,
        activeOrganizers: action.payload.records.filter((organizer) => {
          return organizer.status === "active";
        }),
        pendingOrganizers: action.payload.records.filter((organizer) => {
          return organizer.status === "inactive";
        }),
        loading: false,
      };
    }

    case actions.GET_ORGANIZERS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZER: {
      return {
        ...state,
        organizer: {},
        loading: true,
      };
    }

    case actions.GET_ORGANIZER_SUCCESS: {
      return {
        ...state,
        organizer: action.payload,
        loading: false,
      };
    }

    case actions.GET_ORGANIZER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ALLERGIES: {
      return {
        ...state,
        allergiesList: [],
        loading: true,
      };
    }

    case actions.GET_ALLERGIES_SUCCESS: {
      return {
        ...state,
        allergiesList: action.payload,
        loading: false,
      };
    }

    case actions.GET_ALLERGIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_ORGANIZER: {
      return {
        ...state,
        organizer: {},
        loading: true,
        updateFlag: true,
      };
    }

    case actions.UPDATE_ORGANIZER_SUCCESS: {
      return {
        ...state,
        organizer: action.payload,
        activeOrganizers:
          action.payload.status === "active"
            ? state.activeOrganizers.map((organizer) => {
                return organizer.id === action.payload.id
                  ? action.payload
                  : organizer;
              })
            : state.activeOrganizers,
        pendingOrganizers:
          action.payload.status === "inactive"
            ? state.pendingOrganizers.map((organizer) => {
                return organizer.id === action.payload.id
                  ? action.payload
                  : organizer;
              })
            : state.pendingOrganizers,
        loading: false,
        updateFlag: false,
      };
    }

    case actions.UPDATE_ORGANIZER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_ORGANIZER: {
      let filteredData = state.organizerData.filter((item) => {
        return item.id !== action.payload;
      });
      localStorage.setItem("organizer", JSON.stringify(filteredData));
      return {
        ...state,
        organizerData: filteredData,
      };
    }

    case actions.CURRENT_MENU: {
      return {
        ...state,
        currentMenu: action.payload,
      };
    }

    case actions.CURRENT_ORGANIZER: {
      return {
        ...state,
        currentOrganizer: action.payload,
      };
    }

    case actions.DELETE_ORGANIZER_DATA: {
      let filteredData = state.organizationData.filter((item) => {
        return item.id !== action.payload;
      });
      localStorage.setItem("organization", JSON.stringify(filteredData));
      return {
        ...state,
        organizationData: filteredData,
      };
    }

    case actions.CURRENT_ORGANIZER_DATA: {
      return {
        ...state,
        currentOrganizationData: action.payload,
      };
    }

    case actions.UPDATE_ORGANIZER_STATUS_SUCCESS: {
      return {
        ...state,
        organizer: action.payload,
        activeOrganizers:
          action.payload.status === "active"
            ? [...state.activeOrganizers, action.payload]
            : state.activeOrganizers.filter((organizer) => {
                return organizer.id !== action.payload.id;
              }),
        pendingOrganizers:
          action.payload.status === "inactive"
            ? [...state.pendingOrganizers, action.payload]
            : state.pendingOrganizers.filter((organizer) => {
                return organizer.id !== action.payload.id;
              }),
        loading: false,
      };
    }

    case actions.UPDATE_ORGANIZER_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ASSIGN_ORGANIZATION: {
      return {
        ...state,
        organizations: action.payload,
        loading: true,
      };
    }

    case actions.ASSIGN_ORGANIZATION_SUCCESS: {
      localStorage.removeItem("organizerId");
      return {
        ...state,
        organizations: [],
        loading: false,
      };
    }

    case actions.ASSIGN_ORGANIZATION_FAILURE: {
      localStorage.removeItem("organizerId");
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ACCESS_LEVEL: {
      return {
        ...state,
        accessLevel: [],
        loading: true,
      };
    }
    case actions.GET_ACCESS_LEVEL_SUCCESS: {
      return {
        ...state,
        accessLevel: action.payload,
        loading: false,
      };
    }

    case actions.GET_ACCESS_LEVEL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    // MY CODE FOR THE DELETE REQUEST

    case actions.DELETE_ORGANIZER_REQUEST: {
      return {
        ...state,
        organizer: {},
        loading: true,
      };
    }

    case actions.DELETE_ORGANIZER_SUCCESS: {
      return {
        ...state,
        organizer: {},
        loading: false,
      };
    }

    case actions.DELETE_ORGANIZER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }


    case actions.BLOCK_ORGANIZER_EMAIL: {
      return {
        ...state,
        organizer: {},
        loading: true,
      };
    }

    case actions.BLOCK_ORGANIZER_EMAIL_SUCCESS: {
      return {
        ...state,
        organizer: {},
        loading: false,
      };
    }

    case actions.BLOCK_ORGANIZER_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.CHANGE_ORGANIZER_PASSWORD: {
      return {
        ...state,
        organizer: {},
        loading: true,
      };
    }

    case actions.CHANGE_ORGANIZER_PASSWORD_SUCCESS: {
      return {
        ...state,
        organizer: {},
        loading: false,
      };
    }

    case actions.CHANGE_ORGANIZER_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_UI_DELETION: {
      let filteredDataActive = state.activeOrganizers.filter((item) => {
        return item.id !== action.payload._id;
      });

      let filteredDataPending = state.pendingOrganizers.filter((item) => {
        return item.id !== action.payload._id;
      });

      return {
        ...state,
        pendingOrganizers: filteredDataPending,
        activeOrganizers: filteredDataActive,
      };
    }

    default:
      return state;
  }
};
