const actions = {
  GET_ALL_ADMINS_REQUEST: "GET_ALL_ADMINS_REQUEST",
  GET_ALL_ADMINS_SUCCESS: "GET_ALL_ADMINS_SUCCESS",
  GET_ALL_ADMINS_FAILURE: "GET_ALL_ADMINS_FAILURE",
  GET_ADMIN_DETAILS: "GET_ADMIN_DETAILS",
  GET_ADMIN_DETAILS_SUCCESS: "GET_ADMIN_DETAILS_SUCCESS",
  GET_ADMIN_DETAILS_FAILURE: "GET_ADMIN_DETAILS_FAILURE",
  PATCH_ADMIN_DETAILS: "PATCH_ADMIN_DETAILS",
  PATCH_ADMIN_DETAILS_SUCCESS: "PATCH_ADMIN_DETAILS_SUCCESS",
  PATCH_ADMIN_DETAILS_FAILURE: "PATCH_ADMIN_DETAILS_FAILURE",
  CHANGE_ADMIN_PASSWORD_REQUEST: "CHANGE_ADMIN_PASSWORD_REQUEST",
  CHANGE_ADMIN_PASSWORD_SUCCESS: "CHANGE_ADMIN_PASSWORD_SUCCESS",
  CHANGE_ADMIN_PASSWORD_FAILURE: "CHANGE_ADMIN_PASSWORD_FAILURE",
  DEACTIVATE_ADMIN_REQUEST: "DEACTIVATE_ADMIN_REQUEST",
  DEACTIVATE_ADMIN_SUCCESS: "DEACTIVATE_ADMIN_SUCCESS",
  DEACTIVATE_ADMIN_FAILURE: "DEACTIVATE_ADMIN_FAILURE",
  CREATE_NEW_ADMIN_REQUEST: "CREATE_NEW_ADMIN_REQUEST",
  CREATE_NEW_ADMIN_SUCCESS: "CREATE_NEW_ADMIN_SUCCESS",
  CREATE_NEW_ADMIN_FAILURE: "CREATE_NEW_ADMIN_FAILURE",

  getAllAdmins: () => ({
    type: actions.GET_ALL_ADMINS_REQUEST,
  }),
  getAllAdminsSuccess: (payload) => ({
    type: actions.GET_ALL_ADMINS_SUCCESS,
    payload,
  }),
  getAllAdminsFailure: (payload = {}, errors = {}) => ({
    type: actions.GET_ALL_ADMINS_FAILURE,
    payload,
    errors,
  }),
  getAdminDetails: (payload) => ({
    type: actions.GET_ADMIN_DETAILS,
    payload,
  }),
  getAdminDetailsSuccess: (payload) => ({
    type: actions.GET_ADMIN_DETAILS_SUCCESS,
    payload,
  }),
  getAdminDetailsFailure: (payload = {}, errors = {}) => ({
    type: actions.GET_ADMIN_DETAILS_FAILURE,
    payload,
    errors,
  }),
  patchAdminDetails: (userId, formData) => ({
    type: actions.PATCH_ADMIN_DETAILS,
    payload: {
      userId,
      formData,
    },
  }),
  patchAdminSuccess: (payload) => ({
    type: actions.PATCH_ADMIN_DETAILS_SUCCESS,
    payload,
  }),
  patchAdminFailure: (payload = {}, errors = {}) => ({
    type: actions.PATCH_ADMIN_DETAILS,
    payload,
    errors,
  }),
  changeAdminPasswordRequest: (password, firebaseId) => ({
    type: actions.CHANGE_ADMIN_PASSWORD_REQUEST,
    payload: {
      password,
      firebaseId,
    },
  }),
  changeAdminPasswordSuccess: (payload) => ({
    type: actions.CHANGE_ADMIN_PASSWORD_SUCCESS,
    payload,
  }),
  changeAdminPasswordFailure: (payload = {}, errors = {}) => ({
    type: actions.CHANGE_ADMIN_PASSWORD_FAILURE,
    payload,
    errors,
  }),
  deactivateAdminRequest: (firebaseId) => ({
    type: actions.DEACTIVATE_ADMIN_REQUEST,
    payload: {
      firebaseId,
    },
  }),
  deactivateAdminSuccess: (payload) => ({
    type: actions.DEACTIVATE_ADMIN_SUCCESS,
    payload,
  }),
  deactivateAdminFailure: (payload = {}, errors = {}) => ({
    type: actions.DEACTIVATE_ADMIN_FAILURE,
    payload,
    errors,
  }),
  CreatenewAdminRequest: (payload = {}) => ({
    type: actions.CREATE_NEW_ADMIN_REQUEST,
    payload,
  }),
  CreatenewAdminSuccess: (payload) => ({
    type: actions.CREATE_NEW_ADMIN_SUCCESS,
    payload,
  }),
  CreatenewAdminFailure: (payload = {}, errors = {}) => ({
    type: actions.CREATE_NEW_ADMIN_FAILURE,
    payload,
    errors,
  }),
};
export default actions;
