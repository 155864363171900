import {
  Avatar,
  Col,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
  Modal,
  Input,
  Form,
} from 'antd'
import envelopSimple from 'assets/Images/EnvelopeSimple.svg'
import location from 'assets/Images/MapPin.png'
import warning from 'assets/Images/Warning.png'
import { useEffect, useState } from 'react'
import phone from 'assets/Images/Phone.svg'
import './StyleEmail.scss'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import Cancel from 'assets/Images/close.svg'
import { confirmEmail } from 'api/Organizers'
import Loader from 'helper/Loader'
import BlockEmailModal from 'helper/BlockEmailModal'
import ChangeEmailModal from 'helper/Map/ChangeEmailModal'
const OrganizerContactInfo = ({ data, refetchData }: any) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm()
  const [updatedEmail, setUpdatedEmail] = useState<string | null>(null)

  // const showModal = () => {
  //   setModalVisible(true)
  // }
  const changeMutation = useMutation({
    mutationFn: async ({ id, payload }: any) => {
      return await confirmEmail(id, payload)
    },
    onSuccess: resp => {
      if (resp) {
        setUpdatedEmail(resp?.email)
        setModalVisible(false)
        form.resetFields()
      }
    },
  })
  const alertModalData = {
    heading: 'Block email',
    message1: 'Are you sure you want to block email',
    message2: `${updatedEmail || data?.email}`,
    okText: 'Yes, block email',
    closeIcon: false,
  }
  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const menu = (
    <Menu>
      <Menu.Item key="email" onClick={() => setModalVisible(true)}>
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space
          className="text-color-red"
          onClick={() => {
            setAlertModalVisible(true)
          }}
        >
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const handleOk = (values: any) => {
    changeMutation.mutate({
      id: data?.id,
      payload: {
        newEmail: values.email,
        userType: 'organizer',
      },
    })
  }
  const handleCancel = () => {
    form.resetFields()
    setModalVisible(false)
  }
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button>
          </Dropdown>
        </div>
        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone?.number && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">{data?.phone?.number}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {(updatedEmail || data?.email) && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3">
                              {updatedEmail || data?.email}
                            </h4>{' '}
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* <Modal visible={modalVisible} footer={null} onCancel={handleCancel}>
          <div className="popup-header">
            <h2 className="ChangeText">Change email</h2>
            <img src={Cancel} alt="close button" onClick={handleCancel} />
          </div>
          <Form form={form}>
            <p className="currentEmail">
              Your current email: <b> {updatedEmail || data?.email}</b>
            </p>

            <div className="emailTxt mt-10">New email</div>
            <Form.Item
              className="mt-10"
              name="email"
              rules={[
                { required: true, message: 'Please enter your new email!' },
                { type: 'email', message: 'Please enter a valid email!' },
              ]}
            >
              <Input className="mt-10px" placeholder="Enter new email" />
            </Form.Item>

            <div className="emailTxt mt-20px">Confirm email</div>
            <Form.Item
              name="confirmEmail"
              dependencies={['email']}
              rules={[
                { required: true, message: 'Please confirm your new email!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('The two emails do not match!'),
                    )
                  },
                }),
              ]}
            >
              <Input className="mt-20px" placeholder="Confirm new email" />
            </Form.Item>
          </Form>
          <div className="flex justify-end w-full mt-20">
            <button
              className="prim-sm-btn"
              style={{
                background: '#EBEDF1',
                color: 'black',
                marginRight: '10px',
              }}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="prim-sm-btn"
              disabled={changeMutation.isLoading}
              onClick={handleOk}
            >
              {changeMutation.isLoading ? <Loader /> : 'Change email'}
            </button>
          </div>
        </Modal> */}
        <ChangeEmailModal
          visible={modalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          currentEmail={updatedEmail || data?.email}
          isLoading={changeMutation.isLoading}
          form={form}
        />
        {alertModalVisible && (
          <BlockEmailModal
            isModalVisible={alertModalVisible}
            setIsModalVisible={setAlertModalVisible}
            data={alertModalData}
          ></BlockEmailModal>
        )}
      </div>
    </Col>
  )
}

export default OrganizerContactInfo
