import React, { useState, useEffect } from 'react'
import { Row, Space, Tooltip, Table, Avatar } from 'antd'
import { history } from '../../../Redux/app-store'
import moment from 'moment'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import StatusDropdown from '../../../helper/StatusDropdown'
import {
  capitalizeFirstLetter,
  setPaginationObject,
} from '../../../helper/utility'

const MentorTable = ({
  setSelectedRows,
  activeData,
  refetchMentors,
  sortedInfo,
  hidCols,
  parmsData,
  modalVisible,
  status,
  setParms,
  changeStatus,
  deleteM,
}: any) => {
  let initPaginationInfo = {
    total: activeData.count,
    current: parmsData.page,
    sort: {
      field: 'firstname',
      order: 'asc',
    },
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }

  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)

  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: activeData.count,
    })
  }, [activeData.count])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  const newArray = activeData?.records?.map((item: any, index: any) => {
    return {
      key: item.id,
      firstname: item.firstName,
      mentorAvatar: item.photo?.post,
      email: item.email,
      phone: item?.phone ? `${item.phone.dialCode} ${item.phone.number}` : ' ',
      // phone: item?.phone?.dialCode + ' ' + item?.phone?.number,
      dob: moment(item.dob).format('MMM DD,YYYY'),
      totalhours: '4hr',
      traininglevel: '70',
      status: item.status,
      action: [
        <Space key={item.id} className="orgn-action-btn">
          <Tooltip title="Edit" color="blue">
            <img
              src={editblackPencil}
              width={20}
              alt="edit"
              onClick={() => {
                modalVisible(item)
              }}
              className="cursor_pointer"
            />
          </Tooltip>
          <Tooltip title="Delete" color="blue">
            <img
              src={deleteBlackIcon}
              alt="delete"
              onClick={e => {
                deleteM(item.id)
                refetchMentors()
              }}
              className="cursor_pointer"
            />
          </Tooltip>
        </Space>,
      ],
    }
  })
  const columnss = [
    {
      title: 'Name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text: any, record: any, index: any) => (
        <div
          className="cursor_pointer"
          onClick={() => {
            history.push({
              pathname: `/mentor-profile/${record.key}`,
              state: JSON.stringify(record.key),
            })
          }}
        >
          <Space size={8}>
            <Avatar size={24} src={record?.mentorAvatar}>
              {capitalizeFirstLetter(text)}
            </Avatar>
            {text}
          </Space>
        </div>
      ),
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'firstname' ? sortedInfo.order : null,
      hidden: true,
      className: hidCols.firstname === false ? 'display-none' : '',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: false,
      hidden: true,
      className: hidCols.email === false ? 'display-none' : '',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      hidden: true,
      sorter: false,
      className: hidCols.phone === false ? 'display-none' : '',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dob',
      key: 'dob',
      hidden: true,
      sorter: false,
      className: hidCols.dob === false ? 'display-none' : '',
    },
    {
      title: 'Total Hours',
      dataIndex: 'totalhours',
      key: 'totalhours',
      hidden: true,
      sorter: false,
      className: hidCols.totalhours === false ? 'display-none' : '',
    },
    {
      title: 'Training Level',
      dataIndex: 'traininglevel',
      key: 'traininglevel',
      hidden: true,
      sorter: false,
      className: hidCols.traininglevel === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      hidden: true,
      sorter: false,
      render: (text: String, record: any) => (
        <StatusDropdown
          id={record.key}
          name="mentor"
          status={status}
          changeFn={changeStatus}
          refetchData={refetchMentors}
        />
      ),
      className: hidCols.status === false ? 'display-none' : '',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      hidden: true,
      width: 120,
      sorter: false,
      className: hidCols.action === false ? 'display-none' : '',
    },
  ]

  const onTableChange = (...rest: any) => {
    const newObject: any = [...rest]
    let newPaginationInfo = setPaginationObject.apply(paginationInfo, newObject)
    setPaginationInfo(newPaginationInfo)
    getMentors(newPaginationInfo)
  }
  const getMentors = (newPaginationInfo: any) => {
    setParms({
      ...parmsData,
      page: newPaginationInfo.current,
      limit: 10,
      status: status,
    })
  }
  return (
    <div>
      <Row>
        <Table
          className="mentors-table organizations_table"
          rowSelection={rowSelection}
          style={{ width: '100%' }}
          columns={columnss}
          dataSource={newArray}
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
    </div>
  )
}

export default MentorTable
