import { Avatar, Col, Space, Button } from 'antd'
import editblackPencil from '../../../../../assets/Images/editblackPencil.svg'
import deleteRed from '../../../../../assets/Images/delete_red.svg'
import { history } from '../../../../../Redux/app-store'
import { useDispatch } from 'react-redux'
import actions from '../../../../../Redux/Family/action'
import { useMutation } from '@tanstack/react-query'
import {
  capitalizeFirstLetter,
  convertToLocal,
  getNameSignature,
  getUserName,
} from 'helper/utility'
import { Link } from 'react-router-dom'
interface PersonInterface {
  to: string
  id: string
  photo: string
  name: string
  relation: string
  bcase: string
}
const Person = ({ id, photo, name, relation, to, bcase }: PersonInterface) => {
  const dispatch = useDispatch()

  const deletButton = (cId: any) => {
    if (bcase === 'kid') {
      dispatch({
        type: actions.DELETE_KID,
        payload: cId,
      })
    }
  }
  return (
    <div className="mt-20">
      <Col span={24} className="d-flex justify-between">
        <Link to={{ pathname: `/${to}/${id}`, state: 'guardian' }}>
          <Space
            size={10}
            className="cursor_pointer"
            // onClick={() => {
            //   history.push({
            //     pathname: `/${to}/${id}`,
            //     state: 'guardian',
            //   })
            // }}
          >
            <Avatar size={40} src={photo}>
              {getNameSignature(name)}
            </Avatar>
            <div>
              <h4 className="b2">{name}</h4>
              <h5 className="b2 text-secondary">{relation}</h5>
            </div>
          </Space>
        </Link>
        {/* <Space size={10}>
          <Button className="cursor_pointer o-icon-btn" onClick={() => {}}>
            <img src={editblackPencil} width={20} alt="edit" />
          </Button>
          <Button
            className="cursor_pointer o-icon-btn"
            onClick={() => {
              deletButton(id);
            }}
          >
            <img src={deleteRed} alt="delete" />
          </Button>
        </Space> */}
      </Col>
    </div>
  )
}
export default Person
