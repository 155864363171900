import AppLayout from 'components/layout/layout'
import { Row, Col, Button, Tooltip, Space, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import downloadImg from 'assets/Images/Download.svg'
import { useState } from 'react'
import ExportInventory from './ExportInventory'
import FilePlus from '../../../assets/Images/FilePlus.svg'
import ImportInventoryData from './ImportInventoryData'
import { myStore } from 'state/inventoryStore'
type ItemType = {
  label: string
  key: string
}
const Inventory = ({ data }: any) => {
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const view = myStore(store => store.view)
  const changeView = myStore(store => store.changeView)
  const handleImportClick = () => {
    setIsImportModalVisible(true)
  }

  const handleImportModalClose = () => {
    setIsImportModalVisible(false)
  }

  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
        <Space className="mt-10" onClick={handleImportClick}>
          <img alt="importIcon" src={FilePlus} /> Import
        </Space>
      </Menu.Item>
    </Menu>
  )

  const items: ItemType[] = [
    {
      key: 'list',
      label: 'List',
    },
    {
      key: 'table',
      label: 'Table',
    },
  ]

  const handleMenuItemClick = (label: any) => {
    changeView(label.key)
  }
  return (
    <Row className="page-header" justify="space-between">
      <Col lg={{ span: 6 }} xs={{ span: 24 }}>
        <h1 className="table-head mb-0">Inventory </h1>
      </Col>
      <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
        <div className="right-search-block">
          <div
            className="table-action-btn new-organizations-export-btn"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            {localStorage.getItem('role') === 'admin' && (
              <Dropdown overlay={menu} placement="bottom" className="bg-grey ">
                <button className=" prim-sm-btn" style={{ color: 'black' }}>
                  More <DownOutlined />
                </button>
              </Dropdown>
            )}

            <Dropdown
              menu={{
                items,
                onClick: label => handleMenuItemClick(label),
              }}
              placement="bottom"
              className="mx-20px"
            >
              <button className="borderd-btn">
                {view} <DownOutlined />
              </button>
            </Dropdown>
            <Tooltip placement="bottom" title="Create">
              <Button className={'btn-new primary br-8'}>Add item</Button>
            </Tooltip>
          </div>
        </div>
      </Col>

      <ExportInventory
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={data?.records}
      />
      {isImportModalVisible && (
        <ImportInventoryData
          visible={isImportModalVisible}
          onClose={handleImportModalClose}
        />
      )}
    </Row>
  )
}
export default Inventory
