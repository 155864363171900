import { Button, Col, Dropdown, Menu, Modal, Row, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import active from "../assets/Images/activeGreen.png";
import pendingImg from "../assets/Images/pending.svg";
import partnerImg from "../assets/Images/partner.svg";
import organizerAction from "../Redux/Organizer/action";
import organizationAction from "../Redux/Organization/action";
import mentorAction from "../Redux/mentors/action";
import { useDispatch } from "react-redux";
import { useState } from "react";

const Statusmodal = ({
  isModalVisible,
  setIsModalVisible,
  name,
  selectedRows,
}: any) => {
  const dispatch = useDispatch();

  const [menuStatus, setMenuStatus] = useState(selectedRows[0].status);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMenuClick = (menuItem: any) => {
    setMenuStatus(menuItem.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={name === "organization" ? "approved" : "active"}>
        <img alt="img" src={active}></img> Active
      </Menu.Item>
      <Menu.Item key={name === "organization" ? "under_review" : "inactive"}>
        <img alt="img" src={pendingImg}></img> Pending
      </Menu.Item>
      {/* <Menu.Item key="partner">
        <img alt="img" src={partnerImg}></img> Partner
      </Menu.Item> */}
    </Menu>
  );

  const handleSubmit = () => {
    if (name === "organization") {
      selectedRows.map((row: any) => {
        dispatch({
          type: organizationAction.UPDATE_ORGANIZATIONS_STATUS,
          payload: {
            status: menuStatus,
            reason: "demo",
          },
          params: row.key,
        });
      });
    }
    if (name === "organizer") {
      selectedRows.map((row: any) => {
        dispatch({
          type: organizerAction.UPDATE_ORGANIZER_STATUS,
          payload: {
            status: menuStatus,
            reason: "demo",
          },
          id: row.key,
        });
      });
    }
    if (name === "mentor") {
      selectedRows.map((row: any) => {
        dispatch({
          type: mentorAction.UPDATE_MENTOR_STATUS,
          payload: {
            status: menuStatus,
            reason: "demo",
          },
          params: row.key,
        });
      });
    }
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        width={438}
        closable={false}
        className="set-status-modal"
      >
        <Row gutter={[0, 40]} justify="center">
          <Col className="status-modal-heading">
            <h3 className="b1">Set status</h3>
          </Col>
          <div className="w-100">
            <Dropdown overlay={menu}>
              <Button
                className="status_button"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  height: "38px",
                }}
              >
                <Space>
                  <img
                    alt="img"
                    src={
                      menuStatus === "active" || menuStatus === "approved"
                        ? active
                        : pendingImg
                    }
                  ></img>
                  {menuStatus}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
          <Col>
            <div>
              <Space size={30}>
                <Button
                  onClick={handleCancel}
                  className="gry-btn hl-50 btn-h-50 w-155 br-8 py-0"
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn hl-50 btn-h-50 w-155 br-8 py-0"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Statusmodal;
