/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  jobRequestData: [],
  filteredAvailableMentors: [],
  jobProposed: [],
  loading: false,
  totalCount: 0,
  mentorReject: false,
  approveRequest: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.FILTER_AVAILABLE_MENTORS: {
      return {
        ...state,
        filteredAvailableMentors: action.payload,
      };
    }

    case action.CREATE_MENTOR_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.CREATE_MENTOR_REQUEST_SUCCESS: {
      return {
        ...state,
        jobRequestData: [...state.jobRequestData, action.payload],
        loading: false,
      };
    }

    case actions.CREATE_MENTOR_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case action.MENTOR_REQUESTS: {
      return {
        ...state,
        jobRequestData: [],
        loading: true,
      };
    }
    case actions.MENTOR_REQUESTS_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        jobRequestData: action.payload.records,
        loading: false,
      };
    }

    case actions.MENTOR_REQUESTS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_JOB_PROPOSED: {
      return {
        ...state,
        jobProposed: action.payload,
        loading: false,
      };
    }
    case actions.ADD_JOB_REQUEST_DATA: {
      return {
        ...state,
        jobRequestData: action.payload,
      };
    }
    case actions.DELETE_JOB_REQUEST_DATA: {
      localStorage.setItem("jobRequestData", JSON.stringify(action.payload));
      return {
        ...state,
        jobRequestData: action.payload,
      };
    }

    case actions.SET_MENTOR_REQUEST_REJECT: {
      return {
        ...state,
        mentorReject: true,
      }
    }

    case actions.SET_MENTOR_REQUEST_RESET: {
      return{
        ...state,
        mentorReject: false,
      }
    }

    case actions.SET_MENTOR_REQUEST_RESET: {
      return{
        ...state,
        approveRequest: true,
      }
    }

    case actions.SET_OTHER_REQUEST_APPROVE: {
      return{
        ...state,
        approveRequest: true,
      }
    }

    case actions.OTHER_REQUEST_APPROVE_RESET: {
      return{
        ...state,
        approveRequest: false,
      }
    }

    default:
      return state;
  }
};
