import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Row, Tabs } from 'antd'
import AppLayout from '../../../../components/layout/layout'
import NewFamily from '../../../Modals/FamilyModal/NewFamily/NewFamily'
import MemberTab from './FamilyMembers/MemberTab'
import Events from './Events/Events'
// import Notes from './Notes/Notes'
import Notes from '../NotesComp/Notes'
import Organizations from './Organizations/Organizations'
import History from './History/History'
import { useQuery } from '@tanstack/react-query'
import { GetFamily } from '../../../../api/family'
import FamilyHeader from './FamilyHeader/FamilyHeader'
import Spinner from '../../../../components/Spinner'
import Payments from './Payments/Payments'
import Stripe from './Stripe/Stripe'
const { TabPane } = Tabs

const FamilyProfile = () => {
  const [showEditFamily, setShowEdit] = useState<boolean>(false)

  let { id }: any = useParams()
  const { isLoading, data, refetch } = useQuery(['getFamilyData'], () =>
    GetFamily(id),
  )
  return (
    <AppLayout id={id}>
      {isLoading ? (
        <div className="flex justify-center items-center h-full aligin-center">
          <Spinner />
        </div>
      ) : (
        <div className="bg-grey">
          <FamilyHeader
            FamilyDetails={data}
            setShowEdit={setShowEdit}
            refetch={refetch}
          />
          <Row>
            <Tabs
              defaultActiveKey="1"
              style={{ width: '100%', height: '100%' }}
              className="bg-white profile-tabs"
            >
              <TabPane tab="Members" key="1">
                <MemberTab
                  FamiliyData={data}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </TabPane>
              <TabPane tab="Organizations" key="2">
                <Organizations />
              </TabPane>
              <TabPane tab="Events" key="3">
                <Events familyId={id} />
              </TabPane>
              <TabPane tab="History" key="4">
                <History familyId={id} />
              </TabPane>
              <TabPane tab="Notes" key="5">
                {/* <Notes familyId={id} /> */}
                <Notes id={id} familyId={id} />
              </TabPane>
              <TabPane tab="Payments" key="6">
                <Payments familyId={id}></Payments>
              </TabPane>
              <TabPane tab="Stripe Payment" key="7">
                <Stripe familyId={id}></Stripe>
              </TabPane>
            </Tabs>
          </Row>
        </div>
      )}
      {showEditFamily && (
        <NewFamily
          familyData={data}
          refetchData={refetch}
          showModal={setShowEdit}
        />
      )}
    </AppLayout>
  )
}

export default FamilyProfile
