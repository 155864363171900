import activeImg from 'assets/Images/activeGreen.png'
import pendingImg from 'assets/Images/pending.svg'
import blocked from 'assets/Images/blocked.svg'
import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'

const StatusDropdown = ({ id, name, status, changeFn, refetchData }: any) => {
  const handleChange = (key: string) => {
    if (name === 'guardian') {
      changeFn.mutate({ params: id, payload: { status: key } })
    } else {
      changeFn.mutate({ params: id, payload: key })
      refetchData()
    }
  }
  const handleMenuClick = ({ key }: any) => {
    handleChange(key)
  }
  const active = name === 'organization' ? 'approved' : 'active'
  const pending =
    name === 'organization'
      ? 'under_review'
      : name === 'mentor' || name === 'organizer'
      ? 'inactive'
      : 'pending'

  const StatusMenu = [
    {
      key: active,
      label: (
        <div>
          <img alt="img" src={activeImg}></img> Active
        </div>
      ),
    },
    {
      key: pending,
      label: (
        <div>
          <img alt="img" src={pendingImg}></img> Pending
        </div>
      ),
    },
    {
      key: 'blocked',
      label: (
        <div>
          <div>
            <img alt="img" src={blocked}></img>Blocked
          </div>
          {/* {name === 'family' && (
            <div>
              <img alt="img" src={blocked}></img>Blocked
            </div>
          )} */}
        </div>
      ),
    },
  ]

  const imageSrc =
    status === 'active' || status === 'approved' || status === 'public'
      ? activeImg
      : status === 'blocked' || status === 'private' || status === 'archived'
      ? blocked
      : status === 'pending' ||
        status === 'inactive' ||
        status === 'under_review' ||
        status === 'draft'
      ? pendingImg
      : ''
  const text =
    status === 'pending' || status === 'under_review' || status === 'inactive'
      ? 'Pending'
      : status === 'draft'
      ? 'Draft'
      : status === 'active' || status === 'approved'
      ? 'Active'
      : status === 'public'
      ? 'Public'
      : status === 'blocked'
      ? 'Blocked'
      : status === 'private'
      ? 'Private'
      : status === 'archived'
      ? 'Archived'
      : ''
  return (
    <div>
      <Dropdown
        menu={{
          items: StatusMenu,
          onClick: handleMenuClick,
        }}
      >
        <Button className="status_button">
          <Space>
            <img alt="img" src={imageSrc}></img>
            {text}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  )
}

export default StatusDropdown
