import React, { useState } from "react";
import { Row, Switch } from "antd";
import { DownOutlined } from "@ant-design/icons";

const onChangetoggle = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};
const NotificationSetting = ({ match }: any) => {
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const [toggle6, setToggle6] = useState(false);
  const [toggle7, setToggle7] = useState(false);
  const [toggle8, setToggle8] = useState(false);
  const [toggle9, setToggle9] = useState(false);
  const [toggle10, setToggle10] = useState(false);
  const [toggle11, setToggle11] = useState(false);

  const triggerToggle1 = () => {
    setToggle1(!toggle1);
  };
  const triggerToggle2 = () => {
    setToggle2(!toggle2);
  };
  const triggerToggle3 = () => {
    setToggle3(!toggle3);
  };
  const triggerToggle4 = () => {
    setToggle4(!toggle4);
  };
  const triggerToggle5 = () => {
    setToggle5(!toggle5);
  };
  const triggerToggle6 = () => {
    setToggle6(!toggle6);
  };
  const triggerToggle7 = () => {
    setToggle7(!toggle7);
  };
  const triggerToggle8 = () => {
    setToggle8(!toggle8);
  };
  const triggerToggle9 = () => {
    setToggle9(!toggle9);
  };
  const triggerToggle10 = () => {
    setToggle10(!toggle10);
  };
  const triggerToggle11 = () => {
    setToggle11(!toggle11);
  };
  const triggerToggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <div style={{ paddingBottom: "32px" }}>
        <div className="parent-block-notify">
          <div style={{ paddingBottom: "16px" }}>
            <h3 className="b1">Mute notifications</h3>
          </div>
          <Row className="inner-toggle-swich" style={{ paddingBottom: "24px" }}>
            <div>
              <p className="f-regular">Mute notifications for Parent app</p>
            </div>
            <Switch
              onChange={onChangetoggle}
              className="small-custom-switch-btn"
            />
          </Row>
          <Row className="inner-toggle-swich" style={{ paddingBottom: "24px" }}>
            <div>
              <p className="f-regular">Mute notifications for Organizer app</p>
            </div>
            <Switch
              onChange={onChangetoggle}
              className="small-custom-switch-btn"
            />
          </Row>
          <Row className="inner-toggle-swich" style={{ paddingBottom: "24px" }}>
            <div>
              <p className="f-regular">Mute notifications for Mentor app</p>
            </div>
            <Switch
              onChange={onChangetoggle}
              className="small-custom-switch-btn"
            />
          </Row>
        </div>
        <div style={{ paddingBottom: "23px" }}>
          <h2>Notifications for Parents app</h2>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle()}>
              <Row>
                <div className="collspan">
                  <h4>Mentor session started</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle1()}>
              <Row>
                <div className="collspan">
                  <h4>Registration confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle1 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle2()}>
              <Row>
                <div className="collspan">
                  <h4>Check-in and check-out confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle2 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle3()}>
              <Row>
                <div className="collspan">
                  <h4>Chat message received</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle3 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle4()}>
              <Row>
                <div className="collspan">
                  <h4>Rate or review an event or service</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle4 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div style={{ paddingBottom: "23px" }}>
          <h2>Notifications for Parents app</h2>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle5()}>
              <Row>
                <div className="collspan">
                  <h4>Registration confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle5 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle6()}>
              <Row>
                <div className="collspan">
                  <h4>Check-in and check-out confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle6 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle7()}>
              <Row>
                <div className="collspan">
                  <h4>Chat message received</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle7 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div style={{ paddingBottom: "23px" }}>
          <h2>Notifications for Mentor app</h2>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle11()}>
              <Row>
                <div className="collspan">
                  <h4>Session started</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle11 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle8()}>
              <Row>
                <div className="collspan">
                  <h4>Registration confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle8 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle9()}>
              <Row>
                <div className="collspan">
                  <h4>Check-in and check-out confirmation</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle9 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
        <div className="parent-block-notify">
          <div>
            <div onClick={() => triggerToggle10()}>
              <Row>
                <div className="collspan">
                  <h4>Chat message received</h4>
                  <span className="label-gray">Push, emails, SMS</span>
                  <DownOutlined className="down-arrow" />
                </div>
              </Row>
            </div>
            {toggle10 && (
              <>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Push</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>

                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">Email</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
                <Row
                  className="inner-toggle-swich"
                  style={{ paddingBottom: "24px" }}
                >
                  <div>
                    <p className="f-regular">SMS</p>
                  </div>
                  <Switch
                    onChange={onChangetoggle}
                    className="small-custom-switch-btn"
                  />
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default NotificationSetting;
