import actions from "./actions";
const initState = {
  eventCategories: [],
  loading: false,

  allergiesList: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case actions.EVENT_CATEGORIES: {
      return {
        ...state,
      };
    }
    case actions.EVENT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        eventCategories: action.payload,
      };
    }
    case actions.EVENT_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.GET_ALLERGIES: {
      return {
        ...state,
        allergiesList: [],
        loading: true,
      };
    }
    case actions.GET_ALLERGIES_SUCCESS: {
      return {
        ...state,
        allergiesList: action.payload,
        loading: false,
      };
    }
    case actions.GET_ALLERGIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
