import GetAvatar from 'components/global/GetAvatar'
import { getUserName } from 'helper/utility'
import { Cost } from 'pages/Events/types'
import { useEffect, useState } from 'react'
import { Account } from '..'
import AddFamilyMember from './AddFamilyMember'
import FamilyCheckOut from './FamilyCheckOut'
import { connect } from 'react-redux'
import { StatusList } from '../../CheckInHeader'
import { notification } from 'antd'
interface Acc {
  details: Account[]
  cost: Cost
  checkInId: string
  email: string
  closeModel: () => void
  checkInMember: any
  setText: any
}
const AccountFound: React.FC<Acc> = ({
  details,
  cost,
  email,
  checkInId,
  closeModel,
  checkInMember,
  setText,
}) => {
  const [family, setFamily] = useState<Account[]>([])
  const [showCheckout, setCheckout] = useState(false)
  const [addMember, setMember] = useState(false)

  const handelClick = (data: Account) => {
    if (data.status === 'checked-in') {
      notification.success({
        message: 'This member is already checked-in',
      })
    } else {
      setFamily([...family, data])
      setCheckout(true)
    }
  }
  useEffect(() => {
    const memebers = details.filter(item => {
      return item.type === 'guardian'
    })
    setFamily([...family, ...memebers])
  }, [])

  useEffect(() => {
    if (checkInMember) {
      setMember(false)
      setCheckout(true)
      setFamily([...family, checkInMember])
    }
  }, [checkInMember])
  return (
    <div className="mt-20px h-25rem overflow-auto">
      {showCheckout == false && addMember == false && (
        <>
          {details.map(member => {
            return (
              <div
                key={member._id + member.dob}
                className="flex-center  mt-20px pointer justify-between"
                onClick={() => {
                  handelClick(member)
                }}
              >
                <div className="flex">
                  <GetAvatar person={member} size={46} />
                  <div className="ml-20px ">
                    <h2 className="text-16 ">{getUserName(member)}</h2>
                    <h4 className="text-14">{member.type}</h4>
                  </div>
                </div>
                {/* <div>
                  <h2 className="text-14">{member.status}</h2>
                </div> */}
                {member.status === 'checked-in' &&
                  StatusList.map(item => {
                    if (item.title === 'Checked in') {
                      return (
                        <div
                          style={{
                            backgroundColor: StatusList[2].color,
                          }}
                          className="flex flex-center justify-center px-15px py-10px radius-12 ml-10px w-35p"
                          key={'checkedout'}
                        >
                          <img src={StatusList[2].img} alt={item.value} />
                          <h4 className="ml-10px text-12 w-600">
                            {member.status === 'checked-in'
                              ? 'Checked In'
                              : null}
                          </h4>
                        </div>
                      )
                    }
                  })}
              </div>
            )
          })}
          <button
            className="w-full text-14 bordered-prim-btn text-center py-20px mt-40px mb-30px"
            onClick={() => {
              setMember(true)
              setText(true)
            }}
          >
            None of these
          </button>
        </>
      )}
      {addMember && (
        <AddFamilyMember
          familyId={family[0].familyId!}
          closeModel={() => {
            setMember(false)
          }}
        />
      )}
      {showCheckout && (
        <FamilyCheckOut
          name="guardian"
          setText={setText}
          cost={cost}
          family={family}
          id={checkInId}
          close={closeModel}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  checkInMember: state.session.checkInData,
})
export default connect(mapStateToProps)(AccountFound)
