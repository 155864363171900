import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Switch, Form, Select, Input } from 'antd'
import { CaretLeft } from '@phosphor-icons/react'
import './styles/manage-tickets.scss'
import TicketRow from './essentialComponents/TicketRow'
import { creationEventStore } from 'state/eventCreationStore'
import {
  useGetDiscountCode,
  useSortAdditionalTicket,
  useSortAdmissionTicket,
} from 'api/EventCreation/CreationStep4'
import ButtonSpinner from 'components/global/ButtonSpinner'
import Discounts from './essentialComponents/Discounts'
import { AdditionalTicketResp, TicketsResp } from 'types/eventStep4'

const { Option } = Select

interface ManageTicketsProps {
  handleNext: (data: any) => void
  handleBack: () => void
}

const ManageTickets: React.FC<ManageTicketsProps> = ({
  handleNext,
  handleBack,
}) => {
  const eventData = creationEventStore(store => store.eventData)
  const setEventData = creationEventStore(store => store.setEventData)
  const { _id } = eventData
  const [isRecurringPayment, setIsRecurringPayment] = useState<boolean>(false)
  const handleSwitchChange = (checked: boolean) => {
    setIsRecurringPayment(checked)
  }
  const { mutate: sortAdmissionTicket, isLoading: sortAdmissionTicketLoading } =
    useSortAdmissionTicket()
  const {
    mutate: sortAdditionalTicket,
    isLoading: sortAdditionalTicketLoading,
  } = useSortAdditionalTicket()
  const {
    data: discountCodesData,
    isLoading: discountCodeLoading,
    isFetching: discountCodeFetching,
    refetch: discountCodeRefetch,
  } = useGetDiscountCode(_id)

  const selectArr = [
    { id: 1, name: 'Weekly' },
    { id: 2, name: 'Daily' },
    { id: 3, name: 'Every thursday' },
  ]

  const handleNextClick = () => {
    sortAdmissionTicket(
      {
        eventId: _id,
        payload: {
          orders: eventData.admissionTickets.map(
            (ticket: TicketsResp, index: number) => ({
              ticketId: ticket._id,
              order: index + 1,
            }),
          ),
        },
      },
      {
        onSuccess: data => {
          setEventData(data)
          handleNext(data)
        },
      },
    )
    sortAdditionalTicket(
      {
        eventId: _id,
        payload: {
          orders: eventData.additionalOptions.map(
            (ticket: AdditionalTicketResp, index: number) => ({
              optionId: ticket._id,
              order: index + 1,
            }),
          ),
        },
      },
      {
        onSuccess: data => {
          setEventData(data)
          handleNext(data)
        },
      },
    )
  }

  useEffect(() => {
    discountCodeRefetch()
  }, [])

  return (
    <div className="step4-main-container">
      <div>
        <h3 className="text-24 w-600"> Step 4. Manage Tickets</h3>
      </div>

      <div className="mt-30px overflow-auto-y scroller overflow-hidden-x content-container">
        <div className="flex align-center justify-between">
          <p className="text-16 w-600">Recurring payment</p>
          <Switch onChange={handleSwitchChange} />
        </div>

        {isRecurringPayment && (
          <Row gutter={10} className="mt-20px">
            <Col>
              <label className="input-label text-12 w-600">Frequency</label>
            </Col>
            <Col>
              <Form.Item
                colon={false}
                className="form-control"
                name="frequency"
              >
                <Select
                  mode={'multiple'}
                  className="form-control-field"
                  placeholder="Select"
                >
                  {selectArr.map(item => {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        name={item?.name}
                        _id={item.id}
                      >
                        {item.name}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        <TicketRow type="admission" />
        <TicketRow type="dropin" />
        <TicketRow type="additional" />
        <TicketRow type="discount" />
        {discountCodeLoading || discountCodeFetching ? (
          <div className="w-full py-30px flex justify-center align-center">
            <ButtonSpinner size={30} color="#2ba7ca" />
          </div>
        ) : (
          <Discounts data={discountCodesData} />
        )}
      </div>

      <div className="mt-15px event-button-cont">
        <h5 className="flex gap-10px align-center" onClick={() => handleBack()}>
          <CaretLeft size={20} color="#000" />
          <span className="text-15 w-500">Back</span>
        </h5>
        <Button className="stepper-button" onClick={handleNextClick}>
          {sortAdmissionTicketLoading || sortAdditionalTicketLoading ? (
            <ButtonSpinner size={20} color="#000" />
          ) : (
            'Next'
          )}
        </Button>
      </div>
    </div>
  )
}

export default ManageTickets
