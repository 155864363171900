import { Checkbox, Dropdown, InputNumber, Radio, Slider } from 'antd'
import { useEffect, useRef, useState } from 'react'
import {
  CategoriesCheckBoxes,
  SharingStatusCheckBoxes,
  SortByCheckBoxes,
  TimeStatusCheckBoxes,
} from './filterArrays'
import map from '../../../assets/Images/MapPin.svg'
import { ArrowUp } from '@phosphor-icons/react'
import Down from '../../../helper/Icons/DownIcon'

const EventFilters = ({ setParms, parms }: any) => {
  const [checkedItems, setCheckedItems] = useState([])
  const [open, setOpen] = useState(0)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const [inputValue, setInputValue] = useState(5)
  const [location, setLocation] = useState<{
    latitude: number | null
    longitude: number | null
  }>({
    latitude: null,
    longitude: null,
  })
  const [sortBy, setSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('desc')
  const [sharingStauts, setSharingStauts] = useState('')
  const [timeStatus, setTimeStatus] = useState('')
  const getLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            latitude: Math.round(position.coords.latitude),
            longitude: Math.round(position.coords.longitude),
          })
        },
        function (error) {
          console.error('Error getting location:', error.message)
        },
      )
    } else {
      console.log('Geolocation is not available on this device.')
    }
  }

  const onChange = (newValue: any) => {
    setInputValue(newValue)
  }
  const clear = (item: string, setFn?: any) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }
  const TimeStatus = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={TimeStatusCheckBoxes}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          value={timeStatus}
          onChange={(val: any) => setTimeStatus(val.target.value)}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('timeStatus', setTimeStatus)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)

              timeStatus &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  timeStatus: timeStatus,
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const SharingStatus = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={SharingStatusCheckBoxes}
          className="dropdwn checkbox"
          value={sharingStauts}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onChange={(val: any) => setSharingStauts(val.target.value)}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('status', setSharingStauts)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sharingStauts &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  status: sharingStauts,
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const Categories = [
    {
      key: 'checkboxes',
      label: (
        <Checkbox.Group
          options={CategoriesCheckBoxes}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onChange={(val: any) => setCheckedItems(val)}
          value={checkedItems}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('categoryIds')
              setOpen(0)
              setCheckedItems([])
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              const data = checkedItems[0] && checkedItems

              data &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  categoryIds: data,
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const Distance = [
    {
      key: 'zipcode',
      label: (
        <div className="mx-15px mt-10px">
          <div className="flex justify-between align-center ">
            <h4 className="w-600 text-14">Zip code</h4>
            <input type="number" className="sm-inp" />
          </div>
          <div className="flex mt-20px mb-10px">
            <img src={map} alt="location" />
            <h4 className="loc ml-10px" onClick={getLocation}>
              {location.latitude && location.longitude
                ? location.latitude + ' ' + location.longitude
                : 'My current location'}
            </h4>
          </div>
        </div>
      ),
    },
    {
      key: 'distance',
      label: (
        <div className="mx-15px mt-10px">
          <div className="flex justify-between align-center ">
            <h4 className="w-600 text-14">How many miles</h4>
            <InputNumber
              min={1}
              max={28}
              value={inputValue}
              onChange={onChange}
              className="inp-n"
            />
          </div>
          <div className="mt-20px">
            <Slider
              min={5}
              max={100}
              onChange={onChange}
              value={typeof inputValue === 'number' ? inputValue : 0}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('distance')
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              location.latitude === null
                ? setParms((prevParms: any) => ({
                    ...prevParms,
                    distance: inputValue,
                    page: 1,
                  }))
                : setParms((prevParms: any) => ({
                    ...prevParms,
                    distance: inputValue,
                    lat: location.latitude,
                    lng: location.longitude,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={SortByCheckBoxes}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={(val: any) => setSortBy(val.target.value)}
          />
          <div className=" border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600 ">Sort Results</h2>
              <Radio.Group
                onChange={(val: any) => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                value={orderBy}
              >
                <Radio value={'asc'}>Ascending</Radio>
                <Radio value={'desc'}>Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('orderBy', setSortBy)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sortBy && orderBy
                ? setParms((prevParms: any) => ({
                    ...prevParms,
                    sortBy: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : orderBy
                ? setParms((prevParms: any) => ({
                    ...prevParms,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : setParms((prevParms: any) => ({
                    ...prevParms,
                    sortBy: sortBy,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]

  return (
    <div className="flex justify-between align-center  flex-wrap">
      <div
        className="flex align-center flex-wrap mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Filter by:</h2>
        <Dropdown
          menu={{
            items: TimeStatus,
          }}
          placement="bottomLeft"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 1}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 1 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(1)}
          >
            Time Status <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: SharingStatus,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 2}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(2)}
          >
            Sharing Status <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Categories,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 3}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(3)}
          >
            Category
            <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Distance,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px "
          open={open === 4}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 4 ? 'filter-btn-click ' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(4)}
          >
            Distance
            <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: SortBy,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click ' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(5)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            Creation Date
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}
export default EventFilters
