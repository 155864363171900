import JobsFilters from './JobsFilter'
import { Row, Col, Button, Space, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import downloadImg from 'assets/Images/Download.svg'
import { useState } from 'react'
import ExportJob from './ExportJob'
const JobsHeader = ({ fullData }) => {
  const [isExportModalVisible, setExportModalVisible] = useState(false)

  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <div className="flex justify-between align-center">
        <h1 className="w-600">Jobs</h1>
        <div>
          <Dropdown
            overlay={menu}
            placement="bottom"
            className="bg-grey mx-20px"
          >
            <button className=" prim-sm-btn" style={{ color: 'black' }}>
              More <DownOutlined />
            </button>
          </Dropdown>
          <button className="prim-btn">New Job</button>
        </div>
      </div>
      <JobsFilters />
      <ExportJob
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={fullData}
      />
    </>
  )
}
export default JobsHeader
