import { useQuery } from '@tanstack/react-query'
import { getParticipantCheckInOutHistory } from 'api/Events'
import Spinner from 'components/Spinner'
import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
import { convertToLocal, convertToUtc } from 'helper/utility'
import moment from 'moment'
import { Attendees, CheckInOutEvent, Event } from 'pages/Events/types'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HistoryCard from './Card'
interface ParticipantHistory {
  participant: Attendees
}
const CheckInOutHistory: React.FC<ParticipantHistory> = ({ participant }) => {
  const currentDate = moment().startOf('day')
  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)
  const location = useLocation()
  const event: any = location.state

  const { data, refetch, isLoading } = useQuery<CheckInOutEvent[]>(
    ['getParticpantCheckInOutStatus'],
    () =>
      getParticipantCheckInOutHistory(
        participant._id,
        convertToUtc(selectedDate),
        event._id,
      ),
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    refetch()
  }, [selectedDate])

  return (
    <div className="grid-55-45 my-30px">
      {isLoading ? (
        <div className="flex-center ">
          <Spinner />
        </div>
      ) : (
        <div className="pr-30px checkInoutHistory">
          {data && data?.length > 0 ? (
            data?.map(history => {
              return (
                <div key={history._id}>
                  <h2 className="text-14">
                    {history.checkInStatus == 'checked-in' &&
                    history.checkOutStatus !== 'checked-out'
                      ? convertToLocal(history.checkedInAt, 'DD MMMM, YYYY')
                      : history.checkOutStatus == 'checked-out' &&
                        convertToLocal(history.checkedOutAt, 'DD MMMM, YYYY')}
                  </h2>
                  <HistoryCard data={history} />
                </div>
              )
            })
          ) : (
            <div className="flex-center justify-center h-full">
              <h2 className="text-16">No data found</h2>
            </div>
          )}
        </div>
      )}
      <div className="calendar-customzation">
        <MultiDateSelectCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          dates={event?.datesOfEvent}
        />
      </div>
    </div>
  )
}
export default CheckInOutHistory
