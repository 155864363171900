import {
  Col,
  Row,
  Space,
  Typography,
  Avatar,
  Dropdown,
  Button,
  Divider,
  DatePicker,
} from "antd";
import user from "../../../../../assets/Images/User.svg";
import kid1 from "../../../../../assets/Images/kid1.png";
import kid2 from "../../../../../assets/Images/kid2.png";
import kid3 from "../../../../../assets/Images/kid3.png";
import kid4 from "../../../../../assets/Images/kid4.png";
import kid5 from "../../../../../assets/Images/kid5.png";
import CalendarBlank from "../../../../../assets/Images/CalendarBlank.svg";
import soccer from "../../../../../assets/Images/soccer_img.svg";
import DonutChart from "../../../../../helper/Charts/PieChart/DonutChart";
import moment from "moment";

const { Text, Title } = Typography;

const KidActivity = ({}: any) => {
  const { RangePicker } = DatePicker;

  return (
    <Row
      gutter={10}
      style={{ paddingTop: "10px" }}
      className="bg-grey container padding-top-10"
    >
      <Col lg={12} span={24}>
        <div className="profile_container">
          <Row>
            <Col span={24}>
              <Space direction="vertical" size="middle" className="w-100 mb-20">
                <Row justify="space-between" align="middle">
                  <Col span={14}>
                    <Title level={4} style={{ marginBottom: "0px" }}>
                      Media
                    </Title>
                  </Col>
                  <Col span={10} className="text-right">
                    <Button className="outlined-button br-8 register_child_button-events mr-0">
                      Add media
                    </Button>
                  </Col>
                </Row>
              </Space>
            </Col>
            <Col span={24}>
              <div>
                <img src={kid1} />
                <img src={kid2} />
                <img src={kid3} />
                <img src={kid4} />
                <img src={kid5} />
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col lg={12} span={24}>
        <div className="profile_container">
          <Row justify="space-between">
            <Col>
              <h5 className="activity-heading">
                <b className="b1">Activity levels</b>
              </h5>
            </Col>
            <Col className="d-flex align-items-center">
              <Space size={18}>
                <RangePicker />
                <h5 className="text-primary">
                  <b className="b1">View total activity</b>
                </h5>
              </Space>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div
                style={{ height: "293px" }}
                className="w-100 activity-levels-graph"
              >
                <DonutChart />
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={24}>
              <div className="profile_container">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row justify="space-between" className="d-flex">
                    <Col span={12}>
                      <Title level={5}>Rewards</Title>
                      <Space className="mt-30" size={30}>
                        <Avatar src={soccer}></Avatar>
                        <Avatar src={soccer}></Avatar>
                        <Avatar src={soccer}></Avatar>
                        <Avatar src={soccer}></Avatar>
                        <Avatar src={soccer}></Avatar>
                      </Space>
                    </Col>
                    <Col span={12} className="text-right">
                      <Button
                        className="outlined-button br-8"
                        style={{
                          color: "#2BA7CA",
                          fontWeight: 500,
                          border: "1px solid #2BA7CA",
                        }}
                      >
                        Give a reward
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default KidActivity;
