import React, { useEffect, useState } from 'react'
import { message, Modal } from 'antd'
import { Layout } from 'antd'
import { Steps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import CreatePassword from './Screens/CreatePassword'
import GeneralDetail from './Screens/GeneralDetail'
import actions from '../../../Redux/Organizer/action'
import AccessLevel from './Screens/AssignOrganization/AccessLevel'
import AssignOrganization from './Screens/AssignOrganization/AssignOrganization'
import { useMutation } from '@tanstack/react-query'
import { assignOrganization } from '../../../api/Organizations'

const { Step } = Steps

const CreateOrganizerModal = ({
  modalVisible,
  currentOrganizer,
  setModal,
  isChangingPassword,
  onClose,
}: any) => {
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentOrganizer) {
      if (isChangingPassword) {
        setCurrent(1)
      } else {
        setCurrent(0)
      }
    }
  }, [])
  useEffect(() => {
    if (currentOrganizer) {
      setData(currentOrganizer)
    }
  }, [currentOrganizer])

  const updatedOrganizer = useSelector(
    (state: any) => state.organizer.currentOrganizer,
  )

  useEffect(() => {
    if (updatedOrganizer) {
      setData(prevData => ({
        ...prevData,
        ...updatedOrganizer,
      }))
    }
  }, [updatedOrganizer])

  const next = (childData: any) => {
    if (!currentOrganizer && current === 1) {
      onSubmit(childData)
      setCurrent(current + 1)
    } else if (currentOrganizer && current === 0) {
      onSubmit(childData)
      //setCurrent(current + 2);
      handleOk()
    } else {
      setCurrent(current + 1)
    }
    setData(childData)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleOk = () => {
    setModal(false)
  }

  const handleCancel = () => {
    setModal(false)
  }
  const steps = {
    create: [
      {
        title: 'Personal Details',
      },
      {
        title: 'Create Password',
      },
      {
        title: 'Access level',
      },
      {
        title: 'ASSIGN TO ORGANIZATION',
      },
    ],
    edit: [
      {
        title: 'Personal Details',
      },
    ],
  }
  const AccessLevelMutation = useMutation({
    mutationFn: async (organizations: any) => {
      return await assignOrganization(organizations)
    },
  })

  const handleAssignAccess = (accessOrganizer: any) => {
    AccessLevelMutation.mutate({
      organizations: [
        {
          organizationId: accessOrganizer?.id,
          designation: accessOrganizer?.designation,
          accessLevel: data?.accessLevel,
        },
      ],
    })

    setModal(false)
  }

  const onSubmit = (organizer: any) => {
    if (currentOrganizer) {
      dispatch({
        type: actions.UPDATE_ORGANIZER,
        payload: organizer,
        params: currentOrganizer.id,
      })
    } else {
      dispatch({
        type: actions.ADD_ORGANIZER,
        payload: organizer,
      })
    }
    message.success('completed')
    onClose()
  }
  const handlePasswordChange = (password: string) => {
    dispatch({
      type: actions.CHANGE_ORGANIZER_PASSWORD,
      payload: {
        password: password,
        firebaseId: currentOrganizer.firebaseId,
        organizerId: currentOrganizer?._id,
      },
    })
    dispatch({
      type: actions.CLOSE_ORGANIZER_CHANGE_PASSWORD,
    })
  }

  return (
    <Layout>
      <Modal
        className="organization_model"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>
            {isChangingPassword
              ? 'Change Password'
              : currentOrganizer
              ? 'Edit Organizer'
              : 'Add Organizer'}{' '}
          </h4>
          {!isChangingPassword && (
            <Steps current={current}>
              {steps[currentOrganizer ? 'edit' : 'create'].map(item => (
                <Step key={item.title} className="stepper" title={item.title} />
              ))}
            </Steps>
          )}
        </div>
        <div>
          {current === 0 && (
            <GeneralDetail
              handleNext={next}
              data={data}
              currentOrganizer={currentOrganizer}
              setCurrent={setCurrent}
            />
          )}
          {current === 1 && (
            <CreatePassword
              handleNext={next}
              handleBack={prev}
              data={data}
              isChangingPassword={isChangingPassword}
              currentOrganizer={currentOrganizer}
              onPasswordChange={handlePasswordChange}
            />
          )}

          {current === 2 && (
            <AccessLevel data={data} handleBack={prev} handleNext={next} />
          )}
          {current === 3 && (
            <AssignOrganization
              data={data}
              handleBack={prev}
              handleSubmit={handleAssignAccess}
            />
          )}
        </div>
      </Modal>
    </Layout>
  )
}
export default CreateOrganizerModal
