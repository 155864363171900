// import { Row, Col, Tooltip, Button, List } from 'antd'
// import React from 'react'
// import StripIcon from './../../../../../assets/Images/stripe.svg'
// import delectIcon from './../../../../../assets/Images/Trash.svg'
// import { GetStripeCustomer } from 'api/family'
// import { useQuery } from '@tanstack/react-query'
// export const ExistingCustomer = familyId => {
//   const { isLoading, data, refetch } = useQuery(
//     ['GetStripeCustomer', familyId?.familyId],
//     () => GetStripeCustomer(familyId?.familyId),
//   )
//   console.log(data, 'Stripe')
//   const customers = Array(10).fill({ email: 'example@gmail.com' })

//   return (
//     <>
//       <Row className="page-header" justify="space-between">
//         <Col lg={{ span: 6 }} xs={{ span: 24 }}>
//           <h1 className="table-head mb-0">Existing customer ID </h1>
//         </Col>
//         <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
//           <div className="right-search-block">
//             <div
//               className="table-action-btn new-organizations-export-btn"
//               style={{
//                 display: 'flex',
//                 justifyContent: 'flex-end',
//                 width: '100%',
//               }}
//             >
//               {/* <Tooltip placement="bottom" title="Add">
//                 <Button className={'btn-new-activity br-8'}>
//                   Add Customer ID
//                 </Button>
//               </Tooltip> */}
//             </div>
//           </div>
//         </Col>
//       </Row>

//       <Row className="page-header">
//         <Col>
//           <List
//             itemLayout="horizontal"
//             dataSource={customers}
//             renderItem={item => (
//               <List.Item
//                 style={{ borderBottom: '1px solid #EBEDF1' }}
//                 actions={[
//                   // Use the delectIcon image inside an <img> tag
//                   <Button type="text" danger>
//                     <img
//                       src={delectIcon}
//                       alt="Delete"
//                       style={{ width: 20, height: 20 }}
//                     />
//                   </Button>,
//                 ]}
//               >
//                 <List.Item.Meta
//                   avatar={<img src={StripIcon} alt="Stripe Icon" />}
//                   title={<div>{data?.stripeCustomers.email}</div>}
//                 />
//               </List.Item>
//             )}
//           />
//         </Col>
//       </Row>
//     </>
//   )
// }

import { Row, Col, Button, List } from 'antd'
import React from 'react'
import StripIcon from './../../../../../assets/Images/stripe.svg'
import delectIcon from './../../../../../assets/Images/Trash.svg'
import { GetStripeCustomer, removeCustomer } from 'api/family'
import { useMutation, useQuery } from '@tanstack/react-query'

interface StripeCustomer {
  customerId: string
  email: string
}

interface QueryResult {
  stripeCustomers: StripeCustomer[]
}

export const ExistingCustomer = ({ familyId }: { familyId: string }) => {
  const { isLoading, data, refetch } = useQuery<QueryResult>(
    ['GetStripeCustomer', familyId],
    () => GetStripeCustomer(familyId),
  )
  const changeMutation = useMutation({
    mutationFn: async ({ familyId, customerId }: any) => {
      return await removeCustomer(familyId, customerId)
    },
    onSuccess: resp => {
      if (resp) {
        refetch()
      }
    },
  })
  const handleRemove = customerId => {
    changeMutation.mutate({
      familyId,
      customerId,
    })
  }
  // If data is not available, show loading or empty state
  if (isLoading) return <p>Loading...</p>
  if (!data || !data.stripeCustomers?.length) return null

  return (
    <>
      <Row className="page-header" justify="space-between">
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <h1 className="table-head mb-0">Existing customer ID</h1>
        </Col>
        <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
          <div className="right-search-block">
            <div
              className="table-action-btn new-organizations-export-btn"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {/* Placeholder for Add Customer button, if needed */}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="page-header">
        <Col>
          <List
            itemLayout="horizontal"
            dataSource={data.stripeCustomers}
            renderItem={(customer: StripeCustomer) => (
              <List.Item
                style={{ borderBottom: '1px solid #EBEDF1' }}
                actions={[
                  <Button type="text" danger>
                    <img
                      src={delectIcon}
                      alt="Delete"
                      onClick={() => {
                        handleRemove(customer.customerId)
                      }}
                      style={{ width: 20, height: 20 }}
                    />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<img src={StripIcon} alt="Stripe Icon" />}
                  title={
                    <div>
                      <Row gutter={16}>
                        <Col span={12}>
                          <strong>Email:</strong> {customer.email}
                        </Col>
                        <Col span={12}>
                          <strong>Customer ID:</strong> {customer.customerId}
                        </Col>
                      </Row>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}
