import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './action'
import { axiosGet, axiosPost, signupaxiosPost } from '../axiosHelper'
import { push } from 'connected-react-router'
import { getToken, clearToken } from '../../helper/utility'
import { authentication, app } from '../../Firebase'
import 'firebase/compat/auth'
import { message } from 'antd'
import failureAction from '../Failure/action'
import { myStore } from 'state/roleStore'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
} from 'firebase/auth'
import { failureMessage } from 'api/global'
const provider = new GoogleAuthProvider()
const Fbprovider = new FacebookAuthProvider()
const Appleprovider = new OAuthProvider('apple.com')
// const setRole = myStore(store => store.setRole)
/**
 * Call to login.
 *
 */

export function* signUpRequest({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create`)
    if (data) {
      yield localStorage.setItem('user', JSON.stringify(data))
      yield put(actions.loginSuccess(data))
      yield put(push('/dashboard'))
    } else {
      throw new Error('Invalid credentials provided.')
    }
  } catch (error) {
    yield put(actions.loginFailure(error.message, error.data || {}))
  }
}

export function* loginRequest({ payload }) {
  try {
    yield put(failureAction.clearEroorData())

    const result = yield call(
      signInWithEmailAndPassword,
      authentication,
      payload.email,
      payload.password,
    )

    const data = result
    const userAttributes = JSON.parse(
      data?.user?.reloadUserInfo?.customAttributes,
    )
    const { isAdmin, isOrganizer } = userAttributes

    if (isAdmin || isOrganizer) {
      yield localStorage.setItem('user', JSON.stringify(result))
      const adminData = yield call(axiosGet, 'v1/admins/me')
      myStore.setState({ role: adminData?.data?.role })
      myStore.setState({
        organizationId: adminData?.data?.primaryOrganizationId,
      })

      yield localStorage.setItem('role', adminData?.data?.role)
      yield localStorage.setItem('adminId', adminData.data._id)
      yield localStorage.setItem(
        'organizationId',
        adminData?.data?.primaryOrganizationId,
      )
      yield put(actions.loginSuccess(result.user))
      yield put(message.success('Welcome'))
      yield put(push('/dashboard'))
    } else {
      // failureMessage('You are not allowed to login')
      const errorMessage = 'You are not allowed to login'
      yield put(actions.loginFailure(errorMessage))
      failureMessage(errorMessage)
    }
  } catch (error) {
    yield put(actions.loginFailure(error.message, error.data || {}))
    // message.error(error.message)
    // failureMessage(error?.message)
    const firebaseError = 'Firebase: Error email not found'
    failureMessage(firebaseError)
  }
}

// export function* meRequest() {
//   try {
//     const adminDate = yield axiosGet('v1/admins/me')
//     yield put(actions.loginSuccess(adminDate))
//     yield localStorage.setItem('role', adminDate?.data?.role)
//     yield localStorage.setItem('adminId', adminDate.data._id)
//   } catch (error) {
//     yield put(actions.getMeFailure(error.data || error.message || {}))
//   }
// }

export function* signInWithGoogle() {
  try {
    const result = yield call(signInWithPopup, authentication, provider)
    console.log('result', result)
    yield localStorage.setItem('user', JSON.stringify(result.user))
    yield localStorage.setItem(
      'auth_token',
      JSON.stringify(result._tokenResponse.idToken),
    )
    yield put(actions.loginSuccess(result.user))
    yield put(message.success('welcome'))
    yield put(push('/dashboard'))
  } catch (error) {
    yield put(actions.loginFailure(error.message, error.data || {}))
    message.error(error.message)
  }
}

export function* signInWithFacebook() {
  try {
    const result = yield call(signInWithPopup, authentication, Fbprovider)
    console.log('result', result)
    yield localStorage.setItem('user', JSON.stringify(result.user))
    yield localStorage.setItem(
      'auth_token',
      JSON.stringify(result._tokenResponse.idToken),
    )
    yield put(actions.loginSuccess(result.user))
    yield put(message.success('welcome'))
    yield put(push('/dashboard'))
  } catch (error) {
    yield put(actions.loginFailure(error.message, error.data || {}))
    message.error(error.message)
  }
}

export function* signInWithApple() {
  try {
    const result = yield call(signInWithPopup, authentication, Appleprovider)
    console.log('result', result)
    yield localStorage.setItem('user', JSON.stringify(result.user))
    yield localStorage.setItem(
      'auth_token',
      JSON.stringify(result._tokenResponse.idToken),
    )
    yield put(actions.loginSuccess(result.user))
    yield put(message.success('welcome'))
    yield put(push('/dashboard'))
  } catch (error) {
    yield put(actions.loginFailure(error.message, error.data || {}))
    message.error(error.message)
  }
}

/**
 * Call to log user out.
 *
 */
export function* logout() {
  try {
    clearToken()
    yield put(actions.logoutSuccess())
    yield myStore.setState({ role: null })
    yield put(failureAction.clearEroorData())
    yield put(push('/'))
  } catch (error) {
    yield put(actions.logoutError())
  }
}

/**
 * check if authenticated user access.
 *
 */

export function* checkAuthorization() {
  const token = getToken().get('authToken')
  // const token = getToken().get('auth_token')
  const user = getToken().get('user')
  if (token && user) {
    yield put(actions.loginSuccess(user.user, token))
  } else {
    clearToken()
    yield put(actions.loginFailure('Failure'))
    yield put(push('/'))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    takeEvery(actions.LOGIN_REQUEST, loginRequest),
    takeEvery(actions.SIGNUP_REQUEST, signUpRequest),
    takeEvery(actions.GOOGLE_SIGNIN, signInWithGoogle),
    takeEvery(actions.FACEBOOK_SIGNIN, signInWithFacebook),
    takeEvery(actions.APPLE_SIGNIN, signInWithApple),
    takeEvery(actions.LOGOUT_REQUEST, logout),
    // takeEvery(actions.GET_ME_REQUEST, meRequest),
  ])
}
