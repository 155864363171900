import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Radio,
  Row,
  Select,
  Avatar,
  Form,
  Input,
  TimePicker,
  DatePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, connect } from "react-redux";
import actions from "../../../../../Redux/Family/action";
import GreenCircle from "../../../../../assets/Images/GreenCircle.svg";
import sessionActions from "../../../../../Redux/Session/action";
import NoteDetails from "./NoteDetails";

const { Option } = Select;

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(
    <Option key={i.toString(36) + i}>
      {<Avatar size={18} src={GreenCircle}></Avatar>} {"Allen Joe"}
    </Option>
  );
}

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const NoteForm = ({ contractRoomId, familyData, handleModalVisible }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [value, setValue] = useState("comment");
  const [noteType, setNoteType] = useState(value);
  const [modalVisible, setModalVisible] = useState(false);
  const formSchema = {
    type: "comment",
    method: "none",
    taggedPeople: [],
    description: "",
    photoId: "",
    videoId: "",
    audioId: "",
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    dispatch({
      type: actions.GET_FAMILIES,
    });
  }, []);

  const onFinish = (values: any) => {
    console.log("values:", values);
    let dataValues = { ...values };
    dataValues.contractRoomId = contractRoomId ? contractRoomId : ""; 
    dataValues.incident = {
      "incidentName": values?.incidentName,
      "incidentDate": values?.incidentDate,
      "incidentTime": values?.incidentTime,
      "incidentEventOccurAt": values?.incidentEventOccurAt,
      "incidentType": values?.incidentType,
      "incidentPartiesInvolved": values?.incidentPartiesInvolved,
      "incidentDescription": values?.description,
      "incidentNotifiedAgencies": values?.incidentNotifiedAgencies,
      "incidentAddress": {
          country: "India",
          street: "Shaniwar Peth",
          city: "Pune",
          zip: "411030",
          apartment: "30",
          state: "Maharashtra",
          coordinates: {
            latitude: 18.52121385376673,
            longitude: 73.85489755554198,
          },
    }
  }
    dispatch({
      type: sessionActions.ADD_NOTES_DATA,
      payload: dataValues,
    });
    // handleModalVisible(false);
    // setModalVisible(true);
    // onSubmit({
    //   ...values
    // });
  };
  const onChange = (e: any) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
    setNoteType(e.target.value);
  };
  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row>
          <Col>
            <b>Note Type</b>
          </Col>
          <Col className="mt-10">
            <Form.Item name="type">
              <Radio.Group name="radiogroup" onChange={onChange} value={value}>
                <Radio className="radio" value={"comment"} checked>
                  Comment
                </Radio>
                <Radio className="radio" value={"tips"}>
                  Tips
                </Radio>
                <Radio className="radio" value={"incident"}>
                  Incident
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col className="mt-10">
            <b>Method</b>
          </Col>
          <Col className="mt-10">
            <Form.Item name="method">
              <Radio.Group name="radiogroup" defaultValue={"None"}>
                <Radio className="radio" value={"none"} checked>
                  None
                </Radio>
                <Radio className="radio" value={"text"}>
                  Text
                </Radio>
                <Radio className="radio" value={"emails"}>
                  Emails
                </Radio>
                <Radio className="radio" value={"phoneCalls"}>
                  Phone calls
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {noteType === "incident" ? (
          <>
            <Row>
              <Col className="mt-10">
                <label className="input-label ">
                  <b>Name of Incident</b>
                </label>
              </Col>
              <Col>
                <Form.Item
                  name="incidentName"
                  rules={[
                    { required: true, message: "Incident name is required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <label htmlFor="" className="input-label required-input">
                  <b>Date and Time of Incident</b>
                </label>
              </Col>
              <Col className="mt-10">
                <Form.Item
                  name="incidentDate"
                  rules={[
                    { required: true, message: "Incident date is required" },
                  ]}
                >
                  <DatePicker
                    className="date-picker"
                    placeholder="Date of Incident"
                    name="date"
                  />
                </Form.Item>
              </Col>
              <Col className="mt-10">
                <Form.Item
                  name="incidentTime"
                  rules={[
                    { required: true, message: "Incident time is required" },
                  ]}
                >
                  <TimePicker
                    className="date-picker"
                    placeholder="Time of Incident"
                    name="time"
                  ></TimePicker>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Which event did this occur at?</b>
              </Col>
              <Col>
                <Form.Item name="incidentEventOccurAt">
                  <Select
                    className="mt-10"
                    mode="tags"
                    style={{ width: "520px", height: "51px" }}
                    placeholder="Select"
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Incident Type</b>
              </Col>
              <Col>
                <Form.Item
                  name="incidentType"
                  rules={[
                    { required: true, message: "Incident type is required" },
                  ]}
                >
                  <Select
                    className="mt-10"
                    mode="tags"
                    style={{ width: "520px", height: "51px" }}
                    placeholder="Select"
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <label className="input-label ">
                  <b>Parties Invlved</b>
                </label>
              </Col>
              <Col>
                <Form.Item name="incidentPartiesInvolved">
                  <Input placeholder="List names and ages of anyone involved" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Description of Incident</b>
              </Col>
              <Col>
                <Form.Item name="description">
                  <TextArea
                    rows={4}
                    style={{ width: "520px" }}
                    placeholder="Please provide a full and detailed description of the events that unfolded. This includes events leading up to incidents, who said what to who when, how coaches or party responsible handled the situation. Please be as precise as possible"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Incident Location</b>
              </Col>
              <Col>
                <Form.Item name="incidentAddress">
                  <Select
                    className="mt-10"
                    mode="tags"
                    style={{ width: "520px", height: "51px" }}
                    placeholder="Select or enter name"
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Agencies Notified</b>
              </Col>
              <Col>
                <Form.Item name="incidentNotifiedAgencies">
                  <Select
                    className="mt-10"
                    mode="tags"
                    style={{ width: "520px", height: "51px" }}
                    placeholder="Select or enter name"
                    onChange={handleChange}
                  >
                    {children}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <label className="input-label ">
                  <b>Any other relevent info?</b>
                </label>
              </Col>
              <Col>
                <Form.Item
                  name="incidentOtherInfo"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Type NA if not applicable" />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col className="mt-10">
                <b>Tag people</b>
              </Col>
              <Col>
                <Form.Item name="taggedPeople">
                  <Select
                    className="mt-10"
                    mode="tags"
                    style={{ height: "51px" }}
                    placeholder="Select or enter name"
                    onChange={handleChange}
                  >
                    {/* {children} */}
                    {familyData.length > 0 &&
                      familyData.map((item: any, index: any) => (
                        <Option value={item._id} key={item._id}>
                          {<Avatar size={18} src={GreenCircle}></Avatar>}{" "}
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <b>Note</b>
              </Col>
              <Col>
                <Form.Item name="description">
                  <TextArea rows={4} placeholder="Enter a note here" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row justify="end">
          <Button
            className="note-add bg-text-secondary br-8 mt-30"
            size="large"
            htmlType="submit"
          >
            Create
          </Button>
        </Row>
      </Form>
      {modalVisible && (
        <NoteDetails
          isModalVisible={modalVisible}
          setModalVisible={setModalVisible}
          handleModalVisible={handleModalVisible}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  familyData: state.family.familyData,
});
export default connect(mapStateToProps)(NoteForm);
