export const personalityTrait = (
  <div>
    <p className="text-center text-white-color">
      Personality traits reflect a person's characteristic patterns of thoughts,
      feeling, and behaviors. <br></br>Examples of personality traits are
    </p>
    <ul className="text-center text-white-color">
      <li>Active</li>
      <li>Affectionate</li>
      <li>Funny</li>
      <li>Rough</li>
      <li>Talkative</li>
      <li>Rowdy</li>
      <li>Smart</li>
      <li>Fidgety</li>
      <li>Shy</li>
      <li>Impatient</li>
    </ul>
  </div>
)
export const sensitiveTopic = (
  <div>
    <p className="text-center text-white-color">
      A sensitive topic is any matter that will make your child feel
      embarrassed, uncomfortable, irritated, or unresponsive if discussed or
      brought up randomly.<br></br>Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Hard school subject</li>
      <li>Family issues</li>
      <li>Medical condition</li>
      <li>Past bullying</li>
      <li>etc</li>
    </ul>
  </div>
)
export const favoriteSubj = (
  <div>
    <p className="text-center text-white-color">
      A favorite subject is either a school subject or a hobby done outside of
      school that your child thoroughly enjoys and looks forward to<br></br>
      Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Math</li>
      <li>Science</li>
      <li>PE</li>
      <li>Reading</li>
      <li>Biking</li>
      <li>Arts</li>
    </ul>
  </div>
)
export const dislikeSubj = (
  <div>
    <p className="text-center text-white-color">
      A disliked subject is either a school subject or outside activity that
      your child absolutely dreads and avoids<br></br>Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Math</li>
      <li>Science</li>
      <li>PE</li>
      <li>Reading</li>
      <li>Biking</li>
      <li>Arts</li>
    </ul>
  </div>
)
export const strengthList = (
  <div>
    <p className="text-center text-white-color">
      A strength is something your child is excellent in, whether it be a
      natural talent or learned skill. Could be anything physical, cognitive,
      emotional, social, or spiritual<br></br>Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Singing</li>
      <li>Specific Sport </li>
      <li>Drawing</li>
      <li>Hands-on activities</li>
      <li>Confident</li>
      <li>Organized</li>
      <li>Disciplined</li>
      <li>Patient </li>
      <li>Determined</li>
    </ul>
  </div>
)
export const listAreaImprovement = (
  <div>
    <p className="text-center text-white-color">
      An area of improvement can be anything that you or your child think you
      can work on to get better at. Could be anything physical, cognitive,
      emotional, social, or spiritual<br></br>Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Self control</li>
      <li>Focus</li>
      <li>Specific Sport</li>
      <li>Confidence</li>
      <li>Self Esteem </li>
      <li>Science</li>
      <li>Anger</li>
      <li> Large groups</li>
    </ul>
  </div>
)
export const howComfort = (
  <div>
    <p className="text-center text-white-color">
      Please help us help your child! What can we do to help walk your child
      through their different emotions?<br></br>Examples:
    </p>
    <ul className="text-center text-white-color">
      <li>Hug</li>
      <li>Call Mom/Dad</li>
      <li>Talk it out</li>
      <li>Quiet/Alone</li>
      <li>Time </li>
      <li>Teddy Bear</li>
      <li>Snack</li>
    </ul>
  </div>
)
export const ChildsReading = (
  <div>
    <p className="text-center text-white-color">
      Indicate your child's reading ability by evaluating comprehension (ability
      to understand a topic after reading about it), fluency (how well and clear
      they are able to read), and independence (if they need a lot of help while
      reading or can manage on their own).
    </p>
  </div>
)
