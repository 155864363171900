import { all, put, takeLatest } from 'redux-saga/effects'
import actions from './action'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../axiosHelper'
import failureAction from '../Failure/action'

export function* getEventVenueRequest({ payload }) {
  try {
    const organizationId = payload.organizationId,
      longitude = payload.longitude,
      latitude = payload.latitude
    const { data } = yield axiosGet(
      `v1/event-venues/venues?organizationId=${organizationId}&longitude=${longitude}&latitude=${latitude}`,
    )
    yield put(actions.getEventVenueRequestSuccess(data))
  } catch (error) {
    yield put(actions.getEventVenueRequestFailure())
    yield put(failureAction.failure(error))
  }
}

export function* addEventVenueRequest({ payload }) {
  try {
    const organizationId = payload.organizationId
    const { data } = yield axiosPost(
      payload,
      `v1/event-venues/${organizationId}/event-venue`,
    )

    yield put(
      actions.getEventVenueRequest({
        organizationId: organizationId,
        longitude: payload.coordinates.longitude,
        latitude: payload.coordinates.latitude,
      }),
    )
    yield put(actions.createEventVenueSuccess(data))
  } catch (error) {
    yield put(actions.createEventVenueFailure())
    yield put(failureAction.failure(error))
  }
}

export function* updateEventVenueRequest({ payload }) {
  try {
    const venueId = payload._id
    const { data } = yield axiosPatch(payload, `v1/event-venues/${venueId}`)
    yield put(
      actions.getEventVenueRequest({
        organizationId: payload.organizationId,
        longitude: payload.coordinates.longitude,
        latitude: payload.coordinates.latitude,
      }),
    )
    yield put(actions.updateEventVenueSuccess(data))
  } catch (error) {
    yield put(actions.updateEventVenueFailure())
    yield put(failureAction.failure(error))
  }
}

export function* deleteEventVenue({ eventVenueId }) {
  try {
    const { data } = yield axiosDelete(`v1/event-venues/${eventVenueId}`)
    console.log('resData:', data)
    yield put(
      actions.getEventVenueRequest({
        organizationId: data.organizationId,
        longitude: data.coordinates.longitude,
        latitude: data.coordinates.latitude,
      }),
    )
    yield put(actions.deleteEventVenueSuccess())
  } catch (error) {
    yield put(actions.deleteEventVenueFailure())
    yield put(failureAction.failure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_EVENTS_VENUE_REQUEST, getEventVenueRequest),
    takeLatest(actions.ADD_EVENTS_VENUE_DATA, addEventVenueRequest),
    takeLatest(actions.UPDATE_EVENTS_VENUE_DATA, updateEventVenueRequest),
    takeLatest(actions.DELETE_EVENTS_VENUE, deleteEventVenue),
  ])
}
