
const actions = {
  GET_AMENITIES_REQUEST: "GET_AMENITIES_REQUEST",
  GET_AMENITIES_REQUEST_SUCCESS: "GET_AMENITIES_REQUEST_SUCCESS",
  GET_AMENITIES_REQUEST_FAILURE: "GET_AMENITIES_REQUEST_FAILURE",
  ADD_AMENITIES_DATA: "ADD_AMENITIES_DATA",
  ADD_AMENITIES_DATA_SUCCESS: "ADD_AMENITIES_DATA_SUCCESS",
  ADD_AMENITIES_DATA_FAILURE: "ADD_AMENITIES_DATA_FAILURE",

  getAmenitiesRequest: () => ({
    type: actions.GET_AMENITIES_REQUEST,
  }),

  getAmenitiesRequestSuccess: (payload) => ({
    type: actions.GET_AMENITIES_REQUEST_SUCCESS,
    payload,
  }),

  getAmenitiesRequestFailure: () => ({
    type: actions.GET_AMENITIES_REQUEST_FAILURE,
  }),

  createAmenities: (payload) => ({
    type: actions.ADD_AMENITIES_DATA,
    payload,
  }),

  createAmenitiesSuccess: (payload) => ({
    type: actions.ADD_AMENITIES_DATA_SUCCESS,
    payload,
  }),

  createAmenitiesFailure: () => ({
    type: actions.ADD_AMENITIES_DATA_FAILURE,
  }),
};

export default actions;

