import { Dropdown } from 'antd'
import ico from 'assets/Images/calandar.svg'
import { CloseDropeDown } from 'helper/CloseDropdown'
import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
import moment from 'moment'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useFilters from 'helper/Filters/useFilters'
interface DropInHeader {
  searchval: string
  setSearchVal: Dispatch<SetStateAction<string>>
  calendarDate: string
  setCalenderDate: Dispatch<SetStateAction<string>>
}
const DropInHeader = ({
  searchval,
  setSearchVal,
  calendarDate,
  setCalenderDate,
}: DropInHeader) => {
  const [open, setOpen] = useState(0)
  const [openFilter, setOpenFilter] = useState(0)
  const location = useLocation()
  const event: any = location.state
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })
  const [
    TicketDropDown,
    AttendieDropDown,
    GenderDropDown,
    SchoolDropDown,
    GradeDropDown,
    SortDropDown,
  ] = useFilters({ open: openFilter, setOpen: setOpenFilter })
  const DropInDate = [
    {
      key: 'checkboxes',
      label: (
        <div className="calendar-customzation">
          <MultiDateSelectCalendar
            selectedDate={calendarDate}
            setSelectedDate={setCalenderDate}
            dates={event?.datesOfEvent}
          />
        </div>
      ),
    },
  ]

  return (
    <div className="mt-20px">
      <h4 className="text-14 w-500">Search attendees</h4>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '94% 6%',
          gap: '2%',
          marginTop: '10px',
        }}
      >
        <input
          type="text"
          className="input-style"
          style={{ paddingLeft: '10px' }}
          value={searchval}
          onChange={e => setSearchVal(e.target.value)}
        />
        <div
          className="pointer"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <Dropdown
            menu={{
              items: DropInDate,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 1}
            overlayStyle={{ width: '450px' }}
          >
            <div onClick={() => setOpen(1)}>
              <img src={ico} alt="" />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="mt-20px">
        <div
          className="flex align-center"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          {
            <div className=" flex justify-between w-full">
              <div className="flex-center">
                <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
                <div>{AttendieDropDown}</div>
                <div>{GenderDropDown}</div>
                <div>{SchoolDropDown}</div>
                <div>{GradeDropDown}</div>
              </div>
              <div>
                <div>{SortDropDown}</div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default DropInHeader
