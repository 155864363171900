import React, { useState, useEffect } from "react";
import {
    Button,
    Row,
    Col,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { useDispatch, connect } from "react-redux";

import ViewAddress from "../../../../../../../helper/Map/ViewAddress";

const ViewLocationModal = ({
                               isVisible,
                               setIsVisible,
                                     isLoading,
                                     totalCount,
                                     mapData
                                 }: any) => {

    return (
        <div>
            <Modal
                title="View Location"
                visible={isVisible}
                className="map-modal"
                footer={null}
                centered={true}
                width={600}
                onCancel={() => {
                    setIsVisible(false);
                }}
            >
                <Col>
                    <div>
                        <ViewAddress onlyView={true} mapData={mapData} />
                        <Row>
                            <Col className="d-flex">
                                <Button
                                    className="venue_button-events br-8"
                                    onClick={() =>
                                    {
                                        setIsVisible(false);
                                    }
                                    }
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    totalCount: state.organization.totalCount,
});
export default connect(mapStateToProps)(ViewLocationModal);
