import React, { useState } from "react";
import facebookIcon from "../../assets/Images/facebook.svg";
import twitterIcon from "../../assets/Images/twitter.svg";
import linkedinIcon from "../../assets/Images/linkedin.svg";
import youtubeIcon from "../../assets/Images/youtube.svg";
import instagramIcon from "../../assets/Images/instagram.svg";
import tiktokkIcon from "../../assets/Images/tiktok.svg";
import { Button, Switch } from "antd";
import PrivacyPolicyModal from "../Modals/SettingsModal/PrivacyPolicyModal";
import TermsandConditionModal from "../Modals/SettingsModal/TermsandConditionModal";
import { useHistory } from "react-router";
import EventaggrimentModal from "../Modals/SettingsModal/EventaggrimentModal";

const onChangetoggle = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const AppInformation = ({ match }: any) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisibleTD, setModalVisibleTD] = useState(false);
  const [isModalVisibleEvent, setModalVisibleEvent] = useState(false);
  const history = useHistory();

  return (
    <div className="app-information-block">
      <h1 className="b1 mb-24">App information</h1>
      <div style={{ marginBottom: "32px" }}>
        <h5>
          <b className="b2">Legal Information</b>{" "}
        </h5>
      </div>
      <b className="b2"></b>
      <div className="legal-btn-div">
        <Button
          onClick={() => {
            history.push("/askQuestions");
          }}
          className="legal-btn"
        >
          Frequently asked questions
        </Button>
      </div>
      <div className="legal-btn-div">
        <Button
          className="legal-btn"
          onClick={() => {
            setModalVisible(true);
            // setCurrentData([])
          }}
        >
          Privacy Policy
        </Button>
      </div>
      <div className="legal-btn-div">
        <Button
          onClick={() => {
            setModalVisibleTD(true);
            // setCurrentData([])
          }}
          className="legal-btn"
        >
          Terms and Conditions
        </Button>
      </div>
      <div className=" mb-26">
        <h5 className=" mb-26 ">
          <b className="b2">Event agreement</b>{" "}
        </h5>
        <div className="legal-btn-div ">
          <Button
            onClick={() => {
              setModalVisibleEvent(true);
              // setCurrentData([])
            }}
            className="legal-btn"
          >
            For parents
          </Button>
        </div>
        <div className="legal-btn-div ">
          <Button
            onClick={() => {
              setModalVisibleEvent(true);
              // setCurrentData([])
            }}
            className="legal-btn"
          >
            For events
          </Button>
        </div>
      </div>
      <div className=" mb-26">
        <h5 className=" mb-26 ">
          <b className="b2">App agreement</b>{" "}
        </h5>
        <div className="legal-btn-div ">
          <Button
            onClick={() => {
              setModalVisibleEvent(true);
              // setCurrentData([])
            }}
            className="legal-btn"
          >
            For parents
          </Button>
        </div>
        <div className="legal-btn-div ">
          <Button
            onClick={() => {
              setModalVisibleEvent(true);
              // setCurrentData([])
            }}
            className="legal-btn"
          >
            For events
          </Button>
        </div>
        <div className="legal-btn-div ">
          <Button
            onClick={() => {
              setModalVisibleEvent(true);
              // setCurrentData([])
            }}
            className="legal-btn"
          >
            For mentors
          </Button>
        </div>
      </div>
      <h5>
        <b className="b2">Social media settings</b>{" "}
      </h5>
      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={facebookIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Facebook profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>

      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={twitterIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Twitter profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>

      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={linkedinIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Twitter profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>

      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={youtubeIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Twitter profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>

      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={instagramIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Twitter profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>

      <div className="social-switch-div">
        <div className="social-switch-img">
          <img alt="img" src={tiktokkIcon} />
        </div>
        <input
          type={"text"}
          placeholder="Twitter profile URL"
          className={"social-input"}
        />
        <div className="switch-btn">
          <Switch defaultChecked onChange={onChangetoggle} />
        </div>
      </div>
      {isModalVisible ? (
        <PrivacyPolicyModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
        />
      ) : (
        <></>
      )}
      {isModalVisibleTD ? (
        <TermsandConditionModal
          isModalVisibleTD={isModalVisibleTD}
          setModalVisibleTD={setModalVisibleTD}
        />
      ) : (
        <></>
      )}
      {isModalVisibleEvent ? (
        <EventaggrimentModal
          isModalVisibleEvent={isModalVisibleEvent}
          setModalVisibleEvent={setModalVisibleEvent}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default AppInformation;
