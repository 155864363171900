// import React, { useState } from 'react'
// import { Modal, Button, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'
// import { DownOutlined } from '@ant-design/icons'

// const ExportModal = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'First Name',
//     'Last Name',
//     'Email',
//     'Phone',
//   ])

//   const csvData = data?.map(item => ({
//     firstName: item?.firstName,
//     lastName: item?.lastName,
//     email: item?.email,
//     phone: item?.phone?.number,
//   }))

//   const headers = selectedColumns.map(column => ({
//     label: column,
//     key: column.toLowerCase().replace(' ', ''),
//   }))

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = checkedValues => {
//     setSelectedColumns(checkedValues)
//   }

//   const handleExport = () => {
//     console.log(data, 'data')
//     console.log(csvData, 'csvData')
//     // Implement CSV export logic
//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     }
//     onClose()
//   }

//   return (
//     <Modal
//       visible={visible}
//       onCancel={onClose}
//       onOk={handleExport}
//       title="Export Data"
//     >
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel" disabled>
//           Excel
//         </Radio>
//         <Radio value="pdf" disabled>
//           PDF
//         </Radio>
//       </Radio.Group>
//       <Checkbox.Group
//         options={['First Name', 'Last Name', 'Email', 'Phone', 'Date of Birth']}
//         defaultValue={selectedColumns}
//         onChange={handleColumnChange}
//       />
//       <CSVLink
//         data={csvData}
//         headers={headers}
//         filename="exported_data_New.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportModal

// Second Code
// import React, { useState } from 'react'
// import { Modal, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'

// const ExportModal = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'First Name',
//     'Last Name',
//     'Email',
//     'Phone',
//     'Date of Birth',
//     'Status',
//   ])

//   const csvData = data?.map(item => ({
//     key: item?._id,
//     firstName: item?.firstName,
//     lastName: item?.lastName,
//     email: item?.email,
//     phone: item?.phone?.number,
//     dob: item?.dob,
//     status: item?.status,
//   }))

//   const headers = [
//     { label: 'Key', key: 'key' },
//     { label: 'First Name', key: 'firstName' },
//     { label: 'Last Name', key: 'lastName' },
//     { label: 'Email', key: 'email' },
//     { label: 'Phone', key: 'phone' },
//     { label: 'Date of Birth', key: 'dob' },
//     { label: 'Status', key: 'status' },
//   ]

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = checkedValues => {
//     setSelectedColumns(checkedValues)
//   }

//   const handleExport = () => {
//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     }
//     onClose()
//   }

//   return (
//     <Modal
//       visible={visible}
//       onCancel={onClose}
//       onOk={handleExport}
//       title="Export Data"
//     >
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel" disabled>
//           Excel
//         </Radio>
//         <Radio value="pdf" disabled>
//           PDF
//         </Radio>
//       </Radio.Group>
//       <Checkbox.Group
//         options={[
//           'First Name',
//           'Last Name',
//           'Email',
//           'Phone',
//           'Date of Birth',
//           'Status',
//         ]}
//         defaultValue={selectedColumns}
//         onChange={handleColumnChange}
//       />
//       <CSVLink
//         data={csvData}
//         headers={headers.filter(header =>
//           selectedColumns.includes(header.label),
//         )}
//         filename="exported_data.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportModal

// the below code is working fine
// import React, { useState } from 'react'
// import { Modal, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'
// import { saveAs } from 'file-saver'
// import * as XLSX from 'xlsx'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import Cancel from 'assets/Images/close.svg'
// const ExportModal = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'First Name',
//     'Last Name',
//     'Email',
//     'Phone',
//     'Date of Birth',
//     'Status',
//   ])
//   const handleCancel = () => {
//     onClose()
//   }
//   const csvData = data?.map(item => ({
//     key: item?._id,
//     firstName: item?.firstName,
//     lastName: item?.lastName,
//     email: item?.email,
//     phone: item?.phone?.number,
//     dob: item?.dob,
//     status: item?.status,
//   }))

//   const headers = [
//     { label: 'Key', key: 'key' },
//     { label: 'First Name', key: 'firstName' },
//     { label: 'Last Name', key: 'lastName' },
//     { label: 'Email', key: 'email' },
//     { label: 'Phone', key: 'phone' },
//     { label: 'Date of Birth', key: 'dob' },
//     { label: 'Status', key: 'status' },
//   ]

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = checkedValues => {
//     setSelectedColumns(checkedValues)
//   }

//   const handleExport = () => {
//     const filteredHeaders = headers.filter(header =>
//       selectedColumns.includes(header.label),
//     )
//     const filteredData = csvData.map(row => {
//       const filteredRow = {}
//       filteredHeaders.forEach(header => {
//         filteredRow[header.key] = row[header.key]
//       })
//       return filteredRow
//     })

//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     } else if (fileFormat === 'excel') {
//       const ws = XLSX.utils.json_to_sheet(filteredData)
//       const wb = XLSX.utils.book_new()
//       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
//       XLSX.writeFile(wb, 'Organizer_Table.xlsx')
//     } else if (fileFormat === 'pdf') {
//       const doc = new jsPDF()
//       const tableColumn = filteredHeaders.map(header => header.label)
//       const tableRows = filteredData.map(row =>
//         filteredHeaders.map(header => row[header.key]),
//       )

//       ;(doc as any).autoTable({
//         head: [tableColumn],
//         body: tableRows,
//       })

//       doc.save('Organizer_Table.pdf')
//     }
//     onClose()
//   }

//   return (
//     <Modal visible={visible} footer={false} className="addExport-model">
//       <div className="popup-header ">
//         <h2>Export Data</h2>
//         <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
//       </div>
//       <div className="formatText ">Format </div>
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel">Excel</Radio>
//         <Radio value="pdf">PDF</Radio>
//       </Radio.Group>
//       <div className="radioText ">Please select columns to export </div>
//       <Checkbox.Group
//         className="checkbox-group"
//         options={[
//           'First Name',
//           'Last Name',
//           'Email',
//           'Phone',
//           'Date of Birth',
//           'Status',
//         ]}
//         defaultValue={selectedColumns}
//         onChange={handleColumnChange}
//       />
//       <div className="flex justify-end w-full mt-10px">
//         <div className="bg-grey radius-8 pointer mr-30px">
//           <button
//             className="prim-sm-btn"
//             style={{ background: '#EBEDF1', color: 'black' }}
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//         </div>

//         <button className="prim-sm-btn" onClick={handleExport}>
//           Export
//         </button>
//       </div>
//       <CSVLink
//         data={csvData}
//         headers={headers.filter(header =>
//           selectedColumns.includes(header.label),
//         )}
//         filename="Organizer_Table.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportModal

import React, { useState } from 'react'
import { Modal, Radio, Checkbox } from 'antd'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Cancel from 'assets/Images/close.svg'
import moment from 'moment'
const ExportModal = ({ visible, onClose, data }) => {
  const [fileFormat, setFileFormat] = useState('csv')
  const [selectedColumns, setSelectedColumns] = useState([
    'First Name',
    'Last Name',
    'Organization',
    'Email',
    'Phone',
    'Date of Birth',
    'Status',
  ])

  const handleCancel = () => {
    onClose()
  }
  const csvData = data?.map(item => ({
    key: item?._id,
    firstName: item?.firstName,
    lastName: item?.lastName,
    organization: item?.organization?.name,
    email: item?.email,
    phone: item?.phone?.number,
    dob: moment(item?.dob).format('MMM DD, YYYY'),
    status: item?.status,
  }))

  const headers = [
    { label: 'Key', key: 'key' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Organization', key: 'organization' },
    { label: 'Email', key: 'email' },
    { label: 'Phone', key: 'phone' },
    { label: 'Date of Birth', key: 'dob' },
    { label: 'Status', key: 'status' },
  ]

  const handleFormatChange = e => {
    setFileFormat(e.target.value)
  }

  const handleColumnChange = (checkedValue, label) => {
    setSelectedColumns(prev =>
      checkedValue ? [...prev, label] : prev.filter(col => col !== label),
    )
  }

  const handleExport = () => {
    const filteredHeaders = headers.filter(header =>
      selectedColumns.includes(header.label),
    )
    const filteredData = csvData.map(row => {
      const filteredRow = {}
      filteredHeaders.forEach(header => {
        filteredRow[header.key] = row[header.key]
      })
      return filteredRow
    })

    if (fileFormat === 'csv') {
      document.getElementById('csv-download-link').click()
    } else if (fileFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Organizer_Table.xlsx')
    } else if (fileFormat === 'pdf') {
      const doc = new jsPDF()
      const tableColumn = filteredHeaders.map(header => header.label)
      const tableRows = filteredData.map(row =>
        filteredHeaders.map(header => row[header.key]),
      )

      ;(doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
      })

      doc.save('Organizer_Table.pdf')
    }
    onClose()
  }

  return (
    <Modal visible={visible} footer={false}>
      <div className="popup-header ">
        <h2>Export Data</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="formatText ">Format </div>
      <Radio.Group onChange={handleFormatChange} value={fileFormat}>
        <Radio value="csv">CSV</Radio>
        <Radio value="excel">Excel</Radio>
        <Radio value="pdf">PDF</Radio>
      </Radio.Group>
      <div className="radioText mt-15px">Please select columns to export </div>
      <div className="checkbox-group">
        {[
          'First Name',
          'Last Name',
          'Organization',
          'Email',
          'Phone',
          'Date of Birth',
          'Status',
        ].map(label => (
          <div key={label}>
            <Checkbox
              checked={selectedColumns.includes(label)}
              onChange={e => handleColumnChange(e.target.checked, label)}
            >
              {label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mt-10px">
        <div className="bg-grey radius-8 pointer mr-30px">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <button className="prim-sm-btn" onClick={handleExport}>
          Export
        </button>
      </div>
      <CSVLink
        data={csvData}
        headers={headers.filter(header =>
          selectedColumns.includes(header.label),
        )}
        filename="Organizer_Table.csv"
        id="csv-download-link"
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default ExportModal
