import React, { useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import RequiredDocumentsModal from '../../Modals/documents/RequiredDocuments'
import { creationEventStore } from 'state/eventCreationStore'

const RequiredDocuments: React.FC = () => {
  const [documentModalVisible, setDocumentModalVisible] =
    useState<boolean>(false)
  // TODO: change this to specific type later
  const payload: any = creationEventStore(store => store.step5Payload)
  return (
    <>
      <div onClick={() => setDocumentModalVisible(true)}>
        <IconsTextNavigation
          iconName="FilePlus"
          heading="Required documents"
          details={
            payload?.requiredDocumentIds
              ? payload.requiredDocumentIds.length
              : 'Not Added'
          }
        />
      </div>
      {documentModalVisible && (
        <RequiredDocumentsModal
          openModal={documentModalVisible}
          closeModal={setDocumentModalVisible}
        />
      )}
    </>
  )
}

export default RequiredDocuments
