import { all, put, takeLatest } from 'redux-saga/effects'
import actions from './action'
import {
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
  axiosDelete,
} from '../axiosHelper'

/**
 * get organizer data
 *
 */
export function* getOrganizersRequest({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 10,
      sort: params?.sort?.order || 'desc',
      queryString: params?.queryString || '',
      populate:
        '[{"path":"photo"}, {"path":"organization","populate":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/admins/organizers`, currentParams)
    console.log('data = ', data)
    yield put(actions.getOrganizersSuccess(data))
  } catch (error) {
    yield put(actions.getOrganizersFailure())
  }
}

export function* getOrganizerRequest({ payload, id }) {
  try {
    let currentParams = {
      organizations: true,
    }
    const { data } = yield axiosGet(`v1/organizers/${id}`, currentParams)
    yield put(actions.getOrganizerSuccess(data))
  } catch (error) {
    yield put(actions.getOrganizerFailure())
  }
}

/**
 * post organizer data
 *
 */
export function* addOrganizerRequest({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create-organizer`)
    yield put(actions.addOrganizerSuccess(data))
    yield put(localStorage.setItem('organizerId', data.id))
  } catch (error) {
    yield put(actions.addOrganizerFailure())
  }
}
/**
 * Update organizer data
 *
 */
export function* updateOrganizerRequest({ payload, params }) {
  console.log(params + ' parmas from update')
  try {
    const { data } = yield axiosPatch(payload, `v1/admins/organizer/${params}`)
    yield put(actions.updateOrganizerSuccess(data))
    yield put(localStorage.setItem('organizerId', params))
  } catch (error) {
    yield put(actions.updateOrganizerFailure())
  }
}

export function* updateOrganizationRequest({ payload, params }) {
  try {
    const { data } = yield axiosPatch(payload, `v1/organizations/${params}`)
    yield put(actions.updateOrganizationSuccess(data))
  } catch (error) {
    yield put(actions.updateOrganizationFailure())
  }
}

export function* updateOrganizerStatusRequest({ payload, id }) {
  try {
    const { data } = yield axiosPatch(payload, `v1/organizers/${id}/status`)
    yield put(actions.updateOrganizerStatusSuccess(data))
  } catch (error) {
    yield put(actions.updateOrganizerStatusFailure())
  }
}

export function* getAllergiesRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/allergies`)
    yield put(actions.getAllergiesSuccess(data))
  } catch (error) {
    yield put(actions.getAllergiesFailure())
  }
}

export function* updateAssignOrganization({ payload }) {
  try {
    let id = localStorage.getItem('organizerId')
    yield axiosPut(payload, `v1/admins/organizer/${id}/assign-organization`)
    yield put(actions.assignOrganizationSuccess())
  } catch (error) {
    yield put(actions.assignOrganizationFailure())
  }
}

export function* getAccessLevelRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/access-levels`)
    yield put(actions.getAccessLevelSuccess(data))
  } catch (error) {
    yield put(actions.getAccessLevelFailure())
  }
}

// export function* deleteOrganizerRequest({ payload }) {
//   console.log(payload);
//   try {
//     const { data } = yield axiosDelete(`v1/organizers/${payload}`);
//     yield put(actions.deleteOrganizerSuccess(data));
//   } catch (error) {
//     yield put(actions.deleteOrganizerFailure(error));
//   }
// }
export function* deleteOrganizerRequest({ payload, callback }) {
  console.log(payload)
  try {
    const { data } = yield axiosDelete(`v1/organizers/${payload}`)
    yield put(actions.deleteOrganizerSuccess(data))
    if (callback && typeof callback === 'function') {
      callback()
    }
  } catch (error) {
    yield put(actions.deleteOrganizerFailure(error))
  }
}

export function* blockOrganizerEmail({ payload }) {
  console.log(payload)
  try {
    const { data } = yield axiosPost(payload, `v1/users/${payload}/deactivate`)
    yield put(actions.blockOrganizerEmailSuccess(data))
  } catch (error) {
    yield put(actions.blockOrganizerEmailFailure(error))
  }
}

// export function* changeOrganizerPassword({ payload }) {
//   console.log('ischangingpassword', payload)
//   try {
//     const { data } = yield axiosPost(payload, `v1/users/set-new-password`)
//     yield put(actions.changeOrganizerPasswordSuccess(data))
//   } catch (error) {
//     yield put(actions.changeOrganizerPasswordFailure(error))
//   }
// }
export function* changeOrganizerPassword({ payload }) {
  try {
    const { updatedOrganizer } = yield axiosPost(
      payload,
      `v1/users/set-new-password`,
    )
    yield put(actions.changeOrganizerPasswordSuccess(updatedOrganizer))

    // Fetch updated organizer data
    // const updatedOrganizer = yield
    // axiosGet(`v1/organizers/${payload.organizerId}`, { organizations: true })
    // yield put(actions.getOrganizerSuccess(data))

    const { data } = yield axiosGet(`v1/organizers/${payload.organizerId}`)
    yield put(actions.getOrganizerSuccess(data))
    yield put(actions.closeOrganizerChangePassword())
  } catch (error) {
    yield put(actions.changeOrganizerPasswordFailure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.ADD_ORGANIZER, addOrganizerRequest),
    takeLatest(actions.UPDATE_ORGANIZER, updateOrganizerRequest),
    takeLatest(actions.GET_ORGANIZERS, getOrganizersRequest),
    takeLatest(actions.GET_ORGANIZER, getOrganizerRequest),
    takeLatest(actions.GET_ALLERGIES, getAllergiesRequest),
    takeLatest(actions.UPDATE_ORGANIZER_STATUS, updateOrganizerStatusRequest),
    takeLatest(actions.ASSIGN_ORGANIZATION, updateAssignOrganization),
    takeLatest(actions.GET_ACCESS_LEVEL, getAccessLevelRequest),
    takeLatest(actions.BLOCK_ORGANIZER_EMAIL, blockOrganizerEmail),
    takeLatest(actions.DELETE_ORGANIZER_REQUEST, deleteOrganizerRequest),
    takeLatest(actions.CHANGE_ORGANIZER_PASSWORD, changeOrganizerPassword),
  ])
}
