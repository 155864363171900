import { Table } from 'antd'

const Payments = () => {
  const data = [
    {
      Date: '2024-10-21',
      familyName: 'Masood',
      Total: '$399',
      Tickets: '3',
    },
    {
      Date: '2024-10-22',
      familyName: 'Ahmad',
      Total: '$99',
      Tickets: '3',
    },
  ]

  const dataSource = [
    {
      key: '1',
      familyName: 'Masood',
      total: '$399',
      tickets: '3',
    },
    {
      key: '2',
      familyName: 'Ahmad',
      total: '$399',
      tickets: '3',
    },
  ]
  const expandDataSource = [
    {
      key: '1',
      date: '2024-10-21',
      kid: 'Waris',
      tickets: 'Addmission',
      amount: '$399',
    },
    {
      key: '2',
      date: '2023-12-21',
      kid: 'Ahmad',
      tickets: 'Open',
      amount: '$9',
    },
  ]
  const Excolumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'dated',
    },
    {
      title: 'Kid',
      dataIndex: 'kid',
      key: 'kids',
    },
    {
      title: 'Tickets',
      dataIndex: 'tickets',
      key: 'sold',
    },
    {
      title: 'Total',
      dataIndex: 'amount',
      key: 'sold',
    },
  ]
  const columns = [
    {
      title: 'Family name',
      dataIndex: 'familyName',
      key: 'name',
    },
    {
      title: 'Revenue',
      dataIndex: 'total',
      key: 'revn',
    },
    {
      title: 'Tickets Sold',
      dataIndex: 'tickets',
      key: 'sold',
    },
  ]

  const expandedRowRender = (record: any) => {
    return (
      <div className="flex-center py-20">
        <Table
          dataSource={expandDataSource}
          columns={Excolumns}
          pagination={false}
          className="w-full"
        />
      </div>
    )
  }

  return (
    <div className="p-30px">
      <div className="flex justify-between">
        <div className="border-1px radius-8 py-30px px-30px ">
          <h1 className="text-20 ">Total Earnings</h1>
          <h2>$6400</h2>
        </div>
        <div className="border-1px radius-8 py-30px px-30px ">
          <h1 className="text-20 ">Total Revenue</h1>
          <h2>$2300</h2>
        </div>
        <div className="border-1px radius-8 py-30px px-30px ">
          <h1 className="text-20 ">Tickets Sold</h1>
          <h2>$20</h2>
        </div>
      </div>

      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{ expandedRowRender }}
        className="mt-20px"
      />
    </div>
  )
}
export default Payments
