import { Button, Col, Form, Input, Row, Select } from 'antd'
import marker from '../../assets/Images/placeholder.png'
import backarrow from '../../assets/Images/backarrow.svg'

const Address = ({ next, prev, mapData }: any) => {
  const formSchema = {
    title: mapData?.title || 'Home',
    street: mapData?.street ? mapData?.street : '',
    city: mapData?.city ? mapData?.city : '',
    state: mapData?.state ? mapData?.state : '',
    country: mapData?.country ? mapData?.country : '',
    zip: mapData?.zip ? mapData?.zip : '',
    apartment: mapData?.apartment ? mapData?.apartment : '',
  }
  const onFinish = (values: any) => {
    next({
      ...mapData,
      ...values,
    })
  }

  return (
    <>
      <Row>
        <h3>
          <b className="b2">
            <img src={marker}></img> {mapData.street}
          </b>
        </h3>
      </Row>
      <Row>
        <h5>{mapData.address}</h5>
      </Row>
      <Form layout="vertical" onFinish={onFinish} initialValues={formSchema}>
        <label className="input-label mt-30 ">Name of the location</label>
        <Form.Item
          name="title"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input className="custom-common-field" />
        </Form.Item>
        <label className="input-label ">Street</label>
        <Form.Item
          name="street"
          rules={[{ required: true, message: 'Street is required' }]}
        >
          <Input className="custom-common-field" />
        </Form.Item>
        <label className="input-label ">City</label>
        <Form.Item
          name="city"
          rules={[{ required: true, message: 'City is required' }]}
        >
          <Input className="custom-common-field" />
        </Form.Item>
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <label className="input-label ">Apartment</label>
            <Form.Item name="apartment">
              <Input className="custom-common-field" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="input-label ">State</label>
            <Form.Item
              name="state"
              rules={[{ required: true, message: 'State is required' }]}
            >
              <Input className="custom-common-field" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <label className="input-label ">Zip code</label>
            <Form.Item
              name="zip"
              // rules={[{ required: true, message: 'Zip code is required' }]}
            >
              <Input className="custom-common-field" />
            </Form.Item>
          </Col>
        </Row>
        <label className="input-label ">Country</label>
        <Form.Item
          name="country"
          rules={[{ required: true, message: 'Country is required' }]}
        >
          <Input className="custom-common-field" />
        </Form.Item>
        <Row justify="space-between" className="mt-30">
          <h5
            className="stepperBackh5 align-self-center"
            onClick={() => {
              prev()
            }}
          >
            <span className="mr-10 d-inline-block">
              <img src={backarrow} />
            </span>
            Back
          </h5>
          <Button type="primary" className="stepper-button" htmlType="submit">
            Confirm
          </Button>
        </Row>
      </Form>
    </>
  )
}

export default Address
