import React from 'react'
import { Row, Col, Dropdown, Typography } from 'antd'
import {
  LogoutOutlined,
  SettingOutlined,
  BellOutlined,
  SearchOutlined,
  PlusOutlined,
  DownOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import authActions from '../../Redux/Auth/action'
import { Button } from 'antd'
import { Layout, Menu } from 'antd'
import 'antd/dist/antd.css'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import bamreclogo from '../../assets/Images/bamrec-logo.svg'
import { render } from '@testing-library/react'
import NewsModal from '../../pages/Modals/NewsModal'
import actions from '../../Redux/Organization/action'
import organizerAction from '../../Redux/Organizer/action'
import mentorsAction from '../../Redux/mentors/action'
import familyAction from '../../Redux/Family/action'
import eventsAction from '../../Redux/Events/action'
import settingAction from '../../Redux/Settings/action'
import { connect } from 'react-redux'
import { getMe } from '../../api/Organizations'
import { Link } from 'react-router-dom'
const { Header } = Layout

const HeaderComponent = ({ user }: any) => {
  const { isLoading, data, refetch } = useQuery(['getMe'], () => getMe())

  useEffect(() => {
    refetch()
  }, [])

  const dispatch = useDispatch()
  const history = useHistory()
  const location = history.location.pathname
  const handleLogout = async () => {
    try {
      dispatch(authActions.logout())
      localStorage.removeItem('previousRoute')
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }
  return (
    <div>
      <Header
        style={{ backgroundColor: 'white', display: 'flex', flex: 1 }}
        className="header-nav"
      >
        <Row style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
          <Col
            span={4}
            className={'header-title'}
            style={{
              display: 'flex',
              alignSelf: 'center',
            }}
          >
            <Typography className={'title-fontStyle text-center'}>
              <div
                onClick={() => {
                  history.push('/')
                }}
                className="pointer"
              >
                <img alt="alt" src={bamreclogo} />
              </div>
            </Typography>
          </Col>
          <Col style={{ display: 'flex', flexWrap: 'nowrap', flex: 6 }}>
            <div className="user-search" style={{ width: '100%' }}>
              <div style={{ position: 'relative' }}>
                <input
                  type="search"
                  className="search-input-group br-8"
                  placeholder="Search For Anything"
                />
                <SearchOutlined />
              </div>
            </div>
          </Col>
          <Col style={{ display: 'flex', flexWrap: 'nowrap', flex: 6 }}>
            <div className={'user-title'}>
              <Button
                className="nav-btn br-8"
                onClick={() => {
                  dispatch({
                    type: actions.CURRENT_ORGANIZATION_DATA,
                    payload: null,
                  })
                  dispatch({
                    type: organizerAction.CURRENT_ORGANIZER,
                    payload: null,
                  })
                  dispatch({
                    type: mentorsAction.CURRENT_MENTOR_DATA,
                    payload: [],
                  })
                  dispatch({
                    type: familyAction.CURRENT_FAMILY_DATA,
                    payload: [],
                  })
                  // dispatch({
                  //   type: eventsAction.EVENTS_MODAL_VISIBLE,
                  //   payload: [],
                  // });

                  switch (location) {
                    case '/organization':
                      dispatch({ type: actions.MODAL_VISIBLE, payload: true })
                      break
                    case '/organizer':
                      dispatch({
                        type: organizerAction.ORGANIZER_MODAL_VISIBLE,
                        payload: true,
                      })
                      break
                    case '/mentors':
                      dispatch({
                        type: mentorsAction.MENTORS_MODAL_VISIBLE,
                        payload: true,
                      })
                      break
                    case '/family':
                      dispatch({
                        type: familyAction.FAMILY_MODAL_VISIBLE,
                        payload: true,
                      })
                      break
                    case '/newsfeed':
                      render(<NewsModal />)
                      break
                    // case "/events":
                    //   dispatch({
                    //     type: eventsAction.EVENTS_MODAL_VISIBLE,
                    //     payload: true,
                    //   });
                    //   break;
                    case '/settings':
                      dispatch({
                        type: settingAction.PAYMENT_MODAL_VISIBLE,
                        payload: true,
                      })
                      break
                  }
                }}
              >
                <PlusOutlined /> New
              </Button>
              <div className="nav-btn-bell">
                <BellOutlined
                  style={{ fontSize: '20px' }}
                  type="primary"
                ></BellOutlined>
              </div>
              <Dropdown
                className="user-avtar"
                overlay={
                  <Menu theme="light">
                    {/* <Menu.Item
                      key="setting"
                      icon={<SettingOutlined />}
                      onClick={() => history.push('/system-settings')}
                    >
                      System settings
                    </Menu.Item> */}
                    <Link to={`/system-settings`}>
                      <Menu.Item
                        key="setting"
                        icon={<SettingOutlined />}
                        onClick={() => history.push('/system-settings')}
                      >
                        System settings
                      </Menu.Item>
                    </Link>
                    <Menu.Item
                      key="logout"
                      icon={<LogoutOutlined />}
                      onClick={handleLogout}
                      // onClick={() => dispatch(authActions.logout())}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                }
                placement="bottomLeft"
                arrow
              >
                <Row
                  justify="space-between"
                  style={{ borderColor: 'red', borderWidth: 1 }}
                >
                  <Button className="avtar-btn br-8">
                    {/* {user?.displayName ?? 'Organizer'} */}
                    {data?.firstName + ' ' + data?.lastName ?? ' '}
                    <DownOutlined />
                  </Button>
                  <Typography style={{ color: 'white' }}>
                    {user?.data ? user?.data.user?.username : user.username}
                  </Typography>
                </Row>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Header>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(HeaderComponent)
