import React, { useState } from 'react'
import { Modal, Radio, Checkbox } from 'antd'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Cancel from 'assets/Images/close.svg'
import moment from 'moment'

const ExportOrganization = ({ visible, onClose, data = [] }) => {
  const [fileFormat, setFileFormat] = useState('csv')
  const [selectedColumns, setSelectedColumns] = useState([
    'Organization Name',
    'Admin Name',
    'Created At',
    'Status',
  ])

  const handleCancel = () => {
    onClose()
  }

  const csvData = data?.map(item => ({
    key: item?._id,
    name: item?.name,
    // `${item?.owner?.firstName} ${item?.owner?.lastName}`

    adminName: item?.owner
      ? `${item?.owner?.firstName} ${item?.owner?.lastName}`
      : '',
    createdAt: moment(item.createdAt).format('MMM DD,YYYY'),
    status: item.status === 'approved' ? 'active' : 'pending',
  }))

  const headers = [
    { label: 'Key', key: 'key' },
    { label: 'Organization Name', key: 'name' },
    { label: 'Admin Name', key: 'adminName' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Status', key: 'status' },
  ]

  const handleFormatChange = e => {
    setFileFormat(e.target.value)
  }

  const handleColumnChange = (checkedValue, label) => {
    setSelectedColumns(prev =>
      checkedValue ? [...prev, label] : prev.filter(col => col !== label),
    )
  }

  const handleExport = () => {
    const filteredHeaders = headers.filter(header =>
      selectedColumns.includes(header.label),
    )
    const filteredData = csvData.map(row => {
      const filteredRow = {}
      filteredHeaders.forEach(header => {
        filteredRow[header.key] = row[header.key]
      })
      return filteredRow
    })

    if (fileFormat === 'csv') {
      document.getElementById('csv-download-link').click()
    } else if (fileFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Organization_Table.xlsx')
    } else if (fileFormat === 'pdf') {
      const doc = new jsPDF()
      const tableColumn = filteredHeaders.map(header => header.label)
      const tableRows = filteredData.map(row =>
        filteredHeaders.map(header => row[header.key]),
      )

      ;(doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
      })

      doc.save('Organization_Table.pdf')
    }
    onClose()
  }

  return (
    <Modal visible={visible} footer={false}>
      <div className="popup-header ">
        <h2>Export Data</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="formatText ">Format </div>
      <Radio.Group onChange={handleFormatChange} value={fileFormat}>
        <Radio value="csv">CSV</Radio>
        <Radio value="excel">Excel</Radio>
        <Radio value="pdf">PDF</Radio>
      </Radio.Group>
      <div className="radioText mt-15px">Please select columns to export </div>
      <div className="checkbox-group">
        {['Organization Name', 'Admin Name', 'Created At', 'Status'].map(
          label => (
            <div key={label}>
              <Checkbox
                checked={selectedColumns.includes(label)}
                onChange={e => handleColumnChange(e.target.checked, label)}
              >
                {label}
              </Checkbox>
            </div>
          ),
        )}
      </div>
      <div className="flex justify-end w-full mt-10px">
        <div className="bg-grey radius-8 pointer mr-30px">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <button className="prim-sm-btn" onClick={handleExport}>
          Export
        </button>
      </div>
      <CSVLink
        data={csvData}
        headers={headers.filter(header =>
          selectedColumns.includes(header.label),
        )}
        filename="Organization_Table.csv"
        id="csv-download-link"
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default ExportOrganization
