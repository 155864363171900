import React, { useState } from 'react'
import { Modal } from 'antd'
import { Steps } from 'antd'
import Basicinfo from './Steps/Basicinfo'
import OrganizationDetails from './Steps/OrganizationDetails'
import OrganizerInfo from './Steps/Organizer/OrganizerInfo'
import { useMutation } from '@tanstack/react-query'
import actions from '../../../Redux/Organization/action'

import {
  addNewOrganization,
  updateOrganization,
} from '../../../api/Organizations'
import { useDispatch } from 'react-redux'
const { Step } = Steps
interface OrganizationInterface {
  visible: boolean
  setVisblie: (arg: boolean) => void
  prevdata?: any
}
const OrganizationalModal: React.FC<OrganizationInterface> = ({
  visible,
  setVisblie,
  prevdata,
}) => {
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState(prevdata)
  const [newOrgData, setNewData] = useState({})
  const next = (childData: any) => {
    setNewData({ ...newOrgData, ...childData })
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleOk = () => {
    setVisblie(false)
  }

  const handleCancel = () => {
    setVisblie(false)
  }
  const steps = [
    {
      title: 'Organizer Info',
      content: <OrganizerInfo onSuccess={next} />,
    },
    {
      title: 'Basic Info',
      content: <Basicinfo onSuccess={next} />,
    },
    {
      title: 'Organization details',
      content: <OrganizationDetails />,
    },
  ]
  const dispatch = useDispatch()
  const newOrganizationMutation = useMutation({
    mutationFn: async ({ data }: any) => {
      return await addNewOrganization(data)
    },
  })
  const updateOrganizationMutation = useMutation({
    mutationFn: async ({ id, payload }: any) => {
      return await updateOrganization(id, payload)
    },
    onSuccess: () => {
      dispatch({
        type: actions.GET_ORGANIZATIONS,
        payload: { status: 'approved' },
      })
    },
  })
  const onSubmit = (submitedData: any) => {
    const updatedata = { ...data, ...submitedData }

    if (prevdata !== undefined) {
      updateOrganizationMutation.mutate({
        id: data?.id,
        payload: updatedata,
      })
      setVisblie(false)
    } else {
      setNewData({ ...newOrgData, submitedData })
      newOrganizationMutation.mutate({ data: newOrgData })
      setVisblie(false)
    }
  }
  return (
    <div className="changeModal">
      <Modal
        className="organization_model"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4> {prevdata ? 'Edit Organization' : 'New Organization'} </h4>
          <Steps current={current}>
            {prevdata === undefined &&
              steps.map(item => (
                <Step
                  key={item?.title}
                  className="stepper"
                  title={item?.title}
                />
              ))}
          </Steps>
        </div>

        {prevdata === undefined && (
          <div>
            {current === 0 && <OrganizerInfo onSuccess={next} />}
            {current === 1 && <Basicinfo onSuccess={next} handleBack={prev} />}
            {current === 2 && (
              <OrganizationDetails onSubmit={onSubmit} handleBack={prev} />
            )}
          </div>
        )}
        {prevdata !== undefined && (
          <div>
            {current === 0 && (
              <Basicinfo data={data} changeData={setData} onSuccess={next} />
            )}
            {current === 1 && (
              <OrganizationDetails
                data={data}
                onSubmit={onSubmit}
                handleBack={prev}
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  )
}
export default OrganizationalModal
