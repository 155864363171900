import React from 'react'
import { Avatar } from 'antd'
import { getNameSignature } from 'helper/utility'
import { history } from 'Redux/app-store'
interface taggedPeopleType {
  TaggedData: any
}

const TaggedPeople: React.FC<taggedPeopleType> = ({ TaggedData }) => {
  const hanldeGoToProfile = (member: any) => {
    if (member.type === 'guardian') {
      history.push({
        pathname: `/parent-profile/${member._id}`,
      })
    } else if (member.type === 'kid') {
      history.push({
        pathname: `/kid-profile/${member._id}`,
      })
    } else if (member.type === 'authorized-adult') {
      history.push({
        pathname: `/adult-profile/${member._id}`,
      })
    }
  }

  return (
    <>
      <h4 className="text-14 w-600 px-20px mt-20">Tagged People</h4>
      {TaggedData?.tagged?.length > 0 ? (
        TaggedData?.tagged?.map((item: any) => {
          return (
            <div
              className="flex-col px-20px"
              onClick={() => hanldeGoToProfile(item)}
            >
              <div className="flex  align-center mt-15px pointer">
                <Avatar size={32} src={item?.photo?.post} alt="profile">
                  {getNameSignature(item?.firstName)}
                </Avatar>
                <h4 className="pl-15px text-14 w-500">{item?.firstName}</h4>
              </div>
            </div>
          )
        })
      ) : (
        <div className=" h-full tagged-area">
          <h4 className="text-14 w-600">No people are tagged</h4>
        </div>
      )}
    </>
  )
}

export default TaggedPeople
