import Edit from 'assets/Images/PencilLine.svg'
import { convertToLocal, getUserName } from 'helper/utility'
import { CheckInOutEvent } from 'pages/Events/types'
import { useEffect, useState } from 'react'
interface CardType {
  data: CheckInOutEvent
}

const HistoryCard: React.FC<CardType> = ({ data }) => {
  const status =
    data.checkInStatus == 'checked-in' && data.checkOutStatus !== 'checked-out'
      ? 'Check in'
      : data.checkOutStatus == 'checked-out' && 'Checked out'

  let nameToShow = ''
  let checkInOutPerson = ''
  const [personData, setData] = useState({
    checkInPerson: '',
    checkOutPerson: '',
    checkinName: '',
    checkoutName: '',
  })
  useEffect(() => {
    if (status === 'Check in') {
      if (data.checkInMentor !== null) {
        setData({
          ...personData,
          checkInPerson: 'Mentor check-out',
          checkinName: getUserName(data.checkInMentor),
        })
      } else if (data.checkInGuardian !== null) {
        setData({
          ...personData,
          checkInPerson: 'Guardian check-in',
          checkinName: getUserName(data.checkInGuardian),
        })
      } else if (data.checkInOrganizer !== null) {
        setData({
          ...personData,
          checkInPerson: 'Organizer check-in',
          checkinName: getUserName(data.checkInOrganizer),
        })
      }
    } else {
      if (data.checkOutMentor !== null) {
        setData({
          ...personData,
          checkOutPerson: 'Mentor check-out',
          checkoutName: getUserName(data.checkOutMentor),
        })
      } else if (data.checkOutGuardian !== null) {
        setData({
          ...personData,
          checkOutPerson: 'Guardian check-out',
          checkoutName: getUserName(data.checkOutGuardian),
        })
      } else if (data.checkOutOrganizer !== null) {
        setData({
          ...personData,
          checkOutPerson: 'Organizer check-out',
          checkoutName: getUserName(data.checkOutOrganizer),
        })
      }
    }
  }, [status, data])

  return (
    <div className="mt-20px ">
      <h4
        className="text-12"
        style={{ color: status == 'Check in' ? '#A6CA16' : '#FF6961' }}
      >
        {status}
      </h4>

      <div className="flex-center justify-between ">
        <div>
          <div className="flex-center mt-5px">
            <div className="bg-grey px-15px py-10px radius-8">
              <h4>
                {status == 'Check in'
                  ? convertToLocal(data.checkedInAt, 'hh: mm A')
                  : convertToLocal(data.checkedOutAt, 'hh: mm A')}
              </h4>
            </div>
            <img src={Edit} alt="edit" className="ml-10px w-25px" />
          </div>
        </div>

        <div>
          <h4 className="text-12">{personData.checkInPerson}</h4>
          <h2 className="text-14">{personData.checkinName}</h2>
        </div>
        <div>
          <h4 className="text-12">{personData.checkOutPerson}</h4>
          <h2 className="text-14">{personData.checkoutName}</h2>
        </div>
        <div>
          {data.pickUpPerson !== 'guardian' ? (
            <>
              <h4 className="text-12">Pickup Person</h4>
              <h2 className="text-14">{data.pickUpPersonName}</h2>
            </>
          ) : (
            data.checkOutType == 'request' && (
              <>
                <h4 className="text-12">Guardian approved</h4>
                <h2 className="text-14">
                  {getUserName(data.checkOutGuardian!)}
                </h2>
              </>
            )
          )}
        </div>
      </div>
    </div>
  )
}
export default HistoryCard
