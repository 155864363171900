import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import AddActivityModal from "./AddActivityModal";
import plusBlue from "../../../../assets/Images/plusBlue.svg";
import SearchBar from "../../../../helper/SearchBar";
import ActivityDetailsModal from "./ActivityDetailsModal";
const DefaultTab = () => {
  const [AddActivityModalVisible, setAddActivityModalVisible] = useState(false);
  const [AddActivityDetailsModalVisible, setAddActivityDetailsModalVisible] =
    useState(false);
  const data = ["Check-ins", "Breakfast", "Lunch", "Dinner", "Check-out"];

  return (
    <div>
      <div style={{ height: "400px", marginTop: "20px" }}>
        <Col>
          <SearchBar />
        </Col>
        {data.map((item: any) => {
          return (
            <Row justify="space-between" style={{ marginTop: "20px" }}>
              <Col span={20}>
                {" "}
                <h4>
                  <b className="b2">{item}</b>{" "}
                </h4>
              </Col>
              <Col span={4}>
                <Button
                  className="primary-transparent br-8"
                  onClick={() => {
                    setAddActivityDetailsModalVisible(true);
                  }}
                >
                  View
                </Button>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row justify="space-between" align="middle">
        <h5
          className="text-primary cursor_pointer"
          onClick={() => {
            setAddActivityModalVisible(true);
          }}
        >
          <b className="b2">
            <img src={plusBlue}></img> Create new activity
          </b>
        </h5>
        {AddActivityModalVisible && (
          <AddActivityModal
            isModalVisible={AddActivityModalVisible}
            setModalVisible={setAddActivityModalVisible}
          />
        )}
        {AddActivityDetailsModalVisible && (
          <ActivityDetailsModal
            isModalVisible={AddActivityDetailsModalVisible}
            setModalVisible={setAddActivityDetailsModalVisible}
          />
        )}
        <Button className="stepper-button">Next</Button>
      </Row>
    </div>
  );
};

export default DefaultTab;
