/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { Space } from "antd";
import { Row } from "antd";
import { Table } from "antd";
import editblackPencil from "../../../assets/Images/editblackPencil.svg";
import deleteBlackIcon from "../../../assets/Images/deleteBlackIcon.svg";
import type { ColumnsType } from "antd/es/table/interface";
import AlertModal from "../../../helper/AlertModal";
import StatusDropdown from "../../../helper/StatusDropdown";
import OrganizerDataType from "../../../types/organizer";
import { setPaginationObject } from "../../../helper/utility";
import { history } from "../../../Redux/app-store";

const OrganizerTable = ({
  activeData,
  deleteRecord,
  setEditData,
  setSelectedRows,
  setParms,
  parmsData,
  status,
  hidCols,
  changeStatus,
  refetchOrganizers,
}: any) => {
  const [alertModal, setAlertModal] = useState(false);
  let initPaginationInfo = {
    total: activeData.count,
    current: parmsData.page,
    sort: {
      field: "firstname",
      order: "asc",
    },
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20"],
  };
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo);
  const [data, setData] = useState(activeData?.records);

  useEffect(() => {
    setData(activeData?.records);
  }, [activeData, setData]);

  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: activeData.count,
    });
  }, [activeData.count]);
  const [userId, setUserId] = useState<any>();
  //DATA.................................
  const alertModalData = {
    name: "organizer",
    heading: "Delete Organizer",
    message1: "Are you sure you want to delete this organizer?",
  };

  const OrganizersData = data?.map((item: OrganizerDataType) => {
    //defining the variable for the avatar image
  
    return {
      key: item.id,
      firstName: item.firstName,
      organizerAvatar: item.photo?.post,
      // organizationAvatar: item.organizations?.photo?.post,
      organizationAvatar: item.organization?.photo?.post,
      organizationName: item.organization?.name,
      lastName: item.lastName,
      // phone: item.phone,
      phone:item?.phone,
      email: item.email,
      status: item.status,
      organizationId: item.organization?._id,
      action: [
        <Space className="orgn-action-btn" key={item.id}>
          <img
            src={editblackPencil}
            alt="edit"
            width={20}
            onClick={() => {
              setEditData(item);
            }}
            className="cursor_pointer"
          />
          <img
            src={deleteBlackIcon}
            alt="delete"
            onClick={(e) => {
              setAlertModal(true);
              setUserId(item._id);
            }}
            className="cursor_pointer"
          />
        </Space>,
      ],
    };
  });

  //COLUMNS.................................

  const columnss: ColumnsType<OrganizerDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record: any, index) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: `/organizer-profile/${record.key}`,
              state: JSON.stringify(record.key),
            });
          }}
        >
          <Space size={8}>
            <Avatar size={24} src={record.organizerAvatar}>
              {record.organizerAvatar
                ? ""
                : record.firstName.charAt(0) + record.lastName.charAt(0)}
            </Avatar>
            {record.firstName + " " + record.lastName}
          </Space>
        </div>
      ),
      className: hidCols.name === false ? "display-none" : "",
    },
    {
      title: "Organizations",
      dataIndex: "organizations",
      width: 200,
      key: "organizations",
      render: (text, record: any, index) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: `/organization-profile/${record?.organizationId}`,
              state: record?.organizationId,
            });
          }}
        >
          <Space size={8}>
            <Avatar size={24} src={record.organizationAvatar}>
              {record.organizerAvatar
                ? ""
                : record.firstName.charAt(0) + record.lastName.charAt(0)}
            </Avatar>
            {record.organizationName}
          </Space>
        </div>
      ),

      className: hidCols.organizations === false ? "display-none" : "",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (text: any, record: any, index: any) => (
        <h5 className="font13">
          {/* <b className="b3">{` ${record.phone.dialCode} ${record.phone.number} `}</b>{" "} */}
          <b className="b3">{record?.phone ? `${record.phone.dialCode} ${record.phone.number}` : ' '}</b>{" "}

        </h5>
      ),
      className: hidCols.phone === false ? "display-none" : "",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
      className: hidCols.email === false ? "display-none" : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      className: hidCols.status === false ? "display-none" : "",
      render: (text, record: any) => (
        <StatusDropdown
          name="organizer"
          id={record.key}
          status={status}
          changeFn={changeStatus}
          refetchData={refetchOrganizers}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 120,
      className: hidCols.action === false ? "display-none" : "",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  const onTableChange = (...rest: any) => {
    const newObject: any = [...rest];
    let newPaginationInfo = setPaginationObject.apply(
      paginationInfo,
      newObject
    );
    setPaginationInfo(newPaginationInfo);
    getOrganizers(newPaginationInfo);
  };
  const getOrganizers = (newPaginationInfo: any) => {
    setParms({
      ...parmsData,
      page: newPaginationInfo.current,
      limit: 10,
      status: status,
    });
  };
  return (
    <div>
      <Row>
        <Table
          rowSelection={rowSelection}
          className="organizations_table"
          style={{ width: "100%" }}
          columns={columnss}
          dataSource={OrganizersData}
          rowKey="key"
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          onYesClick={() => {
            deleteRecord(userId);
          }}
        />
      )}
    </div>
  );
};

export default OrganizerTable;
