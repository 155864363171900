import { useEffect, useState } from 'react'
import AppLayout from 'components/layout/layout'
import { Typography, Row, Pagination } from 'antd'
import { Card } from 'antd'
import RequestMentor from 'components/modals/requestMentorModal'
import Header from './Header'
import { useQuery } from '@tanstack/react-query'
import { getRequestdJobs } from '../../api/JobRequests'
import Loader from 'helper/Loader'
import { JobRequestParams, Populate } from '../../types/globalTypes'
import usePagination from 'helper/usePagination'
import RequestCard from './Requestcard/index'
import { connect, useDispatch } from 'react-redux'
import actions from 'Redux/JobRequest/action'

const JobRequest = ({ rejected, showSuccess }: any) => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<JobRequestParams & Populate>({
    page: 1,
    limit: 10,
    populate: ['guardianRequester', 'events', 'organization', 'organizer'],
  })
  const { data, refetch, isLoading, isFetching } = useQuery(
    ['getJobRequest'],
    () => getRequestdJobs(params),
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [params])
  useEffect(() => {
    if (rejected) {
      refetch()
      dispatch({
        type: actions.SET_MENTOR_REQUEST_RESET,
      })
    }
  }, [rejected])
  useEffect(() => {
    if (showSuccess) {
      const timeoutId = setTimeout(() => {
        dispatch({ type: actions.OTHER_REQUEST_APPROVE_RESET })
      }, 3000)

      return () => clearTimeout(timeoutId)
    }
  }, [showSuccess])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentData, setCurrentData] = useState<any>([])
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams,
  })

  return (
    <AppLayout>
      <div>
        <div className="page-header" style={{ marginTop: '40px' }}>
          {showSuccess && (
            <div
              className="px-15px py-10px radius-12"
              style={{
                position: 'absolute',
                top: '90px',
                background: '#DFECAB',
                width: '75%',
                zIndex: '999',
              }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  style={{ position: 'absolute', right: '1.5rem', top: '12px' }}
                  onClick={() =>
                    dispatch({ type: actions.OTHER_REQUEST_APPROVE_RESET })
                  }
                >
                  X
                </div>
                <h3 className="text-14px b1">Request was approved</h3>
                <p>
                  We send a confrimation to the parent that request for Other
                  tutor was approved.
                </p>
              </div>
            </div>
          )}
          <Header
            setModalvisible={setIsModalVisible}
            setCurrent={setCurrentData}
            params={params}
            fullData={data?.records}
            setParmas={setParams}
          />

          <div className="mt-40">
            {isLoading || isFetching ? (
              <Loader />
            ) : (
              <>
                {data?.records.map((item: any, index: any) => {
                  return <RequestCard request={item} key={item} />
                })}
              </>
            )}

            {isModalVisible && (
              <RequestMentor
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            )}
          </div>

          <Row className="justify-end mt-20px">
            <Pagination
              className="pb-20"
              style={{ width: '100%', display: 'flex' }}
              {...paginationInfo}
              onChange={PaginationNoTabel}
            />
          </Row>
        </div>
      </div>
    </AppLayout>
  )
}

const mapStateToProps = (state: any) => ({
  rejected: state.jobRequest.mentorReject,
  showSuccess: state.jobRequest.approveRequest,
})

export default connect(mapStateToProps)(JobRequest)
