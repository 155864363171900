import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from '../../Redux/Allergies/action'
import { Avatar, Select, Checkbox, Row, Col, Space, Modal, Button } from 'antd'
import AddCustomAllergy from './AddCustomAllergy'
import { Plus } from '@phosphor-icons/react'
import Loader from 'helper/Loader'
import icon from 'assets/Images/otherCategory.svg'
import { Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
function AllergyList({
  loading,
  data,
  setAllergies,
  allergiesList,
  isModalOpen,
  setModalOpen,
}) {
  const dispatch = useDispatch()
  const [selectedAllergies, setSelectedAllergies] = useState<any>(data || [])
  const [customAllergyVisible, setCustomAllergyisible] =
    useState<boolean>(false)
  const [customAllergy, setCustomAllergy] = useState<any>([])

  const [editingAllergy, setEditingAllergy] = useState([])
  const allergiesWithoutIds = data?.filter(
    allergy => !allergy._id || !allergiesList?.some(a => a._id === allergy._id),
  )
  useEffect(() => {
    dispatch({
      type: actions.GET_ALLERGIES,
    })
  }, [])

  useEffect(() => {
    setCustomAllergy(allergiesWithoutIds)
  }, [])

  useEffect(() => {
    const selectedAllergiesIds = selectedAllergies.map(allergy => {
      return allergy?.name
    })
    setSelectedAllergies(selectedAllergiesIds)
  }, [])

  const onChange = (checkedValues: any) => {
    const formattedAllergies = allergiesList
      .filter(allergy => checkedValues.includes(allergy.name))
      .map(allergy => ({ _id: allergy._id, name: allergy.name }))

    setSelectedAllergies(checkedValues)
    const selectedCustomAllergy = customAllergy.filter(item =>
      checkedValues.includes(item?.name),
    )

    setAllergies([...formattedAllergies, ...selectedCustomAllergy])
  }

  const handleCancel = () => {
    setModalOpen(false)
    setEditingAllergy(null)
  }
  const handleEdit = (item, index) => {
    setEditingAllergy(item)

    setCustomAllergyisible(true)
  }
  const handleDelete = data => {
    const updatedCustomAllergy = customAllergy.filter(
      item => item._id !== data._id,
    )
    setCustomAllergy(updatedCustomAllergy)
    const updatedSelectedAllergies = selectedAllergies.filter(name => {
      const allergy = customAllergy.find(item => item._id === data._id)
      return allergy ? allergy.name !== name : true
    })
    setSelectedAllergies(updatedSelectedAllergies)
  }
  return (
    <Modal
      className="common_model"
      open={isModalOpen}
      onCancel={handleCancel}
      centered={true}
      width={600}
      footer={null}
    >
      <div className="px-45 py-30">
        <h3>Select Allergies</h3>
        <div>
          {loading || allergiesList?.length == 0 || !allergiesList ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '20rem',
              }}
            >
              <Loader />
            </div>
          ) : (
            <div>
              <div className="form_h_block mt-20">
                <Row gutter={[10, 20]}>
                  <Col span={24}>
                    <Checkbox.Group
                      onChange={onChange}
                      className="d-flex flex flex-column flex-wrap gap-10"
                      value={selectedAllergies}
                    >
                      {allergiesList?.map((allergy: any) => {
                        return (
                          <Checkbox
                            value={allergy.name}
                            key={allergy._id}
                            className="custom_allergies"
                          >
                            <Space size={12}>
                              <Avatar
                                size={18}
                                src={allergy?.icon}
                                className="ml-5"
                              ></Avatar>
                              <h4 className="b3 font16 line-h-16 text-color-black">
                                {allergy?.name}
                              </h4>
                            </Space>
                          </Checkbox>
                        )
                      })}

                      {/* {customAllergy.length > 0 &&
                        customAllergy.map(item => {
                          return (
                            <Checkbox
                              value={item?.name}
                              key={item?.name}
                              className="custom_allergies"
                            >
                              <Space size={12}>
                                <Avatar
                                  size={18}
                                  src={icon}
                                  className="ml-5"
                                ></Avatar>
                                <h4 className="b3 font16 line-h-16 text-color-black">
                                  {item?.name}
                                </h4>
                              </Space>
                            </Checkbox> */}
                      {customAllergy.length > 0 &&
                        customAllergy.map((item, index) => {
                          return (
                            <Checkbox
                              value={item?.name}
                              key={item?.name}
                              className="custom_allergies "
                            >
                              <div className="">
                                <Space size={12} className="">
                                  <Avatar
                                    size={18}
                                    src={icon}
                                    className="ml-5"
                                  ></Avatar>
                                  <h4 className=" b3 font16 line-h-16 text-color-black">
                                    {item?.name}
                                  </h4>
                                </Space>
                                <Space className="ml-15">
                                  <Button
                                    style={{ border: 'none' }}
                                    type="text"
                                    size="small"
                                    onClick={() => handleEdit(item, index)}
                                    icon={<EditOutlined />}
                                  />
                                  <Button
                                    style={{ border: 'none' }}
                                    type="text"
                                    size="small"
                                    onClick={() => handleDelete(item)}
                                    icon={<DeleteOutlined />}
                                  />
                                  {/* <Popconfirm
                                    title="Are you sure delete this allergy?"
                                    onConfirm={() => handleDelete(item)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      type="text"
                                      size="small"
                                      onConfirm={() => handleDelete(item)}
                                      icon={<DeleteOutlined />}
                                    />
                                  </Popconfirm> */}
                                </Space>
                              </div>
                            </Checkbox>
                          )
                        })}
                      {allergiesList?.length > 0 && (
                        <div
                          className="d-flex align-items-center gap-10 mt-10 cursot-pointer"
                          onClick={() => setCustomAllergyisible(true)}
                        >
                          <Plus
                            size={20}
                            height={20}
                            color="rgba(43, 167, 202, 1)"
                          />
                          <p className="text-color-orange">Add new</p>
                        </div>
                      )}
                    </Checkbox.Group>
                  </Col>
                </Row>
              </div>
              {allergiesList?.length > 0 && (
                <Row justify="space-between" align="bottom" className="mt-30 ">
                  <Button
                    className="flex justify-center align-center prim-btn pointer"
                    onClick={handleCancel}
                    style={{ textAlign: 'center' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="flex justify-center align-center prim-btn pointer"
                    onClick={handleCancel}
                    style={{ textAlign: 'center' }}
                  >
                    Next
                  </Button>
                </Row>
              )}
            </div>
          )}
        </div>
      </div>
      {customAllergyVisible && (
        <AddCustomAllergy
          modalVisible={customAllergyVisible}
          setModalVisible={setCustomAllergyisible}
          setCustomAllergy={setCustomAllergy}
          editingAllergy={editingAllergy}
          customAllergy={customAllergy}
          setEditingAllergy={setEditingAllergy}
        />
      )}
    </Modal>
  )
}

const mapStateToProps = (state: any) => ({
  allergiesList: state.allergies.allergiesList,
  loading: state.allergies.loading,
})
export default connect(mapStateToProps)(AllergyList)
