import AppLayout from 'components/layout/layout'
import PageHeader from '../Activity/Headers/PageHeaderActivity'
import { Row, Col, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import ActionHeader from './Headers/ActionHeader'
import ActivityTable from './Table/ActivityTable'
import Spinner from 'components/Spinner'
import { useQuery } from '@tanstack/react-query'
import { getActivity } from 'api/Activity'
const Activity = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [activeData, setActiveData] = useState<any[]>([])
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    name: true,
    category: true,
    organization: true,
    action: true,
  })
  const [parms, setParms] = useState<any>({
    page: 1,
    limit: 10,
    status: 'active',
  })
  const { isLoading, data, refetch } = useQuery(['getActivity', parms], () =>
    getActivity(parms),
  )
  const deleteMultipleRecords = () => {
    let _data = [...activeData]
    selectedRows.forEach((row: any) => {
      _data = data.filter(t => t.id !== row.key)
    })
    setActiveData(_data)
    // dispatch({
    //   type: actions.ADD_ORGANIZER,
    //   payload: _data,
    // })
  }
  const handleSearch = (query: string) => {
    setParms({
      ...parms,
      search: query,
    })
  }
  return (
    <AppLayout>
      <PageHeader data={data}></PageHeader>
      <Row>
        <Col style={{ width: '100%' }}>
          <ActionHeader
            setCols={setHiddenColumns}
            hidCols={hiddenColumns}
            search={handleSearch}
            parms={parms}
            setParms={setParms}
          />
        </Col>

        <Col style={{ alignItems: 'center', width: '100%' }}>
          {isLoading ? (
            <div
              className="flex justify-center aligin-center items-center "
              style={{ height: '80vh' }}
            >
              <Spinner />
            </div>
          ) : (
            <ActivityTable
              setSelectedRows={setSelectedRows}
              hidCols={hiddenColumns}
              data={data}
              params={parms}
              setParams={setParms}
              deleteMulti={deleteMultipleRecords}
            />
          )}
        </Col>
      </Row>
    </AppLayout>
  )
}
export default Activity
