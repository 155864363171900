import AppLayout from '../../../components/layout/layout'
import AvatarSvg from '../../../assets/Images/avatar.svg'
import Chrome from '../../../assets/Images/chrome.svg'
import Safari from '../../../assets/Images/safari.svg'
import Trash from '../../../assets/Images/Trash.png'
import './index.css'
import actions from '../../../Redux/Admin/action'
import ChangeName from './ChangeNamePopup'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import ChangePassword from './ChangePasswordPopup'
import ChangeEmail from './ChangeEmailPopup'
import ChangePhone from './ChangePhonePopup'
import Deactivate from './DeactivateAccount'
import UploadPhoto from './UploadNewPhotoPopup'
// interface adminDataiNT {
//   email: string;
//   firstName: string;
//   phone: {
//     dialCode: string;
//     isoCode: string;
//     number: string;
//   };
//   photo: {
//     post:string
//   };
// }
const AdminProfile = ({ adminData, errorState }: any) => {
  const location = useLocation()
  const [selectedPopup, setSelectedPopup] = useState<Number | null | false>(
    null,
  )
  const { uid, firebaseId }: any = location.state
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: actions.GET_ADMIN_DETAILS, payload: uid })
  }, [dispatch, uid, selectedPopup === false])

  return (
    <AppLayout>
      {selectedPopup === 1 ? (
        <UploadPhoto id={uid} notvisible={setSelectedPopup} />
      ) : (
        ''
      )}
      {selectedPopup === 2 ? (
        <ChangeName id={uid} notvisible={setSelectedPopup} />
      ) : (
        ''
      )}
      {selectedPopup === 3 ? (
        <ChangeEmail
          id={uid}
          notvisible={setSelectedPopup}
          currentEmail={adminData?.email}
        />
      ) : (
        ''
      )}
      {selectedPopup === 4 ? (
        <ChangePhone
          id={uid}
          notvisible={setSelectedPopup}
          currentPhone={
            '(' + adminData?.phone?.dialCode + ')' + adminData?.phone?.number
          }
        />
      ) : (
        ''
      )}
      {selectedPopup === 5 ? (
        <ChangePassword id={firebaseId} notvisible={setSelectedPopup} />
      ) : (
        ''
      )}
      {selectedPopup === 6 ? (
        <Deactivate id={firebaseId} notvisible={setSelectedPopup} />
      ) : (
        ''
      )}
      <div className="page-header main-height setting-main-page">
        <h1 className="mt-20 mb-20">General Settings</h1>
        <div className="bg-hr"></div>

        <div>
          <div className="d-flex justify-between align-items-center mt-10">
            <p className="smal-txt ">Photo</p>
            <div className="d-flex justify-between al">
              {adminData?.photo != null ? (
                <img
                  src={adminData?.photo.post}
                  alt="avatar"
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                />
              ) : (
                <img src={AvatarSvg} alt="avatar" />
              )}
              <h4
                className="linkEdit"
                onClick={() => {
                  setSelectedPopup(1)
                }}
              >
                Edit
              </h4>
            </div>
          </div>
          <div className="bg-hr"></div>
          <div className="d-flex justify-between align-items-center mt-10">
            <p className="smal-txt ">Name</p>
            <div className="d-flex justify-between alin-center">
              <h5 className="para">
                {adminData?.firstName} {adminData?.lastName}
              </h5>
              <h4
                className="linkEdit "
                onClick={() => {
                  setSelectedPopup(2)
                }}
              >
                Edit
              </h4>
            </div>
          </div>
          <div className="bg-hr"></div>
          <div className="d-flex justify-between align-items-center mt-10">
            <p className="smal-txt ">Email</p>
            <div className="d-flex justify-between alin-center">
              <h5 className="para">{adminData?.email} </h5>
              <h4
                className="linkEdit"
                onClick={() => {
                  setSelectedPopup(3)
                }}
              >
                Edit
              </h4>
            </div>
          </div>
          <div className="bg-hr"></div>
          <div className="d-flex justify-between align-items-center mt-10">
            <p className="smal-txt ">Phone number</p>
            <div className="d-flex justify-between alin-center">
              <h5 className="para">
                {'(' +
                  adminData?.phone?.dialCode +
                  ')' +
                  adminData?.phone?.number}
              </h5>
              <h4
                className="linkEdit"
                onClick={() => {
                  setSelectedPopup(4)
                }}
              >
                Edit
              </h4>
            </div>
          </div>
          <div className="bg-hr"></div>
        </div>
        <h1 className=" mt-40">Web browsers</h1>
        <span className="text-gray-color d-block font13">
          These browsers are currently signed in to your account.
        </span>
        <div className="tabel">
          <div className="header">
            <h4>Browser</h4>
            <h4>Location</h4> <h4>Most recent activity</h4>
          </div>
          <div className="list">
            {adminData?.sessions?.map((data: any) => {
              return (
                <div className="listItem">
                  <div className="device ">
                    {data.browserTitle === 'chrome' ? (
                      <div className="device ">
                        <img src={Chrome} alt="chrom" />
                        <h4 className="lighttext">Chrome on Mac OS X</h4>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <h4 className="lighttext">
                    {data?.location.title} {data?.location.city}{' '}
                    {data?.location.state}
                  </h4>
                  {data?.isCurrent ? (
                    <h4 className="lighttext">Current Session</h4>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}

            {/* <div className="listItem">
              <div className="device">
                <img src={Safari} alt="safari" />
                <h4 className="lighttext">Chrome on Mac OS X</h4>
              </div>
              <h4 className="lighttext">Mugla, Turkey</h4>

              <h4 className="lighttext">16 Feb 2023</h4>
              <img src={Trash} alt="safari" />
            </div> */}
          </div>
        </div>
        <h1 className=" mt-40 mb-20">Security</h1>
        <div className="bg-hr"></div>
        <div>
          <div className="d-flex justify-between align-items-center mt-10">
            <div className="mt-10">
              <p className="smal-txt ">Password</p>
              <span className="subtitle">
                Set a unique password to secure the account.
              </span>
            </div>

            <h4
              className="linkEdit"
              onClick={() => {
                setSelectedPopup(5)
              }}
            >
              Change password
            </h4>
          </div>
          <div className="bg-hr"></div>
          <div className="d-flex justify-between align-items-center mt-10 mb-20">
            <div className="mt-10">
              <p className="smal-txt ">Deactivation</p>
              <span className="subtitle">
                Remove access to all organizations and workspaces in Bamrec
                Admin app.
              </span>
            </div>
            <h4
              className="red pointer"
              onClick={() => {
                setSelectedPopup(6)
              }}
            >
              Deactivate account
            </h4>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  adminData: state.admin.adminDetails,
  errorState: state.admin.errorData,
})
export default connect(mapStateToProps)(AdminProfile)
