import React from 'react'
import { Empty } from 'antd'

const EmptyData: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="flex flex-col align-center justify-center">
      <Empty />
      <h3>{message}</h3>
    </div>
  )
}

export default EmptyData
