import { Elements } from '@stripe/react-stripe-js'
import notFound from 'assets/Images/account.png'
import { useEffect, useState } from 'react'
import CheckOutWithOutAccount from './CheckoutWithoutAccount'
import { loadStripe } from '@stripe/stripe-js'
import { useMutation } from '@tanstack/react-query'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

interface Account {
  amountToCharge: number
  email: string
  checkIn: string
  close: () => void
}
const AccountNotFound: React.FC<Account> = ({
  amountToCharge,
  email,
  checkIn,
  close,
}) => {
  const [checkOut, setCheckout] = useState(false)
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')
  const [clientSecret, setClientSecret] = useState()

  const options: any = {
    mode: 'payment',
    amount: amountToCharge,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  }

  return (
    <>
      {checkOut == false && (
        <div className="w-full text-center mt-40px mb-20px">
          <img src={notFound} alt="account not found" />
          <h2 className="text-20">We didn’t find your account</h2>
          <button className="prim-btn mt-20px w-full">
            Send email to download the app
          </button>
          <button
            className="bordered-prim-btn mt-20px w-full mb-40px"
            onClick={() => {
              setCheckout(true)
            }}
          >
            Check-out without account
          </button>
        </div>
      )}
      {checkOut && (
        <Elements stripe={stripePromise} options={options}>
          <CheckOutWithOutAccount
            clientSecret={clientSecret}
            amount={amountToCharge}
            mail={email}
            checkInId={checkIn}
            closeModal={close}
          />
        </Elements>
      )}
    </>
  )
}
export default AccountNotFound
