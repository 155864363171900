import { Suspense, useEffect, useState } from 'react'
import loginActions from './Redux/Auth/action'
import 'antd/dist/antd.css'
import { Spin } from 'antd'
import Routes from './router'
import Spinner from './components/Spinner'
import { history } from './Redux/app-store'
import useLoader from './helper/useLoader'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { firebaseConfig } from './Firebase'
import Loading from './components/loading/Loading'
import { useAppDispatch } from './Redux/hooks'

//require("dotenv").config();

function App() {
  const dispatch = useAppDispatch()
  const [isReady, setReady] = useState(false)
  const loading = useLoader()

  useEffect(() => {
    init(dispatch, setReady)
  }, [])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      let previousRoute = localStorage.getItem('currentRoute')
      localStorage.setItem('currentRoute', location.pathname)
      localStorage.setItem('previousRoute', previousRoute || '/')
    })
    return () => unlisten()
  }, [history])

  return isReady ? (
    <Suspense fallback={<Spinner />}>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <Routes history={history} />
    </Suspense>
  ) : (
    <Loading />
  )
}

const init = (dispatch: any, setReady: (isReady: boolean) => void) => {
  initializeApp(firebaseConfig)
  getAuth().onAuthStateChanged(user => {
    dispatch({
      type: user ? loginActions.LOGIN_SUCCESS : loginActions.LOGIN_ERROR,
      payload: user || undefined,
    })
    setReady(true)
  })
}

export default App
