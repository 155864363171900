import { Button, Dropdown, Menu, Space } from "antd";
import active from "../assets/Images/activeGreen.png";
import pendingImg from "../assets/Images/pending.svg";
import blocked from "../assets/Images/blocked.svg";
import requested from "../assets/Images/requested.png";
import uploaded from "../assets/Images/uploaded.png";
import { DownOutlined } from "@ant-design/icons";

const DocumentStatusDropDown = ({ status }: any) => {
  const menu = (
    <Menu>
      <Menu.Item key={"approved"}>
        <img alt="img" src={active}></img> Approved
      </Menu.Item>
      <Menu.Item key={"pending"}>
        <img alt="img" src={pendingImg}></img> Pending
      </Menu.Item>

      <Menu.Item key={"rejected"}>
        <img alt="img" src={blocked}></img> Rejected
      </Menu.Item>
      <Menu.Item key={"requested"}>
        <img alt="img" src={requested}></img> Requested
      </Menu.Item>
      <Menu.Item key={"uploaded"}>
        <img alt="img" src={uploaded}></img> Uploaded
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="flex align-center">
      {status !== "requested" ? (
        <span className="text-underline mr-20px text-14 prim-txt w-500">
          View
        </span>
      ) : (
        <></>
      )}
      <Dropdown overlay={menu}>
        <Button className="status_button">
          <Space>
            <img
              alt="img"
              src={
                status === "approved"
                  ? active
                  : status === "rejected"
                  ? blocked
                  : status === "pending"
                  ? pendingImg
                  : status === "requested"
                  ? requested
                  : status === "uploaded"
                  ? uploaded
                  : ""
              }
            ></img>
            {status === "pending"
              ? "Pending"
              : status === "approved"
              ? "Active"
              : status === "blocked"
              ? "Blocked"
              : status === "requested"
              ? "Requested"
              : status === "uploaded"
              ? "Uploaded"
              : ""}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};
export default DocumentStatusDropDown;
