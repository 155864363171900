import React, { useState, useEffect } from "react";
import { Row, Avatar, Button, Space } from "antd";
import { useHistory } from "react-router-dom";
import Math from "../../../assets/Images/Math.png";
import PencilButtonIcon from "../../../assets/Images/PencilButtonIcon.svg";
import LocationCircle from "../../../assets/Images/LocationCircle.svg";
import { Tabs } from "antd";
import { useDispatch, connect } from "react-redux";
import LiveUpdateModal from "../../Modals/EventsModal/EventDetails/LiveUpdate/LiveUpdateModal";
import AppLayout from "../../../components/layout/layout";
import SessionTab1 from "./SessionTab1";
import SessionSchedule from "./SessionSchedue";
import SessionActivity from "./SessionActivities";
import ActivityModal from "../../Modals/Sessions/Activity/ActivityModal";
import History from "./History";
import Chat from "./Chat";
import actions from "../../../Redux/Session/action";

const { TabPane } = Tabs;

const SessionDetail = ({ sessionDetails }: any) => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const [contractRoomId, setContractRoomId] = useState({});
  const [isLiveUpdatesModalVisible, setLiveUpdatesModalVisible] =
    useState(false);
  const [activityModalVisible, setActivityModalVisible] = useState(false);
  const [activeKey, setActiveKey] = React.useState("1");

  useEffect(() => {
    setContractRoomId(history.location.state.contractRoomId);
    dispatch({
      type: actions.GET_SESSION_DETAIL_DATA_REQUEST,
      payload: { _id: "635be145b302f160c001e40a" },
    });
  }, []);

  const onKeyChange = (key: any) => setActiveKey(key);
  return (
    <AppLayout>
      <div className="container">
        <Row
          justify="space-between"
          style={{ padding: "30px", paddingBottom: "24px" }}
        >
          <Space size={16}>
            <div>
              <Avatar src={Math}></Avatar>
            </div>
            <div>
              <h3>
                <b>Math tutor</b>
              </h3>
            </div>
          </Space>
          <Space size={10}>
            <Button
              className="secondary br-8"
              onClick={() => setLiveUpdatesModalVisible(true)}
            >
              <img alt="" src={LocationCircle} className="mr-10" /> Live updates
            </Button>
            <Button className="secondary br-8">
              <img alt="" src={PencilButtonIcon} className="mr-10" /> Edit
              session
            </Button>
            <Button
              className="btn-new primary br-8"
              onClick={() => {
                setActivityModalVisible(true);
              }}
            >
              Manage session{" "}
            </Button>
            {activityModalVisible && (
              <ActivityModal
                isModalVisible={activityModalVisible}
                setModalVisible={setActivityModalVisible}
                setDefaultTabKey={setActiveKey}
              />
            )}
          </Space>
          {isLiveUpdatesModalVisible && (
            <LiveUpdateModal
              contractRoomId={contractRoomId}
              isLiveUpdatesModalVisible={isLiveUpdatesModalVisible}
              setLiveUpdatesModalVisible={setLiveUpdatesModalVisible}
            />
          )}
        </Row>
        <Tabs
          defaultActiveKey={"1"}
          activeKey={activeKey}
          className="bg-white profile-tabs"
          onChange={onKeyChange}
        >
          <TabPane tab="Details" key="1">
            <SessionTab1 />
          </TabPane>
          <TabPane tab="Activity" key="2">
            <SessionActivity contractRoomId={contractRoomId} />
          </TabPane>
          <TabPane tab="Schedule" key="3">
            <SessionSchedule />
          </TabPane>
          <TabPane tab="History" key="4">
            <History />
          </TabPane>
          <TabPane tab="CHAT" key="5">
            <Chat />
          </TabPane>
        </Tabs>
      </div>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => ({
  sessionDetails: state.session.sessionDetails,
});
export default connect(mapStateToProps)(SessionDetail);
