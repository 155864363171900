import { Button, Col, Modal, Row, Space } from 'antd'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface AlertModalProps {
  isModalVisible: boolean
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  data: {
    name: string
    heading: string
    message1: string
  }
  onYesClick: () => void
  loading: boolean
}

const AlertDuplication: React.FC<AlertModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  data,
  onYesClick,
  loading,
}) => {
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleDelete = () => {
    onYesClick()
  }

  return (
    <>
      <Modal
        className="delete-modal"
        open={isModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        footer={null}
        centered={true}
        onCancel={handleCancel}
        width={438}
      >
        <Row gutter={[0, 40]} justify="center">
          <Col span={24}>
            <div className="text-center">
              <Space direction="vertical">
                <h3 className="b1">{data?.heading}</h3>
                <h5 className="b3">{data?.message1}</h5>
              </Space>
            </div>
          </Col>
          <Col>
            <div>
              <Space size={20}>
                <Button
                  onClick={handleCancel}
                  className="alert_delete_button bg-grey"
                >
                  Cancel
                </Button>
                <Button
                  className="alert_action_button text-white"
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  {loading ? (
                    <ButtonSpinner size={20} color="#000" />
                  ) : (
                    'Yes, please'
                  )}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default AlertDuplication
