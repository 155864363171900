import { create } from 'zustand'
import { StoredActivityType, inventoryStorageLocType } from 'types/events'
const eventCreationStore = (set: any) => ({
  stepCounter: 0,
  setStepCounter: (state: number) => set(() => ({ stepCounter: state })),

  selectedDate: undefined,
  setSelectedDate: (state: Date) => set(() => ({ selectedDate: state })),

  activity: undefined,
  setActivity: (state: StoredActivityType) => set(() => ({ activity: state })),

  customActivity: undefined,
  setCustomActivity: (state: any) => set(() => ({ customActivity: state })),

  storageLocation: null,
  setStorageLocation: (state: inventoryStorageLocType) =>
    set(() => ({ storageLocation: state })),

  eventData: null,
  setEventData: (state: any) => set(() => ({ eventData: state })),
  requiredDetails: null,
  setRequiredDetails: (state: any) => set(() => ({ requiredDetails: state })),
  step3Payload: {
    numOfStaff: 1,
    estimatedUnitStaffSalary: { min: 0, max: 0 },
    totalStaffSalary: { min: 0, max: 0 },
    venuePrice: { min: 0, max: 0 },
    estimatedTotalPrice: { min: 0, max: 0 },
    estimatedAvgTicketPrice: { min: 0, max: 0 },
  },

  setStep3Payload: (state: any) =>
    set((currentState: any) => ({
      step3Payload: { ...currentState.step3Payload, ...state },
    })),

  step5Payload: {},
  setStep5Payload: (state: any) => set(() => ({ step5Payload: state })),
})

export const creationEventStore = create(eventCreationStore)
