import React, { useState } from "react";
import { Modal, Button, Upload, Checkbox, Input, Card } from "antd";
import { Layout } from "antd";
import Calendar from "../../../assets/Images/Bcalendar.svg";
import Watch from "../../../assets/Images/watch.svg";
import Respect from "../../../assets/Images/respect.svg";
import PuzzleAvatar from "../../../assets/Images/puzzleAvatar.svg";
import Plus from "../../../assets/Images/plusBlue.svg";
import Books from "../../../assets/Images/books.svg";
import SimpleCal from "../../../assets/Images/sCalendar.svg";
import SimpleWatch from "../../../assets/Images/sWatch.svg";
import simpleRespect from "../../../assets/Images/sRespect.svg";
import TextArea from "antd/lib/input/TextArea";
const EventaggrimentModal = ({
  Visible,
  isModalVisibleEvent,
  setModalVisibleEvent,
  props,
}: any) => {
  const [isValue, isSetValue] = useState(true);
  const [isaggrement, setIsAggrement] = useState<any>([
    <div className="e-aggremant">
      <Checkbox defaultChecked></Checkbox>
      <Card className="e-card-icon">
        <img src={Calendar} />
      </Card>
      <TextArea
        placeholder="I agree to attend and be on time to the orientation / set up date"
        autoSize={{ minRows: 1.5, maxRows: 4 }}
      />
    </div>,
    <div className="e-aggremant">
      <Checkbox defaultChecked></Checkbox>
      <Card className="e-card-icon">
        <img src={Watch} />
      </Card>
      <TextArea
        placeholder="I agree to be on time at x:xx PM for this event"
        autoSize={{ minRows: 1.5, maxRows: 4 }}
      />
    </div>,
    <div className="e-aggremant">
      <Checkbox defaultChecked></Checkbox>
      <Card className="e-card-icon">
        <img src={Respect} />
      </Card>
      <TextArea
        placeholder="I agree to be respectful and uphold BR CHE standards"
        autoSize={{ minRows: 1.5, maxRows: 4 }}
      />
    </div>,
    <div className="e-aggremant">
      <Checkbox></Checkbox>
      <Card className="e-card-icon">
        <img src={PuzzleAvatar} />
      </Card>
      <TextArea
        placeholder="Enter statement"
        autoSize={{ minRows: 1.5, maxRows: 4 }}
      />
    </div>,
  ]);
  const addAggrement = () => {
    setIsAggrement([
      ...isaggrement,
      <div className="e-aggremant">
        <Checkbox></Checkbox>
        <Card className="e-card-icon">
          <img src={Books} />
        </Card>
        <TextArea
          placeholder="Enter statement"
          autoSize={{ minRows: 1.5, maxRows: 4 }}
        />
      </div>,
    ]);
  };
  const handleOk = () => {
    setModalVisibleEvent(false);
  };
  const handleCancel = () => {
    setModalVisibleEvent(false);
  };
  return (
    <Layout>
      <Modal
        title={isValue ? "Event Agrrement" : "Preview"}
        visible={isModalVisibleEvent}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        className="event-agreement-body"
      >
        {isValue ? (
          <div>
            <div>
              {isaggrement.map((item: any) => {
                return item;
              })}
            </div>
            <div className="e-add-cell d-flex">
              <div className="d-flex">
                <Button className="add-cell-btn" onClick={addAggrement}>
                  <img src={Plus} /> Add cell
                </Button>
              </div>
              <div className="e-app-prev">
                <Button className="bg-gray" onClick={() => isSetValue(false)}>
                  Preview
                </Button>
                <Button className="primary">Save</Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="event-agreement-p-header">
            <h1>Event agreement</h1>
            <div className="agreement-preview">
              <div>
                <Card className="e-card-icon">
                  <img src={SimpleCal} />
                </Card>
              </div>
              <div className="agreement-content-area">
                <div className="agreement-content-item">
                  I agree to attend and be on time to the orientation / set up
                  date
                </div>
                <div>
                  <Checkbox></Checkbox>
                </div>
              </div>
            </div>
            <div className="agreement-preview">
              <div>
                <Card className="e-card-icon">
                  <img src={SimpleWatch} />
                </Card>
              </div>
              <div className="agreement-content-area">
                <div className="agreement-content-item">
                  I agree to be on time at x:xx PM for this event
                </div>
                <div>
                  <Checkbox></Checkbox>
                </div>
              </div>
            </div>
            <div className="agreement-preview">
              <div>
                <Card className="e-card-icon">
                  <img src={simpleRespect} />
                </Card>
              </div>
              <div className="agreement-content-area">
                <div className="agreement-content-item">
                  I agree to be respectful and uphold BR CHE standards
                </div>
                <div>
                  <Checkbox></Checkbox>
                </div>
              </div>
            </div>
            <div className="e-add-cell d-flex justify-end">
              <div className="e-app-prev">
                <Button className="bg-gray" onClick={() => isSetValue(true)}>
                  Edit
                </Button>
                <Button className="primary">Save</Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </Layout>
  );
};
export default EventaggrimentModal;