// import React, { useState, useRef } from 'react'
// import { Dropdown, Checkbox } from 'antd'
// import Down from 'helper/Icons/DownIcon'
// import ReactCalendar from 'react-calendar'
// import '../../../../Events/Views/CreateEvent/Screens/GeneralDetails/styles/general-details.scss'
// import { ItemType } from 'antd/lib/menu/hooks/useItems'
// import moment from 'moment'

// export const FilterOption = ({ setParms, parms }) => {
//   const [open, setOpen] = useState(0)
//   const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
//   const [selectedDates, setSelectedDates] = useState<string[]>([]) // Store selected dates as strings (YYYY-MM-DD)
//   const dropdownRef = useRef<HTMLDivElement | null>(null)

//   const handleDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
//     e.stopPropagation()
//   }

//   const statusOptions = [
//     { label: 'Success', value: 'success' },
//     { label: 'Failed', value: 'failed' },
//   ]

//   const clearStatus = () => {
//     setSelectedStatuses([])
//   }

//   const handleStatusChange = (checkedValues: string[]) => {
//     setSelectedStatuses(checkedValues)
//   }

//   const handleTileClick = (date: Date) => {
//     const formattedDate = moment(date).format('YYYY-MM-DD')
//     if (!selectedDates.includes(formattedDate)) {
//       setSelectedDates([...selectedDates, formattedDate])
//     } else {
//       setSelectedDates(selectedDates.filter(d => d !== formattedDate))
//     }
//   }

//   const Status: ItemType[] = [
//     {
//       key: '0',
//       label: (
//         <div>
//           <Checkbox.Group
//             options={statusOptions}
//             className="status-checkbox"
//             value={selectedStatuses}
//             onChange={handleStatusChange}
//             style={{ display: 'flex', flexDirection: 'column' }}
//           />
//           <div className="flex justify-between mx-15px mt-10px">
//             <button className="clr-btn" onClick={clearStatus}>
//               Clear
//             </button>
//             <button
//               onClick={() => {
//                 setOpen(0)
//                 if (selectedStatuses.length > 0) {
//                   setParms(prevParms => ({
//                     ...prevParms,
//                     status: selectedStatuses,
//                   }))
//                 }
//               }}
//               className="aply-btn"
//             >
//               Apply
//             </button>
//           </div>
//         </div>
//       ),
//     },
//   ]

//   const clearDates = () => {
//     setSelectedDates([])
//   }

//   const applyDates = () => {
//     setOpen(0)
//     setParms(prevParms => ({
//       ...prevParms,
//       issueDates: selectedDates, // Update with selected dates
//     }))
//   }

//   const IssueDate: ItemType[] = [
//     {
//       key: '1',
//       label: (
//         <div className="event-days-calendar">
//           <ReactCalendar
//             minDate={new Date()}
//             onClickDay={handleTileClick}
//             tileClassName={({ date }) => {
//               const formattedDate = moment(date).format('YYYY-MM-DD')
//               return selectedDates.includes(formattedDate) ? 'selected' : ''
//             }}
//           />
//           <div className="flex justify-between mx-15px mt-10px">
//             <button className="clr-btn" onClick={clearDates}>
//               Clear
//             </button>
//             <button className="aply-btn" onClick={applyDates}>
//               Apply
//             </button>
//           </div>
//         </div>
//       ),
//     },
//   ]

//   return (
//     <div className="flex justify-between align-center flex-wrap">
//       <div
//         className="flex align-center flex-wrap mt-20px"
//         ref={dropdownRef}
//         onClick={handleDropdownClick}
//       >
//         <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
//         <Dropdown
//           menu={{ items: Status }}
//           placement="bottomLeft"
//           arrow={false}
//           className="mr-10"
//           open={open === 3}
//           overlayStyle={{ width: '250px' }}
//         >
//           <button
//             className={` ${
//               open === 3 ? 'filter-btn-click' : 'filter-btn'
//             } w-500`}
//             onClick={() => setOpen(open === 3 ? 0 : 3)}
//           >
//             Status <Down />
//           </button>
//         </Dropdown>
//         <Dropdown
//           menu={{ items: IssueDate }}
//           placement="bottomLeft"
//           arrow={false}
//           className="mr-10"
//           open={open === 4}
//           overlayStyle={{ width: '300px' }}
//         >
//           <button
//             className={` ${
//               open === 4 ? 'filter-btn-click' : 'filter-btn'
//             } w-500`}
//             onClick={() => setOpen(open === 4 ? 0 : 4)}
//           >
//             Issued date <Down />
//           </button>
//         </Dropdown>
//       </div>
//     </div>
//   )
// }

// export default FilterOption

import React, { useState, useRef } from 'react'
import { Dropdown, Checkbox, Radio } from 'antd'
import Down from 'helper/Icons/DownIcon'
import ReactCalendar from 'react-calendar'
import '../../../../Events/Views/CreateEvent/Screens/GeneralDetails/styles/general-details.scss'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import moment from 'moment'
import { ArrowUp } from '@phosphor-icons/react'

export const FilterOption = ({ setParms, parms }) => {
  const [open, setOpen] = useState(0)
  const [orderBy, setOrderBy] = useState('desc')
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [sortBy, setSortBy] = useState('')
  const [selectedDates, setSelectedDates] = useState([])
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const statusOptions = [
    { label: 'Success', value: 'success' },
    { label: 'Failed', value: 'failed' },
  ]

  const clearStatus = () => {
    setSelectedStatuses([])
  }

  const handleStatusChange = (checkedValues: string[]) => {
    setSelectedStatuses(checkedValues)
  }
  const clear = (item, setFn) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const handleTileClick = (date: Date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates.includes(formattedDate)) {
      setSelectedDates([...selectedDates, formattedDate])
    } else {
      setSelectedDates(selectedDates.filter(d => d !== formattedDate))
    }
  }

  const Status: ItemType[] = [
    {
      key: '0',
      label: (
        <div>
          <Radio.Group
            options={statusOptions}
            className="status-checkbox"
            value={selectedStatuses[0]}
            onChange={e => setSelectedStatuses([e.target.value])}
            style={{ display: 'flex', flexDirection: 'column' }}
          />
          <div className="flex justify-between mx-15px mt-10px">
            <button className="clr-btn" onClick={clearStatus}>
              Clear
            </button>
            <button
              onClick={() => {
                setOpen(0)
                if (selectedStatuses.length > 0) {
                  setParms(prevParms => ({
                    ...prevParms,
                    status: selectedStatuses,
                  }))
                }
              }}
              className="aply-btn"
            >
              Apply
            </button>
          </div>
        </div>
      ),
    },
  ]

  const clearDates = () => {
    setSelectedDates([])
  }

  const applyDates = () => {
    setOpen(0)
    const formattedDates = selectedDates.map(date =>
      moment(date).format('YYYY-MM-DDT00:00:00.000[Z]'),
    )
    setParms(prevParms => ({
      ...prevParms,
      issueDates: formattedDates,
    }))
    // setParms(prevParms => ({
    //   ...prevParms,
    //   issueDates: selectedDates.map(i => moment(i)),
    // }))
  }

  const isWeekday = (date: Date) => {
    const day = date.getDay()
    // 0 = Sunday, 6 = Saturday
    return day !== 0 && day !== 6
  }

  const IssueDate: ItemType[] = [
    {
      key: '1',
      label: (
        <div className="event-days-calendar">
          <ReactCalendar
            onClickDay={handleTileClick}
            tileClassName={({ date }) => {
              const formattedDate = moment(date).format('YYYY-MM-DD')
              return selectedDates.includes(formattedDate) ? 'selected' : ''
            }}
            tileDisabled={({ date }) => !isWeekday(date)}
          />
          <div className="flex justify-between mx-15px mt-10px">
            <button className="clr-btn" onClick={clearDates}>
              Clear
            </button>
            <button className="aply-btn" onClick={applyDates}>
              Apply
            </button>
          </div>
        </div>
      ),
    },
  ]
  const getSortByCheckBoxes = () => [
    {
      label: 'Created At',
      value: 'createdAt',
    },
    {
      label: 'Amount',
      value: 'amount',
    },
  ]

  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={getSortByCheckBoxes()}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={val => setSortBy(val.target.value)}
          />
          <div className="border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600">Sort Results</h2>
              <Radio.Group
                onChange={val => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                value={orderBy}
              >
                <Radio value="asc">Ascending</Radio>
                <Radio value="desc">Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('orderBy', setSortBy)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sortBy && orderBy
                ? setParms(prevParms => ({
                    ...prevParms,
                    sort: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
                : orderBy
                ? setParms(prevParms => ({
                    page: 1,
                  }))
                : setParms(prevParms => ({
                    sort: sortBy,
                    orderBy: orderBy,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  return (
    <div className="flex justify-between align-center flex-wrap">
      <div
        className="flex align-center flex-wrap mt-20px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
        <Dropdown
          menu={{ items: Status }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10"
          open={open === 3}
          overlayStyle={{ width: '250px' }}
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(open === 3 ? 0 : 3)}
          >
            Status <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{ items: IssueDate }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10"
          open={open === 4}
          overlayStyle={{ width: '350px' }}
        >
          <button
            className={` ${
              open === 4 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(open === 4 ? 0 : 4)}
          >
            Issued date <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: SortBy,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(5)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            Created At
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}

export default FilterOption
