import { Avatar, Modal } from 'antd'
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import Cancel from 'assets/Images/close.svg'
import DropInHeader from './Header'
import { convertToUtc, getNameSignature } from 'helper/utility'
import AddDropIn from './AddDropIn'
import { useQuery } from '@tanstack/react-query'
import { getEventDropInList, getEventPastDropIns } from 'api/Events'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Person } from 'types/globalTypes'
import Spinner from 'components/Spinner'
import Image from 'antd'
import chevron from 'assets/Images/chevron-right.svg'
import Loader from 'helper/Loader'
import CheckInOutModel from '../CheckInOutModel'
interface DropInProps {
  setOpen: Dispatch<SetStateAction<number>>
}

const DropIn: React.FC<DropInProps> = ({ setOpen }: DropInProps) => {
  const { id }: any = useParams()
  const [openModal, setModal] = useState(true)
  const [showDropIn, setAddDropIn] = useState(false)
  const today = moment().startOf('day')
  const [selectedDate, setSelectedDate] = useState<Date | any>(today)
  const [searchTerm, setSearchTerm] = useState('')
  const [listData, setListData] = useState<Person[] | undefined>([])
  const [isModalOpen, setIsModalopen] = useState<boolean>(false)

  const handleCancel = () => {
    setOpen(0)
    setModal(false)
  }
  const { isFetching, isLoading, data, refetch } = useQuery<Person[]>(
    ['getDropinList'],
    () => getEventDropInList(id, convertToUtc(today)),
    {
      enabled: false,
    },
  )
  const { data: PastData, refetch: PastRefetch } = useQuery<Person[]>(
    ['getPastDropin'],
    () => getEventPastDropIns(id, convertToUtc(selectedDate)),
    {
      enabled: false,
    },
  )
  useEffect(() => {
    const fetchData = async () => {
      await refetch()
      setListData(data)
    }

    fetchData()
  }, [data])

  useEffect(() => {
    if (selectedDate.valueOf() !== today.valueOf()) {
      PastRefetch()
      setListData(PastData)
    } else {
      refetch()
      setListData(data)
    }
  }, [selectedDate, setSelectedDate])
  const filteredData = listData?.filter(
    person =>
      person.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.lastName.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const [attender, setAtender] = useState<any>()

  const handleModalOpen = (person: any) => {
    person.isType = 'dropIn'
    setIsModalopen(true)
    setAtender(person)
  }
  return (
    <Modal open={openModal} onCancel={handleCancel} className="checkInModal">
      <div className="popup-header border-btm">
        <h2>Drop-in</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <DropInHeader
        searchval={searchTerm}
        setSearchVal={setSearchTerm}
        calendarDate={selectedDate}
        setCalenderDate={setSelectedDate}
      />
      <div className="h-50vh mt-20px">
        <div className="h-40vh overflow-auto">
          {isLoading || isFetching ? (
            <div className="flex-center justify-center h-50vh">
              <Loader />
            </div>
          ) : filteredData && filteredData.length > 0 ? (
            filteredData.map(person => (
              <>
                <div
                  className="flex-center mt-20px justify-between"
                  style={{ background: '#ebedf1', height: 80, padding: 5 }}
                  onClick={() => handleModalOpen(person)}
                >
                  <div className="d-flex align-item-center">
                    <Avatar size={40} src={person.photo?.thumb}>
                      {getNameSignature(person.firstName + person.lastName)}
                    </Avatar>
                    <h4 className="text-16px w-500 ml-20px mt-10px">
                      {person.firstName + ' ' + person.lastName}
                      <div style={{ color: 'gray' }}>{person.role}</div>
                    </h4>
                  </div>
                  <img
                    src={chevron}
                    width={20}
                    height={20}
                    alt="chevron"
                    className="cursor-pointer"
                  />
                </div>
              </>
            ))
          ) : null}
          {!isLoading && (!filteredData || filteredData.length === 0) && (
            <div className="flex-center justify-center h-50vh">
              <h4>No Data found</h4>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="bordered-prim-btn text-14"
            onClick={() => {
              setAddDropIn(true)
            }}
          >
            Add Drop-in attendee
          </button>
        </div>
        {showDropIn && (
          <AddDropIn
            open={showDropIn}
            closePrev={handleCancel}
            setOpen={setAddDropIn}
          />
        )}

        {isModalOpen && (
          <CheckInOutModel
            type="Drop-in"
            open={isModalOpen}
            setOpen={setIsModalopen}
            particpant={attender}
            dropInModel={handleCancel}
          />
        )}
      </div>
    </Modal>
  )
}
export default DropIn
