/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Col,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
  Form,
  Typography,
} from 'antd'
import phone from '../../../../../assets/Images/Phone.svg'
import envelopSimple from '../../../../../assets/Images/EnvelopeSimple.svg'
import location from '../../../../../assets/Images/MapPin.png'
import warning from '../../../../../assets/Images/Warning.png'
import { useEffect, useState } from 'react'
import { guardianProfile } from '../../../../../types/family'
import ChangeEmail from '../../../../../components/modals/changeEmail/changeEmail'
import { useMutation } from '@tanstack/react-query'
import { updateGuardianProfile, verifyEmail } from '../../../../../api/family'
import ChangePhone from '../../../../../components/modals/changePhone/changePhone'
import MapModal from '../../../../../helper/Map/MapModal'
import ChangeEmailModal from 'helper/Map/ChangeEmailModal'
import { confirmEmail } from 'api/Organizers'
import BlockEmailGuardian from 'helper/BlockEmailGuardian'
import Check from 'assets/Images/CheckCircle.svg'
import VerifiedEmail from 'helper/VerifiedEmail'
import { verifyPhone } from '../../../../../api/family'
import VerifiedPhone from 'helper/VerifiedPhone'
const { Text, Title } = Typography

interface guardianInfo {
  data: guardianProfile
  refetchData: () => any
}
const GuardianInfo = ({ data, refetchData }: guardianInfo) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [updatedEmail, setUpdatedEmail] = useState<string | null>(null)
  const [verifyModalVisible, setVerifyModalVisible] = useState(false)
  const [verifyPhoneModalVisible, setVerifyPhoneModalVisible] = useState(false)
  const [selectedPhoneToVerify, setSelectedPhoneToVerify] = useState<any>(null) // Selected phone to verify

  const [selectedEmailToVerify, setSelectedEmailToVerify] = useState<
    string | null
  >(null)

  const [form] = Form.useForm()
  const alertModalData = {
    heading: 'Block email',
    message1: 'Are you sure you want to block email',
    message2: `${updatedEmail || data?.email}`,
    okText: 'Yes, block email',
    closeIcon: false,
  }

  const [alertModalVisible, setAlertModalVisible] = useState(false)
  const handleCancel = () => {
    setModalVisible(false)
  }
  const changeMutationEmail = useMutation({
    mutationFn: async ({ id, payload }: any) => {
      return await confirmEmail(id, payload)
    },
    onSuccess: resp => {
      if (resp) {
        setUpdatedEmail(resp?.email)
        setModalVisible(false)
        form.resetFields()
      }
    },
  })
  const handleOk = (values: any) => {
    changeMutationEmail.mutate({
      id: data?.id,
      payload: {
        newEmail: values.email,
        userType: 'guardian',
      },
    })
  }
  const [mapData, setMapModalData] = useState({
    address: '',
  })
  const menu = (
    <Menu>
      <Menu.Item key="email" onClick={() => setModalVisible(true)}>
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space
          className="text-color-red"
          onClick={() => {
            setAlertModalVisible(true)
          }}
        >
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const changeMutation = useMutation({
    mutationFn: async ({ id, data }: any) => {
      return await updateGuardianProfile(id, data)
    },
  })
  const updateGuardianData = (arg: { [key: string]: string | {} }) => {
    changeMutation.mutate({ id: data?.id, data: arg })
    refetchData()
  }
  useEffect(() => {
    mapData.address !== '' ? updateGuardianData({ address: mapData }) : <></>
  }, [mapData])
  const handlePhoneVerificationSuccess = () => {
    refetchData()
  }
  const filteredAdditionalEmails = data?.additionalEmails.filter(
    (item: any) => item.email !== (updatedEmail || data?.email),
  )
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button>
          </Dropdown>
        </div>
        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        <img alt="alt" src={phone}></img>
                        <h4 className="b3">{data?.phone?.number}</h4>
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space size={12} className="contact-information">
                        {(updatedEmail || data?.email) && (
                          <>
                            <img alt="alt" src={envelopSimple} />
                            <h4 className="b3">
                              {updatedEmail || data?.email}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col span={12} className="text-right">
                      <Space className="b3 font14 text-color-black mt-20">
                        {data?.email ||
                        filteredAdditionalEmails.some(
                          (item: any) =>
                            item.email === (updatedEmail || data?.email) &&
                            item.verified,
                        ) ? (
                          <>
                            <img
                              src={Check}
                              alt="Check"
                              style={{ backgroundColor: '#ffffff' }}
                            />
                            <p className="green-txt-500">Verified</p>
                          </>
                        ) : (
                          <button
                            className="prim-sm-btn"
                            onClick={() => {
                              setSelectedEmailToVerify(
                                updatedEmail || data?.email,
                              )
                              setVerifyModalVisible(true)
                            }}
                          >
                            Verify
                          </button>
                        )}
                      </Space>
                    </Col>

                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* {data?.additionalEmails[0]?.email && (
          <>
            <div className=" align-center">
              <h2 className="b1 h6 mt-20">Additional Email</h2>
            </div>
            <div className=" align-center">
              {data?.additionalEmails.map((item: any, index: number) => (
                <Row key={index}>
                  <Col span={12}>
                    <Space className="b1 font14 text-color-black mt-20 contact-information">
                      <img alt="alt" src={envelopSimple} />
                      <h4 className="b3">{item.email}</h4>
                    </Space>
                  </Col>
                  <Col span={12} className="text-right">
                    <Space className="b3 font14 text-color-black mt-20">
                      {item?.verified ? (
                        <>
                          <img src={Check} alt="Check" />
                          <p className="green-txt-500">Verified</p>
                        </>
                      ) : (
                        <button
                          className="prim-sm-btn"
                          onClick={() => {
                            setSelectedEmailToVerify(item.email)
                            setVerifyModalVisible(true)
                          }}
                        >
                          Verify
                        </button>
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        )} */}

        {filteredAdditionalEmails.length > 0 && (
          <>
            <div className="align-center">
              <h2 className="b1 h6 mt-20">Additional Email</h2>
            </div>
            <div className="align-center">
              {filteredAdditionalEmails.map((item: any, index: number) => (
                <Row key={index}>
                  <Col span={12}>
                    <Space className="b1 font14 text-color-black mt-20 contact-information">
                      <img alt="alt" src={envelopSimple} />
                      <h4 className="b3">{item.email}</h4>
                    </Space>
                  </Col>
                  <Col span={12} className="text-right">
                    <Space className="b3 font14 text-color-black mt-20">
                      {item?.verified ? (
                        <>
                          <img src={Check} alt="Check" />
                          <p className="green-txt-500">Verified</p>
                        </>
                      ) : (
                        <button
                          className="prim-sm-btn"
                          onClick={() => {
                            setSelectedEmailToVerify(item.email)
                            setVerifyModalVisible(true)
                          }}
                        >
                          Verify
                        </button>
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        )}

        {data?.additionalPhoneNumbers[0]?.number && (
          <>
            <div className=" align-center">
              <h2 className="b1 h6 mt-20">Additional Phone</h2>
            </div>
            <div className=" align-center">
              {data?.additionalPhoneNumbers.map((item: any, index: number) => (
                <Row key={index}>
                  <Col span={12}>
                    <Space className="b1 font14 text-color-black mt-20 contact-information">
                      <img alt="alt" src={phone}></img>
                      <h4 className="b3">{item?.number}</h4>
                    </Space>
                  </Col>
                  <Col span={12} className="text-right">
                    <Space className="b3 font14 text-color-black mt-20">
                      {item?.verified ? (
                        <>
                          <img src={Check} alt="Check" />
                          <p className="green-txt-500">Verified</p>
                        </>
                      ) : (
                        <button
                          className="prim-sm-btn"
                          onClick={() => {
                            // Store the full phone data in the state (number, dialCode, isoCode)
                            setSelectedPhoneToVerify({
                              dialCode: item.dialCode,
                              isoCode: item.isoCode,
                              number: item.number,
                            })
                            setVerifyPhoneModalVisible(true) // Trigger phone verification modal
                          }}
                        >
                          Verify
                        </button>
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        )}
        <ChangeEmailModal
          visible={modalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          currentEmail={updatedEmail || data?.email}
          isLoading={changeMutation.isLoading}
          form={form}
        />
        {alertModalVisible && (
          <BlockEmailGuardian
            isModalVisible={alertModalVisible}
            setIsModalVisible={setAlertModalVisible}
            data={alertModalData}
          ></BlockEmailGuardian>
        )}
      </div>
      {modal === 3 && (
        <ChangePhone
          setModal={setModal}
          prevPhone={data?.phone?.number}
          changePhone={updateGuardianData}
        />
      )}
      {mapModalVisible && (
        <MapModal
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
        />
      )}{' '}
      <VerifiedEmail
        isModalVisible={verifyModalVisible}
        setIsModalVisible={setVerifyModalVisible}
        data={{
          heading: 'Verify email',
          message1: 'Are you sure you want to verify the email:',
          message2: selectedEmailToVerify,
          okText: 'Yes, verify email',
          closeIcon: true,
        }}
        loading={changeMutation.isLoading}
      />
      <VerifiedPhone
        isModalVisible={verifyPhoneModalVisible}
        setIsModalVisible={setVerifyPhoneModalVisible}
        data={{
          heading: 'Verify Phone',
          message1: 'Are you sure you want to verify the phone:',
          message2: selectedPhoneToVerify, // Pass the full object with dialCode, isoCode, and number
          okText: 'Yes, verify phone',
          closeIcon: true,
        }}
        loading={changeMutation.isLoading}
        refetchData={handlePhoneVerificationSuccess}
      />
    </Col>
  )
}
export default GuardianInfo
