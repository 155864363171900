import actions from "./action";

const initState = {
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case actions.CLEAR_ERROR_DATA: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }

    default:
      return state;
  }
};
