import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { axiosGet } from '../Redux/axiosHelper'
import { importActivity } from 'types/globalTypes'
import { useEffect } from 'react'
import { RequestParms } from 'types/globalTypes'
import { parseParams } from '../helper/utility'
import axios from 'axios'
import { BASE_URL, failureMessage, successMessage } from './global'
export const getFilterList = async () => {
  const response = await axiosGet('v1/admins/activities/filter-options')
  return response.data
}

export const getActivity = async (params: any) => {
  const pars = parseParams(params)
  const response = await axiosGet(`v1/admins/activities?${pars}`)
  return response.data
}
export const importActivityFile = async (payload: importActivity) => {
  try {
    const response = await axios.post(`${BASE_URL}v1/imports/activity`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
      // return response.data
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
    // return Promise.reject(error)
  }
}

export const confirmImportData = async payload => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/activity`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
      // return response.data
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
    // return Promise.reject(error)
  }
}
