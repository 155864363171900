import React from 'react'
import { Modal, Tabs } from 'antd'
import { Layout } from 'antd'
import actions from '../../../../../../../Redux/Events/action'
import { useDispatch } from 'react-redux'
import OrganizationLocationListing from './OrganizationLocationListing'
import ApprovedLocaitonLisitng from './ApprovedLocaitonLisitng'
import { Address } from 'types/globalTypes'

interface LocationListingModalProps {
  isModalVisible: boolean
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  organizationId: string
  activeKeyTab: string
  setDefaultTabKey: React.Dispatch<React.SetStateAction<string>>
  setMapModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setMapModalData: React.Dispatch<React.SetStateAction<Address>>
  setFieldsValue: any
  setIsFromPreviousLoc: React.Dispatch<React.SetStateAction<boolean>>
}

// todo type will be given after sometime
const LocationListingModal: React.FC<any> = ({
  isModalVisible,
  setModalVisible,
  setDefaultTabKey,
  organizationId,
  activeKeyTab,
  setMapModalVisible,
  setMapModalData,
  setFieldsValue,
  setIsFromPreviousLoc,
}) => {
  const handleOk = () => {
    // Visible(false)
    setModalVisible(false)
  }
  const dispatch = useDispatch()
  const { TabPane } = Tabs
  const onChange = (key: string) => {
    setDefaultTabKey(key)
  }

  return (
    <Layout>
      <Modal
        open={isModalVisible}
        className="map-modal overflow discount-code-details-modal"
        onOk={handleOk}
        onCancel={() => {
          setModalVisible(false)
        }}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="discountcode-details-modal-header">
          <h2 className="b1">Select Location</h2>
        </div>
        <Layout>
          <Tabs
            defaultActiveKey={activeKeyTab}
            onChange={onChange}
            className="select-activity-tab"
          >
            <TabPane tab="Organization Locations" key="1">
              <OrganizationLocationListing
                setModalVisible={setModalVisible}
                organizationId={organizationId}
                setMapModalVisible={setMapModalVisible}
                setMapModalData={setMapModalData}
                setFieldsValue={setFieldsValue}
                setIsFromPreviousLoc={setIsFromPreviousLoc}
              />
            </TabPane>
            <TabPane tab="Approved Locations" key="2">
              <ApprovedLocaitonLisitng
                setModalVisible={setModalVisible}
                organizationId={organizationId}
                setMapModalVisible={setMapModalVisible}
                setMapModalData={setMapModalData}
                setFieldsValue={setFieldsValue}
                setIsFromPreviousLoc={setIsFromPreviousLoc}
              />
            </TabPane>
          </Tabs>
        </Layout>
      </Modal>
    </Layout>
  )
}
export default LocationListingModal
