// import React from 'react'
// import { Layout, Pagination, Row } from 'antd'
// import ItemCard from './ItemCard'
// import usePagination from 'helper/usePagination'
// const { Content } = Layout

// const items = [
//   {
//     image: 'path/to/popcorn-machine.png',
//     title: 'Popcorn machine',
//     location: '445 Leigh Ave, Los Gatos',
//     price: '$29.99',
//     updated: '17 minutes ago',
//   },
//   {
//     image: 'path/to/balloons.png',
//     title: 'Balloons',
//     location: '445 Leigh Ave, Los Gatos',
//     price: '$29.99',
//     updated: '17 minutes ago',
//   },
// ]

// const InventoryList = ({ data, params, setParams }: any) => {
//   console.log(params, 'parms')
//   console.log(data, 'data')
//   console.log(setParams, 'set')
//   const [paginationInfo, PaginationNo] = usePagination({
//     totalCount: data?.count || 0,
//     currentPage: params.page,
//     setParams: setParams,
//   })

//   return (
//     <>
//       <Content style={{ padding: '24px' }}>
//         {data?.records.map((item, index) => (
//           <ItemCard key={index} item={item} />
//         ))}
//       </Content>
//       <Row className="justify-center flex mt-10px">
//         <Pagination
//           className="pb-20"
//           style={{
//             width: '100%',
//             display: 'flex',
//             marginLeft: '50px',
//             marginRight: '50px',
//           }}
//           {...paginationInfo}
//           onChange={PaginationNo}
//         />
//       </Row>
//     </>
//   )
// }
// export default InventoryList

import React from 'react'
import { Layout, Pagination, Row } from 'antd'
import ItemCard from './ItemCard'
import usePagination from 'helper/usePagination'

const { Content } = Layout

const InventoryList = ({ hidCols, data, params, setParams }: any) => {
  const [paginationInfo, PaginationNumber, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams: setParams,
  })
  return (
    <>
      <Content style={{ padding: '24px' }}>
        {data?.records.map((item, index) => (
          <ItemCard key={index} item={item} hidCols={hidCols} />
        ))}
      </Content>
      <Row className="justify-center flex mt-10px">
        <Pagination
          className="pb-20"
          style={{
            width: '100%',
            display: 'flex',
            marginLeft: '50px',
            marginRight: '50px',
          }}
          {...paginationInfo}
          onChange={onTableChange}
        />
      </Row>
    </>
  )
}

export default InventoryList
