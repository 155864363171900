import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPost, axiosPatch } from "../axiosHelper";
import failureAction from "../Failure/action";
/**
 * post News Post data
 *
 */

export function* addNewsPostRequest({ payload }) {
    try {
        const { data } = yield axiosPost(payload, `v1/organization-news/add-news`);
        yield put(actions.addNewsPostRequest());

    } catch (error) {}
}

export function* getAllNewsRequest({ params }) {
    try {
        let currentParams = {
            page: params?.current || 1,
            limit: params?.pageSize || 10,
            sort: params?.sort?.order || "desc",
            populate: '[{"path":"photo"}, {"path":"owner","populate":"photo"}]',
        };
        const { data } = yield axiosGet(`v1/organization-news/all-organizations` , currentParams);
        yield put(actions.getAllNewsSuccess(data));
    } catch (error) {

        yield put(actions.getAllNewsFailure());
        yield put(failureAction.failure(error));
    }
}


export function* getAllNewsOfOrganizationRequest({ payload, params }) {
    try {
        const organizationId = params.id;
        let currentParams = {
            page: params?.current || 1,
            limit: params?.pageSize || 10,
            sort: params?.sort?.order || "desc",
            populate: '[{"path":"photo"}, {"path":"owner","populate":"photo"}]',
        };
        const { data } = yield axiosGet(`v1/organization-news/by-organizationId/${organizationId}`,currentParams);
        yield put(actions.getAllNewsOfOrganizationSuccess(data));
    } catch (error) {
        yield put(actions.getAllNewsOfOrganizationFailure());
        yield put(failureAction.failure(error));
    }
}


export function* editNewsRequest({ payload }) {
    try {

        const newsId = payload.id;
        const { data } = yield axiosPatch(
            payload,
            `v1/organization-news/edit-news/${newsId}`
        );
        yield put({
            type: actions.EDIT_NEWS_DATA,
            newsId: payload.newsId,
        });
        yield put(actions.editNewsSuccess(data));
    } catch (error) {
        yield put(actions.editNewsFailure());
        yield put(failureAction.failure(error));
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(actions.ADD_NEWSFEED_DATA, addNewsPostRequest),
        takeLatest(actions.GET_ALL_NEWS, getAllNewsRequest),
        takeLatest(actions.GET_ALL_NEWS_OF_ORGANIZATION, getAllNewsOfOrganizationRequest),
        takeLatest(actions.EDIT_NEWS_DATA, editNewsRequest),
    ]);
}
