import React, { useState } from 'react'
import { Space, Radio, Row, Col, Button } from 'antd'
import { history } from '../../../Redux/app-store'
import Modal from 'antd/lib/modal/Modal'

const EventSaveandDraft = ({
  securityRiskLevel,
  setModalVisibleSaveandDraft,
}: any) => {
  return (
    <div>
      <Modal
        visible={true}
        className="map-modal add-more-fees-modal"
        footer={null}
        centered={true}
        width={500}
        onCancel={() => {
          setModalVisibleSaveandDraft(false)
        }}
      >
        <h2 className="b1 mb-20 text-center"></h2>How would you like to save
        this event?
        <Radio.Group value={securityRiskLevel}>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Draft</p>
                <p className="bg-gray">
                  Self check-in for family events + auto
                </p>
              </div>
              <Radio className="radio mr-0" />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Private</p>
                <p className="bg-gray">check-in for family events + auto</p>
              </div>

              <Radio className="radio mr-0" defaultChecked={false} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Following</p>
                <p className="bg-gray">check-in for family events + auto</p>
              </div>
              <Radio className="radio mr-0" defaultChecked={false} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Subscribed</p>
                <p className="bg-gray">Check-in + Check-out or signature </p>
              </div>
              <Radio className="radio mr-0" defaultChecked={false} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Mentor social</p>
                <p className="bg-gray">Check-in + Check-out or signature </p>
              </div>
              <Radio className="radio mr-0" defaultChecked={false} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-between align-items-center mt-20">
              <div>
                <p className="b2 font16">Public</p>
                <p className="bg-gray">Check-in + Check-out or signature </p>
              </div>
              <Radio className="radio mr-0" defaultChecked={false} />
            </Col>
          </Row>
        </Radio.Group>
        <div className="d-flex justify-center text-center mt-20">
          <Space size={30}>
            <Button className="btn-item bg-gray">Cancel</Button>
            <Button
              className="primary-btn"
              onClick={() => {
                history.goBack()
              }}
            >
              Publish
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  )
}

export default EventSaveandDraft
