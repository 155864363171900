import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Dropdown } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { GetFamily } from 'api/family'
import { X } from '@phosphor-icons/react'
import { DownOutlined } from '@ant-design/icons'
import { Person } from 'types/globalTypes'

interface TaggedMembersPropsType {
  familyId: string
  taggedMembers: Person[]
  setTaggedMembers: React.Dispatch<React.SetStateAction<Person[]>>
}

const TaggedMembers: React.FC<TaggedMembersPropsType> = ({
  familyId,
  taggedMembers,
  setTaggedMembers,
}) => {
  const [familyMembers, setFamilyMembers] = useState<Person[]>([])

  const handleRadioButtonClick = (member: Person) => {
    const isAdded = taggedMembers.find((taggedmember: Person) => {
      if (taggedmember._id === member._id) {
        return true
      }
    })

    if (isAdded) {
      const updatedMembers = taggedMembers.filter(
        (taggedmember: Person) => taggedmember._id !== member._id,
      )
      setTaggedMembers(updatedMembers)
    } else {
      setTaggedMembers([...taggedMembers, member])
    }
  }

  const handleRemoveTaggedMember = (memberId: string) => {
    const updatedMembers = taggedMembers.filter(
      (taggedmember: Person) => taggedmember._id !== memberId,
    )
    setTaggedMembers(updatedMembers)
  }

  const handleSelectAll = () => {
    if (familyMembers.length === taggedMembers.length) {
      setTaggedMembers([])
    } else {
      setTaggedMembers([...familyMembers])
    }
  }

  const items = [
    {
      key: 'select-all',
      label: (
        <div
          className="flex align-center justify-between"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleSelectAll()
          }}
        >
          <h3 className="text-16 w-400">Select All</h3>
          <div
            className={`tagged-menu-button ${
              familyMembers.length === taggedMembers.length
                ? 'selected-member'
                : ' '
            }`}
          ></div>
        </div>
      ),
    },
    // Family members
    ...(familyMembers &&
      familyMembers.map((member: Person, index: number) => {
        const isSelected = taggedMembers.some(
          (taggedmember: Person) => taggedmember._id === member._id,
        )
        return {
          key: index,
          label: (
            <div
              className="flex align-center justify-between"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handleRadioButtonClick(member)
              }}
            >
              <h3 className="text-16 w-400">
                {member?.firstName} {member?.lastName}
              </h3>
              <div
                className={`tagged-menu-button ${
                  isSelected ? 'selected-member' : ''
                }`}
              ></div>
            </div>
          ),
        }
      })),
  ]

  // calling api
  const {
    data: familyData,
    isLoading: familyDataLoading,
    refetch: familyDataRefetch,
  } = useQuery({
    queryKey: ['family-data'],
    queryFn: () => GetFamily(familyId),
    enabled: false,
  })

  useEffect(() => {
    familyDataRefetch()
  }, [familyId])

  useEffect(() => {
    if (familyData) {
      setFamilyMembers([
        ...familyData?.adults,
        ...familyData?.guardians,
        ...familyData?.kids,
      ])
    }
  }, [familyData])

  return (
    <>
      <Row>
        <Col>
          <label htmlFor="" className="custom-field-label">
            Tag People
          </label>
          <Form.Item name="tag-people" colon={false}>
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomLeft"
              trigger={['click']}
              className="dropdown-tagged-member"
              overlayStyle={{
                pointerEvents: 'auto',
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              <div
                className="d-flex px-20px py-10px radius-12 align-center justify-between border-1px"
                style={{ width: '100%', minHeight: '50px' }}
              >
                {taggedMembers.length === 0 ? (
                  <>
                    <h3 className="text-14 w-400 " style={{ color: '#939598' }}>
                      Select Child
                    </h3>
                    <DownOutlined />
                  </>
                ) : (
                  <div className="tagged-member-list">
                    {taggedMembers.map((member: Person) => {
                      return (
                        <div
                          key={member?._id}
                          className="slected-members flex align-center justify-center gap-10px"
                        >
                          <p>{member?.firstName}</p>
                          <X
                            size={20}
                            color="#000"
                            className="pointer"
                            onClick={() =>
                              handleRemoveTaggedMember(member?._id)
                            }
                          />
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </Dropdown>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default TaggedMembers
