import React, { useState } from 'react'
import IconsTextNavigation from 'components/global/IconsTextNavigation'
import FaqModal from '../../Modals/FAQ/FaqModal'
import { creationEventStore } from 'state/eventCreationStore'
import { EventStep5Payload } from 'types/eventStep5'

const FAQ: React.FC = () => {
  const [faqModalOpne, setFaqModalOpen] = useState<boolean>(false)
  const payload: EventStep5Payload = creationEventStore(
    store => store.step5Payload,
  )

  return (
    <>
      <div onClick={() => setFaqModalOpen(true)}>
        <IconsTextNavigation
          iconName="Question"
          heading="FAQ"
          details={payload?.faqIds ? payload.faqIds.length : 'No questions'}
        />
      </div>
      {faqModalOpne && (
        <FaqModal openModal={faqModalOpne} closeMdoal={setFaqModalOpen} />
      )}
    </>
  )
}

export default FAQ
