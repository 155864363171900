import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Col } from 'antd'
import { Menu, Space } from 'antd'
import { Row } from 'antd'
import { Table } from 'antd'
import { Avatar } from 'antd'
import editblackPencil from 'assets/Images/editblackPencil.svg'
import type { MenuProps } from 'antd'
import type { SorterResult } from 'antd/es/table/interface'
import CreateSession from '../../Modals/Sessions/CreateSession'
import { ApiResponse } from '../types'
import { RequestParms } from 'types/globalTypes'
import usePagination from 'helper/usePagination'
import { convertToLocal, getNameSignature } from 'helper/utility'
import { useQuery } from '@tanstack/react-query'
import { getSkillsList } from 'api/mentors'
interface Skill {
  _id: string
  name: string
  icon: string
  category: 'educational' | 'other'
}
interface table {
  data: ApiResponse | undefined
  params: RequestParms
  setParams: React.Dispatch<React.SetStateAction<RequestParms>>
}
function JobsTable({ data, params, setParams }: table) {
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams,
  })
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [sessionValues, setSessionValues] = useState({})
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    family: true,
    mentor: true,
    categoryskill: true,
    totalhours: true,
    firstsessiondate: true,
    upcomingsessiondate: true,
    action: true,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (!isModalVisible) setSessionValues({})
  }, [isModalVisible])

  const { data: skills, refetch } = useQuery<Skill[]>(
    ['getSkillList'],
    () => {
      return getSkillsList()
    },
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [])

  const columnss = [
    {
      title: 'Family',
      dataIndex: 'familyId',
      key: 'familyId',
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }}>
          <Space>{record.familyName}</Space>
        </div>
      ),
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'family' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.family === false ? 'display-none' : '',
    },
    {
      title: 'Mentor',
      dataIndex: 'mentor',
      key: 'mentorId',
      render: (text: any, record: any, index: any) => (
        <div className="flex">
          <Avatar src={text?.photo?.post} size={32}>
            {getNameSignature(text?.firstName)}
          </Avatar>

          <h2 className="ml-10px text-14">{text?.firstName}</h2>
        </div>
      ),
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'mentor' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.mentor === false ? 'display-none' : '',
    },
    {
      title: 'Category/skill',
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (text: any) => (
        <Button shape="round" size="small" className="secondary">
          {skills &&
            skills
              ?.filter((item: any) => item._id === text)
              .map((elm: any) => elm?.name)
              .toString()}
        </Button>
      ),
      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'categoryskill' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.categoryskill === false ? 'display-none' : '',
    },
    {
      title: 'total hrs',
      dataIndex: 'Hours',
      key: 'Hours',
    },

    {
      title: 'First session date',
      dataIndex: 'firstSessionDate',
      key: 'firstsesson',
      render: (text: any) => `${convertToLocal(text, 'DD MMM YYYY')}`,

      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'firstsessiondate' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.firstsessiondate === false ? 'display-none' : '',
    },
    {
      title: 'Upcoming session date ',
      dataIndex: 'upCommingSession',
      key: 'timesPerWeek',
      render: (text: any) =>
        text === 'none' || text === 'tbd'
          ? `${text}`
          : `${convertToLocal(text, 'DD MMM YYYY')}`,
      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'upcomingsessiondate'
          ? sortedInfo.order
          : null,
      hidden: true,
      className:
        hiddenColumns.upcomingsessiondate === false ? 'display-none' : '',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: any) => (
        <Space>
          <img
            src={editblackPencil}
            width={20}
            className="cursor_pointer"
            onClick={() => {
              setSessionValues(record)
              setIsModalVisible(true)
            }}
          />
        </Space>
      ),
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'action' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    }

    setHiddenColumns(updatedHiddenColumns)
    localStorage.setItem('SessionCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('SessionCols')
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns))
    }
  }, [])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // props.setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    console.log(e.key)
  }

  const menu2 = (
    <Menu
      onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.family}
                  name="family"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Family</Col>
            </Row>
          ),

          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.mentor}
                  name="mentor"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Mentor</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.categoryskill}
                  name="categoryskill"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Category Skill</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.totalhours}
                  name="hrs"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Hours</Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.firstsessiondate}
                  name="firstsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>First Session date</Col>
            </Row>
          ),
          key: '5',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.upcomingsessiondate}
                  name="upcomingsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Upcoming Session date</Col>
            </Row>
          ),
          key: '6',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: '7',
        },
      ]}
    />
  )

  return (
    <div className="mt-20">
      <Row align="middle">
        <Table
          rowSelection={rowSelection}
          style={{ width: '100%' }}
          className="organizations_table"
          // rowSelection={rowSelection}
          columns={columnss}
          dataSource={data?.records}
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
      {isModalVisible && (
        <CreateSession
          sessionValues={sessionValues}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </div>
  )
}

export default JobsTable
