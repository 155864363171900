import { Avatar, Col, DatePicker, Form, Modal, Row, Select, Space } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'
import Cancel from 'assets/Images/close.svg'
import userAvatarSvg from 'assets/Images/userAvatar.svg'
import FileUploader from 'helper/FileUploader'
import { useMutation } from '@tanstack/react-query'
import { createEventDropIn } from 'api/Events'
import { addDropInBody } from 'pages/Events/types'
import { convertToUtc } from 'helper/utility'
import { useParams } from 'react-router-dom'
import { successMessage } from 'api/global'
import { myStore } from 'state/eventStore'
import Loader from 'helper/Loader'
import actions from 'Redux/Events/action'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
interface AddDropInProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
  closePrev: () => void
}
const AddDropIn: React.FC<AddDropInProps> = ({ setOpen, open, closePrev }) => {
  const admin = localStorage.getItem('adminId')
  const { id }: any = useParams()
  const setTag = myStore(store => store.setTag)
  const dispatch = useDispatch()
  const handleCancel = () => {
    setOpen(false)
  }
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState()

  // const dropinMutation = useMutation({
  //   mutationFn: async (body: addDropInBody) => {
  //     return await createEventDropIn(body)
  //   },
  //   onSuccess: data => {
  //     dispatch({
  //       type: actions.GET_RECENT_CHECK_IN,
  //       payload: data,
  //     })

  //     successMessage('Dropin added successfully')
  //     setOpen(false)
  //     closePrev()
  //     setTag(true)
  //   },
  //   onError: (error: any) => {
  //     if (
  //       error.response &&
  //       error.response.status === 500 &&
  //       error.response.data.message === 'Error: Event has ended!'
  //     ) {
  //       notification.error({
  //         message: 'Error',
  //         description: 'Event has ended!',
  //       })
  //     } else {
  //       // Handle other errors
  //       console.error('Error:', error)
  //     }
  //   },
  // })

  const dropinMutation = useMutation({
    mutationFn: async (body: addDropInBody) => {
      return await createEventDropIn(body)
    },
    onError: (error: any) => {
      if (
        error.response &&
        error.response.status === 500 &&
        error.response.data.message === 'Error: Event has ended!'
      ) {
        notification.error({
          message: 'Error',
          description: 'Event has ended! zeeshan',
        })
      }
      if (
        error.response &&
        error.response.status === 500 &&
        error.response.data.message === 'Event is not started yet!'
      ) {
        notification.error({
          message: 'Error',
          description: 'Event is not started yet!',
        })
      }
    },
    onSuccess: data => {
      // alert('success')
      if (data !== undefined) {
        dispatch({
          type: actions.GET_RECENT_CHECK_IN,
          payload: data,
        })

        successMessage('Dropin added successfully')
        setOpen(false)
        closePrev()
        setTag(true)
      }
    },
  })

  const onFinish = (values: addDropInBody) => {
    if (values && admin) {
      dropinMutation.mutateAsync({
        adminId: admin,
        dob:
          values.dob !== undefined
            ? convertToUtc(values.dob, 'YYYY-MM-DD')
            : undefined,
        // dob: convertToUtc(values.dob, 'YYYY-MM-DD') ,
        eventId: id,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        grade: values.grade,
        type: values.type,
        photoId: values.photoId,
      })
    }
  }
  return (
    <Modal open={open} onCancel={handleCancel} className=" addDropIn-model ">
      <div className="model-height hidescroll">
        <div className="popup-header border-btm ">
          <h2>Add Drop-in attendee</h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <div className="pb-40px pt-20px">
          <Form form={form} onFinish={onFinish}>
            <Space size={[24, 0]} wrap className="mb-12">
              <Form.Item>
                <Avatar
                  size={80}
                  src={avatar ? avatar : userAvatarSvg}
                ></Avatar>
              </Form.Item>
              <Form.Item
                name="photoId"
                rules={[{ required: false, message: 'Photo is required' }]}
              >
                <FileUploader
                  setAvatar={setAvatar}
                  name="guardian"
                  form={form}
                  text={'Upload a child photo'}
                />
              </Form.Item>
            </Space>
            <div className="mt-20px">
              <Row gutter={24}>
                <Col span={12}>
                  <div className="inputGroup w-full">
                    <label htmlFor="" className="inp-label required-input">
                      First Name
                    </label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        { required: true, message: 'First Name is required' },
                      ]}
                    >
                      <input
                        placeholder="Enter first name"
                        name="firstname"
                        className="my-input  w-full"
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="inputGroup">
                    <label htmlFor="" className="inp-label required-input">
                      Last Name
                    </label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: 'Last Name is required' },
                      ]}
                    >
                      <input
                        placeholder="Enter last name"
                        className="my-input  w-full"
                        name="lastname"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mt-20px">
              <Row>
                <Col span={24}>
                  <div className="inputGroup ">
                    <label htmlFor="" className="inp-label required-input">
                      Gender
                    </label>
                    <Form.Item
                      name="gender"
                      rules={[
                        { required: true, message: 'Gender is required' },
                      ]}
                    >
                      <Select
                        defaultValue="Select"
                        className="selector"
                        options={[
                          {
                            value: 'male',
                            label: 'Male',
                          },
                          {
                            value: 'female',
                            label: 'Female',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mt-20px">
              <Row>
                <Col span={24}>
                  <div className="inputGroup ">
                    <label htmlFor="" className="inp-label ">
                      Date of Birth
                    </label>
                    <Form.Item
                      name="dob"
                      rules={[{ required: false, message: 'DOB is required' }]}
                    >
                      <DatePicker
                        className="date-picker  selector"
                        name="dob"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <Col span={24}>
              <div className="inputGroup mt-20px">
                <label htmlFor="" className="inp-label ">
                  Grade
                </label>
                <Form.Item
                  name="grade"
                  rules={[{ required: false, message: 'Grade is required' }]}
                >
                  <Select
                    defaultValue="Select"
                    className="selector"
                    options={[
                      {
                        value: 'None',
                        label: 'None',
                      },
                      {
                        value: 'Preschool',
                        label: 'Preschool',
                      },
                      {
                        value: 'Kindergarten',
                        label: 'Kindergarten',
                      },
                      {
                        value: 'Grade 1',
                        label: 'Grade 1',
                      },
                      {
                        value: 'Grade 2',
                        label: 'Grade 2',
                      },
                      {
                        value: 'Grade 3',
                        label: 'Grade 3',
                      },
                      {
                        value: 'Grade 4',
                        label: 'Grade 4',
                      },
                      {
                        value: 'Grade 5',
                        label: 'Grade 5',
                      },
                      {
                        value: 'Grade 6',
                        label: 'Grade 6',
                      },
                      {
                        value: 'Grade 7',
                        label: 'Grade 7',
                      },
                      {
                        value: 'Grade 8',
                        label: 'Grade 8',
                      },
                      {
                        value: 'Grade 9',
                        label: 'Grade 9',
                      },
                      {
                        value: 'Grade 10',
                        label: 'Grade 10',
                      },
                      {
                        value: 'Grade 11',
                        label: 'Grade 11',
                      },
                      {
                        value: 'Grade 12',
                        label: 'Grade 12',
                      },
                      {
                        value: 'College',
                        label: 'College',
                      },
                    ]}
                  />
                </Form.Item>
              </div>
            </Col>
            <div className="mt-20px">
              <Row>
                <Col span={24}>
                  <div className="inputGroup ">
                    <label htmlFor="" className="inp-label required-input">
                      Attendee type
                    </label>
                    <Form.Item
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Attendee type is required',
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Select"
                        className="selector"
                        options={[
                          {
                            value: 'kid',
                            label: 'Kid',
                          },

                          {
                            value: 'authorized-adult',
                            label: 'Authorized Adult',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <button
              className="mt-30px prim-btn w-full text-16 w-600"
              style={{ paddingTop: '16px', paddingBottom: '16px' }}
              type="submit"
            >
              {dropinMutation.isLoading ? <Loader /> : 'Check-in'}
            </button>
          </Form>
        </div>
      </div>
    </Modal>
  )
}
export default AddDropIn
