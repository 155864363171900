import { Avatar, Col, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import Clock from '../../assets/Images/Clock.svg'
import user1 from '../../assets/Images/user1.svg'
import user2 from '../../assets/Images/user2.svg'
import user3 from '../../assets/Images/user3.svg'
import actionIcon from '../../assets/Images/actionIcon.svg'

interface DataType {
  key: React.Key
  time: String
  age: number
}

const Calender = ({ currentDate }: any) => {
  const [data, setData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  let users = [
    {
      title: <img alt="" src={Clock}></img>,
      width: 30,
      dataIndex: 'time',
      key: 'time',
      fixed: 'left',
      render: (text: any) => (
        <h5 className="font12 ">
          <b className="b3">{text}</b>
        </h5>
      ),
    },
    {
      title: (
        <Row>
          <Col span={2}>
            <img alt="" src={actionIcon}></img>
          </Col>
          <Col span={3}>
            <img alt="" src={user1}></img>
          </Col>
          <Col span={12}>
            <h5>Ronald Richards</h5>
            <h5 className="subtitle">2 sessions</h5>
          </Col>
        </Row>
      ),
      width: 100,
      dataIndex: 'age',
      key: 'age',
      render: (text: any, record: any, index: any) => (
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          {record.user1.title ? (
            <div
              style={{
                backgroundColor: record.user1.bg,
                height: '100%',
                width: '100%',
              }}
            >
              <Row
                align="middle"
                gutter={[10, 10]}
                justify="start"
                className="card_table_calender_td"
              >
                <Col>
                  <h5>
                    <b className="b2"></b>
                    {record.user1.title}
                  </h5>{' '}
                </Col>
                <Col>{record.user1.timeRange}</Col>
                <Col span={2}>
                  <Avatar size={20} src={record.user1.avatar}></Avatar>
                </Col>
                <Col span={10}>{record.user1.tutor}</Col>
              </Row>
            </div>
          ) : (
            <div style={{ marginTop: '50px' }}>
              <h5 className="font13 text-secondary">
                <b className="b3">No sessions</b>{' '}
              </h5>
            </div>
          )}
        </div>
      ),
    },
    {
      title: (
        <Row>
          <Col span={2}>
            <img alt="" src={actionIcon}></img>
          </Col>
          <Col span={3}>
            <img alt="" src={user2}></img>
          </Col>
          <Col span={12}>
            <h5>Ronald Richards</h5>
            <h5 className="subtitle">2 sessions</h5>
          </Col>
        </Row>
      ),
      width: 100,
      dataIndex: 'user2',
      key: 'user2',
      render: () => (
        <div style={{ marginTop: '50px' }}>
          <h5 className="font13 text-secondary">
            <b className="b3">No sessions</b>{' '}
          </h5>
        </div>
      ),
    },
    {
      title: (
        <Row>
          <Col span={2}>
            <img alt="" src={actionIcon}></img>
          </Col>
          <Col span={3}>
            <img alt="" src={user3}></img>
          </Col>
          <Col span={12}>
            <h5>Ronald Richards</h5>
            <h5 className="subtitle">2 sessions</h5>
          </Col>
        </Row>
      ),
      width: 100,
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: (
        <Row>
          <Col span={2}>
            <img alt="" src={actionIcon}></img>
          </Col>
          <Col span={3}>
            <img alt="" src={user3}></img>
          </Col>
          <Col span={12}>
            <h5>Ronald Richards</h5>
            <h5 className="subtitle">2 sessions</h5>
          </Col>
        </Row>
      ),
      width: 100,
      dataIndex: 'age',
      key: 'age',
    },
  ]

  useEffect(() => {
    if (currentDate?.getDate() == 19) {
      setData(data1)
    } else {
      setData(data2)
    }
    setColumns(users)
  }, [currentDate])

  let mathTutuor1 = {
    title: 'Math tutor',
    timeRange: '11:00 am - 12:00 am',
    tutor: 'Brooklyn Simmons',
    avatar: <img alt="" src={user2}></img>,
    bg: '#EBEDF1',
  }

  let mathTutuor2 = {
    title: 'Math tutor',
    timeRange: '11:00 am - 12:00 am',
    tutor: 'Brooklyn Simmons',
    avatar: <img alt="" src={user2}></img>,
    bg: '#FDEAD2',
  }

  const data1: any[] = [
    {
      key: 1,
      time: '10 am',
      user1: mathTutuor1,
    },
    {
      key: 2,
      time: '11 am',
      user1: 'No sessions',
    },
    {
      key: 3,
      time: '12 pm',
      user1: 'no sessions',
    },
    {
      key: 4,
      time: '1 pm',
      user1: mathTutuor2,
    },
    {
      key: 5,
      time: '2 pm',
      user1: 'no sessions',
    },
    {
      key: 6,
      time: '3 pm',
      user1: 'math tutor',
    },
  ]

  const data2: any[] = [
    {
      key: 1,
      time: '10 am',
      user1: 'no sessions',
    },
    {
      key: 2,
      time: '11 am',
      user1: mathTutuor1,
    },
    {
      key: 3,
      time: '12 pm',
      user1: 'no sessions',
    },
    {
      key: 4,
      time: '1 pm',
      user1: mathTutuor2,
    },
    {
      key: 5,
      time: '2 pm',
      user1: 'no sessions',
    },
    {
      key: 6,
      time: '3 pm',
      user1: 'math tutor',
    },
  ]

  //   for (let i = 0; i < 100; i++) {
  //     data.push({
  //       key: i,
  //       time: 10,
  //       age: i,
  //       user1: `user${i}`,
  //     });
  //   }

  return (
    <Table
      columns={columns}
      bordered
      dataSource={data}
      scroll={{ x: 1500 }}
      sticky
      pagination={false}
      style={{ marginBottom: '30px' }}
    />
  )
}

export default Calender
