import { Modal, notification } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";
interface ChangeEmailProps {
  id: any;
  notvisible: any;
  currentEmail: String;
}
const ChangeEmail: React.FC<ChangeEmailProps> = ({
  id,
  notvisible,
  currentEmail,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [formData, setFormData] = useState({
    email: "",
    confirm: "",
  });
  const openNotification = () => {
    notification.open({
      message: "Email don't match",
      description: "Please double check your email",
    });
  };
  const dispatch = useDispatch();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onFinish = (event: any) => {
    event.preventDefault();
    formData.email === formData.confirm && formData.email !== ""
      ? dispatch({
          type: actions.PATCH_ADMIN_DETAILS,
          payload: {
            userId: id,
            formData: { email: `${formData.email}` },
          },
        })
      : openNotification();
    formData.email === formData.confirm && formData.email !== "" ? (
      notvisible(false)
    ) : (
      <></>
    );
  };
  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };
  return (
    <div className="changeModal">
      <Modal visible={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <div>
              <h2>Change your email</h2>
              <p className="subtitlePara">
                Your current email is {currentEmail}
              </p>
            </div>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <form onSubmit={onFinish}>
            <div className="inputGroup">
              <label className="inp-label">New email</label>
              <input
                type="email"
                name="email"
                className="my-input"
                placeholder="Enter your new email"
                onChange={handleInputChange}
                value={formData.email}
              />
            </div>
            <div className="inputGroup">
              <label className="inp-label">Confirm email</label>
              <input
                type="email"
                name="confirm"
                className="my-input"
                placeholder="Confirm your email"
                onChange={handleInputChange}
                value={formData.confirm}
              />
            </div>
            <div className="popup-footer">
              <button className="btn-sec" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-prim" type="submit">
                Change email
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
export default ChangeEmail;
