import { Button, Space, Dropdown, Menu } from 'antd'
import React from 'react'
import FilterHeaders from './FilterHeader'
import { DownOutlined } from '@ant-design/icons'
import { useState } from 'react'
import downloadImg from 'assets/Images/Download.svg'
import ExportRequest from './ExportRequest'
const Header = ({
  setModalvisible,
  setCurrent,
  params,
  fullData,
  setParmas,
}: any) => {
  const [isExportModalVisible, setExportModalVisible] = useState(false)

  const handleExportClick = () => {
    setExportModalVisible(true)
  }

  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="">
      <div className="d-flex justify-between">
        <h2>
          <b className="b2">Requests</b>
        </h2>
        <div>
          <Dropdown
            overlay={menu}
            placement="bottom"
            className="bg-grey mx-20px"
          >
            <button className=" prim-sm-btn" style={{ color: 'black' }}>
              More <DownOutlined />
            </button>
          </Dropdown>
          <Button
            type="primary"
            className="primary br-8"
            size="large"
            onClick={() => {
              setModalvisible(true)
              setCurrent([])
            }}
          >
            Request a mentor
          </Button>
        </div>
      </div>
      <FilterHeaders parmsData={params} setParms={setParmas} />
      <ExportRequest
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={fullData}
      />
    </div>
  )
}
export default Header
