import {
  capitalizeFirstLetter,
  convertMinutesToLocalTime,
  convertToLocal,
  daysOfWeek,
} from 'helper/utility'
import { MentorRequestResp } from '../types'
import reccurr from 'assets/Images/recc.svg'

interface request {
  request: MentorRequestResp
}
const ReccuringRequest: React.FC<request> = ({ request }) => {
  const session = request.recurringSessionSchedule

  const groupedSlots: any = {}
  for (const [day, slots] of Object.entries(session)) {
    if (day === 'startDate' || day === 'endDate' || slots.length === 0) {
      continue
    }

    for (const slot of slots) {
      const start = convertMinutesToLocalTime(slot.start)
      const end = convertMinutesToLocalTime(slot.end)
      const timeRange = `${start}-${end}`

      if (!(timeRange in groupedSlots)) {
        groupedSlots[timeRange] = [capitalizeFirstLetter(day.substring(0,3))]
      } else {
        groupedSlots[timeRange].push(capitalizeFirstLetter(day.substring(0,3)))
      }
    }
  }

  return (
    <div>
      <div className="flex-center">
        <img src={reccurr} alt="one time" />
        <h2 className="text-14 ml-10px">
          {convertToLocal(session.startDate, 'MMM DD, YYYY')}-
          {'  ' + convertToLocal(session.endDate, 'MMM DD, YYYY')}
        </h2>
      </div>
      {Object.entries(groupedSlots).map(([time, days]) => (
        <div key={time} className='mt-5px'>{`${days} (${time})`}</div>
      ))}
    </div>
  )
}
export default ReccuringRequest
