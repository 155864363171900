import React from "react";
import { Button, Checkbox, Col, Modal, Row } from "antd";
import { Layout } from "antd";
import ActivityDetail from "../../../../assets/Images/ActivityDetail.png";
import delete_red from "../../../../assets/Images/delete_red.svg";
import List from "../../../../assets/Images/List.svg";
import ActivityForm from "./ActivityForm";

const ActivityDetailsModal = ({
  Visible,
  isModalVisible,
  setModalVisible,
  setDefaultTabKey,
}: any) => {
  const handleOk = () => {
    Visible(false);
    setModalVisible(false);
  };
  return (
    <Layout>
      <Modal
        visible={isModalVisible}
        className="activities-details-modal"
        style={{ borderRadius: "24px" }}
        width={900}
        onOk={handleOk}
        onCancel={() => {
          setModalVisible(false);
        }}
        centered={true}
        footer={null}
      >
        <div className="model-header">
          <h4>Activity details</h4>
        </div>
        <div className="activities-details-leftside">
          <div className="activities-details-left-chiled">
            <h1>Human Knot</h1>
            <div className="d-flex">
              <span className="f-bold">Intended for Ages:</span>
              <span className="mr-10">All</span>
            </div>
            <div className="d-flex">
              <span className="f-bold">Time Allocation:</span>
              <span className="mr-10">30 min</span>
            </div>
            <div className="d-flex">
              <span className="f-bold">Type:</span>
              <span className="mr-10">indoor/outdoor</span>
            </div>
            <div className="d-flex">
              <span className="f-bold">Number of Kids:</span>
              <span className="mr-10">10 kids</span>
            </div>
            <div className="mt-20">
              <h4>Lesson Plan</h4>
            </div>
            <div>
              <span className="mt-10">
                Objective: This game for team building relies heavily on good
                communication and teamwork. It also results in a lot of great
                stories for the water cooler chat in the workplace.
              </span>
            </div>
            <div className="mt-20">
              <img src={ActivityDetail} className="w-100" />
            </div>
          </div>
          <div className="activities-details-right-chiled">
            <div>
              <span
                className="f-bold mb-16"
                style={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#000000",
                  lineHeight: "20px",
                }}
              >
                Supplies
              </span>
              <div className="d-flex supplies-details">
                <Checkbox /> Gloves
              </div>
              <div className="d-flex supplies-details">
                <Checkbox /> Rope
              </div>
            </div>
            <div>
              <div className="d-flex justify-between align-items-center mb-16">
                <span
                  style={{
                    fontSize: "13px",
                    lineHeight: "20px",
                    color: "#000000",
                    fontWeight: 600,
                  }}
                >
                  Assign groups
                </span>
                <Button
                  style={{
                    fontSize: "13px",
                    lineHeight: "14px",
                    backgroundColor: "#2BA7CA",
                    padding: "8px 16px",
                    borderRadius: "8px",
                    color: "#ffffff",
                    height: "auto",
                  }}
                >
                  + Add group
                </Button>
              </div>
              <div className="activities-group-list">
                <ul>
                  <li>
                    <span>Group 1</span>
                    <img src={delete_red} />
                  </li>
                  <li>
                    <span>Group 2</span>
                    <img src={delete_red} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-between align-items-center mb-16">
              <span
                style={{
                  fontSize: "13px",
                  lineHeight: "20px",
                  color: "#000000",
                  fontWeight: 600,
                }}
              >
                Assign tasks
              </span>
              <Button
                style={{
                  fontSize: "13px",
                  lineHeight: "14px",
                  backgroundColor: "#2BA7CA",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  height: "auto",
                }}
              >
                + Add group
              </Button>
            </div>
            <div>
              <div className="assign-tasks-list">
                <img src={List} />
                <span>
                  Clean up the table before check-in <img src={delete_red} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="add-activity-btn">
          <a href="">Edit activity</a>
          <Button>Add activity</Button>
        </div>
      </Modal>
    </Layout>
  );
};
export default ActivityDetailsModal;
