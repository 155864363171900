import { message, notification } from 'antd'
// export const BASE_URL = "https://bamrec-api.herokuapp.com/api/";
export const BASE_URL =
  'https://bamrec-development-api-42914a152b7a.herokuapp.com/api/'

export const successMessage = (message = 'Success') => {
  notification.success({
    message,
  })
}
export const failureMessage = (message = 'failure') => {
  notification.error({
    message,
  })
}
export const infoMessage = (message = 'info') => {
  notification.info({
    message,
  })
}
