import { Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { myStore } from '../../../state/eventStore'
import EventFilters from '../../../components/Events/Header/Flters'
import { creationEventStore } from 'state/eventCreationStore'
type ItemType = {
  label: string
  key: string
}

const EventHeader = ({ setParm, parms }: any) => {
  const history = useHistory()
  const changeView = myStore(store => store.changeView)
  const view = myStore(store => store.view)
  const setStepCounter = creationEventStore(store => store.setStepCounter)
  const setEventData = creationEventStore(store => store.setEventData)

  const items: ItemType[] = [
    {
      key: 'cardview',
      label: 'Card view',
    },
    {
      key: 'calendar',
      label: 'Calendar',
    },
    {
      key: 'tableview',

      label: 'Table view',
    },
  ]

  const handleNewEventClick = () => {
    setEventData(null)
    setStepCounter(0)
    history.push('/events/create-event')
  }

  const handleMenuItemClick = (label: any) => {
    changeView(label.key)
  }
  return (
    <div className="mt-30px">
      <div className="flex justify-between align-center">
        <h1 className="w-600">Events</h1>
        <div>
          <button className="borderd-btn">Help run Event</button>
          <Dropdown
            menu={{
              items,
              onClick: label => handleMenuItemClick(label),
            }}
            placement="bottom"
            className="mx-20px"
          >
            <button className="borderd-btn">
              {view} <DownOutlined />
            </button>
          </Dropdown>
          <Link to={'/events/create-event'}>
            <button className="prim-btn" onClick={handleNewEventClick}>
              New Event
            </button>
          </Link>
        </div>
      </div>
      <EventFilters setParms={setParm} parms={parms} />
    </div>
  )
}
export default EventHeader
