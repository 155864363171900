import { Checkbox, Dropdown, Radio, Select } from 'antd'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import {
  ArrowUp,
  CirclesThreePlus,
  MagnifyingGlass,
} from '@phosphor-icons/react'
import Down from 'helper/Icons/DownIcon'
import { useRef, useState } from 'react'
import { CloseDropeDown } from 'helper/CloseDropdown'
import useFilters from 'helper/Filters/useFilters'
import { useSkillsList } from 'api/Lists'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Spinner from 'components/Spinner'

const FilterHeaders = ({ parmsData, setParms }: any) => {
  const [open, setOpen] = useState(0)

  const handleSearchChange = (e: any) => {
    setParms({ ...parmsData, queryString: e })
  }
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })
  const debounced = debounce(handleSearchChange, 300)
  const [, , , , , SortDropDown] = useFilters({ open: open, setOpen: setOpen })

  const ProposeOptions = [
    { label: 'Proposed Mentor', value: true },
    { label: 'Not Proposed', value: false },
  ]
  const RequestOptions = [
    { key: '1', label: 'Parent', value: 'parent' },
    { key: '2', label: 'Organization', value: 'organization' },
    { key: '3', label: 'Admin', value: 'admin' },
    { key: '4', label: 'Organization-event', value: 'organization-event' },
  ]
  const onRequestChange = (val: any) => {
    setParms({ ...parmsData, page: 1, createdBy: val.target.value })
  }
  const onProposeChange = (val: any) => {
    setParms({ ...parmsData, page: 1, proposed: val.target.value })
  }
  const skillsQuery = useSkillsList()
  const checkboxOptions = skillsQuery.data
    ? skillsQuery.data.map(skill => ({
        label: (
          <div className="flex-center">
            {skill.icon !== '' ? (
              <img src={skill.icon} alt={skill.name} className="w-30px" />
            ) : (
              <CirclesThreePlus size={32} />
            )}
            <h4 className="text-14 ml-20px">{skill.name}</h4>
          </div>
        ),
        value: skill._id,
      }))
    : []

  const Category: ItemType[] = [
    skillsQuery !== undefined
      ? {
          key: '0',
          label: (
            <Checkbox.Group
              options={checkboxOptions}
              className="category-checkbox"
              // onChange={(checkedValues) => console.log('checkedValues', checkedValues)}
            />
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]

  const RequestSource = [
    {
      key: '1',
      label: (
        <Radio.Group
          options={RequestOptions}
          className="job-req"
          onChange={val => {
            onRequestChange(val)
          }}
        />
      ),
    },
  ]
  const Activity = [
    {
      key: '1',
      label: (
        <Radio.Group
          options={ProposeOptions}
          onChange={val => {
            onProposeChange(val)
          }}
          className="job-req"
        />
      ),
    },
  ]
  return (
    <div className="   mt-20px">
      <div className="flex-center  justify-between w-full">
        <div
          className="flex-center "
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <h3 className="text-16 mr-20px">Filter by:</h3>

          <div className="filter-btn">
            <MagnifyingGlass size={24} />
          </div>
          <Dropdown
            menu={{
              items: Category,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 2}
            overlayStyle={{ width: '250px' }}
          >
            <button
              className={` ${
                open === 2 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(2)}
            >
              Category <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: RequestSource,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 3}
            overlayStyle={{ width: '250px' }}
          >
            <button
              className={` ${
                open === 3 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(3)}
            >
              Request Source <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: Activity,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 4}
            overlayStyle={{ width: '250px' }}
          >
            <button
              className={` ${
                open === 4 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(4)}
            >
              Activity <Down />
            </button>
          </Dropdown>
        </div>
        <div>{SortDropDown}</div>
      </div>
    </div>
  )
}
export default FilterHeaders
