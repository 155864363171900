import React, { useEffect, useState } from "react";
import { Form, Button, Row, Input, Col, Avatar } from "antd";
import { Layout } from "antd";
import "antd-country-phone-input/dist/index.css";
import userAvatarSvg from "../../../../assets/Images/userAvatar.svg";
import rightArrowIcon from "../../../../assets/Images/rightarrowicon.svg";
import MapModal from "../../../../helper/Map/MapModal";
import FileUploader from "../../../../helper/FileUploader";
import PhoneField from "../../../../helper/PhoneField";

const Basicinfo = ({ data, onSuccess, handleBack }: any) => {
  const [form] = Form.useForm();
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [mapModalData, setMapModalData] = useState<any>(data?.address);
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    console.log("data:", data);
    if (data.address) {
      setMapModalData({
        street: data?.address?.street,
        city: data?.address?.city,
        state: data?.address?.state,
        zip: data?.address?.zip,
        country: data?.address?.country,
        coordinates: data?.address?.coordinates,
      });
    }
  }, []);

  var formSchema = {
    photoId: data?.photoId || "",
    name: data?.name || "",
    email: data?.email || "",
    phone: data?.phone || "",
    address: data?.address || "",
    isLandlinePhone: true,
    nonProfit: true,
  };

  const onFinish = (values: any) => {
    onSuccess({
      ...data,
      ...values,
      address: mapModalData,
    });
  };

  const setFieldsValue = (data: any) => {
    if (data?.address) form.setFieldsValue({ address: data?.address });
    else form.setFieldsValue({ allergies: data?.allergiesList });
  };

  return (
    <Layout>
      <Form
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        layout={"vertical"}
        initialValues={formSchema}
        autoComplete="off"
      >
        <div className="modal-h-element ">
          <Row gutter={24} align="middle">
            <Col span={4}>
              <Form.Item>
                <Avatar
                  size={80}
                  src={avatar ? avatar : userAvatarSvg}
                ></Avatar>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="photoId"
                rules={[{ required: true, message: "Photo is required" }]}
              >
                <FileUploader
                  setAvatar={setAvatar}
                  name="organization"
                  form={form}
                  fileId={data?.photoId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Name
              </label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  placeholder={
                    data.length > 0
                      ? data.organizationName
                      : "Organazations name"
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                Admin First Name
              </label>
              <Form.Item name="adminfirstname">
                <Input
                  placeholder={
                    data.length > 0 ? data.EnterFirstName : "Enter first name"
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                Admin Last Name
              </label>
              <Form.Item
                name="adminlastname"
                //  rules={[
                //    {
                //      required: true,
                //      message: "Please input your last name!",
                //    },
                //  ]}
              >
                <Input
                  placeholder={
                    data.length > 0 ? data.EnterLastName : "Enter last name"
                  }
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Organization Email
              </label>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input placeholder="example@gmail.com" name="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Phone number
              </label>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "A value must be entered",
                  },
                ]}
                className="phone-number-field"
              >
                <PhoneField
                  form={form}
                  data={data?.phone}
                  placeholder="(305) 1234 567"
                  className="cursor_pointer"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Location
              </label>
              <Form.Item
                name="address"
                rules={[{ required: true, message: "Location is required" }]}
              >
                <Input
                  placeholder="Location"
                  onClick={() => {
                    setMapModalVisible(true);
                  }}
                  value={
                    mapModalData
                      ? mapModalData.city + "," + mapModalData.state
                      : ""
                  }
                />
                {mapModalVisible && (
                  <MapModal
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    mapData={mapModalData}
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row justify="space-between" align="middle">
          {!data && <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} />
            </span>
            Back
          </h5>}
          <Button type="primary" className="stepper-button" htmlType="submit">
            Next
          </Button>
        </Row>
      </Form>
    </Layout>
  );
};

export default Basicinfo;
