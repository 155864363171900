import { Button, Col, Form, Input, Layout, message, Row, Space } from "antd";
import React, { useState } from "react";
import checker from "../../../../assets/Images/checker.svg";
import active_checker from "../../../../assets/Images/active_checker.svg";
import backarrow from "../../../../assets/Images/backarrow.svg";

const CreatePassword = ({ data, handleSubmit, handleBack }: any) => {
  const [conditions, setConditions] = useState({
    password: "",
    passwordLength: false,
    containsNumbers: false,
    isUpperCase: false,
  });

  const handleChange = (e: any) => {
    let targetValue = e.target.value.replace(/\s/g, "");
    let matches = targetValue.match(/\d+/g);
    let isUpperCaseMatches = targetValue.match(/[A-Z]/);

    setConditions({
      ...conditions,
      password: targetValue,
      passwordLength: targetValue.length > 7 ? true : false,
      containsNumbers: matches != null ? true : false,
      isUpperCase: isUpperCaseMatches != null ? true : false,
    });
  };

  const onFinish = (values: any) => {
    if (values.password2 !== conditions.password) {
      message.error("password is not matching");
    } else {
      handleSubmit({
        ...data,
        password: values.password2,
      });
    }
  };
  return (
    <Layout>
      <Form layout="vertical" onFinish={onFinish}>
        <div className="modal-h-element">
          <Row gutter={24} className="mb-40">
            <Col span={24}>
              <label htmlFor="" className="input-label secure-password-label">
                Please create a secure password.
              </label>
              <Form.Item>
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="New password"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="password2">
                <Input placeholder="Re-enter new password" />
              </Form.Item>
            </Col>

            <Col>
              <div className="w-100 d-flex gap-20">
                <div>
                  {conditions.passwordLength ? (
                    <img alt="" src={active_checker} className="mr-10"></img>
                  ) : (
                    <img alt="" src={checker} className="mr-10"></img>
                  )}
                  8 characters
                </div>
                <div>
                  {conditions.containsNumbers ? (
                    <img alt="" src={active_checker} className="mr-10"></img>
                  ) : (
                    <img alt="" src={checker} className="mr-10"></img>
                  )}
                  1 number
                </div>
                <div>
                  {conditions.isUpperCase ? (
                    <img alt="" src={active_checker} className="mr-10"></img>
                  ) : (
                    <img alt="" src={checker} className="mr-10"></img>
                  )}
                  1 capital letter
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row justify="space-between" align="bottom">
          <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
            <span className="mr-10 d-inline-block">
              <img src={backarrow} />
            </span>
            Back
          </h5>
          <Button className="stepper-button" htmlType="submit">
            Save
          </Button>
        </Row>
      </Form>
    </Layout>
  );
};

export default CreatePassword;
