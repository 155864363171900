import EventAnalyticsHeader from './Header'
import info from '../../../../assets/Images/Info.svg'
import up from '../../../../assets/Images/up.svg'
import down from '../../../../assets/Images/down.png'
import AgeOverview from './AgeOverview'
import { useQuery } from '@tanstack/react-query'
import { getEventAnalytics } from '../../../../api/Events'
import Spinner from '../../../../components/Spinner'
import PieChart from './PieChart'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GeneralBoard from './GeneralBoard'
import ParticipantsCalendar from './ParticipantsCalendar'
interface AnalyticTypes {
  eventId: string
}
interface TicketData {
  name: string
  count: number
}
export interface Tickets {
  id: any
  name: string
  color: string
  ticketsSold: number
  total: number
  price: number
  attendees: [userId?: any, type?: any]
  percentage: number
}
const EventAnalytics = ({event}) => {
  const { id }: any = useParams()
  const [params, setParams] = useState()
  const { data, isLoading } = useQuery(['getEventAnalytics', params], () =>
    getEventAnalytics(id, params),
  )
  const [Revenue, setRevenue] = useState(0)
  const [Revchart, setRevChart] = useState<TicketData[]>([])

  const totalRevenue = () => {
    let total = 0
    data?.tickets?.forEach((item: Tickets) => {
      total += item.total
    })
    setRevenue(total)
  }
  const chartData = () => {
    let arr: TicketData[] = []
    data?.tickets?.forEach((item: Tickets) => {
      arr.push({ name: item?.name, count: item.ticketsSold })
    })
    setRevChart(arr)
  }
  useEffect(() => {
    totalRevenue()
    chartData()
  }, [data])

  return (
    <div className="container bg-grey pt-10px ">
      <div className="bg-white pt-30px px-30px">
        <EventAnalyticsHeader updateParams={setParams} />
        <div className="grid grid-50 mt-15px">
          <div className="border-1px radius-6 px-15px py-12px ">
            <div className="flex justify-between align-center">
              <h1 className="text-16">Participants</h1>
              <img src={info} alt="info" />
            </div>
            <div className="mt-30px flex justify-between align-center">
              <h1 className="text-40 w-600">{data?.totalAttendees}</h1>
              <div className="w-fit flex flex-col align-end">
                <div
                  className="flex align-center  radius-4 px-8px py-10px w-fit"
                  style={{ backgroundColor: '#DBF7E0' }}
                >
                  <img src={up} alt="upword" className="pr-5px" />
                  <h4 className=" text-12 w-500">+7%</h4>
                </div>
                <p className="grey-txt text-12">previous period</p>
              </div>
            </div>
          </div>
          <div className="border-1px radius-6 px-15px py-12px ">
            <div className="flex justify-between align-center">
              <h1 className="text-16">Ticket purchase rate</h1>
              <img src={info} alt="info" />
            </div>
            <div className="mt-30px flex justify-between align-center">
              <h1 className="text-40 w-600">54%</h1>
              <div className="w-fit flex flex-col align-end">
                <div
                  className="flex align-center  radius-4 px-8px py-10px w-fit"
                  style={{ backgroundColor: '#FFCECC' }}
                >
                  <img src={down} alt="down" className="pr-5px" />
                  <h4 className=" text-12 w-500">-7pts</h4>
                </div>
                <p className="grey-txt text-12">previous period</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-50">
          <div className="border-1px radius-8 mt-30px p-15px">
            <div>
              <div className="flex align-center ">
                <h3 className="text-16">
                  Distribution of revenue per ticket type
                </h3>

                <img src={info} alt="info" className="pl-5px" />
              </div>
            </div>
            {data?.tickets?.length >= 0 ? (
              <div className="grid grid-50">
                <div className="border-1px radius-6 mt-20px p-30px text-center flex flex-col justify-center">
                  {data?.tickets?.map((item: Tickets, index: number) => {
                    return (
                      <div className="flex align-center justify-between">
                        <div className="flex align-center">
                          <div
                            style={{ backgroundColor: `${item.color}` }}
                            className="w-10px h-10px radius-12"
                          ></div>
                          <div className="flex-center">
                            <h4 className="text-14">{item?.name}</h4>
                            <h4 className="text-14 mr-10px ml-5px">
                              ({item.ticketsSold})
                            </h4>
                          </div>
                        </div>
                        <h4 className="w-600 text-14">
                          ${item?.price * item.ticketsSold}
                        </h4>
                      </div>
                    )
                  })}
                </div>
                <div className="h-14">
                  <PieChart
                    data={Revchart}
                    angle="count"
                    color="name"
                    name={`$${Revenue}`}
                  />
                </div>
              </div>
            ) : (
              <h4>There's not much here Check back later.</h4>
            )}
          </div>
          <div className="border-1px radius-8 mt-30px p-15px">
            <div>
              <div className="flex align-center ">
                <h3 className="text-16">Participants gender</h3>

                <img src={info} alt="info" className="pl-5px" />
              </div>
            </div>
            {data?.tickets?.length >= 0 ? (
              <div className="grid grid-50">
                <div className="border-1px radius-6 mt-20px p-30px text-center flex flex-col justify-center">
                  <div className="flex align-center ">
                    <div
                      style={{ backgroundColor: '#2459A8' }}
                      className="w-15px h-15px radius-12 mr-10px"
                    ></div>
                    <h4>Male</h4>
                  </div>
                  <div className="flex align-center mt-10px">
                    <div
                      style={{ backgroundColor: '#ABC5ED' }}
                      className="w-15px h-15px radius-12 mr-10px"
                    ></div>
                    <h4>Female</h4>
                  </div>
                </div>

                <div className="h-14">
                  <PieChart
                    data={[
                      {
                        type: 'Male',
                        value: data?.maleAttendees,
                      },
                      {
                        type: 'Female',

                        value: data?.femaleAttendees,
                      },
                    ]}
                    angle="value"
                    color="type"
                    name="Gender"
                  />
                </div>
              </div>
            ) : (
              <h4>There's not much here Check back later.</h4>
            )}
          </div>{' '}
        </div>
        <GeneralBoard tickets={data?.tickets} />
        <ParticipantsCalendar event={event} />

        {isLoading ? (
          <Spinner />
        ) : (
          <AgeOverview
            totalAgeGroup={data?.totalAgeGroup}
            maleGroup={data?.maleAgeGroup}
            femaleGroup={data?.femaleAgeGroup}
          />
        )}
      </div>
    </div>
  )
}
export default EventAnalytics
