import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet } from "../axiosHelper";

export function* getSkills() {
  try {
    const { data } = yield axiosGet(`v1/lists/skills`);
    yield put(actions.getSkillsSuccess(data));
  } catch (error) {
    yield put(actions.getSkillsFailure());
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_SKILLS, getSkills)]);
}
