import { Gauge } from "@ant-design/plots";
import { Button, Col, Progress, Row, Space } from "antd";

const DemoGauge = ({ setViewDetails }: any) => {
  // const config: any = {
  //   percent: 0.75,
  //   range: {
  //     color: "#8676E8",
  //     width: 18,
  //   },
  //   statistic: {
  //     content: {
  //       formatter: ({}) => (`
  //         <Space direction="vertical" style={{height:"100%"}}>
  //           <h5>Total Participation</h5>
  //           <h4>50:25</h4>
  //         </Space>`
  //       ),
  //       style: {
  //         color: "rgba(0,0,0,0.65)",
  //         fontSize: 18,
  //       },
  //     },
  //   },
  // };
  return (
    <Row
      justify="center"
      onClick={() => {
        setViewDetails(true);
      }}
    >
        <Progress
          type="dashboard"
          percent={75}
          strokeWidth={8}
          width={249}
          strokeColor="#8676E8"
          format={() => {
            return (
              <Space direction="vertical" style={{ height: "100%" }}>
                <h5 style={{marginTop:"70px"}}>Total Participation</h5>
                <h4 style={{fontSize:"42px"}}>50:25</h4>
                <Button shape="round" className="secondary" >
                  view Details
                </Button>
              </Space>
            );
          }}
        />
    </Row>
  );
};
export default DemoGauge;
