import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './assets/global.scss'
import './assets/styles.scss'
import './assets/css/global.css'
import './assets/css/styles.css'
import { store } from './Redux/app-store'
import './i18n'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const queryClient = new QueryClient()
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </Provider>
    </Elements>
  </QueryClientProvider>,

  document.getElementById('root'),
)

reportWebVitals()
