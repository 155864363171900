import { Modal, Form, notification } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import CountryPhoneCode from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { ConfigProvider } from "antd-country-phone-input";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";

interface ChangePhoneProps {
  id: string;
  notvisible: any;
  currentPhone: string;
}
const ChangePhone: React.FC<ChangePhoneProps> = ({
  id,
  notvisible,
  currentPhone,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [phone, setPhone] = useState<any>({});
  const dispatch = useDispatch();
  const openNotification = () => {
    notification.open({
      message: "Incorrect number",
      description: "Please double check your number",
    });
  };
  const onFinish = (event: any) => {
    event.preventDefault();
    phone.dialCode && phone.isoCode && phone.number !== ""
      ? dispatch({
          type: actions.PATCH_ADMIN_DETAILS,
          payload: {
            userId: id,
            formData: {
              phone: {
                dialCode: phone.dialCode,
                isoCode: phone.isoCode,
                number: phone.number,
              },
            },
          },
        })
      : openNotification();
    phone.dialCode && phone.isoCode && phone.number !== "" ? (
      notvisible(false)
    ) : (
      <></>
    );
  };
  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };
  return (
    <ConfigProvider locale={en}>
      <Modal visible={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <div>
              <h2>Change your phone number</h2>
              <p className="subtitlePara">
                Your current phone number {currentPhone}
              </p>
            </div>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <div className="inputGroup">
            <form onSubmit={onFinish}>
              <label className="">New phone number</label>
              <Form.Item className="mt-20">
                <CountryPhoneCode
                  onChange={(v) => {
                    setPhone({
                      dialCode: String(v.code),
                      number: v.phone,
                      isoCode: v.short,
                    });
                  }}
                />
              </Form.Item>
              <div className="popup-footer">
                <button className="btn-sec pointer" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="btn-prim pointer" type="submit">
                  Change phone number
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default ChangePhone;
