import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface SpinnerPropsType {
  color: '#000' | '#fff' | '#2ba7ca'
  size: number
}

const ButtonSpinner: React.FC<SpinnerPropsType> = ({ size, color }) => {
  return (
    <div>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: size,
              color: `${color}`,
            }}
            spin
          />
        }
      />
    </div>
  )
}

export default ButtonSpinner
