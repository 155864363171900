

const actions= {

    ADD_NEWSFEED_DATA:"ADD_NEWSFEED_DATA" ,
    ADD_NEWSFEED_DATA_SUCCESS:"ADD_NEWSFEED_DATA_SUCCESS" ,
    ADD_NEWSFEED_DATA_FAILURE:"ADD_NEWSFEED_DATA_FAILURE" ,

    GET_ALL_NEWS : "GET_ALL_NEWS",
    GET_ALL_NEWS_SUCCESS : "GET_ALL_NEW_SUCCESS",
    GET_ALL_NEWS_FAILURE : "GET_ALL_NEWS_FAILURE",
    GET_ALL_NEWS_OF_ORGANIZATION : "GET_ALL_NEWS_OF_ORGANIZATION",
    GET_ALL_NEWS_OF_ORGANIZATION_SUCCESS : "GET_ALL_NEWS_OF_ORGANIZATION_SUCCESS",
    GET_ALL_NEWS_OF_ORGANIZATION_FAILURE : "GET_ALL_NEWS_OF_ORGANIZATION_FAILURE",
    DELETE_NEWS_POST : "DELETE_NEWS_POST",
    EDIT_NEWS_DATA : "EDIT_NEWS_DATA",
    EDIT_NEWS_DATA_SUCCESS: "EDIT_NEWS_DATA_SUCCESS",
    EDIT_NEWS_DATA_FAILURE: "EDIT_NEWS_DATA_FAILURE",

    FAILURE:"FAILURE",


    addNewsPostRequest: (payload) => ({
        type: actions.ADD_NEWSFEED_DATA,
        payload,
    }),

    addNewsPostSuccess: (payload) => ({
        type: actions.ADD_NEWSFEED_DATA_SUCCESS,
        payload,
    }),

    addNewsPostFailure: () => ({
        type: actions.ADD_NEWSFEED_DATA_FAILURE,
    }),

    getAllNewsRequest: (params) => ({
        type: actions.GET_ALL_NEWS,
        params
    }),
    getAllNewsSuccess: (payload) => ({
        type: actions.GET_ALL_NEWS_SUCCESS,
        payload,
    }),

    getAllNewsFailure: () => ({
        type: actions.GET_ALL_NEWS_FAILURE,
    }),

    getAllNewsOfOrganizationRequest: (payload) => ({
        type: actions.GET_ALL_NEWS_OF_ORGANIZATION,
        payload
    }),

    getAllNewsOfOrganizationSuccess: (payload) => ({
        type: actions.GET_ALL_NEWS_OF_ORGANIZATION_SUCCESS,
        payload
    }),

    getAllNewsOfOrganizationFailure: () => ({
        type: actions.GET_ALL_NEWS_OF_ORGANIZATION_FAILURE,
    }),


    editNewsRequest : (payload)=> ({
        type : actions.EDIT_NEWS_DATA,
        payload,
    }),


    editNewsSuccess: (payload) => ({
        type: actions.EDIT_NEWS_DATA_SUCCESS,
        payload,
    }),

    editNewsFailure: () => ({
        type: actions.EDIT_NEWS_DATA_FAILURE,
    }),

    deleteNewsPostRequest: () => ({
        type: actions.DELETE_NEWS_POST,
    }),

    failure:()=>({
        type:actions.FAILURE
    })
};


export default actions
