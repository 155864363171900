import Spinner from '../components/Spinner'

const Loader = () => {
  return (
    <div className="flex justify-center align-center h-full">
      <Spinner />
    </div>
  )
}
export default Loader
