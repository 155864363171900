const actions = {
  ADD_ORGANIZATION_DATA: "ADD_ORGANIZATION_DATA",
  ADD_ORGANIZATION_DATA_SUCCESS: "ADD_ORGANIZATION_DATA_SUCCESS",
  ADD_ORGANIZATION_DATA_FAILURE: "ADD_ORGANIZATION_DATA_FAILURE",
  GET_ORGANIZATIONS: "GET_ORGANIZATIONS",
  GET_ORGANIZATIONS_SUCCESS: "GET_ORGANIZATIONS_SUCCESS",
  GET_ORGANIZATIONS_FAILURE: "GET_ORGANIZATIONS_FAILURE",
  GET_ORGANIZERS_OF_ORGANIZATION: "GET_ORGANIZERS_OF_ORGANIZATION",
  GET_ORGANIZERS_OF_ORGANIZATION_SUCCESS: "GET_ORGANIZERS_OF_ORGANIZATION_SUCCESS",
  GET_ORGANIZERS_OF_ORGANIZATION_FAILURE: "GET_ORGANIZERS_OF_ORGANIZATION_FAILURE",
  GET_ORGANIZATION: "GET_ORGANIZATION",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "GET_ORGANIZATION_FAILURE",
  UPDATE_ORGANIZATIONS_STATUS: "UPDATE_ORGANIZATIONS_STATUS",
  UPDATE_ORGANIZATIONS_STATUS_SUCCESS: "UPDATE_ORGANIZATIONS_STATUS_SUCCESS",
  UPDATE_ORGANIZATIONS_STATUS_FAILURE: "UPDATE_ORGANIZATIONS_STATUS_FAILURE",
  DELETE_ORGANIZATION_DATA: "DELETE_ORGANIZATION_DATA",
  CURRENT_ORGANIZATION_DATA: "CURRENT_ORGANIZATION_DATA",
  CURRENT_MENU: "CURRENT_MENU",
  MODAL_VISIBLE: "MODAL_VISIBLE",
  UPDATE_ORGANIZATION: "UPDATE_ORGANIZATION",
  UPDATE_ORGANIZATION_SUCCESS: "UPDATE_ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION_FAILURE: "UPDATE_ORGANIZATION_FAILURE",
  FAILURE:"FAILURE",
  // for the organization analytics
  GET_ORGANIZATION_ANALYTICS: "GET_ORGANIZATION_ANALYTICS",
  GET_ORGANIZATION_ANALYTICS_SUCCESS: "GET_ORGANIZATION_ANALYTICS_SUCCESS",
  GET_ORGANIZATION_ANALYTICS_FAILURE: "GET_ORGANIZATION_ANALYTICS_FAILURE",


  // new requests for deleting
  DELETE_ORGANIZATION_REQUEST: "DELETE_ORGANIZATION_REQUEST",
  DELETE_ORGANIZATION_SUCCESS: "DELETE_ORGANIZATION_SUCCESS",
  DELETE_ORGANIZATION_FAILURE: "DELETE_ORGANIZATION_FAILURE",
  UPDATE_UI_ORGANIZATION_DELETION: "UPDATE_UI_ORGANIZATION_DELETION",

  ORGANIZER_MODAL_VISIBLE: "ORGANIZER_MODAL_VISIBLE",
  UPDATE_PAGE_STATUSES: "UPDATE_PAGE_STATUSES",

  GET_ORGANIZATION_REVIEWS: "GET_ORGANIZATION_REVIEWS",
  GET_ORGANIZATION_REVIEWS_SUCCESS: "GET_ORGANIZATION_REVIEWS_SUCCESS",
  GET_ORGANIZATION_REVIEWS_FAILURE: "GET_ORGANIZATION_REVIEWS_FAILURE",



  modalVisible: (payload = false) => ({
    type: actions.MODAL_VISIBLE,
    payload,
  }),

  updatePageInfo: () => ({
    type: actions.UPDATE_PAGE_STATUSES,
  }),

  organizerModalVisible: (payload = false) => ({
    type: actions.ORGANIZER_MODAL_VISIBLE,
    payload,
  }),

  getOrganizationsRequest: (params) => ({
    type: actions.GET_ORGANIZATIONS,
    params
  }),

  getOrganizationsSuccess: (payload) => ({
    type: actions.GET_ORGANIZATIONS_SUCCESS,
    payload,
  }),

  getOrganizationsFailure: () => ({
    type: actions.GET_ORGANIZATIONS_FAILURE,
  }),

  addOrganizationRequest: (payload) => ({
    type: actions.ADD_ORGANIZATION_DATA,
    payload,
  }),

  addOrganizationSuccess: (payload) => ({
    type: actions.ADD_ORGANIZATION_DATA_SUCCESS,
    payload,
  }),

  addOrganizationFailure: () => ({
    type: actions.ADD_ORGANIZATION_DATA_FAILURE,
  }),

  updateOrganizationRequest: (payload, params) => ({
    type: actions.UPDATE_ORGANIZATION,
    payload,
    params,
  }),

  updateOrganizationSuccess: (payload) => ({
    type: actions.UPDATE_ORGANIZATION_SUCCESS,
    payload,
  }),

  updateOrganizationFailure: () => ({
    type: actions.UPDATE_ORGANIZATION_FAILURE,
  }),

  getOrganizersOfOrganizationRequest: (params, id) => ({
    type: actions.GET_ORGANIZERS_OF_ORGANIZATION,
    params,
    id
  }),

  getOrganizersOfOrganizationSuccess: (payload) => ({
    type: actions.GET_ORGANIZERS_OF_ORGANIZATION_SUCCESS,
    payload,
  }),

  getOrganizersOfOrganizationFailure: () => ({
    type: actions.GET_ORGANIZERS_OF_ORGANIZATION_FAILURE,
  }),

  getOrganization: (payload,id) => ({
    type: actions.GET_ORGANIZATION, payload, id
  }),

  getOrganizationSuccess: (payload) => ({
    type: actions.GET_ORGANIZATION_SUCCESS, payload,
  }),

  getOrganizationFailure: () => ({
    type: actions.GET_ORGANIZATION_FAILURE,
  }),

  updateOrganizationsStatus: (payload, params) => ({
    type: actions.UPDATE_ORGANIZATIONS_STATUS,
    payload,
    params,
  }),

  updateOrganizationsStatusSuccess: (payload) => ({
    type: actions.UPDATE_ORGANIZATIONS_STATUS_SUCCESS,
    payload,
  }),

  updateOrganizationsStatusFailure: () => ({
    type: actions.UPDATE_ORGANIZATIONS_STATUS_FAILURE,
  }),


  getOrganizationAnalytics: (payload,id) => ({
    type: actions.GET_ORGANIZATION_ANALYTICS, payload, id
  }),

  getOrganizationAnalyticsSuccess: (payload) => ({
    type: actions.GET_ORGANIZATION_ANALYTICS_SUCCESS, payload,
  }),

  getOrganizationAnalyticsFailure: () => ({
    type: actions.GET_ORGANIZATION_ANALYTICS_FAILURE,
  }),


  failure:()=>({
    type:actions.FAILURE
  }),

  deleteOrganizationSuccess: (payload) => ({
    type: actions.DELETE_ORGANIZATION_SUCCESS,
    payload,
  }),

  deleteOrganizationRequest: (payload) => ({
    type: actions.DELETE_ORGANIZATION_REQUEST,
  }),

  deleteOrganizationFailure: (payload) => ({
    type: actions.DELETE_ORGANIZATION_FAILURE,
    payload,
  }),

  updateUiDeletion: (payload) => ({
    type: actions.UPDATE_UI_ORGANIZATION_DELETION,
    payload,
  }),

  getOrganizationReviews: (payload,id) => ({
    type: actions.GET_ORGANIZATION_REVIEWS, payload, id
  }),

  getOrganizationReviewsSuccess: (payload) => ({
    type: actions.GET_ORGANIZATION_REVIEWS_SUCCESS, payload,
  }),

  getOrganizationReviewsFailure: () => ({
    type: actions.GET_ORGANIZATION_REVIEWS_FAILURE,
  }),

};

export default actions;
