import '../../../style.scss'
import Edit from '../../../../../assets/Images/edit.svg'
import { useQuery } from '@tanstack/react-query'
import { getFamilyCredits } from '../../../../../api/family'
import { useEffect, useState } from 'react'
import ManageCredits from './ManageCreditsPopup'
import { Col, Space, Button, Avatar } from 'antd'
import creaditIllustration from '../../../../../assets/Images/creadit_illustartion.svg'
import Spinner from '../../../../../components/Spinner'
import EditCredits from './EditCredits'
import GetAvatar from 'components/global/GetAvatar'
import { getUserName } from 'helper/utility'
const FamilyCredit = (props: any) => {
  const { isLoading, data, isFetching, refetch } = useQuery(
    ['familyCredit', props.familyId],
    () => getFamilyCredits(props.familyId),
    { enabled: false },
  )
  const [orgData, setOrgData] = useState<any>({ id: '', name: '', current: 0 })

  const [showModal, setShowModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  var prev: number = 0
  var val: number = 0
  const Intparser = (pdata: any) => {
    return parseInt(pdata?.toString(), 10)
  }
  // data?.map((d: any) => {
  //   val = Intparser(prev) + Intparser(d.credit)
  //   prev = val
  //   return val
  // })
  const credits = Array.isArray(data?.credits) ? data.credits : []

  credits.forEach((d: any) => {
    val = Intparser(prev) + Intparser(d.credit)
    prev = val
    return val
  })
  const editClick = (id: any, name: any, credit: any) => {
    setOrgData({ id: id, name: name, current: credit })
    setEditModal(true)
  }
  useEffect(() => {
    refetch()
  }, [])
  return (
    <div className="bg-white credit-page">
      {showModal ? (
        <ManageCredits
          closeModel={setShowModal}
          fId={props.familyId}
          currentCred={val}
          fetchAgain={refetch}
        />
      ) : (
        <></>
      )}
      {editModal ? (
        <EditCredits
          closeModel={setEditModal}
          fId={props.familyId}
          org={orgData}
          fetchAgain={refetch}
        />
      ) : (
        <></>
      )}
      {isLoading || isFetching ? (
        <div className="flex justify-center aligin-center">
          <Spinner />
        </div>
      ) : data?.length === 0 ? (
        <Col xs={{ span: 24 }} xl={{ span: 24 }}>
          <div className="profile_container">
            <Space
              align="center"
              direction="vertical"
              className="w-100 text-center"
            >
              <img alt="img" src={creaditIllustration} className="mb-38 "></img>
              <h3>
                <b className="b1 ">Add family credits</b>
              </h3>
              <h5 className="mb-25 mt-16">
                <b className="b3">
                  Who would you like to add the
                  <br></br> credits now?
                </b>
              </h5>
              <div>
                <Button
                  className="large_add_button w-325"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  Add credits
                </Button>
              </div>
            </Space>
          </div>
        </Col>
      ) : (
        <div>
          <div className="flex justify-between">
            <div className="justify-between">
              <h2 className="text-20 w-600">Credits</h2>
            </div>
            <button
              className="borderd-btn"
              onClick={() => {
                setShowModal(true)
              }}
            >
              Manage credits
            </button>
          </div>
          <div className="total flex justify-between">
            <h2 className="w-600 tex-16">Total Amount </h2>
            <h2 className="text-20 w-600">
              USD {val}
              {'.00'}
            </h2>
          </div>
          {credits?.map((orgData: any) => {
            return (
              <div className="grid-50 align-center  mt-30">
                <div className="flex align-center">
                  <GetAvatar person={orgData?.organization} />
                  <h4 className="text-16 w-400 ml-10px">
                    {getUserName(orgData.organization)}
                  </h4>
                </div>
                <div className="flex justify-end">
                  <h4 className="text-16 w-600 mr-20">USD {orgData.credit}</h4>
                  {/* <div
                    className="flex pointer"
                    onClick={() =>
                      editClick(
                        orgData?.organization?._id,
                        orgData?.organization?.name,
                        orgData?.credit,
                      )
                    }
                  >
                    <img src={Edit} alt="edit" className="pr-10px w-30px" />
                    <h4 className="text-edit text-16 w-600">Edit</h4>
                  </div> */}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
export default FamilyCredit
