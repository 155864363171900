import { parseParams } from '../helper/utility'
import { axiosGet } from '../Redux/axiosHelper'
import axios from 'axios'
import { BASE_URL, failureMessage, successMessage } from './global'
export const getNews = async (params: any) => {
  const parsedParams = parseParams(params)
  const response = await axiosGet(
    `v1/organization-news/all-organizations?${parsedParams}`,
  )
  return response.data
}
export const removeReactionFromNews = async (newsId: string, payload?: any) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/v1/organization-news/remove-reaction/${newsId}`,
      {
        data: payload,
      },
    )
    if (response.data && response.status === 200) {
      successMessage('Like removed')
    }
    return response.data
  } catch (error) {
    failureMessage(error.message)
    throw error
  }
}
export const importNewsFile = async (payload: any) => {
  try {
    const response = await axios.post(`${BASE_URL}v1/imports/news`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const confirmImportData = async payload => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/news`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
  }
}
export const unpublishNews = async (newsId: string, payload: string) => {
  try {
    const response = await axios.patch(
      `${BASE_URL}v1/organization-news/edit-news/${newsId}`,
      payload,
    )
    if (response.status === 200) {
      successMessage('Unpublish Successfully !')
      return response.data
    } else {
      failureMessage(response?.data?.message || 'Unpublish error occurred')
      return Promise.reject(response.data)
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'Unpublish error occurred')
    } else {
      failureMessage('Unpublish error occurred')
    }
  }
}
