import { Col, Layout, Row, Button, Tabs } from "antd";
import { useState } from "react";
import rightArrowIcon from "../../../../../assets/Images/rightarrowicon.svg";
import { useQuery } from "@tanstack/react-query";
import { getOrganizations } from "../../../../../api/Organizations";
import Listing from "./orgListing";

const AssignOrganization = ({ data, handleBack, handleSubmit }: any) => {
  const [parms, setParms] = useState({
    page: 1,
    status: "approved",
    limit: 10,
    searchQuery: "",
  });
  const { isLoading, data: OrganizationData } = useQuery(
    ["getOrganization", parms],
    () => getOrganizations(parms)
  );
  const [selectedOrganization, setSelectedOrganization] = useState();
  const onsubmit = (data: any) => {
    handleSubmit(data);
  };

  const items = [
    {
      key: "1",
      label: "Active",
      children: (
        <Listing
          setParms={setParms}
          parms={parms}
          data={OrganizationData}
          isLoading={isLoading}
          setClickedData={setSelectedOrganization}
        />
      ),
    },
    {
      key: "2",
      label: "Pending",
      children: (
        <Listing
          setParms={setParms}
          parms={parms}
          data={OrganizationData}
          isLoading={isLoading}
          setClickedData={setSelectedOrganization}
        />
      ),
    },
  ];
  const TabChange = (key: string) => {
    if (key === "1") {
      setParms({ ...parms, status: "approved" });
    }
    if (key === "2") {
      setParms({ ...parms, status: "under_review" });
    }
  };
  return (
    <Layout>
      <Row justify="center" gutter={[0, 10]}>
        <Col>
          <Tabs
            defaultActiveKey="1"
            items={items}
            className="org-tab"
            onChange={TabChange}
          />
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className="mt-10">
        <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
          <span className="mr-10 d-inline-block">
            <img src={rightArrowIcon} alt="right arrow" />
          </span>
          Back
        </h5>
        <Button
          className="stepper-button"
          htmlType="submit"
          onClick={() => {
            onsubmit(selectedOrganization);
          }}
        >
          Create Profile
        </Button>
      </Row>
    </Layout>
  );
};
export default AssignOrganization;
