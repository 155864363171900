import React from 'react'
import { Row, Col, Form, Input } from 'antd'

const CommentField = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <label htmlFor="" className="custom-field-label">
            Note
          </label>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Please enter note' }]}
          >
            <Input.TextArea
              className="note-field"
              placeholder="Enter a note here"
              autoSize={{ minRows: 3, maxRows: 7 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default CommentField
