import { Row } from 'antd'
import GeneralDetails from './GeneralDetail'
import MentorActivity from './MentorActivity'
const MentorBasicInfo = ({ parent, refetch }: any) => {
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <GeneralDetails data={parent} refetchData={refetch} />
      <MentorActivity data={parent} refetchData={refetch} />
    </Row>
  )
}

export default MentorBasicInfo
