import actions from "./actions";
import { all, takeLatest, put } from "redux-saga/effects";
import failureAction from "../Failure/action";
import { axiosGet } from "../axiosHelper";

export function* eventCategories({ payload }) {
  try {
    if (payload) {
      yield put(actions.saveEventSuccess(payload));
    }
  } catch (error) {
    console.log(error);
    yield put(failureAction.failure(error));
  }
}
export function* getAllergiesRequest({ payload }) {
  try {
    yield put(actions.getAllergiesSuccess(payload));
  } catch (error) {
    yield put(actions.getAllergiesFailure());
  }
}

export default function* listsSaga() {
  yield all([takeLatest(actions.EVENT_CATEGORIES, eventCategories)]);
  yield all([takeLatest(actions.GET_ALLERGIES, getAllergiesRequest)]);
}
