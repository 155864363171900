import { Modal, Select, Avatar } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import Cancel from "../../assets/Images/close.svg";
import "./index.scss";
import { Pagination } from "antd";
import Spinner from "../Spinner";
import { getFamilies } from "../../api/family";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

const FamilyListing = ({ clicked }: any) => {
  let initPaginationInfo: any = {
    limit: 10,
    page: 1,
    sort: {
      field: "organizationName",
      order: "asc",
    },
    showSizeChanger: true,
    pageSizeOptions: ["10", "20"],
  };
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo);
  const [parms, setParms] = useState({
    page: 1,
    status: "active",
    populate: ["guardians", "kids", "adults"],
    limit: 10,
    searchQuery: "",
  });
  const [selectorDef, setSelectorDef] = useState("Please select a family");
  const { isLoading, data, isFetching, refetch } = useQuery(
    ["getFamiles", parms],
    () => getFamilies(parms)
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedButtons, setClickedButtons] = useState<any>();
  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setPaginationInfo({
      ...paginationInfo,
      page: pageNumber,
      limit: pageSize,
    });
    setParms({ ...parms, page: pageNumber, limit: pageSize });
    refetch();
  };
  const ClickedFam = (data: any, index: number) => {
    setClickedButtons(index);
    clicked(data);
    setSelectorDef(data?.name);
  };

  const submit = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleSearchChange = (e: any) => {
    setParms({ ...parms, searchQuery: e });
  };
  const debounced = debounce(handleSearchChange, 300);
  return (
    <div>
      <Select
        className="custom-select-field h-3 position w-full"
        value={selectorDef}
        placeholder="sort by"
        onClick={() => {
          setIsOpen(true);
        }}
      ></Select>
      <Modal
        visible={isOpen}
        onCancel={handleCancel}
        className="popup-modal h-50rem"
        style={{ zIndex: "50000" }}
      >
        <div>
          <div className="popup-header">
            <h2 className="w-600">Select a Family</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
              className=""
            />
          </div>
          <div className="search-input-box">
            <input
              type="search"
              className="search-input-group"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                debounced(e.target.value);
              }}
            />
            <SearchOutlined />
          </div>
          <div className="overflow-auto pointer mt-20 h-25rem">
            {isLoading || isFetching ? (
              <div className="flex justify-center align-center h-full">
                <Spinner />
              </div>
            ) : (
              data?.records.map((famData: any, index: number) => {
                return (
                  <div
                    className={
                      clickedButtons === index
                        ? "org-active flex align-center mt-15px border-1 pb-10px pointer"
                        : "flex align-center mt-15px border-1 pb-10px  pointer ml-20px"
                    }
                    key={index}
                    onClick={() => {
                      ClickedFam(famData, index);
                    }}
                  >
                    <Avatar
                      size={34}
                      src={famData?.photo ? famData?.photo?.post : ""}
                    >
                      M
                    </Avatar>
                    <h4 className="ml-10">{famData.name}</h4>
                  </div>
                );
              })
            )}
          </div>
          <div className="mt-40px flex justify-center align-center align-center">
            <Pagination
              defaultCurrent={paginationInfo.page}
              total={data?.count}
              onChange={PaginationChanger}
            />
          </div>
          <div className="popup-footer">
            <button
              className="btn-sec pointer"
              onClick={() => {
                handleCancel();
                // setData({ ...prevData });
              }}
            >
              Cancel
            </button>
            <button className="btn-prim" onClick={submit}>
              Select Family
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default FamilyListing;
