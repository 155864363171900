const actions = {
  GET_EVENTS_VENUE_REQUEST: 'GET_EVENTS_VENUE_REQUEST',
  GET_EVENTS_VENUE_REQUEST_SUCCESS: 'GET_EVENTS_VENUE_REQUEST_SUCCESS',
  GET_EVENTS_VENUE_REQUEST_FAILURE: 'GET_EVENTS_VENUE_REQUEST_FAILURE',
  ADD_EVENTS_VENUE_DATA: 'ADD_EVENTS_VENUE_DATA',
  ADD_EVENTS_VENUE_DATA_SUCCESS: 'ADD_EVENTS_VENUE_DATA_SUCCESS',
  ADD_EVENTS_VENUE_DATA_FAILURE: 'ADD_EVENTS_VENUE_DATA_FAILURE',
  UPDATE_EVENTS_VENUE_DATA: 'UPDATE_EVENTS_VENUE_DATA',
  UPDATE_EVENTS_VENUE_DATA_SUCCESS: 'UPDATE_EVENTS_VENUE_DATA_SUCCESS',
  UPDATE_EVENTS_VENUE_DATA_FAILURE: 'UPDATE_EVENTS_VENUE_DATA_FAILURE',
  DELETE_EVENTS_VENUE: ' DELETE_EVENTS_VENUE',
  DELETE_EVENTS_VENUE_SUCCESS: ' DELETE_EVENTS_VENUE_SUCCESS',
  DELETE_EVENTS_VENUE_FAILURE: ' DELETE_EVENTS_VENUE_FAILURE',

  getEventVenueRequest: payload => ({
    type: actions.GET_EVENTS_VENUE_REQUEST,
    payload,
  }),

  getEventVenueRequestSuccess: payload => ({
    type: actions.GET_EVENTS_VENUE_REQUEST_SUCCESS,
    payload,
  }),

  getEventVenueRequestFailure: () => ({
    type: actions.GET_EVENTS_VENUE_REQUEST_FAILURE,
  }),

  createEventVenue: payload => ({
    type: actions.ADD_EVENTS_VENUE_DATA,
    payload,
  }),

  createEventVenueSuccess: payload => ({
    type: actions.ADD_EVENTS_VENUE_DATA_SUCCESS,
    payload,
  }),

  createEventVenueFailure: () => ({
    type: actions.ADD_EVENTS_VENUE_DATA_FAILURE,
  }),

  updateEventVenue: payload => ({
    type: actions.UPDATE_EVENTS_VENUE_DATA,
    payload,
  }),

  updateEventVenueSuccess: payload => ({
    type: actions.UPDATE_EVENTS_VENUE_DATA_SUCCESS,
    payload,
  }),

  updateEventVenueFailure: () => ({
    type: actions.UPDATE_EVENTS_VENUE_DATA_FAILURE,
  }),

  deleteEventVenue: adultId => ({
    type: actions.DELETE_EVENTS_VENUE,
    adultId,
  }),

  deleteEventVenueSuccess: payload => ({
    type: actions.DELETE_EVENTS_VENUE_SUCCESS,
    payload,
  }),

  deleteEventVenueFailure: () => ({
    type: actions.DELETE_EVENTS_VENUE_FAILURE,
  }),
}

export default actions
