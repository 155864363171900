import { useQuery } from '@tanstack/react-query'
import { getEventGroups, getEventParticipants } from 'api/Events'
import ReturnBreadCrumbs from 'components/breadCrumb/NewBreadCrumbs'
import AppLayout from 'components/layout/layout'
import Loader from 'helper/Loader'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import KidsFilters from './Filters'
import Participants from './Participants'
interface Participant {
  _id: string
}

interface EventParticipants {
  [key: string]: Participant[] // Index signature for various participant types
}

interface EventGroup {
  groupId: string
  guardianParticipantIds: string[]
  adultParticipantIds: string[]
  kidParticipantIds: string[]
}
const KidsAttending = (props: any) => {
  const { state } = props.location
  const eventName = state ? state.name : ''
  const { id }: any = useParams()
  const [totalParticpents, setParticpents] = useState<number>()
  const {
    data: eventParticipants,
    isLoading: isEventParticipantsLoading,
    refetch: refetchEventParticipants,
  } = useQuery(
    ['getEventParticipant', id],
    () => {
      return getEventParticipants(id)
    },
    { enabled: false },
  )

  const {
    data: eventGroups,
    isLoading: isEventGroupsLoading,
    refetch: refetchEventGroups,
  } = useQuery(
    ['getEventGroups', id],
    () => {
      return getEventGroups(id)
    },
    { enabled: false },
  )

  useEffect(() => {
    refetchEventParticipants()
    refetchEventGroups()
  }, [id])
  const [participantsInGroups, setParticipantsInGroups] =
    useState<EventParticipants | null>(null)
  const [participantsNotInGroups, setParticipantsNotInGroups] =
    useState<EventParticipants | null>(null)

  useEffect(() => {
    const ath = eventParticipants?.authorizedAdults?.length || 0
    const guardians = eventParticipants?.guardians?.length || 0
    const kids = eventParticipants?.kids?.length || 0
    setParticpents(ath + guardians + kids)
    if (
      !isEventParticipantsLoading &&
      !isEventGroupsLoading &&
      eventParticipants &&
      eventGroups
    ) {
      const participantsInGroups: EventParticipants = {
        guardians: [],
        authorizedAdults: [],
        kids: [],
      }

      const participantsNotInGroups: EventParticipants = {
        guardians: [],
        authorizedAdults: [],
        kids: [],
      }

      // Iterate through the fields (e.g., guardians, adults, kids, etc.)
      for (const field in eventParticipants) {
        if (Array.isArray(eventParticipants[field])) {
          eventParticipants[field]?.forEach((participant: any) => {
            // Check if the participant's ID exists in any group's corresponding participant IDs
            const isInGroup = eventGroups.some(
              (group: any) => group[field]?.includes(participant._id),
            )

            // Add the participant to the appropriate state
            if (isInGroup) {
              participantsInGroups[field]?.push(participant)
            } else {
              participantsNotInGroups[field]?.push(participant)
            }
          })
        }
      }

      // Update the states with the new objects
      setParticipantsInGroups(participantsInGroups)
      setParticipantsNotInGroups(participantsNotInGroups)
    }
  }, [
    isEventParticipantsLoading,
    isEventGroupsLoading,
    eventParticipants,
    eventGroups,
  ])
  return (
    <AppLayout>
      <div className="page-content">
        <div className="flex justify-between">
          <div>
            <ReturnBreadCrumbs
              links={[
                { name: 'Events', link: '/events' },
                { name: `${eventName}`, link: `/events-details/${id}` },
                { name: 'Attendies', link: `/events/${id}/attendess` },
              ]}
            />{' '}
            <h1 className="w-600">Attendies</h1>
          </div>

          <button className="prim-btn h-fit">Create a group</button>
        </div>
        <KidsFilters />
        <div className="mt-25px ">
          <h4 className="grey-txt text-14 w-600">
            Participants: {totalParticpents}
          </h4>
          <div className="grid grid-70-30">
            <div className="border-right">
              {isEventGroupsLoading ? (
                <Loader />
              ) : (
                <Participants
                  ungrouped={participantsNotInGroups}
                  grouped={eventGroups}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}
export default KidsAttending
