import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
// import MasterSheetTable from ".././Table/MasterSheetTable";
import SearchBar from '../../../../helper/SearchBar'
import CardView from './CardViewOrg'
import CalenderView from './CalenderViewOrg'
import { Select, Menu, Dropdown, Button, Tooltip, Space } from 'antd'
import { Row, Col } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { SearchOutlined } from '@ant-design/icons'
import { useDispatch, connect } from 'react-redux'
// import CreateEvent from "../../Views/CreateEvent/CreateEvent";
import { SorterResult } from 'antd/lib/table/interface'
import filterImg from '../../../../assets/Images/filterw.png'
import '../evetnsCards/style.css'
import eventsAction from '../../../../Redux/Events/action'

const { Option } = Select

const EventView = ({
  eventsData,
  setData,
  organizationData,
  data,
  categories,
  events,
  mentorId,
}: any) => {
  const dispatch = useDispatch()
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const history = useHistory()
  const [view, setView] = useState('cardview')
  const [action, setAction] = useState('list')
  const [eventsList, setEventsList] = useState(events)
  const [categorySort, setCategorySort] = useState('')
  const [statusSort, setStatusSort] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  // useEffect(() => {

  //   dispatch({
  //     type: eventsAction.GET_EVENT_CATEGORIES,
  //   });
  // }, []);

  const onChangeAge = (value: string) => {
    // console.log(`selected ${value}`)
    let filteredData = eventsData.filter((item: any) => {
      return item.ageRange.toLowerCase().includes(value.toLowerCase())
    })
    setData(filteredData)
    if (value !== '') {
      return filteredData
    } else {
      setData(eventsData)
    }
  }

  const onChangeCategory = (value: string) => {
    // console.log(`selected ${value}`);
    if (value === 'firstname') {
      setCategorySort('')
    } else {
      setCategorySort(value)
    }
  }

  const handleChangeStatus = (value: string) => {
    // console.log('selected status', statusSort );
    setStatusSort(value)
  }

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value)
  }

  const handleChangeSortName = (value: string) => {
    setSortedInfo({
      order: 'descend',
      columnKey: value,
    })
  }
  const menu = (
    <Menu onClick={e => setView(e.key)}>
      <Menu.Item key="cardview">Card view</Menu.Item>
      <Menu.Item key="calendar">Calendar view</Menu.Item>
      <Menu.Item key="TableView">Table view</Menu.Item>
    </Menu>
  )

  return (
    <div>
      <Row gutter={[10, 24]} align="middle">
        <Col xl={{ span: 24 }}>
          <div style={{ display: 'flex' }}>
            {/* <div className="filter" style={{marginRight: "15px"}}>
                <img src={filterImg} alt="" />
            </div> */}
            <div
              className="right-search-block w-100 d-inline-block"
              style={{ maxWidth: '293px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <div
                  className={'search-input-box'}
                  style={{ marginRight: '15px' }}
                >
                  <input
                    type="search"
                    className="search-input-group"
                    placeholder="Search by event name"
                    onChange={handleSearchChange}
                  />
                  <SearchOutlined />
                </div>
              </div>
            </div>
            <Space size={20}>
              <Select
                className="custom-select-field black width-140"
                defaultValue="Category: All"
                placeholder="Categories"
                allowClear
                onChange={onChangeCategory}
              >
                <Option value="" style={{ width: 'auto' }}>
                  Category: All
                </Option>
                {categories.map((category: any) => {
                  return (
                    <option key={category?._id} value={category?._id}>
                      {category.name}
                    </option>
                  )
                })}
              </Select>
              <Select
                className="custom-select-field black text-color-black width-140"
                defaultValue="Status: All"
                placeholder="Status"
                style={{ width: 'auto' }}
                allowClear
                onChange={handleChangeStatus}
              >
                <Option value="">status: All</Option>
                <Option value="draft">Draft</Option>
                <Option value="public">Public</Option>
                <Option value="following">Following</Option>
                <Option value="subscribed">Subscribed</Option>
                <Option value="private">Private</Option>
                <Option value="mentor_social">Mentor socail</Option>
                <Option value="archived">Archived </Option>
              </Select>

              <Button className="btn-item mr-15">More filters</Button>
            </Space>
            {/* <div
              className="right-search-block w-100 d-inline-block"
              style={{ maxWidth: "293px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  className={"search-input-box"}
                  style={{ marginRight: "0px" }}
                >
                  <input
                    type="search"
                    className="search-input-group"
                    placeholder="Search by event name"
                    //   onChange={(e: any) => searchActive(e.target.value)}
                  />
                  <SearchOutlined />
                </div>
              </div>
            </div> */}
            {/* <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="nav-btn br-8">
                  {view} <DownOutlined />
                </Button>
              </Dropdown>
            </div> */}
            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="nav-btn br-8">
                  {view} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
        </Col>
        <Col>
          <div>
            {view === 'cardview' && (
              <CardView
                mentorId={mentorId}
                data={data}
                setData={setData}
                categorySort={categorySort}
                statusSort={statusSort}
                searchQuery={searchQuery}
              />
            )}
            {view === 'calendar' && <CalenderView />}
            {/* {view === "TableView" && <MasterSheetTable />} */}
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  categories: state.events.eventCategoryData,
  events: state.events.events,
})

export default connect(mapStateToProps)(EventView)
