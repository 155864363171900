export const TicketsArr = [
  {
    label: 'Current',
    value: 'current',
  },
  {
    label: 'Past',
    value: 'past',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
]

export const AttendeeAge = [
  {
    label: 'All ages',
    value: 'all',
  },
  {
    label: '1-3',
    value: '1-3',
  },
  {
    label: '3-6',
    value: '3',
  },
  {
    label: '6-9',
    value: '6',
  },
  {
    label: '9-12',
    value: '9',
  },
]
export const ParticpentsGender = [
  {
    label: 'All Genders',
    value: 'all',
  },
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
]
export const Schools = [
  {
    label: 'All Schools',
    value: 'all',
  },
  {
    label: 'Sch1',
    value: '1-3',
  },
  {
    label: 'Sch2',
    value: '3',
  },
  {
    label: 'Sch3',
    value: '6',
  },
  {
    label: 'Sasfg',
    value: '9',
  },
]
export const Grades = [
  {
    label: 'All Grades',
    value: 'all',
  },
  {
    label: '1-3',
    value: '1-3',
  },
  {
    label: '3-6',
    value: '3',
  },
  {
    label: '6-9',
    value: '6',
  },
  {
    label: '9-12',
    value: '9',
  },
]
