/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Button, Space, Tag } from 'antd'
import { Layout } from 'antd'
import rightArrowIcon from '../../../../assets/Images/rightarrowicon.svg'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createNewMentor,
  getSkillsList,
  updateMentor,
} from '../../../../api/mentors'
import { forceRefetch } from '../../../../helper/utility'

const Skills = ({ childData, handleBack, close, refetc, editData }: any) => {
  const { data: skillsList } = useQuery(['getFamiles'], () => getSkillsList())
  const [selectedSkills, setSkills] = useState<any>([])
  const { CheckableTag } = Tag
  const queryClient = useQueryClient()

  const educational = skillsList?.filter((skill: any) => {
    return skill.category === 'educational'
  })

  const sports = skillsList?.filter((skill: any) => {
    return skill.category === 'sports'
  })

  const createMentorMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await createNewMentor(payload)
    },
  })
  const updateMutation = useMutation({
    mutationFn: async ({ id, payload }: any) => {
      return await updateMentor(id, payload)
    },
  })
  const onFinish = () => {
    const data = {
      ...childData?.values,
      password: 'admin@123',
      dob: childData?.values.dob.format('YYYY-MM-DD'),
      address: childData?.mapModalData,
      allergies:
        childData?.allergyData[0]._id !== undefined
          ? childData?.allergyData
          : editData?.allergies,
      skills: selectedSkills.map((item: any) => {
        let dataValues = { ...item }
        dataValues.level = 'none'
        return dataValues
      }),
    }
    editData === undefined
      ? createMentorMutation.mutate({
          payload: data,
        })
      : updateMutation.mutate({
          id: editData?._id,
          payload: data,
        })
    close()
    forceRefetch('getMentors', queryClient)
    refetc()
  }
  let updatedArr: any[] = []

  useEffect(() => {
    editData?.skills.map((item: any) => {
      updatedArr.push(item)
      setSkills(updatedArr)
    })
  }, [])
  const handleChange = (tag: any, checked: boolean) => {
    const nextSelectedSkills = checked
      ? [...selectedSkills, tag]
      : selectedSkills.filter((item: any) => item.name !== tag.name)
    setSkills(nextSelectedSkills)
  }

  return (
    <Layout>
      <div>
        <Space direction="vertical" size={20}>
          <div>
            <div>
              <h5 className="b1 mb-10">Educational </h5>
              <Space className="skillSet" size={10}>
                {educational?.map((item: any, index: any) => {
                  return (
                    <CheckableTag
                      className="br-12 skill_tags"
                      key={item?._id}
                      checked={selectedSkills.some(
                        (skill: any) => skill._id === item._id,
                      )}
                      onChange={checked => {
                        handleChange(item, checked)
                      }}
                    >
                      {item?.name}
                    </CheckableTag>
                  )
                })}
              </Space>
            </div>
          </div>
          <div>
            <h5 className="b1 mb-10">Sports</h5>
            <Space className="skillSet" size={10}>
              {sports?.map((item: any) => {
                return (
                  <CheckableTag
                    className="br-12 skill_tags"
                    key={item?.name}
                    checked={selectedSkills.some(
                      (skill: any) => skill._id === item._id,
                    )}
                    onChange={checked => {
                      handleChange(item, checked)
                    }}
                  >
                    {item?.name}
                  </CheckableTag>
                )
              })}
            </Space>
          </div>
        </Space>
      </div>
      <div className="footer-modal">
        <div className="stepper-buttons">
          <h5 className="stepperBackh5" onClick={() => handleBack(childData)}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} alt="right" />
            </span>
            Back
          </h5>
          <Button
            type="primary"
            className="stepper-button"
            onClick={() => {
              onFinish()
            }}
          >
            {editData === undefined ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default Skills
