import { SearchOutlined } from '@ant-design/icons'
import { Select, Space } from 'antd'
import pdf from '../../../../../assets/Images/documents.png'
import DocumentStatusDropDown from '../../../../../helper/DocumentStatusDropDown'

const { Option } = Select
const GuardianDocuments = () => {
  return (
    <div className="bg-grey container padding-top-10 h-full">
      <div className="bg-white p-30px h-full">
        <div className="flex justify-between mb-20px">
          <div className="flex">
            <div className={'search-input-box'} style={{ marginRight: '15px' }}>
              <input
                type="search"
                className="search-input-group"
                placeholder="Search by event name"
              />
              <SearchOutlined />
            </div>
            <Space size={20}>
              <Select
                className="custom-select-field black width-140"
                defaultValue="Category: All"
                placeholder="Categories"
                allowClear
              >
                <Option value="" style={{ width: 'auto' }}>
                  Category: All
                </Option>
              </Select>
            </Space>
          </div>
          <button className="bordered-prim-btn">Request document</button>
        </div>
        <div className="flex justify-between mt-20px pb-10px border-btm">
          <div className="flex  align-center justify-items">
            <img src={pdf} alt="pdf img" className="" />
            <div className="ml-20px">
              <h4 className="text-14">Background check</h4>
              <span className="grey-txt text-10">Expiration data:10/2024</span>
            </div>
          </div>
          <DocumentStatusDropDown status={'requested'} />
        </div>
        <div className="flex justify-between mt-20px pb-10px border-btm">
          <div className="flex  align-center justify-items">
            <img src={pdf} alt="pdf img" className="" />
            <div className="ml-20px">
              <h4 className="text-14">Background check</h4>
              <span className="grey-txt text-10">Expiration data:10/2024</span>
            </div>
          </div>
          <DocumentStatusDropDown status={'approved'} />
        </div>
      </div>
    </div>
  )
}
export default GuardianDocuments
