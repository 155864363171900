import { Button, Col, Form, Layout, Radio, Row, Select, Input } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MapModal from "../../../../../helper/Map/MapModal";
import FamilyListing from "../../../../ListingModals/FamilyListingModal";
const GeneralDetails = ({ handleNext, eventCategoryData, data }: any) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [sessionType, setSessionType] = useState(
    data?.isSessionOnline ? "online" : "inperson"
  );
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [familyVal, setFamilyVal] = useState<any>({ _id: null, name: "" });
  const [kidVal, setKidVal] = useState({ _id: "", name: "" });

  const [categoryVal, setCategoryVal] = useState({ _id: "", name: "" });
  const [mapData, setMapModalData] = useState<any>(data?.address || null);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleSessionType = (e: any) => {
    setSessionType(e.target.value);
  };

  const setFieldsValue = (data: any) => {
    if (data?.address) form.setFieldsValue({ location: data?.address });
  };

  const onFinish = (values: any) => {
    // console.log("values session:", values);
    const dataValues = {
      familyId: familyVal._id,
      categoryId: values.categorysId,
      familyVal: familyVal,
      family: familyVal,
      kidVal: kidVal,
      categoryVal: categoryVal,
      kidsIds: [values.kidsIds],
      genderPreference: values.genderPreference,
      isSessionOnline: sessionType === "online" ? true : false,
      address: mapData,
      // childName: values.childName,
      // dob: values.dob,
      // email: values.email,
      // phoneNumber: values.phoneNumber
    };
    // console.log("valu:", dataValues);
    handleNext({
      ...dataValues,
    });
  };
  const [selectedFamily, setFamily] = useState<any>();
  return (
    <Layout>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="modal-h-element">
          <Row align="middle">
            <Col>
              <div className="inputGroup">
                <label className="text-12 font-600">Family</label>
                <div className="mt-10 ">
                  <FamilyListing clicked={setFamily} />
                </div>
              </div>
              {selectedFamily ? (
                <div className="inputGroup">
                  <label className="text-12 font-600">Kids</label>
                  <div className="mt-10 ">
                    <Form.Item
                      name="kids"
                      rules={[{ required: true, message: "Kids are required" }]}
                    >
                      <Select
                        placeholder="Select"
                        className="custom-select-field h-3 position"
                        onChange={(val: any, elm: any) => setKidVal(elm)}
                      >
                        {selectedFamily &&
                          selectedFamily?.kids.map((item: any) => (
                            <Option
                              name="kidId"
                              value={item._id}
                              key={item._id}
                            >
                              {item.firstName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="inputGroup">
                <label className="input-label required-input">Category</label>
                <Form.Item
                  name="categorysId"
                  rules={[{ required: true, message: "Category is required" }]}
                >
                  <Select
                    placeholder="Select"
                    className="custom-select-field h-3 position"
                    onChange={(val: any, elm: any) => setCategoryVal(elm)}
                  >
                    {eventCategoryData &&
                      eventCategoryData.map((item: any) => (
                        <Option
                          name="categoryId"
                          value={item._id}
                          key={item._id}
                        >
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="inputGroup">
                <label htmlFor="" className="input-label required-input">
                  Gender Preferences
                </label>
                <Form.Item
                  name="genderPreference"
                  rules={[
                    {
                      required: true,
                      message: "Gender preference is required",
                    },
                  ]}
                >
                  <Select className="custom-select-field h-3 position">
                    <Select.Option value="male" name="genderPreference">
                      Male
                    </Select.Option>
                    <Select.Option value="female" name="genderPreference">
                      Female
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col>
              {/* <Row className="d-flex"> */}
              <label htmlFor="" className="input-label required-input">
                Session Type
              </label>
              <Radio.Group
                className="d-flex"
                value={sessionType}
                onChange={handleSessionType}
              >
                <Form.Item name="sessionType">
                  <Radio className="radio" value="inperson">
                    In person
                  </Radio>
                </Form.Item>
                <Form.Item>
                  <Radio className="radio" value="online">
                    Online
                  </Radio>
                </Form.Item>
              </Radio.Group>
              {/* </Row> */}
            </Col>
            <Col>
              <label className="input-label required-input">Location</label>
              <Form.Item
                name="location"
                rules={[{ required: true, message: "Location is required" }]}
              >
                <Input
                  onClick={() => {
                    setMapModalVisible(true);
                  }}
                  className="custom-select-field h-3 position"
                  value={mapData ? mapData.city + "," + mapData.state : ""}
                ></Input>
                {mapModalVisible && (
                  <MapModal
                    mapData={mapData}
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    setFieldsValue={setFieldsValue}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Row justify="end">
              <Button className="stepper-button" htmlType="submit">
                Next
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  eventCategoryData: state.lists.eventCategories,
});

export default connect(mapStateToProps)(GeneralDetails);
