import actions from "./action";

const initState = {
  newsFeedData: JSON.parse(localStorage.getItem("newsFeedData")) || [] ,
    newsFeed :{},
    totalCount : 0,

};

export default (state = initState, action) => {

    switch (action.type) {


        case actions.ADD_NEWSFEED_DATA: {
            return {
                ...state,
                newsFeed: {},
                loading: true,
            };
        }

        case actions.ADD_NEWSFEED_DATA_SUCCESS: {
            return {
                ...state,
                newsFeed: action.payload,
                loading: false,
            };
        }

        case actions.ADD_NEWSFEED_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.GET_ALL_NEWS: {
            return {
                ...state,
                newsFeedData: [],
                loading: true,
            };
        }

        case actions.GET_ALL_NEWS_SUCCESS: {
            return {
                ...state,
                totalCount: action.payload.length,
                newsFeedData: action.payload.records,
                loading: false,
            };
        }

        case actions.GET_ALL_NEWS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.GET_ALL_NEWS_OF_ORGANIZATION:{
            return {
                ...state,
                loading:true,
                newsFeedData: []
            }
        }

        case actions.GET_ALL_NEWS_OF_ORGANIZATION_SUCCESS: {
            localStorage.setItem("newsFeedData", JSON.stringify(action.payload));
            return {
                ...state,
                newsFeedData: action.payload,
                loading: false,
            };
        }

        case actions.GET_ALL_NEWS_OF_ORGANIZATION_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case actions.DELETE_NEWS_POST: {
            return {
                ...state,
                newsFeedData: [],
                loading: true,
            };
        }

        case actions.GET_ALL_NEWS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.EDIT_NEWS_DATA: {
            return {
                ...state,
                loading: true,
            };
        }

        case actions.EDIT_NEWS_DATA_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.EDIT_NEWS_DATA_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case actions.FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        default:
            return state

    }

};
