import React, { useState } from "react";
import {
  Button,
  Row,
  Input,
  TimePicker,
  Upload,
  message,
  Form,
  Avatar,
  Col,
} from "antd";
import { Layout } from "antd";
import { Select } from "antd";
import { uuidv4 } from "@antv/xflow-core";
import { useDispatch } from "react-redux";
import moment from "moment";
import rewardsAction from "../../../../Redux/Settings/action";
import getBase64 from "../../../../helper/getBase64";
import ImageSquare from "../../../../assets/Images/ImageSquare.svg";
import ImgCrop from "antd-img-crop";

const { Option } = Select;
const format = "HH";

const RewardsForm = ({ editData, currentData, props }: any) => {
  const [rewardsPhoto, setRewardsPhoto] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleRewardsPhotoChange = (info: any) => {
    getBase64(info.file.originFileObj, (url: any) => {
      setRewardsPhoto(url);
      form.setFieldsValue({
        attachment: url,
      });
    });
  };

  const onFinish = (values: any) => {
    let rewardsData = [];
    rewardsData = JSON.parse(localStorage.getItem("rewardsData") || "[]") || [];
    rewardsData.push(values);
    message.success("completed");
    dispatch({
      type: rewardsAction.ADD_REWARDSDATA_DATA,
      payload: rewardsData,
    });
  };

  var formSchema = {
    id: editData?.id || uuidv4(),
    rewardsName: editData?.rewardsName || "",
    status: editData?.status || "",
    timeRange: editData?.timeRenge || "",
    attachment: editData?.attachment || "",
  };

  return (
    <Layout>
      <Form form={form} initialValues={formSchema} onFinish={onFinish}>
        <div>
          <Row>
            <Col>
              <label htmlFor="" className="input-label required-input">
                Reward Name
              </label>
              <Form.Item
                name="rewardsName"
                rules={[
                  {
                    required: true,
                    message: "Reward name is required",
                  },
                ]}
              >
                <Input name="rewardsName" placeholder="Reward Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="input-label required-input">
                Activity or Category
              </label>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select
                  className="custom-select-field"
                  style={{ width: 330 }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      .toLowerCase()
                      .localeCompare(
                        (optionB!.children as unknown as string).toLowerCase()
                      )
                  }
                >
                  <Option name="status" value="1">
                    Not Identified
                  </Option>
                  <Option name="status" value="2">
                    Closed
                  </Option>
                  <Option name="status" value="3">
                    Communicated
                  </Option>
                  <Option name="status" value="4">
                    Identified
                  </Option>
                  <Option name="status" value="5">
                    Resolved
                  </Option>
                  <Option name="status" value="6">
                    Cancelled
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="input-label required-input">
                How many hours to achieve?
              </label>
              <Form.Item
                name="timeRange"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <TimePicker
                  defaultValue={moment("12:08", format)}
                  format={format}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="input-label required-input">
                Picture of award
              </label>
              <Form.Item
                name="attachment"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <ImgCrop>
                  <Upload
                    name="attachment"
                    className="reward-uploader"
                    listType="picture-card"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    onChange={handleRewardsPhotoChange}
                  >
                    {rewardsPhoto ? (
                      <Avatar src={rewardsPhoto} size={80} />
                    ) : (
                      <img alt="" src={ImageSquare}></img>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
          </Row>
          <Button
            htmlType="submit"
            style={{
              backgroundColor: "#2BA7CA",
              left: "70%",
              width: "129px",
              height: "51px",
              borderRadius: "10px",
              marginTop: "60px",
              color: "white",
            }}
          >
            Create
          </Button>
        </div>
      </Form>
    </Layout>
  );
};
export default RewardsForm;
