import React, { useEffect } from 'react'
import '../suppliesandcoststyles.scss'
import { creationEventStore } from 'state/eventCreationStore'

interface TotalProps {}

const Total: React.FC<TotalProps> = () => {
  const eventData = creationEventStore(store => store.eventData)
  const step3Payload = creationEventStore(store => store.step3Payload)
  const setStep3Payload = creationEventStore(store => store.setStep3Payload)

  useEffect(() => {
    setStep3Payload({
      estimatedAvgTicketPrice: {
        min:
          step3Payload.estimatedTotalPrice.min /
          (eventData?.maxGuestsAllowed || 1),
        max:
          step3Payload.estimatedTotalPrice.max /
          (eventData?.minGuestsRequired || 1),
      },
    })
  }, [step3Payload.estimatedTotalPrice])

  return (
    <div className="w-full flex flex-col align-center justify-center px-20px py-20px total-amount-container">
      <p className="text-center">
        Estimated average ticket price to break even
      </p>
      <h3 className="text-24 w-500 mt-10px">{`$${step3Payload.estimatedAvgTicketPrice.min.toFixed(
        2,
      )} ~ $${step3Payload.estimatedAvgTicketPrice.max.toFixed(2)}`}</h3>
    </div>
  )
}

export default Total
