import { useMutation } from '@tanstack/react-query'
import { Modal, notification, Radio, Steps } from 'antd'
import { createEventRollCall } from 'api/Events'
import { failureMessage, successMessage } from 'api/global'
import Cancel from 'assets/Images/close.svg'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Attendees, CreateRollCallBody } from 'pages/Events/types'
import AttendeeList from './AttendeeList'
import CreateRollCall from './CreateRollCall'

interface RoolCall {
  participants: Attendees[]
  setOpen: React.Dispatch<React.SetStateAction<number>>
}
const RoolCall = ({ setOpen, participants }: RoolCall) => {
  const [openModal, setModal] = useState(true)
  const [current, setCurrent] = useState(0)
  const { id }: any = useParams()
  const [data, setData] = useState<CreateRollCallBody>({
    eventId: id,
    name: '',
    participantIds: [''],
  })
  const handleCancel = () => {
    setOpen(0)
    setModal(false)
  }
  const next = (childData: CreateRollCallBody) => {
    setData(prev => ({ ...prev, ...childData }))
    current !== 1 && setCurrent(current + 1)
  }
  const addRollCall = useMutation({
    mutationFn: async (createRollCallBody: CreateRollCallBody) => {
      const data = await createEventRollCall(createRollCallBody)
      return data
    },
    onSuccess: result => {
      if ((result.status = 200)) successMessage(`Rollcall Created Successfully`)
      else failureMessage('Can not create Rollcall')
      handleCancel()
    },
  })
  const handleSubmit = () => {
    addRollCall.mutateAsync(data)
  }
  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      className="popup-modal w-550px h-35rem"
    >
      <div className=" pt-40px">
        <div className="border-btm">
          <div className="flex justify-between">
            <h2>Create a Roll-call</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <div className="w-70p py-10px">
            <Steps
              current={current}
              size="small"
              items={[
                {
                  title: 'Create Roll-call',
                },
                {
                  title: 'Select Attendee',
                },
              ]}
            />
          </div>
        </div>
        {current === 0 && <CreateRollCall handleNext={next} />}
        {current === 1 && (
          <AttendeeList
            handleNext={next}
            submit={handleSubmit}
            participants={participants}
          />
        )}
      </div>
    </Modal>
  )
}

export default RoolCall
