import { Avatar, Form, Input, Modal } from 'antd'
import { Row, Col, Button } from 'antd'
import { Radio } from 'antd'
import { ConfigProvider } from 'antd-country-phone-input'
import CountryPhoneCode from 'antd-country-phone-input'
import en from 'world_countries_lists/data/countries/en/world.json'
import 'antd-country-phone-input/dist/index.css'
import { useDispatch } from 'react-redux'
import userAvatarSvg from '../../../assets/Images/userAvatar.svg'
import { useState } from 'react'
import FileUploader from '../../../helper/FileUploader'
import Cancel from '../../../assets/Images/close.svg'
import actions from '../../../Redux/Admin/action'

interface adminProps {
  notvisible: any
  loadingData: any
}
const AddNewAdmin: React.FC<adminProps> = ({ notvisible, loadingData }) => {
  const [open, setOpen] = useState<boolean>(true)

  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState('')
  const [phone, setPhone] = useState<any>({})

  const [form] = Form.useForm()
  const signUpFormSchema = {
    photoId: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    gender: 'male',
  }
  const onFinish = (values: any) => {
    dispatch({
      type: actions.CREATE_NEW_ADMIN_REQUEST,
      payload: {
        ...values,
        phone: phone,
      },
    })
    notvisible(null)
  }
  const handleCancel = () => {
    setOpen(false)
    notvisible(null)
  }
  return (
    <div className="changeModal">
      <ConfigProvider locale={en}>
        <Modal open={open} onCancel={handleCancel} className="popup-modal">
          <div>
            <div className="popup-header">
              <div>
                <h2>Add Admin</h2>
              </div>
              <img
                src={Cancel}
                alt="close button pointer"
                onClick={handleCancel}
              />
            </div>
            <Form
              form={form}
              name="nest-messages"
              layout={'vertical'}
              onFinish={onFinish}
              initialValues={signUpFormSchema}
            >
              <Row gutter={24} align="middle">
                <Col span={4} className="mb-16">
                  <Form.Item>
                    <Avatar
                      size={80}
                      src={avatar ? avatar : userAvatarSvg}
                    ></Avatar>
                  </Form.Item>
                </Col>
                <Col span={4} className="mb-16">
                  <Form.Item
                    name="photoId"
                    rules={[
                      {
                        message: 'Photo is required',
                        required: true,
                      },
                    ]}
                  >
                    <FileUploader
                      name="admin"
                      setAvatar={setAvatar}
                      form={form}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="input-flex">
                <div className="inputGroup">
                  <label className="inp-label">First Name</label>
                  <Form.Item
                    rules={[{ required: true }]}
                    name="firstName"
                    className="my-input-box"
                  >
                    <Input name="firstName" />
                  </Form.Item>
                </div>
                <div></div>
                <div className="inputGroup" style={{ marginTop: '0px' }}>
                  <label className="inp-label">Last Name</label>
                  <Form.Item
                    name="lastName"
                    className="my-input-box"
                    rules={[
                      {
                        message: 'this field is required',
                        required: true,
                      },
                    ]}
                  >
                    <Input name="lastName" />
                  </Form.Item>
                </div>
              </div>
              <div className="inputGroup">
                <label className="inp-label">Email</label>
                <Form.Item
                  rules={[
                    {
                      message: 'this field is required',
                      required: true,
                    },
                  ]}
                  name="email"
                  className="my-input-box"
                >
                  <Input name="email" />
                </Form.Item>
              </div>
              <div className="inputGroup">
                <label className="inp-label">Password</label>
                <Form.Item
                  name="password"
                  className="my-input-box"
                  rules={[
                    {
                      message: 'this field is required',
                      required: true,
                    },
                  ]}
                >
                  <Input name="password" />
                </Form.Item>
              </div>

              <Form.Item
                name="gender"
                className="mt-20px"
                rules={[
                  {
                    message: 'this field is required',
                    required: true,
                  },
                ]}
              >
                <Radio.Group name="gender">
                  <Row>
                    <Radio.Button className="radioButton" value="male">
                      <h5>Male</h5>
                    </Radio.Button>
                    <Radio.Button className="radioButton" value="female">
                      <h5>Female</h5>
                    </Radio.Button>
                  </Row>
                </Radio.Group>
              </Form.Item>
              <div className="inputGroup">
                <Row>
                  <Col span={12} className="mb-16">
                    <label htmlFor="" className="input-label">
                      Phone number
                    </label>
                    <Form.Item
                      className="my-input-box"
                      rules={[
                        {
                          message: 'this field is required',
                          required: true,
                        },
                      ]}
                    >
                      <CountryPhoneCode
                        onChange={v => {
                          setPhone({
                            dialCode: String(v.code),
                            number: v.phone,
                            isoCode: v.short,
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className="popup-footer">
                <button className="btn-sec" onClick={handleCancel}>
                  Cancel
                </button>
                <Button className="btn-prim " htmlType="submit">
                  Add Admin
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      </ConfigProvider>
    </div>
  )
}
export default AddNewAdmin
