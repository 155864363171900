import { useQuery } from "@tanstack/react-query";
import { getGuardianMedia } from "../../../../../api/family";
import Spinner from "../../../../../components/Spinner";
import { useEffect, useState } from "react";

const Media = ({ id }: any) => {
  const { data, isLoading, refetch } = useQuery(["guardianMedia", id], () =>
    getGuardianMedia(id)
  );
  const [userMedia, setUserMedia] = useState(data);
  useEffect(() => {
    setUserMedia(data);
  }, [data]);

  console.log(userMedia);
  return (
    <div className="bg-grey container padding-top-10 h-full">
      <div className="bg-white p-30px h-full">
        <h1>Media</h1>
        {isLoading ? (
          <div
            className="flex justify-center aligin-center items-center "
            style={{ height: "80vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-wrap ">
            {userMedia?.map((gMedia: any) => {
              return (
                <div key={gMedia._id} className="m-10px">
                  {gMedia?.images?.map((photos: any) => {
                    return (
                      <div key={photos._id}>
                        <img
                          src={photos?.thumb}
                          alt={gMedia?.title}
                          className="w-150px"
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default Media;
