import React, { useState } from 'react'
import { Modal, Radio, Checkbox } from 'antd'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Cancel from 'assets/Images/close.svg'
import {
  capitalizeFirstLetter,
  convertToLocal,
  getUserName,
  convertMinutesToLocalTime,
} from 'helper/utility'

const ExportRequest = ({ visible, onClose, data }) => {
  const [fileFormat, setFileFormat] = useState('csv')
  const [selectedColumns, setSelectedColumns] = useState([
    'First Name',
    'Last Name',
    'Category',
    'Title',
    'Budget',
    'Session Schedule',
    'Description',
    'Time',
    'Mentor Hired',
    'Request From',
    'Request Type',
  ])

  const handleCancel = () => {
    onClose()
  }

  const csvData = data?.map(item => {
    let time = ''
    if (item.timesPerWeek === 'one-time') {
      time = `${convertToLocal(item.sessionSchedule?.startTime, 'h:mm A')} - ${convertToLocal(item.sessionSchedule?.endTime, 'h:mm A')}`
    } else if (item.timesPerWeek === 'recurring') {
      const groupedSlots: Record<string, string[]> = {}
      const session = item.recurringSessionSchedule

      for (const [day, slots] of Object.entries(session)) {
        if (day === 'startDate' || day === 'endDate' || !(slots as any[]).length) {
          continue
        }

        for (const slot of slots as any[]) {
          const start = convertMinutesToLocalTime(slot.start)
          const end = convertMinutesToLocalTime(slot.end)
          const timeRange = `${start}-${end}`

          if (!(timeRange in groupedSlots)) {
            groupedSlots[timeRange] = [capitalizeFirstLetter(day.substring(0, 3))]
          } else {
            groupedSlots[timeRange].push(capitalizeFirstLetter(day.substring(0, 3)))
          }
        }
      }

      time = Object.entries(groupedSlots)
        .map(([time, days]) => `${days.join(', ')} (${time})`)
        .join(' | ')
    } else if (item.timesPerWeek === 'flexible') {
      time = `${convertToLocal(item.flexibleSessionSchedule?.startDate, 'MMM DD, YYYY')} - ${convertToLocal(item.flexibleSessionSchedule?.endDate, 'MMM DD, YYYY')}`
    }

    const mentorHiredArray = []
    if (item.mentorProposed > 0) {
      mentorHiredArray.push(`Proposed (${item.mentorProposed})`)
    }
    if (item.hiredMentorsCount > 0) {
      mentorHiredArray.push(`Hired (${item.hiredMentorsCount})`)
    }
    if (item.rejectedMentorsCount > 0) {
      mentorHiredArray.push(`Rejected (${item.rejectedMentorsCount})`)
    }
    if (item.interestedMentorsCount > 0) {
      mentorHiredArray.push(`Applicants (${item.interestedMentorsCount})`)
    }
    const mentorHired = mentorHiredArray.join(' / ')

    return {
      key: item?._id,
      firstName: item?.organizer?.firstName || item?.guardianRequester?.firstName,
      lastName: item?.organizer?.lastName || item?.guardianRequester?.lastName,
      category: item?.category?.name,
      title: item?.title,
      budget: item?.category?.price,
      sessionSchedule:
        convertToLocal(item.flexibleSessionSchedule?.startDate, 'MMM DD, YYYY') +
        ' - ' +
        convertToLocal(item.flexibleSessionSchedule?.endDate, 'MMM DD, YYYY'),
      description:
        item?.extraDetails ||
        (item?.additionalNotes?.trim() !== '' && item?.additionalNotes) ||
        'No description is added.',
      time: time,
      mentorHired: mentorHired,
      requestFrom: capitalizeFirstLetter(item.createdBy),
      requestType: item.timesPerWeek,
    }
  })

  const headers = [
    { label: 'Key', key: 'key' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Category', key: 'category' },
    { label: 'Title', key: 'title' },
    { label: 'Budget', key: 'budget' },
    { label: 'Session Schedule', key: 'sessionSchedule' },
    { label: 'Description', key: 'description' },
    { label: 'Time', key: 'time' },
    { label: 'Mentor Hired', key: 'mentorHired' },
    { label: 'Request From', key: 'requestFrom' },
    { label: 'Request Type', key: 'requestType' },
  ]

  const handleFormatChange = e => {
    setFileFormat(e.target.value)
  }

  const handleColumnChange = (checkedValue, label) => {
    setSelectedColumns(prev =>
      checkedValue ? [...prev, label] : prev.filter(col => col !== label),
    )
  }

  const handleExport = () => {
    const filteredHeaders = headers.filter(header =>
      selectedColumns.includes(header.label),
    )
    const filteredData = csvData.map(row => {
      const filteredRow = {}
      filteredHeaders.forEach(header => {
        filteredRow[header.key] = row[header.key]
      })
      return filteredRow
    })

    if (fileFormat === 'csv') {
      document.getElementById('csv-download-link').click()
    } else if (fileFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Request_Table.xlsx')
    } else if (fileFormat === 'pdf') {
      const doc = new jsPDF()
      const tableColumn = filteredHeaders.map(header => header.label)
      const tableRows = filteredData.map(row =>
        filteredHeaders.map(header => row[header.key]),
      )

      ;(doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
      })

      doc.save('Request_Table.pdf')
    }
    onClose()
  }

  return (
    <Modal visible={visible} footer={false}>
      <div className="popup-header ">
        <h2>Export Data</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="formatText ">Format </div>
      <Radio.Group onChange={handleFormatChange} value={fileFormat}>
        <Radio value="csv">CSV</Radio>
        <Radio value="excel">Excel</Radio>
        <Radio value="pdf">PDF</Radio>
      </Radio.Group>
      <div className="radioText mt-15px">Please select columns to export </div>
      <div className="checkbox-group">
        {headers.map(({ label }) => (
          <div key={label}>
            <Checkbox
              checked={selectedColumns.includes(label)}
              onChange={e => handleColumnChange(e.target.checked, label)}
            >
              {label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mt-10px">
        <div className="bg-grey radius-8 pointer mr-30px">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <button className="prim-sm-btn" onClick={handleExport}>
          Export
        </button>
      </div>
      <CSVLink
        data={csvData}
        headers={headers.filter(header =>
          selectedColumns.includes(header.label),
        )}
        filename="Request_Table.csv"
        id="csv-download-link"
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default ExportRequest




// previous Code

// import React, { useState } from 'react'
// import { Modal, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'
// import { saveAs } from 'file-saver'
// import * as XLSX from 'xlsx'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import Cancel from 'assets/Images/close.svg'
// import {
//   capitalizeFirstLetter,
//   convertToLocal,
//   getUserName,
// } from 'helper/utility'
// // const convertToLocal = (date, format) => {
// //   // Implement your date conversion logic here
// //   // This is a placeholder function
// //   return new Date(date).toLocaleDateString('en-US', {
// //     month: 'short',
// //     day: '2-digit',
// //     year: 'numeric',
// //   })
// // }

// const ExportRequest = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'First Name',
//     'Last Name',
//     'Category',
//     'Title',
//     'Budget',
//     'Session Schedule',
//     'Description',
//   ])

//   const handleCancel = () => {
//     onClose()
//   }

//   const csvData = data?.map(item => ({
//     key: item?._id,
//     // firstName:
//     //   item?.guardianRequester?.firstName || item?.organization?.firstName,
//     // lastName: item?.guardianRequester?.lastName || item?.organization?.lastName,
//     firstName: item?.organizer?.firstName || item?.guardianRequester?.firstName,
//     lastName: item?.organizer?.lastName || item?.guardianRequester?.lastName,
//     category: item?.category?.name,
//     title: item?.title,
//     budget: item?.category?.price,
//     sessionSchedule:
//       convertToLocal(item.flexibleSessionSchedule?.startDate, 'MMM DD, YYYY') +
//       ' - ' +
//       convertToLocal(item.flexibleSessionSchedule?.endDate, 'MMM DD, YYYY'),
//     description:
//       item?.extraDetails ||
//       (item?.additionalNotes?.trim() !== '' && item?.additionalNotes) ||
//       'No description is added.',
//   }))

//   const headers = [
//     { label: 'Key', key: 'key' },
//     { label: 'First Name', key: 'firstName' },
//     { label: 'Last Name', key: 'lastName' },
//     { label: 'Category', key: 'category' },
//     { label: 'Title', key: 'title' },
//     { label: 'Budget', key: 'budget' },
//     { label: 'Session Schedule', key: 'sessionSchedule' },
//     { label: 'Description', key: 'description' },
//   ]

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = (checkedValue, label) => {
//     setSelectedColumns(prev =>
//       checkedValue ? [...prev, label] : prev.filter(col => col !== label),
//     )
//   }

//   const handleExport = () => {
//     const filteredHeaders = headers.filter(header =>
//       selectedColumns.includes(header.label),
//     )
//     const filteredData = csvData.map(row => {
//       const filteredRow = {}
//       filteredHeaders.forEach(header => {
//         filteredRow[header.key] = row[header.key]
//       })
//       return filteredRow
//     })

//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     } else if (fileFormat === 'excel') {
//       const ws = XLSX.utils.json_to_sheet(filteredData)
//       const wb = XLSX.utils.book_new()
//       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
//       XLSX.writeFile(wb, 'Request_Table.xlsx')
//     } else if (fileFormat === 'pdf') {
//       const doc = new jsPDF()
//       const tableColumn = filteredHeaders.map(header => header.label)
//       const tableRows = filteredData.map(row =>
//         filteredHeaders.map(header => row[header.key]),
//       )

//       ;(doc as any).autoTable({
//         head: [tableColumn],
//         body: tableRows,
//       })

//       doc.save('Request_Table.pdf')
//     }
//     onClose()
//   }

//   return (
//     <Modal visible={visible} footer={false}>
//       <div className="popup-header ">
//         <h2>Export Data</h2>
//         <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
//       </div>
//       <div className="formatText ">Format </div>
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel">Excel</Radio>
//         <Radio value="pdf">PDF</Radio>
//       </Radio.Group>
//       <div className="radioText mt-15px">Please select columns to export </div>
//       <div className="checkbox-group">
//         {[
//           'First Name',
//           'Last Name',
//           'Category',
//           'Title',
//           'Budget',
//           'Session Schedule',
//           'Description',
//         ].map(label => (
//           <div key={label}>
//             <Checkbox
//               checked={selectedColumns.includes(label)}
//               onChange={e => handleColumnChange(e.target.checked, label)}
//             >
//               {label}
//             </Checkbox>
//           </div>
//         ))}
//       </div>
//       <div className="flex justify-end w-full mt-10px">
//         <div className="bg-grey radius-8 pointer mr-30px">
//           <button
//             className="prim-sm-btn"
//             style={{ background: '#EBEDF1', color: 'black' }}
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//         </div>
//         <button className="prim-sm-btn" onClick={handleExport}>
//           Export
//         </button>
//       </div>
//       <CSVLink
//         data={csvData}
//         headers={headers.filter(header =>
//           selectedColumns.includes(header.label),
//         )}
//         filename="Request_Table.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportRequest

