import React, { useState, useEffect } from 'react'
import { Modal, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Cancel from 'assets/Images/close.svg'
import VectorImg from 'assets/Images/Vector.svg'
import { successMessage, failureMessage } from '../../../api/global'
import './mentorStyle.scss'
import CsvImg from 'assets/Images/pdf 1.svg'
import CheckImg from 'assets/Images/checked.svg' // Assuming you have a checkmark image
import { importMentorFile, confirmImportData } from 'api/mentors'
import { useMutation } from '@tanstack/react-query'
import Loader from 'helper/Loader'
import { Button, Row, Col, Typography } from 'antd'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import warningImg from 'assets/Images/ImportWarning.svg'
import plusImg from 'assets/Images/ImportPlus.svg'
import { getMentors } from 'api/mentors'
import { read, utils } from 'xlsx'
const { Option } = Select
const attributeOptions = [
  // { key: 'email', display: 'Email', required: true },
  // { key: 'firstName', display: 'First Name', required: true },
  // { key: 'middleName', display: 'Middle Name', required: false },
  // { key: 'nickName', display: 'Nick Name', required: false },
  // { key: 'lastName', display: 'Last Name', required: true },
  // { key: 'gender', display: 'Gender', required: false },
  // { key: 'dob', display: 'Date of Birth', required: false },
  // { key: 'phone', display: 'Phone', required: false },
  // { key: 'street', display: 'street', required: false },
  // { key: 'city', display: 'city', required: false },
  // { key: 'state', display: 'state', required: false },
  // { key: 'zip', display: 'zip', required: false },
  // { key: 'country', display: 'country', required: false },
  // { key: 'allergies', display: 'Allergies', required: false },
  // { key: 'medicalNotes', display: 'Medical Notes', required: false },
  // { key: 'bio', display: 'Bio', required: false },
  // { key: 'skills', display: 'Skills', required: false },
  // // Add more attributes as needed

  { key: 'email', display: 'Email', required: true },
  { key: 'password', display: 'Password', required: true },
  { key: 'firstName', display: 'First Name', required: true },
  { key: 'middleName', display: 'Middle Name', required: false },
  { key: 'nickName', display: 'Nick Name', required: false },
  { key: 'lastName', display: 'Last Name', required: true },
  { key: 'gender', display: 'Gender', required: false },
  { key: 'dob', display: 'Date of Birth', required: false },
  { key: 'dialCode', display: 'Phone dial Code', required: true },
  { key: 'phone', display: 'Phone', required: true },
  { key: 'medicalNotes', display: 'Medical Notes', required: false },
  { key: 'bio', display: 'Bio', required: false },
]

const ImportMentorData = ({ visible, onClose }) => {
  const [fileList, setFileList] = useState([])
  const [importType, setImportType] = useState('Mentor')
  const [isMappingModalVisible, setIsMappingModalVisible] = useState(false)
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false)
  const [fileHeaders, setFileHeaders] = useState([])
  const [fileContent, setFileContent] = useState('') // Store file content
  const [attributeMapping, setAttributeMapping] = useState({})
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true)
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true)
  const [isResultModalVisible, setIsResultModalVisible] = useState(false)
  const [isLogsModalVisible, setIsLogsModalVisible] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)

  // const handleFileUpload = ({ file }) => {
  //   const reader = new FileReader()
  //   reader.onload = e => {
  //     const binaryStr = e.target.result
  //     // const workbook = read(binaryStr, { type: 'binary' })
  //     const workbook = read(binaryStr, { type: 'binary', cellText: false, cellDates: true })
  //     const sheetName = workbook.SheetNames[0]
  //     const sheet = workbook.Sheets[sheetName]
  //     // const csvData = utils.sheet_to_csv(sheet)
  //     const csvData = utils.sheet_to_csv(sheet, { raw: true })
  //     const headers = csvData.split('\n')[0].split(',')

  //     setFileHeaders(headers)
  //     setFileContent(csvData) // Store the CSV content
  //     setIsImportButtonDisabled(false)
  //     successMessage(`${file.name} file uploaded successfully`)
  //   }
  //   reader.onerror = () => {
  //     failureMessage(`${file.name} file upload failed.`)
  //   }

  //   // Read the file as binary string if it is xlsx or as text if it is csv
  //   if (
  //     file.type ===
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //   ) {
  //     reader.readAsBinaryString(file)
  //   } else {
  //     reader.readAsText(file)
  //   }
  // }
  const [parms, setParms] = useState<any>({
    page: 1,
    status: 'inactive',
    limit: 10,
  })
  const { isLoading, data, refetch } = useQuery(
    ['getMentors', parms],
    () => getMentors(parms),
    {
      enabled: false,
    },
  )
  const handleFileUpload = ({ file }) => {
    const reader = new FileReader()
    reader.onload = e => {
      const binaryStr = e.target.result
      const workbook = read(binaryStr, { type: 'binary', cellText: false, cellDates: true })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
  
      // Ensure phone numbers and other large numbers are read as text
      const csvData = utils.sheet_to_csv(sheet, { rawNumbers: true })
      const headers = csvData.split('\n')[0].split(',')
  
      setFileHeaders(headers)
      setFileContent(csvData) // Store the CSV content
      setIsImportButtonDisabled(false)
      successMessage(`${file.name} file uploaded successfully`)
    }
    reader.onerror = () => {
      failureMessage(`${file.name} file upload failed.`)
    }
  
    // Read the file as binary string if it is xlsx or as text if it is csv
    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsText(file)
    }
  }
  
  // const handleFileUpload = ({ file }) => {
  //   const reader = new FileReader()
  //   reader.onload = e => {
  //     const content: any = e.target.result
  //     const headers = content.split('\n')[0].split(',')
  //     console.log(headers)
  //     console.log(content)
  //     setFileHeaders(headers)
  //     setFileContent(content) // Store the file content
  //     setIsImportButtonDisabled(false)
  //     successMessage(`${file.name} file uploaded successfully`)
  //   }
  //   reader.onerror = () => {
  //     failureMessage(`${file.name} file upload failed.`)
  //   }
  //   reader.readAsText(file)
  // }

  const uploadProps = {
    beforeUpload: file => {
      handleFileUpload({ file })
      return false // Prevent automatic upload
    },
  }

  const handleAttributeChange = (attribute, value) => {
    setAttributeMapping({ ...attributeMapping, [attribute]: value })
  }
  const importMentorMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await importMentorFile(payload)
    },
    onSuccess: data => {
    
      // successMessage('Mentors imported successfully')
      setIsReviewModalVisible(false)
      // onClose()
      setApiResponse(data)
      setIsResultModalVisible(true)
    },
    // onError: data => {
    //   failureMessage('Failed to import mentors')
    // },
  })
  const confirmImportMutation = useMutation({
    mutationFn: async ({ payload }: any) => {
      return await confirmImportData(payload)
    },
    onSuccess: data => {
      if (data) {
        successMessage(data)
        setIsResultModalVisible(false)
        onClose()
        refetch()

      }

      // debugger

      // console.log(data, 'message')
    },
  })
  // old function to handle import
  // const handleImport = () => {
  //   const lines = fileContent.split('\n')
  //   const headers = lines[0].split(',')

  //   // Initialize an array to store the mapped data
  //   const mappedData = []

  //   // Map the data
  //   for (let i = 1; i < lines.length; i++) {
  //     if (!lines[i]) continue
  //     const values = lines[i].split(',')
  //     const mappedRow = {}

  //     attributeOptions.forEach(attr => {
  //       const csvColumn = attributeMapping[attr.key]
  //       if (csvColumn && csvColumn !== 'Do not import') {
  //         const columnIndex = headers.indexOf(csvColumn)
  //         mappedRow[attr.key] = values[columnIndex].replace(/(^"|"$)/g, '')
  //       }
  //     })

  //     mappedData.push(mappedRow)
  //   }

  //   // Implement your data import logic here
  //   // console.log('Mapped Data:', mappedData)
  //   importMentorMutation.mutate({ payload: mappedData })
  // }
  // const handleImport = () => {
  //   const lines = fileContent.split('\n')
  //   const headers = lines[0].split(',')
  
  //   // Initialize an array to store the mapped data
  //   const mappedData = []
  
  //   // Map the data
  //   for (let i = 1; i < lines.length; i++) {
  //     if (!lines[i].trim()) continue // skip empty lines
  //     const values = lines[i].split(',')
  //     const mappedRow = {}
  
  //     let hasValidData = false
  
  //     attributeOptions.forEach(attr => {
  //       const csvColumn = attributeMapping[attr.key]
  //       if (csvColumn && csvColumn !== 'Do not import') {
  //         const columnIndex = headers.indexOf(csvColumn)
  //         if (columnIndex !== -1) {
  //           const value = values[columnIndex].trim().replace(/(^"|"$)/g, '')
  //           mappedRow[attr.key] = value
  //           if (value) {
  //             hasValidData = true
  //           }
  //         }
  //       }
  //     })
  
  //     if (hasValidData) { // only add if there is at least one valid data point
  //       mappedData.push(mappedRow)
  //     }
  //   }
  
  //   // Implement your data import logic here
  //   // console.log('Mapped Data:', mappedData)
  //   importMentorMutation.mutate({ payload: mappedData })
  // }
  const handleImport = () => {
    const lines = fileContent.split('\n');
    const headers = lines[0].split(',');
    const mappedData = [];
    // Iterate over each data line
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) continue; // skip empty lines
      const values = lines[i].split(',');
      const mappedRow = {};
      let hasValidData = false;
      attributeOptions.forEach(attr => {
        const csvColumn = attributeMapping[attr.key];
        if (csvColumn && csvColumn !== 'Do not import') {
          const columnIndex = headers.indexOf(csvColumn);
          if (columnIndex !== -1) {
            let value = values[columnIndex].trim().replace(/(^"|"$)/g, '');
            value = value.toString();
            if (attr.key === 'phone' && (value.includes('E') || value.includes('e'))) {
              value = parseFloat(value).toFixed(0);
            }
            mappedRow[attr.key] = value;
            if (value) {
              hasValidData = true;
            }
          }
        }
      });
      if (hasValidData) {
        mappedData.push(mappedRow);
      }
    }
    importMentorMutation.mutate({ payload: mappedData });  
  };
  
  const handleComplete = () => {
    const payload = {
      validationId: apiResponse?.validationId,
      confirm: true,
    }
    // console.log(payload)
    confirmImportMutation.mutate({ payload: payload })
  }
  const CancelImport = () => {
    const payload = {
      validationId: apiResponse?.validationId,
      confirm: false,
    }
    confirmImportMutation.mutate({ payload: payload })
    // setIsResultModalVisible(false)
    setIsResultModalVisible(false)
    onClose()
  }

  useEffect(() => {
    const areAllRequiredAttributesMapped = attributeOptions
      .filter(attr => attr.required)
      .every(
        attr =>
          attributeMapping[attr.key] &&
          attributeMapping[attr.key] !== 'Do not import',
      )

    setIsContinueButtonDisabled(!areAllRequiredAttributesMapped)
  }, [attributeMapping])

  const resetMapping = () => {
    const newMapping = {}
    attributeOptions.forEach(attr => {
      newMapping[attr.key] = 'Do not import'
    })
    setAttributeMapping(newMapping)
  }

  const handleBack = () => {
    resetMapping()
    setIsMappingModalVisible(false)
  }

  const handleReview = () => {
    setIsMappingModalVisible(false)
    setIsReviewModalVisible(true)
  }
  const downloadCSV = () => {
    const logs = apiResponse?.logs || []
    const headers = ['Row', 'Message']
    // const rows = logs.map(log => [log.row + 1, log.msg]) remove the plus one row in csv
    const rows = logs.map(log => [log.row + 1, log.msg])
    let csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers, ...rows].map(e => e.join(',')).join('\n')

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'logs.csv')
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        onCancel={onClose}
        className="import-modal"
      >
        <div className="popup-header">
          <h2 className="importText">Import Data</h2>
          <img src={Cancel} alt="close button" onClick={onClose} />
        </div>
        <Select
          defaultValue={importType}
          style={{
            width: '100%',
            height: '40px',
            marginBottom: 16,
            marginTop: 10,
          }}
        >
          {/* <Option value="Create event">Mentor</Option> */}
          {/* Add more options as needed */}
        </Select>
        <Upload.Dragger
          {...uploadProps}
          accept=".csv,.xlsx"
          fileList={fileList}
          onChange={info => setFileList(info.fileList)}
        >
          <p className="ant-upload-drag-icon">
            <img alt="VectorImg" src={VectorImg} />
          </p>
          <p className="dragText">
            Drag and drop document here or
            <b className="uploadText"> upload document</b>
          </p>
          <p className="ant-upload-hint" style={{ marginTop: '10px' }}>
            CSV or XLSX formats
          </p>
        </Upload.Dragger>
        <div className="flex justify-end w-full mt-20">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="prim-sm-btn"
            disabled={isImportButtonDisabled}
            onClick={() => setIsMappingModalVisible(true)}
          >
            Import
          </button>
        </div>
      </Modal>
      <Modal
        visible={isMappingModalVisible}
        footer={null}
        onCancel={handleBack}
        className="mapping-modal"
      >
        <div>
          <div className="popup-header-2">
            <h2>Customize your attributes</h2>
            <img src={Cancel} alt="close button" onClick={handleBack} />
          </div>
          <p className="attributeText">
            Select the correct attribute for each column you’d like to import,
            or create a new one.
          </p>
          <p className="schema-Text">Schema mapping</p>
          <div className="schema-mapping-header">
            <span className="attribute-header">Attribute</span>
            <span className="csv-column-header">CSV column</span>
          </div>
          <div className="schema-mapping h-25rem overflow-auto">
            {attributeOptions.map(attr => (
              <div key={attr.key} className="mapping-row">
                <div className="fleximg">
                  <img
                    alt="csv image"
                    src={CsvImg}
                    style={{ marginRight: '5px' }}
                  />
                  <span
                    className={`attribute-label position-relative ${
                      attr.required ? 'required-input' : ''
                    }`}
                  >
                    {attr.display}
                  </span>
                </div>
                <Select
                  value={attributeMapping[attr.key] || 'Do not import'}
                  className="csv-column-select"
                  onChange={value => handleAttributeChange(attr.key, value)}
                >
                  <Option value="Do not import">Do not import</Option>
                  {fileHeaders.map(header => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
              </div>
            ))}
          </div>
          <div className="flex justify-end w-full mt-20">
            <button
              className="prim-sm-btn"
              style={{ background: '#EBEDF1', color: 'black' }}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className="prim-sm-btn"
              disabled={isContinueButtonDisabled}
              onClick={handleReview}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isReviewModalVisible}
        footer={null}
        onCancel={() => setIsReviewModalVisible(false)}
        className="review-modal"
      >
        <div>
          <div className="popup-header-2">
            <h2>Review values</h2>
            <img
              src={Cancel}
              alt="close button"
              // onClick={handleBack}
              // onClick={() => (setIsReviewModalVisible(false), handleBack)}
              onClick={() => {
                setIsReviewModalVisible(false)
                setIsMappingModalVisible(true)
              }}
            />
          </div>
          <p className="attributeText">
            We’va created the values we found in your data. Check that the
            values are correct and resolve any that are invalid.
          </p>
          <p className="schema-Text">Schema mapping</p>
          <div className="schema-mapping-header">
            <span className="attribute-header">Attribute</span>
            <span className="csv-column-header">CSV column</span>
          </div>
          <div className="schema-mapping h-25rem overflow-auto">
            {Object.keys(attributeMapping).map(
              key =>
                attributeMapping[key] !== 'Do not import' && (
                  <div key={key} className="mapping-row">
                    <div className="fleximg-2">
                      <img
                        alt="check image"
                        src={CheckImg}
                        style={{ marginRight: '10px' }}
                      />
                      <img
                        alt="csv image"
                        src={CsvImg}
                        style={{ marginRight: '8px' }}
                      />

                      <span className="attribute-label position-relative">
                        {
                          attributeOptions.find(attr => attr.key === key)
                            .display
                        }
                      </span>
                    </div>
                    <span className="csv-column-label">
                      {attributeMapping[key].replace(/"/g, '')}
                    </span>
                  </div>
                ),
            )}
          </div>
          <div className="flex justify-end w-full mt-20">
            <button
              className="prim-sm-btn"
              style={{
                background: '#EBEDF1',
                color: 'black',
                marginRight: '10px',
              }}
              onClick={() => {
                setIsReviewModalVisible(false)
                setIsMappingModalVisible(true)
              }}
            >
              Back
            </button>
            <button className="prim-sm-btn" onClick={handleImport}>
              {importMentorMutation.isLoading ? <Loader /> : 'Continue'}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isResultModalVisible}
        onCancel={() => setIsResultModalVisible(false)}
        footer={null}
        className="completeModal"
      >
        <div className="popup-header-2">
          <h2>Complete import</h2>
          <img
            src={Cancel}
            alt="close button"
            onClick={() => {
              setIsResultModalVisible(false)
            }}
          />
        </div>
        <div className="d-flex mt-20">
          <div className="recordFill ">
            <div className="flex">
              <img src={warningImg} alt="warningImg" />
              <span className="failedText">{apiResponse?.stats?.invalid}</span>
            </div>
            <span className="text">Records failed</span>
          </div>
          <div className="recordFill" style={{ marginLeft: '10px' }}>
            <div className="flex">
              <img src={plusImg} alt="plusImg" />
              <span className="SuccessText">{apiResponse?.stats?.valid}</span>
            </div>
            <span className="text">Records will be added</span>
          </div>
        </div>
        <div className="flex justify-end w-full mt-20">
          {apiResponse?.stats?.invalid > 0 && (
            <>
              <button
                className="prim-sm-btn"
                style={{
                  background: 'transparent',
                  border: '1px solid #2BA7CA',
                  color: '#2BA7CA',
                  marginRight: '10px',
                }}
                onClick={CancelImport}
              >
                Cancel
              </button>
              <button
                className="prim-sm-btn"
                style={{
                  background: 'transparent',
                  border: '1px solid #2BA7CA',
                  color: '#2BA7CA',
                  marginRight: '10px',
                }}
                onClick={() => {
                  setIsLogsModalVisible(true)
                }}
              >
                View logs
              </button>
            </>
          )}
          <button className="prim-sm-btn" onClick={handleComplete}>
            {confirmImportMutation.isLoading ? <Loader /> : 'Complete'}
          </button>
        </div>
      </Modal>

      <Modal visible={isLogsModalVisible} footer={null} className="logsModal">
        <div className="popup-header-2">
          <h2>Logs</h2>
          <img
            src={Cancel}
            alt="close button"
            onClick={() => {
              setIsLogsModalVisible(false)
            }}
          />
        </div>

        <div className="schema-mapping h-25rem overflow-auto">
          <table>
            <thead>
              <tr>
                <th colSpan={1}></th>
                <th>Row</th>
                <th colSpan={10}></th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {apiResponse?.logs.map((log, index) => (
                <tr key={index}>
                  <td colSpan={1}></td>
                  <td style={{ textAlign: 'center' }} className="errorMessage">
                    {log.row + 1}
                  </td>
                  <td colSpan={10}></td>
                  <td className="errorMessage">{log.msg}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end w-full mt-20">
          <button
            className="prim-sm-btn"
            style={{
              background: 'transparent',
              border: '1px solid #2BA7CA',
              color: '#2BA7CA',
              marginRight: '10px',
            }}
            onClick={() => {
              setIsLogsModalVisible(false)
            }}
          >
            Back
          </button>
          <button className="prim-sm-btn" onClick={downloadCSV}>
            Download
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ImportMentorData
