import { useQuery } from '@tanstack/react-query'
import { getAllJobs } from 'api/Jobs'
import Spinner from 'components/Spinner'
import { useEffect, useState } from 'react'
import { RequestParms } from 'types/globalTypes'
import AppLayout from '../../components/layout/layout'
import JobsHeader from './Header'
import JobsTable from './Table/JobsTable'
import { ApiResponse } from './types'

const Jobs = () => {
  const [params, setParams] = useState<RequestParms>({
    page: 1,
    limit: 10,
  })
  const { data, isLoading, isFetching, refetch } = useQuery<ApiResponse>(
    ['getMentorProfile'],
    () => {
      return getAllJobs(params)
    },
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [params])

  return (
    <AppLayout>
      <div className="page-content">
        <JobsHeader fullData={data?.records} />
        {isLoading || isFetching ? (
          <div className="w-full h-80vh flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <JobsTable data={data} params={params} setParams={setParams} />
        )}
      </div>
    </AppLayout>
  )
}
export default Jobs
