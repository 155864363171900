import React from 'react'
import likeemojis from './../../assets/Images/likeemojis.svg'
import like from './../../assets/Images/like.svg'
import love from './../../assets/Images/love.svg'
import information from '../../assets/Images/information.svg'
const LikeCount = ({ reactions }: any) => {
  const reactionsImages = (reactions: any) => {
    if (!reactions || reactions.length === 0) {
      return 'none'
    }

    let hasLike = false
    let hasLove = false
    let hasInformative = false

    for (const reaction of reactions) {
      if (reaction.reaction === 'like') {
        hasLike = true
      } else if (reaction.reaction === 'love') {
        hasLove = true
      } else if (reaction.reaction === 'informative') {
        hasInformative = true
      }
    }

    if (hasLike && hasLove && hasInformative) {
      return 'all'
    } else if (hasLike && hasLove) {
      return 'loveLike'
    } else if (hasLike && hasInformative) {
      return 'likeInformative'
    } else if (hasLove && hasInformative) {
      return 'loveInformative'
    } else if (hasLike) {
      return 'like'
    } else if (hasLove) {
      return 'love'
    } else if (hasInformative) {
      return 'informative'
    } else {
      return 'none'
    }
  }
  return (
    <div>
      {reactionsImages(reactions) === 'all' ? (
        <img src={likeemojis} width={40} height={40} alt="like" />
      ) : reactionsImages(reactions) === 'loveLike' ? (
        <div className="d-flex align-items-center">
          <img src={like} width={40} height={40} alt="like" />
          <img src={love} width={40} height={40} alt="like" />
        </div>
      ) : reactionsImages(reactions) === 'likeInformative' ? (
        <div className="d-flex align-items-center">
          <img src={like} width={40} height={40} alt="like" />
          <img src={information} width={40} height={40} alt="like" />
        </div>
      ) : reactionsImages(reactions) === 'loveInformative' ? (
        <div className="d-flex align-items-center">
          <img src={love} width={40} height={40} alt="like" />
          <img src={information} width={40} height={40} alt="like" />
        </div>
      ) : reactionsImages(reactions) === 'like' ? (
        <img src={like} width={40} height={40} alt="like" />
      ) : reactionsImages(reactions) === 'love' ? (
        <img src={love} width={40} height={40} alt="like" />
      ) : reactionsImages(reactions) === 'informative' ? (
        <img src={information} width={40} height={40} alt="like" />
      ) : (
        <img src={like} width={40} height={40} alt="like" />
      )}
    </div>
  )
}
export default LikeCount
