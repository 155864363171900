import GetAvatar from 'components/global/GetAvatar'
import Spinner from 'components/Spinner'
import { convertToLocal, getUserName } from 'helper/utility'
import { Attendees, CheckInData } from 'pages/Events/types'
import { ChangeEvent, useState } from 'react'
// import Input from 'antd/es/input'
interface Details {
  participant: Attendees
  isLoading: boolean
  data: CheckInData
  setVal: React.Dispatch<React.SetStateAction<string>>
}
const Details: React.FC<Details> = ({
  participant,
  data,
  isLoading,
  setVal,
}) => {
  const [email, setEmail] = useState<string>()
  const handelInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handleFinsih = () => {
    if (email) {
      setVal(email)
    }
  }

  const [isEmailValid, setEmailValid] = useState(false)

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value
    setEmail(emailValue)
    setEmailValid(validateEmail(emailValue))
  }

  const handleFinish = () => {
    if (isEmailValid) {
      setVal(email)
    }
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return (
    <>
      {isLoading ? (
        <div className="flex-center h-20 justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="mt-30px ">
          <div className="flex-center">
            <GetAvatar person={participant} size={40} />
            <h4 className="ml-10px text-16 w-500">
              {getUserName(participant)}
            </h4>
          </div>
          <div className="mt-20px">
            <h3 className="text-14 w-600">Ticket details</h3>
            <div className="flex justify-between mt-20px">
              <h4 className="text-14 w-400">Dropin (per {data?.cost.unit})</h4>
              <h4 className="text-14 w-400">${data?.cost.price}</h4>
            </div>
            <div className="flex justify-between mt-20px">
              <h4 className="text-20 w-600">Total</h4>
              <h4 className="text-20 w-600">${data?.cost.amount}</h4>
            </div>
          </div>
          <div className="mt-20px">
            <h3 className="text-14 w-600">History</h3>
            <div
              className=" mt-20px h-20vh overflow-auto "
              // style={{ background: 'rgba(235, 237, 241, 1)' }}
            >
              {data?.checkIns.map(checkins => {
                return (
                  <div key={checkins._id}>
                    {checkins.checkInStatus == 'droped-in' &&
                    checkins.checkOutStatus !== 'dropped-out' ? (
                      <>
                        <div
                          className="flex justify-between bg-white  mt-8px mb-8px"
                          style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                            borderTop: '2px solid #ccc', // Gray border, 1px solid on the top
                            borderBottom: '2px solid #ccc', // Gray border, 1px solid on the bottom
                            // border: '1px solid #ccc', // Gray border, 1px solid with color #ccc
                            // padding: '8px',
                          }}
                        >
                          <div>
                            <h4 className="text-12 w-400">Check-in Person</h4>
                            <h4 className="text-14 w-600">
                              {getUserName(
                                checkins.checkInOrganizer ||
                                  checkins.checkInGuardian ||
                                  checkins.checkInMentor,
                              )}
                            </h4>
                          </div>
                          <div className="flex-center mt-5px mb-5px">
                            <div className="bg-grey px-15px py-10px radius-8">
                              <h4>
                                {convertToLocal(
                                  checkins.checkedInAt,
                                  'hh: mm A',
                                )}
                              </h4>
                            </div>
                            {/* <img
                            src={Edit}
                            alt="edit"
                            className="ml-10px w-25px"
                          /> */}
                          </div>
                        </div>
                        {/* <div className="flex justify-between">
                          <div>
                            <h4 className="text-12 w-400">Check-out Person</h4>
                            <h4 className="text-14 w-600">
                              {getUserName(
                                checkins.checkInOrganizer ||
                                  checkins.checkInGuardian ||
                                  checkins.checkInMentor,
                              )}
                            </h4>
                          </div>
                          <div className="flex-center mt-5px">
                            <div className="bg-grey px-15px py-10px radius-8">
                              <h4>
                                {convertToLocal(
                                  checkins.checkedOutAt,
                                  'hh: mm A',
                                )}
                              </h4>
                            </div>
                          </div>
                        </div> */}
                      </>
                    ) : checkins.checkOutStatus == 'dropped-out' ? (
                      <>
                        <div
                          style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                            borderTop: '2px solid #ccc',
                            borderBottom: '2px solid #ccc',
                          }}
                        >
                          <div className="flex justify-between bg-white mt-8px mb-8px">
                            <div>
                              <h4 className="text-12 w-400">Check-in Person</h4>
                              <h4 className="text-14 w-600">
                                {getUserName(
                                  checkins.checkInOrganizer ||
                                    checkins.checkInGuardian ||
                                    checkins.checkInMentor,
                                )}
                              </h4>
                            </div>
                            <div className="flex-center mt-5px mb-5px">
                              <div className="bg-grey px-15px py-10px radius-8">
                                <h4>
                                  {convertToLocal(
                                    checkins.checkedInAt ||
                                      checkins.checkInRequestedAt,
                                    'hh: mm A',
                                  )}
                                </h4>
                              </div>
                              {/* <img
                            src={Edit}
                            alt="edit"
                            className="ml-10px w-25px"
                          /> */}
                            </div>
                          </div>
                          <div className="flex justify-between bg-white mt-8px mb-8px">
                            <div>
                              <h4 className="text-12 w-400">
                                Check-out Person
                              </h4>
                              <h4 className="text-14 w-600">
                                {getUserName(
                                  checkins.checkOutOrganizer ||
                                    checkins.checkOutGuardian ||
                                    checkins.checkOutMentor,
                                )}
                              </h4>
                            </div>
                            <div className="flex-center mt-5px mb-5px">
                              <div className="bg-grey px-15px py-10px radius-8">
                                <h4>
                                  {convertToLocal(
                                    checkins.checkedOutAt ||
                                      checkins.checkOutRequestedAt,
                                    'hh: mm A',
                                  )}
                                </h4>
                              </div>
                              {/* <img
                            src={Edit}
                            alt="edit"
                            className="ml-10px w-25px"
                          /> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          {/* <div>
            <label className="mt-20px">Parent's email</label>

            <input
              className="my-input mt-10px w-full"
              type={'email'}
              value={email}
              onChange={handelInput}
            />
            <button
              className="form-btn pointer w-full mt-20px"
              onClick={handleFinsih}
            >
              Find account
            </button>
          </div> */}

          <div>
            <label className="mt-20px">Parent's email</label>
            <input
              className={`my-input mt-10px w-full ${
                isEmailValid ? 'valid-input' : 'invalid-input'
              }`}
              type="email"
              value={email}
              onChange={handleInput}
            />
            {!isEmailValid && email && (
              <div
                className=" text-sm mt-2"
                style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}
              >
                Please enter a valid email address.
              </div>
            )}
            <button
              className="form-btn pointer w-full mt-20px"
              onClick={handleFinish}
              disabled={!isEmailValid}
            >
              Find account
            </button>
          </div>
        </div>
      )}
    </>
  )
}
export default Details
