import { Row, Col, Button, Space, Dropdown, Menu } from 'antd'
import { CSVLink } from 'react-csv'
import printerImg from '../../../assets/Images/Printer.svg'
import downloadImg from '../../../assets/Images/Download.svg'
import statusimg from '../../../assets/Images/Activity.svg'
import deleteImg from '../../../assets/Images/delete_white.svg'
import FilePlus from '../../../assets/Images/FilePlus.svg'
import { DownOutlined } from '@ant-design/icons'
import { useState } from 'react'
import ExportMentor from './ExportMentor'
import ImportMentorData from './ImportMentor'
const colors = ['blue']
const PageNameHeader = ({
  Rows,
  setAlert,
  data,
  fullData,
  deleteMulti,
  newMentor,
}: any) => {
  const csvData = data.map(({ item, index }: any) => {
    return {
      key: index,
      name: item.personalDetails.firstname,
      email: item.personalDetails.email,
      phone: item.personalDetails.phone.phone,
      dob: item.personalDetails.dob,
      status: 'active',
    }
  })
  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone' },
    { label: 'Date of Birth	', key: 'dob' },
    { label: 'Status', key: 'status' },
  ]
  const [isExportModalVisible, setExportModalVisible] = useState(false)

  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const handleModalClose = () => {
    setExportModalVisible(false)
  }

  const [isImportModalVisible, setIsImportModalVisible] = useState(false)

  const handleImportClick = () => {
    setIsImportModalVisible(true)
  }

  const handleImportModalClose = () => {
    setIsImportModalVisible(false)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Space onClick={handleExportClick}>
          <img alt="img" src={downloadImg} /> Export
        </Space>
        <Space className="mt-10" onClick={handleImportClick}>
          <img alt="importIcon" src={FilePlus} /> Import
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <Row align="middle" className="page-header" justify="space-between">
      <Col lg={{ span: 6 }} xs={{ span: 24 }}>
        <h1 className="table-head">Mentors</h1>
      </Col>
      <Col lg={{ span: 18 }} offset={6} xs={{ span: 24, offset: 0 }}>
        {Rows.length > 0 ? (
          <Space
            className="organizations-table-onselect-btn"
            size={10}
            align="end"
          >
            <Button
              className={'bg-grey br-8'}
              icon={<img alt="" src={statusimg}></img>}
              onClick={() => {
                setAlert(true)
              }}
            >
              Status
            </Button>
            <Button
              className={'bg-grey br-8'}
              onClick={() => {
                window.print()
              }}
              icon={
                <img alt="" src={printerImg} style={{ padding: '1%' }}></img>
              }
            >
              Print
            </Button>
            <Button
              className={'bg-grey br-8'}
              icon={<img alt="" src={downloadImg}></img>}
            >
              <CSVLink
                data={csvData}
                headers={headers}
                style={{ color: 'black', padding: '1%' }}
                filename="mentors.csv"
                target="_blank"
              >
                Export
              </CSVLink>
            </Button>
            <Button
              className={'bg-grey br-8'}
              onClick={() => {
                deleteMulti()
              }}
              icon={
                <img alt="" src={deleteImg} style={{ padding: '1%' }}></img>
              }
            >
              Delete
            </Button>
          </Space>
        ) : (
          <div className="right-search-block">
            <div
              className="table-action-btn new-organizations-export-btn"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {/* {colors.map((color) => (
                <CSVLink
                  style={{ color: "#000000" }}
                  data={csvData}
                  headers={headers}
                  filename="mentors.csv"
                  target="_blank"
                >
                  <Button
                    icon={<img alt="" src={downloadImg}></img>}
                    className={"btn-export bg-grey br-8"}
                  >
                    Export
                  </Button>
                </CSVLink>
              ))} */}
              {localStorage.getItem('role') === 'admin' && (
                <Dropdown
                  overlay={menu}
                  placement="bottom"
                  className="bg-grey mx-20px"
                >
                  <button className=" prim-sm-btn" style={{ color: 'black' }}>
                    More <DownOutlined />
                  </button>
                </Dropdown>
              )}

              <Button
                className={'btn-new primary br-8'}
                onClick={() => {
                  newMentor(true)
                }}
              >
                New mentor
              </Button>
            </div>
          </div>
        )}
      </Col>
      <ExportMentor
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={fullData}
      />
      {isImportModalVisible && (
        <ImportMentorData
          visible={isImportModalVisible}
          onClose={handleImportModalClose}
        />
      )}
    </Row>
  )
}
export default PageNameHeader
