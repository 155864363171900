import { Button, Col, Form, Input, Layout, Row, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import List from "../../../../assets/Images/List.svg";
import GrayClose from "../../../../assets/Images/GrayClose.svg";
import React from "react";

const AddActivityForm = () => {
  return (
    <Layout>
      <Row>
        <Col span={12}>
          <label className="input-label">Activity name</label>
          <Form.Item>
            <Input placeholder="Activity name"></Input>
          </Form.Item>
        </Col>
        <Col>
          <label className="input-label">Plan/Description</label>
          <Form.Item>
            <TextArea rows={4} placeholder="Short description of the activity" maxLength={6} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <label className="input-label">Number of Kids</label>
          <Form.Item>
            <Input placeholder="0"></Input>
          </Form.Item>
        </Col>
        <Col span={2} style={{margin: "45px 10px auto 10px"}}>
          <h5>kids</h5>
        </Col>
        <Col>
          <Form.Item>
            <label className="input-label">Location</label>
            <Form.Item>
              <Row gutter={10}>
                <Col span={4}>
                  <Button className="secondary br-8">Indoors</Button>
                </Col>
                <Col span={4}>
                  <Button className="secondary br-8">Outdoors</Button>
                </Col>
              </Row>
            </Form.Item>
          </Form.Item>
        </Col>
        <Col>
          <label className="input-label">Age</label>

          <Form.Item>
            <Row>
              <Col span={4}>
                <Button className="secondary br-8">3-5</Button>
              </Col>
              <Col span={4}>
                <Button className="secondary br-8">6-9</Button>
              </Col>
              <Col span={4}>
                <Button className="secondary br-8">10-13</Button>
              </Col>
              <Col span={4}>
                <Button className="secondary br-8">14+</Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button className="stepper-button">Save</Button>
      </Row>
      
    </Layout>
  );
};

export default AddActivityForm;
