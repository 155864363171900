import { Button, Col, Modal, Row, Space } from 'antd'
import { useDispatch } from 'react-redux'
import familyActions from '../Redux/Family/action'
import eventVenueActions from '../Redux/EventVenue/action'
import organizerActions from '../Redux/Organizer/action'
import { history } from '../Redux/app-store'
import ButtonSpinner from 'components/global/ButtonSpinner'

const AlertModal = ({
  isModalVisible,
  setIsModalVisible,
  data,
  dataItem,
  onYesClick,
  deleteOrgAdmin,
  loading,
}: any) => {
  const dispatch = useDispatch()
  const handleOk = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleDelete = () => {
    if (onYesClick) {
      onYesClick()
    } else {
      if (data.name === 'adult') {
        dispatch({
          type: familyActions.DELETE_AUTHORIZED_ADULT,
          adultId: dataItem?._id,
        })
      } else if (data.name === 'children') {
        dispatch({
          type: familyActions.DELETE_KID,
          kidId: dataItem?.id,
        })
      } else if (data.name === 'Orgadmins') {
        deleteOrgAdmin('archived')
      } else if (data.name === 'blockOrg') {
        console.log('firebase Id ', data.firebaseID)
        dispatch({
          type: organizerActions.BLOCK_ORGANIZER_EMAIL,
          payload: data.firebaseID,
        })
      } else {
        setIsModalVisible(false)
      }

      if (
        data.name !== 'venue' &&
        data.name !== 'adult' &&
        data.name !== 'children' &&
        data.name !== 'organizations' &&
        data.name !== 'organizer'
      ) {
        history.goBack()
        setIsModalVisible(false)
      }

      // my code
      else {
        setIsModalVisible(false)
      }
    }
  }

  return (
    <>
      <Modal
        className="delete-modal"
        open={isModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        footer={null}
        centered={true}
        onCancel={handleCancel}
        width={438}
        closable={data?.closeIcon}
      >
        <Row gutter={[0, 40]} justify="center">
          <Col span={24}>
            <div className="text-center">
              <Space direction="vertical">
                <h3 className="b1">{data?.heading}</h3>
                <div
                  className="b3"
                  dangerouslySetInnerHTML={{ __html: data?.message1 }}
                ></div>
                {/* <h5 className="b3">{data?.message1}</h5> */}
                <h5 className="b1">{data?.message2}</h5>
              </Space>
            </div>
          </Col>
          <Col>
            <div>
              <Space size={20}>
                <Button
                  onClick={handleCancel}
                  className="alert_delete_button bg-grey"
                >
                  Cancel
                </Button>
                <Button
                  className="alert_delete_button text-white"
                  onClick={() => {
                    handleDelete()
                  }}
                >
                  {/* {data?.okText ? data?.okText : 'Yes, delete'} */}
                  {loading ? (
                    <ButtonSpinner size={20} color="#000" />
                  ) : data?.okText ? (
                    data?.okText
                  ) : (
                    'Yes, delete'
                  )}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default AlertModal
