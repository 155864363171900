import { Modal } from 'antd'
import Cancel from 'assets/Images/close.svg'
import CheckInHeader from './CheckInHeader'

import moment from 'moment'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
import { useQuery } from '@tanstack/react-query'
import { getEventAttendeesStatus } from 'api/Events'
import {
  AtendeeStatusParams,
  AtendeeResponse,
  Attendees,
  attendeeStatus,
} from 'pages/Events/types'
import { convertToUtc } from 'helper/utility'
import ParticpantsList from './ParticpantsList'
import RollCallList from './RollCallList'
import { myStore } from 'state/eventStore'
interface CheckInOut {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const CheckInOut = ({ open, setOpen }: CheckInOut) => {
  const { id }: any = useParams()
  const eventId: string = id
  const location = useLocation()
  const event: any = location.state
  const currentDate = moment().startOf('day')
  const tag = myStore(store => store.tag)
  const setTag = myStore(store => store.setTag)

  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)

  const [params, setParams] = useState<AtendeeStatusParams>({
    eventId,
    date: convertToUtc(selectedDate),
  })
  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams,
      date: convertToUtc(selectedDate),
    }))
  }, [selectedDate])
  const { data, refetch, isLoading, isFetching } = useQuery<AtendeeResponse>(
    ['getAttendieStatus', params],
    () => getEventAttendeesStatus(params),
    { enabled: false },
  )
  const [particpantStatus, setParticpantStatus] = useState<Attendees[]>()
  const [switchView, setSwitch] = useState<'particpants' | 'roll-calls'>(
    'particpants',
  )
  useEffect(() => {
    switchView === 'particpants' && refetch()
  }, [params])
  useEffect(() => {
    if (tag) {
      refetch()
      setTag(false)
    }
  }, [tag])
  useEffect(() => {
    const mapParticipantsWithType = (participants: any, type: attendeeStatus) =>
      participants.map((participant: any) => ({
        ...participant,
        type,
        participantType: participant.type,
      }))

    const participantsWithTypes = [
      ...mapParticipantsWithType(data?.kids || [], 'kid'),
      ...mapParticipantsWithType(data?.guardians || [], 'guardian'),
      ...mapParticipantsWithType(
        data?.authorizedAdults || [],
        'authorizedAdult',
      ),
      ...mapParticipantsWithType(data?.dropInAttendees || [], 'dropInAttendee'),
    ]
    setParticpantStatus(participantsWithTypes)
  }, [data])

  // useEffect(() => {
  //   const mapParticipantsWithType = (participants: any, type: attendeeStatus) =>
  //     participants.map((participant: any) => ({ ...participant, type }))

  //   const participantsWithTypes = [
  //     ...mapParticipantsWithType(data?.kids || [], 'kid'),
  //     ...mapParticipantsWithType(data?.guardians || [], 'guardian'),
  //     ...mapParticipantsWithType(
  //       data?.authorizedAdults || [],
  //       'authorizedAdult',
  //     ),
  //     ...mapParticipantsWithType(data?.dropInAttendees || [], 'dropInAttendee'),
  //   ]
  //   setParticpantStatus(participantsWithTypes)
  // }, [data])

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Modal open={open} onCancel={handleCancel} className="checkInModal">
      <div className="popup-header border-btm">
        <div>
          <h2>Check-in/out</h2>
        </div>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <CheckInHeader
        params={params}
        setParms={setParams}
        setView={setSwitch}
        currentView={switchView}
        participantData={particpantStatus!}
        date={selectedDate}
      />
      <div className="flex justify-between pb-40px">
        <div className="w-55p h-30rem overflow-auto">
          {switchView === 'particpants' ? (
            <ParticpantsList
              isLoading={isLoading || isFetching}
              particpantStatus={particpantStatus}
              date={selectedDate}
            />
          ) : switchView === 'roll-calls' ? (
            <RollCallList date={selectedDate} />
          ) : (
            <></>
          )}
        </div>

        <div className="calendar-customzation w-40p">
          <MultiDateSelectCalendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            dates={event?.datesOfEvent}
          />
        </div>
      </div>
    </Modal>
  )
}
export default CheckInOut
