import React from 'react'
import { Button, Row } from 'antd'
import GetAvatar from 'components/global/GetAvatar'
import {
  getUserName,
  convertDOBToAge,
  convertToLocal,
  capitalizeFirstLetter,
} from 'helper/utility'
import { Link, useHistory } from 'react-router-dom'
import { Family } from '../../types'
import { Person } from 'types/globalTypes'
import GetSkillsIco from 'components/global/GetSkillsIco'
import CategoryImage from 'helper/CategoryImageLink'
import { Question, VideoCamera } from '@phosphor-icons/react'
import loc from 'assets/Images/MapPinLine.svg'

interface Attendee extends Person {
  type: 'parent' | 'kid' | 'authorizedAdult'
}
interface GeneralDetailsProps {
  family?: Family
  organization?: any
  attendees?: Attendee[]
  data?: any
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({
  family,
  organization,
  attendees,
  data,
}) => {
  const history = useHistory()
  const {
    event,
    address,
    description,
    hasPreferredMentors,
    preferredMentorName,
    extraDetails,
  } = data
  const { title, apartment, street, city, state, country } = address || {}

  return (
    <>
      <div className="d-flex justify-between align-center">
        <h1 className="text-20 w-600 line-0">General Details</h1>
        <Button className="nav-btn br-8 px-30 w-600">Edit</Button>
      </div>

      <div>
        <h1 className="text-14 w-600 line-0 mt-20px">
          {family ? 'Family' : organization ? 'Organization' : ''}
        </h1>
        <Link
          to={
            family
              ? `/family-profile/${family._id}`
              : `/organization-profile/${organization!._id}`
          }
        >
          <div
            className="flex-center mt-10px pointer"
            // onClick={() => {
            //   family
            //     ? history.push(`/family-profile/${family._id}`)
            //     : history.push(`/organization-profile/${organization!._id}`)
            // }}
          >
            <GetAvatar person={family || organization!!} size={40} />
            <h4 className="ml-10px text-16">
              {getUserName(family || organization!!)}
            </h4>
          </div>
        </Link>
      </div>

      <div>
        <div>
          {attendees?.map((item, index) => (
            <div key={index}>
              <div>
                {item.type === 'parent' && (
                  <>
                    <h2 className="text-14 w-600 line-0 mt-20px">Guardian</h2>
                    <Link to={`/${item.type}-profile/${item._id}`}>
                      <div
                        className="flex-center mt-10px justify-start pointer"
                        key={index}
                        // onClick={() => {
                        //   history.push(`/${item.type}-profile/${item._id}`)
                        // }}
                      >
                        <GetAvatar person={item} size={45} />
                        <div className="ml-20px ">
                          <h2 className=" text-16 line-0">
                            {getUserName(item)}
                          </h2>
                          <h4 className="grey-txt text-14 mt-5px">
                            {convertToLocal(item.dob) +
                              '  ' +
                              '(' +
                              convertDOBToAge(item.dob) +
                              ' years old)'}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </>
                )}

                {item.type === 'kid' && (
                  <>
                    <h2 className="text-14 w-600 line-0 mt-20px">Child</h2>
                    <Link to={`/${item.type}-profile/${item._id}`}>
                      <div
                        className="flex-center mt-10px justify-start pointer"
                        key={index}
                        // onClick={() => {
                        //   history.push(`/${item.type}-profile/${item._id}`)
                        // }}
                      >
                        <GetAvatar person={item} size={45} />
                        <div className="ml-20px ">
                          <h2 className=" text-16 line-0">
                            {getUserName(item)}
                          </h2>
                          <h4 className="grey-txt text-14 mt-5px">
                            {convertToLocal(item.dob) +
                              '  ' +
                              '(' +
                              convertDOBToAge(item.dob) +
                              ' years old)'}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </>
                )}

                {item.type === 'authorizedAdult' && (
                  <>
                    <h2 className="text-14 w-600 line-0 mt-20px">
                      Authorized Adult
                    </h2>
                    <Link to={`/${item.type}-profile/${item._id}`}>
                      <div
                        className="flex-center mt-10px justify-start pointer"
                        key={index}
                        // onClick={() => {
                        //   history.push(`/${item.type}-profile/${item._id}`)
                        // }}
                      >
                        <GetAvatar person={item} size={45} />
                        <div className="ml-20px ">
                          <h2 className=" text-16 line-0">
                            {getUserName(item)}
                          </h2>
                          <h4 className="grey-txt text-14 mt-5px">
                            {convertToLocal(item.dob) +
                              '  ' +
                              '(' +
                              convertDOBToAge(item.dob) +
                              ' years old)'}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <h1 className="text-14 w-600 line-0 mt-20px">Category</h1>
        <Row justify="space-between" className="mt-10px">
          {data?.type === 'parent-request' ||
          data?.type == 'organization-request' ? (
            <GetSkillsIco id={data?.categoryId!} />
          ) : (
            <div className="flex-center">
              <CategoryImage id={data?.event?.category?._id!} />
              <h4 className="text-14 w-500 ml-10px">
                {data?.event?.category?.name}
              </h4>
            </div>
          )}
        </Row>
      </div>

      <div className="mt-2px flex justify-start mt-20px">
        {data?.sessionType === 'online' ? (
          <div>
            <h1 className="text-14 w-600 line-0 mt-20px">Online session</h1>
            <h3
              className="text-14 w-600 line-0 mt-10px"
              style={{ color: '#2B6BCA', textDecoration: 'underline' }}
            >
              Zoom Url
            </h3>
          </div>
        ) : data?.sessionType === 'any' ? (
          <div className="flex-center">
            <Question size={24} className="mr-20px" />
            <h4 className="font16 b1 line-0 ">Any</h4>
          </div>
        ) : (
          <div>
            <h1 className="text-14 w-600 line-0 mt-20px">Location</h1>
            <h2 className=" text-16 line-0 w-400 mt-10px">
              {capitalizeFirstLetter(title !== undefined ? title : '')}
            </h2>
            <h4 className="grey-txt text-14 line-0">
              {address
                ? `${apartment} ${street}, ${city}, ${state}`
                : 'No address found'}
            </h4>
          </div>
        )}
      </div>

      {hasPreferredMentors && (
        <div className="d-flex justify-between align-center  mt-20px">
          <h3 className="text-14 w-600 line-0">{preferredMentorName}</h3>
          <p className="text-14"></p>
        </div>
      )}

      {extraDetails && (
        <div>
          <h1 className="text-14 w-600 line-0 mt-20px">Description</h1>
          <p className="mt-10px grey-txt">
            {extraDetails || 'No description added'}
          </p>
        </div>
      )}
    </>
  )
}

export default GeneralDetails
