import { Space, Dropdown, Select, Menu, Row, Col, Checkbox } from 'antd'
import { Option } from 'antd/lib/mentions'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import filterSvg from '../../assets/Images/filter.svg'
import type { MenuProps } from 'antd'

const ActionsHeader = ({
  setParms,
  parmsData,
  hiddenColumns,
  setHiddenColumns,
}: any) => {
  const handleSearchChange = (e: any) => {
    if (e.length >= 3 || e.length === 0) {
      setParms({ ...parmsData, queryString: e, page: 1 })
    }
  }

  const debounced = debounce(handleSearchChange, 700)
  const [visible, setVisible] = useState(false)
  const handleMenuClicked: MenuProps['onClick'] = e => {}
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    }

    setHiddenColumns(updatedHiddenColumns)
    localStorage.setItem('FamilyCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('FamilyCols')
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns))
    }
  }, [])
  const menu2 = (
    <Menu
      onClick={handleMenuClicked}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.family}
                  name="family"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Family</Col>
            </Row>
          ),
          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.guardian}
                  name="guardian"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Guardian</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.adults}
                  name="adults"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Adults</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.kids}
                  name="kids"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>kids</Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.primarycontact}
                  name="primarycontact"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Primary Contact</Col>
            </Row>
          ),
          key: '5',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.status}
                  name="status"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Status</Col>
            </Row>
          ),
          key: '6',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: '7',
        },
      ]}
    />
  )
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const handleChangeSortName = (value: string) => {
    setParms({ ...parmsData, sort: value })
  }
  return (
    <Space size={20} className="mentors-table-header">
      <Dropdown
        overlay={menu2}
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Space>
          <Space>
            <img src={filterSvg} alt=""></img>
          </Space>
        </Space>
      </Dropdown>
      {/* <Select
        className="custom-select-field"
        defaultValue="firstname"
        placeholder="sort by"
        allowClear
        onChange={handleChangeSortName}
      >
        <Option value="email">Sort by: Email</Option>
        <Option value="-createdat">Sort by: Created at</Option>
      </Select> */}
      <Select
        className="custom-select-field"
        placeholder="sort by"
        allowClear
        onChange={handleChangeSortName}
        style={{ width: '200px' }} // Adjust the width as needed
      >
        <Option value="email">Sort by: Email</Option>
        <Option value="-createdat">Sort by: Created at</Option>
      </Select>

      <div>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search by name, email, phone or anything"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
      </div>
    </Space>
  )
}
export default ActionsHeader
