import { Avatar, Button, Card, Col, Row, Switch } from "antd";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import RewardsModal from "../Modals/SettingsModal/RewardsModal";
import Meta from "antd/lib/card/Meta";
import addRewards from "../../assets/Images/addRewards.svg";

const Rewards = ({ rewardsData }: any) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentData, setCurrentData] = useState<any>([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(rewardsData);
  }, [rewardsData]);
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };
  return (
    <div>
      <h1 className="mb-20">Rewards</h1>
      <div>
        <h4 className="mb-20">
          <b className="b3">Add the details of kids rewards.</b>{" "}
        </h4>
      </div>
      {data.length !== 0 ? (
        <Row gutter={[15, 15]}>
          <Col xl={8} lg={12} span={24}>
            <div
              className="rewards-card cursor_pointer mr-0"
              onClick={() => {
                setModalVisible(true);
                setCurrentData([]);
              }}
            >
              <img alt="" src={addRewards} className="mr-5" />

              <span>New reward</span>
            </div>
          </Col>
          {data.map((item: any) => {
            return (
              <Col xl={8} lg={12} span={24}>
                <Card>
                  <Row className="justify-between" gutter={[24, 10]}>
                    <Col span={12}>
                      <Meta
                        avatar={<Avatar src={item.attachment} size={40} />}
                        className="rewards-avtar-img"
                      ></Meta>
                    </Col>
                    <Col span={12} className="pl-0">
                      <div className="d-flex align-items-center justify-end">
                        <div className="rewards-switch-toggle">
                          <Switch
                            defaultChecked
                            onChange={onChange}
                            className="small-custom-switch-btn"
                          />
                        </div>
                        {/* <div
                          onClick={() => {
                            setModalVisible(true);
                            setCurrentData([]);
                          }}
                        ></div> */}
                        <p className="rewards-edit-btn cursor_pointer">
                          <span>Edit</span>
                        </p>
                      </div>
                    </Col>
                    <Col span={24}>
                      <h4>
                        <b className="b2">{item.rewardsName}</b>{" "}
                      </h4>
                    </Col>
                    <Col span={24}>
                      <div>
                        <h5 className="text-secondary">
                          <b className="b3">50 hours</b>{" "}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      ) : (
        <div>
          <Button
            style={{
              backgroundColor: "#2BA7CA",
              borderRadius: "8px",
              color: "white",
            }}
            onClick={() => {
              setModalVisible(true);
              setCurrentData([]);
            }}
          >
            Create a Reward
          </Button>
        </div>
      )}
      {isModalVisible ? (
        <RewardsModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          rewardsData={rewardsData}
          currentData={currentData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  rewardsData: state.settings.rewardsData,
});
export default connect(mapStateToProps)(Rewards);
