import { Col, Row, Button, Card, Space, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AppLayout from "../../../../components/layout/layout";
import actions from "../../../../Redux/JobRequest/action";
const { Meta } = Card;

const ProposedMentors = ({ jobRequestData }: any) => {
  const [data, setData] = useState([]);
  const location: any = useLocation();
  const dispatch = useDispatch();
  let historyData = location.state;
  let JobData = JSON.parse(historyData);

  console.log(JobData);

  useEffect(() => {
    dispatch({
      type: actions.GET_JOB_PROPOSED,
      payload: JobData.id,
    });
  }, []);

  return (
    <AppLayout>
      <div className="bg-white container">
        <Row gutter={10}>
          <Col span={16}>
            <div className="Mentors-Proposed-block">
              <h6>
                <b className="b2">{`Mentors Proposed (${data.length})`}</b>
              </h6>
              {/* <div className="event-top-bar">
                <SearchBar />
              </div> */}
              <div>
                {data.map((item: any) => {
                  return (
                    <Card className="propose-mentor-card pl-0 pr-0">
                      <Row gutter={24}>
                        <Col span={14}>
                          <Meta
                            avatar={
                              <Avatar
                                size={32}
                                src={item.personalDetails.mentorAvatar}
                              ></Avatar>
                            }
                            title={
                              <h5 className="mb-0">
                                <b className="b2">
                                  {item.personalDetails.firstname}
                                </b>
                              </h5>
                            }
                            description={<span className="font12"></span>}
                          ></Meta>
                          <Button
                            shape="round"
                            size="small"
                            className="secondary"
                            style={{
                              margin: "10px",
                            }}
                          ></Button>
                          );
                        </Col>
                        <Col span={10}>
                          <div className="d-flex">
                            <Button
                              size="middle"
                              key="list-loadmore-more"
                              danger
                              type="text"
                              shape="round"
                              onClick={() => {}}
                            >
                              Cancel Proposal
                            </Button>
                            <Button
                              key="list-loadmore-edit"
                              shape="round"
                              size="middle"
                              type="primary"
                              className="primary"
                            >
                              View Profile
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={7}>
            <div className="padding-30 pl-0">
              <div>
                <h6>
                  <b className="b1">Job Title</b>
                </h6>
                <p className="font13 mb-20">
                  We are looking for a tutor for our kid, we need experienced
                  and other job description goes here. We are looking for a
                  tutor for our kid, we need experienced and other job
                  description goes here
                </p>
              </div>
              <Space>
                <span className="font13">
                  <b className="b1">Budget: $100 per day</b>
                </span>
                <span className="font13">
                  <b className="b1">Terms: 5 days</b>
                </span>
              </Space>
              <Divider />
              <h4>
                <b className="b1">Terms</b>
              </h4>
              <h5 className="text-secondary">
                <b className="b2">Client budget: $75</b>
              </h5>
            </div>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => ({
  jobRequestData: state.jobRequest.jobRequestData,
});
export default connect(mapStateToProps)(ProposedMentors);
