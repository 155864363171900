import { Space, Button } from "antd";
import illustration from "../../../../../assets/Images/Illustration.svg";

const EmptyFamily = () => {
  return (
    <Space
      direction="vertical"
      className="container bg-white padding-30"
      align="center"
    >
      <div className="text-center">
        <img alt="img" src={illustration} className="mb-38"></img>
        <h3>
          <b className="b1">No family members added yet</b>
        </h3>
        <h5 className="mb-25 mt-16">
          <b className="b3">Who would you like to add to the family?</b>
        </h5>
        <Space direction="vertical" className="members-add" size={16}>
          <Button
            className="large_add_button w-325"
            onClick={() => {
              // setGuardiansModalVisible(true);
            }}
          >
            Guardians
          </Button>

          <Button className="large_add_button w-325">Child</Button>
          <Button
            className="large_add_button w-325"
            onClick={() => {
              // setAdultsModalVisible(true);
            }}
          >
            Authorized Adults
          </Button>
        </Space>
      </div>
    </Space>
  );
};
export default EmptyFamily;
