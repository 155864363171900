import Spinner from 'components/Spinner'
import { RequestDetailResp } from '../types'
import {
  getUserName,
} from 'helper/utility'
import { useEffect, useState } from 'react'
import { Person } from 'types/globalTypes'
import GetAvatar from 'components/global/GetAvatar'
import { useHistory } from 'react-router-dom'
import GeneralDetails from './Details/GeneralDetails'
import TimeAndFrequency from './Details/TimeAndFrequency';
import OtherDetails from './Details/OtherDetails';
interface Attendee extends Person {
  type: 'parent' | 'kid' | 'authorizedAdult'
}
interface DetailsData {
  Requestdata: RequestDetailResp | undefined
  Loading: boolean
  session: {
    totalTimeLast24Hours: number
    totalTimeLastWeek: number
    totalTimeSinceStart: number
    totalTimeThisWeek: number
  }
  changeTab: (arg0: '1' | '2' | '3') => void
}

const Details: React.FC<DetailsData> = ({
  Requestdata,
  Loading,
  session,
  changeTab,
}) => {
  const [attendees, setAttendees] = useState<Attendee[]>()

  useEffect(() => {
    Requestdata &&
      setAttendees([
        ...(Requestdata.guardians || []).map(guardian => ({
          ...guardian,
          type: 'parent' as const,
        })),
        ...(Requestdata.kids || []).map(kid => ({
          ...kid,
          type: 'kid' as const,
        })),
        ...(Requestdata.authorizedAdults || []).map(adult => ({
          ...adult,
          type: 'authorizedAdult' as const,
        })),
      ])
  }, [Requestdata])
  const {
    recurringSessionSchedule,
    family,
    organization,
    mentor,
    isMentorHired,
    timesPerWeek,
  } = Requestdata || {}
  const event = Requestdata?.event

  const { date: recurringDate, ...recurringRest } =
    recurringSessionSchedule || {}
  const history = useHistory()

  return (
    <div>
      {Loading ? (
        <div className="flex-center justify-center h-70vh">
          <Spinner />
        </div>
      ) : (
        <div className="bg-grey">
          {session && (
            <div className="flex justify-between px-55px py-20px bg-white mb-10px">
              <div className="flex flex-col justify-center align-center">
                <h2 className="text-14">Last 24 hours</h2>
                <h1 className="text-24 w-500">
                  {session.totalTimeLast24Hours} hrs
                </h1>
              </div>
              <div className="flex flex-col justify-center align-center">
                <h2 className="text-14">This week</h2>
                <h1 className="text-24 w-500">
                  {session.totalTimeThisWeek} hrs
                </h1>
              </div>
              <div className="flex flex-col justify-center align-center">
                <h2 className="text-14">Last week</h2>
                <h1 className="text-24 w-500">
                  {session.totalTimeLastWeek} hrs
                </h1>
              </div>
              <div className="flex flex-col justify-center align-center">
                <h2 className="text-14">Since start</h2>
                <h1 className="text-24 w-500">
                  {session.totalTimeSinceStart} hrs
                </h1>
              </div>
            </div>
          )}
          <div className="grid-50 bg-grey h-fill">
            <div className="bg-white p-30px ">
              <GeneralDetails
                family={family}
                organization={organization}
                attendees={attendees}
                data={Requestdata}
              />
            </div>
            <div className="bg-white p-30px">
              <TimeAndFrequency
                sessionType={timesPerWeek}
                data = {Requestdata}
              />

              <div className='mt-20px'>
                <OtherDetails data = {Requestdata} />
              </div>
              <div>
                {isMentorHired && (
                  <div className="mt-30">
                    <h3 className="text-20 w-600">Mentor</h3>
                    <div>
                      <div
                        className="flex-center mt-16 pointer"
                        onClick={() => {
                          history.push(`/mentor-profile/${mentor!._id}`)
                        }}
                      >
                        <GetAvatar person={mentor!} />
                        <div className="ml-20">
                          <h4 className="text-16 w-500 ">
                            {getUserName(mentor!)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Details
