import { Spinner } from '@phosphor-icons/react'
import { Avatar, Modal } from 'antd'
import GetAvatar from 'components/global/GetAvatar'
import { useMutation } from '@tanstack/react-query'
import { myStore } from 'state/eventStore'
import Cancel from 'assets/Images/close.svg'
import {
  capitalizeFirstLetter,
  convertToLocal,
  getNameSignature,
  getUserName,
} from 'helper/utility'
import { useState } from 'react'
import { Attendees } from 'pages/Events/types'
import { StatusList } from './CheckInHeader'
import CheckInOutModel from './CheckInOutModel'
import DropOut from './Drop-out'
import moment from 'moment'
import { failureMessage, successMessage } from 'api/global'
import { approveParticipant, approveParticipantCheckIn } from 'api/Events'
import { useDispatch } from 'react-redux'
import actions from 'Redux/Events/action'
import Loader from 'helper/Loader'
import checkInImg from 'assets/Images/checkIn.svg'
import { Button } from 'antd'
interface ParticpantList {
  isLoading: boolean
  particpantStatus: Attendees[] | undefined
  date: string
}
const ParticpantsList = ({
  isLoading,
  particpantStatus,
  date,
}: ParticpantList) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const setTag = myStore(store => store.setTag)
  const [model, setModel] = useState(false)
  const [modelType, setType] = useState<
    'Check-in' | 'Check-out' | 'drop-out' | 'approve' | 'Drop-in'
  >('Check-in')
  const [attendee, setAttendee] = useState<Attendees>()
  const [approveModal, setApproveModal] = useState(false)
  const showModal = () => {
    setApproveModal(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleSomeAction = async (attendee: any) => {
    if (
      attendee?.checkInStatus === 'checked-in' &&
      attendee.checkOutStatus === 'request-check-out'
    ) {
      const checkOutAdminId = localStorage.getItem('adminId')
      await mutation.mutate({ checkInId: attendee?.checkInId, checkOutAdminId })
    } else {
      await checkInmutation.mutate({ checkInId: attendee?.checkInId })
    }
  }
  const [isLoadingApprove, setLoadingApprove] = useState(false) // Define a loading state variable
  const checkinParticipantsCount = particpantStatus
    ? particpantStatus.filter(
        participant =>
          participant.checkInStatus !== 'pending' &&
          participant.checkOutStatus !== 'request-check-out' &&
          participant.checkInStatus !== 'request-check-in',
        // (participant.checkInStatus !== 'pending' &&
        //   participant.checkOutStatus !== 'pending') ||
        // participant.checkInStatus !== 'pending',
      ).length
    : 0

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const mutation = useMutation({
    mutationFn: async (checkInId: any) => {
      return await approveParticipant(checkInId)
    },
    onSuccess: () => {
      // successMessage(`Participant check-out approve successfully`)
      setTag(true)
      setIsModalOpen(false)
      setLoadingApprove(false) // Set the loading state variable back to false after mutation success
    },
    onError: error => {
      failureMessage(`${error}`)
      setLoadingApprove(false) // Set the loading state variable back to false after mutation error
    },
  })
  const checkInmutation = useMutation({
    mutationFn: async (checkInId: any) => {
      return await approveParticipantCheckIn(checkInId)
    },
    onSuccess: () => {
      // successMessage(`Participant check-in approve successfully`)
      setTag(true)
      setIsModalOpen(false)
      setLoadingApprove(false)
    },
    onError: error => {
      failureMessage(`${error}`)
      setLoadingApprove(false) // Set the loading state variable back to false after mutation error
    },
  })
  const currentDate = moment().startOf('day').format('DD MMMM YYYY')
  const selectedDate = convertToLocal(date, 'DD MMMM YYYY')

  const handleClick = async (particpant: Attendees) => {
    if (selectedDate !== currentDate) {
      failureMessage('Please select the current date to perform this action')
    } else {
      if (
        particpant.checkOutStatus === 'pending' &&
        particpant.type !== 'dropInAttendee'
      ) {
        setType('Check-out')
        setModel(true)
      }

      if (
        particpant.type == 'dropInAttendee' &&
        particpant.checkOutStatus !== 'dropped-out'
      ) {
        setType('drop-out')
        setModel(true)
      }
      if (!particpant.checkOutStatus) {
        setType('Check-in')
        setModel(true)
      }
      if (
        particpant.checkInStatus == 'checked-in' &&
        particpant.checkOutStatus == 'checked-out'
      ) {
        setType('Check-in')
        setModel(true)
      }
      if (
        particpant.checkInStatus == 'droped-in' &&
        particpant.checkOutStatus == 'dropped-out'
      ) {
        setType('Drop-in')
        setModel(true)
      }
      if (
        particpant.checkOutStatus == 'request-check-in' ||
        particpant.checkOutStatus == 'request-check-out' ||
        particpant.checkInStatus == 'request-check-in'
      ) {
        //
        //
        // setLoadingApprove(true)
        setIsModalOpen(true)
        setModel(false)
        // await mutation.mutate({ checkInId: particpant?.checkInId }) // Wait for mutation to complete
        // setTag(true)
        // setType('approve')
        // setModel(true)
      }
    }
    setAttendee(particpant)
  }
  return (
    <>
      <Modal
        open={isModalOpen}
        footer={
          <>
            <div className="flex justify-end">
              <button
                className="flex-center prim-sm-btn pointer"
                key="approve"
                onClick={() => handleSomeAction(attendee)}
              >
                {mutation?.isLoading ? (
                  <Loader />
                ) : checkInmutation?.isLoading ? (
                  <Loader />
                ) : (
                  'Approve'
                )}
              </button>
            </div>
          </>
        }
      >
        <div className="popup-header border-btm">
          <div>
            <h3>{`Are you sure you want to appprove ${
              attendee?.checkOutStatus === 'request-check-out'
                ? 'Check-out?'
                : 'Check-in?'
            }`}</h3>
          </div>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <div className="flex-center " style={{ marginTop: 20 }}>
          <GetAvatar person={attendee} size={50} />
          <div className="ml-10px">
            <h3 className="text-14 w-500">
              {attendee?.firstName} {attendee?.lastName}
            </h3>
            <h4 className="text-12 w-400 grey-txt">{attendee?.type}</h4>
          </div>
        </div>
      </Modal>
      <div>
        {isLoading ? (
          <div className="flex-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="overflow-auto h-90p">
            <h4 className="text-16 flex pb-20px">
              {convertToLocal(date, 'DD MMMM YYYY')}
              <span className="grey-txt text-14 ml-10px">
                {particpantStatus?.length} attendees
              </span>
              <span className="grey-txt text-14 ml-10px">
                {checkinParticipantsCount} checked-in
              </span>
            </h4>
            {particpantStatus?.map((particpant, index) => {
              const hasNextItem = index < particpantStatus.length - 1

              return (
                <div key={index} className={hasNextItem ? ' pb-20px ' : ''}>
                  <div className="flex-center justify-between pr-20px ">
                    <div className="flex-center  w-40p">
                      <GetAvatar person={particpant} />
                      <h3 className="text-14 w-500 ml-10">
                        {getUserName(particpant)}
                      </h3>
                    </div>
                    {StatusList.map(item => {
                      if (
                        item.title === 'Checked in' &&
                        particpant.checkInStatus === 'checked-in' &&
                        particpant.checkOutStatus === 'checked-out'
                      ) {
                        return (
                          <div
                            style={{
                              backgroundColor: StatusList[4].color,
                            }}
                            className="flex flex-center justify-center px-15px py-10px radius-12 ml-10px w-35p"
                          >
                            <img src={StatusList[4].img} alt={item.value} />
                            <h4 className="ml-10px text-12 w-600">
                              {
                                // : particpant.checkInStatus === 'checked-in'
                                // ? 'Checked '
                                StatusList[4].title
                              }
                            </h4>
                          </div>
                        )
                      } else if (
                        item.title === 'Checked in' &&
                        particpant.checkOutStatus === 'checked-out'
                      ) {
                        return (
                          <div
                            style={{
                              backgroundColor: StatusList[3].color,
                            }}
                            className="flex flex-center justify-center px-15px py-10px radius-12 ml-10px w-35p"
                            key={'checkedout'}
                          >
                            <img src={StatusList[3].img} alt={item.value} />
                            <h4 className="ml-10px text-12 w-600">
                              {particpant.checkInStatus === 'request-check-in'
                                ? 'Checked In'
                                : // : particpant.checkInStatus === 'checked-in'
                                  // ? 'Checked '
                                  StatusList[3].title}
                            </h4>
                          </div>
                        )
                      } else if (
                        item.value === particpant?.checkInStatus &&
                        particpant.checkOutStatus !== 'checked-out'
                      ) {
                        return (
                          <div
                            style={{
                              backgroundColor:
                                particpant.checkOutStatus ===
                                'request-check-out'
                                  ? StatusList[5].color
                                  : item.color,
                            }}
                            className="flex flex-center justify-center px-15px py-10px radius-12 ml-10px w-35p"
                            key={item.key}
                          >
                            <img
                              src={
                                particpant.checkOutStatus ===
                                'request-check-out'
                                  ? StatusList[5].img
                                  : item.img
                              }
                              alt={item.value}
                            />

                            <h4 className="ml-10px text-12 w-600">
                              {/* {particpant.checkInStatus === 'droped-in' &&
                                particpant.checkOutStatus === 'pending'
                                  ? 'dropped-in'
                                  : particpant.checkOutStatus !== 'pending'
                                  ? particpant.checkOutStatus
                                  : particpant.checkInStatus !== 'checked-in'
                                  ? particpant.checkInStatus
                                  : particpant.checkInStatus} */}

                              {/* {particpant.checkInStatus === 'droped-in' &&
                                particpant.checkOutStatus === 'pending'
                                  ? 'dropped-in'
                                  : particpant.checkInStatus !== 'checked-in'
                                  ? particpant.checkInStatus
                                  : particpant.checkInStatus == 'checked-in' &&
                                    particpant.checkOutStatus == 'pending'
                                  ? particpant.checkInStatus
                                  : particpant.checkOutStatus} */}

                              {particpant.checkOutStatus === null ||
                              particpant.checkOutStatus === 'pending'
                                ? particpant.checkInStatus
                                : particpant.checkInStatus === 'droped-in' &&
                                  particpant.checkOutStatus === 'pending'
                                ? 'dropped-in'
                                : particpant.checkOutStatus}

                              {/* {item.title} */}
                              {/* {particpant.checkOutStatus} */}
                            </h4>
                          </div>
                        )
                      }
                    })}

                    <button
                      className="btn-borderless prim-txt text-14 w-600 w-35p text-right pointer mr-10px pointer"
                      disabled={
                        particpant.checkOutStatus === 'checked-out' &&
                        particpant.checkOutStatus === 'dropped-out'
                      } // Disable button if checked-out
                      style={{
                        cursor:
                          particpant.checkOutStatus === 'checked-out' &&
                          particpant.checkOutStatus === 'dropped-out'
                            ? 'disable'
                            : 'pointer',
                      }} // Set cursor based on status
                      onClick={() => handleClick(particpant)} // Only call handleClick if enabled
                    >
                      {/* {particpant.checkInStatus == 'droped-in'
                      ? 'Drop-out' ??  particpant.checkInStatus == 'request-check-in'
                      : 'approve checkin'
                      : particpant.checkOutStatus == 'pending'
                      ? 'Check-out'
                      : particpant.checkOutStatus == 'checked-out'
                      ? 'Checked-out'
                      : particpant.checkInStatus == 'dropped-out'
                      ? 'Dropped-out'
                      
                      : 'Check-in'} */}
                      {particpant.checkInStatus === 'pending'
                        ? 'Check in'
                        : particpant.checkInStatus === 'request-check-in' &&
                          particpant.checkOutStatus == 'pending'
                        ? 'Approve'
                        : particpant.checkInStatus == 'checked-in' &&
                          particpant.checkOutStatus === 'pending'
                        ? 'Check out'
                        : particpant.checkOutStatus === 'request-check-out'
                        ? 'Approve'
                        : particpant.checkInStatus === 'droped-in' &&
                          particpant.checkOutStatus == 'pending'
                        ? 'Drop-out'
                        : particpant.checkInStatus === 'droped-in'
                        ? 'Dropped-in'
                        : particpant.checkInStatus === 'checked-in' &&
                          particpant.checkOutStatus === 'checked-out'
                        ? 'Check in'
                        : 'Checked out'}
                      {/* {particpant.checkOutStatus ? 'Check-out' : 'Check-in'  }  */}
                    </button>
                    {/* <button
                      className="btn-borderless prim-txt text-14 w-600"
                      onClick={showModal}
                    >
                      {' '}
                      approve
                    </button> */}
                  </div>
                </div>
              )
            })}

            {modelType !== 'drop-out' && model && attendee ? (
              <CheckInOutModel
                particpantStatus={particpantStatus}
                open={model}
                setOpen={setModel}
                type={modelType}
                particpant={attendee}
              />
            ) : (
              <></>
            )}
            {modelType == 'drop-out' && (
              <DropOut
                openModal={model}
                closeModal={setModel}
                participant={attendee!}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ParticpantsList
