import { Input, Modal } from 'antd'
import React from 'react'
import { inventoryStorageLocType } from 'types/events'
import closeIcon from 'assets/Images/close.svg'
import ViewAddress from 'helper/Map/ViewAddress'

interface ViewStorageLocationProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  data: inventoryStorageLocType
}

const ViewStorageLocation: React.FC<ViewStorageLocationProps> = ({
  openModal,
  closeModal,
  data,
}) => {
  const handleCancel = () => {
    closeModal(false)
  }

  return (
    <>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="add-supplyform-modal"
        footer={false}
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Storage location</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="mt-20px">
          <ViewAddress onlyView={true} mapData={data} />
          <div className="mt-10px flex align-center justify-between gap-16px">
            <div className="flex flex-col gap-5px align-center">
              <h3 className="text-14 w-600">Building</h3>
              <p>{data.building}</p>
            </div>
            <div className="flex flex-col gap-5px align-center">
              <h3 className="text-14 w-600">Box</h3>
              <p>{data.box}</p>
            </div>
            <div className="flex flex-col gap-5px align-center">
              <h3 className="text-14 w-600">Shelf</h3>
              <p>{data?.shelf}</p>
            </div>
            <div className="flex flex-col gap-4px align-center">
              <h3 className="text-14 w-600">Room</h3>
              <p>{data?.room}</p>
            </div>
          </div>

          <div className="flex gap-20px mt-20px">
            <div className="w-full">
              <div>
                <h3 className="text-14 w-600">Quantity</h3>
              </div>
              <div className="mt-10px">
                <Input
                  className="form-control-field"
                  value={data.quantity}
                  disabled
                ></Input>
              </div>
            </div>

            <div className="w-full">
              <div>
                <h3 className="text-14 w-600">Unit</h3>
              </div>
              <div className="mt-10px">
                <Input
                  className="form-control-field"
                  placeholder="Short description of the supply"
                  value={data.unit}
                  disabled
                ></Input>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20px">
          <button className="big-btn mx-auto" onClick={handleCancel}>
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default ViewStorageLocation
