import React, { useState } from 'react'
import { Checkbox, Dropdown } from 'antd'
import { EventFaqs, OrgFaqs } from 'types/eventStep5'
import { CaretDown, DotsThree, Pencil, Trash } from '@phosphor-icons/react'
import '../../styles/additional-settings.scss'
import AlertModal from 'helper/AlertModal'
import { AlertModalGeneric } from 'pages/Events/Views/CreateEvent/utility'
import { useRemoveQuestion } from 'api/EventCreation/creationStep5'
import AddQuestion from './AddQuestion'

interface FaqContainerProps {
  data: OrgFaqs | EventFaqs
  questions: string[]
  setQuestion: React.Dispatch<React.SetStateAction<string[]>>
  editable: boolean
}

const FaqContainer: React.FC<FaqContainerProps> = ({
  data,
  questions,
  setQuestion,
  editable,
}) => {
  const [active, setActive] = useState<boolean>(false)
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [addQuestion, setAddQuestion] = useState<boolean>(false)
  const { mutate: deleteFaq, isLoading: deleteFaqLoading } = useRemoveQuestion()

  const menuOptions = [
    {
      key: 'edit',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('edit')}
        >
          <Pencil size={20} color="#000" />
          <p className="text-16 w-500">Edit question</p>
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div
          className="flex gap-16px align-center"
          onClick={() => handleMenuClick('delete')}
        >
          <Trash size={20} color="#ff6961" />
          <p className="text-16 w-500 danger-txt">Delete question</p>
        </div>
      ),
    },
  ]

  const handleMenuClick = (operation: 'edit' | 'delete') => {
    if (operation === 'edit') {
      setAddQuestion(true)
    } else {
      setAlertModal(true)
    }
  }

  const handleYesDelete = () => {
    console.log(data)
    deleteFaq(
      { questionId: data._id },
      {
        onSuccess: () => setAlertModal(false),
        onError: () => setAlertModal(false),
      },
    )
  }

  const hanldeOnChange = (questionData: OrgFaqs | EventFaqs) => {
    if (!questions.includes(questionData._id)) {
      setQuestion([...questions, questionData._id])
    } else {
      setQuestion(questions.filter(item => item !== questionData._id))
    }
  }

  const hanldeOpenAnswer = () => {
    setActive(prev => !prev)
  }

  return (
    <>
      <div className="my-15px">
        <div
          className={`flex align-start gap-16px faq-container ${
            active && 'active'
          }`}
        >
          <Checkbox
            checked={questions.includes(data._id)}
            onChange={event => hanldeOnChange(data)}
          />
          <div className="w-full" style={{ marginTop: '3px' }}>
            <div className="question">
              <div className="flex align-center">
                <h4 className="text-14 w-600 text-break">{data.question}</h4>
              </div>
              <div className="flex align-center gap-16px">
                {editable && (
                  <Dropdown
                    menu={{
                      items: menuOptions,
                    }}
                  >
                    <DotsThree
                      size={20}
                      color="#000"
                      className="question-dots"
                    />
                  </Dropdown>
                )}
                <CaretDown
                  size={20}
                  color="#000"
                  onClick={hanldeOpenAnswer}
                  className="question-carret"
                />
              </div>
            </div>
            <div className="answer">
              <p className="text-break">{data.answer}</p>
            </div>
          </div>
        </div>
      </div>
      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={AlertModalGeneric('Faq', 'Faq!')}
          onYesClick={handleYesDelete}
          loading={deleteFaqLoading}
        />
      )}

      {addQuestion && (
        <AddQuestion
          openModal={addQuestion}
          closeModal={setAddQuestion}
          data={data as EventFaqs}
        />
      )}
    </>
  )
}

export default FaqContainer
