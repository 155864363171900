import { useMutation, useQuery } from '@tanstack/react-query'
import { getEventRollCall, updateEventRollCall } from 'api/Events'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  EventRollCallParams,
  EventRollCallResponse,
  updateRollCall,
} from 'pages/Events/types'
import Roolimg from 'assets/Images/lookout.svg'
import Spinner from 'components/Spinner'
import {
  convertToLocal,
  convertToUtc,
  getNameSignature,
  getUserName,
} from 'helper/utility'
import { Avatar, Checkbox } from 'antd'
import { failureMessage, successMessage } from 'api/global'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import GetAvatar from 'components/global/GetAvatar'
interface RollCallList {
  date: Date | any
}
interface updateRollCallMutation {
  id: string
  body: updateRollCall
}
const RollCallList = ({ date }: RollCallList) => {
  const { id }: any = useParams()
  const [params, setParams] = useState<EventRollCallParams>({
    date: convertToUtc(date),
    eventId: id,
  })
  const [isAttendeesVisible, setShowAttendees] = useState<number | null>(null)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [editData, setEditData] = useState({
    id: '',
    name: '',
    checked: [''],
  })
  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams,
      date: convertToUtc(date),
    }))
  }, [date])
  const { data, refetch, isLoading } = useQuery<EventRollCallResponse[]>(
    ['getEventRollCallList', params],
    () => getEventRollCall(id, convertToUtc(date)),
    { enabled: false },
  )

  useEffect(() => {
    refetch()
  }, [params])

  const updateRollCall = useMutation({
    mutationFn: async ({ id, body }: updateRollCallMutation) => {
      const data = await updateEventRollCall(id, body)
      return data
    },
    onSuccess: result => {
      if ((result.status = 200)) successMessage(`Rollcall updated Successfully`)
      else failureMessage('Can not update Rollcall')
      refetch()
    },
  })
  const onChange = (checkedValues: CheckboxChangeEvent['target']['value']) => {
    setEditData({ ...editData, checked: checkedValues })
  }
  const UpdateRollfn = () => {
    updateRollCall.mutateAsync({
      id: editData.id,
      body: { participantIds: editData.checked, name: editData.name },
    })
  }
  return (
    <div>
      {isLoading ? (
        <div className="flex-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {data &&
            data?.map((list, index) => {
              const dateObj = convertToLocal(list.time, 'hh:mm A')
              const defaultChecked = list.participants?.map(
                part => part._id,
              ) || ['']

              return (
                <div className=" mt-20px border-btm  pb-10px">
                  <div className="flex-center">
                    <img src={Roolimg} alt="Rool Call" />
                    <div className="ml-20px flex-center justify-between w-full">
                      <div>
                        <h2 className="text-16">
                          {list.name} .{' ' + dateObj}
                        </h2>
                        <h4></h4>
                      </div>
                      {list?.participants && list?.participants?.length > 0 && (
                        <h2
                          className="text-18 prim-txt pointer"
                          onClick={() => {
                            setShowAttendees(
                              isAttendeesVisible === index ? null : index,
                            )
                          }}
                        >
                          {isAttendeesVisible === index ? 'Close' : 'Open'}
                        </h2>
                      )}
                    </div>
                  </div>
                  {isAttendeesVisible === index && (
                    <div className="ml-20px">
                      <Checkbox.Group
                        options={list.participants?.map(atendee => ({
                          label: (
                            <div className="flex-center ml-10px">
                              <GetAvatar person={atendee} />

                              <h2 className="text-16 ml-10">
                                {getUserName(atendee)}
                              </h2>
                            </div>
                          ),

                          value: atendee._id,
                          disabled: !isEdit,
                        }))}
                        className="mt-20px atendeeCheckbox"
                        defaultValue={defaultChecked}
                        onChange={onChange}
                      />
                      <div className="flex justify-end">
                        <button
                          onClick={() => {
                            setIsEdit(!isEdit)
                            setEditData({
                              ...editData,
                              id: list._id,
                              name: list.name,
                            })
                            isEdit && UpdateRollfn()
                          }}
                          className="pointer mt-20px  prim-sm-btn"
                        >
                          {isEdit ? 'Update' : 'Edit RollCall'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
        </>
      )}
    </div>
  )
}

export default RollCallList
