import { Form, Button, Col, Layout, Row, Slider, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import ConfirmModal from "./ConfirmModal";
import rightArrowIcon from "../../../../../assets/Images/backarrow.svg";

const Other = ({ data, handleBack, setIsModalVisible, setCurrent }: any) => {
  const [form] = Form.useForm();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [sessionData, setSessionData] = useState<any>({});
  const [sliderRangeValue, setSliderRangeValue] = useState<any>(0);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const formSchema = {
    budgetPerSession: data?.budgetPerSession ? data?.budgetPerSession : "",
    additionalNotes: data?.additionalNotes ? data?.additionalNotes : "",
  };

  const onSliderChange = (value: number | [number, number]) => {
    form.setFieldsValue({budgetPerSession:value});
    setSliderRangeValue(value);
  };

  const onFinish = (values: any) => {
    setSessionData({
      ...data,
      ...values,
      willingToAlternate: true,
      preferredMentorsIds: [],
    });
    setConfirmModalVisible(true);
  };

  return (
    <Layout>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="modal-h-element">
          <Row>
            <Col span={20}>
              <h4 className="b2">What is your budget per session (1 hour)</h4>
            </Col>
            <Col>
              <Form.Item
                name="budgetPerSession"
              >
                <Input
                  placeholder="$100"
                  type={"number"}
                  value={sliderRangeValue}
                  className="ant-input text-end br-5 text-center ms-auto mr-0 d-block text-color-black font14"
                  style={{ width: "100px" }}
                ></Input>
                <Slider defaultValue={40}
                      onChange={onSliderChange}
                      value={sliderRangeValue}></Slider>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="additionalNotes"
                rules={[{ required: true, message: "Extra notes is required" }]}
              >
                <TextArea
                  rows={9}
                  placeholder="Tell us more what would you like to add..."
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row justify="space-between" align="middle">
          <h5 className="stepperBackh5" onClick={() => handleBack()}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} />
            </span>
            Back
          </h5>

          <Button
            className="stepper-button"
            htmlType="submit"
          >
            Create Session
          </Button>
        </Row>
      </Form>
      {confirmModalVisible && (
        <ConfirmModal
          isModalVisible={confirmModalVisible}
          isOtherModalVisible={setIsModalVisible}
          setIsModalVisible={setConfirmModalVisible}
          sessionData={sessionData}
          setCurrent={setCurrent}
        />
      )}
    </Layout>
  );
};

export default Other;
