const actions = {
  ADD_AVAILABLE_MENTORS: "ADD_AVAILABLE_MENTORS",
  FILTER_AVAILABLE_MENTORS: "FILTER_AVAILABLE_MENTORS",
  DELETE_JOB_REQUEST_DATA: "DELETE_JOB_REQUEST_DATA",
  MENTOR_REQUESTS: "MENTOR_REQUESTS",
  MENTOR_REQUESTS_SUCCESS: "MENTOR_REQUESTS_SUCCESS",
  MENTOR_REQUESTS_FAILURE: "MENTOR_REQUESTS_FAILURE",
  PROPOSE_MENTOR: "PROPOSE_MENTOR",
  PROPOSE_MENTOR_SUCCESS: "PROPOSE_MENTOR_SUCCESS",
  PROPOSE_MENTOR_FAILURE: "PROPOSE_MENTOR_FAILURE",
  GET_JOB_PROPOSED: "GET_JOB_PROPOSED",
  GET_JOB_PROPOSED_SUCCESS: "GET_JOB_PROPOSED_SUCCESS",
  GET_JOB_PROPOSED_FAILURE: "GET_JOB_PROPOSED_FAILURE",
  CREATE_MENTOR_REQUEST: "CREATE_MENTOR_REQUEST",
  CREATE_MENTOR_REQUEST_SUCCESS: "CREATE_MENTOR_REQUEST_SUCCESS",
  CREATE_MENTOR_REQUEST_FAILURE: "CREATE_MENTOR_REQUEST_FAILURE",
  SET_MENTOR_REQUEST_REJECT: "SET_MENTOR_REQUEST_REJECT",
  SET_MENTOR_REQUEST_RESET: "SET_MENTOR_REQUEST_RESET",
  SET_OTHER_REQUEST_APPROVE: "SET_OTHER_REQUEST_APPROVE",
  OTHER_REQUEST_APPROVE_RESET: "OTHER_REQUEST_APPROVE_RESET", 

  createMentorRequest: (payload) => ({
    type: actions.CREATE_MENTOR_REQUEST,
    payload,
  }),

  mentorRequests: (payload) => ({
    type: actions.MENTOR_REQUESTS,
    payload,
  }),

  getMentorsRequestsSuccess: (payload) => ({
    type: actions.MENTOR_REQUESTS_SUCCESS,
    payload,
  }),

  getMentorsRequestsFailure: () => ({
    type: actions.MENTOR_REQUESTS_FAILURE,
  }),

  proposeMentor: (payload) => ({
    type: actions.PROPOSE_MENTOR,
    payload,
  }),

  proposeMentorSuccess: (payload) => ({
    type: actions.PROPOSE_MENTOR_SUCCESS,
    payload,
  }),

  proposeMentorFailure: () => ({
    type: actions.PROPOSE_MENTOR_FAILURE,
  }),

  getJobProposed: (payload) => ({
    type: actions.GET_JOB_PROPOSED,
    payload,
  }),

  getJobProposedSuccess: (payload) => ({
    type: actions.GET_JOB_PROPOSED_SUCCESS,
    payload,
  }),

  getJobProposedFailure: () => ({
    type: actions.GET_JOB_PROPOSED_FAILURE,
  }),

  availableMentors: (payload) => ({
    type: actions.ADD_AVAILABLE_MENTORS,
    payload,
  }),
  filterAvilableMentors: (payload) => ({
    type: actions.ADD_AVAILABLE_MENTORS,
    payload,
  }),
  setMentorReqeustRejectSuccess: (payload) => ({
    type: actions.SET_MENTOR_REQUEST_REJECT,
  }),
  setMentorReqeustReset: (payload) => ({
    type: actions.SET_MENTOR_REQUEST_REJECT,
  }),
  setOtherApprove: (payload) => ({
    type: actions.SET_OTHER_REQUEST_APPROVE,
  }),
  otherRequestReset: (payload) => ({
    type: actions.OTHER_REQUEST_APPROVE_RESET,
  })
};

export default actions;
