import React, { useEffect } from 'react'
import { Modal, Button, Avatar } from 'antd'
import './ViewTicketStyle.scss'
import { convertDOBToAge } from 'helper/utility'
import { getEventAttendeeTickets } from 'api/Events'
import ticketImg from 'assets/Images/ticketImg.svg'
import arrow from 'assets/Images/ticket-arrow.png'
import Cancel from 'assets/Images/close.svg'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import Loader from 'helper/Loader'

interface TicketModalProps {
  isVisible: boolean
  onClose: () => void
  data: any
}

const ViewTicketModal: React.FC<TicketModalProps> = ({
  isVisible,
  onClose,
  data,
}) => {
  const { id }: any = useParams()

  const {
    data: attendeeTickets,
    refetch,
    isLoading,
    isFetching,
  } = useQuery<any>(
    ['getAttendeeTickets'],
    () => getEventAttendeeTickets(id, data._id),
    { enabled: false },
  )

  // Fetch data on modal open
  useEffect(() => {
    if (isVisible) {
      refetch()
    }
  }, [isVisible, refetch])
  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width={500}
      //   title="View tickets"
    >
      <div className="popup-header ">
        <h2 className="w-600 text-24">View tickets</h2>
        <img src={Cancel} alt="close button pointer" onClick={onClose} />
      </div>
      {/* Header Section with User Info */}
      <div className="modal-header flex ">
        <Avatar src={data?.photo?.thumb} size={50}>
          {data?.firstName?.[0]}
        </Avatar>
        <div className="ml-10px">
          <h4 className="text-16">{data?.firstName + data?.lastName}</h4>
          <h3 className="text-12 grey-txt">
            {convertDOBToAge(data?.dob)} years old
          </h3>
        </div>
      </div>

      {/* Ticket List */}
      <div className="ticket-list mt-30px">
        {isLoading || isFetching ? (
          <div className="flex-center justify-center">
            <Loader />
          </div>
        ) : (
          attendeeTickets?.tickets?.map((ticket: any) => (
            <div
              key={ticket.id}
              className="ticket-item mb-1 0px  ModalStyle "
              style={{
                padding: '20px',
                borderBottom: '1px solid #EEEFF1',
              }}
            >
              <div className="flex-center justify-between">
                <div className="flex-center  ">
                  <img src={ticketImg} alt="ticket" />
                  <h2 className="text-16 w-600 ml-10px">{ticket.name}</h2>
                </div>

                <div className="flex-center ">
                  <h3 className="text-14 w-500 mr-10px ">${ticket.price}</h3>
                </div>
              </div>
              <h3 className="text-14 grey-txt ml-40px">{ticket.ticketType}</h3>
              {/* Additional Options */}
              {ticket.additionalOptions.map((option: any) => (
                // <div key={option.additionalId} className="additional-option">
                //   <p className="additional-name">{option.name}</p>
                //   <p className="additional-type">Type: {option.ticketType}</p>
                //   <p className="additional-price">${option.price.toFixed(2)}</p>
                // </div>

                <div
                  className="flex-center justify-between mt-10px"
                  key={option}
                >
                  <div className="flex-center">
                    <img src={arrow} alt="ticket" />
                    <h2 className="text-16 w-600 ml-10px">{option.name}</h2>
                  </div>
                  <div className="flex-center">
                    <h3 className="text-14 w-500 mr-10px">${option.price}</h3>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>

      {/* Modal Footer Buttons */}
      {/* <div className="modal-footer flex justify-between mt-30px">
        <Button type="default" onClick={() => alert('Add new ticket clicked')}>
          Add a new ticket
        </Button>
        <Button type="primary" onClick={() => alert('Update clicked')}>
          Update
        </Button>
      </div> */}
    </Modal>
  )
}

export default ViewTicketModal
