import React, { useEffect } from 'react'
import './styles.scss'
import { Avatar, Progress, Rate } from 'antd'
import { useGetEventReviewsList, useGetEventReviewsStats } from 'api/Events'
import { useParams } from 'react-router-dom'
import Spinner from 'components/Spinner'
import { getNameSignature } from 'helper/utility'
import moment from 'moment'

const Reviews = () => {
  const { id }: any = useParams()
  const {
    isLoading: eventReviewsStatLoading,
    data: eventReviewsStat,
    refetch: eventReviewsStatRefetch,
  } = useGetEventReviewsStats({ eventId: id })
  const {
    isLoading: eventReviewsListLoading,
    data: eventReviewsList,
    refetch: eventReviewsListRefetch,
  } = useGetEventReviewsList({ eventId: id })

  useEffect(() => {
    eventReviewsStatRefetch()
    eventReviewsListRefetch()
  }, [id])

  console.log(eventReviewsList?.records, 'records')

  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        <div className="d-flex gap-40px">
          {eventReviewsStatLoading || eventReviewsListLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="w-50p flex align-center gap-40px">
                <div className="flex flex-col justify-center">
                  <h1 className="text-40">{eventReviewsStat.averageRating}</h1>
                  <Rate
                    defaultValue={Math.round(
                      Number(eventReviewsStat.averageRating),
                    )}
                    disabled
                    className="-mt-15px"
                  />
                  <p>{`${eventReviewsStat.totalReviews} Reviews`}</p>
                </div>
                <div className="w-progress-container">
                  <div className="flex align-center gap-16px mt-4px">
                    <p className="text-13px w-500 text-space-nowrap">5 Stars</p>
                    <Progress
                      percent={eventReviewsStat.starDistribution['1-star']}
                      strokeColor="#8CC73F"
                      format={percent => `${percent}%`}
                    />
                  </div>
                  <div className="flex align-center gap-16px mt-4px">
                    <p className="text-13px w-500 text-space-nowrap">4 Stars</p>
                    <Progress
                      percent={eventReviewsStat.starDistribution['2-star']}
                      strokeColor="#8CC73F"
                      format={percent => `${percent}%`}
                    />
                  </div>
                  <div className="flex align-center gap-16px mt-4px">
                    <p className="text-13px w-500 text-space-nowrap">3 Stars</p>
                    <Progress
                      percent={eventReviewsStat.starDistribution['3-star']}
                      strokeColor="#8CC73F"
                      format={percent => `${percent}%`}
                    />
                  </div>
                  <div className="flex align-center gap-16px mt-4px">
                    <p className="text-13px w-500 text-space-nowrap">2 Stars</p>
                    <Progress
                      percent={eventReviewsStat.starDistribution['4-star']}
                      strokeColor="#8CC73F"
                      format={percent => `${percent}%`}
                    />
                  </div>
                  <div className="flex align-center gap-16px mt-4px">
                    <p className="text-13px w-500 text-space-nowrap">1 Star</p>
                    <Progress
                      percent={eventReviewsStat.starDistribution['5-star']}
                      strokeColor="#8CC73F"
                      format={percent => `${percent}%`}
                    />
                  </div>
                </div>
              </div>
              <div className="w-50p">
                {eventReviewsList.records.length > 0 ? (
                  <div>
                    {eventReviewsList.records.map((review, index) => {
                      return (
                        <div key={index} className="py-15px border-btm">
                          <div className="flex align-center gap-16px">
                            <Avatar
                              src={review.reviewedByguardian.photo?.post}
                              size={48}
                            >
                              {getNameSignature(
                                review.reviewedByguardian.firstName,
                              )}
                            </Avatar>
                            <div>
                              <h3 className="text-16px w-500">
                                {review.reviewedByguardian.firstName}
                              </h3>
                              <p className="text-gray text-14px w-400">
                                {moment(review.createdAt).format('MM/DD/YYYY')}
                              </p>
                            </div>
                          </div>
                          <Rate
                            defaultValue={Math.round(review.averageRating)}
                            disabled
                          />
                          <p className="text-14px w-400 mt-10px">
                            {review.text}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <h4>No Reviews available</h4>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Reviews
