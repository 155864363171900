import { Modal } from 'antd'
import React from 'react'
import closeIcon from 'assets/Images/close.svg'
import bamLogo from 'assets/Images/bam_logo.svg'
import { socialIcon } from 'pages/Events/Views/CreateEvent/utility'

interface PreviewTemplateProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  text: string
}

const PreviewTemplate: React.FC<PreviewTemplateProps> = ({
  openModal,
  closeModal,
  text,
}) => {
  const handleCancel = () => {
    closeModal(false)
  }

  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="preview-template"
        footer={false}
        width={700}
        centered
      >
        <div className="px-40 pt-20px">
          <div className="flex-center pt-20px justify-between ">
            <h2 className="w-600 text-18">Preview Template</h2>
            <img
              src={closeIcon}
              alt="close-button"
              onClick={handleCancel}
              className="close button pointer"
            />
          </div>
          <div className="">
            <div className="flex w-full justify-center">
              <img src={bamLogo} alt="logo" width={130} height={90} />
            </div>
            <div
              className="mt-10px h-18rem scroller overflow-auto-y"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>

            <div
              className="flex flex-column align-center"
              style={{ marginTop: '60px' }}
            >
              <div className="flex gap-30px">
                {socialIcon.map((item, index) => (
                  <a href={item.url} target="_blank" key={index}>
                    <img src={item.icon} alt={item.url} />
                  </a>
                ))}
              </div>
              <p className="text-12 w-400 mt-20px">
                Copyright. Bamrec 2021. All rights rererved
              </p>
            </div>
          </div>

          <div className="mt-20px flex justify-end">
            <button className="btn-secondary" onClick={handleCancel}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PreviewTemplate
