/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Row,
  Form,
  Upload,
  Col,
  Radio,
  Select,
  Image,
  Space,
} from 'antd'
import { useDispatch, connect } from 'react-redux'
import actions from '../../../../../Redux/Family/action'
import sessionActions from '../../../../../Redux/Session/action'
import './video.css'
import ReactPlayer from 'react-player'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { Layout } from 'antd'
import 'react-quill/dist/quill.snow.css'
import Icon from '../../../../../assets/Images/icon.svg'
import Gallery from '../../../../../assets/Images/galleryIcon.svg'
import Music from '../../../../../assets/Images/musicIcon.svg'
import playIcon from '../../../../../assets/Images/playIcon.svg'
import pause from '../../../../../assets/Images/pause.svg'
import Pin from '../../../../../assets/Images/Pinicon.svg'
import Document from '../../../../../assets/Images/document.svg'
import PlayerImage from '../../../../../assets/Images/playerIcon.svg'
import ImageSquareSvg from '../../../../../assets/Images/ImageSquare.svg'
import fileActions from '../../../../../Redux/FileUpload/action'
import TextArea from 'antd/lib/input/TextArea'
import Location from '../../../../../helper/Location/Location'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getEventParticipants } from '../../../../../api/Events'
import { addLiveUpdate } from '../../../../../api/Liveupdate'

const LiveUpdateModal = ({
  familyData,
  fileData,
  loading,
  contractRoomId,
  isLiveUpdatesModalVisible,
  setLiveUpdatesModalVisible,
}: any) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [galleryUrl, setGalleryUrl] = useState<any>([])
  const [videoUrl, setVideoUrl] = useState<any>([])
  const [audioUrl, setAudioUrl] = useState<any>([])
  const [mapData, setMapModalData] = useState<any>(null)
  const [showTo, setShowTo] = useState('public')
  const [sendNotification, setSendNotification] = useState(false)
  const [locationVisible, setLoactionVisible] = useState(false)
  const [demoState, setDemoState] = useState([])
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)

  const formSchema = {
    showTo: 'public',
    sendNotification: false,
    taggedKids: [],
    text: '',
    photoId: '',
    videoId: '',
    audioId: '',
  }

  useEffect(() => {
    dispatch({
      type: actions.GET_FAMILIES,
    })
  }, [])
  const { isLoading, data } = useQuery(
    ['getParticipants', contractRoomId],
    () => getEventParticipants(contractRoomId),
  )
  console.log(JSON.stringify(data) + ' asdasgfa')
  useEffect(() => {
    if (fileData) {
      if (fileData?.description === 'video_id') {
        form.setFieldsValue({
          videoId: [fileData._id],
        })
      } else if (fileData?.description === 'audio_id') {
        form.setFieldsValue({
          audioId: [fileData._id],
        })
      } else if (fileData?.description === 'image_id') {
        form.setFieldsValue({
          photoId: [fileData._id],
        })
      }
      dispatch({
        type: fileActions.CLEAR_FILE_DATA,
      })
    }
  }, [loading])

  const handleChangeTag = (props: any) => {}

  const propsForPhoto: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        info.file.status = 'done'
      }
      if (info.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', info.file.originFileObj)
        let newFile = {
          title: 'session',
          description: 'image_id',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: fileActions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    },
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      if (type[0] === 'image')
        setGalleryUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      else
        setVideoUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      return true
    },
  }

  const propsForVideo: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        info.file.status = 'done'
      }
      if (info.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', info.file.originFileObj)
        let newFile = {
          title: 'session',
          description: 'video_id',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: fileActions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    },
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      if (type[0] === 'image')
        setGalleryUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      else
        setVideoUrl((prevValue: any) => [
          ...prevValue,
          { url: url, type: type[0] },
        ])
      return true
    },
  }

  const propsForAudio: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        info.file.status = 'done'
      }
      if (info.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', info.file.originFileObj)
        let newFile = {
          title: 'session',
          description: 'audio_id',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: fileActions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    },
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      setAudioUrl((prevValue: any) => [...prevValue, { url: url }])
      return true
    },
  }

  const handleOk = () => {
    setLiveUpdatesModalVisible(false)
  }

  const { Option } = Select

  const children: React.ReactNode[] = []
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>,
    )
  }

  const handleDeleteImage = (idx: any) => {
    let newImageData = galleryUrl.splice(idx, 1)
    setDemoState(newImageData)
  }

  const handleDeleteVideo = (idx: any) => {
    let newArr = videoUrl.splice(idx, 1)
    setDemoState(newArr)
  }

  const handleDeleteAudio = (idx: any) => {
    let newAudioData = audioUrl.splice(idx, 1)
    setDemoState(newAudioData)
  }

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  }

  const onRadioChange = (e: any) => {
    const eventName = e.target.name,
      eventValue = e.target.value
    if (eventName === 'sendNotification') {
      setSendNotification(eventValue)
    } else {
      setShowTo(eventValue)
    }
  }

  const playPauseVideo = (e: any, idx: any) => {
    e.preventDefault()
    let player: any = document.getElementById(`video-element-${idx}`)
    let btnPlayPause: any = document.getElementById(`btnPlayPause${idx}`)
    if (player.paused || player.ended) {
      // Change the button to a pause button
      changeButtonType(btnPlayPause, 'play-pause-btn pause')
      player.play()
    } else {
      // Change the button to a play button
      changeButtonType(btnPlayPause, 'play-pause-btn play')
      player.pause()
    }
  }

  const changeButtonType = (btn: any, value: any) => {
    btn.className = value
  }
  const addUpdate = useMutation({
    mutationFn: async mutData => {
      return await addLiveUpdate(mutData)
    },
  })
  const onFinish = (values: any) => {
    // console.log("values:", values);
    let dataValues = { ...values }
    dataValues.ownerId = contractRoomId ? contractRoomId : ''
    dataValues.ownerType = 'organizer'
    dataValues.type = 'event'
    dataValues.belongsToId = contractRoomId ? contractRoomId : ''
    dataValues.visbility = showTo
    dataValues.imageIds = values.photoId ? values.photoId : []
    dataValues.videoIds = values.videoId ? values.videoId : []
    dataValues.audioIds = values.audioId ? values.audioId : []
    dataValues.location = mapData

    // dataValues.location = mapData;
    // dataValues.datesOfEvent = values.date.map((item: any) =>
    //   moment(item).format("YYYY-MM-DDTHH:mm:ss[Z]")
    // );
    // dataValues.name = values.eventName;
    // dataValues.category = {
    //   _id: categoryVal._id,
    //   name: categoryVal.name,
    // };
    // dataValues.venueType = "virtual";
    // dataValues.venueId = venueDataItem ? venueDataItem?._id : "";
    // dataValues.timings = {
    //   startTime: 1440,
    //   endTime: 1440,
    //   specialSchedules: [],
    // };
    // console.log("data:", dataValues);
    addUpdate.mutate(dataValues)

    setLiveUpdatesModalVisible(false)
    // if (eventOrganizationData?._id) {
    //   dispatch({ type: actions.EDIT_EVENTS_DATA, payload: dataValues });
    // } else {
    //   dispatch({ type: actions.ADD_EVENTS_DATA, payload: dataValues });
    // }
    // handleNext({
    //   ...values,
    //   location: mapData,
    // });
  }

  const uploadButton = (
    <div>
      {<img alt="" src={ImageSquareSvg}></img>}
      <div style={{ marginTop: 8 }}>Add a picture</div>
    </div>
  )
  return (
    <Layout>
      <Modal
        title="Add updates"
        visible={true}
        onOk={handleOk}
        onCancel={() => {
          setLiveUpdatesModalVisible(false)
        }}
        centered={true}
        footer={null}
        width="600px"
      >
        <Form
          form={form}
          layout="horizontal"
          initialValues={formSchema}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className="add-activity-modal-h">
            <Row>
              <Col>
                <p className="b1 font16 text-color-black">Updates settings</p>
              </Col>
              <Col className="mt-10">
                <span className="font12">
                  <b>Who can see your live updates? </b>
                </span>
              </Col>
              <Col className="mt-10">
                <Form.Item className="form-control" name="showTo">
                  <Radio.Group
                    name="radiogroup"
                    onChange={onRadioChange}
                    value={showTo}
                  >
                    <Radio
                      className="radio align-items-end"
                      value={'public'}
                      checked
                    >
                      Public
                    </Radio>
                    <Radio
                      className="radio align-items-end"
                      value={'parentWithRegisteredKids'}
                    >
                      Parents of registered kids
                    </Radio>
                    <Radio
                      className="radio align-items-end"
                      value={'parentWithTaggedKids'}
                    >
                      Parents of tagged kids
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="font12">
                  <b>
                    Would you like to send a push notification to the chosen
                    audience?
                  </b>
                </span>
              </Col>
              <Col className="mt-10">
                <Form.Item className="form-control" name="sendNotification">
                  <Radio.Group
                    name="sendNotification"
                    onChange={onRadioChange}
                    value={sendNotification}
                  >
                    <Radio
                      className="radio align-items-end"
                      value={false}
                      checked
                    >
                      No, don’t send notifications
                    </Radio>
                    <Radio
                      className="radio align-items-end"
                      value={true}
                      checked
                    >
                      Yes, send notifications
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="font12 text-color-black">
                  <b>Tag people</b>
                </p>
              </Col>
              <Col>
                <Form.Item className="form-control" name="taggedKids">
                  <Select
                    className="mt-10 tag-people-field"
                    mode="tags"
                    placeholder="Select or enter name"
                    onChange={handleChangeTag}
                  >
                    {
                      (data?.guardians.map((item: any) => {
                        return (
                          <Option value={item._id} key={item._id}>
                            {item.firstName} {item.lastName}
                          </Option>
                        )
                      }),
                      data?.authorizedAdults.map((item: any) => {
                        return (
                          <Option value={item._id} key={item._id}>
                            {item.firstName} {item.lastName}
                          </Option>
                        )
                      }),
                      data?.kids.map((item: any) => {
                        return (
                          <Option value={item._id} key={item._id}>
                            {item.firstName} {item.lastName}
                          </Option>
                        )
                      }))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="mt-5">
                <p className="font12 text-color-black">
                  <b>What do you want to say?</b>
                </p>
              </Col>
              <Col className="mt-10">
                <Form.Item className="form-control mb-20" name="text">
                  <TextArea rows={4} placeholder="Enter a text of the update" />
                </Form.Item>
              </Col>
            </Row>
            {galleryUrl.length > 0 && (
              <Row className="mb-20">
                <Col>
                  <p className="b1 font16 text-color-black">Images</p>
                </Col>
                <Col className="antd-img-upload d-flex gap-20 mt-10" style={{}}>
                  {galleryUrl.map((item: any, index: any) => {
                    return (
                      <>
                        <div className="img-item">
                          <img
                            alt="#img"
                            className="image-closing-icon mt-5"
                            src={Icon}
                            onClick={() => handleDeleteImage(index)}
                          />
                          <Image
                            key={index}
                            className="br-12 object-fit-cover"
                            height={140}
                            width={140}
                            src={item.url}
                            rootClassName={'mb-10 min-w-140'}
                          />
                        </div>
                      </>
                    )
                  })}
                </Col>
              </Row>
            )}
            {videoUrl.length > 0 && (
              <Row className="mb-20">
                <Col>
                  <p className="b1 font16 text-color-black">Videos</p>
                </Col>
                <Col className="antd-img-upload d-flex gap-20 mt-10" style={{}}>
                  {videoUrl.map((item: any, index: any) => {
                    return (
                      <div
                        style={{
                          height: '140px',
                          width: '140px',
                          position: 'relative',
                        }}
                      >
                        <img
                          alt="#img"
                          className="video-closing-icon mt-5"
                          src={Icon}
                          onClick={() => handleDeleteVideo(index)}
                        />
                        <div className="session-video">
                          <video
                            style={{
                              width: '140px',
                              height: '140px',
                              objectFit: 'fill',
                            }}
                            id={`video-element-${index}`}
                            className="br-12 min-w-140 "
                            src={item.url}
                            width={140}
                            height={140}
                          />
                          <div id="controls">
                            <button
                              id={`btnPlayPause${index}`}
                              className="play-pause-btn play"
                              onClick={(e: any) => playPauseVideo(e, index)}
                            >
                              <img
                                src={playIcon}
                                alt="playIcon"
                                width={11}
                                className="playIcon"
                              />
                              <img
                                src={pause}
                                alt="pauseicon"
                                width={24}
                                className="pauseIcon"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Col>
              </Row>
            )}
            {audioUrl.length > 0 && (
              <Row className="mb-20">
                <Col>
                  <b>Audio</b>
                </Col>
                <Col
                  className="d-flex justify-between flex-wrap mt-10"
                  style={{ width: '400px' }}
                >
                  {audioUrl.map((item: any, index: any) => (
                    // console.log("audioUrl:", audioUrl),
                    <div
                      style={{
                        height: '50px',
                        width: '400px',
                        position: 'relative',
                      }}
                    >
                      <img
                        alt="#img"
                        className="audio-closing-icon mt-5"
                        src={Icon}
                        onClick={() => handleDeleteAudio(index)}
                      />
                      <ReactPlayer
                        // style={{marginBottom:"20px"}}
                        className="mb-20"
                        url={item.url}
                        width="400px"
                        height="50px"
                        // playing={false}
                        controls={true}
                        muted={false}
                      />
                    </div>
                  ))}
                </Col>
              </Row>
            )}
            {locationVisible && (
              <Row className="mb-20">
                <Col>
                  <b>Location</b>
                </Col>
                <Col className="location-container">
                  {locationVisible && (
                    <Location
                      mapData={mapData}
                      setMapModalData={setMapModalData}
                      setLoactionVisible={setLoactionVisible}
                    />
                  )}
                </Col>
              </Row>
            )}
          </div>
          <Row className="d-flex mt-40">
            <Col className="d-flex" span={14}>
              <Space size={15}>
                <Form.Item className="form-control mb-0" name="photoId">
                  <Upload {...propsForPhoto} accept={'image/*'} multiple={true}>
                    <img alt="#img" src={Gallery} />
                  </Upload>
                </Form.Item>
                <Form.Item className="form-control mb-0" name="videoId">
                  <Upload {...propsForVideo} accept={'video/*'} multiple={true}>
                    <img alt="#img" src={PlayerImage} />
                  </Upload>
                </Form.Item>
                <Form.Item className="form-control mb-0" name="audioId">
                  <Upload {...propsForAudio} accept={'audio/*'} multiple={true}>
                    <img alt="#img" src={Music} />
                  </Upload>
                </Form.Item>
                <Form.Item className="form-control mb-0" name="locationId">
                  <img
                    alt="#img"
                    src={Pin}
                    onClick={() => {
                      setLoactionVisible(true)
                    }}
                  />
                </Form.Item>
                <Form.Item className="form-control mb-0" name="locationId">
                  <img
                    alt="#img"
                    src={Document}
                    onClick={() => {
                      setIsAddFormOpen(true)
                    }}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={10} className="text-right">
              <Button className="btn-prim br-8" htmlType="submit">
                Add update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* {isAddFormOpen && (
        <AddDocumentmodal setIsAddFormOpen={setIsAddFormOpen} />
      )} */}
    </Layout>
  )
}
const mapStateToProps = (state: any) => ({
  familyData: state.family.familyData,
  modalVisible: state.family.isModalVisible,
  currentFamilyData: state.family.currentFamilyData,
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})

export default connect(mapStateToProps)(LiveUpdateModal)
