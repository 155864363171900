import { Avatar, Row, Space, Table, Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import mentorsAction from "../../../Redux/mentors/action";
import actions from "../../../Redux/mentors/action";
import StatusDropdown from "../../../helper/StatusDropdown";
import deleteBlackIcon from "../../../assets/Images/deleteBlackIcon.svg";
import editblackPencil from "../../../assets/Images/editblackPencil.svg";
import moment from "moment";
import { history } from "../../../Redux/app-store";
import { connect, useDispatch } from "react-redux";
import { SorterResult } from "antd/es/table/interface";

const History = ({ activeData, mentorsData }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: actions.GET_MENTORS,
    });
  }, []);

  console.log(mentorsData, "ss");
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  });
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const onChangeColumnCheckBox = (e: any) => {
    console.log(`checked = ${e.target.name}`);

    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    });
  };

  const newArray = mentorsData?.map((item: any, index: any) => {
    return {
      key: item.id,
      firstname: item.firstName,
      mentorAvatar: item.photo?.post,
      dob: moment(item.dob).format("MM/DD/YYYY"),
      totalhours: "4hr",
      status: item.status,
      action: [
        <Space key={item.id} className="orgn-action-btn">
          <Tooltip placement="bottom" title="Edit" color="blue">
            <img
              src={editblackPencil}
              width="20"
              onClick={() => {
                dispatch({
                  type: mentorsAction.CURRENT_MENTOR_DATA,
                  payload: item,
                });
                dispatch({
                  type: mentorsAction.MENTORS_MODAL_VISIBLE,
                  payload: true,
                });
              }}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete" color="blue">
            <img src={deleteBlackIcon} onClick={(e) => {}} />
          </Tooltip>
        </Space>,
      ],
    };
  });
  const columnss = [
    {
      title: "Mentor",
      dataIndex: "firstname",
      key: "firstname",
      render: (text: any, record: any, index: any) => (
        <div
          className="cursor_pointer"
          onClick={() => {
            history.push({
              pathname: `/mentor-profile/${record.key}`,
              state: JSON.stringify(record.key),
            });
          }}
        >
          <Tooltip placement="bottom" title={text} color="blue">
            <Space size={8}>
              <Avatar
                size={24}
                icon={<img alt="img" src={record.mentorAvatar}></img>}
              ></Avatar>
              {text}
            </Space>
          </Tooltip>
        </div>
      ),
      sorter: (a: any, b: any) => a.firstname.length - b.firstname.length,
      sortOrder: sortedInfo.columnKey === "firstname" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.firstname === false ? "display-none" : "",
    },
    {
      title: "Date",
      dataIndex: "dob",
      key: "dob",
      hidden: true,
      className: hiddenColumns.dob === false ? "display-none" : "",
    },
    {
      title: "Time",
      dataIndex: "totalhours",
      key: "totalhours",
      hidden: true,
      className: hiddenColumns.totalhours === false ? "display-none" : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: true,
      render: (text: String, record: any) => (
        <StatusDropdown id={record.key} name="mentor" status={text} />
      ),
      className: hiddenColumns.status === false ? "display-none" : "",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      hidden: true,
      width: 120,
      sorter: false,
      className: hiddenColumns.action === false ? "display-none" : "",
    },
  ];

  const rowSelection = {};

  return (
    <Row>
      <Table
        rowKey="key"
        rowSelection={rowSelection}
        style={{ width: "100%" }}
        columns={columnss}
        dataSource={newArray}
        pagination={{ pageSize: 10, showSizeChanger: true }}
        scroll={{ x: 400 }}
      />
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  mentorsData: state.mentor.mentorsData,
});

export default connect(mapStateToProps)(History);
