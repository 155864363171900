// import React, { useState } from 'react'
// import { Modal, Radio, Checkbox } from 'antd'
// import { CSVLink } from 'react-csv'
// import { saveAs } from 'file-saver'
// import * as XLSX from 'xlsx'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
// import Cancel from 'assets/Images/close.svg'

// const ExportModalFamily = ({ visible, onClose, data }) => {
//   const [fileFormat, setFileFormat] = useState('csv')
//   const [selectedColumns, setSelectedColumns] = useState([
//     'Family Member',
//     'Role',
//     'First Name',
//     'Last Name',
//     'Date of Birth',
//   ])

//   const handleCancel = () => {
//     onClose()
//   }

//   const csvData = data
//     ?.map(item => [
//       ...item.kids.map(kid => ({
//         familyMember: 'Kid',
//         role: '',
//         firstName: kid.firstName,
//         lastName: kid.lastName,
//         dob: kid.dob,
//       })),
//       ...item.guardians.map(guardian => ({
//         familyMember: 'Guardian',
//         role: guardian.relation.name,
//         firstName: guardian.firstName,
//         lastName: guardian.lastName,
//         dob: '',
//       })),
//       ...item.adults.map(adult => ({
//         familyMember: 'Adult',
//         role: adult.relation.name,
//         firstName: adult.firstName,
//         lastName: adult.lastName,
//         dob: '',
//       })),
//     ])
//     .flat()

//   const headers = [
//     { label: 'Family Member', key: 'familyMember' },
//     { label: 'Role', key: 'role' },
//     { label: 'First Name', key: 'firstName' },
//     { label: 'Last Name', key: 'lastName' },
//     { label: 'Date of Birth', key: 'dob' },
//   ]

//   const handleFormatChange = e => {
//     setFileFormat(e.target.value)
//   }

//   const handleColumnChange = (checkedValue, label) => {
//     setSelectedColumns(prev =>
//       checkedValue ? [...prev, label] : prev.filter(col => col !== label),
//     )
//   }

//   const handleExport = () => {
//     const filteredHeaders = headers.filter(header =>
//       selectedColumns.includes(header.label),
//     )
//     const filteredData = csvData.map(row => {
//       const filteredRow = {}
//       filteredHeaders.forEach(header => {
//         filteredRow[header.key] = row[header.key]
//       })
//       return filteredRow
//     })

//     if (fileFormat === 'csv') {
//       document.getElementById('csv-download-link').click()
//     } else if (fileFormat === 'excel') {
//       const ws = XLSX.utils.json_to_sheet(filteredData)
//       const wb = XLSX.utils.book_new()
//       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
//       XLSX.writeFile(wb, 'Family_Members.xlsx')
//     } else if (fileFormat === 'pdf') {
//       const doc = new jsPDF()
//       const tableColumn = filteredHeaders.map(header => header.label)
//       const tableRows = filteredData.map(row =>
//         filteredHeaders.map(header => row[header.key]),
//       )

//       ;(doc as any).autoTable({
//         head: [tableColumn],
//         body: tableRows,
//       })

//       doc.save('Family_Members.pdf')
//     }
//     onClose()
//   }

//   return (
//     <Modal visible={visible} footer={false} className="addExport-model">
//       <div className="popup-header ">
//         <h2>Export Family Data</h2>
//         <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
//       </div>
//       <div className="formatText ">Format </div>
//       <Radio.Group onChange={handleFormatChange} value={fileFormat}>
//         <Radio value="csv">CSV</Radio>
//         <Radio value="excel">Excel</Radio>
//         <Radio value="pdf">PDF</Radio>
//       </Radio.Group>
//       <div className="radioText mt-15px">Please select columns to export </div>
//       <div className="checkbox-group">
//         {[
//           'Family Member',
//           'Role',
//           'First Name',
//           'Last Name',
//           'Date of Birth',
//         ].map(label => (
//           <div key={label}>
//             <Checkbox
//               checked={selectedColumns.includes(label)}
//               onChange={e => handleColumnChange(e.target.checked, label)}
//             >
//               {label}
//             </Checkbox>
//           </div>
//         ))}
//       </div>
//       <div className="flex justify-end w-full mt-10px">
//         <div className="bg-grey radius-8 pointer mr-30px">
//           <button
//             className="prim-sm-btn"
//             style={{ background: '#EBEDF1', color: 'black' }}
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//         </div>
//         <button className="prim-sm-btn" onClick={handleExport}>
//           Export
//         </button>
//       </div>
//       <CSVLink
//         data={csvData}
//         headers={headers.filter(header =>
//           selectedColumns.includes(header.label),
//         )}
//         filename="Family_Members.csv"
//         id="csv-download-link"
//         style={{ display: 'none' }}
//       />
//     </Modal>
//   )
// }

// export default ExportModalFamily

import React, { useState } from 'react'
import { Modal, Radio, Checkbox } from 'antd'
import { CSVLink } from 'react-csv'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Cancel from 'assets/Images/close.svg'
import moment from 'moment'
const ExportModalFamily = ({ visible, onClose, data }) => {
  const [fileFormat, setFileFormat] = useState('csv')
  const [selectedColumns, setSelectedColumns] = useState([
    'Family',
    'Family Member',
    'Role',
    'First Name',
    'Last Name',
    'Date of Birth',
    'Primary Contact',
    'Status',
  ])

  const handleCancel = () => {
    onClose()
  }

  const csvData = data

    ?.map(item => [
      ...item.kids.map(kid => ({
        family: item?.name,
        familyMember: 'Kid',
        role: 'Kid',
        firstName: kid.firstName,
        lastName: kid.lastName,
        // dob: moment(kid?.dob).format('MMM/DD/YYYY'),
        primaryContact: '',
        status: item?.status,
      })),
      ...item.guardians.map(guardian => ({
        family: item?.name,
        familyMember: 'Guardian',
        role: guardian.relation.name,
        firstName: guardian.firstName,
        lastName: guardian.lastName,
        // dob: '',
        primaryContact: guardian?.phone?.number,
        status: item?.status,
      })),
      ...item.adults.map(adult => ({
        family: item?.name,
        familyMember: 'Adult',
        role: adult.relation.name,
        firstName: adult.firstName,
        lastName: adult.lastName,
        // dob: '',
        primaryContact: '',
        status: item?.status,
      })),
    ])
    .flat()

  const headers = [
    { label: 'Family', key: 'family' },
    { label: 'Family Member', key: 'familyMember' },
    { label: 'Role', key: 'role' },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    // { label: 'DoB', key: 'dob' },
    { label: 'Primary Contact', key: 'primaryContact' },
    { label: 'Status', key: 'status' },
  ]

  const handleFormatChange = e => {
    setFileFormat(e.target.value)
  }

  const handleColumnChange = (checkedValue, label) => {
    setSelectedColumns(prev =>
      checkedValue ? [...prev, label] : prev.filter(col => col !== label),
    )
  }

  const handleExport = () => {
    const filteredHeaders = headers.filter(header =>
      selectedColumns.includes(header.label),
    )
    const filteredData = csvData.map(row => {
      const filteredRow = {}
      filteredHeaders.forEach(header => {
        filteredRow[header.key] = row[header.key]
      })
      return filteredRow
    })

    if (fileFormat === 'csv') {
      document.getElementById('csv-download-link').click()
    } else if (fileFormat === 'excel') {
      const ws = XLSX.utils.json_to_sheet(filteredData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'Family_Members.xlsx')
    } else if (fileFormat === 'pdf') {
      const doc = new jsPDF()
      const tableColumn = filteredHeaders.map(header => header.label)
      const tableRows = filteredData.map(row =>
        filteredHeaders.map(header => row[header.key]),
      )

      ;(doc as any).autoTable({
        head: [tableColumn],
        body: tableRows,
      })

      doc.save('Family_Members.pdf')
    }
    onClose()
  }

  return (
    <Modal visible={visible} footer={false}>
      <div className="popup-header ">
        <h2>Export Data</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <div className="formatText ">Format </div>
      <Radio.Group onChange={handleFormatChange} value={fileFormat}>
        <Radio value="csv">CSV</Radio>
        <Radio value="excel">Excel</Radio>
        <Radio value="pdf">PDF</Radio>
      </Radio.Group>
      <div className="radioText mt-15px">Please select columns to export </div>
      <div className="checkbox-group">
        {[
          'Family',
          'Family Member',
          'Role',
          'First Name',
          'Last Name',
          // 'Date of Birth',
          'Primary Contact',
          'Status',
        ].map(label => (
          <div key={label}>
            <Checkbox
              checked={selectedColumns.includes(label)}
              onChange={e => handleColumnChange(e.target.checked, label)}
            >
              {label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full mt-40px">
        <div className="bg-grey radius-8 pointer mr-30px">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
        <button className="prim-sm-btn" onClick={handleExport}>
          Export
        </button>
      </div>
      <CSVLink
        data={csvData}
        headers={headers.filter(header =>
          selectedColumns.includes(header.label),
        )}
        filename="Family_Members.csv"
        id="csv-download-link"
        style={{ display: 'none' }}
      />
    </Modal>
  )
}

export default ExportModalFamily
