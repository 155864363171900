const actions = {
  GET_SKILLS: "GET_SKILLS",
  GET_SKILLS_SUCCESS: "GET_SKILLS_SUCCESS",
  GET_SKILLS_FAILURE: "GET_SKILLS_FAILURE",

  getSkills: (payload) => ({
    type: actions.GET_SKILLS,
    payload,
  }),

  getSkillsSuccess: (payload) => ({
    type: actions.GET_SKILLS_SUCCESS,
    payload,
  }),

  getSkillsFailure: () => ({
    type: actions.GET_SKILLS_FAILURE,
  }),
};

export default actions;
