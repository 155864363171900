import AppLayout from 'components/layout/layout'
import PageHeaderInventory from '../Inventory/Headers/PageHeaderInventory'
import ActionHeader from '../Inventory/Headers/ActionHeader'
import { useEffect, useState } from 'react'
import { Row, Col, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import Spinner from '../../components/Spinner'
import InventoryTable from './Table/InventoryTable'
import { getInventory } from 'api/Inventory'
import { GetRequestParams, RequestParms } from '../../types/globalTypes'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import actions from '../../Redux/Organizer/action'
import { useDispatch } from 'react-redux'
import { myStore } from 'state/inventoryStore'
import InventoryList from './ListView/InventoryList'
const Inventory = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [activeData, setActiveData] = useState<any[]>([])
  const dispatch = useDispatch()
  const [parms, setParms] = useState<RequestParms & GetRequestParams>({
    page: 1,
    limit: 10,
    status: 'active',
  })
  const { isLoading, data, refetch } = useQuery(['getInventory', parms], () =>
    getInventory(parms),
  )
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    name: true,
    organization: true,
    location: true,
    Quantity: true,
    Price: true,
  })
  const handleSearch = (query: string) => {
    setParms({
      ...parms,
      search: query,
    })
  }
  const handleChangeStatus = (value: string) => {
    setParms({ ...parms, status: value })
  }
  useEffect(() => {
    refetch()
  }, [parms])
  const deleteMultipleRecords = () => {
    let _data = [...activeData]
    selectedRows.forEach((row: any) => {
      _data = data.filter(t => t.id !== row.key)
    })
    setActiveData(_data)
    dispatch({
      type: actions.ADD_ORGANIZER,
      payload: _data,
    })
  }
  const view = myStore(store => store.view)
  // console.log(view, 'view', view === 'table')
  return (
    <AppLayout>
      <PageHeaderInventory
        selectedRows={selectedRows}
        data={data}
        deleteMulti={deleteMultipleRecords}
      />
      <Row>
        <Col style={{ width: '100%' }}>
          {/* <Tabs className="orgn-tabs" defaultActiveKey="1" > */}
          {/* <TabPane tab="Active" key="1"> */}
          <ActionHeader
            setCols={setHiddenColumns}
            hidCols={hiddenColumns}
            search={handleSearch}
            parms={parms}
            setParms={setParms}
            deleteMulti={deleteMultipleRecords}
          />
          {/* <Row>
            <Col style={{ alignItems: 'center', width: '100%' }}>
              {isLoading ? (
                <div
                  className="flex justify-center aligin-center items-center "
                  style={{ height: '80vh' }}
                >
                  <Spinner />
                </div>
              ) : (
                <InventoryTable
                  setSelectedRows={setSelectedRows}
                  hidCols={hiddenColumns}
                  data={data}
                  params={parms}
                  setParams={setParms}
                  deleteMulti={deleteMultipleRecords}
                />
              )}
            </Col>
          </Row> */}
          {view === 'table' && (
            <Row>
              <Col style={{ alignItems: 'center', width: '100%' }}>
                {isLoading ? (
                  <div
                    className="flex justify-center aligin-center items-center "
                    style={{ height: '80vh' }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <InventoryTable
                    setSelectedRows={setSelectedRows}
                    hidCols={hiddenColumns}
                    data={data}
                    params={parms}
                    setParams={setParms}
                    deleteMulti={deleteMultipleRecords}
                  />
                )}
              </Col>
            </Row>
          )}
          {view === 'list' && (
            <InventoryList
              setSelectedRows={setSelectedRows}
              hidCols={hiddenColumns}
              data={data}
              params={parms}
              setParams={setParms}
            />
          )}
        </Col>
      </Row>
    </AppLayout>
  )
}
export default Inventory
