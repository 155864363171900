import { Dropdown } from 'antd'
import React, { useState } from 'react'
import EditReg from 'assets/Images/EditRegistration.svg'
import CheckIn from 'assets/Images/checkIn.svg'
import CheckOut from 'assets/Images/checkOut.svg'
import EditRegistration from '../EditRegistration'
import { EvenetAttendie } from 'pages/Events/types'
interface BtnInterface {
  id: string
  attendieDetails: EvenetAttendie
}
const ManageBtn = ({ id, attendieDetails }: BtnInterface) => {
  const [open, setOpen] = useState<number>(0)
  const ManageBtn = [
    {
      key: 'registration',
      label: (
        <div
          className="flex align-center"
          onClick={() => {
            setOpen(1)
          }}
        >
          <img src={EditReg} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Edit Registration</h4>
        </div>
      ),
    },
    {
      key: 'checkIns',
      label: (
        <div className="flex align-center">
          <img src={CheckIn} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">View check-ins</h4>
        </div>
      ),
    },
    {
      key: 'checkOut',
      label: (
        <div className="flex align-center">
          <img src={CheckOut} alt="contac-attende" />
          <h4 className="w-500 text-14 ml-10px">Check-out</h4>
        </div>
      ),
    },
  ]
  return (
    <>
      <Dropdown
        menu={{
          items: ManageBtn,
        }}
      >
        <button key={id} className="borderd-btn">
          Manage
        </button>
      </Dropdown>
      {open === 1 && (
        <EditRegistration
          open={open}
          setOpen={setOpen}
          attendie={attendieDetails}
        />
      )}
    </>
  )
}

export default ManageBtn
