// import { useEffect, useRef, useState } from 'react'
// import { Checkbox, Dropdown, InputNumber, Radio, Slider } from 'antd'
// import { ItemType } from 'antd/lib/menu/hooks/useItems'
// import Down from 'helper/Icons/DownIcon'
// import { getFilterList } from 'api/Inventory'
// import { useQuery } from '@tanstack/react-query'
// import Spinner from 'components/Spinner'
// import { CloseDropeDown } from 'helper/CloseDropdown'
// import { ArrowUp } from '@phosphor-icons/react'
// const InventoryFilter = ({ setParms, parms }: any) =>{
//   const [sortBy, setSortBy] = useState('')
//   const [orderBy, setOrderBy] = useState('desc')
//   const [open, setOpen] = useState(0)

//   const dropdownRef = useRef<HTMLDivElement | null>(null)
//   const handleDocumentClick = (e: any) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
//       setOpen(0)
//     }
//   }
//   const handleDropdownClick = (e: React.MouseEvent) => {
//     e.stopPropagation()
//   }
//   useEffect(() => {
//     document.addEventListener('click', handleDocumentClick)
//     return () => {
//       document.removeEventListener('click', handleDocumentClick)
//     }
//   }, [])

//     // const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })

// const {data,refetch} = useQuery(
//     ['getFilterList'],() => getFilterList(),{enabled:false}
// )
// useEffect(()=>{
//     refetch()
// },[])

// const SortByCheckBoxes = [
//   {
//     label: 'Updated date',
//     value: '-updatedAt',
//   },
//   {
//     label: 'Name',
//     value: 'name',
//   },
//   {
//     label: 'Quantity',
//     value: '-totalQuantity',
//   },
//   {
//     label: 'Min Level',
//     value: 'minLevel',
//   },
// ]

// const clear = (item: string, setFn?: any) => {
//   if (item in parms) {
//     const { [item]: _, ...updatedParms } = parms
//     setParms(updatedParms)
//   }
//   setFn && setFn('')
// }
// const checkboxOptions = data?.organizations?.map(item => ({
//     label: (
//         <div>
//             <h4 className="text-14 ml-20px">{item.name}</h4>
//         </div>
//     ),
//     value: item.id
// }))
// const checkboxLocation = data?.locations?.map(item => ({
//     label: (
//         <div>
//             <h4 className="text-14 ml-20px">{item.title}</h4>
//         </div>
//     ),
//     value: item.id
// }))

//       const Category: ItemType[] = [
//         data !== undefined
//           ? {
//               key: '0',
//               label: (
//                 <Checkbox.Group
//                   options={checkboxOptions}
//                   className="category-checkbox"
//                   // onChange={(checkedValues) => console.log('checkedValues', checkedValues)}
//                 />
//               ),
//             }
//           : {
//               key: '0',
//               label: (
//                 <div>
//                   <Spinner />
//                 </div>
//               ),
//             },
//       ]
//       const Locationitem: ItemType[] = [
//         data !== undefined
//           ? {
//               key: '0',
//               label: (
//                 <Checkbox.Group
//                   options={checkboxLocation}
//                   className="category-checkbox"
//                   // onChange={(checkedValues) => console.log('checkedValues', checkedValues)}
//                 />
//               ),
//             }
//           : {
//               key: '0',
//               label: (
//                 <div>
//                   <Spinner />
//                 </div>
//               ),
//             },
//       ]
//       const SortBy = [
//         {
//           key: 'sort',
//           label: (
//             <div>
//               <Radio.Group
//                 options={SortByCheckBoxes}
//                 className="dropdwn checkbox"
//                 value={sortBy}
//                 style={{
//                   display: 'flex',
//                   flexDirection: 'column',
//                 }}
//                 onChange={(val: any) => setSortBy(val.target.value)}
//               />
//               <div className=" border-top pb-10px">
//                 <div className="dropdwn">
//                   <h2 className="text-14 w-600 ">Sort Results</h2>
//                   <Radio.Group
//                     onChange={(val: any) => setOrderBy(val.target.value)}
//                     className="flex flex-col mt-10px"
//                     value={orderBy}
//                   >
//                     <Radio value={'asc'}>Ascending</Radio>
//                     <Radio value={'desc'}>Descending</Radio>
//                   </Radio.Group>
//                 </div>
//               </div>
//             </div>
//           ),
//         },
//         {
//           key: 'btns',
//           label: (
//             <div className="flex justify-between mx-15px">
//               <button
//                 className="clr-btn"
//                 onClick={() => {
//                   clear('orderBy', setSortBy)
//                 }}
//               >
//                 Clear
//               </button>
//               <button
//                 onClick={() => {
//                   setOpen(0)
//                   sortBy && orderBy
//                     ? setParms((prevParms: any) => ({
//                         ...prevParms,
//                         sortBy: sortBy,
//                         orderBy: orderBy,
//                         page: 1,
//                       }))
//                     : orderBy
//                     ? setParms((prevParms: any) => ({
//                         ...prevParms,
//                         orderBy: orderBy,
//                         page: 1,
//                       }))
//                     : setParms((prevParms: any) => ({
//                         ...prevParms,
//                         sortBy: sortBy,
//                         page: 1,
//                       }))
//                 }}
//                 className="aply-btn"
//               >
//                 Apply
//               </button>
//             </div>
//           ),
//         },
//       ]
//     return(
//         <div className="flex justify-between align-center  flex-wrap">
//         <div
//           className="flex align-center flex-wrap mt-20px"
//           ref={dropdownRef}
//           onClick={handleDropdownClick}
//         >
//           <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
//           <Dropdown
//             menu={{
//               items: Category,
//             }}
//             placement="bottomLeft"
//             arrow={false}
//             className="mr-10px "
//             open={open === 2}
//             overlayStyle={{ width: '250px' }}
//           >
//             <button
//               className={` ${
//                 open === 2 ? 'filter-btn-click ' : 'filter-btn'
//               } w-500`}
//               onClick={() => setOpen(2)}
//             >
//               Organization <Down />
//             </button>
//           </Dropdown>
//           <Dropdown
//             menu={{
//               items: Locationitem,
//             }}
//             placement="bottomLeft"
//             arrow={false}
//             className="mr-10px "
//             open={open === 3}
//             overlayStyle={{ width: '250px' }}
//              overlayClassName="custom-dropdown-overlay"
//           >
//             <button
//               className={` ${
//                 open === 3 ? 'filter-btn-click ' : 'filter-btn'
//               } w-500 `}
//               onClick={() => setOpen(3)}
//             >
//               Location <Down />
//             </button>
//           </Dropdown>

//           </div>
//           <div
//         className="flex align-center mt-25px"
//         ref={dropdownRef}
//         onClick={handleDropdownClick}
//       >
//         <h2 className="w-500 text-14">Sort by:</h2>
//         <Dropdown
//           menu={{
//             items: SortBy,
//           }}
//           placement="bottomRight"
//           arrow={false}
//           className="ml-20px mr-10px"
//           open={open === 5}
//           overlayStyle={{ width: '300px' }}
//         >
//           <button
//             className={` ${
//               open === 5 ? 'filter-btn-click ' : 'filter-btn'
//             } w-500 align-center`}
//             onClick={() => setOpen(5)}
//           >
//             <ArrowUp
//               size={18}
//               color={'#9ea1a3'}
//               style={{
//                 position: 'relative',
//                 top: '4px',
//               }}
//             />{' '}
//             Creation Date
//             <Down />
//           </button>
//         </Dropdown>
//       </div>
//           </div>
//     )
// }

// export default InventoryFilter

import { useEffect, useRef, useState } from 'react'
import { Checkbox, Dropdown, Radio, Input } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Down from 'helper/Icons/DownIcon'
import { getFilterList } from 'api/Inventory'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/Spinner'
import { ArrowUp } from '@phosphor-icons/react'

const InventoryFilter = ({ setParms, parms }) => {
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedLocation, setSelectedLocation] = useState([])
  const [sortBy, setSortBy] = useState('')
  const [orderBy, setOrderBy] = useState('desc')
  const [open, setOpen] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchloc, setSearchloc] = useState('')
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDocumentClick = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const handleDropdownClick = e => {
    e.stopPropagation()
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const { data, refetch } = useQuery(['getFilterList'], () => getFilterList(), {
    enabled: false,
  })
  useEffect(() => {
    refetch()
  }, [])

  const handleCategoryChange = selectedValues => {
    setSelectedCategories(selectedValues)
  }

  const getSortByCheckBoxes = () => [
    {
      label: 'Updated date',
      value: orderBy === 'asc' ? 'updatedAt' : '-updatedAt',
    },
    {
      label: 'Name',
      value: orderBy === 'asc' ? 'name' : '-name',
    },
    {
      label: 'Quantity',
      value: orderBy === 'asc' ? 'totalQuantity' : '-totalQuantity',
    },
    {
      label: 'Min Level',
      value: orderBy === 'asc' ? 'minLevel' : '-minLevel',
    },
  ]

  const clear = (item, setFn) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const checkboxOptions = data?.organizations?.map(item => ({
    label: (
      <div>
        <h4 className="text-14 ml-20px">{item.name}</h4>
      </div>
    ),
    value: item.id,
  }))

  const checkboxLocation = data?.locations?.map(item => ({
    label: (
      <div>
        <h4 className="text-14 ml-20px">{item.title}</h4>
      </div>
    ),
    value: item.id,
  }))

  const filteredCheckboxOptions = checkboxOptions?.filter(option =>
    option.label.props.children.props.children
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )

  const Category: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <Checkbox.Group
                options={filteredCheckboxOptions}
                className="category-checkbox"
                value={selectedCategories}
                onChange={handleCategoryChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('organizationIds', setSelectedCategories)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedCategories &&
                      setParms(prevParms => ({
                        ...prevParms,
                        organizationIds: selectedCategories,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]

  const filteredLocationOptions = checkboxLocation?.filter(option =>
    option.label.props.children.props.children
      .toLowerCase()
      .includes(searchloc.toLowerCase()),
  )
  const handleLocationChange = selectedValues => {
    setSelectedLocation(selectedValues)
  }

  const Locationitem: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Input
                placeholder="Search..."
                value={searchloc}
                onChange={e => setSearchloc(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <Checkbox.Group
                options={filteredLocationOptions}
                className="category-checkbox"
                value={selectedLocation}
                onChange={handleLocationChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('locationIds', setSelectedLocation)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedLocation &&
                      setParms(prevParms => ({
                        ...prevParms,
                        locationIds: selectedLocation,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]

  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={getSortByCheckBoxes()}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={val => setSortBy(val.target.value)}
          />
          <div className="border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600">Sort Results</h2>
              <Radio.Group
                onChange={val => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                value={orderBy}
              >
                <Radio value="asc">Ascending</Radio>
                <Radio value="desc">Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('orderBy', setSortBy)
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              sortBy && orderBy
                ? setParms(prevParms => ({
                    ...prevParms,
                    sort: sortBy,
                    page: 1,
                  }))
                : orderBy
                ? setParms(prevParms => ({
                    page: 1,
                  }))
                : setParms(prevParms => ({
                    sort: sortBy,
                    page: 1,
                  }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]

  return (
    <div className="flex justify-between align-center flex-wrap">
      <div
        className="flex align-center flex-wrap mt-20px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
        <Dropdown
          menu={{
            items: Category,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 2}
          overlayStyle={{ width: '250px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 2 ? 0 : 2)
            }}
          >
            Organization <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: Locationitem,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 3}
          overlayStyle={{ width: '250px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => setOpen(3)}
          >
            Location <Down />
          </button>
        </Dropdown>
      </div>
      <div
        className="flex align-center mt-25px"
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14">Sort by:</h2>
        <Dropdown
          menu={{
            items: SortBy,
          }}
          placement="bottomRight"
          arrow={false}
          className="ml-20px mr-10px"
          open={open === 5}
          overlayStyle={{ width: '300px' }}
        >
          <button
            className={` ${
              open === 5 ? 'filter-btn-click' : 'filter-btn'
            } w-500 align-center`}
            onClick={() => setOpen(5)}
          >
            <ArrowUp
              size={18}
              color={'#9ea1a3'}
              style={{
                position: 'relative',
                top: '4px',
              }}
            />{' '}
            Creation Date
            <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}

export default InventoryFilter
