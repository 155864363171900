import React, { useEffect, useState } from 'react'
import './Style/newsStyle.scss'

import { ArrowLeft, ArrowRight, XCircle } from '@phosphor-icons/react'
interface NewsImageType {
  images: any[]
}
const NewsImageContainer: React.FC<NewsImageType> = ({ images }) => {
  const maxImagesToShow = 5
  const imagesToRender = images.slice(0, maxImagesToShow)
  const [showAllImages, setShowAllImages] = useState(false)
  const [currentPreviewImage, setCurrentPreviewImage] = useState(null)
  const [imgIndex, setImgIndex] = useState(null)
  const [currentOpenedLength, setCurrentOpenedLength] = useState(0)

  const showImagePreview = (index, length) => {
    setImgIndex(index)
    setCurrentOpenedLength(length)
  }

  console.log(images)
  const hideImagePreview = () => {
    setCurrentPreviewImage(null)
    setImgIndex(null)
  }

  const handleShowAllImages = () => {
    setShowAllImages(true)
  }

  useEffect(() => {
    setCurrentPreviewImage(imgIndex)
  }, [imgIndex])

  return (
    <>
      {images.length >= 5 ? (
        <>
          <div className="img_cont">
            <div className="main_image_contianer five_column_upper_container">
              {/* Render first two images */}
              {imagesToRender.slice(0, 2).map((img, index) => (
                <div key={index} className="img_container">
                  <img
                    src={img?.post}
                    alt="event image"
                    width={1000}
                    height={1000}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    onClick={() => showImagePreview(index, images.length)}
                  />
                </div>
              ))}
            </div>
            <div className="main_image_contianer five_column_lower_container">
              {imagesToRender.slice(2, 5).map((img, index) => (
                <div key={index + 2} className="img_container">
                  <img
                    src={img?.post}
                    alt="event image"
                    width={1000}
                    height={1000}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    onClick={() => showImagePreview(index + 2, images.length)}
                  />
                </div>
              ))}
            </div>

            {/* Render "+X" indicator */}
            {!showAllImages && (
              <div
                className="additional_image_indicator_unauth"
                onClick={handleShowAllImages}
              >
                +{images.length - 5}
              </div>
            )}
          </div>

          {/* show all the images   */}
          <div className="main_image_contianer more_than_five_grid">
            {/* Render remaining images when clicked */}
            {showAllImages &&
              images.slice(5).map((img, index) => (
                <div key={index + 5} className="img_container">
                  <img
                    src={img?.post}
                    alt="event image"
                    width={1000}
                    height={1000}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                    onClick={() => showImagePreview(index + 5, images.length)}
                  />
                </div>
              ))}
          </div>
        </>
      ) : (
        <div
          className={`main_image_contianer ${
            images.length === 2
              ? 'two_column_grid'
              : images.length === 3
              ? 'three_column_grid'
              : images.length === 4
              ? 'four_column_grid'
              : ''
          }`}
        >
          {/* Render all images */}
          {imagesToRender.map((img, index) => (
            <div key={index} className="img_container">
              <img
                src={img?.post}
                alt="event image"
                width={1000}
                height={1000}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                }}
                onClick={() => showImagePreview(index, images.length)}
              />
            </div>
          ))}
        </div>
      )}
      {currentPreviewImage !== null && (
        <div className="image_preview_overlay">
          <div className="image_preview_container">
            <img
              src={images[currentPreviewImage]?.post}
              width={1200}
              height={1200}
              alt="img"
              style={{ width: '100%', height: '100%' }}
            />

            <div className="cross_icon">
              <XCircle size={32} color="#000" onClick={hideImagePreview} />
            </div>
          </div>

          {currentOpenedLength > 1 && (
            <>
              <div className="arrows arrow_left">
                <ArrowLeft
                  size={32}
                  color="#000"
                  onClick={() =>
                    setImgIndex(prev =>
                      prev === 0 ? currentOpenedLength - 1 : prev - 1,
                    )
                  }
                />
              </div>
              <div className="arrows arrow_right">
                <ArrowRight
                  size={32}
                  color="#000"
                  onClick={() =>
                    setImgIndex(prev =>
                      prev === currentOpenedLength - 1 ? 0 : prev + 1,
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
export default NewsImageContainer
