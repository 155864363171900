import { Row, Switch } from "antd";
import ApplePay from "../../../assets/Images/ApplePay.svg";
import GooglePay from "../../../assets/Images/GooglePay.svg";
import PePal from "../../../assets/Images/PePal.svg";
import Visas from "../../../assets/Images/Visas.svg";
import Venmo from "../../../assets/Images/Venmo.svg";
const onChangetoggle = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};
const PaymentMethod = ({ match }: any) => {
  return (
    <div>
      <div>
        <h1 className="mb-20">Payment method</h1>
        <span style={{ color: "#9CA5B0", paddingBottom: "34px" }}>
          Choose payment methods that is acceped
        </span>
      </div>
      <div className="parent-block-notify">
        <Row className="inner-toggle-swich">
          <p>
            <img alt="" src={ApplePay} /> ApplePay
          </p>
          <div className="payment-switch-div">
            <div className="switch-btn">
              <Switch
                defaultChecked
                onChange={onChangetoggle}
                className="small-custom-switch-btn"
              />
            </div>
          </div>
        </Row>
        <Row className="inner-toggle-swich">
          <p>
            <img alt="" src={GooglePay} /> GooglePay
          </p>
          <div className="payment-switch-div">
            <div className="switch-btn">
              <Switch
                defaultChecked
                onChange={onChangetoggle}
                className="small-custom-switch-btn"
              />
            </div>
          </div>
        </Row>
        <Row className="inner-toggle-swich">
          <p>
            <img alt="" src={PePal} /> PayPal
          </p>
          <div className="payment-switch-div">
            <div className="switch-btn">
              <Switch
                defaultChecked
                onChange={onChangetoggle}
                className="small-custom-switch-btn"
              />
            </div>
          </div>
        </Row>
        <Row className="inner-toggle-swich">
          <p>
            <img alt="" src={Visas} /> Visa/Mastercard
          </p>
          <div className="payment-switch-div">
            <div className="switch-btn">
              <Switch
                onChange={onChangetoggle}
                className="small-custom-switch-btn"
              />
            </div>
          </div>
        </Row>
        <Row className="inner-toggle-swich">
          <p>
            <img alt="" src={Venmo} /> Venmo
          </p>
          <div className="payment-switch-div">
            <div className="switch-btn">
              <Switch
                onChange={onChangetoggle}
                className="small-custom-switch-btn"
              />
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};
export default PaymentMethod;
