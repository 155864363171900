import { RequestParms, CreateMentor, importMentor } from '../types/globalTypes'
import { axiosGet } from '../Redux/axiosHelper'
import { BASE_URL, failureMessage, successMessage } from './global'
import axios from 'axios'
export const getMentors = async (parms: RequestParms) => {
  const response = await axiosGet(`v1/admins/mentors`, parms)
  return response.data
}
export const changeMentorStatus = async (params: string, payload: string) => {
  const response = await axios.patch(`${BASE_URL}v1/mentors/${params}/status`, {
    status: payload,
  })
  if (response) {
    successMessage('Status changed Successfully !')
  } else {
    failureMessage('Error changing Status')
  }
}
export const createNewMentor = async (payload: CreateMentor) => {
  const response = await axios.post(
    `${BASE_URL}v1/admins/create-mentor`,
    payload,
  )
  response.status === 200
    ? successMessage('New Mentor created Successfully !')
    : failureMessage(`Unable to create Mentor${response.statusText}`)
}
export const updateMentor = async (id: string, payload: CreateMentor) => {
  const response = await axios.post(
    `${BASE_URL}v1/admins/update-mentor/${id}`,
    payload,
  )

  response.status === 200
    ? successMessage('Mentor updated Successfully !')
    : failureMessage(`Unable to update Mentor+${response.statusText}`)
}
export const importMentorFile = async (payload: importMentor) => {
  const response = await axios.post(`${BASE_URL}v1/imports/mentor`, payload)
  return response.data
}
// export const confirmImportData = async (payload: any) => {
//   const response = await axios.put(`${BASE_URL}v1/imports/mentor`, payload)
//   return response.data
//   response.status !== 200 {
//     failureMessage(response?.data?.message)
//   }
// }
export const confirmImportData = async payload => {
  try {
    const response = await axios.put(`${BASE_URL}v1/imports/mentor`, payload)
    if (response.status === 200) {
      return response.data
    } else {
      failureMessage(response?.data?.message || 'An error occurred')
      return Promise.reject(response.data)
      // return response.data
    }
  } catch (error) {
    if (error.response) {
      failureMessage(error.response.data.message || 'An error occurred')
    } else {
      failureMessage('An error occurred')
    }
    // return Promise.reject(error)
  }
}
// export const confirmImportData = async (payload: any) => {
//   try {
//     const response = await axios.put(`${BASE_URL}v1/imports/mentor`, payload)

//     if (response.status === 200) {
//       message.success('Data imported successfully')
//       return response.data
//     } else {
//       message.error(`Error: ${response.data.message}`)
//       throw new Error(response.data.message)
//     }
//   } catch (error) {
//     if (error.response) {
//       // Server responded with a status other than 200 range
//       message.error(`Error: ${error.response.data.message}`)
//     } else if (error.request) {
//       // No response received from server
//       message.error('Error: No response received from server')
//     } else {
//       // Something else caused the error
//       message.error(`Error: ${error.message}`)
//     }
//     throw error
//   }
// }
export const getSkillsList = async () => {
  const response = await axiosGet(`v1/lists/skills`)
  return response.data
}
export const deleteMentors = async (id: string) => {
  const response = await axios.delete(`${BASE_URL}v1/admins/mentors/${id}`)
  if (response) {
    successMessage('Mentor Deleted Successfully !')
  } else {
    failureMessage('Unable to delete Mentor')
  }
}
export const getMentorProfile = async (mentorId: string) => {
  const response = await axiosGet(`v1/mentors/${mentorId}`)
  return response.data
}
export const getRequestDetails = async (requestId: string) => {
  const response = await axiosGet(`v1/mentor-request/${requestId}`)
  return response.data
}
export const getMentorSessionStats = async (
  requestId: string,
  mentorId: string,
  today: string | Date,
) => {
  const response = await axiosGet(
    `v1/mentor-request/get-session-stats/${requestId}`,
    { mentorId, today },
  )
  return response.data
}
