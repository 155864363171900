import React from "react";
import { Modal } from "antd";
import { Layout } from "antd";
import RewardsForm from "./Rewards/RewardsForm";

const RewardsModal = ({ Visible, isModalVisible, setModalVisible }: any) => {
  const handleOk = () => {
    Visible(false);
    setModalVisible(false);
  };
  return (
    <Layout>
      <Modal
        visible={isModalVisible}
        className="organization_model"
        onOk={handleOk}
        onCancel={() => {
          setModalVisible(false);
        }}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4>Create a reward </h4>
        </div>
        <RewardsForm />
      </Modal>
    </Layout>
  );
};
export default RewardsModal;
