import { Checkbox, Col, Row, Select } from 'antd'
import { Menu, Dropdown, Space } from 'antd'
import filterSvg from '../../../assets/Images/filter.svg'
import { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import ActivityFilter from './ActivityFilter'
const ActionHeader = ({ hidCols, setCols, search, parms, setParms }: any) => {
  const [visible, setVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hidCols,
      [e.target.name]: e.target.checked,
    }

    setCols(updatedHiddenColumns)
    localStorage.setItem('InventoryCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('ActivityCols')
    if (storedHiddenColumns) {
      setCols(JSON.parse(storedHiddenColumns))
    }
  }, [])
  const menu2 = (
    <Menu
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.name}
                  name="name"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Name</Col>
            </Row>
          ),
          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.category}
                  name="category"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Category</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.organization}
                  name="organization"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Organization</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hidCols.name}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: '4',
        },
      ]}
    />
  )
  const handleSearchChange = (e: any) => {
    setSearchQuery(e)
    search(e)
  }
  const debounced = debounce(handleSearchChange, 300)
  return (
    <div>
      <Space size={20} align="center" className="mentors-table-header">
        <Dropdown
          overlay={menu2}
          onOpenChange={handleVisibleChange}
          open={visible}
        >
          <h5 onClick={e => e.preventDefault()}>
            <Space>
              <img src={filterSvg} alt=""></img>
            </Space>
          </h5>
        </Dropdown>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <ActivityFilter setParms={setParms} parms={parms} />
      </Space>
    </div>
  )
}
export default ActionHeader
