import { axiosGet, axiosPost } from '../Redux/axiosHelper'
import { NotesPayloadTypes } from 'types/notes'

export const getNotes = async (params: any) => {
  const response = await axiosGet(`v1/notes/get-notes`, params)
  return response.data
}

export const addNotes = async (adminId: string, payload: NotesPayloadTypes) => {
  const response = await axiosPost(
    payload,
    `v1/notes/add-note/by-admin/${adminId}`,
  )
  return response.data
}
