import { Card, Col, Row, Space } from "antd";
import React from "react";
import SessionTable from "../../../Sessions/Table/SessionTable";
import MentorSessionTable from "./MentorSessionTable";
const Sessions = () => {
  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        <Row gutter={[10, 20]}>
          <Col>
            <Card className="bg-grey br-12 w-100 px-40">
              <Row gutter={20}>
                <Col span={5}>
                  <Space
                    direction="vertical"
                    size={10}
                    className="session-dash"
                  >
                    <h3 className="text-secondary">On-Time Rate</h3>
                    <h4 className="b1">--%</h4>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space
                    direction="vertical"
                    size={10}
                    className="session-dash"
                  >
                    <h3 className="text-secondary">Avg. Hr/Wk</h3>
                    <h4 className="b1">1:00 hrs</h4>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space
                    direction="vertical"
                    size={10}
                    className="session-dash"
                  >
                    <h3 className="text-secondary">Avg. Session </h3>
                    <h4 className="b1">5:00 hrs</h4>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space
                    direction="vertical"
                    size={10}
                    className="session-dash"
                  >
                    <h3 className="text-secondary">Session Worked</h3>
                    <h4 className="b1">20</h4>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space
                    direction="vertical"
                    size={10}
                    className="session-dash"
                  >
                    <h3 className="text-secondary">Cancelled Sessions</h3>
                    <h4 className="b1">0</h4>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col></Col>
        </Row>
        <MentorSessionTable />
      </div>
    </div>
  );
};

export default Sessions;
