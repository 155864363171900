import { useQuery } from '@tanstack/react-query'
import { axiosGet } from 'Redux/axiosHelper'
import { PaginatedParams } from 'types/globalTypes'

const getMedia = async (kidId: string, params: PaginatedParams) => {
  const response = await axiosGet(`v1/kids/${kidId}/media`, params)
  return response.data
}

export const useGetMedia = (kidId: string, params: PaginatedParams) => {
  const media = useQuery<any>(
    ['kid-media-listing'],
    () => getMedia(kidId, params),
    { enabled: false },
  )
  return media
}
