import {
  axiosDeleteWithBody,
  axiosGet,
  axiosPatch,
  axiosPost,
} from 'Redux/axiosHelper'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AdmissionTicketPayloadTypes,
  DiscountCreateProps,
  DiscountResp,
  DropinTicketPayload,
  TicketColors,
  UpdateTicketStatusPayload,
} from 'types/eventStep4'

// get ticket colors
export const getTicketColor = async () => {
  const response = await axiosGet(`v1/lists/ticket-colors`)
  return response.data
}

export const useGetTicketColors = () => {
  const ticketColors = useQuery<TicketColors[]>(
    ['ticket-colors'],
    getTicketColor,
    {
      enabled: false,
    },
  )
  return ticketColors
}

// patch request create admission ticket
export const createAdmissionTicket = async ({
  payload,
  eventId,
  ticketId,
}: {
  payload: AdmissionTicketPayloadTypes
  eventId: string
  ticketId?: string
}) => {
  const response = await axiosPatch(
    payload,
    ticketId
      ? `v1/organization-events/${eventId}/event-step4-admission-ticket?ticketId=${ticketId}`
      : `v1/organization-events/${eventId}/event-step4-admission-ticket`,
  )
  return response.data
}

export const useCreateAdmissionTicket = () => {
  return useMutation(createAdmissionTicket)
}

// patch request sort admision ticket
const sortAdmissionTicket = async ({
  eventId,
  payload,
}: {
  eventId: string
  payload: { orders: { ticketId: string; order: number }[] }
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/sort-admission-tickets`,
  )
  return response.data
}

export const useSortAdmissionTicket = () => {
  return useMutation(sortAdmissionTicket)
}

// patch request create additional ticket
export const createAdditionalTicket = async ({
  payload,
  eventId,
  ticketId,
}: {
  payload: AdmissionTicketPayloadTypes
  eventId: string
  ticketId?: string
}) => {
  const response = await axiosPatch(
    payload,
    ticketId
      ? `v1/organization-events/${eventId}/event-step4-additional-option?ticketId=${ticketId}`
      : `v1/organization-events/${eventId}/event-step4-additional-option`,
  )
  return response.data
}

export const useCreateAdditionalTicket = () => {
  return useMutation(createAdditionalTicket, {
    onSuccess: additionalTicket => {},
  })
}

// patch request sort admision ticket
const sortAdditionalTicket = async ({
  eventId,
  payload,
}: {
  eventId: string
  payload: { orders: { optionId: string; order: number }[] }
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/sort-additional-options`,
  )
  return response.data
}

export const useSortAdditionalTicket = () => {
  return useMutation(sortAdditionalTicket)
}

// patch reqeust create dropin ticket
export const createDropinTicket = async ({
  payload,
  eventId,
  ticketId,
}: {
  payload: DropinTicketPayload
  eventId: string
  ticketId?: string
}) => {
  const response = await axiosPatch(
    payload,
    ticketId
      ? `v1/organization-events/${eventId}/event-step4-dropin-ticket?ticketId=${ticketId}`
      : `v1/organization-events/${eventId}/event-step4-dropin-ticket`,
  )
  return response.data
}

export const useCreateDropinTicket = () => {
  return useMutation(createDropinTicket, {
    onSuccess: dropinTicket => {},
  })
}

//patch request delete all type ticket
const deleteTicketUsingType = async ({
  eventId,
  ticketId,
  type,
}: {
  eventId: string
  ticketId: string
  type: 'admission' | 'additional' | 'dropin' | 'discount'
}) => {
  const response = await axiosPatch(
    {},
    `${
      type === 'admission'
        ? `v1/organization-events/${eventId}/delete-admission-ticket/${ticketId}`
        : type === 'additional'
        ? `v1/organization-events/${eventId}/delete-additional-ticket/${ticketId}`
        : `v1/organization-events/${eventId}/delete-dropin-ticket`
    }`,
  )
  return response.data
}

export const useDeleteTicketUsingType = () => {
  return useMutation(deleteTicketUsingType)
}

// post request duplicated ticket
const duplicateTicketUsingType = async ({
  eventId,
  ticketId,
  ticketType,
}: {
  eventId: string
  ticketId: string
  ticketType: 'admission' | 'additional'
}) => {
  const response = await axiosPost(
    {},
    `${
      ticketType === 'admission'
        ? `v1/organization-events/${eventId}/admission-tickets/${ticketId}/duplicate`
        : `v1/organization-events/${eventId}/additional-options/${ticketId}/duplicate`
    }`,
  )
  return response.data
}

export const useDuplicateTicketUsingType = () => {
  return useMutation(duplicateTicketUsingType)
}

//patch request activate or disable ticket
const changeTicketStatus = async ({
  eventId,
  payload,
}: {
  eventId: string
  payload: UpdateTicketStatusPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step4-ticket-status`,
    `Ticket successfully ${payload.status}`,
  )
  return response.data
}

export const useChangeTicketStatus = () => {
  return useMutation(changeTicketStatus)
}

// post request create discount code
export const createDiscountCode = async ({
  payload,
  eventId,
}: {
  payload: DiscountCreateProps
  eventId: string
}) => {
  const response = await axiosPost(payload, `v1/discount/${eventId}`)
  return response.data
}

export const useCreateDiscountCode = () => {
  const queryClient = useQueryClient()
  return useMutation(createDiscountCode, {
    onSuccess: discount => {
      queryClient.setQueryData(
        ['event-discount-code'],
        (oldData: DiscountResp[] | undefined) => {
          return oldData ? [...oldData, discount] : [discount]
        },
      )
    },
  })
}

// patch request update discount code
export const updateDiscountCode = async ({
  payload,
  discountCodeId,
}: {
  payload: DiscountCreateProps
  discountCodeId: string
}) => {
  const response = await axiosPatch(payload, `v1/discount/${discountCodeId}`)
  return response.data
}

export const useUpdateDiscountCode = () => {
  const queryClient = useQueryClient()
  return useMutation(updateDiscountCode, {
    onSuccess: discount => {
      queryClient.setQueryData(
        ['event-discount-code'],
        (oldData: DiscountResp[] | undefined) => {
          if (!oldData) return [discount]
          return oldData.map(data =>
            data._id === discount._id ? discount : data,
          )
        },
      )
    },
  })
}

// patch request update discount code
export const updateDiscountStatus = async ({
  payload,
  discountCodeId,
}: {
  payload: { status: string }
  discountCodeId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/discount/status/${discountCodeId}`,
  )
  return response.data
}

export const useUpdateDiscountStatus = () => {
  const queryClient = useQueryClient()
  return useMutation(updateDiscountStatus, {
    onSuccess: discount => {
      queryClient.setQueryData(
        ['event-discount-code'],
        (oldData: DiscountResp[] | undefined) => {
          if (!oldData) return [discount]
          return oldData.map(data =>
            data._id === discount._id ? discount : data,
          )
        },
      )
    },
  })
}

// delete request remove discount code
const removeDiscountCode = async ({
  discountCodeId,
}: {
  discountCodeId: string
}) => {
  const response = await axiosDeleteWithBody(
    {
      status: 'archived',
    },
    `v1/discount/${discountCodeId}`,
  )
  return response.data
}

export const useRemoveDiscountCode = () => {
  const queryClient = useQueryClient()
  return useMutation(removeDiscountCode, {
    onSuccess: (deletedDiscount: DiscountResp) => {
      queryClient.setQueryData(
        ['event-discount-code'],
        (oldData: DiscountResp[] | undefined) => {
          if (!oldData) return []

          return oldData.filter(data => data._id !== deletedDiscount._id)
        },
      )
    },
  })
}

// get discount code by id
const getDiscountDetailsById = async (discountCodeId: string) => {
  const response = await axiosGet(`v1/discount/${discountCodeId}`)
  return response.data
}
export const useGetDiscountCodeById = (discountCodeId: string) => {
  const discountById = useQuery<DiscountResp>(
    ['discount-by-id'],
    () => getDiscountDetailsById(discountCodeId),
    { enabled: false },
  )
  return discountById
}

// get all discount codes for the event
export const getDiscountCode = async (eventId: string) => {
  const response = await axiosGet(`v1/discount/event/${eventId}`)
  return response.data
}

export const useGetDiscountCode = (eventId: string) => {
  const discountCodes = useQuery<DiscountResp[]>(
    ['event-discount-code'],
    () => getDiscountCode(eventId),
    { enabled: false },
  )

  return discountCodes
}
