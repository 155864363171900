import { useQuery } from '@tanstack/react-query'
import { Checkbox, Dropdown, Input } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Down from 'helper/Icons/DownIcon'
import React, { useState, useRef, useEffect } from 'react'
import { getFilterList } from 'api/Inventory'
import Spinner from 'components/Spinner'
import Calendar from 'react-calendar'
import moment from 'moment'
import Loader from 'helper/Loader'
export const NewsFilter = ({ setParms, parms }: any) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [selectedOrganization, setSelectedOrganization] = useState([])
  const [open, setOpen] = useState(0)
  const [selectedDates, setSelectedDates] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedType, setSelectedType] = useState([])
  const TypeWiseFilters = [
    {
      label: 'Audio',
      value: 'audioIds',
    },
    {
      label: 'Documents',
      value: 'documentIds',
    },
    {
      label: 'Locations',
      value: 'address',
    },
    {
      label: 'Images',
      value: 'imageIds',
    },
    {
      label: 'Regular post',
      value: 'regularPost',
    },
    {
      label: 'Video',
      value: 'videoIds',
    },
  ]
  const clear = (item, setFn) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }
  const { data, refetch } = useQuery(['getFilterList'], () => getFilterList(), {
    enabled: false,
  })
  useEffect(() => {
    refetch()
  }, [])

  const handleTypeChange = selectedValues => {
    setSelectedType(selectedValues)
  }
  const checkboxOptions = data?.organizations?.map(item => ({
    label: (
      <div>
        <h4 className="text-14 ml-20px">{item.name}</h4>
      </div>
    ),
    value: item.id,
  }))
  const typeFilters: ItemType[] = [
    {
      key: 'checkboxes',
      label: (
        <>
          <Checkbox.Group
            options={TypeWiseFilters}
            className="category-checkbox"
            onChange={handleTypeChange}
            value={selectedType}
          />
          <div className="flex justify-between mx-15px mt-10px">
            <button
              className="clr-btn"
              onClick={() => {
                clear('types', setSelectedType)
              }}
            >
              Clear
            </button>
            <button
              onClick={() => {
                setOpen(0)
                selectedType &&
                  setParms(prevParms => ({
                    ...prevParms,
                    types: selectedType,
                  }))
              }}
              className="aply-btn"
            >
              Apply
            </button>
          </div>
        </>
      ),
    },
  ]
  const handleDocumentClick = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const handleDropdownClick = e => {
    e.stopPropagation()
  }
  const filteredCheckboxOptions = checkboxOptions?.filter(option =>
    option.label.props.children.props.children
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  )
  const handleOrganizationChange = selectedValues => {
    setSelectedOrganization(selectedValues)
  }

  const OrganizationType: ItemType[] = [
    data !== undefined
      ? {
          key: '0',
          label: (
            <div>
              <Input
                placeholder="Search..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ marginBottom: '10px' }}
              />
              <Checkbox.Group
                options={filteredCheckboxOptions}
                className="category-checkbox"
                value={selectedOrganization}
                onChange={handleOrganizationChange}
              />
              <div className="flex justify-between mx-15px mt-10px">
                <button
                  className="clr-btn"
                  onClick={() => {
                    clear('organizationIds', setSelectedOrganization)
                  }}
                >
                  Clear
                </button>
                <button
                  onClick={() => {
                    setOpen(0)
                    selectedOrganization &&
                      setParms(prevParms => ({
                        ...prevParms,
                        organizationIds: selectedOrganization,
                        page: 1,
                      }))
                  }}
                  className="aply-btn"
                >
                  Apply
                </button>
              </div>
            </div>
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Loader />
            </div>
          ),
        },
  ]

  const handleTileClick = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    if (!selectedDates.includes(formattedDate)) {
      setSelectedDates([...selectedDates, formattedDate])
    } else {
      setSelectedDates(selectedDates.filter(d => d !== formattedDate))
    }
  }
  const clearDates = () => {
    setSelectedDates([])
    setParms(prevParms => {
      const { dates, ...restParms } = prevParms
      return { ...restParms }
    })
  }
  const applyDates = () => {
    setOpen(0)
    setParms(prevParms => ({
      ...prevParms,
      dates: selectedDates.map(i => moment(i).utc().format()),
    }))
  }

  const dateFilters = [
    {
      key: 'datefilter',
      label: (
        <div>
          <Calendar
            calendarType="US"
            onClickDay={handleTileClick}
            tileClassName={({ date }) => {
              const formattedDate = moment(date).format('YYYY-MM-DD')
              return selectedDates.includes(formattedDate) ? 'selected' : ''
            }}
          />
          <div className="flex justify-between mx-15px mt-10px">
            <button className="clr-btn" onClick={clearDates}>
              Clear
            </button>
            <button className="aply-btn" onClick={applyDates}>
              Apply
            </button>
          </div>
        </div>
      ),
    },
  ]

  return (
    <div className="flex justify-between align-center flex-wrap">
      <div
        className="flex align-center flex-wrap "
        ref={dropdownRef}
        onClick={handleDropdownClick}
      >
        <h2 className="w-500 text-14 mr-20px">Filter by:</h2>
        <Dropdown
          menu={{
            items: typeFilters,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 2}
          overlayStyle={{ width: '280px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 2 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 2 ? 0 : 2)
            }}
          >
            Type <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: OrganizationType,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 3}
          overlayStyle={{ width: '280px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 3 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 3 ? 0 : 3)
            }}
          >
            Organization <Down />
          </button>
        </Dropdown>
        <Dropdown
          menu={{
            items: dateFilters,
          }}
          placement="bottomLeft"
          arrow={false}
          className="mr-10px"
          open={open === 4}
          overlayStyle={{ width: '350px' }}
          overlayClassName="custom-dropdown-overlay"
        >
          <button
            className={` ${
              open === 4 ? 'filter-btn-click' : 'filter-btn'
            } w-500`}
            onClick={() => {
              setOpen(open === 4 ? 0 : 4)
            }}
          >
            Date <Down />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}
export default NewsFilter
