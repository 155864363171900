import { useMutation } from "@tanstack/react-query";
import { Input, notification, Form } from "antd";
import { useState } from "react";
import { updateFamilyMemberPass } from "../../../../../api/family";
interface FormData {
  [key: string]: string;
}
const ChangePassword = ({ data, back, close }: any) => {
  const changePass = useMutation({
    mutationFn: ({ id, pass }: any) => {
      return updateFamilyMemberPass(id, pass);
    },
  });
  const [formData, setFormData] = useState<FormData>({
    newPass: "",
    confirmPass: "",
  });
  const openNotification = () => {
    notification.error({
      message: "Password don't match",
      description: "Please double check your password",
    });
  };
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onFinish = (event: any) => {
    event.preventDefault();
    const mutationOptions = {
      id: data.firebaseId,
      pass: formData.newPass,
    };
    if (formData.newPass !== "" && formData.newPass === formData.confirmPass) {
      changePass.mutate(mutationOptions);
      close(false);
    } else openNotification();
  };
  return (
    <div>
      <form name="changePassword" onSubmit={onFinish}>
        <div className="inputGroup">
          <label className="inp-label">New password</label>
          <Form.Item
            name="newPass"
            className="my-input-box"
            rules={[
              {
                required: true,
                message: "Please enter your new password",
                len: 8,
              },
            ]}
          >
            <Input.Password
              name="newPass"
              placeholder="Enter your new password"
              onChange={handleInputChange}
              value={formData.newPass}
            />
          </Form.Item>
        </div>
        <div className="inputGroup">
          <label className="inp-label">Confirm password</label>
          <Form.Item
            name="confirmPass"
            className="my-input-box"
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
                len: 8,
              },
            ]}
          >
            <Input.Password
              name="confirmPass"
              placeholder="Please confirm your password"
              onChange={handleInputChange}
              value={formData.confirmPass}
            />
          </Form.Item>
        </div>
        <div className="popup-footer">
          <button
            className="btn-sec pointer"
            onClick={() => {
              back(0);
            }}
          >
            Back
          </button>
          <button className="btn-prim pointer" type="submit" value="Submit">
            Change password
          </button>
        </div>
      </form>
    </div>
  );
};
export default ChangePassword;
