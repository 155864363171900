import React, { useState, useMemo } from 'react'
import { FC } from 'react'
import { Account } from '..'
import GetAvatar from 'components/global/GetAvatar'
import { getUserName } from 'helper/utility'
import { PlusOutlined } from '@ant-design/icons'
import {
  Checkbox,
  Form,
  Input,
  Select,
  Avatar,
  Row,
  Col,
  Upload,
  UploadFile,
  UploadProps,
  Image,
} from 'antd'
import uploadactions from '../../../../../../../Redux/FileUpload/action'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Cost } from 'pages/Events/types'
import Spinner from 'components/Spinner'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { dropOutParticipant } from 'api/Events'
import Coverimage from '../../../../../../../assets/Images/Coverimage.svg'
import { failureMessage, successMessage } from 'api/global'
import { myStore } from 'state/eventStore'
import Loader from 'helper/Loader'
import { useDispatch, connect } from 'react-redux'
import fileActions from '../../../../../../../Redux/FileUpload/action'
import actions from 'Redux/Session/action'
import BigPlusIcon from '../../../../../../../assets/Images/BigPlusIcon.svg'
import type { RcFile } from 'antd/es/upload/interface'
import PhoneField from 'helper/PhoneField'
import userAvatarSvg from '../../../../../../../assets/Images/userAvatar.svg'
import FileUploader from '../../../../../../../helper/FileUploader'
import type {} from 'antd'
import ImgCrop from 'antd-img-crop'
import './style/uploadFile.scss'
interface FamilyCheckOutI {
  name?: any
  fileData?: any
  setText?: any
  family?: Account[]
  cost?: Cost
  id?: string
  close?: () => void
}

const FamilyCheckOut: FC<FamilyCheckOutI> = ({
  fileData,
  name,
  setText,
  family,
  cost,
  id,
  close,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])
  // const onPreview = async (file: UploadFile) => {
  //   let src = file.url as string
  //   if (!src) {
  //     src = await new Promise(resolve => {
  //       const reader = new FileReader()
  //       // Use the global File type directly
  //       reader.readAsDataURL(file.originFileObj as File)
  //       reader.onload = () => resolve(reader.result as string)
  //     })
  //   }
  //   const image = new Image()
  //   image.src = src
  //   const imgWindow = window.open(src)
  //   imgWindow?.document.write(image.outerHTML)
  // }
  const [galleryUrl, setGalleryUrl] = useState<any[]>([]) // Change to array
  const [uploadedFile, setUploadedFile] = useState<RcFile | null>(null)
  const form = Form.useForm()[0]
  const dispatch = useDispatch()
  const onChangefile: UploadProps['onChange'] = (fileList: any) => {
    const uploadButton = (
      <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
    )
    if (fileList.fileList.length === 0) {
      setFileList([])
    } else if (fileList.fileList.length > 0) {
      if (fileList.file.status === 'uploading') {
        fileList.file.status = 'done'
      }
      if (fileList.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', fileList.file.originFileObj)
        setFileList(fileList.fileList)
        const newFile = {
          title: name, // Assuming 'name' is defined somewhere in your scope
          description: 'img',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: uploadactions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    }
  }

  const onChange = (e: CheckboxChangeEvent) => {
    e.target.checked && setSelectedValue(e.target.name)
  }
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    'guardian',
  )
  const setTag = myStore(store => store.setTag)
  const location = useLocation()
  const event: any = location.state
  const memberList: {
    key: number
    label: string
    value: string
    type: string
  }[] = useMemo(() => {
    const filteredData =
      family?.length > 0
        ? family
            .filter((data: any) => {
              if (selectedValue === 'guardian') {
                return data.type === 'guardian'
              } else if (selectedValue === 'adults') {
                return data.type === 'authorized-adult'
              } else {
                // Handle other cases here, currently assuming 'someone'
                // If 'someone' is selected, return all family members
                return true
              }
            })
            .map((data: any, index: number) => ({
              key: index,
              label: data.firstName,
              value: data._id,
              type: data.type,
            }))
        : [
            {
              key: 0,
              label: 'No data found',
              value: 'no data',
              type: 'no data',
            },
          ]
    return filteredData
  }, [family, selectedValue])

  const propsForPhoto: UploadProps = {
    showUploadList: false,
    className: 'mt-100',
    beforeUpload: file => {
      var url = URL.createObjectURL(file)
      let type = file.type.split(/[/]/)
      if (type[0] === 'image' && galleryUrl.length < 2) {
        setUploadedFile(file)
        setGalleryUrl(prevState => [...prevState, { url: url, type: type[0] }])
      }
      return false
    },
  }

  // const uploadButton = (
  //   <div>
  //     <>
  //       <div className="upload-acover-pic">
  //         <img src={Coverimage} alt="avatar" />
  //         Upload a cover picture
  //       </div>
  //     </>
  //   </div>
  // )

  // previous ID
  // // checkOutOrganizerId: event.organizerIds[0],

  const matched =
    family[family?.length - 1]?.type !== 'authorized-adult'
      ? family[family?.length - 1]?.type + 'Id'
      : 'authorizedAdultId'

  const onFinish = (values: any) => {
    const body = {
      ...values,
      photoId: fileData?._id || undefined,
      checkOutAdminId: localStorage.getItem('adminId'),
      eventId: event._id,
      familyId: family[0].familyId,
      checkOutType: 'direct',
      checkInId: id,
      isDropInAttendee: true,
      [matched]: family[family.length - 1]._id,
      uploadedFile: form.getFieldValue('uploadedFile'),
    }
    mutation.mutate({
      body,
    })
  }

  const mutation = useMutation({
    mutationFn: async ({ body }: any) => {
      return await dropOutParticipant(body)
    },
    onSuccess: () => {
      setText(false)
      dispatch({ type: actions.RESET_CHECKIN })
      close()
      setTag(true)
    },
    onError: error => {
      failureMessage(`${error}`)
    },
  })
  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )
  type FileType = Parameters<UploadProps['beforeUpload']>[0]
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
    })
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }
  return (
    <div>
      {family?.map(member => {
        return (
          <div key={member._id + member.dob} className="flex-center  mt-20px">
            <GetAvatar person={member} size={46} />
            <div className="ml-20px">
              <h2 className="text-16 ">{getUserName(member)}</h2>
              <h4 className="text-14">{member.type}</h4>
            </div>
          </div>
        )
      })}
      <div className="mt-20px">
        <h3 className="text-14 w-600">Ticket details</h3>
        <div className="flex justify-between mt-20px">
          <h4 className="text-14 w-400">Dropin (per {cost?.unit})</h4>
          <h4 className="text-14 w-400">${cost?.price}</h4>
        </div>
        <div className="flex justify-between mt-20px">
          <h4 className="text-20 w-600">Total</h4>
          <h4 className="text-20 w-600">${cost?.amount}</h4>
        </div>
      </div>
      <div className="pr-30px">
        <h4>Who is picking up?</h4>
        <div className="flex-center mt-20px">
          <Checkbox
            onChange={onChange}
            name="guardian"
            checked={selectedValue === 'guardian'}
            className="cutom-checkbox"
          >
            Guardian
          </Checkbox>
          <Checkbox
            onChange={onChange}
            name="adults"
            checked={selectedValue === 'adults'}
            className="cutom-checkbox"
          >
            Authorized Adults
          </Checkbox>
          <Checkbox
            onChange={onChange}
            name="someone"
            checked={selectedValue === 'someone'}
            className="cutom-checkbox"
          >
            Someone else
          </Checkbox>
        </div>

        {family?.length < 1 && selectedValue !== 'someone' ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Form form={form} onFinish={onFinish}>
            {selectedValue !== 'someone' ? (
              <div className="mt-20px">
                <>
                  <label htmlFor="">
                    {selectedValue === 'guardian'
                      ? 'Guardian'
                      : 'Authorized adults'}
                  </label>

                  <Form.Item
                    name={
                      selectedValue === 'guardian'
                        ? 'pickUpGuardianId'
                        : selectedValue === 'adults'
                        ? 'pickUpAdultId'
                        : ''
                    }
                    rules={[
                      {
                        required: true,
                        message: `Please select ${selectedValue}`,
                      },
                    ]}
                    className="pointer selector-input"
                  >
                    <Select
                      options={memberList}
                      disabled={memberList === undefined && false}
                      className="select-input-field mt-10 cursor-pointer select-member"
                    />
                  </Form.Item>
                </>
              </div>
            ) : (
              <div>
                <label className="mt-20px">Name</label>
                <Form.Item name="pickUpPersonName">
                  <Input className="my-input mt-10px " />
                </Form.Item>
                <label className="mt-20px">Upload a photo</label>
                <Form.Item name="photoId">
                  {/* <ImgCrop rotationSlider>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChangefile}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && '+ Upload'}
                    </Upload>
                  </ImgCrop> */}
                  <>
                    <Upload
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={onChangefile}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: visible => setPreviewOpen(visible),
                        }}
                        // preview={{
                        //   visible: previewOpen,
                        //   onVisibleChange: visible => setPreviewOpen(visible),
                        //   afterOpenChange: visible =>
                        //     !visible && setPreviewImage(''),
                        // }}
                        src={previewImage}
                      />
                    )}
                  </>
                </Form.Item>

                {/* <FileUploader
                  setAvatar={setAvatar}
                  name="guardian"
                  form={form}
                /> */}
                {/* </Form.Item> */}
                {/* <label className="mt-20px">Phone Number</label>

              <Form.Item name="pickUpPersonPhoneNumber">
                <PhoneField form={form} className="cursor_pointer" />
              </Form.Item> */}
              </div>
            )}
            <label className="mt-20px">Notes</label>

            <Form.Item name="checkInNotes">
              <Input className="notes-inp mt-10px" />
            </Form.Item>
            <div className="flex justify-end">
              <button className="form-btn pointer">
                {mutation.isLoading ? <Loader /> : 'Drop out'}
              </button>
            </div>
          </Form>
        )}
        {/* <label className="mt-20px">Notes</label>

      <Form.Item name="checkInNotes">
        <Input className="notes-inp mt-10px" />
      </Form.Item> */}
        {/* <div className="flex justify-end">
        <button className="form-btn pointer" onClick={checkOut}>
          {mutation.isLoading ? <Loader /> : 'Drop out'}
        </button>
      </div> */}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})
export default connect(mapStateToProps)(FamilyCheckOut)
