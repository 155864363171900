import { Modal, Form, notification } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import CountryPhoneCode from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { ConfigProvider } from "antd-country-phone-input";
import { failureMessage } from "../../../api/global";

interface ChangePhoneProps {
  setModal: (arg: Number | null | false) => void;
  prevPhone: string;
  changePhone: (arg: any) => void;
}
const ChangePhone: React.FC<ChangePhoneProps> = ({
  setModal,
  prevPhone,
  changePhone,
}) => {
  const [phone, setPhone] = useState<any>({});
  const [open, setOpen] = useState<boolean>(true);

  const onFinish = (event: any) => {
    event.preventDefault();
    if (phone.dialCode && phone.isoCode && phone.number !== "") {
      changePhone({
        phone: {
          dialCode: phone.dialCode,
          isoCode: phone.isoCode,
          number: phone.number,
        },
      });
      setOpen(false);

      setModal(false);
    } else failureMessage("Please enter phone number correctly!!");
  };
  const handleCancel = () => {
    setModal(false);
    setOpen(false);
  };
  return (
    <ConfigProvider locale={en}>
      <Modal open={open} onCancel={handleCancel} className="popup-modal">
        <div>
          <div className="popup-header">
            <div>
              <h2>Change your phone number</h2>
              <p className="subtitlePara">
                Your current phone number {prevPhone}
              </p>
            </div>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <div className="inputGroup">
            <form onSubmit={onFinish}>
              <label className="">New phone number</label>
              <Form.Item className="mt-20">
                <CountryPhoneCode
                  onChange={(v) => {
                    setPhone({
                      dialCode: String(v.code),
                      number: v.phone,
                      isoCode: v.short,
                    });
                  }}
                />
              </Form.Item>
              <div className="popup-footer">
                <button className="btn-sec pointer" onClick={handleCancel}>
                  Cancel
                </button>
                <button className="btn-prim pointer" type="submit">
                  Change phone number
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default ChangePhone;
