import { Button, Card, Col, Dropdown, Row, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import eventCard2 from "../../../../../assets/Images/eventCard-2.png";
import CustomCalender from "../../../../../helper/Calendar/Eventscalendar/CustomCalender";
import { SearchOutlined } from "@ant-design/icons";
import Actionicon from "../../../../../assets/Images/Action.svg";
import google from "../../../../../assets/Images/google.svg";
import outlook from "../../../../../assets/Images/outlook.svg";
const Menu = <></>;
const Events = () => {
  return (
    <div className="bg-grey padding-top-10">
      <div className="profile_container">
        <Row justify="space-between" style={{ marginBottom: "30px" }}>
          <Col span={12}>
            <div className="right-search-block">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  className={"search-input-box"}
                  style={{ marginRight: "0px" }}
                >
                  <input
                    type="search"
                    className="search-input-group"
                    placeholder="Search by event name"
                    // onChange={(e: any) => searchActive(e.target.value)}
                  />
                  <SearchOutlined />
                </div>
              </div>
            </div>
          </Col>
          <Col span={12} className="justify text-right" >
            <Button shape="round" className="nav-btn" onClick={() => {}}>
              Calendar
            </Button>
          </Col>
        </Row>
        <Row gutter={10}>
        <Col span={24} lg={{span:8}} className="org-profile-event-cal">
            <CustomCalender />
            <Row justify="center" gutter={[24, 10]}>
            <Col span={15}>
              <h5 className="org-sync-calendars">
                <b className="b1">Sync with calendars</b>
              </h5>
              </Col>
              <Col span={15}>
              <Card style={{ width: "293px", margin:"auto" }}>
                <Row gutter={[24, 24]}>
                  <Col span={4}>
                    <img alt="img" src={google}></img>
                  </Col>
                  <Col span={20}>Google Calendar</Col>
                </Row>
              </Card>
              </Col>
              <Col span={15}>
              <Card style={{ width: "293px", margin:"auto" }}>
                <Row gutter={24}>
                  <Col span={6}>
                    <img alt="img" src={outlook}></img>
                  </Col>
                  <Col span={18}>Outlook</Col>
                </Row>
              </Card>
             </Col>
            </Row>
          </Col>
          <Col span={24} lg={{span:16}}>
            <Card title="Todays event" className="events_card">
              <Row gutter={[24, 24]} style={{position:"relative"}}>
                <Col xl={{ span: 10 }} xs={{ span: 24 }}>
                  <Meta
                    avatar={<img alt="" src={eventCard2} />}
                    title="Day in a Zoo"
                    description="Ages 3 - 5"
                  />
                </Col>
                <Col xl={{ span: 14 }} xs={{ span: 24 }}>
                  <Space className="org-today-event-btn">
                    <Button className=" br-8" style={{ width: "102px" }}>
                      check in
                    </Button>
                    <Button className="primary br-8">Register a child</Button>
                  </Space>
                </Col>
                  <Dropdown
                    overlay={Menu}
                    placement="bottom"
                    className="event-btns-card event-dropdown-btn"
                  >
                    <Button>
                      <img alt="img" src={Actionicon} />
                    </Button>
                  </Dropdown>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Events;