import { Layout, Modal } from "antd";
import { useState, useEffect } from "react";
import { Steps } from "antd";
import { useDispatch } from "react-redux";
import GeneralDetails from "./Screens/GeneralDetails/index";
import TimeAndFrequency from "./Screens/TimeAndFrequency/index";
import Other from "./Screens/Other";

const { Step } = Steps;

const RequestMentor = ({
  isModalVisible,
  setIsModalVisible,
  sessionValues,
}: any) => {
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState(sessionValues);

  useEffect(() => {
    const dataVal = { ...sessionValues };
    dataVal.additionalNotes = "demo";
  }, []);

  const next = (childData: any) => {
    setData({ ...data, ...childData });
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const steps = [
    {
      title: "General Details",
    },
    {
      title: "Time And Frequency",
    },
    {
      title: "Other",
    },
  ];

  return (
    <Layout>
      <Modal
        visible={isModalVisible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        width={600}
        className="organization_model py-0"
      >
        <div className="model-header new-session-model-header">
          <h4> {sessionValues?._id ? "Edit Session" : "New Session"} </h4>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} className="stepper" title={item.title} />
            ))}
          </Steps>
        </div>
        <div>
          {current === 0 && <GeneralDetails data={data} handleNext={next} />}
          {current === 1 && (
            <TimeAndFrequency handleBack={prev} data={data} handleNext={next} />
          )}
          {current === 2 && (
            <Other
              handleBack={prev}
              data={data}
              setIsModalVisible={setIsModalVisible}
              setCurrent={setCurrent}
            />
          )}
        </div>
      </Modal>
    </Layout>
  );
};

export default RequestMentor;
