import { Layout, Tabs } from "antd";
import React from "react";
import DefaultTab from "./DefaultTab";
import SpecificTab from "./SpecificTab";

const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};
const ActivityForm = ({ setDefaultTabKey, setModalVisible }:any) => {
  return (
    <Layout>
      <Tabs defaultActiveKey="1" onChange={onChange} >
        <TabPane tab="Default" key="1">
          <DefaultTab />
        </TabPane>
        <TabPane tab="Specific" key="2">
          <SpecificTab setDefaultTabKey={setDefaultTabKey} setModalVisible={setModalVisible}/>
        </TabPane>
      </Tabs>
    </Layout>
  );
};

export default ActivityForm;
