const actions = {
  GET_SESSION_DATA_REQUEST: "GET_SESSION_DATA_REQUEST",
  GET_SESSION_DATA_REQUEST_SUCCESS: "GET_SESSION_DATA_REQUEST_SUCCESS",
  GET_SESSION_DATA_REQUEST_FAILURE: "GET_SESSION_DATA_REQUEST_FAILURE",
  GET_SESSION_DETAIL_DATA_REQUEST: "GET_SESSION_DETAIL_DATA_REQUEST",
  GET_SESSION_DETAIL_DATA_REQUEST_SUCCESS: "GET_SESSION_DETAIL_DATA_REQUEST_SUCCESS",
  GET_SESSION_DETAIL_DATA_REQUEST_FAILURE: "GET_SESSION_DETAIL_DATA_REQUEST_FAILURE",
  ADD_SESSION_DATA: "ADD_SESSION_DATA",
  ADD_SESSION_DATA_SUCCESS: "ADD_SESSION_DATA_SUCCESS",
  ADD_SESSION_DATA_FAILURE: "ADD_SESSION_DATA_FAILURE",
  UPDATE_SESSION_DATA: "UPDATE_SESSION_DATA",
  UPDATE_SESSION_DATA_SUCCESS: "UPDATE_SESSION_DATA_SUCCESS",
  UPDATE_SESSION_DATA_FAILURE: "UPDATE_SESSION_DATA_FAILURE",
  GET_LIVE_UPDATE_DATA: "GET_LIVE_UPDATE_DATA",
  GET_LIVE_UPDATE_DATA_SUCCESS: "GET_LIVE_UPDATE_DATA_SUCCESS",
  GET_LIVE_UPDATE_DATA_FAILURE: "GET_LIVE_UPDATE_DATA_FAILURE",
  ADD_LIVE_UPDATE_DATA: "ADD_LIVE_UPDATE_DATA",
  ADD_LIVE_UPDATE_DATA_SUCCESS: "ADD_LIVE_UPDATE_DATA_SUCCESS",
  ADD_LIVE_UPDATE_DATA_FAILURE: "ADD_LIVE_UPDATE_DATA_FAILURE",
  GET_NOTES_DATA: "GET_NOTES_DATA",
  GET_NOTES_DATA_SUCCESS: "GET_NOTES_DATA_SUCCESS",
  GET_NOTES_DATA_FAILURE: "GET_NOTES_DATA_FAILURE",
  ADD_NOTES_DATA: "ADD_NOTES_DATA",
  ADD_NOTES_DATA_SUCCESS: "ADD_NOTES_DATA_SUCCESS",
  ADD_NOTES_DATA_FAILURE: "ADD_NOTES_DATA_FAILURE",

  KID_ADD_SUCCESS: "KID_ADD_SUCCESS",
  AUTHORIZED_ADULT_SUCCESS: "AUTHORIZED_ADULT_SUCCESS",
  RESET_CHECKIN: "RESET_CHECKIN",

  getSessionDataRequest: (payload) => ({
    type: actions.GET_SESSION_DATA_REQUEST,
    payload,
  }),

  getSessionDataRequestSuccess: (payload) => ({
    type: actions.GET_SESSION_DATA_REQUEST_SUCCESS,
    payload,
  }),

  getSessionDataRequestFailure: () => ({
    type: actions.GET_SESSION_DATA_REQUEST_FAILURE
  }),

  getSessionDetailDataRequest: (payload) => ({
    type: actions.GET_SESSION_DETAIL_DATA_REQUEST,
    payload,
  }),

  getSessionDetailDataRequestSuccess: (payload) => ({
    type: actions.GET_SESSION_DETAIL_DATA_REQUEST_SUCCESS,
    payload,
  }),

  getSessionDetailDataRequestFailure: () => ({
    type: actions.GET_SESSION_DETAIL_DATA_REQUEST_FAILURE
  }),

  addSessionData: (payload) => ({
    type: actions.ADD_SESSION_DATA,
    payload,
  }),

  addSessionDataSuccess: (payload) => ({
    type: actions.ADD_SESSION_DATA_SUCCESS,
    payload,
  }),

  addSessionDataFailure: () => ({
    type: actions.ADD_SESSION_DATA_FAILURE
  }),

  updateSessionData: (payload) => ({
    type: actions.UPDATE_SESSION_DATA,
    payload,
  }),

  updateSessionDataSuccess: (payload) => ({
    type: actions.UPDATE_SESSION_DATA_SUCCESS,
    payload,
  }),

  updateSessionDataFailure: () => ({
    type: actions.UPDATE_SESSION_DATA_FAILURE
  }),

  getLiveUpdateData: (payload) => ({
    type: actions.GET_LIVE_UPDATE_DATA,
    payload,
  }),

  getLiveUpdateDataSuccess: (payload) => ({
    type: actions.GET_LIVE_UPDATE_DATA_SUCCESS,
    payload,
  }),

  getLiveUpdateDataFailure: () => ({
    type: actions.GET_LIVE_UPDATE_DATA_FAILURE
  }),

  addLiveUpdateData: (payload) => ({
    type: actions.ADD_LIVE_UPDATE_DATA,
    payload,
  }),

  addLiveUpdateDataSuccess: (payload) => ({
    type: actions.ADD_LIVE_UPDATE_DATA_SUCCESS,
    payload,
  }),

  addLiveUpdateDataFailure: () => ({
    type: actions.ADD_LIVE_UPDATE_DATA_FAILURE
  }),

  getNotesData: (payload) => ({
    type: actions.GET_NOTES_DATA,
    payload,
  }),

  getNotesDataSuccess: (payload) => ({
    type: actions.GET_NOTES_DATA_SUCCESS,
    payload,
  }),

  getNotesDataFailure: () => ({
    type: actions.GET_NOTES_DATA_FAILURE
  }),

  addNotesData: (payload) => ({
    type: actions.ADD_NOTES_DATA,
    payload,
  }),

  addNotesDataSuccess: (payload) => ({
    type: actions.ADD_NOTES_DATA_SUCCESS,
    payload,
  }),

  addNotesDataFailure: () => ({
    type: actions.ADD_NOTES_DATA_FAILURE
  }),
  kidAddSuccess: (payload) => ({
    type: actions.KID_ADD_SUCCESS,
    payload,
  }),
  authorizedAdultSuccess: (payload) => ({
    type: actions. AUTHORIZED_ADULT_SUCCESS,
    payload,
  }),
  resetCheckin: () => ({
    type: actions.RESET_CHECKIN,
  })
};

export default actions;
