import { Button, Layout, Modal } from 'antd'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import EventSaveandDraft from './EventSaveandDraft'
const Exitwithoutfinishing = ({
  Visible,
  isModalVisibleEvent,
  setModalVisibleEvent,
  props,
}: any) => {
  const [isModalVisibleSaveandDraft, setModalVisibleSaveandDraft] =
    useState(false)
  const history = useHistory()
  const handleOk = () => {
    setModalVisibleEvent(false)
  }
  const handleExit = () => {
    history.push('/events')
    setModalVisibleEvent(false)
  }

  return (
    <div>
      <Layout>
        <Modal
          visible={isModalVisibleEvent}
          onOk={handleOk}
          onCancel={() => {
            setModalVisibleEvent(false)
          }}
          centered={true}
          footer={null}
          className="map-modal temp-model-step2"
        >
          <div className="justify-center text-center">
            <h1 className="f-semibold text-color-black text-center">
              Exit without finishing
            </h1>
            <p className="text-center mt-20">
              {' '}
              If you leave now, your event won’t be created and your progress
              won’t be saved.
            </p>
            <div>
              <Button className="big-btn-gray mt-20" onClick={handleExit}>
                Yes, exit
              </Button>
            </div>
            <div>
              <Button className="big-btn-blue mt-20" onClick={handleOk}>
                Continue Editing
              </Button>
            </div>
          </div>
        </Modal>
      </Layout>
      {isModalVisibleSaveandDraft ? (
        <EventSaveandDraft
          setModalVisibleSaveandDraft={setModalVisibleSaveandDraft}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
export default Exitwithoutfinishing
