import { Row } from 'antd'
import AdultInfo from './AdultInfo'
import AdultGeneral from './AdultGeneral'
const AdultsBasicInfo = ({ parent, refetch }: any) => {
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <AdultInfo data={parent} refetchData={refetch} />
      <AdultGeneral data={parent} refetchData={refetch} />
    </Row>
  )
}

export default AdultsBasicInfo
