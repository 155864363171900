import { Button, Upload } from 'antd'
import ImageCrop from 'antd-img-crop'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from '../Redux/FileUpload/action'
import beforeUpload from './beforeUpload'
import { LoadingOutlined } from '@ant-design/icons'

const FileUploader = ({
  setAvatar,
  name,
  fileData,
  form,
  loading,
  fileId,
}: any) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (fileId) {
      dispatch({
        type: actions.GET_FILE,
        fileId: fileId,
      })
    }
  }, [fileId])

  useEffect(() => {
    if (fileData) {
      form.setFieldsValue({
        photoId: fileData._id,
      })
      setAvatar(fileData.post)
      dispatch({
        type: actions.CLEAR_FILE_DATA,
      })
    }
  }, [loading])

  const handleChangeFile = (info: any) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done'
    }
    if (info.file.status === 'done') {
      const fileObj = new FormData()
      fileObj.append('file', info.file.originFileObj)
      console.log('originFileObj', info.file.originFileObj)
      let newFile = {
        title: name,
        description: 'img',
        altText: 'img',
        sourceType: 'user_profile',
      }

      dispatch({
        type: actions.REQUEST_FILE_UPLOAD,
        payload: fileObj,
        newFile: newFile,
      })
    }
  }

  return (
    <div>
      <ImageCrop
        beforeCrop={(image: any) => {
          return beforeUpload(image)
        }}
      >
        <Upload
          onChange={handleChangeFile}
          multiple={false}
          maxCount={1}
          showUploadList={false}
        >
          {loading ? (
            <LoadingOutlined />
          ) : (
            <div className="btstyle">
              <Button className="secondary upload">Upload photo</Button>
            </div>
          )}
        </Upload>
      </ImageCrop>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})

export default connect(mapStateToProps)(FileUploader)
