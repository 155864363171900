import { Avatar, Dropdown, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Cancel from 'assets/Images/close.svg'
import { convertDOBToAge, getNameSignature } from 'helper/utility'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { AdditionalOption, Ticket } from 'pages/Events/types'
import { getEventAttendeeTickets } from 'api/Events'
import ticketImg from 'assets/Images/ticketImg.svg'
import arrow from 'assets/Images/ticket-arrow.png'
import Loader from 'helper/Loader'
import more from 'assets/Images/moreDots.svg'
import EditReg from 'assets/Images/EditRegistration.svg'
import delete_red from 'assets/Images/delete_red.svg'
import { CaretDown } from '@phosphor-icons/react'
import DeleteTicket from './DeleteTicket'
const EditRegistration = ({ isVisible, onClose, attendie }) => {
  const { id }: any = useParams()

  const {
    data: AttendieTickets,
    refetch,
    isLoading,
  } = useQuery(
    ['getAttendeeTickets'],
    () => getEventAttendeeTickets(id, attendie._id),
    { enabled: false },
  )

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deleteType, setDeleteType] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [loading, setLoading] = useState(false)
  const [deleteId, setDeleteId] = useState<{
    admissionId: string
    additionalId: string
  }>({ admissionId: '', additionalId: null })

  const handleDelete = (type, name, admissionId, additionalId) => {
    setDeleteType(type)
    setDeleteName(name)
    setDeleteId({ admissionId, additionalId })
    setDeleteModalVisible(true)
  }

  const handleConfirmDelete = () => {
    setLoading(true)

    setLoading(false)
    setDeleteModalVisible(false)
  }

  useEffect(() => {
    refetch()
  }, [])

  const MoreForSimpleTicket = (ticketName, admissionId) => [
    {
      key: 1,
      label: (
        <div className="flex align-center pointer">
          <img src={EditReg} alt="edit-ticket" />
          <h4 className="w-500 text-14 ml-10px">Edit ticket</h4>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          className="flex align-center pointer"
          onClick={() => handleDelete('ticket', ticketName, admissionId, null)}
        >
          <img src={delete_red} alt="delete-ticket" />
          <h4 className="w-500 text-14 ml-10px danger-txt">Delete ticket</h4>
        </div>
      ),
    },
  ]

  const MoreForAdditionalOption = (
    additionalOptionName,
    admissionId,
    additionalId,
  ) => [
    {
      key: 1,
      label: (
        <div className="flex align-center pointer">
          <img src={EditReg} alt="edit-additional-option" />
          <h4 className="w-500 text-14 ml-10px">Edit additional option</h4>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          className="flex align-center pointer"
          onClick={() =>
            handleDelete(
              'additional option',
              additionalOptionName,
              admissionId,
              additionalId,
            )
          }
        >
          <img src={delete_red} alt="delete-additional-option" />
          <h4 className="w-500 text-14 ml-10px danger-txt">
            Delete additional option
          </h4>
        </div>
      ),
    },
  ]
  const NewTicket = [
    {
      key: 1,
      label: <h4 className="w-500 text-14 ml-10px">Admission Ticket</h4>,
    },
    {
      key: 2,
      label: <h4 className="w-500 text-14 ml-10px ">Additional Option</h4>,
    },
  ]
  const Update = [
    {
      key: 1,
      label: (
        <h4 className="w-500 text-14 ml-10px">
          Send to parent for confirmation
        </h4>
      ),
    },
    {
      key: 2,
      label: <h4 className="w-500 text-14 ml-10px ">Charge for tickets</h4>,
    },
  ]
  return (
    <>
      <Modal visible={isVisible} onCancel={onClose} className="popup-modal">
        <div className="popup-header ">
          <h2 className="w-600 text-24">Edit Registration</h2>
          <img src={Cancel} alt="close button pointer" onClick={onClose} />
        </div>
        <div className="mt-20">
          <div className="flex-center">
            <Avatar src={attendie.photo?.thumb} size={50}>
              {getNameSignature(attendie.firstName + attendie.lastName)}
            </Avatar>
            <div className="ml-20px">
              <h3 className="text-16">
                {attendie.firstName + attendie.lastName}
              </h3>
              <h4 className="text-12 grey-txt">
                {convertDOBToAge(attendie.dob)} years old
              </h4>
            </div>
          </div>
          <div className="px-20px py-20px bg-white bg-shadow mt-20px radius-12">
            {isLoading ? (
              <div className="flex-center justify-center">
                <Loader />
              </div>
            ) : (
              <div>
                {AttendieTickets?.tickets?.map(
                  (Tdetail: Ticket, index: number) => {
                    const hasNextItem =
                      index < AttendieTickets.tickets.length - 1
                    return (
                      <div
                        key={index}
                        className={hasNextItem ? 'border-btm pb-20px' : ''}
                      >
                        <div className="flex-center justify-between mt-10">
                          <div className="flex-center ">
                            <img src={ticketImg} alt="ticket" />
                            <h2 className="text-16 w-600 ml-10px">
                              {Tdetail.name}
                            </h2>
                          </div>
                          <div className="flex-center">
                            <h3 className="text-14 w-500 mr-10px">
                              ${Tdetail.price}
                            </h3>
                            <Dropdown
                              key={index}
                              menu={{
                                items: MoreForSimpleTicket(
                                  Tdetail.name,
                                  Tdetail.id,
                                ),
                              }}
                            >
                              <img src={more} alt="more" className="pointer" />
                            </Dropdown>
                          </div>
                        </div>
                        {Tdetail.additionalOptions &&
                          Tdetail.additionalOptions.map(
                            (
                              additionalTicket: AdditionalOption,
                              index: number,
                            ) => {
                              return (
                                <div
                                  className="flex-center justify-between mt-10px"
                                  key={index}
                                >
                                  <div className="flex-center">
                                    <img src={arrow} alt="ticket" />
                                    <h2 className="text-16 w-600 ml-10px">
                                      {additionalTicket.name}
                                    </h2>
                                  </div>
                                  <div className="flex-center">
                                    <h3 className="text-14 w-500 mr-10px">
                                      ${additionalTicket.price}
                                    </h3>
                                    <Dropdown
                                      key={index}
                                      menu={{
                                        items: MoreForAdditionalOption(
                                          additionalTicket.name,
                                          Tdetail.id,
                                          additionalTicket.id,
                                        ),
                                      }}
                                    >
                                      <img
                                        src={more}
                                        alt="more"
                                        className="pointer"
                                      />
                                    </Dropdown>
                                  </div>
                                </div>
                              )
                            },
                          )}
                      </div>
                    )
                  },
                )}
              </div>
            )}
          </div>
          <div className="mt-30px pb-30px flex justify-end">
            <Dropdown menu={{ items: NewTicket }}>
              <button className="py-15px borderd-btn  w-600 text-16">
                Add a new ticket
                <CaretDown
                  color="#2BA7CA"
                  size={18}
                  style={{
                    position: 'relative',
                    top: '4px',
                    marginLeft: '4px',
                  }}
                />
              </button>
            </Dropdown>
            <Dropdown menu={{ items: Update }}>
              <button className="prim-sm-btn ml-20px text-16">
                Update
                <CaretDown
                  color="#ffff"
                  size={18}
                  style={{
                    position: 'relative',
                    top: '4px',
                    marginLeft: '4px',
                  }}
                />
              </button>
            </Dropdown>
          </div>
        </div>
      </Modal>

      <DeleteTicket
        isVisible={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        name={deleteName}
        type={deleteType}
        loading={loading}
        addmissionId={deleteId.admissionId}
        additionalId={deleteId.additionalId}
        refetch={refetch}
      />
    </>
  )
}

export default EditRegistration
