import {
  Button,
  ConfigProvider,
  Form,
  Row,
  Col,
  Input,
  Radio,
} from "antd";
import { Layout } from "antd";
import paypals from "../../../../assets/Images/Paypals.png";
import VisaCard from "../../../../assets/Images/VisaCard.png";
import { DatePicker } from "antd";


const PaymentDataForm = ({ match, onSuccess, currentData }: any) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log(values);
    onSuccess({
      //   ...data,
      //   id: data?.id || uuidv4(),
      //   status: data?.status || "active",
      //   basic: {
      //     ...values,
      //   },
    });
  };
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  return (
    <Layout>
      <ConfigProvider>
        <Form
          form={form}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          layout={"vertical"}
          // initialValues={formSchema}
          autoComplete="off"
        >
          <Row gutter={24} align="middle">
            <h1>Add Payment Method</h1>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="mb-16 .display-column">
              <Form.Item>
                <Radio className="radio" value={1}>
                  <img src={VisaCard}></img> Credit card (MasterCard, Visa)
                </Radio>
              </Form.Item>
              <Form.Item>
                <Radio className="radio" value={2}>
                  <img src={paypals}></img> PayPal
                </Radio>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="mb-16">
              <label htmlFor="" className="input-label">
                Card number
              </label>
              <Form.Item colon={false} name="cardNumber">
                <Input
                  placeholder="XXXX XXXX XXXX XXXX"
                  required
                  name="firstname"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mb-16">
              <label htmlFor="" className="input-label">
                Name on the card
              </label>
              <Form.Item colon={false} name="cardName">
                <Input placeholder="Enter name on the card" name="lastname" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="mb-16">
              <label htmlFor="" className="input-label">
                Select Month
              </label>
              <Form.Item colon={false} name="month">
                <DatePicker picker="month" bordered={false} />
              </Form.Item>
            </Col>
            <Col span={12} className="mb-16">
              <label htmlFor="" className="input-label">
                Select Year
              </label>
              <Form.Item colon={false} name="year">
                <DatePicker picker="year" bordered={false} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="mb-16">
              <label htmlFor="" className="input-label">
                CVV
              </label>
              <Form.Item colon={false} name="cvv">
                <Input placeholder="XXX" />
              </Form.Item>
            </Col>
          </Row>
          <div className="stepperNextButton">
            <Button type="primary" className="stepper-button" htmlType="submit">
              Create
            </Button>
          </div>
        </Form>
      </ConfigProvider>
    </Layout>
  );
};
export default PaymentDataForm;
