/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  loading: false,
  allSesionData: [],
  sessionDetails: {},
  sessionData: [],
  liveUpdateData: {},
  liveUpdateDataList: [],
  notesData: {},
  notesDataList: [],
  checkInData: null,
  totalCount: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.GET_SESSION_DATA_REQUEST: {
      return {
        ...state,
        allSesionData: [],
        loading: true,
      };
    }

    case actions.GET_SESSION_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        allSesionData: action.payload,
        loading: false,
      };
    }

    case actions.GET_SESSION_DATA_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_SESSION_DETAIL_DATA_REQUEST: {
      return {
        ...state,
        sessionDetails: {},
        loading: true,
      };
    }

    case actions.GET_SESSION_DETAIL_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        sessionDetails: action.payload,
        loading: false,
      };
    }

    case actions.GET_SESSION_DETAIL_DATA_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ADD_SESSION_DATA: {
      return {
        ...state,
        sessionData: [],
        loading: true,
      };
    }
    case actions.ADD_SESSION_DATA_SUCCESS: {
      return {
        ...state,
        sessionData: action.payload,
        loading: false,
      };
    }

    case actions.ADD_SESSION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_SESSION_DATA: {
      return {
        ...state,
        sessionData: [],
        loading: true,
      };
    }
    case actions.UPDATE_SESSION_DATA_SUCCESS: {
      return {
        ...state,
        sessionData: action.payload,
        loading: false,
      };
    }

    case actions.UPDATE_SESSION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_LIVE_UPDATE_DATA: {
      return {
        ...state,
        liveUpdateDataList: [],
        loading: true,
      };
    }
    case actions.GET_LIVE_UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        liveUpdateDataList: action.payload,
        loading: false,
      };
    }

    case actions.GET_LIVE_UPDATE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ADD_LIVE_UPDATE_DATA: {
      return {
        ...state,
        liveUpdateData: {},
        loading: true,
      };
    }
    case actions.ADD_LIVE_UPDATE_DATA_SUCCESS: {
      return {
        ...state,
        liveUpdateData: action.payload,
        loading: false,
      };
    }

    case actions.ADD_LIVE_UPDATE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_NOTES_DATA: {
      return {
        ...state,
        notesDataList: [],
        loading: true,
      };
    }
    case actions.GET_NOTES_DATA_SUCCESS: {
      return {
        ...state,
        notesDataList: action.payload,
        loading: false,
      };
    }

    case actions.GET_NOTES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.ADD_NOTES_DATA: {
      return {
        ...state,
        notesData: {},
        loading: true,
      };
    }
    case actions.ADD_NOTES_DATA_SUCCESS: {
      return {
        ...state,
        notesData: action.payload,
        loading: false,
      };
    }

    case actions.ADD_NOTES_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.KID_ADD_SUCCESS: {
      return {
        ...state,
        checkInData: {...action.payload, type: "kid"},
      }
    }

    case actions.AUTHORIZED_ADULT_SUCCESS: {
      return {
        ...state, 
        checkInData: {...action.payload, type: "authorized-adult"},
      }
    }

    case actions.RESET_CHECKIN: {
      return {
        ...state,
        checkInData: null,
      }
    }

    default:
      return state;
  }
};
