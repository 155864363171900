import actions from "./action";

const initState = {
     locations : [] ,
    approvedLocations : [] ,
    pendingLocations : [] ,
    location :{},
    totalCount : 0,

};

export default (state = initState, action) => {

    switch (action.type) {

        case actions.GET_ALL_LOCATIONS: {
            return {
                ...state,
                locations: [],
                loading: true,
            };
        }

        case actions.GET_ALL_LOCATIONS_SUCCESS: {
            return {
                ...state,
                totalCount: action.payload.length,
                locations: action.payload,
                approvedLocations: action.payload.filter((location) => {
                    return location.status === "approved";
                }),
                pendingLocations: action.payload.filter((location) => {
                    return location.status !== "approved";
                }),
                loading: false,
            };
        }

        case actions.GET_ALL_LOCATIONS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case actions.FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }

        default:
            return state

    }

};
