import { Modal, Form, Input } from 'antd'
import Cancel from 'assets/Images/close.svg'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { rejectMentorRequest } from 'api/JobRequests'
import actions from "Redux/JobRequest/action"
import { useDispatch } from 'react-redux'
interface RejectModalType {
  open: boolean
  setOpen: (value: boolean) => void
  requestId?: string
}

const RejectRequestModal: React.FC<RejectModalType> = ({
  open,
  setOpen,
  requestId,
}) => {

  const dispatch = useDispatch();

  const handleCancel = () => {
    setOpen(false)
  }

  const handleSubmit = (values: any) => {
    rejectRequestMutation.mutate({
      status: "archived",
      rejectedReason: values.reason,
    })
  }

  const rejectRequestMutation = useMutation({
    mutationFn: async (payload: any) => {
      rejectMentorRequest(requestId, payload)
    },
    onSuccess: () => {
      handleCancel();
      dispatch({
        type: actions.SET_MENTOR_REQUEST_REJECT,
      })
    },
    onError: err => {
      handleCancel()
    },
  })

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      className="rejectRequestModal"
      footer={false}
    >
      <div className="flex-center  pt-40px justify-between ">
        <h2 className="w-600 text-24">Reject Request</h2>
        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>
      <p className="text-14 w-400 mt-10px">
        Please, explain why you unable to fullfill this request at this time
      </p>

      <Form onFinish={handleSubmit} className="mt-20px">
        <Form.Item
          name="reason"
          rules={[{ required: true, message: 'Please enter reason' }]}
        >
          <Input.TextArea
            className="reject-mentor-field"
            placeholder="Enter a reason for rejection..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <div className='flex align-center justify-end gap-30px mt-30px pb-30px'>
            <button className='btn-secondary' onClick={handleCancel}>Cancel</button>
            <button className='prim-btn' type='submit'>Reject</button>
        </div>
      </Form>
    </Modal>
  )
}

export default RejectRequestModal
