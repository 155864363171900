import { CaretRight } from '@phosphor-icons/react'
import { Avatar } from 'antd'
import { getNameSignature } from 'helper/utility'
import React from 'react'
import { Link } from 'react-router-dom'
import { Person } from 'types/globalTypes'

interface EventManagersProps {
  eventManagers: Person[]
}

const EventManagers: React.FC<EventManagersProps> = ({ eventManagers }) => {
  return (
    <div className="bg-grey container padding-top-10">
      {eventManagers.length > 0 ? (
        <div className="profile_container">
          {eventManagers?.map((item: Person, index: number) => {
            return (
              <Link key={item._id} to={`/organizer-profile/${item?._id}`}>
                <div className="flex gap-20 mt-20px align-center pointer">
                  <div className="flex align-center">
                    <Avatar src={item?.photo?.thumb} size={60}>
                      {getNameSignature(`${item.firstName} ${item.lastName}`)}
                    </Avatar>
                    <h4 className="text-20 w-500 ml-10px">
                      {item?.firstName + ' ' + item?.lastName}
                    </h4>
                  </div>
                  <CaretRight />
                </div>
              </Link>
            )
          })}
        </div>
      ) : (
        <h2>No event managers are available</h2>
      )}
    </div>
  )
}

export default EventManagers
