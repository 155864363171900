import { Button, Checkbox, Col, Input, Row, Space } from "antd";
import React, { useState } from "react";
import plusCircle from "../../../../assets/Images/PlusCircle.svg";
import deleteSvg from "../../../../assets/Images/delete_red.svg";
import moment from "moment";
import ClipboardCopy from "../../../../helper/ClipboardCopy";
import CalendarModal from "../../../../helper/Calendar/Availablitycalendar/CalenderModal";

const Availablity = ({ mentor }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday",];

  console.log(mentor);

  return (
      <Row gutter={2} className="bg-grey container padding-top-10">
        <Col lg={16} span={24} className="mb-md-15">
          <div className="profile_container">
            <h3 className="b1 mb-20 text-color-black">Set weekly schedule</h3>
            <Row gutter={[20, 0]}>
              <Col>
                {days?.map((day: any) => {
                  return (
                      <Row
                          align="middle"
                          gutter={[20, 40]}
                          className="bottom_gray_border weekly-schedule-list"
                      >
                        <Col xl={5} lg={6} span={8} className="text-nowrap">
                          <Checkbox
                              checked={
                                mentor && mentor?.schedule && mentor?.schedule[day][0]
                                    ? true
                                    : false
                              }
                          />
                          <span className="b1 d-inline-block ml-10 mr-10 px">
                        {day}
                      </span>
                        </Col>
                        {mentor && mentor?.schedule && mentor?.schedule[day][0] ? (
                            <Col xl={17} lg={16} span={14}>
                              <Space size={5}>
                                <Input
                                    value={moment
                                        .utc()
                                        .startOf("day")
                                        .add(mentor?.schedule[day][0].start, "minutes")
                                        .format("hh:mm A")}
                                    className="text-center"
                                    style={{
                                      width: "80%",
                                      height: "41px",
                                      color: "#000",
                                    }}
                                ></Input>
                                <Input
                                    value={moment
                                        .utc()
                                        .startOf("day")
                                        .add(mentor?.schedule[day][0].end, "minutes")
                                        .format("hh:mm A")}
                                    className="text-center"
                                    style={{
                                      width: "80%",
                                      height: "41px",
                                      color: "#000",
                                    }}
                                ></Input>
                                <img
                                    src={deleteSvg}
                                    className="delete-btn-img cursor_pointer"
                                ></img>
                              </Space>
                            </Col>
                        ) : (
                            <Col xl={17} lg={16} span={14}>
                              <h5 className="text-secondary">Unavailable</h5>
                            </Col>
                        )}

                        <Col span={2}>
                          <Space className="justify-between w-100">
                            <img
                                src={plusCircle}
                                alt="plusCircle"
                                className="cursor_pointer"
                            ></img>
                            {mentor && mentor?.schedule && (
                                <ClipboardCopy
                                    copyText={moment
                                        .utc()
                                        .startOf("day")
                                        .add(mentor?.schedule[day][0]?.start, "minutes")
                                        .format("hh:mm A")}
                                />
                            )}
                          </Space>
                        </Col>
                      </Row>
                  );
                })}
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8} span={24}>
          <div className="profile_container ">
            <Row gutter={[12, 16]}>
              <Col>
                <h3 className="b1">Add date overrides</h3>
              </Col>
              <Col>Add dates when availability changed from weekly hours</Col>
              <Col span={24}>
                <Button
                    className="outlined-button br-8 w-100"
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                >
                  Add a date override
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        {isModalVisible && (
            <CalendarModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
        )}
      </Row>
  );
};

export default Availablity;
