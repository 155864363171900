import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd'
const { Text, Title } = Typography
const MentorActivity = ({ data, refetchData }: any) => {
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6"> Mentor activity</h2>
        </div>
        <Row gutter={[24, 24]} justify="space-between" className="mt-20">
          <Col xl={{ span: 8 }} xs={{ span: 24 }}>
            <Tooltip title="1275" placement="bottom" color={'blue'}>
              <Card className="bg-light-gray organization-profile-statistics-card">
                <h6 style={{ marginBottom: '0px' }}>
                  <b className="b1">$ 30K</b>
                </h6>
                <span className="subtitle3">Total Earned</span>
              </Card>
            </Tooltip>
          </Col>
          <Col xl={{ span: 8 }} xs={{ span: 24 }}>
            <Tooltip title="1275" placement="bottom" color={'blue'}>
              <Card className="bg-light-gray organization-profile-statistics-card">
                <h6 style={{ marginBottom: '0px' }}>
                  <b className="b1">2</b>
                </h6>{' '}
                <span className="subtitle3">Jobs worked</span>
              </Card>
            </Tooltip>
          </Col>
          <Col xl={{ span: 8 }} xs={{ span: 24 }}>
            <Tooltip title="1275" placement="bottom" color={'blue'}>
              <Card className="bg-light-gray organization-profile-statistics-card">
                <h6 style={{ marginBottom: '0px' }}>
                  <b className="b1">h</b>
                </h6>{' '}
                <span className="subtitle3">Response Time</span>
              </Card>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </Col>
  )
}
export default MentorActivity
