import React, { useState } from 'react'
import { Header } from './header'
import { getStripHistory } from 'api/family'
import { useQuery } from '@tanstack/react-query'
import { Col, Dropdown, Menu, Row, Space } from 'antd'
import Loader from 'helper/Loader'
import { StripeTable } from './Table/StripeTable'
import { ExistingCustomer } from '../Payments/ExistingCustomer'
export const Stripe = familyId => {
  const [selectedRows, setSelectedRows] = useState([])
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    name: true,
    payment: true,
    amount: true,
    date: true,
    status: true,
    action: true,
  })
  const [parms, setParms] = useState<any>({
    page: 1,
    limit: 10,
    status: 'success',
  })
  const { isLoading, data, refetch } = useQuery(
    ['getStripHistory', familyId?.familyId, parms],
    () => getStripHistory(familyId?.familyId, parms),
  )
  return (
    <>
      <Header data={data?.records}></Header>
      <Row>
        <Col style={{ alignItems: 'center', width: '100%' }}>
          {isLoading ? (
            <div
              className="flex justify-center aligin-center items-center "
              style={{ height: '80vh' }}
            >
              <Loader />
            </div>
          ) : (
            <StripeTable
              setSelectedRows={setSelectedRows}
              hidCols={hiddenColumns}
              data={data}
              parms={parms}
              setParms={setParms}
            />
          )}
        </Col>
      </Row>
      <ExistingCustomer familyId={familyId?.familyId} />
    </>
  )
}
export default Stripe
