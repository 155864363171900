import { Modal, Form, Input } from 'antd'
import { useState } from 'react'
import CancelIcon from 'assets/Images/close.svg'
import Loader from 'helper/Loader'
import './../../pages/Organizer/Profiles/BasicInformation/StyleEmail.scss'
interface ChangeEmailModalProps {
  visible: boolean
  onCancel: () => void
  onOk: (values: any) => void
  currentEmail: string
  isLoading: boolean
  form: any
}

const ChangeEmailModal = ({
  visible,
  onCancel,
  onOk,
  currentEmail,
  isLoading,
  form,
}: ChangeEmailModalProps) => {
  // const [form] = Form.useForm()

  const handleOk = () => {
    form.validateFields().then(values => {
      onOk(values)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onCancel()
  }

  return (
    <Modal visible={visible} footer={null} onCancel={handleCancel}>
      <div className="popup-header">
        <h2 className="ChangeText">Change email</h2>
        <img src={CancelIcon} alt="close button" onClick={handleCancel} />
      </div>
      <Form form={form}>
        <p className="currentEmail">
          Your current email: <b>{currentEmail}</b>
        </p>

        <div className="emailTxt mt-10">New email</div>
        <Form.Item
          className="mt-10"
          name="email"
          rules={[
            { required: true, message: 'Please enter your new email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input className="mt-10px" placeholder="Enter new email" />
        </Form.Item>

        <div className="emailTxt mt-20px">Confirm email</div>
        <Form.Item
          name="confirmEmail"
          dependencies={['email']}
          rules={[
            { required: true, message: 'Please confirm your new email!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('email') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two emails do not match!'))
              },
            }),
          ]}
        >
          <Input className="mt-20px" placeholder="Confirm new email" />
        </Form.Item>
      </Form>
      <div className="flex justify-end w-full mt-20">
        <button
          className="prim-sm-btn"
          style={{
            background: '#EBEDF1',
            color: 'black',
            marginRight: '10px',
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button className="prim-sm-btn" disabled={isLoading} onClick={handleOk}>
          {isLoading ? <Loader /> : 'Change email'}
        </button>
      </div>
    </Modal>
  )
}

export default ChangeEmailModal
