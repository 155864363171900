import React, { useState } from 'react'
import './Style/newsStyle.scss'
import { Avatar, Button, Col, Dropdown } from 'antd'
import { getNameSignature } from 'helper/utility'
import Actionicon from 'assets/Images/Action.svg'
import Map from 'helper/Map/Map'
import NewsImageContainer from './NewsImageContainer'
import LikeCount from './LikeCount'
import moment from 'moment'
import ViewAddress from 'helper/Map/ViewAddress'
import { removeReactionFromNews } from 'api/News'
import { ShareAltOutlined, DeleteOutlined } from '@ant-design/icons'
import ArrowCounterClockwise from 'assets/Images/ArrowCounter.svg'
import UnpublishModal from './UnpublishModal'
import {
  ThumbsUp,
  PushPinSimple,
  // PaperPlaneTilt,
} from '@phosphor-icons/react'
import { useMutation } from '@tanstack/react-query'
const BasicNewsLayout = ({ newsData, parms }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [reason, setReason] = useState('') // State to store reason dropdown value
  const [comments, setComments] = useState('')
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleClose = () => {
    setIsModalVisible(false)
    setComments('')
    setReason('')
  }

  const handleUnpublish = () => {
    // Here you can add the API call or any logic to unpublish the news.
    setIsModalVisible(false)
  }
  const items = [
    {
      key: 'pin',
      label: <p>Pin post</p>,
    },
    {
      key: 'edit',
      label: <p>Edit post</p>,
    },
    {
      key: 'delete',
      label: <p>Delete post</p>,
    },
  ]
  return (
    <>
      <Col key={newsData?._id} className="  br-12 basic_newsContainer">
        <div>
          <div className="px-20 py-20 d-flex justify-between align-items-center">
            <div
              className="d-flex gap-20 align-items-center cursot-pointer"
              //   onClick={() =>
              //     router.push(
              //       `/organizationdetails/${newsData?.organization?.id}`
              //     )
              //   }
            >
              <Avatar size={50} src={newsData?.organization?.photo?.post}>
                {getNameSignature(newsData?.organization?.name)}
              </Avatar>
              <div>
                <div className="d-flex gap-10 align-items-center  ">
                  <h3 className="font14 b1">{newsData?.organization?.name}</h3>
                  <p className="gray_text">
                    {moment().diff(newsData?.createdAt, 'days') === 0
                      ? 'Today'
                      : `${moment().diff(
                          newsData?.createdAt,
                          'days',
                        )} days ago`}
                  </p>
                  <div></div>
                </div>
              </div>
            </div>
            <div>
              {
                <Dropdown
                  // overlay={Actionmenu(item)}
                  menu={{
                    items: items,
                    // onClick: e => handleDropdownItemClick(e, item),
                  }}
                  placement="bottom"
                >
                  <img alt="img" src={Actionicon} />
                </Dropdown>
              }
              {/* {newsData?.isPinned && <PushPinSimple size={24} color="#000" />} */}
            </div>
            {/* <div>
              {authenticated && (
                <PinNews
                  handlePin={handlePin}
                  newsData={newsData}
                  pinned={pinned}
                />
              )}
            </div> */}
          </div>
          <div className="px-20 mt-10 br-12 news-feed">
            {newsData?.title && (
              <div className="mt-16">
                <h3 className="font14 b1">{newsData?.title}</h3>
              </div>
            )}
            <p className="mt-10">{newsData?.text}</p>

            {newsData?.images.length && (
              <NewsImageContainer images={newsData?.images} />
            )}

            {newsData?.videos?.map(video => {
              return (
                <div key={video?._id} className="mt-16 br-12">
                  <video width="555" height="300" controls>
                    <source src={video?.link} type="video/mp4" />
                  </video>
                </div>
              )
            })}

            {newsData?.audios?.map(audio => {
              return (
                <div key={audio?._id} className="mt-16">
                  <audio controls>
                    <source src={audio?.link} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )
            })}

            {newsData?.address && (
              <div className="mt-10 br-12">
                <div className="mt-10 mb-16 gap-16 d-flex align-items-center justify-between">
                  <h3 className="font14 b-2">{`${newsData?.address?.apartment} ${newsData?.address?.street} ${newsData?.address?.city}, ${newsData?.address?.country}`}</h3>
                  <a
                    href={`https://www.google.com/maps/dir/${newsData.address.city}/${newsData.address.street}, ${newsData.address.city}, ${newsData.address.country}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="stepper-button">View location</Button>
                  </a>
                </div>

                <ViewAddress onlyView={true} mapData={newsData?.address} />
              </div>
            )}
          </div>
          <div className="px-20 like_count d-flex align-items-center">
            <LikeCount reactions={newsData?.reactions} />
            <p className={` gray_text font14 b2`}>
              {newsData?.reactions?.length}
            </p>
          </div>
          <div className="action-buttons d-flex justify-center align-items-center mt-20">
            <Button
              className="unpublish-button"
              type="text"
              onClick={showModal}
            >
              <img
                src={ArrowCounterClockwise}
                alt="ArrowCounterClockwise"
                style={{ marginRight: '10px' }}
              />{' '}
              Unpublish
            </Button>
            {/* 
            <Button className="share-button" type="text">
              Share
            </Button> */}
          </div>
        </div>
      </Col>
      <UnpublishModal
        parms={parms}
        newsid={newsData?._id}
        visible={isModalVisible}
        onClose={handleClose}
        onUnpublish={handleUnpublish}
        reason={reason}
        comments={comments}
        setReason={setReason}
        setComments={setComments}
      />
    </>
  )
}
export default BasicNewsLayout
