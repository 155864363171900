import React, { useState } from 'react'
import { Form, Button, Row, Input, Col, Avatar, Space } from 'antd'
import { Layout } from 'antd'
import 'antd-country-phone-input/dist/index.css'
import userAvatarSvg from '../../../../assets/Images/userAvatar.svg'
import FileUploader from '../../../../helper/FileUploader'
import RelationsList from '../../../../helper/RelationsList'
import CountryPhoneCode from 'antd-country-phone-input'
import { ConfigProvider } from 'antd-country-phone-input'
import PhoneField from '../../../../helper/PhoneField'
import en from 'world_countries_lists/data/countries/en/world.json'
import 'antd/dist/antd.css'
import 'antd-country-phone-input/dist/index.css'
import AllergyList from '../../../../helper/AllergyList'
import MapModal from '../../../../helper/Map/MapModal'
import { Text } from 'html-react-parser'

const PersonalDetails = ({ data, handleNext }: any) => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState()
  const [allergies, setAllergies] = useState<any>([])
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapModalData, setMapModalData] = useState<any>(data?.address)
  const [phone, setPhone] = useState<any>({})
  const getFlag = (short: string) => {
    const data = require(
      `world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`,
    )
    // for dumi
    if (typeof data === 'string') {
      return data
    }
    // for CRA
    return data.default
  }
  const formSchema = {
    photoId: data?.photoId || '',
    relationId: data?.relation?._id || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    phone: data?.phone || '',
    medicalNotes: data?.medicalNotes || '',
  }
  const onFinish = (values: any) => {
    let allergyData = allergies.map((alergy: any) => {
      return {
        _id: alergy.key,
        name: alergy.value,
      }
    })
    handleNext({
      ...values,
      isEmergencyContact: true,
      allergies: allergyData,
      gender: 'male',
      address: mapModalData,
    })
  }
  return (
    <Layout>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <div className="modal-h-element">
          <Space size={[24, 0]} wrap className="mb-12">
            <Form.Item>
              <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
            </Form.Item>
            <Form.Item name="photoId">
              <FileUploader
                setAvatar={setAvatar}
                name="guardian"
                form={form}
                fileId={data?.photoId}
              />
            </Form.Item>
            <p className="b3 mb-0 mt-12 text-color-black font12 position-relative required-field-star pl-10">
              For security purposes, please only use a real photo
            </p>
          </Space>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Relationship
              </label>
              <Form.Item
                name="relationId"
                rules={[
                  { required: true, message: 'Relationship is required' },
                ]}
                className="custom-select-field"
              >
                <RelationsList
                  data={null}
                  form={form}
                  relationId={formSchema.relationId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                First Name
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input placeholder="First Name" name="firstname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Last Name
              </label>
              <Form.Item
                name="lastName"
                colon={false}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input placeholder="Last Name" name="lastname" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Email
              </label>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Email is required' }]}
              >
                <Input placeholder="example@gmail.com" name="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Phone
              </label>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: 'Phone number is required' },
                ]}
                className="phone-number-field"
              >
                <PhoneField
                  form={form}
                  data={data?.phone}
                  className="cursor_pointer"
                />
              </Form.Item>
              {/* <Form.Item
                className="form-control"
                style={{ paddingTop: "32px" }}
              >
                <label className="input-label">Phone Number</label>
                <ConfigProvider
                  locale={en}
                  areaMapper={(area) => {
                    return {
                      ...area,
                      emoji: (
                        <img
                          alt="flag"
                          style={{
                            width: 18,
                            height: 18,
                            verticalAlign: "sub",
                          }}
                          src={getFlag(area.short)}
                        />
                      ),
                    };
                  }}
                >
                  <CountryPhoneCode
                    placeholder="Phone Number"
                    defaultValue={{ short: "India" }}
                    value={phone}
                    onChange={(v) => {
                      setPhone(v);
                    }}
                    className="your-custom-class"
                  />
                </ConfigProvider>
              </Form.Item> */}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Home address
              </label>
              <Form.Item rules={[{ required: true }]}>
                <Input
                  placeholder="Location"
                  onClick={() => {
                    setMapModalVisible(true)
                  }}
                  value={
                    mapModalData
                      ? mapModalData.city + ',' + mapModalData.state
                      : ''
                  }
                />
                {mapModalVisible && (
                  <MapModal
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    mapData={mapModalData}
                  />
                )}{' '}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label ">
                Allergies
              </label>
              <Form.Item
                name="allergies"
                className="form-control allergies-field"
              >
                <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="mb-16">
              <label htmlFor="" className="input-label">
                Medical Notes
              </label>
              <Form.Item
                name="medicalNotes"
                // rules={[{ required: true, message: "Medical notes is required" }]}
              >
                <Input placeholder="Ex.: Asthma, Lactose intolerant" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="stepperNextButton">
          <Button type="primary" className="stepper-button" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </Layout>
  )
}
export default PersonalDetails
