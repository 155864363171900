import React, { useState } from "react";
import {
  Form,
  Button,
  Radio,
  DatePicker,
  Row,
  Input,
  Col,
  Space,
  Avatar,
} from "antd";
import { Layout } from "antd";
import moment from "moment";
import userAvatarSvg from "../../../../assets/Images/userAvatar.svg";
import FileUploader from "../../../../helper/FileUploader";
import PhoneField from "../../../../helper/PhoneField";
import AllergyList from "../../../../helper/AllergyList";
import MapModal from "../../../../helper/Map/MapModal";

const Basicinfo = ({ data, handleNext }: any) => {
  const [form] = Form.useForm();
  const [allergies, setAllergies] = useState(data?.allergies);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [mapModalData, setMapModalData] = useState<any>(data?.address);
  const [avatar, setAvatar] = useState();
  var formSchema = {
    photoId: data?.photoId || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    gender: data?.gender || "",
    email: data?.email || "",
    phone: data?.phone || "",
    dob: moment(data?.dob) || null,
    medicalNotes: data?.medicalNotes || "",
  };

  const onFinish = (values: any) => {
    let allergyData =
      allergies &&
      allergies.map((alergy: any) => {
        return {
          _id: alergy.key,
          name: alergy.value,
        };
      });
    handleNext({
      values: values,
      mapModalData: mapModalData,
      allergyData: allergyData,
    });
  };

  return (
    <Layout>
      <Form
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        layout="vertical"
        initialValues={formSchema}
      >
        <div className="modal-h-element ">
          <Row>
            <Col className="mb-16">
              <Space>
                <Avatar
                  size={80}
                  src={avatar ? avatar : userAvatarSvg}
                ></Avatar>
                <Form.Item name="photoId">
                  <FileUploader
                    setAvatar={setAvatar}
                    name="mentor"
                    form={form}
                    fileId={data?.photoId}
                  />
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                First Name
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                rules={[{ required: true, message: "First name is required" }]}
              >
                <Input placeholder="FirstName" name="firstname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Last Name
              </label>
              <Form.Item
                colon={false}
                name="lastName"
                rules={[{ required: true, message: "Last name is required" }]}
              >
                <Input placeholder="Last Name" name="lastName" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label required-input">
                Gender
              </label>
              <Form.Item
                name="gender"
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Radio.Group name="gender">
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="female"
                  >
                    Female
                  </Radio.Button>
                  <Radio.Button
                    className="radioButton gender-radio-button"
                    value="male"
                  >
                    Male
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Email
              </label>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input placeholder="example@gmail.com" name="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Contact number
              </label>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Phone number is required" },
                ]}
              >
                <PhoneField form={form} data={data?.phone} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Date of Birth
              </label>
              <Form.Item name="dob">
                <DatePicker
                  name="dob"
                  className="date-picker custom-select-field"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Home address
              </label>
              <Form.Item rules={[{ required: true }]}>
                <Input
                  placeholder="Location"
                  onClick={() => {
                    setMapModalVisible(true);
                  }}
                  value={
                    mapModalData
                      ? mapModalData.city + "," + mapModalData.state
                      : ""
                  }
                />
                {mapModalVisible && (
                  <MapModal
                    setMapModalVisible={setMapModalVisible}
                    setMapModalData={setMapModalData}
                    mapData={mapModalData}
                  />
                )}{" "}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label ">
                Allergies
              </label>
              <Form.Item
                name="allergies"
                className="form-control allergies-field"
              >
                <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Medical Notes
              </label>
              <Form.Item
                name="medicalNotes"
                rules={[
                  { required: true, message: "Medical notes are required" },
                ]}
              >
                <Input placeholder="Ex.: Asthma, Lactose intolerant" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="footer-modal">
          <div className="stepperNextButton">
            <Button type="primary" className="stepper-button" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form>
    </Layout>
  );
};
export default Basicinfo;
