import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, Select } from "antd";
import { Menu, Dropdown, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { Table } from "antd";
import { Avatar, Image } from "antd";
import { useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import editblackPencil from "../../../../assets/Images/editblackPencil.svg";
import { Option } from "antd/lib/mentions";
import type { MenuProps } from "antd";
import type { SorterResult } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import CreateSession from "../../../Modals/Sessions/CreateSession";
import sessionAction from "../../../../Redux/Session/action";
import familyAction from "../../../../Redux/Family/action";
import mentorAction from "../../../../Redux/mentors/action";
import categoryAction from "../../../../Redux/Events/action";
import moment from "moment";

function MentorSessionTable({
  allSesionData,
  familyData,
  mentorsData,
  eventCategoryData,
}: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [visible, setVisible] = useState(false);
  const [sessionValues, setSessionValues] = useState({});
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    firstname: true,
    email: true,
    phone: true,
    dob: true,
    traininglevel: true,
    status: true,
    action: true,
    totalhours: true,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: sessionAction.GET_SESSION_DATA_REQUEST,
    });
    dispatch({
      type: familyAction.GET_FAMILIES,
    });
    dispatch({
      type: mentorAction.GET_MENTORS,
    });
    dispatch({
      type: categoryAction.GET_EVENT_CATEGORIES,
    });
  }, []);

  useEffect(() => {
    // console.log("isModalVisible:",isModalVisible,!isModalVisible)
    if (!isModalVisible) setSessionValues({});
  }, [isModalVisible]);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleChangeSortName = (value: string) => {
    setSortedInfo({
      order: "descend",
      columnKey: value,
    });
  };

  const data = [
    {
      family: "Smith",
      mentor: "Darlene Smith",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Wilson",
      mentor: "Cassandra Dunn",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Jones",
      mentor: "David Power",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Lopez",
      mentor: "Keiko Tanaka",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Taylor",
      mentor: "Charlotte de Crum",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Anderson",
      mentor: "Cody Fisher",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Williams",
      mentor: "Aaron Buxton",
      categoryskill: "Arts and Crafts ",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Moore",
      mentor: "Babak Shammas",
      categoryskill: "Arts and Crafts",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Martin",
      mentor: "Kadji Bell",
      categoryskill: "Arts and Crafts",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
    {
      family: "Smith",
      mentor: "Eric Ishida",
      categoryskill: "Arts and Crafts",
      hrs: "2h",
      firstsessiondate: "11/15/2022",
      upcomingsessiondate: "12/15/2022",
    },
  ];

  const columnss = [
    {
      title: "Family",
      dataIndex: "familyId",
      key: "familyId",
      render: (text: any) => (
        <div
          style={{ cursor: "pointer" }}
          // onClick={() => {
          //   history.push({
          //     pathname: "/sessiondetails",
          //   });
          // }}
        >
          <Space>
            {familyData &&
              familyData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.name)
                .toString() && (
                <Avatar
                  src={
                    familyData &&
                    familyData
                      ?.filter((item: any) => item._id === text)
                      .map((elm: any) => elm?.photo?.original)
                      .toString()
                  }
                  size={38}
                ></Avatar>
              )}
            {familyData &&
              familyData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.name)
                .toString()}
          </Space>
        </div>
      ),
      sorter: (a: any, b: any) => a.family.length - b.family.length,
      sortOrder: sortedInfo.columnKey === "family" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.family === false ? "display-none" : "",
    },
    {
      title: "Mentor",
      dataIndex: "mentorId",
      key: "mentorId",
      render: (text: any, record: any, index: any) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push({
              pathname: "/sessiondetails",
              state: {
                contractRoomId: record?.contractRoomId,
              },
            });
          }}
        >
          <Space>
            {mentorsData &&
              mentorsData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.firstName)
                .toString() && (
                <Avatar
                  src={
                    mentorsData &&
                    mentorsData
                      ?.filter((item: any) => item._id === text)
                      .map((elm: any) => elm?.photo?.original)
                      .toString()
                  }
                  size={32}
                ></Avatar>
              )}
            {mentorsData &&
              mentorsData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.firstName)
                .toString()}
          </Space>
        </div>
      ),
      sorter: (a: any, b: any) => a.mentor.length - b.mentor.length,
      sortOrder: sortedInfo.columnKey === "mentor" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.mentor === false ? "display-none" : "",
    },
    {
      title: "Category/skill",
      dataIndex: "categoryId",
      key: "categoryId",
      render: (text: any, record: any, index: any) => (
        <Button shape="round" size="small" className="secondary">
          {eventCategoryData &&
            eventCategoryData
              ?.filter((item: any) => item._id === text)
              .map((elm: any) => elm?.name)
              .toString()}
        </Button>
      ),
      sorter: (a: any, b: any) =>
        a.categoryskill.length - b.categoryskill.length,
      sortOrder:
        sortedInfo.columnKey === "categoryskill" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.categoryskill === false ? "display-none" : "",
    },
    {
      title: "Hours",
      dataIndex: "hrs",
      key: "hrs",
      render: (text: any, record: any, index: any) => "-",
      sorter: (a: any, b: any) => a.hrs.length - b.hrs.length,
      sortOrder: sortedInfo.columnKey === "hrs" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.hrs === false ? "display-none" : "",
    },
    {
      title: "First session date",
      dataIndex: "timesPerWeek",
      key: "timesPerWeek",
      render: (text: any, record: any, index: any) =>
        text === "one_time"
          ? moment(record?.oneTimeSessionSchedule?.startTime).format(
              "MM-DD-YYYY"
            )
          : text === "flexible"
          ? moment(record?.flexibleSessionSchedule?.startDate).format(
              "MM-DD-YYYY"
            )
          : moment(record?.recurringSessionSchedule?.startDate).format(
              "MM-DD-YYYY"
            ),
      sorter: (a: any, b: any) =>
        a.firstsessiondate.length - b.firstsessiondate.length,
      sortOrder:
        sortedInfo.columnKey === "firstsessiondate" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.firstsessiondate === false ? "display-none" : "",
    },
    {
      title: "Upcoming session date ",
      dataIndex: "timesPerWeek",
      key: "timesPerWeek",
      render: (text: any, record: any, index: any) =>
        text === "one_time"
          ? moment(record?.oneTimeSessionSchedule?.endTime).format("MM-DD-YYYY")
          : text === "flexible"
          ? moment(record?.flexibleSessionSchedule?.endDate).format(
              "MM-DD-YYYY"
            )
          : moment(record?.recurringSessionSchedule?.endDate).format(
              "MM-DD-YYYY"
            ),
      sorter: (a: any, b: any) =>
        a.upcomingsessiondate.length - b.upcomingsessiondate.length,
      sortOrder:
        sortedInfo.columnKey === "upcomingsessiondate"
          ? sortedInfo.order
          : null,
      hidden: true,
      className:
        hiddenColumns.upcomingsessiondate === false ? "display-none" : "",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any, index: any) => (
        <Space>
          <img
            src={editblackPencil}
            width={20}
            className="cursor_pointer"
            onClick={() => {
              setSessionValues(record);
              setIsModalVisible(true);
            }}
          />
        </Space>
      ),
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === "action" ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.action === false ? "display-none" : "",
    },
  ];

  const onChangeColumnCheckBox = (e: any) => {
    console.log(`checked = ${e.target.name}`);
    setHiddenColumns({
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    });
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //     props.setSelectedRows(selectedRows);
  //   },
  //   getCheckboxProps: (record: any) => ({
  //     name: record.name,
  //   }),
  // };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log(e.key);
  };

  const menu2 = (
    <Menu
      onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={!hiddenColumns.firstName}
                  name="family"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Family</Col>
            </Row>
          ),

          key: "1",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="mentor"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Mentor</Col>
            </Row>
          ),
          key: "2",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="categoryskill"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Category Skill</Col>
            </Row>
          ),
          key: "3",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="hrs"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Hours</Col>
            </Row>
          ),
          key: "4",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="firstsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>First Session date</Col>
            </Row>
          ),
          key: "5",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="upcomingsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Upcoming Session date</Col>
            </Row>
          ),
          key: "6",
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={true}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: "7",
        },
      ]}
    />
  );

  return (
    <div className="mt-20 sessions-data-table">
      <Row align="middle">
        <Col xl={6} lg={8} span={24}>
          <Space size={10} align="center" className="mentors-table-header">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div className={"search-input-box"}>
                <input
                  type="search"
                  className="search-input-group"
                  placeholder="Search"
                  // onChange={(e: any) => search(e.target.value)}
                />
                <SearchOutlined />
              </div>
            </div>
          </Space>
        </Col>
        <Col xl={14} lg={12} span={20}>
          <Space size={20}>
            <Select
              defaultValue="firstname"
              placeholder="sort by"
              style={{ width: 120 }}
              allowClear
              onChange={handleChangeSortName}
            >
              <Option value="firstname">Family: All</Option>
              <Option value="email">Email</Option>
            </Select>
            <Select
              defaultValue="firstname"
              placeholder="sort by"
              style={{ width: 120 }}
              allowClear
              onChange={handleChangeSortName}
            >
              <Option value="firstname">Type: All</Option>
              <Option value="email">Email</Option>
            </Select>
            <Button className="btn-item">More filters</Button>
          </Space>
        </Col>
        <Col span={4} className="text-right">
          <Button
            className="outlined-button br-8 blue-border-btn"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            New session
          </Button>
        </Col>
        <Col span={24}>
          <Table
            style={{ width: "100%" }}
            className="pl-20"
            // rowSelection={rowSelection}
            columns={columnss}
            dataSource={allSesionData?.records}
          />
        </Col>
      </Row>
      {isModalVisible && (
        <CreateSession
          sessionValues={sessionValues}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  allSesionData: state.session.allSesionData,
  familyData: state.family.familyData,
  mentorsData: state.mentor.mentorsData,
  eventCategoryData: state.events.eventCategoryData,
});

export default connect(mapStateToProps)(MentorSessionTable);
