import { Modal, Form, Col, notification } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import userAvatarSvg from "../../../assets/Images/userAvatar.svg";
import actions from "../../../Redux/Admin/action";
import { useDispatch } from "react-redux";
import FileUploader from "../../../helper/FileUploader";
interface UploadphotoInt {
  id: any;
  notvisible: any;
}
const openNotification = (message: string, description: string) => {
  notification.open({
    message: message,
    description: description,
  });
};
const UploadPhoto: React.FC<UploadphotoInt> = ({ id, notvisible }) => {
  const [avatar, setAvatar] = useState("");
  const [open, setOpen] = useState<boolean>(true);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    values.photoId
      ? dispatch({
          type: actions.PATCH_ADMIN_DETAILS,
          payload: {
            userId: id,
            formData: {
              photoId: `${values.photoId}`,
            },
          },
        })
      : openNotification("Image not found", "Please upload your image");
    avatar ? notvisible(false) : <></>;
  };

  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };
  return (
    <div className="changeModal ">
      <Modal visible={open} className={"popup-modal"} onCancel={handleCancel}>
        <div>
          <div className="popup-header">
            <h2>Upload your Image</h2>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>
          <div className="img-prev">
            <div className="image-area">
              <img
                className="avatar-image"
                src={avatar ? avatar : userAvatarSvg}
                alt="user_image"
              />
              <Form form={form} onFinish={onFinish}>
                <Col span={4} className="mb-16">
                  <Form.Item name="photoId">
                    <FileUploader
                      name="admin"
                      setAvatar={setAvatar}
                      form={form}
                    />
                  </Form.Item>
                </Col>
                <div className="popup-footer">
                  <button className="btn-sec " onClick={handleCancel}>
                    Cancel
                  </button>
                  <button className="btn-prim" type="submit">
                    Done
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default UploadPhoto;
// {!isImage ? (
//       <div>
//         <div className="popup-header">
//           <h2>Add an account photo</h2>
//           <img src={Cancel} alt="close button" />
//         </div>

//         <div
//           className="upload-main"
//           onDrop={FileUploader}
//           onDragOver={handleDragOver}
//         >
//           {/* <div className="upload-area ">
//             {isLoading ? (
//               <h3 className="upload-title">Uploading....</h3>
//             ) : (
//               <h3 className="upload-title">
//                 Drag and drop or
//                 <label className="link">
//                   <FileUploader name="UserImage" setAvatar={setisImage} />
//                   upload from your computer
//                 </label>
//                 <span></span>
//               </h3>
//             )}
//           </div> */}
//         </div>
//         <div className="mt-20"></div>
//       </div>
//     ) : (        )}
