import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Col, Select } from 'antd'
import { Menu, Dropdown, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { Table } from 'antd'
import { Avatar, Image } from 'antd'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import filterSvg from '../../../assets/Images/filter.svg'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import { Option } from 'antd/lib/mentions'
import type { MenuProps } from 'antd'
import type { SorterResult } from 'antd/es/table/interface'
import { SearchOutlined } from '@ant-design/icons'
import CreateSession from '../../Modals/Sessions/CreateSession'
import sessionAction from '../../../Redux/Session/action'
import familyAction from '../../../Redux/Family/action'
import mentorAction from '../../../Redux/mentors/action'
import categoryAction from '../../../Redux/Events/action'
import moment from 'moment'
import { setPaginationObject } from '../../../helper/utility'

function SessionTable({
  allSesionData,
  familyData,
  mentorsData,
  eventCategoryData,
  setSelectedRows,
  totalCount,
}: any) {
  let initPaginationInfo = {
    total: totalCount,
    current: 1,
    sort: {
      field: 'firstname',
      order: 'asc',
    },
    showTotal: (total: any, range: any) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [visible, setVisible] = useState(false)
  const [sessionValues, setSessionValues] = useState({})
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)
  const [hiddenColumns, setHiddenColumns] = useState<any>({
    family: true,
    mentor: true,
    categoryskill: true,
    totalhours: true,
    firstsessiondate: true,
    upcomingsessiondate: true,
    action: true,
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: totalCount,
    })
  }, [totalCount])

  useEffect(() => {
    dispatch({
      type: sessionAction.GET_SESSION_DATA_REQUEST,
    })
    // dispatch({
    //   type: familyAction.GET_FAMILIES,
    // });
    // dispatch({
    //   type: mentorAction.GET_MENTORS,
    // });
    dispatch({
      type: categoryAction.GET_EVENT_CATEGORIES,
    })
  }, [])

  useEffect(() => {
    // console.log("isModalVisible:",isModalVisible,!isModalVisible)
    if (!isModalVisible) setSessionValues({})
  }, [isModalVisible])

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleChangeSortName = (value: string) => {
    setSortedInfo({
      order: 'descend',
      columnKey: value,
    })
  }

  const columnss = [
    {
      title: 'Family',
      dataIndex: 'familyId',
      key: 'familyId',
      render: (text: any, record: any) => (
        console.log('text id:', text, record),
        (
          <div style={{ cursor: 'pointer' }}>
            <Space>
              {record.family?.photo?.post && (
                <Avatar src={record.family?.photo?.post} size={38}></Avatar>
              )}
              {record.family?.name}
            </Space>
            {/* <Space>
            {familyData &&
              familyData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.name)
                .toString() && (
                <Avatar
                  src={
                    familyData &&
                    familyData
                      ?.filter((item: any) => item._id === text)
                      .map((elm: any) => elm?.photo?.original)
                      .toString()
                  }
                  size={38}
                ></Avatar>
              )}
            {familyData &&
              familyData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.name)
                .toString()}
          </Space> */}
          </div>
        )
      ),
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'family' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.family === false ? 'display-none' : '',
    },
    {
      title: 'Mentor',
      dataIndex: 'mentorId',
      key: 'mentorId',
      render: (text: any, record: any, index: any) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push({
              pathname: '/sessiondetails',
              state: {
                contractRoomId: record?.contractRoomId,
              },
            })
          }}
        >
          <Space>
            {record.mentor?.photo?.post && (
              <Avatar src={record.mentor?.photo?.post} size={38}></Avatar>
            )}
            {record.mentor?.firstName}
          </Space>
          {/* <Space>
            {mentorsData &&
              mentorsData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.firstName)
                .toString() && (
                <Avatar
                  src={
                    mentorsData &&
                    mentorsData
                      ?.filter((item: any) => item._id === text)
                      .map((elm: any) => elm?.photo?.original)
                      .toString()
                  }
                  size={32}
                ></Avatar>
              )}
            {mentorsData &&
              mentorsData
                ?.filter((item: any) => item._id === text)
                .map((elm: any) => elm?.firstName)
                .toString()}
          </Space> */}
        </div>
      ),
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'mentor' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.mentor === false ? 'display-none' : '',
    },
    {
      title: 'Category/skill',
      dataIndex: 'categoryId',
      key: 'categoryId',
      render: (text: any, record: any, index: any) => (
        <Button shape="round" size="small" className="secondary">
          {eventCategoryData &&
            eventCategoryData
              ?.filter((item: any) => item._id === text)
              .map((elm: any) => elm?.name)
              .toString()}
        </Button>
      ),
      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'categoryskill' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.categoryskill === false ? 'display-none' : '',
    },
    {
      title: 'Hours',
      dataIndex: 'hrs',
      key: 'hrs',
      render: (text: any, record: any, index: any) => '-',
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'hrs' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.hrs === false ? 'display-none' : '',
    },
    {
      title: 'First session date',
      dataIndex: 'timesPerWeek',
      key: 'timesPerWeek',
      render: (text: any, record: any, index: any) =>
        text === 'one_time'
          ? moment(record?.oneTimeSessionSchedule?.startTime).format(
              'MM-DD-YYYY',
            )
          : text === 'flexible'
          ? moment(record?.flexibleSessionSchedule?.startDate).format(
              'MM-DD-YYYY',
            )
          : moment(record?.recurringSessionSchedule?.startDate).format(
              'MM-DD-YYYY',
            ),
      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'firstsessiondate' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.firstsessiondate === false ? 'display-none' : '',
    },
    {
      title: 'Upcoming session date ',
      dataIndex: 'timesPerWeek',
      key: 'timesPerWeek',
      render: (text: any, record: any, index: any) =>
        text === 'one_time'
          ? moment(record?.oneTimeSessionSchedule?.endTime).format('MM-DD-YYYY')
          : text === 'flexible'
          ? moment(record?.flexibleSessionSchedule?.endDate).format(
              'MM-DD-YYYY',
            )
          : moment(record?.recurringSessionSchedule?.endDate).format(
              'MM-DD-YYYY',
            ),
      sorter: false,
      sortOrder:
        sortedInfo.columnKey === 'upcomingsessiondate'
          ? sortedInfo.order
          : null,
      hidden: true,
      className:
        hiddenColumns.upcomingsessiondate === false ? 'display-none' : '',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: any, index: any) => (
        <Space>
          <img
            src={editblackPencil}
            width={20}
            className="cursor_pointer"
            onClick={() => {
              setSessionValues(record)
              setIsModalVisible(true)
            }}
          />
        </Space>
      ),
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'action' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hiddenColumns,
      [e.target.name]: e.target.checked,
    }

    setHiddenColumns(updatedHiddenColumns)
    localStorage.setItem('SessionCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem('SessionCols')
    if (storedHiddenColumns) {
      setHiddenColumns(JSON.parse(storedHiddenColumns))
    }
  }, [])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // props.setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    console.log(e.key)
  }

  const menu2 = (
    <Menu
      onClick={handleMenuClick}
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.family}
                  name="family"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Family</Col>
            </Row>
          ),

          key: '1',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.mentor}
                  name="mentor"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Mentor</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.categoryskill}
                  name="categoryskill"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Category Skill</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.totalhours}
                  name="hrs"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Hours</Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.firstsessiondate}
                  name="firstsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>First Session date</Col>
            </Row>
          ),
          key: '5',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.upcomingsessiondate}
                  name="upcomingsessiondate"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Upcoming Session date</Col>
            </Row>
          ),
          key: '6',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox
                  defaultChecked={hiddenColumns.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={20}>Action</Col>
            </Row>
          ),
          key: '7',
        },
      ]}
    />
  )

  const onTableChange = (...rest: any) => {
    const newObject: any = [...rest]
    let newPaginationInfo = setPaginationObject.apply(paginationInfo, newObject)
    getSessions(newPaginationInfo)
    setPaginationInfo(newPaginationInfo)
  }

  const getSessions = (newPaginationInfo: any) => {
    dispatch({
      type: sessionAction.GET_SESSION_DATA_REQUEST,
      params: newPaginationInfo,
    })
  }

  return (
    <div className="mt-20">
      <Row align="middle">
        <Col span={24}>
          <Space size={20} align="center" className="mentors-table-header">
            <Dropdown
              overlay={menu2}
              onVisibleChange={handleVisibleChange}
              visible={visible}
            >
              <h5 onClick={e => e.preventDefault()}>
                <Space>
                  <img src={filterSvg} alt=""></img>
                </Space>
              </h5>
            </Dropdown>
            <Select
              defaultValue="firstname"
              placeholder="sort by"
              allowClear
              onChange={handleChangeSortName}
              className="custom-select-field"
            >
              <Option value="firstname">Sort by: Name</Option>
              <Option value="email">Sort by: Email</Option>
            </Select>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <div className={'search-input-box mr-0'}>
                <input
                  type="search"
                  className="search-input-group"
                  placeholder="Search"
                  // onChange={(e: any) => search(e.target.value)}
                />
                <SearchOutlined />
              </div>
            </div>
          </Space>
        </Col>

        <Table
          rowSelection={rowSelection}
          style={{ width: '100%' }}
          className="organizations_table"
          // rowSelection={rowSelection}
          columns={columnss}
          dataSource={allSesionData?.records}
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
      {isModalVisible && (
        <CreateSession
          sessionValues={sessionValues}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  allSesionData: state.session.allSesionData,
  familyData: state.family.familyData,
  mentorsData: state.mentor.mentorsData,
  eventCategoryData: state.events.eventCategoryData,
  totalCount: state.session.totalCount,
})

export default connect(mapStateToProps)(SessionTable)
