import { Row } from 'antd'
import OrganizationDetails from './OrganizationDetails'
import Statistic from './Statistic'
const OrganizationBasicInfo = ({ parent, refetch, analytics }: any) => {
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <OrganizationDetails data={parent} refetchData={refetch} />
      <Statistic data={analytics} refetchData={refetch} />
    </Row>
  )
}
export default OrganizationBasicInfo
