import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { getEventFaqs } from 'api/Events'
import Spinner from 'components/Spinner'
import Cancel from 'assets/Images/close.svg'

// @ts-ignore
import Faq from 'react-faq-component'

interface FAQS {
  answer: string
  createdAt: Date
  event: null | any
  id: string
  organizationId: string
  question: string
  updatedAt: Date
  _id: string
}
interface model {
  visible: boolean
  setVisible: any
  eventId: string
}
const FaqModal: React.FC<model> = ({ visible, setVisible, eventId }) => {
  const [faqs, setFaqs] = useState<FAQS[]>([])
  const handleOk = () => {
    setVisible(false)
  }
  const handleCancel = () => {
    setVisible(false)
  }
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['Event-faqs'],
    queryFn: () => getEventFaqs(eventId),
    enabled: false,
  })
  useEffect(() => {
    refetch()
  }, [eventId])
  useEffect(() => {
    setFaqs(data)
  }, [data])
  // defining faq data
  const FAQS = {
    rows: faqs?.map((item: FAQS) => {
      return {
        title: item.question + '?',
        content: item.answer,
      }
    }),
  }
  const styles = {
    rowTitleColor: '#000',
    rowTitleTextSize: '14px',
    arrowColor: '#000',
    rowContentColor: '#9CA5B0',
    rowContentTextSize: '14px',
    rowContentPaddingBottom: '10px',
  }
  const config = {
    animate: true,
    tabFocus: true,
  }
  return (
    <div>
      <Modal
        className="common_model"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        maskClosable={true}
        width={600}
      >
        <div className="popup-header  border-btm">
          <h2> Event FAQ's</h2>

          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <div className="px-30 py-30">
          <div>
            {isLoading || isFetching ? (
              <div className="flex-center justify-center mt-20px">
                <Spinner />
              </div>
            ) : (
              <div className="mt-10">
                <Faq data={FAQS} styles={styles} config={config} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default FaqModal
