import React from 'react'
import { Envelope, PencilLine, Trash } from '@phosphor-icons/react'
import { EmailTemplateResp } from 'types/globalTypes'
import ButtonSpinner from 'components/global/ButtonSpinner'

interface TemplateInfoProps {
  template: EmailTemplateResp
  loaderId: string
  isEventTemplate: boolean
  handleAddToEvent: (id: string, operation: 'add' | 'remove') => void
  handleEditTemplate: (template: EmailTemplateResp) => void
}

const TemplateInfo: React.FC<TemplateInfoProps> = ({
  template,
  loaderId,
  isEventTemplate,
  handleAddToEvent,
  handleEditTemplate,
}) => {
  return (
    <div className="flex align-center justify-between gap-20px mt-10px">
      <div className="flex align-center gap-16px mt-10px">
        <Envelope size={30} color="#2ba7ca" />
        <div>
          <h3 className="text-14 w-600">{template.templateName}</h3>
          <p className="text-14 w-400">{template.description}</p>
        </div>
      </div>
      <div className="flex align-center gap-16px">
        {isEventTemplate && (
          <PencilLine
            size={20}
            color="#000"
            onClick={() => handleEditTemplate(template)}
          />
        )}
        <button
          className="borderd-btn"
          onClick={() =>
            handleAddToEvent(template._id, isEventTemplate ? 'remove' : 'add')
          }
        >
          {loaderId === template._id ? (
            <ButtonSpinner size={20} color="#2ba7ca" />
          ) : isEventTemplate ? (
            'Remove from event'
          ) : (
            'Add to event'
          )}
        </button>
      </div>
    </div>
  )
}

export default TemplateInfo
