import { Pie } from '@ant-design/plots'

const PieChart = ({ data, angle, color, name }) => {
  const config = {
    appendPadding: 10,
    color: [
      '#2459A8',
      '#ABC5ED',
      '#FF708B',
      '#FFB969',
      '#44D6B5',
      '#96EDCC',
      '#32C3FD',
    ],

    data,
    angleField: angle,
    colorField: color,

    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 11,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,

      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: `<h4 className="text-10px">${name}</h4>`,
      },
    },
  }
  return <Pie {...config} />
}
export default PieChart
