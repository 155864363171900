// import React, { useState } from 'react'
// import moment from 'moment'
// import CustomBigCalendar from './Calender'
// import CustomToolbar from './ToolBar'
// import EventSceduleTimings from './EventSchedule'
// import { convertMinutesToLocalTime } from 'helper/utility'
// import { useLocation } from 'react-router-dom'
// import { Select, Space } from 'antd'
// import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
// const Schedule = ({ event }) => {
//   const location = useLocation()
//   const events = event?.activitiesSchedule?.flatMap((event: any) => {
//     return event?.days?.map((day: any) => {
//       const startDate = moment(day).toDate()
//       const endTime = convertMinutesToLocalTime(
//         event?.duration + event?.startTime,
//       )
//       const endTimeWithDate = moment(startDate)
//         .add(event?.duration, 'minutes')
//         .toDate()
//       return {
//         start: moment(day).toDate(),
//         end: endTimeWithDate,
//         title: event?.name,
//         color:
//           event?.name === 'Check-in'
//             ? '#FDEAD2'
//             : event?.name === 'Check-out'
//             ? '#DBDDF2'
//             : event?.name === 'Breakfast'
//             ? '#8CC73F33'
//             : event?.name === 'Lunch'
//             ? '#D5EDF4'
//             : event?.name === 'Dinner'
//             ? '#E8769F33'
//             : '#CFF49F',
//       }
//     })
//   })
//   const eventStyleGetter = (event: any) => {
//     const style = {
//       backgroundColor: event.color,
//       borderRadius: '0px',
//       opacity: 1,
//       color: '#000',
//       display: 'block',
//       border: 'none',
//     }
//     return {
//       style,
//     }
//   }
//   const [selectedView, setSelectedView] = useState<any>('day')
//   const handleViewChange = (newView: any) => {
//     setSelectedView(newView)
//   }
//   const currentDate = moment().startOf('day')
//   const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)
//   const eventStartDate = moment
//     .utc(event?.datesOfEvent[0])
//     .utcOffset(0)
//     .toDate()
//   const eventStartTime = moment(
//     convertMinutesToLocalTime(event?.timings?.startTime),
//     'h:mm A',
//   )
//   const eventStartTimeObj = {
//     hour: eventStartTime.hour(),
//     minute: eventStartTime.minute(),
//     second: 0,
//   }
//   eventStartDate.setHours(eventStartTimeObj.hour)
//   eventStartDate.setMinutes(eventStartTimeObj.minute)
//   const eventEndDate = moment
//     .utc(event?.datesOfEvent[event?.datesOfEvent?.length - 1])
//     .toDate()
//   const eventEndTime = moment(
//     convertMinutesToLocalTime(event?.timings?.endTime),
//     'h:mm A',
//   )
//   const eventEndTimeObj = {
//     hour: eventEndTime.hour(),
//     minute: eventEndTime.minute(),
//     second: 0,
//   }
//   eventEndDate.setHours(eventEndTimeObj.hour)
//   eventEndDate.setMinutes(eventEndTimeObj.minute)
//   console.log(eventStartDate, eventEndDate)
//   return (
//     <div className="bg-grey container padding-top-10">
//       <div className="big-calendar  pt-40px px-30px bg-white">
//         <div className="flex justify-between pb-40px">
//           <h2 className="text-20 w-600">Event schedule</h2>
//           <Select
//             className=""
//             defaultValue="day"
//             onChange={handleViewChange}
//             options={[
//               {
//                 value: 'day',
//                 label: 'Day',
//               },
//               {
//                 value: 'week',
//                 label: 'Week',
//               },
//             ]}
//           />
//         </div>
//         <Space size={10}></Space>
//         <div
//           className={`mt-20px ${selectedView === 'day' && 'grid'}`}
//           style={{ gridTemplateColumns: '25% 65%', gap: '5%' }}
//         >
//           {selectedView === 'day' ? (
//             <div
//               className={`calendar-customzation`}
//               style={{ marginTop: '-45px' }}
//             >
//               <MultiDateSelectCalendar
//                 selectedDate={selectedDate}
//                 setSelectedDate={setSelectedDate}
//                 dates={event?.datesOfEvent}
//               />{' '}
//               <div className={`mt-40`}>
//                 <EventSceduleTimings
//                   datesOfevent={event?.datesOfEvent}
//                   eventTiming={event?.timings}
//                   selectedDate={selectedDate}
//                 />
//               </div>
//             </div>
//           ) : null}
//           <div
//             className={`detail-schedule ${
//               selectedView !== 'day'
//                 ? `dayView weekView`
//                 : `sceduleRightContainer hide-header`
//             }`}
//             style={{ height: '620px' }}
//           >
//             <CustomBigCalendar
//               selectable
//               min={eventStartDate}
//               max={eventEndDate}
//               defaultView={'day'}
//               date={selectedDate}
//               views={['day', 'week']}
//               view={selectedView}
//               events={events}
//               components={{
//                 toolbar: CustomToolbar,
//               }}
//               formats={{
//                 dayHeaderFormat: (date: Date) =>
//                   moment(date).format('DD MMM,YYYY'),
//               }}
//               eventPropGetter={eventStyleGetter}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
// export default Schedule

import React, { useState } from 'react'
import moment from 'moment'
import CustomBigCalendar from './Calender'
import CustomToolbar from './ToolBar'
import EventSceduleTimings from './EventSchedule'
import { convertMinutesToLocalTime } from 'helper/utility'
import { useLocation } from 'react-router-dom'
import { Select, Space } from 'antd'
import MultiDateSelectCalendar from 'helper/MultiDateSelectCalendar'
const Schedule = ({ event }) => {
  const location = useLocation()

  const events = event?.activitiesScheduleV2?.flatMap((event: any) => {
    return event?.days?.map((day: any) => {
      const startDate = moment(day).toDate()
      const endTime = convertMinutesToLocalTime(
        event?.duration + event?.startTime,
      )
      const endTimeWithDate = moment(startDate)
        .add(event?.duration, 'minutes')
        .toDate()
      return {
        start: moment(day).toDate(),
        end: endTimeWithDate,
        title: event?.name,
        color:
          event?.name === 'Check-in'
            ? '#FDEAD2'
            : event?.name === 'Check-out'
            ? '#DBDDF2'
            : event?.name === 'Breakfast'
            ? '#8CC73F33'
            : event?.name === 'Lunch'
            ? '#D5EDF4'
            : event?.name === 'Dinner'
            ? '#E8769F33'
            : '#CFF49F',
      }
    })
  })
  const eventStyleGetter = (event: any) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: '0px',
      opacity: 1,
      color: '#000',
      display: 'block',
      border: 'none',
    }
    return {
      style,
    }
  }
  const [selectedView, setSelectedView] = useState<any>('day')
  const handleViewChange = (newView: any) => {
    setSelectedView(newView)
  }
  const currentDate = moment().startOf('day')
  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)
  const eventStartDate = moment
    .utc(event?.datesOfEvent[0])
    .utcOffset(0)
    .toDate()
  const eventStartTime = moment(
    convertMinutesToLocalTime(event?.timings?.startTime),
    'h:mm A',
  )
  const eventStartTimeObj = {
    hour: eventStartTime.hour(),
    minute: eventStartTime.minute(),
    second: 0,
  }
  eventStartDate.setHours(eventStartTimeObj.hour)
  eventStartDate.setMinutes(eventStartTimeObj.minute)
  const eventEndDate = moment
    .utc(event?.datesOfEvent[event?.datesOfEvent?.length - 1])
    .toDate()
  const eventEndTime = moment(
    convertMinutesToLocalTime(event?.timings?.endTime),
    'h:mm A',
  )
  const eventEndTimeObj = {
    hour: eventEndTime.hour(),
    minute: eventEndTime.minute(),
    second: 0,
  }
  eventEndDate.setHours(eventEndTimeObj.hour)
  eventEndDate.setMinutes(eventEndTimeObj.minute)
  return (
    <div className="bg-grey container padding-top-10">
      <div className="big-calendar  pt-40px px-30px bg-white">
        <div className="flex justify-between pb-40px">
          <h2 className="text-20 w-600">Event schedule</h2>
          <Select
            className=""
            defaultValue="day"
            onChange={handleViewChange}
            options={[
              {
                value: 'day',
                label: 'Day',
              },
              {
                value: 'week',
                label: 'Week',
              },
            ]}
          />
        </div>
        <Space size={10}></Space>
        <div
          className={`mt-20px ${selectedView === 'day' && 'grid'}`}
          style={{ gridTemplateColumns: '25% 65%', gap: '5%' }}
        >
          {selectedView === 'day' ? (
            <div
              className={`calendar-customzation`}
              style={{ marginTop: '-45px' }}
            >
              <MultiDateSelectCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                dates={event?.datesOfEvent}
              />{' '}
              <div className={`mt-40`}>
                <EventSceduleTimings
                  datesOfevent={event?.datesOfEvent}
                  eventTiming={event?.timings}
                  selectedDate={selectedDate}
                />
              </div>
            </div>
          ) : null}
          <div
            className={`detail-schedule ${
              selectedView !== 'day'
                ? `dayView weekView`
                : `sceduleRightContainer hide-header`
            }`}
            style={{ height: '620px' }}
          >
            <CustomBigCalendar
              selectable
              min={eventStartDate}
              max={eventEndDate}
              defaultView={'day'}
              date={selectedDate}
              views={['day', 'week']}
              view={selectedView}
              events={events}
              components={{
                toolbar: CustomToolbar,
              }}
              formats={{
                dayHeaderFormat: (date: Date) =>
                  moment(date).format('DD MMM,YYYY'),
              }}
              eventPropGetter={eventStyleGetter}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Schedule
