import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Radio,
  Select,
  DatePicker,
  Row,
  Input,
  Col,
  message,
  Upload,
  Space,
  TimePicker,
  Avatar,
} from "antd";
import { Layout } from "antd";
import "antd-country-phone-input/dist/index.css";
import { useDispatch } from "react-redux";
import newsFeedAction from "../../../../Redux/NewsFeed/action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PlusOutlined } from "@ant-design/icons";
import getBase64 from "../../../../helper/getBase64";
import actions from "../../../../Redux/Organization/action";
import { uuidv4 } from "@antv/xflow-core";
import ImageCrop from "antd-img-crop";

const { Option } = Select;

const PostAdd = ({ organizationData, editData , handleCancel }: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(editData.photo || "");
  const isEditingPost = editData?.length !== 0

  useEffect(() => {
    dispatch({
      type: actions.GET_ORGANIZATIONS,
    });
  }, [dispatch]);

  const handleChangePhoto = (info: any) => {
    getBase64(info.file.originFileObj, (url: any) => {
      setLoading(false);
      setImageUrl(url);
      form.setFieldsValue({
        photo: url,
      });
    });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </>
      )}
    </div>
  );

  const formSchema = {
    organization: editData?.organization?.name || "",
    title: editData?.title || "",
    describe: editData?.describe || "",
    photo: editData?.photo || "",
    schedule: false,
    schedule_date: "",
    schedule_time: "",
  };

  const onFinish = (values: any) => {
    const organization = JSON.parse(values.organization);
    const payload = {
      organizationId: organization?.orgId,
      title: values.title,
      text: values.describe,
    }
    dispatch({
      type: newsFeedAction.ADD_NEWSFEED_DATA,
      payload: payload,
    });
    message.success("completed");
    handleCancel();

    // if (isEditingPost){
    //   data.id = editData?.id
    // }

    // let newsFeedData = [];
    // newsFeedData =
    //   JSON.parse(localStorage.getItem("newsFeedData") || "[]") || [];
    // if (editData?.id) {
    //   newsFeedData.forEach((item: any, index: any) => {
    //     if (item.id === data.id) {
    //       newsFeedData[index] = data;
    //     }
    //   });
    // } else {
    //   newsFeedData.push(data);
    // }
    // message.success("completed");

    // handleCancel();
  };

  return (
    <Layout>
      <Form
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={formSchema}
        layout={"vertical"}
        autoComplete="off"
      >
        <Row>
          <Col span={12}>
            <label htmlFor="" className="input-label required-input">
              Select organization
            </label>
            <Form.Item
              colon={false}
              name="organization"
              rules={[{ required: Array.isArray(editData) ? true : false }]}
            >
              <Select disabled={!Array.isArray(editData)} className="select">
                {organizationData.map((item: any) => {
                  return (
                    <Option
                      key={item.id}
                      name="organization"
                      value={JSON.stringify({
                        orgId: item.id,
                        name: item.name,
                        avatar: item.photo?.post,
                      })}

                    >
                      <Avatar src={item.photo?.post}></Avatar> {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label htmlFor="" className="input-label required-input">
              Title
            </label>
            <Form.Item colon={false} name="title" rules={[{ required: true }]}>
              <Input
                style={{ width: "100%" }}
                placeholder="News Tittle"
                required
                name="title"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <label htmlFor="" className="input-label">
            Describe the information to be posted
          </label>
          <Form.Item name="describe">
            <ReactQuill
              style={{ width: "519px" }}
              onChange={(value: any) => {
                form.setFieldsValue({
                  describe: value,
                });
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Col>
            <label htmlFor="" className="input-label ">
              Attach photo(s) or file(s)
            </label>
            <Form.Item name="photo">
              <ImageCrop>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  onChange={handleChangePhoto}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </ImageCrop>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="schedule">
          <Radio>Schedule the post</Radio>
        </Form.Item>
        <Row gutter={24} align="middle">
          <Col span={8}>
            <h5>Select date & time</h5>
          </Col>
          <Col span={12}>
            <Space size="small">
              <Form.Item name="schedule_date">
                <DatePicker className="date-picker" />
              </Form.Item>
              <Form.Item name="schedule_time">
                <TimePicker></TimePicker>
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="stepper-button"
            size={"large"}
          >
            {isEditingPost ? "Update" : "Post"}
          </Button>
        </Row>
      </Form>
    </Layout>
  );
};
export default PostAdd;
