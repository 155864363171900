import React, { useState } from 'react'
import { DatePicker, Form, Select, TimePicker } from 'antd'

interface CustomTimeProps {
  setIsCustomTime: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomTime: React.FC<CustomTimeProps> = ({ setIsCustomTime }) => {
  const hanldeChange = (value: string) => {
    if (value === 'customTimeToSend') {
      setIsCustomTime(true)
    } else {
      setIsCustomTime(false)
    }
  }
  return (
    <div className="flex gap-16px align-center w-full">
      <div style={{ width: '35%' }}>
        <Form.Item name="date">
          <DatePicker
            className="w-100 form-control-field"
            placeholder="Date"
            // disabledDate={}
          />
        </Form.Item>
      </div>

      <div style={{ width: '35%' }}>
        <Form.Item name="time">
          <TimePicker
            format={'hh:mm a'}
            className="w-100 form-control-field"
            placeholder="Time"
          />
        </Form.Item>
      </div>

      <div style={{ width: '30%' }}>
        <Form.Item className="pointer selector-input" name="conditionType">
          <Select
            className="select-input-field type-select-field"
            placeholder="Select"
            optionLabelProp="label"
            onChange={hanldeChange}
            options={[
              {
                value: 'event-registration',
                label: 'Event registration',
              },
              {
                value: 'registration-edit',
                label: 'Registration edit',
              },
              {
                value: 'ticket-start-date',
                label: 'Start date',
              },
              {
                value: 'ticket-end-date',
                label: 'End date',
              },
              {
                value: 'customTimeToSend',
                label: 'Custom Time To Send',
              },
            ]}
          />
        </Form.Item>
      </div>
    </div>
  )
}

export default CustomTime
