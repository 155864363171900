import { PaginationProps } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import actions from 'Redux/Family/action'

interface PaginationTypes {
  totalCount: number
  currentPage: number
  setParams: (key: any) => void
  status?: 'active' | 'pending' | 'archived' | 'under_review' | 'blocked'
}

interface InitPagination {
  total: number
  current: number
  limit: number
  showTotal: (total: number, range: [number, number]) => string
  showSizeChanger: boolean
  pageSizeOptions: string[]
}

const usePagination = ({
  totalCount,
  currentPage,
  setParams,
  status,
}: PaginationTypes) => {
  const dispatch = useDispatch();
  const initPaginationInfo: InitPagination = {
    total: totalCount,
    current: currentPage,
    limit: 10,
    showTotal: (total: number, range: [number, number]) =>
      `Showing ${range[0]} to ${range[1]} of ${total} records`,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }

  const [paginationInfo, setPaginationInfo] =
    useState<InitPagination>(initPaginationInfo)
  useEffect(() => {
    setPaginationInfo({
      ...paginationInfo,
      total: totalCount,
    })
  }, [totalCount])
  const onTableChange: any = (pagination: any) => {
    const newPaginationInfo = {
      ...paginationInfo,
      current: pagination.current,
      pageSize: pagination.pageSize || 10,
    }
    setPaginationInfo(newPaginationInfo)
    setParams((prevParams: any) => ({
      ...prevParams,
      page: pagination.current,
      limit: pagination.pageSize,
      ...(status !== undefined && { status: status }),
    }))
    dispatch({type: actions.CURRENT_PAGE, payload: pagination.current})
  }
  const PaginationNoTabel: PaginationProps['onChange'] = page => {
    setPaginationInfo({
      ...paginationInfo,
      current: page,
    })
    setParams((prevParams: any) => ({
      ...prevParams,
      page: page,
      ...(status !== undefined && { status: status }),
    }))
  }

  return [paginationInfo, onTableChange, PaginationNoTabel]
}

export default usePagination
