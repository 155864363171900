import OrganizationModal from '../../../Modals/OrganizationModal'
import AppLayout from '../../../../components/layout/layout'
import { useLocation, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Avatar,
  Button,
  Space,
  Tabs,
  Dropdown,
  Menu,
  Tooltip,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Basic from './Basic'
import Events from './Events'
import Reviews from './Reviews'
import deleteSvg from '../../../../assets/Images/delete_red.svg'
import pencilLine from '../../../../assets/Images/PencilLine.svg'
import LockKey from '../../../../assets/Images/LockKey.svg'
import actions from '../../../../Redux/mentors/action'
import StatusDropdown from '../../../../helper/StatusDropdown'
import Availablity from './Availablity'
import Sessions from './Sessions'
import { useQuery } from '@tanstack/react-query'
import { getMentorProfile } from 'api/mentors'
import { GetMentorProfile } from './types'
import { capitalizeFirstLetter } from 'helper/utility'
import { useEffect } from 'react'
import MentorBasicInfo from '../Mentor/BasicInfo/index'
import { getNameSignature } from 'helper/utility'
const { TabPane } = Tabs
const MentorProfile = () => {
  const location: any = useLocation()
  let { id }: any = useParams()

  const { data, isLoading, refetch } = useQuery<GetMentorProfile>(
    ['getMentorProfile', id],
    () => {
      return getMentorProfile(id)
    },
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [])
  const actionMenu = (
    <Menu>
      <Menu.Item key="changePassword">
        <Space>
          <img alt="img" src={LockKey}></img> Change Password
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Editprofile"
        // onClick={() => {
        //   dispatch({ type: actions.MENTORS_MODAL_VISIBLE, payload: true })
        // }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        // onClick={() => {
        //   dispatch({
        //     type: actions.DELETE_FAMILY_MEMBERS_DATA,
        //     payload: family.id,
        //   });
        // }}
      >
        <Space>
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <AppLayout id={id}>
      <div className=" bg-grey container">
        <div className="org-profile-header">
          <Row gutter={10}>
            <Col xl={{ span: 10 }}>
              <Row align="middle">
                <Col span={24} lg={{ span: 24 }}>
                  <div className="d-flex align-center">
                    <Avatar src={data?.photo?.thumb} size={80}>
                      {getNameSignature(data?.firstName + ' ' + data?.lastName)}
                    </Avatar>
                    <div className="avatar-name align-self-center">
                      <Tooltip
                        placement="bottom"
                        title={data ? data?.firstName : 'mentor Name'}
                        color="blue"
                      >
                        <h1 style={{ marginBottom: '0px' }}>
                          <b className="b1">
                            {data
                              ? capitalizeFirstLetter(data?.firstName)
                              : 'mentor Name'}
                          </b>
                        </h1>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={{ span: 14 }}>
              <Row>
                <Col span={24} className="Organization-header">
                  <Space>
                    <h5 className="status-title">
                      <b className="b1">Status</b>
                    </h5>
                    <div className="profile-head-action-dropdown">
                      <StatusDropdown
                        name="mentor"
                        id={data?._id}
                        status={data?.status}
                      />
                    </div>
                  </Space>
                  <Dropdown overlay={actionMenu}>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="actionButton actionButton-org-profile"
                      shape="round"
                    >
                      Action
                    </Button>
                  </Dropdown>
                </Col>
                {/* <Col span={11}>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%', height: '100%' }}
            className="bg-white profile-tabs"
          >
            <TabPane tab="Basic Info" key="1" style={{ width: '100%' }}>
              {/* <Basic mentor={data} /> */}
              <MentorBasicInfo parent={data} />
            </TabPane>
            <TabPane tab="Availability" key="2" style={{ width: '100%' }}>
              <Availablity mentor={data} />
            </TabPane>
            <TabPane tab="Events" key="3">
              <Events mentorId={id} />
            </TabPane>
            <TabPane tab="Sessions" key="4">
              <Sessions />
            </TabPane>
            <TabPane tab="Reviews" key="5">
              <Reviews />
            </TabPane>
          </Tabs>
        </div>
      </div>
      {/* {modalVisible && (
        <OrganizationModal modalVisible={modalVisible} currentData={data} />
      )} */}
    </AppLayout>
  )
}

export default MentorProfile
