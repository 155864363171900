import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Layout,
  Row,
  Col,
  Avatar,
  Radio,
} from "antd";
import "antd-country-phone-input/dist/index.css";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import MapModal from "../../../../helper/Map/MapModal";
import userAvatarSvg from "../../../../assets/Images/userAvatar.svg";
import moment from "moment";
import FileUploader from "../../../../helper/FileUploader";
import PhoneField from "../../../../helper/PhoneField";
import AllergyList from "../../../../helper/AllergyList";

const GeneralDetail = ({ handleNext, data, currentOrganizer }: any) => {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = useState("");
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [mapData, setMapModalData] = useState<any>(null);
  const [allergies, setAllergies] = useState<any>([]);

  useEffect(() => {
    // console.log("Check:",data,currentOrganizer)
    if (data?.address) {
      form.setFieldsValue({ address: data?.address });
      setMapModalData({
        title: data?.address.title,
        apartment: data?.address.apartment,
        street: data?.address.street,
        city: data?.address.city,
        state: data?.address.state,
        zip: data?.address.zip,
        country: data?.address.country,
        coordinates: data?.address.coordinates,
      });
    } else if (currentOrganizer?.address) {
      // form.setFieldsValue({ address: currentOrganizer?.address });
      setMapModalData({
        title: currentOrganizer?.address.title,
        apartment: currentOrganizer?.address.apartment,
        street: currentOrganizer?.address.street,
        city: currentOrganizer?.address.city,
        state: currentOrganizer?.address.state,
        zip: currentOrganizer?.address.zip,
        country: currentOrganizer?.address.country,
        coordinates: currentOrganizer?.address.coordinates,
      });
    }
  }, []);

  let alergyData = allergies.map((alergy: any) => {
    return {
      _id: alergy.key,
      name: alergy.value,
    };
  });

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const formSchema = {
    photoId: data?.photoId ? data?.photoId : currentOrganizer?.photoId,
    email: data?.email ? data?.email : currentOrganizer?.email,
    firstName: data?.firstName ? data?.firstName : currentOrganizer?.firstName,
    middleName: data?.middleName
      ? data?.middleName
      : currentOrganizer?.middleName,
    nickName: data?.nickName ? data?.nickName : currentOrganizer?.nickName,
    lastName: data?.lastName ? data?.lastName : currentOrganizer?.lastName,
    dob: data?.dob ? moment(data?.dob) : moment(currentOrganizer?.dob),
    gender: data?.gender ? data?.gender : currentOrganizer?.gender,
    medicalNotes: data?.medicalNotes
      ? data?.medicalNotes
      : currentOrganizer?.medicalNotes,
    phone: data?.phone ? data?.phone : currentOrganizer?.phone,
    allergies: data?.allergies ? data?.allergies : currentOrganizer?.allergies,
    address: data?.address
      ? data?.address?.city
      : currentOrganizer?.address?.city,
  };

  const onFinish = (values: any) => {
    handleNext({
      ...values,
      address: mapData,
      dob: moment(values.dob).format("YYYY-MM-DD"),
      allergies: alergyData.length
        ? alergyData
        : data?.allergies
        ? data?.allergies
        : currentOrganizer?.allergies,
    });
  };

  const setFieldsValue = (data: any) => {
    if (data?.address) form.setFieldsValue({ address: data?.address });
    else form.setFieldsValue({ allergies: data?.allergiesList });
  };

  return (
    <Layout>
      <div>
        <Form
          form={form}
          layout="horizontal"
          initialValues={formSchema}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className="modal-h-element">
            <Row gutter={24} align="middle">
              <Col span={4} className="mb-16">
                <Form.Item>
                  <Avatar
                    size={80}
                    src={avatar ? avatar : userAvatarSvg}
                  ></Avatar>
                </Form.Item>
              </Col>
              <Col span={8} className="mb-16">
                <Form.Item
                  name="photoId"
                  rules={[{ required: true, message: "Photo is required" }]}
                >
                  <FileUploader
                    name="organizer"
                    form={form}
                    setAvatar={setAvatar}
                    fileId={
                      data?.photoId ? data?.photoId : currentOrganizer?.photoId
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label htmlFor="" className="input-label required-input">
                  Email
                </label>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Email is required" }]}
                >
                  <Input placeholder="example@gmail.com" name="email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <label className="input-label required-input">First Name</label>
                <Form.Item
                  className="form-control"
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input placeholder="Enter first name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <label className="input-label required-input">Last Name</label>
                <Form.Item
                  className="form-control"
                  name="lastName"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input placeholder="Enter last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <label className="input-label">Middle Name</label>
                <Form.Item className="form-control" name="middleName">
                  <Input placeholder="Enter middle name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <label className="input-label">Nickname</label>
                <Form.Item className="form-control" name="nickName">
                  <Input placeholder="Enter nick name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor="" className="input-label required-input">
                  Gender
                </label>
                <Form.Item
                  name="gender"
                  rules={[{ required: true, message: "Gender is required" }]}
                >
                  <Radio.Group name="gender">
                    <Radio.Button
                      className="radioButton gender-radio-button"
                      value="female"
                    >
                      Female
                    </Radio.Button>
                    <Radio.Button
                      className="radioButton gender-radio-button"
                      value="male"
                    >
                      Male
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="input-label required-input">
                  Date of Birth
                </label>
                <Form.Item
                  className="form-control select-birth"
                  name="dob"
                  rules={[
                    { required: true, message: "Date of birth is required" },
                  ]}
                >
                  <DatePicker className="custom-select-field" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="required-input input-label">
                  Contact Number
                </label>
                <Form.Item
                  name="phone"
                  className="form-control phone-number-field"
                  rules={[
                    { required: true, message: "Contact number is required" },
                  ]}
                >
                  <PhoneField
                    form={form}
                    data={data?.phone ? data?.phone : currentOrganizer?.phone}
                    className="cursor_pointer"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="required-input  input-label">
                  Home adress
                </label>
                <Form.Item
                  className="form-control"
                  name="address"
                  rules={[
                    { required: true, message: "Home address is required" },
                  ]}
                >
                  <Input
                    onClick={() => {
                      setMapModalVisible(true);
                    }}
                    value={mapData ? mapData.city + "," + mapData.state : ""}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="required-input input-label">Allergies</label>
                <Form.Item
                  className="form-control allergies-field"
                  name="allergies"
                  // rules={[{ required: true, message: "Allergies is required" }]}
                >
                  <AllergyList
                    data={
                      data?.allergies
                        ? data?.allergies
                        : currentOrganizer?.allergies
                    }
                    setAllergies={setAllergies}
                    setFieldsValue={setFieldsValue}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label className="input-label">Medical notes</label>
                <Form.Item className="form-control" name="medicalNotes">
                  <Input placeholder="Medical Notes" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row justify="end">
            <Button className="stepper-button" htmlType="submit">
              {currentOrganizer ? 'Save': 'Next'}
            </Button>
          </Row>
        </Form>
        {mapModalVisible && (
          <MapModal
            mapData={mapData}
            setMapModalVisible={setMapModalVisible}
            setMapModalData={setMapModalData}
            setFieldsValue={setFieldsValue}
          />
        )}
      </div>
    </Layout>
  );
};

export default GeneralDetail;
