import { convertMinutesToLocalTime, convertToLocal } from 'helper/utility'
import { MentorRequestResp } from '../types'
import oneTime from 'assets/Images/one-time.svg'

interface request {
  request: MentorRequestResp
}
const OneTimeRequest: React.FC<request> = ({ request }) => {
  const sessionSchedule = request.oneTimeSessionSchedule
  return (
    <>
      {request.type === 'parent-request' ? (
        <>
          <div className="flex-center ">
            <img src={oneTime} alt="one time" />
            <h2 className="text-14 ml-10px w-600">
              {convertToLocal(sessionSchedule.startTime, 'MMM DD, YYYY') + ' '}
            </h2>
          </div>
          <h4 className="text-12 w-400 mt-8px  ">
            {convertToLocal(sessionSchedule.startTime, 'h:mm A')}-
            {'  ' + convertToLocal(sessionSchedule.endTime, 'h:mm A')}
          </h4>
        </>
      ) : request.type == 'organization-event-request' ? (
        <>
          <div>
            {request?.requiredShifts && (
              <div className="flex-center">
                <img src={oneTime} alt="one time" />

                <h2 className="text-14 ml-10px w-600">
                  {convertToLocal(
                    request?.requiredShifts?.[0]?.date!,
                    'MMM DD, YYYY',
                  )}
                </h2>
                <h2>
                  {request?.requiredShifts.length > 1 && (
                    <h2 className="text-14 ml-10px w-600">
                      {' - ' +
                        convertToLocal(
                          request?.requiredShifts?.[
                            request?.requiredShifts.length - 1
                          ]?.date!,
                          'MMM DD, YYYY',
                        )}
                    </h2>
                  )}
                </h2>
              </div>
            )}
            {request.requiredShifts?.map(data => {
              return (
                <div>
                  <h4 className="text-14 w-500 mt-5px">
                    {convertToLocal(data.date, 'ddd') +
                      ': ' +
                      convertMinutesToLocalTime(data?.shifts[0]?.start) +
                      ' - ' +
                      convertMinutesToLocalTime(data?.shifts[0]?.end)}
                  </h4>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
export default OneTimeRequest
