import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetEventForKids } from 'api/Events'
import Spinner from 'components/Spinner'
import { Pagination } from 'antd'
import usePagination from 'helper/usePagination'

const KidEvents = () => {
  const { id }: any = useParams()
  const [parms, setParms] = useState({
    page: 1,
    limit: 10,
  })
  const { isLoading, data, refetch } = useGetEventForKids(id, parms)
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })

  useEffect(() => {
    refetch()
  }, [id])

  return (
    <div className="bg-grey container padding-top-10">
      <div className="profile_container">
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            {data.count > 0 ? (
              <div>
                <div className="flex gap-40px flex-wrap">
                  {data.records.map(event => {
                    return (
                      <Link to={`/events-details/${event._id}`} key={event._id}>
                        <div className="pointer">
                          <img
                            src={event.coverPhotos[0].post}
                            alt={event.name}
                            width={250}
                            height={200}
                            className="radius-12"
                          />
                          <h4 className="mt-5px text-16px w-600">
                            {event.name}
                          </h4>
                        </div>
                      </Link>
                    )
                  })}
                </div>
                {(data.hasNextPage || data.hasPrevPage) && (
                  <div className="mt-40px">
                    <Pagination
                      className="pb-20"
                      style={{ width: '100%', display: 'flex' }}
                      {...paginationInfo}
                      onChange={PaginationNoTabel}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <h4>No Events for this kid</h4>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default KidEvents
