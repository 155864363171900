import { useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosPatch } from 'Redux/axiosHelper'
import { AdditionalFeePayload, EventStep3Payload } from 'types/eventStep3'

// patch request event step 3
const eventStep3 = async ({
  payload,
  eventId,
}: {
  payload: EventStep3Payload
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/event-step3`,
  )
  return response.data
}

export const useEventStep3 = () => {
  return useMutation(eventStep3)
}

// patch add additional fee
const addAdditionalFee = async ({
  payload,
  eventId,
}: {
  payload: AdditionalFeePayload
  eventId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/add-more-fee`,
  )
  return response.data
}

export const useAddAdditionalFee = () => {
  return useMutation(addAdditionalFee)
}

// patch edit additional fee
const editAdditionalFee = async ({
  payload,
  eventId,
  feeId,
}: {
  payload: AdditionalFeePayload
  eventId: string
  feeId: string
}) => {
  const response = await axiosPatch(
    payload,
    `v1/organization-events/${eventId}/edit-more-fee/${feeId}`,
  )
  return response.data
}

export const useEditAdditionalFee = () => {
  return useMutation(editAdditionalFee)
}

// patch to delete additional fee
const deleteAdditionalFee = async ({
  eventId,
  feeId,
}: {
  eventId: string
  feeId: string
}) => {
  const response = await axiosPatch(
    {},
    `v1/organization-events/${eventId}/delete-more-fee/${feeId}`,
  )
  return response.data
}

export const useDeleteAdditionalFee = () => {
  return useMutation(deleteAdditionalFee)
}
