

const actions= {

    GET_ALL_LOCATIONS : "GET_ALL_LOCATIONS",
    GET_ALL_LOCATIONS_SUCCESS : "GET_ALL_LOCATIONS_SUCCESS",
    GET_ALL_LOCATIONS_FAILURE : "GET_ALL_LOCATIONS_FAILURE",

    FAILURE:"FAILURE",


    getAllLocationsRequest: (params) => ({
        type: actions.GET_ALL_LOCATIONS,
        params
    }),
    getAllLocationsSuccess: (payload) => ({
        type: actions.GET_ALL_LOCATIONS_SUCCESS,
        payload,
    }),

    getAllLocationsFailure: () => ({
        type: actions.GET_ALL_LOCATIONS_FAILURE,
    }),

    failure:()=>({
        type:actions.FAILURE
    })
};


export default actions
