import React, { useEffect } from 'react'
import { Button } from 'antd'
import { capitalizeFirstLetter } from 'helper/utility'
import pdfIcon from '../../../../assets/Images/pdf.png'
import { DownloadSimple } from '@phosphor-icons/react'
import { getDocumentById } from 'api/Documents'
import { useQuery } from '@tanstack/react-query'
import { File } from 'types/globalTypes'

const OtherDetails: React.FC<any> = ({ data }) => {
  const {
    genderPreference,
    estimatePrice,
    whoWillBringCurriculum,
    curriculumIds,
    hopingOutOfThis,
    additionalNotes,
  } = data || {}

  const { data: documentData, refetch: documentRefetch } = useQuery<File>({
    queryKey: ['doc-by-id'],
    queryFn: () => getDocumentById(curriculumIds[0]),
    enabled: false,
  })

  useEffect(() => {
    if (curriculumIds.length > 0) {
      documentRefetch()
    }
  }, [curriculumIds])

  return (
    <>
      <div className="d-flex justify-between align-center">
        <h1 className="text-20 w-600 line-0">Other details</h1>
        <Button className="nav-btn br-8 px-30 w-600">Edit</Button>
      </div>

      {genderPreference && (
        <div className="d-flex justify-between align-center  mt-20px">
          <h3 className="text-14 w-600 line-0">Gender Preferences</h3>
          <p className="text-14">{capitalizeFirstLetter(genderPreference)}</p>
        </div>
      )}

      {estimatePrice && (
        <div className="d-flex justify-between align-center  mt-20px">
          <h3 className="text-14 w-600 line-0">Budget per session (1 hour)</h3>
          <p className="text-14">${estimatePrice}</p>
        </div>
      )}

      {whoWillBringCurriculum && (
        <div className="d-flex justify-between align-center  mt-20px">
          <h3 className="text-14 w-600 line-0">
            Who will be bringing the curriculum
          </h3>
          <p className="text-14">
            {capitalizeFirstLetter(whoWillBringCurriculum)}
          </p>
        </div>
      )}

      {curriculumIds?.length > 0 && (
        <div className="mt-20px">
          <h3 className="text-14 w-600 line-0">Example of the curriiculum</h3>
          <div
            className="px-15px py-15px d-flex align-center justify-between radius-12 mt-20px"
            style={{ border: '1px solid #EEEFF1', backgroundColor: '#FaFaFc' }}
          >
            <div className="d-flex align-center">
              <img src={pdfIcon} alt="pdf" />
              <p className="ml-10px text-14px w-400">{`${documentData?.name}.${documentData?.ext}`}</p>
            </div>

            <a
              href={documentData?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadSimple size={24} height={24} color="#000000" />
            </a>
          </div>
        </div>
      )}

      {hopingOutOfThis && (
        <div className="d-flex flex-column mt-20px">
          <h3 className="text-14 w-600 line-0">Hoping to get out of</h3>
          <p className="text-14 mt-10px">
            {capitalizeFirstLetter(hopingOutOfThis)}
          </p>
        </div>
      )}

      {additionalNotes && (
        <div className="d-flex flex-column mt-20px">
          <h3 className="text-14 w-600 line-0">Additional Comments</h3>
          <p className="text-14 mt-10px">
            {capitalizeFirstLetter(additionalNotes)}
          </p>
        </div>
      )}
    </>
  )
}

export default OtherDetails
