import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { Modal } from "antd";
import { Layout } from "antd";
import { useParams } from "react-router-dom";
const { Option } = Select;

const BasicinfoModal = ({ currentData, modalVisible, updateData }: any) => {
  const [form] = Form.useForm();
  const value = currentData?.owner?.firstName;
  const [accessLevel, setAccessLevel] = useState(currentData?.accessLevel);
  const [status, setStatus] = useState(currentData?.status);
  const { id }: { id: string } = useParams();

  useEffect(() => {}, [accessLevel, status]);

  const handleOk = () => {
    modalVisible(false);
  };

  const handleCancel = () => {
    modalVisible(false);
  };

  const statusOptions = [
    <Option key="requested">Requested</Option>,
    <Option key="approved">Approved</Option>,
    <Option key="rejected">Rejected</Option>,
    <Option key="archived">Archived</Option>,
  ];

  const accessLevelOptions = [
    <Option key="owner">Owner</Option>,
    <Option key="organizer">Organizer</Option>,
    <Option key="staff-member">Staff member</Option>,
    <Option key="view-only">View only</Option>,
    <Option key="event-manager">Event manager</Option>,
  ];

  const formSchema = {
    name: currentData?.name ? currentData?.name : "",
    changeStatus: currentData?.status || [],
    accessLevel: currentData?.accessLevel || [],
  };

  const onFinish = (values: any) => {};

  const handleChangeAccess = (newValue: any) => {
    setAccessLevel(newValue);
  };

  const handleChangeStatus = (newValue: any) => {
    setStatus(newValue);
  };

  const handleUpdateAdmin = () => {
    updateData.mutate({
      organizationKey: id,
      data: currentData,
      status,
    });
  };

  return (
    <Layout>
      <Modal
        className="organization_model"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          <h4> Edit Admin Details </h4>
        </div>

        <div className="ml-30px mt-10px border-btm py-10px">
          <h6>Basic Info</h6>
        </div>

        {/* // actual content  */}

        <Layout>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={formSchema}
          >
            <div className="modal-h-element ">
              <Row gutter={24}>
                <Col span={12}>
                  <label htmlFor="" className="input-label required-input">
                    Name
                  </label>
                  <Form.Item name="name">
                    <Input value={"farooq organization"} disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 15]}>
                <Col span={24}>
                  <label className="input-label">Change status</label>
                  <Form.Item
                    name="changeStatus"
                    rules={[
                      { required: true, message: "Organizer is required" },
                    ]}
                  >
                    <Select
                      showSearch
                      value={value}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onChange={handleChangeStatus}
                      notFoundContent={null}
                      style={{ width: "100%" }}
                      placeholder="search organizer"
                      className="custom-select-field"
                    >
                      {statusOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[24, 15]}>
                <Col span={24}>
                  <label className="input-label">Change access Level</label>
                  <Form.Item name="accessLevel">
                    <Select
                      showSearch
                      value={value}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onChange={handleChangeAccess}
                      notFoundContent={null}
                      style={{ width: "100%" }}
                      placeholder="search organizer"
                      className="custom-select-field"
                    >
                      {accessLevelOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Row justify="end">
              <Col span={24} className="text-end">
                <Button
                  className="stepper-button"
                  htmlType="submit"
                  onClick={handleUpdateAdmin}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Layout>
      </Modal>
    </Layout>
  );
};
export default BasicinfoModal;
