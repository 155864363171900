import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./action";
import { axiosGet, axiosPost, axiosPatch } from "../axiosHelper";
import failureAction from "../Failure/action";

/**
 * Get Locations data
 **/


export function* getAllLocationsRequest({ params }) {
    try {
        console.log('here-getAllLocationsRequest')
        let currentParams = {
            page: params?.current || 1,
            limit: params?.pageSize || 10,
            sort: params?.sort?.order || "desc",
        };
        const { data } = yield axiosGet(`v1/location` , currentParams);
        yield put(actions.getAllLocationsSuccess(data));
    } catch (error) {
        console.log('error -get-all-locations-request', error)
        yield put(actions.getAllLocationsFailure());
        yield put(failureAction.failure(error));
    }
}


export default function* rootSaga() {
    yield all([
        takeLatest(actions.GET_ALL_LOCATIONS, getAllLocationsRequest),
    ]);
}
