import React, { useState } from "react";
import Home from ".";
import Address from "./Address";

const Location = ({ setLoactionVisible, setMapModalData, mapData }: any) => {
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = useState(mapData || null);
  const next = (childData: any) => {
    // setCurrent(current + 1);
    setData({
      ...data,
      ...childData,
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onSubmit = (formData: any) => {
    setMapModalData(formData);
    // setLoactionVisible(false);
  };
  return (
    <div>
        {current === 0 && <Home setLoactionVisible={setLoactionVisible} next={next} mapData={data} />}
        {/* {current === 1 && (
          <Address prev={prev} mapData={data} onSubmit={onSubmit} />
        )} */}
    </div>
  );
};

export default Location;
