/* eslint-disable import/no-anonymous-default-export */
import actions from './action'

const initState = {
  ismodelVisible: false,
  eventsData: JSON.parse(localStorage.getItem('events')) || [],
  events: [],
  eventsDetails: {},
  eventOrganizationData: {},
  currentEventsData: [],
  eventsDataValue: {},
  eventCategoryData: [],
  eventActivityCategoryData: [],
  eventActivityGradesData: [],
  currentEventStepperStep: 0,
  loading: false,
  eventDefaultActivities: [],
  eventsScheduleActivitiesValue: {},
  eventsSuppliesDataValue: {},
  eventsAdmissionTicketDataValue: {},
  eventsAdditionalTicketDataValue: {},
  eventsDropInTicketDataValue: {},
  eventsAdditionalData: [],
  itemBringData: [],
  faqData: [],
  reqDocumentData: [],
  eventsPaymentFrequencyData: {},
  totalCount: 0,
  mentorEvents: [],
  mentorEventsTotalCount: 0,
  eventActivities: [],
  currentEvent: null,
  organizationLocations: [],
  approvedLocations: [],
  organizationFaqs: [],
  mentorRequest: null,
  shifts: null,
  approveSuccess: false,
  recentCheckIn: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case actions.GET_EVENT_REQUEST: {
      return {
        ...state,
        events: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        events: action.payload.records,
        eventOrganizationData: {},
        loading: false,
      }
    }

    case actions.GET_EVENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_MENTOR_EVENTS: {
      return {
        ...state,
        mentorEvents: [],
        loading: true,
      }
    }

    case actions.GET_MENTOR_EVENTS_SUCCESS: {
      return {
        ...state,
        mentorEventsTotalCount: action.payload.count,
        mentorEvents: action.payload.records,
        loading: false,
      }
    }

    case actions.GET_MENTOR_EVENTS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST: {
      return {
        ...state,
        eventOrganizationData: {},
        currentEvent: null,
        loading: true,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST_SUCCESS: {
      return {
        ...state,
        eventOrganizationData: action.payload,
        currentEvent: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_ORGANIZATION_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA: {
      return {
        ...state,
        eventsDataValue: {},
        loading: true,
      }
    }

    case actions.ADD_EVENTS_DATA_SUCCESS: {
      return {
        ...state,
        eventsDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.EVENTS_MODAL_VISIBLE: {
      return {
        ...state,
        ismodelVisible: action.payload,
      }
    }

    case actions.GET_EVENT_CATEGORIES: {
      return {
        ...state,
        eventCategoryData: [],
        loading: true,
      }
    }

    case actions.GET_EVENT_CATEGORIES_SUCCESS: {
      return {
        ...state,
        eventCategoryData: action.payload,
        loading: false,
      }
    }

    case actions.GET_EVENT_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.CURRENT_EVENT_STEPPER_STEP: {
      return {
        ...state,
        currentEventStepperStep: action.payload,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_TWO_SUCCESS: {
      return {
        ...state,
        eventsScheduleActivitiesValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_TWO_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_THREE_SUCCESS: {
      return {
        ...state,
        eventsSuppliesDataValue: action.payload,
        loading: false,
      }
    }

    case actions.ADD_EVENTS_DATA__STEP_THREE_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case actions.SET_ADD_ITEM_BRING_DATA: {
      return {
        ...state,
        itemBringData: [...state.itemBringData, action.payload],
      }
    }

    case actions.SET_ADD_FAQ_DATA: {
      return {
        ...state,
        faqData: [...state.faqData, action.payload],
      }
    }

    case actions.SET_ADD_REQ_DOCUMENT_DATA: {
      return {
        ...state,
        reqDocumentData: [...state.reqDocumentData, action.payload],
      }
    }
    case actions.DELETE_DROP_IN_TICKET: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.DELETE_DROP_IN_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case action.APPROVE_PARTICIPANTS: {
      return {
        ...state,
        approveSuccess: true,
      }
    }
    case actions.GET_RECENT_CHECK_IN: {
      return {
        ...state,
        recentCheckIn: action.payload,
      }
    }

    default:
      return state
  }
}
