import { Modal } from 'antd'
import React, { useEffect } from 'react'
import closeIcon from 'assets/Images/close.svg'
import { useGetDiscountCodeById } from 'api/EventCreation/CreationStep4'
import Spinner from 'components/Spinner'
import moment from 'moment'

interface ViewDiscountProps {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  id: string
}

const ViewDiscount: React.FC<ViewDiscountProps> = ({
  openModal,
  closeModal,
  id,
}) => {
  const { data, isLoading, isFetching, refetch } = useGetDiscountCodeById(id)
  const handleCancel = () => {
    closeModal(false)
  }

  useEffect(() => {
    refetch()
  }, [id])
  return (
    <div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        className="discount-view-modal"
        footer={false}
        centered
      >
        <div className="flex-center pt-20px justify-between ">
          <h2 className="w-600 text-18">Discount code details</h2>
          <img
            src={closeIcon}
            alt="close-button"
            onClick={handleCancel}
            className="close button pointer"
          />
        </div>
        <div className="h-25rem scroller overflow-auto-y">
          {isLoading || isFetching ? (
            <div className="flex align-center justify-center h-full">
              <Spinner />
            </div>
          ) : (
            <div className="mt-20px">
              <h3 className="text-16 w-600 mt-10px">{data.code}</h3>
              <div className="mt-10px flex align-center justify-between">
                <h4 className="text-14 w-600">Value</h4>
                <p>
                  {data.discount} {`${data.type === 'percent' ? '%' : '$'}`}
                </p>
              </div>
              <div className="mt-10px flex align-center justify-between">
                <h4 className="text-14 w-600">Type</h4>
                <p>{data.type}</p>
              </div>
              <div className="mt-10px flex align-center justify-between">
                <h4 className="text-14 w-600">Used</h4>
                <p>{data.userIds.length}</p>
              </div>
              {data.limitPerFamily && (
                <div className="mt-10px flex align-center justify-between">
                  <h4 className="text-14 w-600">Limit per family</h4>
                  <p>{data?.limitPerFamily}</p>
                </div>
              )}
              {data?.totalLimit && (
                <div className="mt-10px flex align-center justify-between">
                  <h4 className="text-14 w-600">Total limit</h4>
                  <p>{data?.totalLimit}</p>
                </div>
              )}
              {data?.expiry && (
                <div className="mt-10px flex align-center justify-between">
                  <h4 className="text-14 w-600">Expiry date</h4>
                  <p>{`${moment(data.expiry).format(
                    'MMM DD, yyyy',
                  )} at ${moment(data.expiry).format('hh:mm a')}`}</p>
                </div>
              )}

              <div className="mt-15px">
                <h4 className="text-14 w-600">Aavailalbe on Tickets</h4>
                {data.admissionTickets.length > 0 && (
                  <div className="mt-5px">
                    {data.admissionTickets.map(ticket => (
                      <p className="mt-10px">{ticket.name}</p>
                    ))}
                  </div>
                )}
                {data.additionalOptions.length > 0 && (
                  <div className="mt-5px">
                    {data.additionalOptions.map(ticket => (
                      <p className="mt-10px">{ticket.name}</p>
                    ))}
                  </div>
                )}
              </div>

              {data?.note && (
                <div className="mt-10px">
                  <h4 className="text-14 w-600">Description</h4>
                  <p className="mt-5px">{data?.note}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ViewDiscount
