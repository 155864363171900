import { Avatar, Col, Menu, Row, Space, Tabs } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../../components/layout/layout'
import Events from '../KidDetails/Events'
import actions from '../../../../../Redux/Family/action'
import StatusDropdown from '../../../../../helper/StatusDropdown'
import ActionButton from '../../../../../helper/ActionButton'
import ParentBasicInfo from '../../GuardianProfile/BasicInformation'
import AdultsModal from '../../../../Modals/FamilyModal/Adults/AdultsModal'
import GuardiansModal from '../../../../../components/modals/add-EditFamilyMembers/Guardian/GuardiansModal'
import AlertModal from '../../../../../helper/AlertModal'

const { TabPane } = Tabs

function KidDetails({ familyData, kidsDetailData }: any) {
  const dispatch = useDispatch()
  const [kid, setKid] = useState<any>(kidsDetailData)
  let { id }: any = useParams()
  let { state }: any = useLocation()

  const [isModalVisible, setModalVisible] = useState(false)
  const [alertModalVisible, setAlertModal] = useState(false)

  useEffect(() => {
    dispatch({
      type: actions.GET_KID_DETAIL,
      kidId: id,
    })
  }, [])

  // let profile = JSON.parse(data);

  // useEffect(() => {
  //   let kid = familyData.map((family: any) => {
  //     return family.kidsData.filter((kid: any) => {
  //       return kid.id === profile.kidId;
  //     });
  //   });
  //   setKid(kid[0][0]);
  // }, [familyData, profile.id]);

  console.log(kid)

  const { personalDetails, personality } = kid

  const menu = (
    <Menu>
      <Menu.Item key="event">Check-in to Event </Menu.Item>
      <Menu.Item key="ChangePassword">Change Password</Menu.Item>
      <Menu.Item
        key="Editprofile"
        // onClick={() => {
        //   history.push({
        //     pathname: "/kid-profile",
        //     state: JSON.stringify({
        //       familyId: profile.familyId,
        //       kidId: profile.kidId,
        //     }),
        //   });
        // }}
      >
        Edit profile
      </Menu.Item>
      <Menu.Item key="Delete">Delete </Menu.Item>
    </Menu>
  )

  const onChange = (key: string) => {
    console.log(key)
  }

  const alertData = {
    id: kid?.id,
    name: 'Kid',
    heading: `Delete ${kid?.name}`,
  }

  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        <div className="profile_header">
          <Row align="middle">
            <Col xs={{ span: 24 }} xl={{ span: 12 }}>
              <Space>
                <Col>
                  <Avatar src={kid?.photo?.post} size={80}></Avatar>
                </Col>
                <Col>
                  <span className="profile_title">
                    {kid?.name || kid?.firstName}
                  </span>
                  <span className="subtitle">{kid?.relation?.name}</span>
                </Col>
              </Space>
            </Col>
            <Col xs={{ span: 4 }} xl={{ span: 2 }}>
              <h5>
                <b className="b1">Status</b>
              </h5>
            </Col>
            <Col xs={{ span: 8 }} xl={{ span: 4 }}>
              <StatusDropdown id={kid?.id} name="parent" status="active" />
            </Col>
            <Col xs={{ span: 10 }} xl={{ span: 6 }}>
              <ActionButton
                name={state}
                setModalVisible={setModalVisible}
                setAlertModal={setAlertModal}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%', height: '100%' }}
            className="bg-white profile-tabs"
          >
            <TabPane tab="Basic Info" key="1">
              <ParentBasicInfo />
            </TabPane>
            <TabPane tab="Events" key="2">
              <Events />
            </TabPane>
            <TabPane tab="Media" key="3">
              <Events />
            </TabPane>
            <TabPane tab="Documents" key="4">
              <Events />
            </TabPane>
          </Tabs>
        </Row>
      </div>
      {isModalVisible && state === 'adult' && (
        <AdultsModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          familyId={id}
          adultData={kid}
        />
      )}
      {isModalVisible && state === 'guardian' && (
        <GuardiansModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          familyId={id}
          guardianData={kid}
        />
      )}
      {alertModalVisible && (
        <AlertModal
          isModalVisible={alertModalVisible}
          setIsModalVisible={setAlertModal}
          data={alertData}
        />
      )}
    </AppLayout>
  )
}
const mapStateToProps = (state: any) => ({
  familyData: state.family.familyMembersData,
  kidsDetailData: state.family.kidsDetailData,
})

export default connect(mapStateToProps)(KidDetails)
