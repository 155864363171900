import { Avatar, Checkbox, Col, Row, Space } from "antd";
import { connect } from "react-redux";
import Detailtab from "../../../assets/Images/DetailTab.svg";
import location from "../../../assets/Images/MapPinLine.svg";
import Calendar from "../../../assets/Images/calendarBlankNew.svg";
import MapTrifold from "../../../assets/Images/mapTrifoldBlue.svg";
import familyPic from "../../../assets/Images/familyPic.png";
import { UserOutlined } from "@ant-design/icons";
import { List } from "antd";
import moment from "moment";
const SessionTab1 = ({ sessionDetails }: any) => {
  const data1 = [
    {
      title: "Sam",
      description: "12 July 2016",
    },
    {
      title: "Jinny",
      description: "27 August 2015",
    },
    {
      title: "Penny",
      description: "5 June 2014",
    },
  ];
  const dataItemsBring = [
    {
      title: "Jacket",
    },
    {
      title: "Bottle of water",
    },
  ];
  return (
    <div className="bg-grey padding-top-10">
      <Row className="profile_container">
        <Col span={6}>
          <span>Last 24 hours</span>
          <h2>1:00 hrs</h2>
        </Col>
        <Col span={6}>
          <span>This week</span>
          <h2>1:00 hrs</h2>
        </Col>
        <Col span={6}>
          <span>Last week</span>
          <h2>5:00 hrs</h2>
        </Col>
        <Col span={6}>
          <span>Since start</span>
          <h2>94:00 hrs</h2>
        </Col>
      </Row>
      <Row gutter={10} className="bg-grey container">
        <Col lg={{ span: 12 }} span={24} className="mb-md-15">
          <div className="profile_container mt-10">
            <Row gutter={[0, 16]}>
              {/* <Col>
                <h3>Request details</h3>
              </Col> */}
              <Col className="d-flex justify-between">
                <div className="d-flex align-items-start">
                  <img src={location} className="mr-15" />
                  <h5 className="b1 mb-0 font16 text-color-black">
                    {sessionDetails?.address?.street}
                    <span className="text-gray-color font14 b3 mt-5">
                      Address goes here
                    </span>
                  </h5>
                </div>
                <img src={MapTrifold} />
              </Col>
              <Col>
                <div className="d-flex align-items-start">
                  <img src={Calendar} className="mr-15" />
                  <h5 className="b1 mb-0 font16 text-color-black">
                    {moment(
                      sessionDetails?.oneTimeSessionSchedule?.startTime
                    ).format("DD MMMM YYYY") +
                      "-" +
                      moment(
                        sessionDetails?.oneTimeSessionSchedule?.endTime
                      ).format("DD MMMM YYYY")}
                    <span className="text-gray-color font14 b3 mt-5">
                      {moment(
                        sessionDetails?.oneTimeSessionSchedule?.startTime
                      ).format("HH:MM A") +
                        "-" +
                        moment(
                          sessionDetails?.oneTimeSessionSchedule?.endTime
                        ).format("HH:MM A")}
                    </span>
                  </h5>
                </div>
              </Col>
              <Col>
                <Space direction="vertical" size={16}>
                  <h4 className="mb-0 b1">Description</h4>
                  <h5 className="b3 mb-0">
                    We are looking for a tutor for our kid, we need experiensed
                    and other job description goes here. We are looking for a
                    tutor for our kid, we need experiensed and other job
                    description goes here
                  </h5>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical" size={12}>
                  <h4 className="b1 mb-0">Organization</h4>
                  <div>
                    <Space size={16}>
                      <Avatar size={40} src={Detailtab}></Avatar>
                      <h4 className="b2 mb-0">Organization name</h4>
                    </Space>
                  </div>
                </Space>
              </Col>
              <Col>
                <p>
                  <b>Items to bring</b>
                </p>
                <List
                  itemLayout="horizontal"
                  dataSource={dataItemsBring}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Checkbox value={item.title} />}
                        title={<h5>{item.title}</h5>}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={{ span: 12 }} span={24}>
          <div className="profile_container mt-10">
            <Row gutter={[0, 20]}>
              <Col>
                <p>
                  <b>Kids</b>
                </p>

                <List
                  itemLayout="horizontal"
                  dataSource={sessionDetails?.kids}
                  renderItem={(item: any) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={item?.photo?.post} size={40} />}
                        title={<h5>{item?.firstName}</h5>}
                        description={moment(item?.dob).format(`DD MMMM YYYY [ (${moment().diff(item?.dob, 'years')} years old)]`)}
                      />
                    </List.Item>
                  )}
                />
              </Col>
              <Col>
                <p>
                  <b>Mentors</b>
                </p>
                <List
                  itemLayout="horizontal"
                  dataSource={data1}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src="https://joeschmoe.io/api/v1/random"
                            size={40}
                          />
                        }
                        title={<h5>{item.title}</h5>}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sessionDetails: state.session.sessionDetails,
});
export default connect(mapStateToProps)(SessionTab1);
