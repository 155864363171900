import { Avatar, Button, Checkbox, Col, Layout, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import actions from '../../../../Redux/Family/action'
import backarrow from '../../../../assets/Images/backarrow.svg'
import { myStore } from 'state/eventStore'

const SelectChild = ({
  data,
  handleSubmit,
  handleBack,
  familyId,
  kids,
  parent,
  setModalVisible,
  closePrev,
}: any) => {
  const dispatch = useDispatch()
  const [selectedKidIds, setselectedKidIds] = useState<any>([])
  const setTag = myStore(store => store.setTag)

  useEffect(() => {
    dispatch({
      type: actions.GET_FAMILY_KIDS,
      payload: familyId,
    })
  }, [familyId])

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setselectedKidIds(checkedValues)
  }

  const onSubmit = () => {
    console.log('selectedKidIds:', selectedKidIds, parent?._id)
    let formData = {
      kidsIds: selectedKidIds,
    }
    dispatch({
      type: actions.ASSIGN_KIDS_TO_ADULT,
      payload: formData,
      adultId: parent?._id,
    })

    setModalVisible(false)
    closePrev()
    setTag(true)
  }

  return (
    <Layout>
      <div className="modal-h-element">
        <Row gutter={[10, 20]}>
          <Col>
            <h5>
              <b className="b3">
                This adult is authorized to pick up the following child(ren).
              </b>
            </h5>
          </Col>
          <Col>
            <Checkbox.Group
              onChange={onChange}
              className="d-flex flex flex-column flex-wrap gap-10"
            >
              {kids.map((kid: any) => {
                return (
                  <Checkbox
                    value={kid.id}
                    key={kid.id}
                    className="kids-listing-auth-adults"
                  >
                    <Space size={12}>
                      <Avatar
                        size={40}
                        src={kid?.photo?.post}
                        className="ml-5"
                      ></Avatar>
                      <h4 className="b3">{kid?.firstName}</h4>
                    </Space>
                  </Checkbox>
                )
              })}
            </Checkbox.Group>
          </Col>
        </Row>
      </div>
      <Row align="middle" justify="space-between">
        <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
          <span className="mr-10 d-inline-block">
            <img src={backarrow} />
          </span>
          Back
        </h5>
        <Button
          className="stepper-button"
          onClick={() => {
            // handleSubmit(data);
            onSubmit()
          }}
        >
          Create
        </Button>
      </Row>
    </Layout>
  )
}

const mapStateToProps = (state: any) => ({
  kids: state.family.kids,
  parent: state.family.parent,
})

export default connect(mapStateToProps)(SelectChild)
