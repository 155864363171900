import { Avatar, Select } from 'antd'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from '../Redux/Allergies/action'

const AllergyList = ({
  data,
  allergiesList,
  form,
  setAllergies,
  setFieldsValue,
}: any) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: actions.GET_ALLERGIES,
    })
  }, [])
  console.log('allergiesList', allergiesList)
  return (
    <div>
      <Select
        mode="multiple"
        placeholder={'Select allergies'}
        defaultValue={
          data &&
          data.map((alergy: any) => {
            return alergy.name
          })
        }
        onChange={(v, item) => {
          setAllergies(item)
        }}
        className="selector"
      >
        {allergiesList?.map((allergy: any) => (
          <Select.Option key={allergy._id} value={allergy.name}>
            {<Avatar size={18} src={allergy?.icon}></Avatar>} {allergy?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  allergiesList: state.allergies.allergiesList,
})
export default connect(mapStateToProps)(AllergyList)
