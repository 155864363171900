import { CirclesThreePlus } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { getSkillsList } from 'api/Lists'
import Spinner from 'components/Spinner'
import { useEffect } from 'react'
interface Skill {
  _id: string
  name: string
  icon: string
  category: 'educational' | 'other'
}

interface GetSkillsIcoProps {
  id: string
}

const GetSkillsIco: React.FC<GetSkillsIcoProps> = ({ id }) => {
  const { data, refetch } = useQuery<Skill[]>(
    ['getSkillList'],
    () => {
      return getSkillsList()
    },
    { enabled: false },
  )

  useEffect(() => {
    refetch()
  }, [id])

  const item = data?.find(item => item._id === id)

  return (
    <div className="flex-center">
      {data ? (
        <>
          {item?.icon ? (
            <img src={item?.icon} alt={item?.name} />
          ) : (
            <CirclesThreePlus size={40} />
          )}
          <h3 className="text-20 w-600 ml-20px">{item?.name || 'Other'}</h3>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}

export default GetSkillsIco
