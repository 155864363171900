import { Button, Col, Row, Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import plusBlue from '../../../../../../../assets/Images/plusBlue.svg'
import { useGetApprovedLocations } from 'api/EventCreation/EventCreation'
import { LocationParams } from 'types/organization'
import Loader from 'helper/Loader'
import usePagination from 'helper/usePagination'
import { Address } from 'types/globalTypes'
import ViewLocationModal from './ViewLocationModal'

interface OrganizationLocationListingProps {
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  organizationId: string
  setMapModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setMapModalData: React.Dispatch<React.SetStateAction<Address>>
  setFieldsValue: any
  setIsFromPreviousLoc: React.Dispatch<React.SetStateAction<boolean>>
}

const ApprovedLocationListing: React.FC<OrganizationLocationListingProps> = ({
  setModalVisible,
  organizationId,
  setMapModalVisible,
  setFieldsValue,
  setMapModalData,
  setIsFromPreviousLoc,
}) => {
  const [viewAddressVisible, setViewAddressVisible] = useState(false)
  const [mapData, setMapData] = useState(null)
  const [params, setParams] = useState<LocationParams>({
    page: 1,
    limit: 10,
  })

  const { data, isLoading, isFetching, refetch } =
    useGetApprovedLocations(params)

  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: data?.count || 0,
    currentPage: params.page,
    setParams: setParams,
  })

  console.log(paginationInfo, 'pagination info..')
  const [isLocationSelected, setIsLocationSelected] = useState(false)
  const [selectedLocationDetailItem, setSelectedLocationDetailItem] =
    useState<any>({})

  useEffect(() => {
    refetch()
  }, [params])
  const onConfirm = () => {
    setFieldsValue(selectedLocationDetailItem)
    setMapModalData(selectedLocationDetailItem)
    setIsFromPreviousLoc(true)
    setModalVisible(false)
  }

  const handleMapModalShow = () => {
    setMapModalVisible(true)
    setIsFromPreviousLoc(false)
  }

  return (
    <div className="used-users-list">
      <div
        style={{
          maxHeight: '400px',
          marginTop: '20px',
          overflowY: 'auto',
          marginBottom: '15px',
        }}
      >
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          data.records.length > 0 &&
          data.records.map((item: Address) => {
            return (
              <>
                <Row justify="space-between" style={{ marginTop: '20px' }}>
                  <Col span={16}>
                    {' '}
                    <h5 className="text-14 w-600">
                      {`${item.title}:`}
                      <span
                        className="w-400"
                        style={{ display: 'inline' }}
                      >{` ${item?.street} , ${item?.city} , ${item?.country} `}</span>
                    </h5>
                  </Col>
                  <Col span={8} className="d-flex gap-15">
                    <Button
                      className="primary-transparent br-8"
                      onClick={() => {
                        setViewAddressVisible(true)
                        setMapData(item)
                      }}
                    >
                      View
                    </Button>
                    <Button
                      className={
                        selectedLocationDetailItem == item && isLocationSelected
                          ? 'btn-blue'
                          : 'primary-transparent br-8'
                      }
                      onClick={() => {
                        setSelectedLocationDetailItem(item)
                        if (selectedLocationDetailItem == item) {
                          setIsLocationSelected(!isLocationSelected)
                        } else {
                          setIsLocationSelected(true)
                        }
                      }}
                    >
                      {selectedLocationDetailItem == item && isLocationSelected
                        ? 'selected'
                        : 'select'}
                    </Button>
                  </Col>
                </Row>
              </>
            )
          })
        )}

        {(data?.hasNextPage || data?.hasPrevPage) && (
          <Row className="justify-center flex mt-40px">
            <Pagination
              className="pb-20"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              responsive={true}
              showSizeChanger={false}
              showQuickJumper={false}
              total={paginationInfo.total}
              onChange={PaginationNoTabel}
            />
          </Row>
        )}
      </div>
      <Row justify="space-between" align="middle">
        <h5
          className="text-primary cursor_pointer"
          onClick={handleMapModalShow}
        >
          <b className="b2">
            <img src={plusBlue}></img> Add custom Location
          </b>
        </h5>
        <Button
          disabled={!isLocationSelected}
          className="stepper-button"
          onClick={() => {
            onConfirm()
          }}
        >
          Confirm
        </Button>
      </Row>

      {viewAddressVisible && (
        <ViewLocationModal
          isVisible={viewAddressVisible}
          setIsVisible={setViewAddressVisible}
          mapData={mapData}
        />
      )}
    </div>
  )
}

export default ApprovedLocationListing
