import React from 'react'
import { useHistory } from 'react-router'
import { Layout, Menu } from 'antd'
import 'antd/dist/antd.css'
import { LeftOutlined } from '@ant-design/icons'
const { Sider } = Layout
const ProfilesSidebar = () => {
  const history = useHistory()
  return (
    <Sider className="side-bar" width={'278px'}>
      <Menu mode="inline" style={{ height: '100%' }} className="padding-30">
        <Menu.Item
          className="profile-back-menu"
          onClick={() => {
            history.goBack()
          }}
        >
          <LeftOutlined />
          Back
        </Menu.Item>
      </Menu>
    </Sider>
  )
}
export default ProfilesSidebar
