import { Modal } from "antd";
import { Layout } from "antd";
import GeneralDetails from "./GeneralDetails";
import { useMutation } from "@tanstack/react-query";
import { updateFamilyProfile } from "../../../../api/family";
import { useState } from "react";
const NewFamily = ({ familyData, refetchData, showModal }: any) => {
  const [open, setOpen] = useState<boolean>(true);
  const id = familyData._id || familyData?.family._id;
  const updataFamily = useMutation({
    mutationFn: (mutData: any) => {
      return updateFamilyProfile(id, mutData);
    },
  });

  const handleOk = () => {};

  const handleCancel = () => {
    setOpen(false);
    showModal(false);
  };

  const onSubmit = (formData: any) => {
    updataFamily.mutate(formData);
    refetchData();
    setOpen(false);
    showModal(false);
  };

  return (
    <Layout>
      <Modal
        className="organization_modal"
        title="Update Family"
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
      >
        <GeneralDetails handleSubmit={onSubmit} data={familyData} />
      </Modal>
    </Layout>
  );
};
export default NewFamily;
