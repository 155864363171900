import { Row, Col, Avatar, Button, Space, Dropdown, Menu } from 'antd'
import deleteSvg from '../../../../../assets/Images/delete_red.svg'
import pencilLine from '../../../../../assets/Images/PencilLine.svg'
import { PlusOutlined } from '@ant-design/icons'
import StatusDropdown from '../../../../../helper/StatusDropdown'
import { useMutation } from '@tanstack/react-query'
import { changeFamilyStatus, detletFamily } from '../../../../../api/family'
import { history } from '../../../../../Redux/app-store'
import checkin from '../../../../../assets/Images/Calendar.svg'
import password from '../../../../../assets/Images/LockKey.svg'
import { useState } from 'react'
import FamilyMembersModal from './FamilyMembersModal'
import { getNameSignature } from 'helper/utility'
const FamilyHeader = ({ setShowEdit, FamilyDetails, refetch }: any) => {
  const [modalVisibal, setModalVisible] = useState<boolean>()
  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      return await changeFamilyStatus(params, payload)
    },
  })
  const deletMutation = useMutation({
    mutationFn: async mutData => {
      return await detletFamily(mutData)
    },
  })
  const menu = (
    <Menu>
      <Menu.Item key="checkin">
        <Space>
          <img alt="img" src={checkin}></img> Check-in to Event
        </Space>
      </Menu.Item>
      <Menu.Item
        key="addCredits"
        onClick={() => {
          setModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={password}></img> Change Password
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Editprofile"
        onClick={() => {
          setShowEdit(true)
        }}
      >
        <Space>
          <img alt="img" src={pencilLine}></img> Edit profile
        </Space>
      </Menu.Item>
      <Menu.Item
        key="Delete"
        onClick={() => {
          deletMutation.mutate(FamilyDetails.family.id)
          history.push({
            pathname: `/family`,
          })
          refetch()
        }}
      >
        <Space className="text-color-red">
          <img alt="img" src={deleteSvg}></img> Delete
        </Space>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="org-profile-header">
      <Row gutter={10}>
        <Col xl={{ span: 10 }}>
          <Row align="middle">
            <Col span={24} lg={{ span: 24 }}>
              <div className="d-flex align-center">
                <Avatar src={FamilyDetails?.family?.photo?.post} size={80}>
                  {getNameSignature(FamilyDetails?.family?.name)}
                </Avatar>
                <div className="avatar-name align-self-center">
                  <span className="profile_title">
                    {FamilyDetails?.family?.name}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={{ span: 14 }}>
          <Row>
            <Col span={24} className="Organization-header">
              <Space>
                <h5 className="status-title">
                  <b className="b1">Status</b>
                </h5>
                <div className="profile-head-action-dropdown">
                  <StatusDropdown
                    id={FamilyDetails?.family?.id}
                    name="family"
                    status={FamilyDetails?.family?.status}
                    changeFn={changeMutation}
                    refetchData={refetch}
                  />
                </div>
              </Space>
              <Dropdown overlay={menu} className="status_dropdown">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="actionButton actionButton-org-profile"
                  shape="round"
                >
                  Manage
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      {modalVisibal && (
        <FamilyMembersModal
          visbleModel={setModalVisible}
          data={FamilyDetails}
        />
      )}
    </div>
  )
}
export default FamilyHeader
