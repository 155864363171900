import React from "react";
import "antd/dist/antd.css";
import { Calendar, Select, Col, Row, Badge, Tooltip, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";

const AvailanlityCalendar = ({ eventsData }: any) => {
  const dateCellRender = (value: any) => {};

  const onPanelChange = (value: any, mode: any) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  return (
    <div className="mycalender">
      <Calendar
        fullscreen={false}
        // dateCellRender={dateCellRender}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const month = value.month();
          const year = value.year();
          return (
            <Space
              align="end"
              size={60}
              direction="horizontal"
              className="space-container"
            >
              <Tooltip title="Previous Month" color={"blue"} placement="bottom">
                <LeftOutlined
                  onClick={(selectedMonth: any) => {
                    const newValue = value.clone();
                    newValue.month(parseInt(String(month - 1), 10));
                    onChange(newValue);
                  }}
                />
              </Tooltip>
              <h5 className="b2">{moment(value).format("MMMM Do YYYY")}</h5>
              <Tooltip title="Next Month" color={"blue"} placement="bottom">
                <RightOutlined
                  onClick={(selectedMonth: any) => {
                    const newValue = value.clone();
                    newValue.month(parseInt(String(month + 1), 10));
                    onChange(newValue);
                  }}
                />
              </Tooltip>
            </Space>
          );
        }}
        onPanelChange={onPanelChange}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  eventsData: state.events.eventsData,
});

export default connect(mapStateToProps)(AvailanlityCalendar);
