import { Link, useHistory } from 'react-router-dom'
import { CaretRight } from '@phosphor-icons/react'

interface LinkArray {
  name: string
  link: string
}
interface BreadCrumb {
  links: LinkArray[]
}
const ReturnBreadCrumbs: React.FC<BreadCrumb> = ({ links }) => {
  const history = useHistory()
  return (
    <div className="flex align-center pointer h-fit">
      {links?.map((item, index) => {
        return (
          <div className="flex align-center">
            {index !== links.length - 1 ? (
              <div className="flex align-center ">
                {/* <h4
                  className="text-12 w-500 mr-5px"
                  style={{ color: '#939598' }}
                  onClick={() => {
                    history.push(`${item?.link}`)
                  }}
                >
                  {item.name}
                </h4> */}
                <Link to={`${item?.link}`}>
                  <h4
                    className="text-12 w-500 mr-5px"
                    style={{ color: '#939598' }}
                  >
                    {item.name}
                  </h4>
                </Link>
                <CaretRight size={12} height={12} color="#939598" />
              </div>
            ) : (
              <div className="flex align-center">
                {/* <h4
                  className="text-12 w-500 ml-5px"
                  style={{ color: '#000' }}
                  onClick={() => {
                    history.push(`${item?.link}`)
                  }}
                >
                  {item.name}
                </h4> */}
                <Link to={item?.link}>
                  <h4
                    className="text-12 w-500 ml-5px"
                    style={{ color: '#000' }}
                    onClick={() => {
                      history.push(`${item?.link}`)
                    }}
                  >
                    {item.name}
                  </h4>
                </Link>
                <CaretRight size={4} height={8} color="#0000" />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
export default ReturnBreadCrumbs
