import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import AppLayout from "../../../../../components/layout/layout";
import DemoGauge from "../Gauge";
import GeneralDetails from "./GeneralDetails";
import AuthorizedAdults from "./AuthorizedAdults";
import Personality from "./Personality";
import Abilities from "./Abilities";
import Events from "./Events";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import ArrowCircleRight from "../../../../../assets/Images/ArrowCircleRight.svg";
import backSvg from "../../../../../assets/Images/back.svg";

const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};
const { Text } = Typography;

const KidProfile = ({ familyData }: any) => {
  const [kid, setKid] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  const location: any = useLocation();
  let data = location.state;
  let profile = JSON.parse(data);

  useEffect(() => {
    let kid = familyData.map((family: any) => {
      return family.kidsData.filter((kid: any) => {
        return kid.id === profile.kidId;
      });
    });
    setKid(kid[0][0]);
  }, [familyData, profile.id]);

  const { personalDetails, personality, authAdults, abilities } = kid;

  console.log(kid);

  const updateFamilyData = (data: any) => {
    let updateKey = Object.keys(data)[0];
    let updateValue = Object.values(data)[0];
    familyData.forEach((item: any, index: any) => {
      if (item.id === profile.id) {
        familyData[index][updateKey] = updateValue;
      }
      setLoading(true);
    });

    // dispatch({
    //   type: actions.ADD_FAMILY_DATA,
    //   payload: familyData,
    // });
  };

  return (
    <AppLayout>
      <div className="kid-profile">
        {loading ? (
          <h5>load</h5>
        ) : (
          <Row gutter={10}>
            <Col span={7}>
              <Card className="card" style={{ position: "fixed" }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Meta
                    avatar={
                      <Avatar src={personalDetails?.kidAvatar} size={60} />
                    }
                    title={personalDetails?.firstname}
                    description={`${moment(personalDetails?.dob).format(
                      "DD/MMMM/YYYY"
                    )} (${moment().diff(
                      personalDetails?.dob,
                      "years",
                      false
                    )} years old)`}
                  ></Meta>
                  <Button shape="round" className="secondary">
                    Change password
                  </Button>
                  <Button shape="round" className="primary">
                    Check-in to Event
                  </Button>
                </Space>
                <Divider />
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row justify="space-between">
                    <h5>
                      <b>Rewards</b>
                    </h5>
                    <h5 className="text-primary">Give a reward</h5>
                  </Row>
                  <Row justify="space-between">
                    <Avatar></Avatar>
                    <Avatar></Avatar>
                    <Avatar></Avatar>
                    <Avatar></Avatar>
                    <Avatar></Avatar>
                  </Row>
                  <Row justify="space-between">
                    <h5>
                      <b>Activity</b>
                    </h5>
                    <h5>
                      <b>Today - 12 Jan 2021</b>
                    </h5>
                  </Row>
                  {viewDetails ? (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Row justify="space-between" align="middle">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewDetails(false);
                          }}
                        >
                          <img alt="img" src={backSvg}></img>
                        </span>
                        <h4>
                          <b>Total Participation</b>
                        </h4>
                        <h4>
                          <b>50:25</b>
                        </h4>
                      </Row>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Card
                          className="grey-card"
                          style={{
                            margin: "0px",
                            padding: "2px",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col span={22}>
                              <Row>
                                <Text>Educational</Text>
                              </Row>
                              <Row>
                                <Progress
                                  strokeColor="#4D53BF"
                                  percent={70}
                                  size="small"
                                  strokeWidth={3}
                                  format={(percent) => <></>}
                                />
                              </Row>
                            </Col>
                            <Col span={2}>
                              <img alt="img" src={ArrowCircleRight}></img>
                            </Col>
                          </Row>
                        </Card>
                        <Card
                          className="grey-card"
                          style={{
                            margin: "0px",
                            padding: "2px",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col span={22}>
                              <Row>
                                <Text>Camps</Text>
                              </Row>
                              <Row>
                                <Progress
                                  strokeColor="#0091FF"
                                  percent={70}
                                  size="small"
                                  strokeWidth={3}
                                  format={(percent) => <></>}
                                />
                              </Row>
                            </Col>
                            <Col span={2}>
                              <img alt="img" src={ArrowCircleRight}></img>
                            </Col>
                          </Row>
                        </Card>
                        <Card
                          className="grey-card"
                          style={{
                            margin: "0px",
                            padding: "2px",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col span={22}>
                              <Row>
                                <Text>Sports</Text>
                              </Row>
                              <Row>
                                <Progress
                                  strokeColor="#32C5FF"
                                  percent={70}
                                  size="small"
                                  strokeWidth={3}
                                  format={(percent) => <></>}
                                />
                              </Row>
                            </Col>
                            <Col span={2}>
                              <img alt="img" src={ArrowCircleRight}></img>
                            </Col>
                          </Row>
                        </Card>
                        <Card
                          className="grey-card"
                          style={{
                            margin: "0px",
                            padding: "2px",
                            borderRadius: "10px",
                          }}
                        >
                          <Row>
                            <Col span={22}>
                              <Row>
                                <Text>Spiritual</Text>
                              </Row>
                              <Row>
                                <Progress
                                  strokeColor="#44D7B6"
                                  percent={70}
                                  size="small"
                                  strokeWidth={3}
                                  format={(percent) => <></>}
                                />
                              </Row>
                            </Col>
                            <Col span={2}>
                              <img alt="img" src={ArrowCircleRight}></img>
                            </Col>
                          </Row>
                        </Card>
                        <Card
                          className="grey-card"
                          style={{
                            margin: "0px",
                            borderRadius: "10px",
                            padding: "2px",
                          }}
                        >
                          <Row>
                            <Col span={22}>
                              <Row>
                                <Text>Other</Text>
                              </Row>
                              <Row>
                                <Progress
                                  strokeColor="#96EDCC"
                                  percent={70}
                                  size="small"
                                  strokeWidth={3}
                                  format={(percent) => <></>}
                                />
                              </Row>
                            </Col>
                            <Col span={2}>
                              <img alt="img" src={ArrowCircleRight}></img>
                            </Col>
                          </Row>
                        </Card>
                      </Space>
                    </Space>
                  ) : (
                    <div>
                      {/* <Row justify="space-between">
                      <h4>
                        <b className="b1">Activity</b>
                      </h4>
                      <h4>
                        <b className="b1">Today - 12 Jan 2021</b>
                      </h4>
                    </Row> */}
                      <Row justify="center">
                        <DemoGauge setViewDetails={setViewDetails} />
                      </Row>
                    </div>
                  )}{" "}
                </Space>
              </Card>
            </Col>
            <Col span={17}>
              <div style={{ paddingTop: "54px" }}>
                <Tabs defaultActiveKey="1" onChange={onChange}>
                  <TabPane tab="General Details" key="1">
                    <GeneralDetails
                      generalDetails={personalDetails}
                      updateFamilyData={updateFamilyData}
                    />
                  </TabPane>
                  <TabPane tab="Authorized Adults" key="2">
                    <AuthorizedAdults
                      authorizedAdults={authAdults}
                      updateFamilyData={updateFamilyData}
                    />
                  </TabPane>
                  <TabPane tab="Personality" key="3">
                    <Personality
                      personality={personality}
                      updateFamilyData={updateFamilyData}
                    />
                  </TabPane>
                  <TabPane tab="Abilities" key="4">
                    <Abilities
                      abilities={abilities}
                      updateFamilyData={updateFamilyData}
                    />
                  </TabPane>
                  <TabPane tab="Events" key="5">
                    <Events />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => ({
  familyData: state.family.familyMembersData,
});

export default connect(mapStateToProps)(KidProfile);
