import { useQuery } from '@tanstack/react-query'
import { Popover } from 'antd'
import { getEventCategories } from 'api/Events'
import { useEffect } from 'react'
import { connect } from 'react-redux'
interface category {
  id: string
}
const CategoryImage = ({ id }: category) => {
  const { data, refetch } = useQuery(
    ['getEventCategories'],
    () => getEventCategories(),
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  }, [])
  const requested = data?.filter((img: any) => {
    return img?._id == id
  })
  const categoryName = (
    <h4 className="text-14 text-white"> {requested && requested[0]?.name}</h4>
  )

  return (
    <Popover
      content={categoryName}
      placement="bottom"
      className="event-popover"
    >
      <img
        src={requested && requested[0]?.icon}
        alt="categorie"
        className="w-40px"
      />
    </Popover>
  )
}

export default CategoryImage
