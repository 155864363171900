import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getKidAuthorizedAdults } from '../../../../../api/family'
import Email from '../../../../../assets/Images/adultemail.svg'
import Phone from '../../../../../assets/Images/adultPhone.svg'
import admin from '../../../../../assets/Images/Amin.png'
import { getNameSignature } from 'helper/utility'
import { Avatar, Row } from 'antd'
import UserMisus from '../../../../../assets/Images/UserMinus.png'
const KidAuthorizedAdults = () => {
  const { id }: any = useParams()
  const { data } = useQuery(['getKidAuthorizeAdult'], () =>
    getKidAuthorizedAdults(id),
  )
  // console.log('data:', data)
  return (
    <div className=" h-full overflow-auto bg-white p-30px h-80vh ml-10px">
      <div className="flex justify-between ">
        <h1>Authorized Adults</h1>
        <button className="borderd-btn">Add adult</button>
      </div>
      {data?.map((data: any) => {
        return (
          <>
            <div className="flex justify-between align-center">
              <div className="flex justify-start  mt-30px">
                <Avatar src={data?.photo?.post} size={50}>
                  {getNameSignature(data?.firstName)}
                </Avatar>
                <div className="ml-20px">
                  {' '}
                  <h2 className="text-14">
                    {data?.firstName + ' ' + data?.lastName}
                  </h2>
                  <h3 className="grey-txt text-14">{data?.relation?.name}</h3>
                  {data?.email && (
                    <div className="flex">
                      <img src={Email} alt="email" />
                      <h3 className="text-14 ml-10px">{data?.email}</h3>
                    </div>
                  )}
                  <div className="flex">
                    <img src={Phone} alt="email" />
                    <h3 className="text-14 ml-10px">{data?.phone?.number}</h3>
                  </div>
                </div>
              </div>
              <Row>
                <img src={UserMisus} alt="" />
                <h4 style={{ color: '#FF6961', marginLeft: '10px' }}>
                  Unauthorize
                </h4>
              </Row>
            </div>
          </>
        )
      })}
      {/* {data ? (
        <div className="flex justify-start  mt-30px">
          <Avatar src={data?.photo?.post} size={50}>
            {getNameSignature(data?.firstName)}
          </Avatar>
          <div className="ml-20px">
            {' '}
            <h2 className="text-14">
              {data?.firstName + ' ' + data?.lastName}
            </h2>
            <h3 className="grey-txt text-14">{data?.relation?.name}</h3>
            {data?.email && (
              <div className="flex">
                <img src={Email} alt="email" />
                <h3 className="text-14 ml-10px">{data?.email}</h3>
              </div>
            )}
            <div className="flex">
              <img src={Phone} alt="email" />
              <h3 className="text-14 ml-10px">{data?.phone?.number}</h3>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-12 w-500">No Authorized adults are added</h3>
        </div>
      )} */}
    </div>
  )
}
export default KidAuthorizedAdults
