import { all, put, takeLatest } from 'redux-saga/effects'
import actions from './action'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../axiosHelper'
import failureAction from '../Failure/action'

export function* getEventRequest({ params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      organizationId: params?.orgId,
      status: params?.status || null,
      limit: params?.pageSize || 5,
      sort: params?.sort?.order || 'desc',
      categoryId: params?.id || null,
      populate: '[{"path":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/organization-events`, currentParams)
    yield put(actions.getEventRequestSuccess(data))
  } catch (error) {
    yield put(actions.getEventRequestFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getMentorEvents({ payload }) {
  try {
    console.log('params', payload)
    const mentorId = payload?.mentorId
    let currentParams = {
      page: payload?.current || 1,
      organizationId: payload?.orgId,
      status: payload?.status || null,
      limit: payload?.pageSize || 5,
      sort: payload?.sort?.order || 'desc',
      categoryId: payload?.id || null,
      populate: '[{"path":"photo"}]',
    }
    const { data } = yield axiosGet(
      `v1/mentors/${mentorId}/events`,
      currentParams,
    )
    yield put(actions.getMentorEventsSuccess(data))
  } catch (error) {
    yield put(actions.getMentorEventsFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getEventOrganizationRequest({ eventId }) {
  try {
    const { data } = yield axiosGet(`v1/organization-events/${eventId}`)
    yield put(actions.getEventOrganizationRequestSuccess(data))
  } catch (error) {
    yield put(actions.getEventOrganizationRequestFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getEventCategoriesRequest() {
  try {
    const { data } = yield axiosGet(`v1/lists/event-categories`)
    yield put(actions.getEventCategoriesSuccess(data))
  } catch (error) {
    yield put(actions.getEventCategoriesFailure())
    yield put(failureAction.failure(error))
  }
}

export function* addEventRequest({ payload }) {
  try {
    const organizationId = payload.organizationId
    const { data } = yield axiosPost(
      payload,
      `v1/organization-events/${organizationId}/event-step1`,
    )
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: data?._id,
    })
    yield put(actions.createEventSuccess(data))
  } catch (error) {
    yield put(actions.createEventFailure())
    yield put(failureAction.failure(error))
  }
}

export function* addAdditionalEventRequest({ payload }) {
  try {
    const eventId = payload?.eventId
    const { data } = yield axiosPatch(
      payload,
      `v1/organization-events/${eventId}/event-step5`,
    )
    yield put(actions.createEventForStepFiveSuccess(data))
  } catch (error) {
    yield put(actions.createEventForStepFiveFailure())
    yield put(failureAction.failure(error))
  }
}

export function* duplicateAdditionalOptionTicket({ payload }) {
  try {
    const { ticketId, eventId } = payload

    const { data } = yield axiosPost(
      payload,
      `v1/organization-events/${eventId}/additional-options/${ticketId}/duplicate`,
    )
    yield put(actions.duplicateAdditionalOptionTicketSuccess(data))
    yield put({
      type: actions.GET_EVENT_ORGANIZATION_REQUEST,
      eventId: eventId,
    })
  } catch (error) {
    yield put(actions.duplicateAdditionalOptionTicketFailure())
    yield put(failureAction.failure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_EVENT_REQUEST, getEventRequest),
    takeLatest(actions.GET_MENTOR_EVENTS, getMentorEvents),
    takeLatest(
      actions.GET_EVENT_ORGANIZATION_REQUEST,
      getEventOrganizationRequest,
    ),
    takeLatest(actions.GET_EVENT_CATEGORIES, getEventCategoriesRequest),
    takeLatest(actions.ADD_EVENTS_DATA, addEventRequest),
  ])
}
