import { useEffect, useState } from 'react'
import filterSvg from '../../../assets/Images/filter.svg'
import { Checkbox, Col, Row, Select } from 'antd'
import { Menu, Dropdown, Space } from 'antd'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import InventoryFilter from '../Headers/InventoryFilter'
const ActionHeader = ({hidCols,setCols,search, parms,
  setParms}:any)=>{
    const [visible, setVisible] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const onChangeColumnCheckBox = (e: any) => {
        const updatedHiddenColumns = {
          ...hidCols,
          [e.target.name]: e.target.checked,
        }
    
        setCols(updatedHiddenColumns)
        localStorage.setItem('InventoryCols', JSON.stringify(updatedHiddenColumns))
      }
      useEffect(() => {
        const storedHiddenColumns = localStorage.getItem('InventoryCols')
        if (storedHiddenColumns) {
          setCols(JSON.parse(storedHiddenColumns))
        }
      }, [])
    
    const menu2 = (
        <Menu
          // onClick={handleMenuClick}
          title="show all"
          items={[
            {
              label: (
                <Row gutter={10} justify="space-between">
                  <Col span={4}>
                    <Checkbox
                      defaultChecked={hidCols.name}
                      name="name"
                      onChange={onChangeColumnCheckBox}
                    ></Checkbox>
                  </Col>
                  <Col span={20}>Name</Col>
                </Row>
              ),
              key: '1',
            },
            {
              label: (
                <Row gutter={10} justify="space-between">
                  <Col span={4}>
                    <Checkbox
                      defaultChecked={hidCols.organization}
                      name="organization"
                      onChange={onChangeColumnCheckBox}
                    ></Checkbox>
                  </Col>
                  <Col span={20}>Organization</Col>
                </Row>
              ),
              key: '2',
            },
            {
              label: (
                <Row gutter={10} justify="space-between">
                  <Col span={4}>
                    <Checkbox
                      defaultChecked={hidCols.location}
                      name="location"
                      onChange={onChangeColumnCheckBox}
                    ></Checkbox>
                  </Col>
                  <Col span={20}>Location</Col>
                </Row>
              ),
              key: '3',
            },
            {
              label: (
                <Row gutter={10} justify="space-between">
                  <Col span={4}>
                    <Checkbox
                      defaultChecked={hidCols.Quantity}
                      name="Quantity"
                      onChange={onChangeColumnCheckBox}
                    ></Checkbox>
                  </Col>
                  <Col span={20}>Quantity</Col>
                </Row>
              ),
              key: '4',
            },
            {
              label: (
                <Row gutter={10} justify="space-between">
                  <Col span={4}>
                    <Checkbox
                      defaultChecked={hidCols.Price}
                      name="Price"
                      onChange={onChangeColumnCheckBox}
                    ></Checkbox>
                  </Col>
                  <Col span={20}>Price</Col>
                </Row>
              ),
              key: '5',
            },
          
          ]}
        />
      )
      const handleVisibleChange = (flag: boolean) => {
        setVisible(flag)
      }


      const handleSearchChange = (e: any) => {
        setSearchQuery(e)
        search(e)
      }
        const debounced = debounce(handleSearchChange, 300)
    return(
       <div>
         <Space size={20} align="center" className="mentors-table-header">
        <Dropdown
          overlay={menu2}
          onOpenChange={handleVisibleChange}
          open={visible}
        >
          <h5 onClick={e => e.preventDefault()}>
            <Space>
              <img src={filterSvg} alt=""></img>
            </Space>
          </h5>
        </Dropdown>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search"
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
        <InventoryFilter setParms={setParms} parms={parms}/>

        </Space>
       </div>
    )
}
export default ActionHeader