import { Radio } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useState } from 'react'
import KidImg from 'assets/Images/kid-ico.png'
import Adult from 'assets/Images/adult.png'
import Kid from 'components/modals/add-EditFamilyMembers/Kid'
import AdultsModal from 'components/modals/add-EditFamilyMembers/Adults'
interface addMember {
  familyId: string
  closeModel: () => void
}
const AddFamilyMember: React.FC<addMember> = ({ familyId, closeModel }) => {
  const [memberType, setMemberType] = useState<string>()
  const [openModel, setModel] = useState(false)
  const radioFn = (e: CheckboxChangeEvent) => {
    e.target.checked && setMemberType(e.target.name)
  }
  return (
    <div>
      <h2>Add new Member to family</h2>
      <div className="grid-50 mt-30px text-center">
        <div>
          <img src={KidImg} alt="organization" />
          <h2 className="text-16 mt-20px w-500">Kid</h2>
          <h4 className="text-12 mt-10px">Add a new Kid to this family </h4>
          <Radio
            name="kid"
            className="mt-10px"
            checked={memberType == 'kid'}
            onChange={radioFn}
          ></Radio>
        </div>

        <div>
          <img src={Adult} alt="organization" />
          <h2 className="text-16 mt-20px w-500">Authorized Adult</h2>
          <h4 className="text-12 mt-10px">Add new Adult to this family</h4>
          <Radio
            name="adult"
            className="mt-10px"
            checked={memberType == 'adult'}
            onChange={radioFn}
          ></Radio>
        </div>
      </div>
      <div className="flex justify-center mt-40px">
        <button
          className="w-80p prim-btn "
          onClick={() => {
            setModel(true)
          }}
        >
          Continue
        </button>
      </div>
      {memberType == 'kid' && openModel ? (
        <Kid
          isModalVisible={openModel}
          setModalVisible={setModel}
          familyId={familyId}
          closePrevModel={closeModel}
        />
      ) : memberType == 'adult' && openModel ? (
        <AdultsModal
          setModalVisible={setModel}
          isModalVisible={openModel}
          familyId={familyId}
          closePrevModel={closeModel}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
export default AddFamilyMember
