import React, { useState, useEffect } from "react";
import { Avatar, Button, Col, Layout, Modal, Row, Space, Divider } from "antd";
import rightArrowIcon from "../../../../../assets/Images/backarrow.svg";
import editpencil from "../../../../../assets/Images/editpencil.svg";
import sessionActions from "../../../../../Redux/Session/action";
import { useDispatch, connect, useSelector } from "react-redux";
import moment from "moment";

const ConfirmModal = ({
  isModalVisible,
  setIsModalVisible,
  sessionData,
  handleBack,
  isOtherModalVisible,
  action,
  setCurrent,
}: any) => {
  const dispatch = useDispatch();
  //  const {actions} = useSelector((state:any) => state.session)
  // useEffect(()=>{
  //   console.log("action:",actions);
  // },[actions])

  // useEffect(() => {
  //   console.log("action:", action, sessionData);
  // if (action === artistActions.CREATE_ARTIST_SUCCESS) {
  //   history.goBack();
  // }
  // }, [action.loading]);

  const handleOk = () => {
    const dataValues = {
      familyId: sessionData?.familyVal?.key      ,
      categoryId: sessionData?.categoryId,
      // other: true,
      // extraDetails: sessionData
      kidsIds: sessionData?.kidsIds,
      genderPreference: sessionData?.genderPreference,
      isSessionOnline: sessionData?.isSessionOnline,
      address: sessionData?.address,
      willingToAlternate: sessionData?.willingToAlternate,
      // preferredMentorsIds: ["string"],
      timesPerWeek: sessionData?.timesPerWeek,
      ...(sessionData?.oneTimeSessionSchedule  && {oneTimeSessionSchedule: sessionData?.oneTimeSessionSchedule} ),
      ...(sessionData?.recurringSessionSchedule  && {recurringSessionSchedule: sessionData?.recurringSessionSchedule} ),
      ...(sessionData?.flexibleSessionSchedule  && {flexibleSessionSchedule: sessionData?.flexibleSessionSchedule} ),
      budgetPerSession: sessionData?.budgetPerSession || 0,
      additionalNotes: sessionData?.additionalNotes,
      // createdBy: "string",
      // locationId: "string",
      // organizerId: "string",
      // organizationId: "string",
    };

    if (sessionData?._id) {
      delete dataValues.familyId;
      delete dataValues.kidsIds;
      dispatch({
        type: sessionActions.UPDATE_SESSION_DATA,
        payload: {...dataValues,_id:sessionData?._id},
      });
    } else {
      dispatch({ type: sessionActions.ADD_SESSION_DATA, payload: dataValues });
    }

    setIsModalVisible(false);
    isOtherModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCurrentStep = (step: any) => {
    setCurrent(step);
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
        width={600}
        className="confirmation-modal organization_model py-0"
      >
        <div className="model-header new-session-model-header">
          <h4> {"Confirmation"} </h4>
        </div>

        {/* <Row gutter={[0, 120]} className="modal_container " style={{marginLeft:'30px', marginTop:'10px'}}> */}
        {/* <Col> */}
        <Layout>
          <Space className="general-detail-devider d-flex justify-between font14 b1">
            General details{" "}
            <img
              alt="demo"
              src={editpencil}
              width={20}
              className="cursor_pointer"
              onClick={() => handleCurrentStep(0)}
            />
          </Space>
          <div className="px-20 py-10">
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Family</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h4 className="b2">{sessionData?.familyVal?.name?.name}</h4>
                  <Avatar size={40}>
                    {sessionData?.familyVal?.name?.name?.charAt(0)}
                  </Avatar>
                </Space>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Child</h5>
              </Col>
              <Col span={12} className="text-end">
                <h5 className="b2">{sessionData?.kidVal?.name.firstName}</h5>
                <span className="text-secondary font14">
                  {moment(sessionData.kidVal?.name?.dob).format("DD MMM YYYY")}
                </span>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Phone number</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">+305 (552) 364 47 86</h5>
                </Space>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Email</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">koury@gmail.com</h5>
                </Space>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Category</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">{sessionData?.categoryVal?.children}</h5>
                </Space>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Gender Preferences</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">{sessionData?.genderPreference}</h5>
                </Space>
              </Col>
            </Row>
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Location</h5>
              </Col>
              <Col span={12} className="text-end">
                <h5 className="b2">{sessionData?.address.title}</h5>
                <span className="text-secondary font14">
                  {sessionData?.address.address}
                </span>
              </Col>
            </Row>
          </div>
          <Space className="general-detail-devider d-flex justify-between font14 b1">
            Time and frequency
            <img
              src={editpencil}
              width={20}
              className="cursor_pointer"
              onClick={() => handleCurrentStep(1)}
            />
          </Space>
          <div className="px-20 py-10">
            <Row className="d-flex justify-between mb-10">
              <Col span={12} className="align-self-center">
                <h5 className="b1">Frequency</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">
                    {sessionData.timesPerWeek === "one_time"
                      ? "One Time"
                      : sessionData.timesPerWeek === "flexible"
                      ? "flexible"
                      : "Recurring"}
                  </h5>
                </Space>
              </Col>
            </Row>
            {sessionData?.oneTimeSessionSchedule?.startTime ? (
              <>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12} className="align-self-center">
                    <h5 className="b1">Start time</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.oneTimeSessionSchedule?.startTime
                        ).format("HH:mm")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12} className="align-self-center">
                    <h5 className="b1">End time</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.oneTimeSessionSchedule?.endTime
                        ).format("HH:mm")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
              </>
            ) : sessionData?.flexibleSessionSchedule?.startDate ? (
              <>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12} className="align-self-center">
                    <h5 className="b1">Start date</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.flexibleSessionSchedule?.startDate
                        ).format("YYYY-MM-DD")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12} className="align-self-center">
                    <h5 className="b1">End date</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.flexibleSessionSchedule?.endDate
                        ).format("YYYY-MM-DD")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
                <Row className="d-flex justify-between mt-10">
                  <Col span={12}>
                    <h5 className="b1">Restrictions</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {sessionData?.flexibleSessionSchedule?.restrictions}
                      </h5>
                    </Space>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12}>
                    <h5 className="b1">Start date</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.recurringSessionSchedule?.startDate
                        ).format("YYYY-MM-DD")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12}>
                    <h5 className="b1">End date</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <Space size={16}>
                      <h5 className="b2">
                        {moment(
                          sessionData?.recurringSessionSchedule?.endDate
                        ).format("YYYY-MM-DD")}
                      </h5>
                    </Space>
                  </Col>
                </Row>
                <Row className="d-flex justify-between mb-10">
                  <Col span={12}>
                    <h5 className="b1">Time</h5>
                  </Col>
                  <Col span={12} className="text-end">
                    <h5 className="b2">Mon, Tue, Wed</h5>
                    <span className="text-secondary font14">
                      08:00 AM - 10:00 AM
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <Space className="general-detail-devider d-flex justify-between font14 b1">
            Other details{" "}
            <img
              alt="demo"
              src={editpencil}
              width={20}
              className="cursor_pointer"
              onClick={() => {
                handleCurrentStep(2);
                handleCancel();
              }}
            />
          </Space>
          <div className="px-20 py-10">
            <Row className="d-flex justify-between mb-10">
              <Col span={12}>
                <h5 className="b1">Budget</h5>
              </Col>
              <Col span={12} className="text-end">
                <Space size={16}>
                  <h5 className="b2">$ {sessionData.budgetPerSession}</h5>
                </Space>
              </Col>
            </Row>
          </div>

          {/* </Col> */}

          <Row justify="space-between" align="middle" className="px-20">
            <h5 className="stepperBackh5" onClick={() => handleCancel()}>
              <span className="mr-10 d-inline-block">
                <img src={rightArrowIcon} />
              </span>
              Back
            </h5>
            <Button
              className="stepper-button"
              htmlType="submit"
              onClick={() => handleOk()}
            >
              Confirm and create
            </Button>
          </Row>

          {/* </Row> */}
        </Layout>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  action: state.session,
});

export default connect(mapStateToProps)(ConfirmModal);
