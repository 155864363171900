import { Radio, RadioChangeEvent } from 'antd'
import React, { useState } from 'react'
import { CreateRollCallBody } from 'pages/Events/types'
interface roolcall {
  handleNext: (arg: any) => void
}
const CreateRollCall = ({ handleNext }: roolcall) => {
  const [value, setValue] = useState('')
  const [otherName, setotherName] = useState('')

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  return (
    <div>
      <Radio.Group className="flex flex-col mt-20px" onChange={onChange}>
        <Radio value={'Bathroom'} className="text-16 w-500 mt-15px">
          Bathroom
        </Radio>
        <Radio value={'Bus'} className="text-16 w-500 mt-15px">
          Bus
        </Radio>
        <Radio value={'Diaper change'} className="text-16 w-500 mt-15px">
          Diaper change
        </Radio>
        <Radio value={'Lunch'} className="text-16 w-500 mt-15px">
          Lunch
        </Radio>
        <Radio value={'Snack'} className="text-16 w-500 mt-15px">
          Snack
        </Radio>
        <Radio value={'Other'} className="text-16 w-500 mt-15px">
          Other
        </Radio>
      </Radio.Group>
      {value == 'Other' && (
        <input
          name="other"
          className="my-input w-full mt-10px"
          onChange={e => {
            setotherName(e.target.value)
          }}
        />
      )}
      <div
        className="absolute   py-40px"
        style={{ left: '79%', bottom: '0px' }}
      >
        <button
          className="prim-sm-btn"
          onClick={() => {
            otherName == ''
              ? handleNext({
                  name: value,
                })
              : handleNext({
                  name: otherName,
                })
          }}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default CreateRollCall
