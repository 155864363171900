import { Avatar, Col, Row, Space, Dropdown, Button, Menu } from 'antd'
import { useEffect, useState } from 'react'
import phone from '../../../../../assets/Images/Phone.svg'
import envelopSimple from '../../../../../assets/Images/EnvelopeSimple.svg'
import location from '../../../../../assets/Images/MapPin.png'
import warning from '../../../../../assets/Images/Warning.png'
import ChangePhone from '../../../../../components/modals/changePhone/changePhone'
import ChangeEmail from '../../../../../components/modals/changeEmail/changeEmail'
import MapModal from '../../../../../helper/Map/MapModal'
import { adultProfile } from '../../../../../types/family'
import Person from '../../FamilyProfile/FamilyMembers/Person'
import UserMisus from '../../../../../assets/Images/UserMinus.png'
interface adultInfo {
  data: adultProfile
  refetchData: () => any
}
const AdultInfo = ({ data, refetchData }: adultInfo) => {
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const [mapData, setMapModalData] = useState({
    address: '',
  })
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const kidmenu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>Kid</Space>
      </Menu.Item>
    </Menu>
  )
  // console.log('data:', data)
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button> */}
            <button className="bordered-prim-btn">Manage</button>
          </Dropdown>
        </div>

        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">{data?.phone?.number}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.email && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3"> {data?.email}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* <Row className="pt-40px"> */}
        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">Kids</h2>
          <Dropdown overlay={kidmenu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Add Kid
            </Button> */}
            <button className="bordered-prim-btn"> Add Kid</button>
          </Dropdown>
        </div>
        <Row className="pt-40px">
          <Col>
            {data?.kids.length > 0 && (
              <div>
                <div>
                  {data?.kids?.map((data: any) => {
                    return (
                      <>
                        <div className="flex justify-between align-center">
                          <Person
                            id={data?._id}
                            photo={data?.photo?.post}
                            name={data?.firstName + ' ' + data?.lastName}
                            relation={data?.relation?.name}
                            to="parent-profile"
                            bcase={'guardian'}
                          />
                          <Row>
                            <img src={UserMisus} alt="" />
                            <h4
                              style={{ color: '#FF6961', marginLeft: '10px' }}
                            >
                              Unauthorize
                            </h4>
                          </Row>
                        </div>
                      </>
                    )
                  })}
                </div>
                {/* <div className="mt-20">
                  <Row>
                    <img src={UserMisus} alt="" />
                    <h4 style={{ color: '#FF6961', marginLeft: '10px' }}>
                      Unauthorize
                    </h4>
                  </Row>
                </div> */}
              </div>
            )}
          </Col>
        </Row>
        {/* </Row> */}
      </div>

      {/* {modal === 1 && (
        <ChangeEmail
          setModal={setModal}
          prevEmail={data?.email}
          changeEmail={updateGuardianData}
        />
      )}
      {modal === 3 && (
        <ChangePhone
          setModal={setModal}
          prevPhone={data?.phone?.number}
          changePhone={updateGuardianData}
        />
      )}
      {mapModalVisible && (
        <MapModal
          setMapModalVisible={setMapModalVisible}
          setMapModalData={setMapModalData}
        />
      )}{' '} */}
    </Col>
  )
}
export default AdultInfo
