import {
  Avatar,
  Col,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
  Typography,
  Carousel,
} from 'antd'
import { useEffect, useState, useRef } from 'react'
import envelopSimple from '../../../../../assets/Images/EnvelopeSimple.svg'
import location from '../../../../../assets/Images/MapPin.png'
import warning from '../../../../../assets/Images/Warning.png'
import phone from '../../../../../assets/Images/Phone.svg'
import { useQuery } from '@tanstack/react-query'
import { getAllergyList } from '../../../../../api/Lists'
import moment from 'moment'
import '../BasicInfo/style/generalDetails.scss'
import icon from 'assets/Images/otherCategory.svg'
import Slidernext from 'assets/Images/SliederNext.svg'
import SliderPrev from 'assets/Images/SliederPrev.svg'
import EditMore from 'assets/Images/EditMore.svg'
import KidWalk from 'assets/Images/KidWalk.svg'
import Car from 'assets/Images/Car.svg'
import Byciacle from 'assets/Images/Byciacle.svg'
import familyFriends from 'assets/Images/familyFriends.svg'
import motorCycle from 'assets/Images/Motorcycle.svg'
import other from 'assets/Images/other.svg'
import parent from 'assets/Images/Parent.svg'
import publicTransport from 'assets/Images/publicTransport.svg'
import scooter from 'assets/Images/scooter.svg'
const { Text, Title } = Typography
const GeneralDetail = ({ data, refetchData }: any) => {
  const { data: AllergyData } = useQuery(['getAllergyList'], () =>
    getAllergyList(),
  )
  const [modal, setModal] = useState<Number | null | false>(null)
  const [mapModalVisible, setMapModalVisible] = useState(false)
  const menu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>
          <img alt="img" src={envelopSimple}></img> Change email
        </Space>
      </Menu.Item>
      <Menu.Item
        key="address"
        onClick={() => {
          setMapModalVisible(true)
        }}
      >
        <Space>
          <img alt="img" src={location}></img> Edit address
        </Space>
      </Menu.Item>
      <Menu.Item
        key="phone"
        onClick={() => {
          setModal(3)
        }}
      >
        <Space>
          <img alt="img" src={phone}></img> Edit phone number
        </Space>
      </Menu.Item>
      <Menu.Item key="block">
        <Space className="text-color-red">
          <img alt="img" src={warning}></img> Block email
        </Space>
      </Menu.Item>
    </Menu>
  )
  const kidmenu = (
    <Menu>
      <Menu.Item
        key="email"
        onClick={() => {
          setModal(1)
        }}
      >
        <Space>General</Space>
      </Menu.Item>
    </Menu>
  )
  const getAllergywithIcon = (data: any) => {
    const allergy = AllergyData?.find(
      (allergy: any) => allergy._id === data?._id,
    )

    if (allergy) {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={allergy.icon} size={20} />
          <h2 className="text-16 pl-10">{allergy.name}</h2>
        </div>
      )
    } else {
      return (
        <div className="flex justify-center align-center mr-20px chips">
          <Avatar src={icon} size={20} />
          <h2 className="text-16 pl-10">{data?.name}</h2>
        </div>
      )
    }
  }
  const slider = useRef<any>()

  const onChangeType = (currentSlide: any) => {
    slider.current.next(currentSlide)
  }
  const [currentSlide, setCurrentSlide] = useState(0)
  const onChange = (currentSlide: number) => {
    setCurrentSlide(currentSlide) // Update the current slide index when the slide changes
  }
  return (
    <Col span={12}>
      <div className="profile_container">
        <div className="flex justify-between align-center">
          <h2 className="b1 h6">Contact information</h2>
          <Dropdown overlay={menu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Manage
            </Button> */}
            <button className="bordered-prim-btn">Manage</button>
          </Dropdown>
        </div>
        <Row className="pt-40px">
          <Col>
            <div className="contact-information">
              <Row gutter={[5, 30]}>
                <Col>
                  <Row gutter={[12, 20]}>
                    <Col>
                      <Space size={12}>
                        {data?.phone && (
                          <>
                            <img alt="alt" src={phone}></img>
                            <h4 className="b3">
                              {data?.phone?.dialCode} {data?.phone?.number}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.email && (
                          <>
                            <img alt="alt" src={envelopSimple}></img>
                            <h4 className="b3"> {data?.email}</h4>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Space size={12}>
                        {data?.address && (
                          <>
                            <Avatar src={location} size={28} />
                            <h4 className="b3">
                              {data.address.apartment} {data.address.street}{' '}
                              {data.address.state}
                            </h4>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">General Details</h2>
          <Dropdown overlay={kidmenu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Add Kid
            </Button> */}
            <button className="bordered-prim-btn">Edit</button>
          </Dropdown>
        </div>

        <div style={{ marginTop: 20 }}>
          {data?.dob && (
            <>
              <Row>
                <Col span={12}>
                  <Text type="secondary" className="b1 font14 text-color-black">
                    Birthday
                  </Text>
                </Col>
                <Col span={12} className="text-right">
                  <Text className="b3 font14 text-color-black">
                    {}
                    {data?.dob
                      ? moment(data?.dob).format('MMMM DD, YYYY')
                      : 'No date of birth found'}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.gender && (
            <Row>
              <Col span={12}>
                <Text type="secondary" className="b1 font14 text-color-black">
                  Gender
                </Text>
              </Col>
              <Col span={12} className="text-right">
                <Text className="b3 font14 text-color-black">
                  {data?.gender?.charAt(0).toUpperCase() +
                    data?.gender?.slice(1)}
                </Text>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.nickName && (
            <Row>
              <Col span={12}>
                <Text type="secondary" className="b1 font14 text-color-black">
                  Preferred name
                </Text>
              </Col>
              <Col span={12} className="text-right">
                <Text className="b3 font14 text-color-black">
                  {data?.nickName}
                </Text>
              </Col>
            </Row>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.medicalNotes && (
            <>
              <Row>
                <Col span={24}>
                  <Text type="secondary" className="b1 font14 text-color-black">
                    Medical Notes
                  </Text>
                </Col>
                <Col span={24} className=" flex-column">
                  <Text className="b3 mt-5px font14 text-color-black">
                    {data?.medicalNotes}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.allergies?.length > 0 && (
            <>
              <Row>
                <Col span={24}>
                  <Text
                    type="secondary"
                    className="b1 font14 text-color-black mb-10"
                  >
                    Allergies
                  </Text>
                  <div className="flex  flex-wrap w-full">
                    {data?.allergies?.map((data: any) => {
                      return (
                        <div key={data?._id} className="mt-10px">
                          {getAllergywithIcon(data)}
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">Professional Details</h2>
          <Dropdown overlay={kidmenu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Add Kid
            </Button> */}
            <button className="bordered-prim-btn">Edit</button>
          </Dropdown>
        </div>

        <Col className="justify-content-center d-flex" span={24}>
          <div className="document_img_slider">
            <div className="img_frontside_view">
              <h2 className="b1 font16">
                {currentSlide === 0 ? 'Front side' : 'Back side'}
              </h2>
              <img
                height={25}
                width={24}
                alt="icon"
                src={EditMore}
                className="cursor_pointer"
              />
            </div>
            <div></div>
            <div className="arrow_icon arrow_prev">
              <img
                src={SliderPrev}
                height={20}
                width={20}
                alt="icon"
                onClick={onChangeType}
              />
            </div>
            <Carousel
              ref={(ref: any) => {
                slider.current = ref
              }}
              afterChange={onChange}
            >
              <div>
                <h3>
                  <img
                    height={230}
                    width={339}
                    alt="icon"
                    src={data?.aboutMe?.driverLicenceFrontPhoto?.post}
                  />
                </h3>
              </div>
              <div>
                <h3>
                  <img
                    height={230}
                    width={339}
                    alt="icon"
                    src={data?.aboutMe?.driverLicenceBackPhoto?.post}
                  />
                </h3>
              </div>
            </Carousel>
            <div className="arrow_icon arrow_next">
              <img
                height={20}
                width={20}
                alt="icon"
                src={Slidernext}
                onClick={onChangeType}
              />
            </div>
          </div>
        </Col>

        <div style={{ marginTop: 20 }}>
          {data?.aboutMe?.driverLicenceNumber && (
            <>
              <Row>
                <Col span={12} className="d-flex justify-start">
                  <p className="b1">Driver license </p>
                </Col>
                <Col span={12}>
                  <p className="b3 text-right font14">
                    {data?.aboutMe?.driverLicenceNumber}
                  </p>
                  <p className="b3 text-right font14 text-color-gray">
                    {/* Exp.: 10/2030 */}
                    {'Exp: ' +
                      moment(data?.aboutMe?.driverLicenceExpiringAt).format(
                        'd/yyyy',
                      )}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div style={{ marginTop: 20 }}>
          {data?.aboutMe?.education && (
            <>
              <Row>
                <Col span={12} className="d-flex justify-start">
                  <p className="b1">Education </p>
                </Col>
                <Col span={12}>
                  <p className="b3 text-right font14">
                    {data?.aboutMe?.education}
                  </p>
                  <p className="b3 text-right font14 text-color-gray">
                    {'Completion Year:' + data?.aboutMe?.completionYear}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>

        <div style={{ marginTop: 20 }}>
          {data?.aboutMe?.strength && (
            <>
              <Row>
                <Col span={12} className="d-flex justify-start">
                  <p className="b1">Your Strengths </p>
                </Col>
                <Col span={12}>
                  <p className="b3 text-left font14 ">
                    {data?.aboutMe?.strength}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.aboutMe?.weakness && (
            <>
              <Row>
                <Col span={12} className="d-flex justify-start">
                  <p className="b1">Areas of improvements</p>
                </Col>
                <Col span={12}>
                  <p className="b3 text-left font14">
                    {data?.aboutMe?.weakness}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          {data?.aboutMe?.biography && (
            <>
              <Row>
                <Col span={24} className="d-flex justify-start">
                  <p className="b1">Short bio</p>
                </Col>
                <div className="mt-5">
                  <span className="b3 font14">{data?.aboutMe?.biography}</span>
                </div>
              </Row>
            </>
          )}
        </div>

        <div className="pt-40px flex justify-between align-center">
          <h2 className="b1 h6">Preferences</h2>
          <Dropdown overlay={kidmenu} className="status_dropdown">
            {/* previous code of 06 May 2024
            <Button
              type="primary"
              className="actionButton actionButton-org-profile"
              shape="round"
            >
              Add Kid
            </Button> */}
            <button className="bordered-prim-btn">Edit preferences</button>
          </Dropdown>
        </div>

        <div style={{ marginTop: 20 }}>
          {data?.preferences?.maxCommuteDistance && (
            <>
              <Col xl={{ span: 18 }} span={24}>
                <Space align="center" size={10}>
                  <h5>
                    <b className="b1">How are willing to commute?</b>
                  </h5>
                </Space>
                <p className="h3 text-secondary f-light mt-16 ">
                  1 mi - {data?.preferences?.maxCommuteDistance} mi
                </p>
              </Col>
            </>
          )}
        </div>

        <Col xl={{ span: 18 }} span={24}>
          {data?.preferences?.transportationSource && (
            <>
              <Space align="center" size={10}>
                <h5>
                  <b className="b1">Reliable source of transportation</b>
                </h5>
              </Space>
              <div className="mt-10 flex gap-15">
                {Object.entries(
                  data?.preferences?.transportationSource || {},
                ).map(([key, value]) => {
                  if (value) {
                    switch (key) {
                      case 'car':
                        return <img key={key} src={Car} />
                      case 'cycle':
                        return <img key={key} src={Byciacle} />
                      case 'fnfMember':
                        // Assuming you have an image for fnfMember, replace 'familyFriends' with the actual import
                        return <img key={key} src={familyFriends} />
                      case 'motorcycle':
                        return <img key={key} src={motorCycle} />
                      case 'other':
                        return <img key={key} src={other} />
                      case 'parent':
                        return <img key={key} src={parent} />
                      case 'publicTransport':
                        return <img key={key} src={publicTransport} />
                      case 'scooter':
                        return <img key={key} src={scooter} />
                      case 'walking':
                        return <img key={key} src={KidWalk} />
                      default:
                        return null
                    }
                  }
                  return null
                })}
              </div>
            </>
          )}
        </Col>

        <Col xl={{ span: 18 }} span={24} className="mt-10">
          {data?.preferences?.sleeveSize && (
            <>
              <Space align="center" size={10}>
                <h5>
                  <b className="b1">Clothes prefferences</b>
                </h5>
              </Space>
              <div className="mt-8px">
                <span className="b3 text-18 text-capitalize w-300px">
                  {data?.preferences?.uniformSize}
                </span>

                {data?.preferences?.sleeveSize && (
                  <span className="b3 text-15 w-500 grey-txt  text-capitalize">
                    {data?.preferences?.sleeveSize}
                  </span>
                )}
              </div>
            </>
          )}
        </Col>
      </div>
    </Col>
  )
}
export default GeneralDetail
