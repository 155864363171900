import filterSvg from 'assets/Images/filter.svg'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Down from 'helper/Icons/DownIcon'
import { ArrowUp } from '@phosphor-icons/react'
import { Dropdown, Menu, Space } from 'antd'
import CheckInOut from './CheckInOut'
import { useParams } from 'react-router-dom'
import { CloseDropeDown } from 'helper/CloseDropdown'
import { DownOutlined } from '@ant-design/icons'
import downloadImg from 'assets/Images/Download.svg'
import ExportModalAttendee from './Table/ExportModalAttendee'
import { useQuery } from '@tanstack/react-query'
import { getExportEventAttendee } from 'api/Events'
import ExportEventAttendee from './Table/ExportEventAttendee'
const AttendanceHeader = ({ setParms, fullData }: any) => {
  const { id }: any = useParams()
  const handleSearchChange = (e: any) => {
    setParms((prevParms: any) => ({
      ...prevParms,
      searchQuery: e,
    }))
  }
  const { data, refetch, isLoading } = useQuery(
    ['getExportEventAttendee'],
    () => getExportEventAttendee(id),
    { enabled: false },
  )
  useEffect(() => {
    refetch()
  })

  const [open, setOpen] = useState(0)
  const [showCheckIn, setShowCheckIn] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })

  const debounced = debounce(handleSearchChange, 300)
  const Tickets = [
    {
      key: '1',
      label: <h4>Tickets</h4>,
    },
  ]
  const Attendie = [
    {
      key: '1',
      label: <h4>Attendie</h4>,
    },
  ]
  const Gender = [
    {
      key: '1',
      label: <h4>Gender</h4>,
    },
  ]
  const School = [
    {
      key: '1',
      label: <h4>School</h4>,
    },
  ]
  const Grade = [
    {
      key: '1',
      label: <h4>Grade</h4>,
    },
  ]
  const RegisteredDate = [
    {
      key: '1',
      label: <h4>Registered</h4>,
    },
  ]

  const [hiddenColumns, setHiddenColumns] = useState<any>({
    family: true,
    guardian: true,
    adults: true,
    kids: true,
    primarycontact: true,
    status: true,
    action: true,
  })
  const [isExportModalVisible, setExportModalVisible] = useState(false)
  const handleModalClose = () => {
    setExportModalVisible(false)
  }
  const handleExportClick = () => {
    setExportModalVisible(true)
  }
  const menu = (
    <Menu>
      <Menu.Item onClick={handleExportClick}>
        <Space>
          <img alt="img" src={downloadImg} /> Export
        </Space>
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex align-center">
          <img src={filterSvg} alt="filter" />
          <div className="search-input-box ml-20px">
            <input
              type="search"
              className="search-input-group "
              placeholder="Search"
              style={{ width: '350px' }}
              onChange={e => {
                e.preventDefault()
                debounced(e.target.value)
              }}
            />
            <SearchOutlined />
          </div>
        </div>
        <div>
          {localStorage.getItem('role') === 'admin' && (
            <Dropdown
              overlay={menu}
              placement="bottom"
              className="bg-grey mx-20px"
            >
              <button className=" prim-sm-btn" style={{ color: 'black' }}>
                More <DownOutlined />
              </button>
            </Dropdown>
          )}

          <button
            className="borderd-btn"
            onClick={() => {
              setShowCheckIn(true)
            }}
          >
            Check-in/out
          </button>
          <button className="borderd-btn ml-20px">Contact parents</button>
        </div>
      </div>
      <div className="flex justify-between align-center mt-25px">
        <div
          className="flex align-center"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <h2 className="w-500 text-14">Filter by:</h2>
          <Dropdown
            menu={{
              items: Tickets,
            }}
            placement="bottomLeft"
            arrow={false}
            className="ml-20px mr-10px"
            open={open === 1}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 1 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(1)}
            >
              Ticket <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: Attendie,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 2}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 2 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(2)}
            >
              Attendee age <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: Gender,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 3}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 3 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(3)}
            >
              Gender
              <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: School,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 4}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 4 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(4)}
            >
              School
              <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: Grade,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 5}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 5 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(5)}
            >
              Grade
              <Down />
            </button>
          </Dropdown>
          <Dropdown
            menu={{
              items: RegisteredDate,
            }}
            placement="bottomLeft"
            arrow={false}
            className="mr-10px "
            open={open === 6}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 6 ? 'filter-btn-click ' : 'filter-btn'
              } w-500`}
              onClick={() => setOpen(6)}
            >
              Registered Date
              <Down />
            </button>
          </Dropdown>
        </div>
        <div
          className="flex align-center"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <h2 className="w-500 text-14">Sort by:</h2>
          <Dropdown
            menu={{
              items: Grade,
            }}
            placement="bottomRight"
            arrow={false}
            className="ml-20px mr-10px"
            open={open === 7}
            overlayStyle={{ width: '300px' }}
          >
            <button
              className={` ${
                open === 7 ? 'filter-btn-click ' : 'filter-btn'
              } w-500 align-center`}
              onClick={() => setOpen(7)}
            >
              <ArrowUp
                size={18}
                color={'#9ea1a3'}
                style={{
                  position: 'relative',
                  top: '4px',
                }}
              />{' '}
              First Name
              <Down />
            </button>
          </Dropdown>
        </div>
      </div>
      {showCheckIn && (
        <CheckInOut open={showCheckIn} setOpen={setShowCheckIn} />
      )}
      {/* previous Export
      <ExportModalAttendee
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={fullData}
      /> */}
      <ExportEventAttendee
        visible={isExportModalVisible}
        onClose={handleModalClose}
        data={data}
      />
    </div>
  )
}
export default AttendanceHeader
