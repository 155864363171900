import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { Modal, Steps } from 'antd'
import { Layout } from 'antd'
import BasicInfo from './BasicInfo'
import Personality from './Personality'
import Abilities from './Levels'
import { useDispatch } from 'react-redux'
import Cancel from 'assets/Images/close.svg'
import { useMutation } from '@tanstack/react-query'
import { addNewKid } from 'api/family'
import { failureMessage, successMessage } from 'api/global'
import { myStore } from 'state/eventStore'
import actions from 'Redux/Session/action'
import Loader from 'helper/Loader'
const { Step } = Steps
const steps = [
  {
    title: 'General details',
    content: <BasicInfo />,
  },
  {
    title: 'Personality',
    content: <Personality />,
  },
  {
    title: 'Abilities',
    content: <Abilities />,
  },
]
interface KidModel {
  isModalVisible: boolean
  setModalVisible: Dispatch<SetStateAction<boolean>>
  familyId: string
  currentData?: any
  dataChild?: any
  currentStep?: any
  isUpdate?: any
  setIsUpdated?: any
  closePrevModel?: () => void
}
const Kids: React.FC<KidModel> = ({
  isModalVisible,
  currentData,
  setModalVisible,
  dataChild,
  currentStep,
  isUpdate,
  setIsUpdated,
  familyId,
  closePrevModel,
}: any) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(currentData || dataChild || {})
  const [loading, setIsLoading] = useState<boolean>(false)
  const [current, setCurrent] = React.useState(
    !dataChild?._id ? 0 : currentStep,
  )
  const setTag = myStore(store => store.setTag)

  const createKidMutation = useMutation({
    mutationFn: async body => {
      return await addNewKid(familyId, body)
    },
    onSuccess: response => {
      dispatch({
        type: actions.KID_ADD_SUCCESS,
        payload: response,
      })
      successMessage('New Kid added to Family successfully')
      // setModalVisible(false)
      closePrevModel && closePrevModel()
      setTag(true)
    },
    onError: err => {
      failureMessage(`Unable to create new Kid ${err}`)
    },
  })
  const [kidId, setKidId] = useState(dataChild?._id)
  const onSubmit = (formData: any) => {
    const dataValues = {
      ...formData,
    }
    if (data._id || kidId) {
      // dispatch({
      //   type: actions.UPDATE_CHILD_REQUEST,
      //   payload: dataValues,
      //   kidId: kidId || dataChild?._id,
      // })
      // setModalVisible(false)
      // dispatch({
      //   type: actions.RESET_KID_VALUE,
      // })
    } else {
      createKidMutation.mutate(dataValues)
      // setModalVisible(true)
      // setModalVisible(createKidMutation.isLoading)
      // closePrevModel && closePrevModel()

      // setModalVisible(createKidMutation.isLoading)
    }
    setIsUpdated(!isUpdate)
  }
  const handleOk = () => {
    // setModalVisible(false)
  }
  const handleCancel = () => {
    setModalVisible(false)
    // dispatch({ type: actions.RESET_KID_VALUE })
  }
  const next = (data: any) => {
    setData(data)
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  useEffect(() => {
    setIsLoading(createKidMutation.isLoading)
  }, [createKidMutation.isLoading])
  return (
    <Layout>
      <Modal
        className="addNewChildModal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
      >
        <div className="flex-center  pt-40px justify-between border-btm pb-20px">
          <h2 className="w-600 text-24">
            {dataChild?._id ? 'Edit Child' : 'Add New Child'}
          </h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <div className=" mt-20px">
          <Steps current={current}>
            {steps.map(item => (
              <Step
                key={item.title}
                className="stepper-menu-item"
                title={item.title}
              />
            ))}
          </Steps>
        </div>
        <div className="mt-20px h-60vh  modal-h-child">
          {current === 0 && (
            <BasicInfo
              data={data}
              handleNext={next}
              kidId={kidId}
              setKidId={setKidId}
            />
          )}
          {current === 1 && (
            <Personality
              data={data}
              handleBack={prev}
              handleNext={next}
              handleCancel={() => {
                setCurrent(2)
              }}
            />
          )}
          {current === 2 && (
            <Abilities
              data={data}
              handleBack={prev}
              setModalVisible={setModalVisible}
              onSubmit={onSubmit}
              loading={loading}
            />
          )}
        </div>
      </Modal>
    </Layout>
  )
}
export default Kids
