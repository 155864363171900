import {
  Button,
  Dropdown,
  Menu,
  Space,
  Table,
  Tooltip,
  Select,
  Avatar,
  Row,
  Col,
  Checkbox,
} from 'antd'
import Spinner from 'components/Spinner'
import usePagination from 'helper/usePagination'
import React, { useState, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { DownOutlined } from '@ant-design/icons'
import { history } from '../../../Redux/app-store'
import filterSvg from 'assets/Images/filter.svg'
import { useQuery, useMutation } from '@tanstack/react-query'
import { getOrganizationFamily } from '../../../api/Organizations'
import { useLocation } from 'react-router'
import { RequestParms } from '../../../types/globalTypes'
import active from '../../../assets/Images/activeGreen.png'
import pendingImg from '../../../assets/Images/pending.svg'
import type { SorterResult } from 'antd/es/table/interface'
import blockedImg from '../../../assets/Images/blocked.svg'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import type { ColumnsType } from 'antd/es/table'
import { getNameSignature } from '../../../helper/utility'
import moment from 'moment'
import StatusDropdown from '../../../helper/StatusDropdown'
import { getFamiliesForOrganization } from 'api/Organizations'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { title } from 'process'
interface DataType {
  key: React.Key
  name: string
  email: String
  number: string
  dob: String
  totalHours: Number
  trainingLevel: Number
  status: String
}
const FamiliesOrg = () => {
  const { id }: any = useParams()
  const [parms, setParms] = useState<any>({
    page: 1,
    limit: 10,
  })

  const { isLoading, data, refetch } = useQuery(
    ['getFamiliesForOrganization', parms],
    () => getFamiliesForOrganization(id, parms),
  )

  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })
  const StatusMenu = [
    {
      key: 'subscribed',
      label: <div>subscribed</div>,
    },
    {
      key: 'followed',
      label: <div>followed</div>,
    },
    {
      key: 'past',
      label: <div>Previous</div>,
    },
  ]
  const [statusSort, setStatusSort] = useState('')
  const [familySort, setFamilySort] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedKey, setSelectedKey] = useState<any>()
  const [showMoreIdPhone, setShowMoreIdPhone] = useState<any>([])
  const [showMoreId1, setShowMoreID1] = useState<any>([])
  const [alertModal, setAlertModal] = useState(false)
  const [showMoreId, setShowMoreID] = useState<any>([])
  const [selectedItem, setSelectedItem] = useState<DataType | null>(null)
  const [hidCols, setHiddenColumns] = useState<any>({
    family: true,
    members: true,
    contact: true,
    adults: true,
    kids: true,
    status: true,
    action: true,
  })
  const onChangeColumnCheckBox = (e: any) => {
    const updatedHiddenColumns = {
      ...hidCols,
      [e.target.name]: e.target.checked,
    }

    setHiddenColumns(updatedHiddenColumns)
    localStorage.setItem('FamilyCols', JSON.stringify(updatedHiddenColumns))
  }
  useEffect(() => {
    refetch()
  }, [parms])
  const menu2 = (
    <Menu
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.family}
                  name="family"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Family</Col>
            </Row>
          ),
          key: '1',
        },

        {
          label: (
            <Row gutter={20} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.members}
                  name="members"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Guardians</Col>
            </Row>
          ),
          key: '2',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.contact}
                  name="contact"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Contact</Col>
            </Row>
          ),
          key: '3',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.adults}
                  name="adults"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Adults</Col>
            </Row>
          ),
          key: '4',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.kids}
                  name="kids"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Kids</Col>
            </Row>
          ),
          key: '5',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.status}
                  name="status"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Status</Col>
            </Row>
          ),
          key: '6',
        },
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4} style={{ marginRight: '10px' }}>
                <Checkbox
                  defaultChecked={hidCols.action}
                  name="action"
                  onChange={onChangeColumnCheckBox}
                ></Checkbox>
              </Col>
              <Col span={18}>Action</Col>
            </Row>
          ),
          key: '7',
        },
      ]}
    />
  )
  const { Option } = Select
  const handleChangeStatus = (value: string) => {
    setStatusSort(value)
  }
  const handleChangeFamilies = (value: string) => {
    if (value === 'all') {
      setParms({ ...parms, sub: false, follow: false, previous: false })
    } else if (value === 'sub') {
      setParms({ ...parms, sub: true, follow: false, previous: false })
    } else if (value === 'follow') {
      setParms({ ...parms, sub: false, follow: true, previous: false })
    } else if (value === 'previous') {
      setParms({ ...parms, sub: false, follow: false, previous: true })
    }
  }
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const [visible, setVisible] = useState(false)
  const handleSearchChange = (e: any) => {
    setSearchQuery(e)
    setParms({
      ...parms,
      searchString: e,
    })
  }
  const debounced = debounce(handleSearchChange, 300)
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {},
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  const handleChange = (key: string) => {
    if (selectedItem) {
      //    updateAdminStatus.mutate({
      //      organizationKey,
      //      data: selectedItem,
      //      status: key,
      //    })
      refetch()
    }
  }
  const menu = (
    <Menu
      className="absolute w-full"
      selectedKeys={[selectedKey]}
      onClick={item => {
        setSelectedKey(item.key)
        handleChange(item.key)
      }}
    >
      <Menu.Item key={'approved'}>
        <img alt="img" src={active}></img> Approved
      </Menu.Item>
      <Menu.Item key={'requested'}>
        <img alt="img" src={pendingImg}></img> Pending
      </Menu.Item>
      <Menu.Item key={'rejected'}>
        <img alt="img" src={pendingImg}></img> Archived
      </Menu.Item>
      <Menu.Item key={'archived'}>
        <img alt="img" src={blockedImg}></img> Declined
      </Menu.Item>
    </Menu>
  )
  const [sortedInfo] = useState<SorterResult<any>>({})
  const columns = [
    {
      title: 'Family',
      dataIndex: 'family',
      key: 'family',
      sorter: (a: any, b: any) => a.family.length - b.family.length,
      sortOrder: sortedInfo.columnKey === 'family' ? sortedInfo.order : null,
      hidden: true,
      render: (text: any, record: any) => (
        <Space size={8} className="cursor_pointer">
          {/* {record.familyAvatar && (
            <Avatar
              size={24}
              icon={<img alt="img" src={record.familyAvatar}></img>}
            ></Avatar>
          )} */}
          {text}
        </Space>
      ),
      className: hidCols.family === false ? 'display-none' : '',
    },
    {
      title: 'Guardians',
      dataIndex: 'members',
      key: 'members',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      sortOrder: sortedInfo.columnKey === 'members' ? sortedInfo.order : null,
      hidden: true,
      render: (members: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.members?.map((member: any, index: number) => {
              if (showMoreId.includes(record.key)) {
                return (
                  <Col>
                    <Space
                      size={12}
                      onClick={() => {
                        history.push({
                          pathname: `/parent-profile/${member?._id}`,
                        })
                      }}
                      className="pointer flex align-start"
                    >
                      <Avatar src={member.photo?.post}>
                        {getNameSignature(member?.firstName)}
                      </Avatar>
                      <div>
                        <h3 className="text-14 w-600">{`${
                          member.firstName || member.name
                        } ${member?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {member?.relation?.name}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Space
                      size={12}
                      onClick={() => {
                        history.push({
                          pathname: `/parent-profile/${member?._id}`,
                        })
                      }}
                      className="pointer flex align-start"
                    >
                      <Avatar src={member.photo?.post}>
                        {getNameSignature(member?.firstName)}
                      </Avatar>

                      <div>
                        <h3 className="text-14 w-600">{`${
                          member.firstName || member.name
                        } ${member?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {member?.relation?.name}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              }
            })}

            {record.kids?.length > 2 && !showMoreId.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  setShowMoreID([...showMoreId, record.key])
                }}
              >
                Show More
                <DownOutlined />
              </h5>
            )}
            {showMoreId.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
      className: hidCols.members === false ? 'display-none' : '',
    },

    {
      title: 'Primary Contact',
      dataIndex: 'emergencyContacts',
      key: 'emergencyContacts',
      width: 200,
      sorter: (a: any, b: any) =>
        a.emergencyContacts.length - b.emergencyContacts.length,
      sortOrder:
        sortedInfo.columnKey === 'primarycontact' ? sortedInfo.order : null,
      hidden: true,
      render: (text: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]}>
            {record.members?.map((member: any, index: number) => {
              if (showMoreIdPhone.includes(record.key)) {
                return (
                  <Col className="mb-16px">
                    <div> {member?.phone?.number} </div>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col className="mb-24px">
                    <div> {member?.phone?.number} </div>
                  </Col>
                )
              }
            })}

            {record.kids &&
              record.members?.length > 2 &&
              !showMoreIdPhone.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreIdPhone([...showMoreId1, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreIdPhone.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId1.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreIdPhone([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
      className: hidCols.contact === false ? 'display-none' : '',
    },
    {
      title: 'Adults',
      dataIndex: 'authorizedAdults',
      key: 'authorizedAdults',
      sorter: (a: any, b: any) =>
        a.authorizedAdults.length - b.authorizedAdults.length,
      sortOrder:
        sortedInfo.columnKey === 'authorizedAdults' ? sortedInfo.order : null,
      hidden: true,
      render: (members: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.authorizedAdults?.map((member: any, index: number) => {
              if (showMoreId.includes(record.key)) {
                return (
                  <Col>
                    <Space
                      size={12}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: `/parent-profile/${member?._id}`,
                      //   })
                      // }}
                      className="pointer flex align-start"
                    >
                      <Avatar src={member.photo?.post}>
                        {getNameSignature(member?.firstName)}
                      </Avatar>
                      <div>
                        <h3 className="text-14 w-600">{`${
                          member.firstName || member.name
                        } ${member?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {member?.relation?.name}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Space
                      size={12}
                      // onClick={() => {
                      //   history.push({
                      //     pathname: `/parent-profile/${member?._id}`,
                      //   })
                      // }}
                      className="pointer flex align-start"
                    >
                      <Avatar src={member.photo?.post}>
                        {getNameSignature(member?.firstName)}
                      </Avatar>

                      <div>
                        <h3 className="text-14 w-600">{`${
                          member.firstName || member.name
                        } ${member?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {member?.relation?.name}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              }
            })}

            {record.authorizedAdults?.length > 2 &&
              !showMoreId.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreID([...showMoreId, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreId.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
      className: hidCols.adults === false ? 'display-none' : '',
    },
    {
      title: 'Kids ',
      dataIndex: 'Kids',
      key: 'Kids',
      sorter: (a: any, b: any) => a.kids.length - b.kids.length,
      sortOrder: sortedInfo.columnKey === 'kids' ? sortedInfo.order : null,
      hidden: true,
      render: (text: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.kids?.map((kid: any, index: number) => {
              if (showMoreId1.includes(record.key)) {
                return (
                  <Col>
                    <Space size={12} className="pointer flex align-start">
                      <Avatar src={kid.photo?.post}>
                        {getNameSignature(kid?.firstName)}
                      </Avatar>
                      <div>
                        <h3 className="text-14 w-600">{`${
                          kid.firstName || kid.name
                        } ${kid?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {moment(kid?.dob).format('DD MMM,YYYY')}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Space size={12} className="pointer flex align-start">
                      <Avatar src={kid.photo?.post}>
                        {getNameSignature(kid?.firstName)}
                      </Avatar>
                      <div>
                        <h3 className="text-14 w-600">{`${
                          kid.firstName || kid.name
                        } ${kid?.lastName}`}</h3>
                        <p className="text-12 w-400 grey-txt">
                          {moment(kid?.dob).format('DD MMM,YYYY')}
                        </p>
                      </div>
                    </Space>
                  </Col>
                )
              }
            })}

            {record.kids &&
              record.kids.length > 2 &&
              !showMoreId1.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreID1([...showMoreId1, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreId1.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId1.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID1([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
      className: hidCols.kids === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      hidden: true,

      render: (text, record: any) => (
        <Dropdown
          menu={{
            items: StatusMenu,
            // onClick: handleMenuClick,
          }}
        >
          <Button className="status_button">
            <Space>
              {text === 'subscribed'
                ? 'Subscribed'
                : text === 'followed'
                ? 'Followed'
                : 'past'
                ? 'Previous'
                : ''}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
      className: hidCols.status === false ? 'display-none' : '',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'action' ? sortedInfo.order : null,
      hidden: true,
      className: hidCols.action === false ? 'display-none' : '',
    },
  ]

  const members = data?.records.map((item: any) => ({
    key: item._id,
    family: item.name,
    familyAvatar: item.photo?.post,
    guardians: item.owner,
    emergencyContacts: item.emergencyContacts,
    members: item?.guardians,
    kids: item?.kids,
    authorizedAdults: item?.authorizedAdults,
    adults: item?.adults,
    // status: 'active',
    status: item.subscribed
      ? 'subscribed'
      : item.requested
      ? 'requested'
      : item.followed
      ? 'followed'
      : item.past
      ? 'past'
      : 'active',
    action: [
      <Space key={item.id} className="orgn-action-btn">
        <img
          src={editblackPencil}
          width={20}
          // onClick={() => {
          //   setShowEdit(true)
          //   setFamilyData(item)
          // }}
          className="cursor_pointer"
          alt="edit"
        />
        <img
          src={deleteBlackIcon}
          // onClick={e => {
          //   setAlertModal(true)
          //   setUserId(item._id)
          // }}
          className="cursor_pointer"
          alt="delete"
        />
      </Space>,
    ],
  }))

  return (
    <>
      <div className="bg-grey padding-top-10">
        <div className="bg-white" style={{ paddingTop: '30px' }}>
          <div className="p-20px flex justify-between">
            <div className="flex justify-between align-center w-35p">
              <div style={{ marginRight: '15px' }}>
                <Dropdown
                  overlay={menu2}
                  onOpenChange={handleVisibleChange}
                  open={visible}
                >
                  <h5 onClick={e => e.preventDefault()}>
                    <Space>
                      <img src={filterSvg} alt=""></img>
                    </Space>
                  </h5>
                </Dropdown>
              </div>
              <div
                className="right-search-block w-100 d-inline-block"
                style={{ maxWidth: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <div
                    className={'search-input-box'}
                    style={{ marginRight: '15px' }}
                  >
                    <input
                      type="search"
                      className="search-input-group"
                      placeholder="Search"
                      onChange={e => debounced(e.target.value)}
                    />
                    <SearchOutlined />
                  </div>
                </div>
              </div>
              <Select
                className="custom-select-field black text-color-black width-140"
                defaultValue="All families"
                placeholder="All families"
                style={{ width: 'auto' }}
                allowClear
                onChange={handleChangeFamilies}
              >
                <Option value="all">All families</Option>
                <Option value="sub">Subscribed</Option>
                <Option value="follow">Following</Option>
                <Option value="previous">Previous Attendance</Option>
              </Select>
              {/* <Select
                className="custom-select-field black text-color-black width-140 ml-10px"
                defaultValue="Status: All"
                placeholder="Status"
                style={{ width: 'auto' }}
                allowClear
                onChange={handleChangeStatus}
              >
                <Option value="">status: All</Option>
                <Option value="subscribed">Subscribed</Option>
                <Option value="following">Following</Option>
                <Option value="private">Previous Attendance</Option>
              </Select> */}
            </div>
          </div>
          {isLoading ? (
            <div className="w-full h-80vh flex justify-center align-center">
              <Spinner />
            </div>
          ) : (
            <Row className="container">
              <Table
                className="organizations_table"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={members}
                pagination={paginationInfo}
                style={{ width: '100%' }}
                onChange={onTableChange}
              />
            </Row>
          )}
        </div>
      </div>
    </>
  )
}
export default FamiliesOrg
