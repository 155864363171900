import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAuthorizedAdultById } from '../../../../api/family'
import { useParams } from 'react-router-dom'
import AppLayout from '../../../../components/layout/layout'
import { Row, Tabs } from 'antd'
import AdultProfileHeader from './Header'
import Notes from '../NotesComp/Notes'
import Spinner from 'components/Spinner'
import AdultsBasicInfo from '../../Profiles/AdultProfile/BasicInformation/index'
const { TabPane } = Tabs

const AdultProfile = () => {
  let { id }: any = useParams()
  const { isLoading, data, refetch } = useQuery({
    queryKey: ['auhorized-adult-by-id'],
    queryFn: () => getAuthorizedAdultById(id),
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [])
  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        <AdultProfileHeader data={data} refetch={refetch} />
        {isLoading ? (
          <div className="flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <Row>
            <Tabs
              defaultActiveKey="1"
              style={{ width: '100%', height: '100%' }}
              className="bg-white profile-tabs"
            >
              <TabPane tab="Basic Info" key="1">
                <AdultsBasicInfo parent={data} refetch={refetch} />
              </TabPane>
              <TabPane tab="Events" key="2">
                <h3>Events will come here</h3>
              </TabPane>
              <TabPane tab="Media" key="3">
                <h3>Media will come here</h3>
              </TabPane>
              <TabPane tab="Documents" key="4">
                <h3>Documents will come here</h3>
              </TabPane>
              <TabPane tab="Notes" key="5">
                <Notes id={id} familyId={data?.familyId} />
              </TabPane>
            </Tabs>
          </Row>
        )}
      </div>
    </AppLayout>
  )
}
export default AdultProfile
