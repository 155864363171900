import { all, put, takeLatest } from 'redux-saga/effects'
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
  axiosPut,
  axiosSave,
} from '../axiosHelper'
import failureAction from '../Failure/action'
import actions from './action'
import sessionActions from '../Session/action'

export function* getFamilies({ payload, params }) {
  try {
    let currentParams = {
      page: params?.current || 1,
      limit: params?.pageSize || 20,
      sort: params?.sort?.order || '-createdAt',
    }

    const { data } = yield axiosGet(
      `v1/admins/families?&populate=kids&populate=guardians&populate=adults&status=${payload.status}`,
      currentParams,
    )
    yield put(actions.getFamiliesSuccess(data))
  } catch (error) {
    yield put(actions.getFamiliesFailure())
    yield put(failureAction.failure(error))
  }
}

export function* getFamily({ payload }) {
  try {
    let params = {
      page: 1,
      populate:
        '[{"path":"photo"}, {"path":"owner","populate":"photo"},{"path":"emergencyContacts"}]',
    }
    const { data } = yield axiosGet(`v1/families/${payload}`, params)
    yield put(actions.getFamilySuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.getFamilyFailure())
  }
}

export function* getFamilyGuardian({ payload }) {
  try {
    let params = {
      page: 1,
      populate:
        '[{"path":"photo"}, {"path":"owner","populate":"photo"},{"path":"emergencyContacts"}]',
    }
    const { data } = yield axiosGet(`v1/families/${payload}/guardians`)
    yield put(actions.getFamilyGuardianSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.getFamilyGuardianFailure())
  }
}

export function* updateFamily({ payload, familyId }) {
  try {
    const { data } = yield axiosPatch(payload, `v1/families/${familyId}`)
    yield put(actions.getFamily(familyId))
    yield put(actions.updateFamilySuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.updateFamilyFailure())
  }
}

export function* updateFamilyStatus({ payload, params }) {
  try {
    const { data } = yield axiosPatch(
      payload,
      `v1/admins/update-status/${params}`,
    )
    yield put(actions.updateFamilyStatusSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.updateFamilyStatusFailure())
  }
}

export function* getAuthorizedAdult({ payload }) {
  try {
    const { data } = yield axiosGet(`v1/authorized-adults/${payload}`)
    yield put(actions.getAuthorizedAdultSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.getAuthorizedAdultFailure())
  }
}

export function* addAuthorizedAdult({ payload, familyId }) {
  try {
    const { data } = yield axiosPost(
      payload,
      `v1/authorized-adults/${familyId}/create-authorized-adult`,
    )
    yield put(actions.getFamily(familyId))
    yield put(actions.addAuthorizedAdultSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.addAuthorizedAdultFailure())
  }
}

export function* authorizedAdultUpdate({ payload, adultId, familyId }) {
  try {
    const { data } = yield axiosPatch(
      payload,
      `v1/authorized-adults/${adultId}`,
    )
    yield put(actions.getFamily(familyId))
    yield put(actions.authorizedAdultUpdateSuccess(data))
    // yield put(sessionActions.authorizedAdultSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.authorizedAdultUpdateFailure())
  }
}

export function* deleteAuthorizedAdult({ adultId }) {
  try {
    const { data } = yield axiosDelete(`v1/authorized-adults/${adultId}`)
    yield put(actions.getFamily(data?.familyId))
    yield put(actions.deleteAuthorizedAdultSuccess())
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.deleteAuthorizedAdultFailure())
  }
}

export function* getFamilyKids({ payload }) {
  try {
    let params = {
      page: 1,
      populate: '[{"path":"photo"}]',
    }
    const { data } = yield axiosGet(`v1/families/${payload}/kids`, params)
    yield put(actions.getFamilyKidsSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.getFamilyKidsFailure())
  }
}
export function* authorizedAdultsAssignKids({ payload }) {
  try {
    const { data } = yield axiosSave(
      payload,
      `v1/authorized-adults/${payload.id}/assign-kids`,
    )
    yield put(actions.addAuthorizedAdultSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.addAuthorizedAdultFailure())
  }
}

//Guardian

export function* createGuardian({ payload }) {
  try {
    const { data } = yield axiosPost(payload, `v1/admins/create-guardian`)
    yield put(actions.addGuardianSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.addGuardianFailure())
  }
}

export function* addGuardian({ payload, familyId }) {
  try {
    yield axiosPost(payload, `v1/families/${familyId}/add-guardian`)
    yield put(actions.addGuardianSuccess())
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.addGuardianFailure())
  }
}

export function* getGuardian({ guardianId }) {
  try {
    const { data } = yield axiosGet(`v1/guardians/${guardianId}`)
    yield put(actions.getAuthorizedAdultSuccess(data))
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.getAuthorizedAdultFailure())
  }
}

export function* addKid({ payload, familyId }) {
  try {
    const res = yield axiosPost(payload, `v1/kids/${familyId}/create-kid`)
    yield put(actions.addKidSuccess(res))
    yield put(actions.getFamily(familyId))
  } catch (error) {
    yield put(actions.addKidFailure())
    yield put(failureAction.failure(error))
  }
}

export function* updateKid({ payload, kidId, familyId }) {
  try {
    const res = yield axiosPatch(payload, `v1/kids/${kidId}`)
    yield put(actions.updateKidSuccess(res))
    yield put(actions.getFamily(familyId))
  } catch (error) {
    yield put(actions.updateKidFailure())
    yield put(failureAction.failure(error))
  }
}

export function* deleteKid({ kidId }) {
  try {
    const { data } = yield axiosDelete(`v1/kids/${kidId}`)
    yield put(actions.getFamily(data?.familyId))
    yield put(actions.deleteKidSuccess())
  } catch (error) {
    yield put(failureAction.failure(error))
    yield put(actions.deleteKidFailure())
  }
}

export function* getKidDetailRequest({ kidId }) {
  try {
    const res = yield axiosGet(`v1/kids/${kidId}`)
    yield put(actions.getKidDetailDataSuccess(res?.data))
  } catch (error) {
    yield put(actions.getKidDetailDataFailure())
    yield put(failureAction.failure(error))
  }
}

export function* assignKidsToAdults({ adultId, payload }) {
  try {
    const res = yield axiosPut(
      payload,
      `v1/authorized-adults/${adultId}/assign-kids`,
    )
    yield put(actions.assignKidsToAdultSuccess(res?.data))
    yield put(sessionActions.authorizedAdultSuccess(res?.data))
  } catch (error) {
    yield put(actions.assignKidsToAdultFailure())
    yield put(failureAction.failure(error))
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_FAMILIES, getFamilies),
    takeLatest(actions.GET_FAMILY, getFamily),
    takeLatest(actions.GET_FAMILY_GUARDIAN, getFamilyGuardian),
    takeLatest(actions.UPDATE_FAMILY, updateFamily),
    takeLatest(actions.UPDATE_FAMILY_STATUS, updateFamilyStatus),
    takeLatest(actions.GET_AUTHORIZED_ADULT, getAuthorizedAdult),
    takeLatest(actions.ADD_AUTHORIZED_ADULT, addAuthorizedAdult),
    takeLatest(actions.GET_FAMILY_KIDS, getFamilyKids),
    takeLatest(
      actions.AUTHORIZED_ADULTS_ASSIGN_KIDS,
      authorizedAdultsAssignKids,
    ),
    takeLatest(actions.AUTHORIZED_ADULT_UPDATE, authorizedAdultUpdate),
    takeLatest(actions.ADD_GUARDIAN, addGuardian),
    takeLatest(actions.CREATE_GUARDIAN, createGuardian),
    takeLatest(actions.DELETE_AUTHORIZED_ADULT, deleteAuthorizedAdult),
    takeLatest(actions.GET_GURADINA, getGuardian),
    takeLatest(actions.ADD_KID, addKid),
    takeLatest(actions.UPDATE_KID, updateKid),
    takeLatest(actions.DELETE_KID, deleteKid),
    takeLatest(actions.GET_KID_DETAIL, getKidDetailRequest),
    takeLatest(actions.ASSIGN_KIDS_TO_ADULT, assignKidsToAdults),
  ])
}
