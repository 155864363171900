import {
  PaperClipOutlined,
  SendOutlined,
  SmileTwoTone,
} from "@ant-design/icons";
import {
  Avatar,
  Col,
  Comment,
  Form,
  Input,
  List,
  Row,
  Upload,
  UploadProps,
} from "antd";
import Picker from "emoji-picker-react";
import moment from "moment";
import React, { useState } from "react";
const { TextArea } = Input;
interface CommentItem {
  author: string;
  content: React.ReactNode;
  datetime: string;
}
interface DataType {  
  gender?: string;
  name: {
    title?: string;
    first?: string;
    last?: string;
  };
  email?: string;
  picture: {
    large?: string;
    medium?: string;
    thumbnail?: string;
  };
  nat?: string;
  loading: boolean;
}
interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => any;
  onSubmit: (e: any) => any;
  submitting: boolean;
  value: string;
}
const CommentList = ({ comments }: { comments: CommentItem[] }) => (
  <List
    className="message-list-conversation"
    dataSource={comments}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);
const data1 = [
  {
    title: "Sam",
    description: "Father",
  },
  {
    title: "Jinny",
    description: "Mother",
  },
  {
    title: "Penny",
    description: "Admin",
  },
  {
    title: "Sanny",
    description: "Father",
  },
];
const props: UploadProps = {
  name: "file",
  accept: "video/*, image/*, .doc, .pdf",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info: any) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
  },
};
const Editor = ({ onChange, onSubmit, value }: EditorProps) => {
  const [Emojistatus, setChosenstatus] = useState<boolean>(false);
  const [isText, setIstext] = useState<any>("");
  const onEmojiClick = (event: any, emojiObject: any) => {
    setIstext(isText + emojiObject.emoji);
    console.log("emoi", isText);
    setChosenstatus(!Emojistatus);
  };
  const onclickValue = () => {
    onSubmit(isText);
    setIstext("");
  };
  const handleKeyDown = (event : any) => {
    if (event.key === 'Enter') {
      onSubmit(isText);
      setIstext("");
    }
  }
  return (
    <div>
      <div className="message-chat-bord">
        <div>
          <div className="custom-text-area">
            <div>
              <SmileTwoTone onClick={() => setChosenstatus(!Emojistatus)} />
              {Emojistatus && <Picker onEmojiClick={onEmojiClick} />}
            </div>
            <Form.Item className="file-attechment">
              <Upload {...props}>
                <PaperClipOutlined />
              </Upload>
            </Form.Item>
            <Form.Item  className="text-input-group">
              <Input
                className="custom-chat-textarea"
                // onChange={onChange}
                onChange={(e) => setIstext(e.target.value)}
                onKeyDown={handleKeyDown}
                value={isText}
              />
              <SendOutlined onClick={() => onclickValue()} type="primary" />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};
const Chat = ({ activeData, mentorsData }: any) => {
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const handleSubmit = (text: any) => {
    setValue(text);
    if (!value) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      setValue("");
      setComments([
        ...comments,
        {
          author: "Admin",
          content: <p>{value}</p>,
          datetime: moment().format('hh:mm'),
        },
      ]);
    }, 100);
  };
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };
  return (
    <div className="right-side-contact-list">
      <div className="main-bg-custom-chat">
        {comments.length > 0 && <CommentList comments={comments} />}
        <div className="contact-list-chat">
          <Comment
            content={
              <Editor
                onChange={handleChange}
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                submitting={submitting}
                value={value}
              />
            }
          />
        </div>
      </div>
      <div className="contact-list">
        <List
          className="chat-list"
          itemLayout="horizontal"
          dataSource={data1}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={<h5>{item.title}</h5>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};
export default Chat;