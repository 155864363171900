import { Button, Col, Modal, Row, Space } from "antd";
import { useState } from "react";

const DeleteAlert = ({ setIsModalVisible, data, onYesClick }: any) => {
  const [open, setOpen] = useState<boolean>(true);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setOpen(false);
  };
  return (
    <>
      <Modal
        className="delete-modal"
        open={open}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        footer={null}
        centered={true}
        onCancel={handleCancel}
        width={438}
        closable={data?.closeIcon}
      >
        <Row gutter={[0, 40]} justify="center">
          <Col span={24}>
            <div className="text-center">
              <Space direction="vertical">
                <h3 className="b1">{data?.heading}</h3>
                <div
                  className="b3"
                  dangerouslySetInnerHTML={{ __html: data?.message1 }}
                ></div>
                <h5 className="b1">{data?.message2}</h5>
              </Space>
            </div>
          </Col>
          <Col>
            <div>
              <Space size={20}>
                <Button
                  onClick={handleCancel}
                  className="alert_delete_button bg-grey"
                >
                  Cancel
                </Button>
                <Button
                  className="alert_delete_button text-white"
                  onClick={() => {
                    onYesClick();
                    handleCancel();
                  }}
                >
                  {data?.okText ? data?.okText : "Yes, delete"}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default DeleteAlert;
