import { Typography, Form, Row, Col, Button, notification } from "antd";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import "antd-country-phone-input/dist/index.css";
import { ConfigProvider } from "antd-country-phone-input";
import CountryPhoneCode from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import bamreclogo from "../../assets/Images/bamrec-logo.svg";
import { useState } from "react";
import { authentication } from "../../Firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import PhoneVerify from "./PhoneVerify";

const PhooneLogin = ({ authenticated }: any) => {
  const [phone, setPhone] = useState<any>({});
  const [confirmObj, setConfirmObj] = useState<any>("");
  const [flag, setFlag] = useState(false);
  const [form] = Form.useForm();
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    // for dumi
    if (typeof data === "string") {
      return data;
    }
    // for CRA
    return data.default;
  };
  const onFinish = (values: any) => {
    let mynumber = "+" + phone.code + phone.phone;
    const generateRecaptcha = () => {
      let recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response: any) => {
            console.log(response);
          },
        },
        authentication
      );
      recaptchaVerifier.render();
      signInWithPhoneNumber(authentication, mynumber, recaptchaVerifier)
        .then((confirmationResult) => {
          console.log(confirmationResult);
          setConfirmObj(confirmationResult);
          setFlag(true);
        })
        .catch((error) => {
          notification["error"]({
            message: error,
          });
        });
    };
    if (phone.phone.length >= 10) {
      generateRecaptcha();
    } else {
      notification["error"]({
        message: "Please enter valid phone number",
      });
    }
  };

  if (authenticated) {
    return <Redirect to="/dashboard"></Redirect>;
  }
  return (
    <>
      {flag ? (
        <PhoneVerify confirmObj={confirmObj} />
      ) : (
        <div className={"login-form-container"}>
          <Row style={{ width: "100%" }}>
            <Col md={24} lg={10} xl={10} style={{ background: "#fff" }}>
              <div className="left-login-form">
                <div>
                  <Row>
                    <Col span={6}>
                      <Typography className={"title-fontStyle text-center"}>
                        <img alt="img" src={bamreclogo} />
                      </Typography>
                    </Col>
                  </Row>
                </div>
                <div className={"margin-20"}>
                  <span>
                    <h1 className="login-with-email">Hi, Welcome back!</h1>
                    <h5 className="b3">
                      We will send a 4 digit OTP to verify your mobile number
                    </h5>
                  </span>
                  <div>
                    <Form form={form} name="register" onFinish={onFinish}>
                      <Form.Item
                        className="form-control"
                        style={{ paddingTop: "32px" }}
                      >
                        <label className="input-label">Phone Number</label>
                        <ConfigProvider
                          locale={en}
                          areaMapper={(area) => {
                            return {
                              ...area,
                              emoji: (
                                <img
                                  alt="flag"
                                  style={{
                                    width: 18,
                                    height: 18,
                                    verticalAlign: "sub",
                                  }}
                                  src={getFlag(area.short)}
                                />
                              ),
                            };
                          }}
                        >
                          <CountryPhoneCode
                            placeholder="Phone Number"
                            defaultValue={{ short: "India" }}
                            value={phone}
                            onChange={(v) => {
                              setPhone(v);
                            }}
                            className="your-custom-class"
                          />
                        </ConfigProvider>
                      </Form.Item>
                      <Form.Item className="mt-30">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          id="sign-in-button"
                        >
                          <span
                            style={{
                              color: "white",
                              fontSize: "14px",
                              alignItems: "center",
                            }}
                          >
                            Send code
                          </span>
                        </Button>
                      </Form.Item>
                      <Row
                        className="text-center signup-link phone-login-dont-acct"
                        justify="center"
                      >
                        <p>
                          Don’t have account?{" "}
                          <a href="/Signup">Contact admin</a>
                        </p>
                        <Button></Button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={24} lg={14} xl={14}>
              <div className="right-col-blue"></div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(PhooneLogin);
