import { Avatar, Button, Card, Col, Row, Space, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Amin from "../../../assets/Images/Amin.png";
import Action from "../../../assets/Images/Action.svg";
import People from "../../../assets/Images/People.svg";
import actions from "../../../Redux/Session/action";
import AddNoteModal from "../../Modals/EventsModal/EventDetails/AddNote/AddNoteModal";
import LiveUpdateModal from "../../Modals/EventsModal/EventDetails/LiveUpdate/LiveUpdateModal";
const { Text, Title } = Typography;
const SessionActivity = ({
  contractRoomId,
  liveUpdateDataList,
  notesDataList,
}: any) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLiveUpdatesModalVisible, setLiveUpdatesModalVisible] =
    useState(false);

  useEffect(() => {
    dispatch({
      type: actions.GET_LIVE_UPDATE_DATA,
      payload: { _id: contractRoomId },
    });
  }, []);

  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <Col span={12}>
        <div className="profile_container">
          <div className="d-flex justify-between mb-20">
            <h6 className="b1 text-color-black mb-0">Live Update</h6>
            <Button
              // className="register_child_button-events br-8"
              style={{ marginLeft: "30px", borderRadius: "10px" }}
              type="primary"
              onClick={() => setLiveUpdatesModalVisible(true)}
            >
              Add live Update
            </Button>
          </div>
          {/* {liveUpdateDataList?.map((post: any) => {
            return (
              <Card className="mb-20">
                <Row gutter={[0, 15]} className="w-100">
                  <Col span={24}>
                    <Row>
                      <Col span={18}>
                        <Meta
                          avatar={
                            <Avatar
                              size={45}
                              src="https://joeschmoe.io/api/v1/random"
                            />
                          }
                          title={
                            <h5 style={{ marginBottom: "0px" }}>
                              <b className="b2 text-black">Ralph Edwards</b>
                              {post?.userName}
                            </h5>
                          }
                          description={
                            <span className="font12 text-secondary">
                              <b className="b3">
                                {moment(post?.createdAt).fromNow()}
                              </b>
                            </span>
                          }
                        ></Meta>
                      </Col>
                      <Col span={6} className="text-right">
                        <button className="o-icon-btn b-transparent cursor_pointer">
                          <img src={Action} alt="Action icon" width={18} />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <img
                          alt="img"
                          src={post?.images[0]?.post}
                          className="br-20 w-100 cover-photo"
                        ></img>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>{post?.text}</Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            );
          })} */}
        </div>
      </Col>
      <Col span={12}>
        <div className="profile_container">
          <div className="d-flex justify-between align-items-center mb-25">
            <Title level={4} style={{ marginBottom: "0px" }}>
              Notes
            </Title>
            <Button
              className="register_child_button-events br-8"
              type="primary"
              onClick={() => setModalVisible(true)}
            >
              Add note
            </Button>
          </div>
          {isModalVisible && (
            <AddNoteModal
              contractRoomId={contractRoomId}
              isModalVisible={isModalVisible}
              setModalVisible={setModalVisible}
            />
          )}
          {isLiveUpdatesModalVisible && (
            <LiveUpdateModal
              contractRoomId={contractRoomId}
              isLiveUpdatesModalVisible={isLiveUpdatesModalVisible}
              setLiveUpdatesModalVisible={setLiveUpdatesModalVisible}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  liveUpdateDataList: state.session.liveUpdateDataList,
  notesDataList: state.session.notesDataList,
});
export default connect(mapStateToProps)(SessionActivity);
// {
//   notesDataList?.map((post: any) => {
//     return (
//       <Row className="mb-16">
//         <Col className="bb-solid-1 b-color-gray pb-20">
//           <Row>
//             <Col span={24}>
//               <div className="d-flex mb-12">
//                 <img src={Amin} className="mr-15" />
//                 <div>
//                   <h5 className="b1">Amin Mirza</h5>
//                   <span className="text-gray-color d-block font13">
//                     Tips • 6 Aug, 2020 at 6:10 PM
//                   </span>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//           <p className="font14">{post?.description}</p>
//         </Col>
//       </Row>
//     );
//   });
// }
