import clock from 'assets/Images/Clock.svg'
import { Row } from 'antd'
import moment from 'moment'
import { convertMinutesToLocalTime, convertToLocal } from 'helper/utility'
function EventSceduleTimings({ datesOfevent, eventTiming, selectedDate }: any) {
  let timeArr: any = []
  datesOfevent?.map((eachDay: any) => {
    let updateTimeArr
    if (eventTiming?.specialSchedules?.length > 0) {
      const specialSchedule = eventTiming?.specialSchedules?.find(
        (item: any) => item.day === eachDay,
      )
      if (specialSchedule) {
        updateTimeArr = [
          ...timeArr,
          {
            date: convertToLocal(eachDay, 'MMM DD YYYY'),
            startTime: convertMinutesToLocalTime(specialSchedule.startTime),
            endTime: convertMinutesToLocalTime(specialSchedule.endTime),
          },
        ]
      } else {
        updateTimeArr = [
          ...timeArr,
          {
            date: convertToLocal(eachDay, 'MMM DD YYYY'),
            startTime: convertMinutesToLocalTime(eventTiming?.startTime),
            endTime: convertMinutesToLocalTime(eventTiming?.endTime),
          },
        ]
      }
    } else {
      updateTimeArr = [
        ...timeArr,
        {
          date: convertToLocal(eachDay, 'MMM DD YYYY'),
          startTime: convertMinutesToLocalTime(eventTiming?.startTime),
          endTime: convertMinutesToLocalTime(eventTiming?.endTime),
        },
      ]
    }
    timeArr = updateTimeArr
  })
  return (
    <>
      <div className={`mt-40`}>
        <h3 className="font14 b2 text-italic text-inactive mt-20">
          Every week till
          {convertToLocal(datesOfevent[datesOfevent.length - 1], 'MMM.DD.YYYY')}
        </h3>
        <Row className={`mt-10 w-100 h-20 overflow-auto`}>
          {timeArr?.map((item: any, index: number) => {
            return (
              <div
                className="d-flex justify-content-between align-center w-100 mt-20"
                key={index}
              >
                <h6
                  className={`font14 mr-10px ${
                    selectedDate === item.date ? 'b1' : 'b3'
                  }`}
                >
                  {item.date}
                </h6>
                <img src={clock} alt="clock-icon" className="w-20px h-20px" />

                <h6
                  className={`font14 mr-10px ${
                    selectedDate === item.date ? 'b1' : 'b3'
                  }`}
                >
                  {item.startTime} - {item.endTime}
                </h6>
              </div>
            )
          })}
        </Row>
      </div>
    </>
  )
}
export default EventSceduleTimings
