import { SingleFamily } from 'pages/Events/types'
import { create } from 'zustand'
const eventStore = (set: any) => ({
  view: 'cardview',
  changeView: (newView: string) => set(() => ({ view: newView })),
  attendeeFamily: {},
  setFamily: (family: SingleFamily) => set(() => ({ attendeeFamily: family })),
  familyLoading: true,
  setFamilyLoading: (loading: boolean) =>
    set(() => ({ familyLoading: loading })),
  tag: false,
  setTag: (newState: boolean) => set(() => ({ tag: newState })),
})
export const myStore = create(eventStore)
