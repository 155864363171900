import {
  Form,
  Button,
  Col,
  DatePicker,
  Input,
  Layout,
  Row,
  Space,
  TimePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState, useEffect } from "react";
import plusCircle from "../../../../../assets/Images/PlusCircle.svg";
import deleteSvg from "../../../../../assets/Images/delete_red.svg";
import ClipboardCopy from "../../../../../helper/ClipboardCopy";
import moment from "moment";
import rightArrowIcon from "../../../../../assets/Images/backarrow.svg";

const TimeAndFrequency = ({ data, handleBack, handleNext }: any) => {
  const [form] = Form.useForm();
  const [option, setOption] = useState(data?.timesPerWeek || "");
  const [specialScheduleInputData, setSpecialScheduleInputData] = useState([]);
  const [scheduleDays, setScheduleDays] = useState<any>([
    {
      day: "Sunday",
      selected: false,
    },
    {
      day: "Monday",
      selected: false,
    },
    {
      day: "Tuesday",
      selected: false,
    },
    {
      day: "Wednesday",
      selected: false,
    },
    {
      day: "Thursday",
      selected: false,
    },
    {
      day: "Friday",
      selected: false,
    },
    {
      day: "Saturday",
      selected: false,
    },
  ]);

  // useEffect(() => {
  //   console.log("data:",data)
  // }, []);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const formSchema = {
    oneTimeDate:
      data?.timesPerWeek === "one_time" &&
      data?.oneTimeSessionSchedule?.startTime
        ? moment(data?.oneTimeSessionSchedule?.startTime)
        : "",
    oneTimeStart:
      data?.timesPerWeek === "one_time" &&
      data?.oneTimeSessionSchedule?.startTime
        ? moment(data?.oneTimeSessionSchedule?.startTime)
        : "",
    oneTimeEnd:
      data?.timesPerWeek === "one_time" && data?.oneTimeSessionSchedule?.endTime
        ? moment(data?.oneTimeSessionSchedule?.endTime)
        : "",
    flexibleStartDate:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.startDate
        ? moment(data?.flexibleSessionSchedule?.startDate)
        : "",
    flexibleEndDate:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.endDate
        ? moment(data?.flexibleSessionSchedule?.endDate)
        : "",
    flexibleRestrictions:
      data?.timesPerWeek === "flexible" &&
      data?.flexibleSessionSchedule?.restrictions
        ? data?.flexibleSessionSchedule?.restrictions
        : "",
    recurringStartDate:
      data?.timesPerWeek === "recurring" &&
      data?.recurringSessionSchedule?.startDate
        ? moment(data?.recurringSessionSchedule?.startDate)
        : "",
    recurringEndDate:
      data?.timesPerWeek === "recurring" &&
      data?.recurringSessionSchedule?.endDate
        ? moment(data?.recurringSessionSchedule?.endDate)
        : "",
  };

  const toggleDaySelected = (value: any) => {
    let updatedArray = scheduleDays.map((sch: any) => {
      if (sch.day === value.day) {
        return {
          day: value.day,
          selected: !value.selected,
        };
      } else {
        return sch;
      }
    });
    setScheduleDays(updatedArray);
  };

  const addSpecialscheduleInput = () => {
    setSpecialScheduleInputData((s): any => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const removeSpecialscheduleInput = () => {
    let dataInput = specialScheduleInputData.splice(1, 1);
    setSpecialScheduleInputData(dataInput);
  };

  const onFinish = (values: any) => {
    // console.log("timesPerWeek:", values, option);
    const dataValues = {
      timesPerWeek: option,
      ...(values.oneTimeStart && {
        oneTimeSessionSchedule: {
          startTime: moment(values.oneTimeStart).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          endTime: moment(values.oneTimeEnd).format("YYYY-MM-DDTHH:mm:ss[Z]"),
        },
      }),
      ...(values.flexibleStartDate && {
        flexibleSessionSchedule: {
          startDate: moment(values.flexibleStartDate).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          endDate: moment(values.flexibleEndDate).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          restrictions: values.flexibleRestrictions,
        },
      }),
      ...(values.recurringStartDate && {
        recurringSessionSchedule: {
          startDate: moment(values.recurringStartDate).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          endDate: moment(values.recurringEndDate).format(
            "YYYY-MM-DDTHH:mm:ss[Z]"
          ),
          monday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          tuesday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          wednesday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          thursday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          friday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          saturday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
          sunday: [
            {
              start: 1440,
              end: 1440,
            },
          ],
        },
      }),
    };
    // console.log("dataValues:", dataValues);
    handleNext({
      ...dataValues,
    });
  };

  return (
    <Layout>
      <Form
        form={form}
        layout="horizontal"
        initialValues={formSchema}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <div className="modal-h-element ">
          <Row gutter={[0, 16]}>
            <Col>
              <h4 className="b1">How often do you need this service?</h4>
            </Col>
            <Col>
              <label className="input-label d-block mb-0">Times per week</label>
            </Col>
            <Col>
              <Space size={12}>
                <Button
                  className={
                    option === "one_time"
                      ? "bg-dark br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("one_time");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  One time
                </Button>
                <Button
                  className={
                    option === "flexible"
                      ? "bg-dark br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("flexible");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  Flexible
                </Button>
                <Button
                  className={
                    option === "recurring"
                      ? "bg-dark br-50 text-white"
                      : "bg-secondary br-50"
                  }
                  onClick={() => {
                    setOption("recurring");
                  }}
                  size="large"
                  style={{ height: "44px" }}
                >
                  Recurring
                </Button>
              </Space>
            </Col>
            <Col span={24}>
              {option === "one_time" && (
                <Row gutter={[20, 0]}>
                  <Col>
                    <label className="input-label">Date & Time</label>
                    <Form.Item
                      name="oneTimeDate"
                      rules={[{ required: true, message: "Date is required" }]}
                    >
                      <DatePicker
                        className="w-100 form-control-field"
                        placeholder="Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="oneTimeStart"
                      rules={[
                        { required: true, message: "Start time is required" },
                      ]}
                    >
                      <TimePicker
                        className="w-100 form-control-field"
                        placeholder="Start Time"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="oneTimeEnd"
                      rules={[
                        { required: true, message: "End time is required" },
                      ]}
                    >
                      <TimePicker
                        className="w-100 form-control-field"
                        placeholder="End  Time"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {option === "flexible" && (
                <Row gutter={[10, 0]}>
                  <Col span={24}>
                    <label className="input-label">Date (optional)</label>
                    <Row gutter={[20, 0]}>
                      <Col span={12}>
                        <Form.Item
                          name="flexibleStartDate"
                          rules={[
                            {
                              required: true,
                              message: "Start date is required",
                            },
                          ]}
                        >
                          <DatePicker
                            className="w-100 form-control-field"
                            placeholder="Start date"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="flexibleEndDate"
                          rules={[
                            { required: true, message: "End date is required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100 form-control-field"
                            placeholder="End date"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Item
                      name="flexibleRestrictions"
                      rules={[
                        { required: true, message: "Restrictions is required" },
                      ]}
                    >
                      <TextArea
                        placeholder="Tell us more about your restrictions"
                        rows={4}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              {option === "recurring" && (
                <Row gutter={[10, 0]}>
                  <Col>
                    <label className="input-label">Date & Time</label>
                    <Row gutter={[20, 0]}>
                      <Col span={12}>
                        <Form.Item
                          name="recurringStartDate"
                          rules={[
                            {
                              required: true,
                              message: "Start date is required",
                            },
                          ]}
                        >
                          <DatePicker
                            className="w-100 form-control-field"
                            placeholder="Start Date"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="recurringEndDate"
                          rules={[
                            { required: true, message: "End date is required" },
                          ]}
                        >
                          <DatePicker
                            className="w-100 form-control-field"
                            placeholder="End Date"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <label className="input-label">Schedule</label>
                    <Space size={20} className="w-100 mb-20">
                      {scheduleDays.map((value: any) => {
                        return (
                          <Button
                            className={
                              value.selected
                                ? "bg-dark text-white"
                                : "bg-secondary"
                            }
                            size="large"
                            shape="circle"
                            onClick={() => {
                              toggleDaySelected(value);
                            }}
                          >
                            {value.day.charAt(0)}
                          </Button>
                        );
                      })}
                    </Space>
                  </Col>
                  <Col>
                    {scheduleDays.map((sch: any) => {
                      if (sch.selected == true) {
                        return (
                          <Row gutter={[0, 20]} className="mb-20">
                            <Col span={18}>
                              <Row>
                                <Col span={12}>
                                  <h5 className="b1">{sch.day}</h5>{" "}
                                </Col>
                                <Col span={4} offset={8}>
                                  <Space size={20}>
                                    <img
                                      src={plusCircle}
                                      alt=""
                                      onClick={() => addSpecialscheduleInput()}
                                    ></img>
                                    <ClipboardCopy
                                      copyText={moment
                                        .utc()
                                        .startOf("day")
                                        .add("8am", "minutes")
                                        .format("hh:mm A")}
                                    />
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                            {specialScheduleInputData.map((item, i) => {
                              return (
                                <Col span={22}>
                                  <Row gutter={15}>
                                    <Col span={8}>
                                      <Input
                                        value="08:00 AM"
                                        className="font14 text-color-black text-center"
                                        style={{ height: "41px" }}
                                      ></Input>
                                    </Col>
                                    <Col span={8}>
                                      <Input
                                        value="12:00 PM"
                                        className="font14 text-color-black text-center"
                                        style={{ height: "41px" }}
                                      ></Input>
                                    </Col>
                                    <Col span={6} className="align-self-center">
                                      <img
                                        src={deleteSvg}
                                        onClick={() =>
                                          removeSpecialscheduleInput()
                                        }
                                        style={{ width: "20px" }}
                                      ></img>
                                    </Col>
                                  </Row>
                                </Col>
                              );
                            })}
                          </Row>
                        );
                      }
                    })}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        <Row justify="space-between" align="middle">
          <h5 className="stepperBackh5" onClick={() => handleBack()}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} />
            </span>
            Back
          </h5>
          <Button className="stepper-button" htmlType="submit">
            Next
          </Button>
        </Row>
      </Form>
    </Layout>
  );
};

export default TimeAndFrequency;
