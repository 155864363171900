import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { SkillList } from 'pages/JobRequest/types'
import { useEffect } from 'react'
import { axiosGet } from '../Redux/axiosHelper'

export const getAllergyList = async () => {
  const response = await axiosGet('v1/lists/allergies')
  return response.data
}
export const getAccessLevel = async () => {
  const response = await axiosGet('v1/lists/access-levels')
  return response.data
}
export const getSkillsList = async () => {
  const response = await axiosGet('v1/lists/skills')
  return response.data
}
export const useSkillsList = () => {
  const SkillsList = async () => {
    const response = await axiosGet('v1/lists/skills')
    return response.data
  }
  const response: UseQueryResult<SkillList[], unknown> = useQuery(
    ['getSkillList'],
    () => SkillsList(),
  )

  return response
}
