import { useQuery } from "@tanstack/react-query";
import GlobalEventComponent from "../../../../../components/Events/Events";
import { useState } from "react";
import Spinner from "../../../../../components/Spinner";
import { getGuardianEvents } from "../../../../../api/Events";

const GuardianEvents = ({ id }: any) => {
  const [param, setParms] = useState({
    page: 1,
    limit: 10,
  });
  const { data, isLoading } = useQuery(["guardianEvent", param], () =>
    getGuardianEvents(id, param)
  );

  return (
    <div>
      <GlobalEventComponent
        data={data}
        isLoading={isLoading}
        parms={param}
        setParms={setParms}
      />
    </div>
  );
};
export default GuardianEvents;
