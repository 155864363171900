/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  eventsVenueData: [],
  eventsVenueDataValue: {},
  currentEventsVenueDataValue: {},
  loading: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.ADD_EVENTS_VENUE_DATA: {
      return {
        ...state,
        eventsVenueDataValue: action.payload,
        loading: true,
      };
    }

    case actions.ADD_EVENTS_VENUE_DATA_SUCCESS: {
      return {
        ...state,
        eventsVenueDataValue: action.payload,
        loading: false,
      };
    }

    case actions.ADD_EVENTS_VENUE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_EVENTS_VENUE_DATA: {
      return {
        ...state,
        currentEventsVenueDataValue: action.payload,
        loading: true,
      };
    }

    case actions.UPDATE_EVENTS_VENUE_DATA_SUCCESS: {
      return {
        ...state,
        currentEventsVenueDataValue: action.payload,
        loading: false,
      };
    }

    case actions.UPDATE_EVENTS_VENUE_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_EVENTS_VENUE_REQUEST: {
      return {
        ...state,
        eventsVenueData: [],
        loading: true,
      };
    }

    case actions.GET_EVENTS_VENUE_REQUEST_SUCCESS: {
      return {
        ...state,
        eventsVenueData: action.payload,
        loading: false,
      };
    }

    case actions.GET_EVENTS_VENUE_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_EVENTS_VENUE:
      return {
        ...state,
        loading: true,
      };

    case actions.DELETE_EVENTS_VENUE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

      case actions.DELETE_EVENTS_VENUE_FAILURE:
        return {
          ...state,
          loading: false,
        };

    default:
      return state;
  }
};
