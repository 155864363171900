import CardView from './CardView'
import CalenderView from './CalenderView'
import { Row, Col } from 'antd'
import TableView from './TableView'
const EventView = ({ data, parms, setParms, view }: any) => {
  return (
    <div>
      <Row gutter={[10, 24]} align="middle">
        <Col>
          <div className="mt-20px">
            {view === 'cardview' && (
              <CardView events={data} parms={parms} setParms={setParms} />
            )}
            {view === 'calendar' && <CalenderView events={data} />}
            {view === 'tableview' && (
              <TableView events={data} setParms={setParms} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EventView
