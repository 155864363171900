import React from "react";
import { SearchOutlined } from "@ant-design/icons";

const SearchBar = ({
  currentTab,
  data,
  setData,
  dataSource,
  searchKey,
}: any) => {
  const searchActive = (value: any) => {
    if (currentTab === 1) {
      let filteredData = dataSource.filter((item: any) => {
        return item[searchKey].toLowerCase().includes(value.toLowerCase());
      });
      if (value !== "") {
        setData(filteredData);
      } else {
        setData(dataSource);
        console.log(dataSource);
        console.log(data);
      }
    } else {
      let filteredData = dataSource.filter((item: any) => {
        return item.searchKey.toLowerCase().includes(value.toLowerCase());
      });
      setData(filteredData);
      if (value !== "") {
        return filteredData;
      } else {
        setData(dataSource);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div className={"search-input-box"} style={{ marginRight: "0px" }}>
          <input
            type="search"
            onChange={(e: any) => searchActive(e.target.value)}
            className="search-input-group br-12"
            placeholder="Search"
            style={{ width: "100%" }}
          />
          <SearchOutlined />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
