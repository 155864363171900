const actions = {
  GET_RELATIONS: "GET_RELATIONS",
  GET_RELATIONS_SUCCESS: "GET_RELATIONS_SUCCESS",
  GET_RELATIONS_FAILURE: "GET_RELATIONS_FAILURE",

  getRelationsRequest: (payload) => ({
    type: actions.GET_RELATIONS,
    payload,
  }),

  getRelationsRequestSuccess: (payload) => ({
    type: actions.GET_RELATIONS_SUCCESS,
    payload,
  }),

  getRelationsRequestFailure: () => ({
    type: actions.GET_RELATIONS_FAILURE,
  }),
};

export default actions;
