const actions = {
  EVENT_CATEGORIES: "EVENT_CATEGORIES",
  EVENT_CATEGORIES_SUCCESS: "EVENT_CATEGORIES_SUCCESS",
  EVENT_CATEGORIES_FAILURE: "EVENT_CATEGORIES_FAILURE",
  GET_ALLERGIES: "GET_ALLERGIES",
  GET_ALLERGIES_SUCCESS: "GET_ALLERGIES_SUCCESS",
  GET_ALLERGIES_FAILURE: "GET_ALLERGIES_FAILURE",

  addEventCategories: (payload) => ({
    type: actions.EVENT_CATEGORIES,
    payload,
  }),
  saveEventSuccess: (payload) => ({
    type: actions.EVENT_CATEGORIES_SUCCESS,
    payload,
  }),
  getAllergiesRequest: () => ({
    type: actions.GET_ALLERGIES,
  }),

  getAllergiesSuccess: (payload) => ({
    type: actions.GET_ALLERGIES_SUCCESS,
    payload,
  }),

  getAllergiesFailure: () => ({
    type: actions.GET_ALLERGIES_FAILURE,
  }),
};

export default actions;
