import { Avatar, Checkbox } from 'antd'
import GetAvatar from 'components/global/GetAvatar'
import { getNameSignature, getUserName } from 'helper/utility'
import { Attendees } from 'pages/Events/types'
interface roolcall {
  participants: Attendees[]
  handleNext: (arg: any) => void
  submit: () => void
}
const AttendeeList: React.FC<roolcall> = ({
  handleNext,
  submit,
  participants,
}) => {
  const onChange = (checkedValues: any) => {
    handleNext({ participantIds: checkedValues })
  }
  const checkedInParticipants = participants.filter(
    parti =>
      (parti.checkInStatus == 'checked-in' &&
        parti.checkOutStatus !== 'checked-out') ||
      (parti.checkInStatus == 'droped-in' &&
        parti.checkOutStatus !== 'dropped-out'),
  )
  const TotalCount = checkedInParticipants?.length || 0

  return (
    <div className=" mt-20px">
      <div className="overflow-auto h-18rem">
        {TotalCount > 0 ? (
          <>
            <h5 className="grey-txt">{TotalCount} attendees</h5>
            <Checkbox.Group
              options={checkedInParticipants.map(atendee => ({
                label: (
                  <div className="flex-center ml-10px">
                    <GetAvatar person={atendee} />
                    <h2 className="text-16 ml-10">{getUserName(atendee)}</h2>
                  </div>
                ),
                value: atendee._id,
              }))}
              className="mt-20px atendeeCheckbox"
              onChange={onChange}
            />
          </>
        ) : (
          <div className="flex-center justify-center h-50">
            <h3 className="text-center text-16">No particpants enrolled yet</h3>
          </div>
        )}
      </div>
      <button
        className="prim-sm-btn absolute"
        style={{ left: '79%', bottom: '30px' }}
        onClick={submit}
      >
        Save
      </button>
    </div>
  )
}
export default AttendeeList
