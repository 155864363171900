export const gradeDropdown = [
  {
    key: undefined,
    label: 'none',
  },
  {
    key: 'Preschool',
    label: 'Preschool',
  },
  {
    key: 'Kindergarten',
    label: 'Kindergarten',
  },
  {
    key: 'Grade 1',
    label: 'Grade 1',
  },
  {
    key: 'Grade 2',
    label: 'Grade 2',
  },
  {
    key: 'Grade 3',
    label: 'Grade 3',
  },
  {
    key: 'Grade 4',
    label: 'Grade 4',
  },
  {
    key: 'Grade 5',
    label: 'Grade 5',
  },
  {
    key: 'Grade 6',
    label: 'Grade 6',
  },
  {
    key: 'Grade 7',
    label: 'Grade 7',
  },
  {
    key: 'Grade 8',
    label: 'Grade 8',
  },
  {
    key: 'Grade 9',
    label: 'Grade 9',
  },
  {
    key: 'Grade 10',
    label: 'Grade 10',
  },
  {
    key: 'Grade 11',
    label: 'Grade 11',
  },
  {
    key: 'Grade 12',
    label: 'Grade 12',
  },
  {
    key: 'College',
    label: 'College',
  },
]
let kidWeight = []
for (var i = 1; i <= 300; i++) {
  kidWeight.push({
    key: i,
    label: `${i} lb.`,
  })
}
let feet = []
for (var i = 1; i <= 10; i++) {
  feet.push({
    key: i,
    label: i,
  })
}
let inches = []
for (var i = 0; i <= 12; i++) {
  inches.push({
    key: i,
    label: i,
  })
}
export const kidWeightDropdown = kidWeight
export const kidFeetDropdown = feet
export const kidInchesDropdown = inches
// conversion functions from heigher unit to meter
export const feetToMeters = (feet: number) => {
  const meters = feet * 3.3048
  return meters
}
export const inchesToMeters = (inches: number) => {
  const meters = inches * 0.0254
  return meters
}
export const feetAndInchesToMeters = (feet: number, inches: number) => {
  const meters = feet * 0.3048 + inches * 0.0254
  return meters
}
// conversion from the meters to feet and inches
export const metersToFeet = (meters: number) => {
  const feet = meters / 3.280839895
  return feet
}
// export const metersToInches = (meters: number) => {
//   const inches = meters / 0.0254;
//   return feet;
// };
export const metersToInches = (meters: number) => {
  const inches = meters * 39.3701
  return inches
}
export const metersToFeetAndInches = (meters: number) => {
  const totalInches = meters / 0.0254
  const feet = Math.floor(totalInches / 12)
  const inches = totalInches - 12 * feet
  return {
    feet,
    inches,
  }
}
export const kgToPounds = (weight: number) => {
  return weight * 2.20462
}
