import React from 'react'
import 'antd/dist/antd.css'
import { Button, Calendar, Col, Row } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment, { Moment } from 'moment'

const DATE_FORMAT = 'DD/MM/yyyy'

type CustomCalenderProps = {
  daysToHighlight?: moment.Moment[] | undefined | null
  onClick?: (date: moment.Moment) => undefined
}

const CustomCalender:React.FC<CustomCalenderProps> = ({ daysToHighlight, onClick }) => {

  const dateFullCellRender = (d: any) => {
    const shouldHighlight =
      daysToHighlight?.length &&
      daysToHighlight.find(
        (dayToHighlight: any) =>
          dayToHighlight.format(DATE_FORMAT) === d.format(DATE_FORMAT),
      )
    const isCurrentDate = moment().format(DATE_FORMAT) === d.format(DATE_FORMAT)
    return (
      <div
        style={{
          display: 'flex',
          backgroundColor: isCurrentDate
            ? '#2b6bca'
            : shouldHighlight
            ? '#2b6bca'
            : 'white',
          color: isCurrentDate
              ? 'white'
              : shouldHighlight
                  ? 'white'
                  : 'inherit',
          fontWeight: isCurrentDate
              ? 'bold'
              : shouldHighlight
                  ? 'bold'
                  : 'inherit',
          borderRadius: '30px',
          height: '30px',
          width: '30px',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: shouldHighlight ? 'pointer' : 'default'
        }}
        onClick={onClick ? ()=> onClick(d): undefined}
      >
        {d.get('date').toString()}
      </div>
    )
  }

  const headerRender =({ value, onChange }:any) => {
    const month = value.month()
    return (
      <Row align="middle" justify="space-between">
        <Col xl={{ span: 3 }} xs={{ span: 3 }}>
          <Button
            size="small"
            className="calender-action-btn"
            onClick={() => {
              const newValue = value.clone()
              newValue.month(parseInt(String(month - 1), 10))
              onChange(newValue)
            }}
          >
            <LeftOutlined />
          </Button>
        </Col>
        <Col xl={{ span: 16 }} xs={{ span: 12 }}>
          <h3
            style={{ marginBottom: '0px' }}
            className="text-center b1 data-title"
          >
            {value.format('MMMM yyyy')}
          </h3>
        </Col>
        <Col xl={{ span: 3 }} xs={{ span: 3 }} className="text-right">
          <Button
            className="calender-action-btn"
            size="small"
            onClick={() => {
              const newValue = value.clone()
              newValue.month(parseInt(String(month + 1), 10))
              onChange(newValue)
            }}
          >
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <div className="mycalender event-side-calender">
      <Calendar
        style={{ height: '330px' }}
        fullscreen={false}
        dateFullCellRender={dateFullCellRender}
        headerRender={headerRender}
      />
    </div>
  )
}

export default CustomCalender
