import { Modal } from 'antd'
import { Attendees, CheckInData } from 'pages/Events/types'
import Cancel from 'assets/Images/close.svg'
import React, { useEffect, useState } from 'react'
import { convertToUtc, getUserName } from 'helper/utility'
import { useQuery } from '@tanstack/react-query'
import { getDropInCheckinData, getMembersByEmail } from 'api/Events'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { Person } from 'types/globalTypes'
import AccountFound from './AccountFound'
import Details from './Detail'
import AccountNotFound from './AccountNotFound'
import { myStore } from 'state/eventStore'
import { useDispatch } from 'react-redux'
import actions from 'Redux/Session/action'
import FamilyCheckOut from './AccountFound/FamilyCheckOut'
import Attendance from '../..'
import { getKidbyId, GetFamily } from 'api/family'
import Spinner from 'components/Spinner'
import Family from 'pages/Family'
interface DropOutInterface {
  openModal: boolean
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
  participant: Attendees
}
export type Account = Person & { type: 'guardian' | 'kid' | 'authorized-adult' }
const DropOut: React.FC<DropOutInterface> = ({
  openModal,
  closeModal,
  participant,
}) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>('')
  const [setTextHead, setText] = useState(false)
  const handleCancel = () => {
    closeModal(false)
    setEmail('')
    setText(false)
    dispatch({ type: actions.RESET_CHECKIN })
  }
  const location = useLocation()
  const [family, setFamily] = useState<Account[]>([])

  const event: any = location.state
  const currentDate: any = moment().startOf('day')
  const Tag = myStore(store => store.tag)
  const {
    data: familyData,
    isLoading: familyLoading,
    refetch: familyrefetch,
  } = useQuery<any>(
    ['getFamilyId'],
    () => {
      return GetFamily(participant?.familyId)
    },
    { enabled: false },
  )
  useEffect(() => {
    if (
      (participant?.familyId !== undefined &&
        participant?.participantType === 'kid') ||
      participant?.participantType === 'authorizedAdult' ||
      participant?.participantType === 'guardian'
    ) {
      familyrefetch()
    }
    const kidfilter = familyData?.kids.filter(item => {
      return item._id === participant._id
    })
  }, [])

  const kidfilter = familyData?.kids.filter(item => {
    return item._id === participant._id
  })
  const adultFilter = familyData?.adults.filter(item => {
    return item._id === participant._id
  })

  useEffect(() => {
    if (!familyLoading) {
      setFamily([])
      const guardian = familyData?.guardians?.map(guardian => {
        return {
          ...guardian,
          type: 'guardian',
        }
      })
      const filteredAdult = adultFilter.map(adult => ({
        ...adult,
        type: 'authorized-adult',
      }))
      const filteredKid = kidfilter.map(kid => ({ ...kid, type: 'kid' }))

      setFamily([...guardian, ...filteredAdult, ...filteredKid])
    }
  }, [familyLoading, participant.checkInId])
  const { data, isLoading, isFetching, refetch } = useQuery<CheckInData>(
    ['getDropInCheckIn'],
    () => {
      return getDropInCheckinData(
        participant._id,
        convertToUtc(currentDate),
        participant.checkInId,
        event._id,
      )
    },
  )

  const { data: Account, refetch: aRefetch } = useQuery<Account[]>(
    ['getAccountByEmail'],
    () => {
      return getMembersByEmail(email, convertToUtc(currentDate), event._id)
    },
    { enabled: false },
  )

  useEffect(() => {
    refetch()
  }, [participant])
  useEffect(() => {
    email !== '' && aRefetch()
  }, [email, Tag])
  return (
    <Modal
      open={openModal}
      onCancel={handleCancel}
      className="popup-modal w-550px  "
    >
      <div className="popup-header border-btm ">
        <h2>
          {/* {Account == undefined
            ? `Drop-out ${getUserName(participant)}`
            : `Drop-out ${getUserName(participant)}`
            ? Account?.length == 0 &&
              email !== '' &&
              'which of the participants'
            : Account && Account.length > 0 && email && 'Drop-out'} */}
          {Account && Account.length > 0 && email !== '' && setTextHead
            ? 'Drop-out'
            : Account && Account.length > 0 && email !== ''
            ? 'Which of this matches the profile?'
            : `Drop-out ${getUserName(participant)}`}
        </h2>

        <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
      </div>

      {email === '' && participant?.familyId == undefined && (
        <Details
          participant={participant}
          data={data!}
          isLoading={isLoading || isFetching}
          setVal={setEmail}
        />
      )}
      {Account && Account.length > 0 && email !== '' && (
        <AccountFound
          details={Account}
          cost={data?.cost!}
          checkInId={participant.checkInId}
          email={email}
          closeModel={handleCancel}
          setText={setText}
        />
      )}
      {Account?.length == 0 && email !== '' && (
        <AccountNotFound
          amountToCharge={data?.cost.amount!}
          email={email}
          checkIn={participant.checkInId}
          close={handleCancel}
        />
      )}
      {participant.familyId !== undefined &&
      participant?.participantType !== 'dropInAttendee' &&
      familyLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20rem',
          }}
        >
          <Spinner />
        </div>
      ) : (
        participant.familyId !== undefined &&
        participant?.participantType !== 'dropInAttendee' && (
          <div className="h-25rem overflow-auto">
            <FamilyCheckOut
              name="guardian"
              setText={setText}
              cost={data?.cost}
              family={family} // Assuming Account contains the family details
              id={participant.checkInId}
              close={handleCancel}
            />
          </div>
        )
      )}
    </Modal>
  )
}
export default DropOut
