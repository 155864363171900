import { Avatar, Select, Pagination } from "antd";
import { useState } from "react";
import Spinner from "../../../../Spinner";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { RequestParms } from "../../../../../types/globalTypes";
interface ListingTypes {
  setParms: (arg: any) => void;
  parms: RequestParms;
  data: any;
  isLoading: boolean;
  setClickedData: (arg: any) => void;
}
const Listing = ({
  setParms,
  parms,
  data,
  isLoading,
  setClickedData,
}: ListingTypes) => {
  const handleSearchChange = (e: any) => {
    setParms({ ...parms, searchQuery: e });
  };
  const debounced = debounce(handleSearchChange, 300);
  const [clickedButtons, setClickedButtons] = useState<any>();
  const [id, setId] = useState();
  const ClickedFam = (data: any, index: number) => {
    setClickedButtons(index);
    setId(data._id);
  };
  const handleChange = (value: string) => {
    setClickedData({ id: id, designation: value });
  };
  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setParms({ ...parms, page: pageNumber, limit: pageSize });
  };
  return (
    <div className="h-full my-40px ">
      <div className="search-input-box">
        <input
          type="search"
          className="search-input-group"
          placeholder="Search"
          onChange={(e) => {
            e.preventDefault();
            debounced(e.target.value);
          }}
        />
        <SearchOutlined />
      </div>
      <div className="overflow-auto pointer mt-20 h-25rem">
        {isLoading ? (
          <div className="flex justify-center align-center h-full">
            <Spinner />
          </div>
        ) : (
          data?.records.map((OrgData: any, index: number) => {
            return (
              <div
                className={
                  clickedButtons === index
                    ? "org-active flex align-center justify-between  mt-15px border-1 pb-10px pointer"
                    : "flex align-center mt-15px border-1 pb-10px  pointer ml-20px"
                }
                key={index}
                onClick={() => {
                  ClickedFam(OrgData, index);
                }}
              >
                <div className="flex align-center">
                  <Avatar
                    size={34}
                    src={OrgData?.photo ? OrgData?.photo?.post : ""}
                  >
                    M
                  </Avatar>
                  <h4 className="ml-10">
                    {OrgData.firstName
                      ? OrgData.firstName + "  " + OrgData.lastName
                      : OrgData.name}
                  </h4>
                </div>

                {clickedButtons === index ? (
                  <Select
                    defaultValue="Please Select Access level"
                    onChange={handleChange}
                    style={{
                      width: 120,
                    }}
                    options={[
                      {
                        value: "full admin",
                        label: "Full admin",
                      },
                      {
                        value: "organizer",
                        label: "Organizer",
                      },
                      {
                        value: "staff-member",
                        label: "Staff member",
                      },
                      {
                        value: "view-only",
                        label: "View Only",
                      },
                      {
                        value: "event-manager",
                        label: "Event Manager",
                      },
                    ]}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })
        )}
      </div>
      <div className="mt-40px flex justify-center align-center align-center">
        <Pagination
          defaultCurrent={parms?.page}
          total={data?.count}
          onChange={PaginationChanger}
        />
      </div>
    </div>
  );
};
export default Listing;
