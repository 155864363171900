import React from 'react'
import { Avatar } from 'antd'
import { getNameSignature } from 'helper/utility'
import { Person, generalProfile } from 'types/globalTypes'

interface AvatarProps {
  person: Person | generalProfile | undefined
  size?: number
}

const isPerson = (obj: any): obj is Person => {
  return 'firstName' in obj
}

const GetAvatar: React.FC<AvatarProps> = ({ person, size }) => {
  return (
    <Avatar src={person?.photo?.thumb} size={size}>
      {isPerson(person)
        ? getNameSignature(person?.firstName + ' ' + person?.lastName)
        : getNameSignature(person?.name)}
    </Avatar>
  )
}

export default GetAvatar
