import { Dropdown, Space, Menu, Row, Col, Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import filterSvg from '../../../../../assets/Images/filter.svg'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
const Organizations = () => {
  const [visible, setVisible] = useState(false)
  const menu2 = (
    <Menu
      title="show all"
      items={[
        {
          label: (
            <Row gutter={10} justify="space-between">
              <Col span={4}>
                <Checkbox name="family"></Checkbox>
              </Col>
              <Col span={20}>What we show</Col>
            </Row>
          ),
          key: '1',
        },
      ]}
    />
  )
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }
  const handleSearchChange = (e: any) => {
    if (e.length >= 3 || e.length === 0) {
    }
  }
  const debounced = debounce(handleSearchChange, 700)
  return (
    <Space size={20} className="mentors-table-header">
      <Dropdown
        overlay={menu2}
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Space>
          <Space>
            <img src={filterSvg} alt=""></img>
          </Space>
        </Space>
      </Dropdown>

      <div>
        <div className="search-input-box">
          <input
            type="search"
            className="search-input-group"
            placeholder="Search "
            onChange={e => {
              e.preventDefault()
              debounced(e.target.value)
            }}
          />
          <SearchOutlined />
        </div>
      </div>
    </Space>
  )
}
export default Organizations
