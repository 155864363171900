import { Row, Table, Space, Button, Dropdown } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import React from 'react'
import './paymentStyle.scss'
import Actionicon from 'assets/Images/Action.svg'
import { convertToLocalWithAt } from 'helper/utility'
import viseIcon from 'assets/Images/Visa.svg'
import usePagination from 'helper/usePagination'
import SimpleCard from 'assets/Images/CreditCard.svg'
import GooglePay from 'assets/Images/GPayIcon.svg'
import ApplePay from 'assets/Images/applePayIcon.svg'
export const PaymentTable = ({
  data,
  parms,
  setParms,
  hidCols,
  setSelectedRows,
}) => {
  const spaceSize = 8 // Define a constant for space size
  const items = [
    {
      key: 'edit',
      label: <p>Edit Amount</p>,
    },
    {
      key: 'delete',
      label: <p>Refund</p>,
    },
  ]
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  }
  const columns = [
    // {
    //   title: 'Organization',
    //   dataIndex: 'Organization',
    //   key: 'Organization',
    //   render: (text: any, record: any) => (
    //     <div style={{ cursor: 'pointer' }} className="py-10px">
    //       <Space size={spaceSize}>{record.name}</Space>
    //     </div>
    //   ),
    // },
    {
      title: 'Payment method',
      dataIndex: 'Payment method',
      key: 'Payment method',
      width: 283,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={spaceSize}>
            <div>
              {record?.cardBrand === 'visa' &&
              record?.paymentMethodType === 'card' ? (
                <img src={viseIcon} alt="Visa Card" />
              ) : record?.paymentMethodType === 'google-pay' ? (
                <img src={GooglePay} alt="Google Pay" />
              ) : record?.paymentMethodType === 'apple-pay' ? (
                <img src={ApplePay} alt="Apple Pay" />
              ) : (
                <img src={SimpleCard} alt="Simple Card" width="32px" />
              )}
            </div>
            {record?.cardBrand}
            {record?.last4 && (
              <>
                <img src={Actionicon} alt="Action Icon" />
                {record?.last4}
              </>
            )}
          </Space>
        </div>
      ),
      className: hidCols.payment === false ? 'display-none' : '',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 120,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }}>
          <Space size={spaceSize}>{`$${record.chargeFromCard}`}</Space>
        </div>
      ),
      className: hidCols.amount === false ? 'display-none' : '',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      width: 180,
      render: (text: any, record: any) => (
        <div className="py-10px">
          <Space size={spaceSize}>
            {convertToLocalWithAt(record?.createdAt, 'DD MMM YYYY [at] h:mm A')}
          </Space>
        </div>
      ),
      className: hidCols.date === false ? 'display-none' : '',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: 190,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Space size={spaceSize} className="status-bg">
            {record.status}
          </Space>
        </div>
      ),
      className: hidCols.status === false ? 'display-none' : '',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      render: (text: any, record: any) => (
        <div style={{ cursor: 'pointer' }} className="py-10px">
          <Dropdown
            // overlay={Actionmenu(item)}
            menu={{
              items: items,
              // onClick: e => handleDropdownItemClick(e, item),
            }}
            placement="bottom"
          >
            <img alt="img" src={Actionicon} />
          </Dropdown>
        </div>
      ),
      className: hidCols.action === false ? 'display-none' : '',
    },
  ]

  return (
    <div className="mt-20">
      <Row>
        <Table
          className="organizations_table"
          style={{ width: '100%' }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data?.records}
          rowKey="key"
          pagination={paginationInfo}
          scroll={{ x: 400 }}
          onChange={onTableChange}
        />
      </Row>
    </div>
  )
}

export default PaymentTable
