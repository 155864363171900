import { Modal, Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import Cancel from 'assets/Images/close.svg'
import React, { useEffect, useState } from 'react'
import { Attendees, SingleFamily, TicketOrder } from 'pages/Events/types'
import CheckInOutParticpant from './CheckInOut'
import CheckInOutHistory from './CheckInOutHistory'
import { convertToUtc } from 'helper/utility'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { GetFamily } from 'api/family'
import { getParticpantTicketByDay, getDropInTicket } from 'api/Events' // Assuming there's a function getDropInTicket
interface ModelType {
  particpantStatus?: any
  type: 'Check-in' | 'Check-out' | 'drop-out' | 'approve' | 'Drop-in'
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  particpant?: Attendees
  dropInModel?: any
  tab?: 'notes' | 'checkIn' | 'history'
}
const CheckInOutModel: React.FC<ModelType> = ({
  particpantStatus,
  type,
  open,
  setOpen,
  particpant,
  dropInModel,
  tab,
}) => {
  const [tabKey, setTabKey] = useState<string>('1')
  const { id }: any = useParams()
  const eventId: string = id

  useEffect(() => {
    setTabKey(tab === 'checkIn' ? '1' : tab === 'history' ? '2' : '3')
  }, [tab])

  const handleCancel = () => {
    setOpen(false)
  }

  const participantType = particpant?.type
  const currentDate = convertToUtc(moment().startOf('day'))
  const params: Record<string, string> = {
    date: currentDate,
    ...(participantType === 'kid'
      ? { kidId: particpant?._id }
      : participantType === 'guardian'
      ? { guardianId: particpant?._id }
      : { authorizedAdultId: particpant?._id }),
  }

  const { data, isLoading, refetch } = useQuery<SingleFamily>(
    ['GetFamilyById'],
    {
      queryFn: () => GetFamily(particpant?.familyId || ''),
      enabled: false,
    },
  )
  // console.log(particpant.type === 'dropInAttendee')
  const {
    data: ticketData,
    isLoading: ticketLoading,
    refetch: ticketRefetch,
  } = useQuery<TicketOrder>(
    ['GetUserTodayTickets'],
    () => {
      return particpant?.type === 'dropInAttendee' ||
        particpant?.isType === 'dropIn'
        ? getDropInTicket(eventId)
        : getParticpantTicketByDay(eventId, params)
    },
    { enabled: false },
  )

  useEffect(() => {
    if (particpant?.familyId) {
      refetch()
    }
    ticketRefetch()
  }, [])

  // let ticketData: TicketOrder | undefined
  // let ticketLoading: boolean = false
  // let ticketRefetch: () => void = () => {}

  // useEffect(() => {
  //   if (type !== 'drop-in') {
  //     const fetchData = async () => {
  //       const result = await getParticpantTicketByDay(eventId, params)
  //       ticketData = result.data
  //       ticketLoading = result.isLoading
  //       ticketRefetch = result.refetch
  //     }
  //     fetchData()
  //   } else {
  //     const fetchData = async () => {
  //       const result = await getDropInTicket(eventId)
  //       console.log(result) // Call your drop-in API function
  //       ticketData = result
  //       console.log(ticketData)
  //       ticketLoading = result.isLoading
  //       ticketRefetch = result.refetch
  //     }
  //     fetchData()
  //   }
  // }, [type]) // Only re-run the effect if 'type' changes
  // console.log('part:', particpant)
  return (
    <>
      <Modal open={open} onCancel={handleCancel} className="checkInOutModal ">
        <div className="flex-center  pt-40px justify-between ">
          <h2 className="w-600 text-24">{type}s details</h2>
          <img src={Cancel} alt="close button pointer" onClick={handleCancel} />
        </div>
        <Tabs className="orgn-tabs" defaultActiveKey={tabKey}>
          <TabPane tab="Notes" key="1"></TabPane>
          <TabPane tab={type} key="2">
            <CheckInOutParticpant
              participant={particpant}
              dropInModel={dropInModel}
              closeModel={handleCancel}
              type={type}
              ticketsData={{
                isLoading: ticketLoading,
                data: ticketData,
              }}
              familyData={{
                isLoading,
                data,
              }}
              name="guardian"
            />
          </TabPane>
          <TabPane tab="History" key="3">
            <CheckInOutHistory participant={particpant} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

export default CheckInOutModel
