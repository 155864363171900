import { Avatar, Button, Col, Dropdown, Menu, Row, Space, Table } from "antd";
import React from "react";
import org2 from "../../../assets/Images/org2.svg";
import { DownOutlined } from "@ant-design/icons";
import LockSimple from "../../../assets/Images/LockSimple.svg";
import { history } from "../../../Redux/app-store";

const menu = <Menu />;

const Organizations = ({ organizations, setSelectedRows }: any) => {
  const dataSource = [
    {
      key: "1",
      organizationName: "Organization Name",
      OrganizationsAvatar: <img src={org2} alt=""></img>,
      accessLevel: "Event Manager",
      actions: "10 Downing Street",
    },
    {
      key: "2",
      organizationName: "Organization Name",
      OrganizationsAvatar: <img src={org2} alt=""></img>,
      accessLevel: "View Only",
      actions: "10 Downing Street",
    },
    {
      key: "3",
      organizationName: "Organization Name",
      OrganizationsAvatar: <img src={org2} alt=""></img>,
      accessLevel: "Staff Member",
      actions: "10 Downing Street",
    },
  ];

  console.log(organizations);

  const dataSource2 = organizations?.map((item: any) => {
    return {
      key: item.organizationId,
      organizationName: item.name,
      OrganizationsAvatar: item?.photo?.post,
      accessLevel: "Staff Member",
    };
  });

  const columns = [
    {
      title: "Organization Name",
      dataIndex: "organizationName",
      key: "organizationName",
      render: (text: any, item: any, index: any) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: `/organization-profile/${item.key}`,
                state: JSON.stringify(item.key),
              });
            }}
          >
            <Space size={8}>
              <Avatar size={24} src={item.OrganizationsAvatar}></Avatar>
              {text}
            </Space>
          </div>
        );
      },
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      width: 150,
      key: "accessLevel",

      render: (text: any) => {
        return (
          <Row>
            <Col span={4}>
              <Dropdown
                overlay={menu}
                className="status_dropdown manager-drop-btn"
              >
                <Button shape="round">
                  <Space>
                    <img alt="alt" src={LockSimple}></img>
                    Event Manager <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 150,

      key: "actions",
      render: (text: any, record: any) => {
        return (
          <Button
            className="button_organizer"
            style={{ width: "132px" }}
            onClick={() => {
              history.push({
                pathname: `/organization-profile/${record.key}`,
                state: JSON.stringify(record.key),
              });
            }}
          >
            View Profile
          </Button>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };
  return (
    <div className="bg-grey container" style={{ paddingTop: "10px" }}>
      <div className="orgnz-profile-tab-organizations-bg pagination">
        <Table
          className="left-right-space-element"
          dataSource={dataSource2}
          columns={columns}
          rowSelection={rowSelection}
        />
      </div>
    </div>
  );
};

export default Organizations;
