import moment from 'moment'

const CustomToolbar = (toolbar: any) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV')
  }
  const goToNext = () => {
    toolbar.onNavigate('NEXT')
  }
  const goToCurrent = () => {
    toolbar.onNavigate('TODAY')
  }
  const date = moment(toolbar.date)
  const currentDate = date.format('DD')
  const year = date.format('YYYY')
  const label = toolbar.label.match(/\D/g).join('')
  return (
    <div className="rbc-toolbar mb-38">
      <div className="d-flex align-items-center">
        {/* <button className={`${styles.btnToolbar}`} type="button" onClick={goToBack}>
              <LeftOutlined />
            </button> */}
        <h3 className="rbc-toolbar-label mx-50">
          {currentDate} {label} {year}
        </h3>
        {/* <button className={`${styles.btnToolbar}`} type="button" onClick={goToNext}>
              <RightOutlined />
            </button> */}
      </div>
      <span className="rbc-btn-group" />
    </div>
  )
}
export default CustomToolbar
