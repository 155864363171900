import { useParams } from 'react-router-dom'
import { Row, Tabs } from 'antd'
import AppLayout from '../../../../components/layout/layout'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getGuardian } from '../../../../api/family'
import Spinner from '../../../../components/Spinner'
import GuardianBasicInfo from './BasicInformation'
import Header from './Header/Header'
import GuardianEvents from './GuardianEvents'
import Media from './Media'
import { useEffect } from 'react'
import GuardianDocuments from './Documents'
import { forceRefetch } from '../../../../helper/utility'
// import Notes from "../KidProfile/Notes/Notes";
import Notes from '../NotesComp/Notes'

const { TabPane } = Tabs
const GuardianProfile = () => {
  const { id }: { id: string } = useParams()
  const { data, isLoading, refetch } = useQuery(['getGuardian', id], () =>
    getGuardian(id),
  )
  const queryClient = useQueryClient()
  const update = () => {
    forceRefetch('getGuardian', queryClient)
    refetch()
  }
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <AppLayout id={id}>
      <div className="container bg-grey">
        <Header data={data} refetch={update} />
        {isLoading ? (
          <div className="flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          <Row>
            <Tabs
              defaultActiveKey="1"
              style={{ width: '100%', height: '100%' }}
              className="bg-white profile-tabs"
            >
              <TabPane tab="Basic Info" key="1">
                <GuardianBasicInfo parent={data} refetch={refetch} />
              </TabPane>
              <TabPane tab="Events" key="2">
                <GuardianEvents id={data.id} />
              </TabPane>
              <TabPane tab="Media" key="3">
                <Media id={data.id} />
              </TabPane>
              <TabPane tab="Documents" key="4">
                <GuardianDocuments />
              </TabPane>
              <TabPane tab="Notes" key="5">
                {/* <Notes kidId={id} /> */}
                <Notes id={id} familyId={data?.familyId} />
              </TabPane>
            </Tabs>
          </Row>
        )}
      </div>
    </AppLayout>
  )
}
export default GuardianProfile
