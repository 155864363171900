import React from 'react'
import { Row, Col, Form, Radio } from 'antd'

interface BasicFieldState {
  type: string
  method: string
}
interface BasicFieldsProps {
  basicField: BasicFieldState
  setBasicField: React.Dispatch<React.SetStateAction<BasicFieldState>>
}

const BasicFields: React.FC<BasicFieldsProps> = ({
  basicField,
  setBasicField,
}) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <label htmlFor="" className="custom-field-label">
            Note Type
          </label>
          <Form.Item
            name="noteType"
            colon={false}
            rules={[{ required: true, message: 'Note type is required' }]}
          >
            <Radio.Group
              name="noteType"
              style={{ width: '100%' }}
              defaultValue={basicField.type}
              onChange={e =>
                setBasicField({ ...basicField, type: e.target.value })
              }
            >
              <Radio value="comment">Comment</Radio>
              <Radio value="tips">Tips</Radio>
              <Radio value="incident">Incident</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <label htmlFor="" className="custom-field-label">
            Method
          </label>
          <Form.Item
            name="method"
            colon={false}
            rules={[{ required: true, message: 'Method type is required' }]}
          >
            <Radio.Group
              name="method"
              style={{ width: '100%' }}
              defaultValue={basicField.method}
            >
              <Radio value="none">None</Radio>
              <Radio value="text">Text</Radio>
              <Radio value="email">Email</Radio>
              <Radio value="phone-call">Phone Calls</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default BasicFields
