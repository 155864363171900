import { useState } from 'react'

import { Col } from 'antd'
import { useParams } from 'react-router-dom'
import GuardiansModal from '../../../../../components/modals/add-EditFamilyMembers/Guardian/GuardiansModal'
import FamilyModal from '../../../../Modals/FamilyModal/FamilyModal'
import AdultsModal from '../../../../Modals/FamilyModal/Adults/AdultsModal'
import AlertModal from '../../../../../helper/AlertModal'
import FamilyCredit from '../Credits/FamilyCredits'
import FamilyData from './FamilyData'
import Spinner from '../../../../../components/Spinner'
import AddFamilyMembers from '../../../../../components/modals/add-EditFamilyMembers'

const FamilyProfile = ({ FamiliyData, isLoading, refetch }: any) => {
  const [alertModal, setAlertModal] = useState(false)
  const [alertModalData] = useState({
    name: 'adult',
    heading: 'Delete the adult',
    message1: `Are you sure you want to delete the 
    #adult_name Adult?`,
    closeIcon: false,
  })
  const [dataItem] = useState<any>()
  const [membersModalVisible, setMembersModalVisible] = useState(false)

  let { id }: any = useParams()

  return (
    <>
      <div className="bg-grey container padding-top-10 ">
        <div className="grid grid-50">
          <Col xs={{ span: 24 }} xl={{ span: 24 }}>
            {isLoading ? (
              <div className="flex justify-center h-full">
                <Spinner />
              </div>
            ) : (
              <FamilyData
                id={id}
                setMembersVisible={setMembersModalVisible}
                fdata={FamiliyData}
              />
            )}
          </Col>
          <FamilyCredit familyId={id} />
        </div>
      </div>
      {membersModalVisible && (
        <AddFamilyMembers
          open={membersModalVisible}
          setOpen={setMembersModalVisible}
          familyId={id}
        />
      )}

      {alertModal && (
        <AlertModal
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          dataItem={dataItem}
        />
      )}
    </>
  )
}

export default FamilyProfile
