import { ArrowUp } from '@phosphor-icons/react'
import { Dropdown, Radio } from 'antd'
import { CloseDropeDown } from 'helper/CloseDropdown'
import Down from 'helper/Icons/DownIcon'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import {
  AttendeeAge,
  Grades,
  ParticpentsGender,
  Schools,
  SortByCheckBoxes,
  TicketsArr,
} from './FilterArrays'
interface filter {
  open: number
  setOpen: Dispatch<SetStateAction<number>>
}
const useFilters = ({ open, setOpen }: filter) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [sortBy, setSortBy] = useState('')

  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })
  const SortBy = [
    {
      key: 'sort',
      label: (
        <div>
          <Radio.Group
            options={SortByCheckBoxes}
            className="dropdwn checkbox"
            value={sortBy}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onChange={(val: any) => setSortBy(val.target.value)}
          />
          <div className=" border-top pb-10px">
            <div className="dropdwn">
              <h2 className="text-14 w-600 ">Sort Results</h2>
              <Radio.Group
                // onChange={(val: any) => setOrderBy(val.target.value)}
                className="flex flex-col mt-10px"
                // value={orderBy}
              >
                <Radio value={'asc'}>Ascending</Radio>
                <Radio value={'desc'}>Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button className="clr-btn">Clear</button>
          <button className="aply-btn">Apply</button>
        </div>
      ),
    },
  ]
  const Tickets = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={TicketsArr}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const TicketDropDown = (
    <Dropdown
      menu={{
        items: Tickets,
      }}
      placement="bottomLeft"
      arrow={false}
      className="ml-20px mr-10px"
      open={open === 1}
      overlayStyle={{ width: '300px' }}
    >
      <button
        className={` ${open === 1 ? 'filter-btn-click ' : 'filter-btn'} w-500`}
        onClick={() => setOpen(1)}
      >
        Ticket <Down />
      </button>
    </Dropdown>
  )
  const Attendie = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={AttendeeAge}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const AttendieDropDown = (
    <Dropdown
      menu={{
        items: Attendie,
      }}
      placement="bottomLeft"
      arrow={false}
      className="mr-10px "
      open={open === 2}
      overlayStyle={{ width: '300px' }}
    >
      <button
        className={` ${open === 2 ? 'filter-btn-click ' : 'filter-btn'} w-500`}
        onClick={() => setOpen(2)}
      >
        Attendee age <Down />
      </button>
    </Dropdown>
  )
  const Gender = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={ParticpentsGender}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const GenderDropDown = (
    <Dropdown
      menu={{
        items: Gender,
      }}
      placement="bottomLeft"
      arrow={false}
      className="mr-10px "
      open={open === 3}
      overlayStyle={{ width: '300px' }}
    >
      <button
        className={` ${open === 3 ? 'filter-btn-click ' : 'filter-btn'} w-500`}
        onClick={() => setOpen(3)}
      >
        Gender
        <Down />
      </button>
    </Dropdown>
  )
  const School = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={Schools}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const SchoolDropDown = (
    <Dropdown
      menu={{
        items: School,
      }}
      placement="bottomLeft"
      arrow={false}
      className="mr-10px "
      open={open === 4}
      overlayStyle={{ width: '300px' }}
    >
      <button
        className={` ${open === 4 ? 'filter-btn-click ' : 'filter-btn'} w-500`}
        onClick={() => setOpen(4)}
      >
        School
        <Down />
      </button>
    </Dropdown>
  )
  const Grade = [
    {
      key: 'checkboxes',
      label: (
        <Radio.Group
          options={Grades}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      ),
    },
  ]
  const GradeDropDown = (
    <Dropdown
      menu={{
        items: Grade,
      }}
      placement="bottomLeft"
      arrow={false}
      className="mr-10px "
      open={open === 5}
      overlayStyle={{ width: '300px' }}
    >
      <button
        className={` ${open === 5 ? 'filter-btn-click ' : 'filter-btn'} w-500`}
        onClick={() => setOpen(5)}
      >
        Grade
        <Down />
      </button>
    </Dropdown>
  )
  const SortDropDown = (
    <div
      className="flex align-center"
      ref={dropdownRef}
      onClick={handleDropdownClick}
    >
      <h2 className="w-500 text-14">Sort by:</h2>
      <Dropdown
        menu={{
          items: SortBy,
        }}
        placement="bottomRight"
        arrow={false}
        className="ml-20px mr-10px"
        open={open === 5}
        overlayStyle={{ width: '300px' }}
      >
        <button
          className={` ${
            open === 5 ? 'filter-btn-click ' : 'filter-btn'
          } w-500 align-center`}
          onClick={() => setOpen(5)}
        >
          <ArrowUp
            size={18}
            color={'#9ea1a3'}
            style={{
              position: 'relative',
              top: '4px',
            }}
          />{' '}
          Creation Date
          <Down />
        </button>
      </Dropdown>
    </div>
  )
  return [
    TicketDropDown,
    AttendieDropDown,
    GenderDropDown,
    SchoolDropDown,
    GradeDropDown,
    SortDropDown,
  ]
}
export default useFilters
