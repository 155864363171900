/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Space } from 'antd'
import { Row, Col, Avatar } from 'antd'
import { Table } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { history } from '../../../Redux/app-store'
import editblackPencil from '../../../assets/Images/editblackPencil.svg'
import deleteBlackIcon from '../../../assets/Images/deleteBlackIcon.svg'
import StatusDropdown from '../../../helper/StatusDropdown'
import DeleteAlert from '../../../components/modals/deleteModal'
import type { SorterResult } from 'antd/es/table/interface'
import NewFamily from '../../Modals/FamilyModal/NewFamily/NewFamily'
import usePagination from 'helper/usePagination'
import { connect } from 'react-redux'
import { getNameSignature, convertDOBToAge } from 'helper/utility'
import moment from 'moment'
import { Link } from 'react-router-dom'

const FamilyTable = ({
  activeData,
  status,
  setParms,
  parmsData,
  changeStatus,
  del,
  refetchFamilies,
  hiddenColumns,
  currentPage,
}: any) => {
  const [userId, setUserId] = useState()
  const [alertModal, setAlertModal] = useState(false)
  const [showMoreId, setShowMoreID] = useState<any>([])
  const [showMoreId1, setShowMoreID1] = useState<any>([])
  const [showMoreId2, setShowMoreID2] = useState<any>([])
  const [showMoreIdPhone, setShowMoreIdPhone] = useState<any>([])
  const [showEditFamily, setShowEdit] = useState<boolean>(false)
  const [familyData, setFamilyData] = useState<string>()
  const [sortedInfo] = useState<SorterResult<any>>({})
  const alertModalData = {
    heading: 'Are you sure want to delete this',
    name: 'organizations',
    id: userId,
  }
  const prevRoute = localStorage.getItem('previousRoute')
  const newArray = activeData?.records?.map((item: any) => {
    return {
      key: item._id,
      family: item.name,
      familyAvatar: item.photo?.post,
      guardians: item.owner,
      emergencyContacts: item.emergencyContacts,
      members: item?.guardians,
      kids: item?.kids,
      adults: item?.adults,
      status: 'active',
      action: [
        <Space key={item.id} className="orgn-action-btn">
          <img
            src={editblackPencil}
            width={20}
            onClick={() => {
              setShowEdit(true)
              setFamilyData(item)
            }}
            className="cursor_pointer"
            alt="edit"
          />
          <img
            src={deleteBlackIcon}
            onClick={e => {
              setAlertModal(true)
              setUserId(item._id)
            }}
            className="cursor_pointer"
            alt="delete"
          />
        </Space>,
      ],
    }
  })

  const columns = [
    //family//
    {
      title: 'Family',
      dataIndex: 'family',
      key: 'family',
      sorter: (a: any, b: any) => a.family.length - b.family.length,
      sortOrder: sortedInfo.columnKey === 'family' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns?.family === false ? 'display-none' : '',
      render: (text: any, record: any) => (
        <Link to={`/family-profile/${record.key}`}>
          <Space
            size={8}
            className="cursor_pointer"
            // onClick={() => {
            //   history.push({
            //     pathname: `/family-profile/${record.key}`,
            //   })
            // }}
          >
            {record.familyAvatar && (
              <Avatar
                size={24}
                icon={<img alt="img" src={record.familyAvatar}></img>}
              ></Avatar>
            )}
            {text}
          </Space>
        </Link>
      ),
    },
    //Guardians//

    {
      title: 'Guardians',
      dataIndex: 'members',
      key: 'members',
      sorter: (a: any, b: any) => a.members.length - b.members.length,
      sortOrder: sortedInfo.columnKey === 'members' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.guardian === false ? 'display-none' : '',
      render: (members: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.members?.map((member: any, index: number) => {
              if (showMoreId.includes(record.key)) {
                return (
                  <Col>
                    <Link to={`/parent-profile/${member?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/parent-profile/${member?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={member.photo?.post}>
                          {getNameSignature(member?.firstName)}
                        </Avatar>
                        <div>
                          <h3 className="text-14 w-600">{`${
                            member.firstName || member.name
                          } ${member?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {member?.relation?.name}
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Link to={`/parent-profile/${member?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/parent-profile/${member?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={member.photo?.post}>
                          {getNameSignature(member?.firstName)}
                        </Avatar>

                        <div>
                          <h3 className="text-14 w-600">{`${
                            member.firstName || member.name
                          } ${member?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {member?.relation?.name}
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              }
            })}

            {record.kids?.length > 2 && !showMoreId.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  setShowMoreID([...showMoreId, record.key])
                }}
              >
                Show More
                <DownOutlined />
              </h5>
            )}
            {showMoreId.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
    },

    // contact
    {
      title: 'Primary Contact',
      dataIndex: 'emergencyContacts',
      key: 'emergencyContacts',
      width: 200,
      sorter: (a: any, b: any) =>
        a.emergencyContacts.length - b.emergencyContacts.length,
      sortOrder:
        sortedInfo.columnKey === 'primarycontact' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.kids === false ? 'display-none' : '',
      render: (text: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]}>
            {record.members?.map((member: any, index: number) => {
              if (showMoreIdPhone.includes(record.key)) {
                return (
                  <Col className="mb-16px">
                    <div> {member?.phone?.number} </div>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col className="mb-24px">
                    <div> {member?.phone?.number} </div>
                  </Col>
                )
              }
            })}

            {record.kids &&
              record.members?.length > 2 &&
              !showMoreIdPhone.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreIdPhone([...showMoreId1, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreIdPhone.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId1.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreIdPhone([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
    },

    // //Adults//

    {
      title: 'Adults',
      dataIndex: 'adults',
      key: 'adults',
      sorter: (a: any, b: any) => a.adults.length - b.adults.length,
      sortOrder: sortedInfo.columnKey === 'adults' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.adults === false ? 'display-none' : '',
      render: (text: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.adults?.map((adults: any, index: number) => {
              if (showMoreId2.includes(record.key)) {
                return (
                  <Col>
                    <Link to={`/adult-profile/${adults?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/adult-profile/${adults?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={adults.photo?.post}>
                          {getNameSignature(adults?.firstName)}
                        </Avatar>
                        <div>
                          <h3 className="text-14 w-600">{`${
                            adults.firstName || adults.name
                          } ${adults?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {adults?.relation?.name}
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Link to={`/adult-profile/${adults?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/adult-profile/${adults?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={adults.photo?.post}>
                          {getNameSignature(adults?.firstName)}
                        </Avatar>
                        <div>
                          <h3 className="text-14 w-600">{`${
                            adults.firstName || adults.name
                          } ${adults?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {adults?.relation?.name}
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              }
            })}

            {record.adults &&
              record.adults.length > 2 &&
              !showMoreId2.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreID2([...showMoreId2, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreId2.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId2.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID2([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
    },
    // //kids//
    {
      title: 'Kids ',
      dataIndex: 'Kids',
      key: 'Kids',
      sorter: (a: any, b: any) => a.kids.length - b.kids.length,
      sortOrder: sortedInfo.columnKey === 'kids' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.kids === false ? 'display-none' : '',
      render: (text: any, record: any) => (
        <div className="kids-list">
          <Row gutter={[0, 10]} className="display-column">
            {record.kids?.map((kid: any, index: number) => {
              if (showMoreId1.includes(record.key)) {
                return (
                  <Col>
                    <Link to={`/kid-profile/${kid?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/kid-profile/${kid?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={kid.photo?.post}>
                          {getNameSignature(kid?.firstName)}
                        </Avatar>
                        <div>
                          <h3 className="text-14 w-600">{`${
                            kid.firstName || kid.name
                          } ${kid?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {moment(kid?.dob).format('DD MMM,YYYY')} (
                            {convertDOBToAge(kid?.dob)})
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              } else if (index <= 1) {
                return (
                  <Col>
                    <Link to={`/kid-profile/${kid?._id}`}>
                      <Space
                        size={12}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: `/kid-profile/${kid?._id}`,
                        //   })
                        // }}
                        className="pointer flex align-start"
                      >
                        <Avatar src={kid.photo?.post}>
                          {getNameSignature(kid?.firstName)}
                        </Avatar>
                        <div>
                          <h3 className="text-14 w-600">{`${
                            kid.firstName || kid.name
                          } ${kid?.lastName}`}</h3>
                          <p className="text-12 w-400 grey-txt">
                            {moment(kid?.dob).format('DD MMM,YYYY')} (
                            {convertDOBToAge(kid?.dob)})
                          </p>
                        </div>
                      </Space>
                    </Link>
                  </Col>
                )
              }
            })}

            {record.kids &&
              record.kids.length > 2 &&
              !showMoreId1.includes(record.key) && (
                <h5
                  style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                  className="cursor_pointer align-items-center"
                  onClick={() => {
                    setShowMoreID1([...showMoreId1, record.key])
                  }}
                >
                  Show More
                  <DownOutlined />
                </h5>
              )}
            {showMoreId1.includes(record.key) && (
              <h5
                style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
                className="cursor_pointer align-items-center"
                onClick={() => {
                  let filteredData = showMoreId1.filter(
                    (id: any) => !id === record.key,
                  )
                  setShowMoreID1([filteredData])
                }}
              >
                Show less
                <span
                  className="d-inline-block"
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <DownOutlined />
                </span>
              </h5>
            )}
          </Row>
        </div>
      ),
    },
    // {
    //   title: 'Primary Contact',
    //   dataIndex: 'emergencyContacts',
    //   key: 'emergencyContacts',
    //   width: 200,
    //   sorter: (a: any, b: any) =>
    //     a.emergencyContacts.length - b.emergencyContacts.length,
    //   sortOrder:
    //     sortedInfo.columnKey === 'primarycontact' ? sortedInfo.order : null,
    //   hidden: true,
    //   className: hiddenColumns.kids === false ? 'display-none' : '',
    //   render: (text: any, record: any) => (
    //     <div className="kids-list">
    //       <Row gutter={[0, 10]}>
    //         {record.members?.map((member: any, index: number) => {
    //           if (showMoreIdPhone.includes(record.key)) {
    //             return (
    //               <Col className="mb-16px">
    //                 <div> {member?.phone?.number} </div>
    //               </Col>
    //             )
    //           } else if (index <= 1) {
    //             return (
    //               <Col className="mb-24px">
    //                 <div> {member?.phone?.number} </div>
    //               </Col>
    //             )
    //           }
    //         })}

    //         {record.kids &&
    //           record.members?.length > 2 &&
    //           !showMoreIdPhone.includes(record.key) && (
    //             <h5
    //               style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
    //               className="cursor_pointer align-items-center"
    //               onClick={() => {
    //                 setShowMoreIdPhone([...showMoreId1, record.key])
    //               }}
    //             >
    //               Show More
    //               <DownOutlined />
    //             </h5>
    //           )}
    //         {showMoreIdPhone.includes(record.key) && (
    //           <h5
    //             style={{ display: 'flex', gap: '1rem', color: '#2BA7CA' }}
    //             className="cursor_pointer align-items-center"
    //             onClick={() => {
    //               let filteredData = showMoreId1.filter(
    //                 (id: any) => !id === record.key,
    //               )
    //               setShowMoreIdPhone([filteredData])
    //             }}
    //           >
    //             Show less
    //             <span
    //               className="d-inline-block"
    //               style={{ transform: 'rotate(180deg)' }}
    //             >
    //               <DownOutlined />
    //             </span>
    //           </h5>
    //         )}
    //       </Row>
    //     </div>
    //   ),
    // },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.status === false ? 'display-none' : '',
      render: (text: String, record: any) => (
        <StatusDropdown
          id={record.key}
          name="family"
          status={status}
          changeFn={changeStatus}
          refetchData={refetchFamilies}
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      sorter: false,
      sortOrder: sortedInfo.columnKey === 'action' ? sortedInfo.order : null,
      hidden: true,
      className: hiddenColumns.action === false ? 'display-none' : '',
    },
  ]

  const rowSelection = {}
  const [paginationInfo, onTableChange, PaginationNoTabel] = usePagination({
    totalCount: activeData?.count || 0,
    currentPage: parmsData.page,
    setParams: setParms,
  })

  return (
    <div>
      {alertModal && (
        <DeleteAlert
          isModalVisible={alertModal}
          setIsModalVisible={setAlertModal}
          data={alertModalData}
          onYesClick={() => {
            del(userId)
          }}
        />
      )}
      {showEditFamily && (
        <NewFamily
          familyData={familyData}
          refetchData={refetchFamilies}
          showModal={showEditFamily}
        />
      )}
      <Table
        rowKey="key"
        className=" mentors-table organizations_table"
        rowSelection={rowSelection}
        style={{ width: '100%' }}
        columns={columns}
        dataSource={newArray}
        pagination={paginationInfo}
        onChange={onTableChange}
      />
    </div>
  )
}

// export default FamilyTable
const mapStateToProps = (state: any) => ({
  currentPage: state.family.currentPage,
})
export default connect(mapStateToProps)(FamilyTable)
