import { Button, Col, Row, Select, Space } from "antd";
import React, {  useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Calender from "./Calender";
import AppLayout from "../../components/layout/layout";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

const CalenderApp = () => {
  const [currentDate, setCurrentDate] = useState<any>(new Date());

  const getPreviousdate = () => {
    const currentDayInMilli = new Date(currentDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);
    setCurrentDate(previousDate);
  };

  const getNextDate = () => {
    const currentDayInMilli = new Date(currentDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    setCurrentDate(nextDate);
  };

  return (
    <AppLayout>
      <div className="container ">
        <div className="padding-30">
          <Row style={{ marginBottom: "10px" }} gutter={[0,10]}>
            <Space size={10}>
              <h3 className="b1">
                {moment(currentDate).format("dddd Do  MMMM  YYYY")}
              </h3>

              <Button
                onClick={() => {
                  getPreviousdate();
                }}
              >
                <LeftOutlined></LeftOutlined>
              </Button>
              <Button
                onClick={() => {
                  getNextDate();
                }}
              >
                <RightOutlined></RightOutlined>
              </Button>
            </Space>
          </Row>
          <Row gutter={24}>
            <Col xl={{ span: 6 }} xs={{ span: 8 }}>
              <div className="right-search-block">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div
                    className={"search-input-box"}
                    style={{ marginRight: "0px" }}
                  >
                    <input
                      type="search"
                      className="search-input-group"
                      placeholder="Search "
                      onChange={(e) => {}}
                    />
                    <SearchOutlined />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 4 }} xs={{ span: 6 }}>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="All mentors"
                optionFilterProp="children"
              ></Select>
            </Col>
            <Col xl={{ span: 5 }} xs={{ span: 8 }}>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="All statuses"
                optionFilterProp="children"
              ></Select>
            </Col>
            <Col xl={{ span: 5 }} xs={{ span: 8 }}>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="All skills"
                optionFilterProp="children"
              ></Select>
            </Col>
          </Row>
        </div>
        <div>
          <Calender currentDate={currentDate} />
        </div>
      </div>
    </AppLayout>
  );
};

export default CalenderApp;
