import { Table, Dropdown, Checkbox } from 'antd'
import { useRef, useState } from 'react'
import AppLayout from 'components/layout/layout'
import { useLocation } from 'react-router-dom'
import usePagination from 'helper/usePagination'
import { GetRequestParams, RequestParms } from 'types/globalTypes'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getMentors } from 'api/mentors'
import Spinner from 'components/Spinner'
import ReturnBreadCrumbs from 'components/breadCrumb/NewBreadCrumbs'
import GetAvatar from 'components/global/GetAvatar'
import { Mentor, Skill } from './types'
import {
  capitalizeFirstLetter,
  convertDOBToAge,
  getUserName,
} from 'helper/utility'
import {
  CaretDown,
  CirclesThreePlus,
  MagnifyingGlass,
} from '@phosphor-icons/react'
import './styles.scss'
import { proposeMentor } from 'api/JobRequests'
import { CloseDropeDown } from 'helper/CloseDropdown'
import Down from 'helper/Icons/DownIcon'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useSkillsList } from 'api/Lists'
import useFilters from 'helper/Filters/useFilters'
interface Mentors {
  count: number
  hasNextPage: boolean
  hasPrevPage: boolean
  totalPages: number
  records: Mentor[]
}
interface mutation {
  jobId: string
  mentorId: string
}
const ProposeMentor = () => {
  const [open, setOpen] = useState(0)

  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const handleDropdownClick = CloseDropeDown({ dropdownRef, setOpen })
  const [parms, setParms] = useState<RequestParms & GetRequestParams>({
    page: 1,
    status: 'active',
    limit: 10,
  })
  const [, , , , , SortDropDown] = useFilters({
    open: open,
    setOpen: setOpen,
  })

  const { isLoading, data } = useQuery<Mentors>(['getMentorsList', parms], () =>
    getMentors(parms),
  )
  const proposeMutation = useMutation({
    mutationFn: async ({ jobId, mentorId }: mutation) => {
      return await proposeMentor(jobId, mentorId)
    },
  })
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: data?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
    status: 'active',
  })
  const location: any = useLocation()
  let { id } = location.state
  const handlePropose = (jobId: string, mentorId: string) => {
    proposeMutation.mutateAsync({ jobId, mentorId })
  }
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      colSpan: 1,
      render: (text: string, record: Mentor) => (
        <div className="flex-center">
          <GetAvatar person={record} />
          <div className="ml-10px">
            <h2 className="text-14 w-500">{getUserName(record)}</h2>
            <h4 className="text-12 w-400 grey-txt">
              {capitalizeFirstLetter(record.gender) +
                ' . ' +
                convertDOBToAge(record.dob) +
                'Y'}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Skills',

      dataIndex: 'skills',
      render: (text: Skill[]) => (
        <div className="flex-center flex-wrap">
          {text.map(skill => {
            return (
              <div
                key={skill._id}
                style={{
                  backgroundColor: '#EBEDF1',
                  borderRadius: '100px',
                  padding: '10px 20px',

                  textAlign: 'center',
                  marginRight: '10px',
                }}
              >
                <h4 className="text-12">{skill.name}</h4>
              </div>
            )
          })}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (record: Mentor) => (
        <Dropdown
          menu={{
            items: [
              {
                key: '1',
                label: 'Send Job',
                onClick: () => {
                  handlePropose(id, record.id)
                },
              },
              {
                key: '2',
                label: 'Assign Directly',
              },
            ],
          }}
          placement="bottom"
          arrow
        >
          <button className="borderd-btn" style={{ padding: '8px 24px' }}>
            Send Job
            <CaretDown
              color="#2BA7CA"
              size={18}
              style={{
                position: 'relative',
                top: '4px',
                marginLeft: '4px',
              }}
            />
          </button>
        </Dropdown>
      ),
    },
  ]
  const skillsQuery = useSkillsList()

  const checkboxOptions = skillsQuery.data
    ? skillsQuery.data.map(skill => ({
        label: (
          <div className="flex-center">
            {skill.icon !== '' ? (
              <img src={skill.icon} alt={skill.name} className="w-30px" />
            ) : (
              <CirclesThreePlus size={32} />
            )}
            <h4 className="text-14 ml-20px">{skill.name}</h4>
          </div>
        ),
        value: skill._id,
      }))
    : []

  const Category: ItemType[] = [
    skillsQuery !== undefined
      ? {
          key: '0',
          label: (
            <Checkbox.Group
              options={checkboxOptions}
              className="category-checkbox"
              // onChange={(checkedValues) => console.log('checkedValues', checkedValues)}
            />
          ),
        }
      : {
          key: '0',
          label: (
            <div>
              <Spinner />
            </div>
          ),
        },
  ]
  return (
    <AppLayout>
      <div className=" m-40px">
        <ReturnBreadCrumbs
          links={[
            { name: 'Requests', link: '/jobrequest' },
            { name: `Available Mentors`, link: `/propose-mentor` },
          ]}
        />
        <h1 className="text-24 w-600 mt-20px">Available Mentors</h1>
        <div
          className="flex justify-between mt-20px"
          ref={dropdownRef}
          onClick={handleDropdownClick}
        >
          <div className="flex-center ">
            <h3 className="text-16 mr-20px">Filter by:</h3>
            <div className="filter-btn mr-20px">
              <MagnifyingGlass size={24} />
            </div>
            <Dropdown
              menu={{
                items: Category,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 2}
              overlayStyle={{ width: '250px' }}
            >
              <button
                className={` ${
                  open === 2 ? 'filter-btn-click ' : 'filter-btn'
                } w-500`}
                onClick={() => setOpen(2)}
              >
                Category <Down />
              </button>
            </Dropdown>
          </div>
          {SortDropDown}
        </div>
        <div className="mt-20px">
          {isLoading || proposeMutation.isLoading ? (
            <div className="flex-center justify-center h-80vh">
              <Spinner />
            </div>
          ) : (
            <Table
              rowSelection={{
                ...rowSelection,
              }}
              columns={columns}
              dataSource={data?.records}
              size="middle"
              className="propose-table mentors-table organizations_table"
              pagination={paginationInfo}
              onChange={onTableChange}
            />
          )}
        </div>
      </div>
    </AppLayout>
  )
}

export default ProposeMentor
