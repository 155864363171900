import actions from "./action";

const initState = {
  Alladmins: [],
  adminDetails: [],
  errorData: {},
  action: null,
  message: null,
  loading: false,
  patchComplete: false,
};
const admin = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ALL_ADMINS_REQUEST:
      return {
        Alladmins: [],
        loading: true,
      };
    case actions.GET_ALL_ADMINS_SUCCESS:
      return {
        ...state,
        Alladmins: action.payload,
        loading: false,
      };
    case actions.GET_ALL_ADMINS_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
      };
    case actions.GET_ADMIN_DETAILS:
      return {
        ...state,
        adminDetails: [],
        loading: true,
      };
    case actions.GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        adminDetails: action.payload,
        loading: false,
      };
    case actions.GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
      };
    case actions.PATCH_ADMIN_DETAILS:
      return {
        ...state,
        loading: false,
        patchComplete: true,
        action: action.type,
      };
    case actions.PATCH_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
        patchComplete: false,
      };
    case actions.CHANGE_ADMIN_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.CHANGE_ADMIN_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.CHANGE_ADMIN_PASSWORD_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
      };
    case actions.DEACTIVATE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DEACTIVATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.DEACTIVATE_ADMIN_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
      };
    case actions.CREATE_NEW_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_NEW_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.CREATE_NEW_ADMIN_FAILURE:
      return {
        ...state,
        errorData: action.errors || {},
        action: action.type,
        loading: false,
        message: action.payload,
      };
    default:
      return state;
  }
};
export default admin;
