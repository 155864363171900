/* eslint-disable import/no-anonymous-default-export */
import actions from "./action";

const initState = {
  isVisible : false,
  isModalVisible: false,
  organization: {},
  organizationAnalytics: {},
  organizationReviews: [],
  organizationData: [],
  activeOrganizations: [],
  pendingOrganizations: [],
  currentOrganizationData: [],
  currentMenu: "",
  loading: false,
  updateFlag: false,
  error: {},
  totalCount: 0,
  organizersData: [],
  activeOrganizers: [],
  pendingOrganizers: [],
  totalOrganizers: 0,
  statusUpdate: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actions.MODAL_VISIBLE:
      return {
        ...state,
        isModalVisible: action.payload,
      };

    case actions.ORGANIZER_MODAL_VISIBLE:
      return {
        ...state,
        isVisible: action.payload,
      };

    case actions.UPDATE_PAGE_STATUSES:
      return{
        ...state,
        statusUpdate: !state.statusUpdate
      }

    case actions.ADD_ORGANIZATION_DATA: {
      return {
        ...state,
        organization: {},
        loading: true,
      };
    }

    case actions.ADD_ORGANIZATION_DATA_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        loading: false,
        activeOrganizations:
          action.payload.status === "approved"
            ? [...state.activeOrganizations, action.payload]
            : state.activeOrganizations,
        pendingOrganizations:
          action.payload.status === "under_review"
            ? [...state.pendingOrganizations, action.payload]
            : state.pendingOrganizations,
      };
    }

    case actions.ADD_ORGANIZATION_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATIONS: {
      return {
        ...state,
        organizationData: [],
        loading: true,
      };
    }

    case actions.GET_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        organizationData: action.payload.records,
        activeOrganizations: action.payload.records.filter((organization) => {
          return organization.status === "approved";
        }),
        pendingOrganizations: action.payload.records.filter((organization) => {
          return organization.status === "under_review";
        }),
        loading: false,
      };
    }

    case actions.GET_ORGANIZERS_OF_ORGANIZATION: {
      return {
        ...state,
        organizersData: [],
        loading: true,
      };
    }

    case actions.GET_ORGANIZERS_OF_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        totalOrganizers: action.payload.length,
        organizersData: action.payload,
        activeOrganizers: action.payload.filter((organizer) => {
          return organizer.status === "approved";
        }),
        pendingOrganizers: action.payload.filter((organizer) => {
          return organizer.status === "active";
        }),
        loading: false,
      };
    }


    case actions.GET_ORGANIZERS_OF_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATION: {
      return {
        ...state,
        organization: {},
        loading: true,
      };
    }

    case actions.GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATION_ANALYTICS: {
      return {
        ...state,
        organizationAnalytics: {},
        loading: true,
      };
    }

    case actions.GET_ORGANIZATION_ANALYTICS_SUCCESS: {
      return {
        ...state,
        organizationAnalytics: action.payload,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATION_ANALYTICS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.DELETE_ORGANIZATION_DATA: {
      let filteredData = state.organizationData.filter((item) => {
        return item.id !== action.payload;
      });
      localStorage.setItem("organization", JSON.stringify(filteredData));
      return {
        ...state,
        organizationData: filteredData,
      };
    }

    case actions.CURRENT_MENU: {
      return {
        ...state,
        currentMenu: action.payload,
      };
    }

    case actions.UPDATE_ORGANIZATION: {
      return {
        ...state,
        organization: {},
        loading: true,
        updateFlag: true,
      };
    }

    case actions.UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        activeOrganizations:
          action.payload.status === "approved"
            ? state.activeOrganizations.map((organization) => {
                return organization.id === action.payload.id
                  ? action.payload
                  : organization;
              })
            : state.activeOrganizations,
        pendingOrganizations:
          action.payload.status === "under_review"
            ? state.pendingOrganizations.map((organization) => {
                return organization.id === action.payload.id
                  ? action.payload
                  : organization;
              })
            : state.pendingOrganizations,
        loading: false,
        updateFlag: false,
      };
    }

    case actions.UPDATE_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.UPDATE_ORGANIZATIONS_STATUS: {
      return {
        ...state,
        organization: {},
        loading: true,
      };
    }

    case actions.UPDATE_ORGANIZATIONS_STATUS_SUCCESS: {
      return {
        ...state,
        organization: action.payload,
        activeOrganizations:
          action.payload.status === "approved"
            ? [...state.activeOrganizations, action.payload]
            : state.activeOrganizations.filter((organization) => {
                return organization.id !== action.payload.id;
              }),
        pendingOrganizations:
          action.payload.status === "under_review"
            ? [...state.pendingOrganizations, action.payload]
            : state.pendingOrganizations.filter((organization) => {
                return organization.id !== action.payload.id;
              }),
        loading: false,
      };
    }

    case actions.UPDATE_ORGANIZATIONS_STATUS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case actions.CURRENT_ORGANIZATION_DATA: {
      return {
        ...state,
        currentOrganizationData: action.payload,
      };
    }
    case actions.FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }


    // organization Deleting reducer
    case actions.DELETE_ORGANIZATION_REQUEST: {
      return {
        ...state,
        organization: {},
        loading: true,
      };
    }

    case actions.DELETE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organization: {},
        loading: false,
      }
    }

    case actions.DELETE_ORGANIZATION_FAILURE: {
      return {
        ...state,
        loading: false,

      }
    }

    case actions.GET_ORGANIZATION_REVIEWS: {
      return {
        ...state,
        organizationReviews: [],
        loading: true,
      };
    }

    case actions.GET_ORGANIZATION_REVIEWS_SUCCESS: {
      return {
        ...state,
        organizationReviews: action.payload,
        loading: false,
      };
    }

    case actions.GET_ORGANIZATION_REVIEWS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }


    default:
      return state;
  }
};
