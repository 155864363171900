import { Popover, Button, Col, Row, Typography } from "antd";
import ChangePassword from "./ChangePasswordPopup";
import React, { useEffect, useState } from "react";
import "./index.css";
import Amin from "../../../assets/Images/Amin.png";
import { Link } from "react-router-dom";
import PasswordIco from "../../../assets/Images/LockKeyBlack.svg";
import User from "../../../assets/Images/UserProfileblack.svg";
import DeactivateIco from "../../../assets/Images/deactivate.svg";
import { connect, useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";
import AddNewAdmin from "./AddNewAdminPopup";
import Deactivate from "./DeactivateAccount";
const { Title } = Typography;

const AdminsMain = ({ admins, loading }: any) => {
  const dispatch = useDispatch();
  const [selectedPopup, setSelectedPopup] = useState<Number | null | false>(
    null
  );
  const [userId, setId] = useState<any>(null);
  useEffect(() => {
    dispatch({
      type: actions.GET_ALL_ADMINS_REQUEST,
    });
  }, [selectedPopup === false]);

  const buttonContent = (uid: string, firebaseId: string) => (
    <div className="cursor_pointer  m-10 ">
      <div className="d-flex w-100">
        <img alt="" src={User} />
        <Link to={{ pathname: "/admin-profile", state: { uid, firebaseId } }}>
          <p className="font15 ant-form flex pl-10">View profile</p>
        </Link>
      </div>
      <div className="d-flex w-100 mt-12">
        <img alt="" src={PasswordIco} />
        <p
          className="font15 ant-form flex pl-10"
          onClick={() => {
            setId(firebaseId);
            setSelectedPopup(1);
          }}
        >
          Change password
        </p>
      </div>
      <div className="d-flex w-100 mt-12">
        <img alt="" src={DeactivateIco} />
        <p
          className="font15 ant-form flex pl-10"
          style={{ color: "#FF6961" }}
          onClick={() => {
            setId(firebaseId);

            setSelectedPopup(2);
          }}
        >
          Deactivate account
        </p>
      </div>
    </div>
  );
  return (
    <div className="profile_container" style={{ marginTop: 60 }}>
      {selectedPopup === 1 ? (
        <ChangePassword id={userId} notvisible={setSelectedPopup} />
      ) : (
        ""
      )}
      {selectedPopup === 2 ? (
        <Deactivate id={userId} notvisible={setSelectedPopup} />
      ) : (
        ""
      )}
      {selectedPopup === 3 ? (
        <AddNewAdmin notvisible={setSelectedPopup} loadingData={loading} />
      ) : (
        ""
      )}
      <div className="d-flex justify-between align-items-center mb-25">
        <Title level={4} style={{ marginBottom: "0px" }}>
          Admins
        </Title>

        <Button
          className=" br-12"
          type="primary"
          onClick={() => {
            setSelectedPopup(3);
          }}
        >
          Add Admin
        </Button>
      </div>

      {admins?.map((data: any) => {
        return (
          <div
            className="d-flex justify-between align-items-start mb-25 flex-wrap bb-solid-1 b-color-gray"
            key={data.id}
          >
            <div className=" pb-10">
              <Row>
                <Col span={24}>
                  <div className="d-flex mb-12">
                    {data.photo != null ? (
                      <img
                        src={data.photo.post}
                        alt="UserImage"
                        className="mr-15 image-style"
                      />
                    ) : (
                      <img src={Amin} alt="userImage" className="mr-15" />
                    )}

                    <div>
                      <h5 className="b1">
                        {data.firstName} {data.lastName}
                      </h5>
                      <span className="text-gray-color d-block font13">
                        {data.email}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <p className="font14">
                    Last login was Monday, 24 Feb 2023 4:30 PM
                  </p> */}
            </div>
            <div className="pb-20 cursor_pointer ">
              <Popover
                content={buttonContent(data.id, data.firebaseId)}
                placement="bottom"
              >
                <Button className=" br-10 px-30 py-8" type="primary">
                  Manage
                </Button>
              </Popover>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  admins: state.admin.Alladmins,
  loading: state.admin.loading,
  errorState: state.admin.errorData,
});
export default connect(mapStateToProps)(AdminsMain);
