import React from 'react';
import { Spin } from 'antd';

const Spinner = () => {
  return (
    <div className={'spinner'}>
      <Spin size="large" tip="Loading" />
      <div className="content" />
    </div>
  );
};

export default Spinner;
