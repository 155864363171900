import { axiosGet, axiosPost, axiosPatch } from '../Redux/axiosHelper'
import { RequestParms, Populate } from '../types/globalTypes'
import { converArrayToQueryParms } from './family'

interface rejectMentorRequestPayload {
  status: string
  rejectedReason: string
}

export const getRequestdJobs = async (params: RequestParms & Populate) => {
  const p = {
    ...params,
  }
  delete p.populate
  const response = await axiosGet(
    `v1/admins/mentor-requests?${await converArrayToQueryParms(
      'populate',
      params.populate,
    )}`,
    p,
  )
  return response.data
}
export const getContractRoomDetails = async (id: string) => {
  const response = await axiosGet(`v1/mentor-request/contract-room/${id}`)
  return response.data
}
export const proposeMentor = async (jobId: string, mentorId: string) => {
  const response = await axiosPost(
    {
      jobId,
      mentorId,
    },
    `v1/admins/propose-mentor`,
    'Mentor Proposed successfully',
  )
  return response.data
}

export const rejectMentorRequest = async (requestId: any, payload: any) => {
  const response = await axiosPatch(payload, `v1/mentor-request/update-status/${requestId}`);
  return response.data;
}