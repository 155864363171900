import React, { useState, useEffect } from 'react'
import { Modal, Select, Upload } from 'antd'
import Loader from 'helper/Loader'
import { read, utils } from 'xlsx'
import Cancel from 'assets/Images/close.svg'
import { successMessage, failureMessage } from 'api/global'
import VectorImg from 'assets/Images/Vector.svg'
import CsvImg from 'assets/Images/pdf 1.svg'
import CheckImg from 'assets/Images/checked.svg'
import warningImg from 'assets/Images/ImportWarning.svg'
import plusImg from 'assets/Images/ImportPlus.svg'
import { useMutation } from '@tanstack/react-query'
import {confirmOrganizationImportData,importOrganizationFile} from 'api/Organizations'
const { Option } = Select
const ImportOrganization  = ({ visible, onClose }) => {
    const [importType, setImportType] = useState('Organization')
    const [fileHeaders, setFileHeaders] = useState([])
    const [fileContent, setFileContent] = useState('')
    const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true)
    const [fileList, setFileList] = useState([])
    const [isMappingModalVisible, setIsMappingModalVisible] = useState(false)
    const [attributeMapping, setAttributeMapping] = useState({})
    const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true)
    const [isReviewModalVisible, setIsReviewModalVisible] = useState(false)
    const [apiResponse, setApiResponse] = useState(null)
    const [isResultModalVisible, setIsResultModalVisible] = useState(false)
    const [isLogsModalVisible, setIsLogsModalVisible] = useState(false)
    const uploadProps = {
        beforeUpload: file => {
          handleFileUpload({ file })
          return false
        },
      }
      const handleFileUpload = ({ file }) => {
        const reader = new FileReader()
        reader.onload = e => {
          const binaryStr = e.target.result
          const workbook = read(binaryStr, { type: 'binary' })
          const sheetName = workbook.SheetNames[0]
          const sheet = workbook.Sheets[sheetName]
          const csvData = utils.sheet_to_csv(sheet)
          const headers = csvData.split('\n')[0].split(',')

          setFileHeaders(headers)
          setFileContent(csvData) // Store the CSV content
          setIsImportButtonDisabled(false)
          successMessage(`${file.name} file uploaded successfully`)
        }
        reader.onerror = () => {
          failureMessage(`${file.name} file upload failed.`)
        }
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          reader.readAsBinaryString(file)
        } else {
          reader.readAsText(file)
        }
      }
      const handleBack = () => {
        resetMapping()
       setIsMappingModalVisible(false)
     }
     const attributeOptions = [
      // { key: 'name', display: 'Name', required: true },
      // { key: 'ownerEmail', display: 'Owner Email', required: true },
      // { key: 'apartment', display: 'Apartment', required: true },
      // { key: 'street', display: 'Street', required: true },
      // { key: 'city', display: 'City', required: true },
      // { key: 'state', display: 'State', required: true },
      // { key: 'zip', display: 'Zip', required: true },
      // { key: 'country', display: 'Country', required: true },
      // { key: 'phone', display: 'Phone', required: false },
      // { key: 'email', display: 'Email', required: false },
      // { key: 'establishedOn', display: 'Established On', required: false },
      // { key: 'nonProfit', display: 'Non Profit', required: false },
      // { key: 'website', display: 'Website', required: false },
      // { key: 'about', display: 'About', required: false },
      // { key: 'communicationEmail', display: 'Communication Email', required: false },
      // { key: 'facebook', display: 'Facebook', required: false },
      // { key: 'insta', display: 'Insta', required: false },
      // { key: 'twitter', display: 'Twitter', required: false },
      // { key: 'youtube', display: 'Youtube', required: false },
      // { key: 'linkedIn', display: 'LinkedIn', required: false },
      // { key: 'snapchat', display: 'Snapchat', required: false },
      // { key: 'tiktok', display: 'Tiktok', required: false },
      // { key: 'bereal', display: 'Bereal', required: false },

    { key: 'name', display: 'Name', required: true },
		{ key: 'ownerEmail', display: 'Owner Email', required: true },
		{ key: 'apartment', display: 'Apartment', required: true },
		{ key: 'street', display: 'Street', required: true },
		{ key: 'city', display: 'City', required: true },
		{ key: 'state', display: 'State', required: true },
		{ key: 'zip', display: 'Zip', required: true },
		{ key: 'country', display: 'Country', required: true },
		{ key: 'dialCode', display: 'Dial Code', required: false },
		{ key: 'phone', display: 'Phone', required: false },
		{ key: 'email', display: 'Email', required: false },
		{ key: 'establishedOn', display: 'Established On', required: false },
		{ key: 'nonProfit', display: 'Non Profit', required: false },
		{ key: 'website', display: 'Website', required: false },
		{ key: 'about', display: 'About', required: false },
		{ key: 'communicationEmail', display: 'Communication Email', required: false },
		{ key: 'facebook', display: 'Facebook', required: false },
		{ key: 'insta', display: 'Insta', required: false },
		{ key: 'twitter', display: 'Twitter', required: false },
		{ key: 'youtube', display: 'Youtube', required: false },
		{ key: 'linkedIn', display: 'LinkedIn', required: false },
		{ key: 'snapchat', display: 'Snapchat', required: false },
		{ key: 'tiktok', display: 'Tiktok', required: false },
		{ key: 'bereal', display: 'Bereal', required: false },
    ]
    const handleAttributeChange = (attribute, value) => {
      setAttributeMapping({ ...attributeMapping, [attribute]: value })
    }
    const handleReview = () => {
      setIsMappingModalVisible(false)
      setIsReviewModalVisible(true)
    }
    const resetMapping = () => {
      const newMapping = {}
      attributeOptions.forEach(attr => {
        newMapping[attr.key] = 'Do not import'
      })
      setAttributeMapping(newMapping)
    }
    useEffect(() => {
      const areAllRequiredAttributesMapped = attributeOptions
        .filter(attr => attr.required)
        .every(
          attr =>
            attributeMapping[attr.key] &&
            attributeMapping[attr.key] !== 'Do not import',
        )
  
      setIsContinueButtonDisabled(!areAllRequiredAttributesMapped)
    }, [attributeMapping])
    const handleImport = () => {
      const lines = fileContent.split('\n')
      const headers = lines[0].split(',')
  
      const mappedData = []
  
      // Map the data
      for (let i = 1; i < lines.length; i++) {
        if (!lines[i]) continue
        const values = lines[i].split(',')
        const mappedRow = {}
  
        attributeOptions.forEach(attr => {
          const csvColumn = attributeMapping[attr.key]
          if (csvColumn && csvColumn !== 'Do not import') {
            const columnIndex = headers.indexOf(csvColumn)
            mappedRow[attr.key] = values[columnIndex].replace(/(^"|"$)/g, '')
          }
        })
  
        mappedData.push(mappedRow)
      }
      importOrganizationMutation.mutate({ payload: mappedData })
    }
    const importOrganizationMutation = useMutation({
      mutationFn: async ({ payload }: any) => {
        return await importOrganizationFile(payload)
      },
      onSuccess: data => {
    
          if(data){
            setIsReviewModalVisible(false)
        setApiResponse(data)
        setIsResultModalVisible(true)
          }
  
  
        // successMessage('organization imported successfully')
        // onClose()
      
      },
    })
    const CancelImport = () => {
      const payload = {
        validationId: apiResponse?.validationId,
        confirm: false,
      }
      confirmImportMutation.mutate({ payload: payload })
    }
    const handleComplete = () => {
      const payload = {
        validationId: apiResponse?.validationId,
        confirm: true,
      }
      confirmImportMutation.mutate({ payload: payload })
    }
    const confirmImportMutation = useMutation({
      mutationFn: async ({ payload }: any) => {
        return await confirmOrganizationImportData(payload)
      },
      onSuccess: data => {
        if(data){
          successMessage(data)
          setIsResultModalVisible(false)
        onClose()
        }
      },
    })
    const downloadCSV = () => {
      const logs = apiResponse?.logs || []
      const headers = ['Row', 'Message']
      const rows = logs.map(log => [log.row + 1, log.msg])
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        [headers, ...rows].map(e => e.join(',')).join('\n')
  
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'logs.csv')
      document.body.appendChild(link) 
      link.click()
      document.body.removeChild(link)
    }
    return (
        <>
         <Modal
        visible={visible}
        footer={null}
        onCancel={onClose}
        className="import-modal"
      >
        <div className="popup-header">
          <h2 className="importText">Import Data</h2>
          <img src={Cancel} alt="close button" onClick={onClose} />
        </div>
        <Select
          defaultValue={importType}
          style={{
            width: '100%',
            height: '40px',
            marginBottom: 16,
            marginTop: 10,
          }}
        >
        
        </Select>
        <Upload.Dragger
          {...uploadProps}
          accept=".csv,.xlsx"
          fileList={fileList}
          onChange={info => setFileList(info.fileList)}
        >
          <p className="ant-upload-drag-icon">
            <img alt="VectorImg" src={VectorImg} />
          </p>
          <p className="dragText">
            Drag and drop document here or
            <b className="uploadText"> upload document</b>
          </p>
          <p className="ant-upload-hint" style={{ marginTop: '10px' }}>
            CSV or XLSX formats
          </p>
        </Upload.Dragger>
        <div className="flex justify-end w-full mt-20">
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="prim-sm-btn"
            disabled={isImportButtonDisabled}
            onClick={() => setIsMappingModalVisible(true)}
          >
            Import
          </button>
        </div>
      </Modal>

      <Modal
        visible={isMappingModalVisible}
        footer={null}
        onCancel={handleBack}
        className="mapping-modal"
      >
        <div>
          <div className="popup-header-2">
            <h2>Customize your attributes</h2>
            <img src={Cancel} alt="close button" onClick={handleBack} />
          </div>
          <p className="attributeText">
            Select the correct attribute for each column you’d like to import,
            or create a new one.
          </p>
          <p className="schema-Text">Schema mapping</p>
          <div className="schema-mapping-header">
            <span className="attribute-header">Attribute</span>
            <span className="csv-column-header">CSV column</span>
          </div>
          <div className="schema-mapping h-25rem overflow-auto">
            {attributeOptions.map(attr => (
              <div key={attr.key} className="mapping-row">
                <div className="fleximg">
                  <img
                    alt="csv image"
                    src={CsvImg}
                    style={{ marginRight: '5px' }}
                  />
                  <span
                    className={`attribute-label position-relative ${
                      attr.required ? 'required-input' : ''
                    }`}
                  >
                    {attr.display}
                  </span>
                </div>
                <Select
                  value={attributeMapping[attr.key] || 'Do not import'}
                  className="csv-column-select"
                  onChange={value => handleAttributeChange(attr.key, value)}
                >
                  <Option value="Do not import">Do not import</Option>
                  {fileHeaders.map(header => (
                    <Option key={header} value={header}>
                      {header}
                    </Option>
                  ))}
                </Select>
              </div>
            ))}
          </div>
          <div className="flex justify-end w-full mt-20">
            <button
              className="prim-sm-btn"
              style={{ background: '#EBEDF1', color: 'black' }}
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className="prim-sm-btn"
              disabled={isContinueButtonDisabled}
              onClick={handleReview}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        visible={isReviewModalVisible}
        footer={null}
        onCancel={() => setIsReviewModalVisible(false)}
        className="review-modal"
      >
        <div>
          <div className="popup-header-2">
            <h2>Review values</h2>
            <img
              src={Cancel}
              alt="close button"
              // onClick={handleBack}
              // onClick={() => (setIsReviewModalVisible(false), handleBack)}
              onClick={() => {
                setIsReviewModalVisible(false)
                setIsMappingModalVisible(true)
              }}
            />
          </div>
          <p className="attributeText">
            We’va created the values we found in your data. Check that the
            values are correct and resolve any that are invalid.
          </p>
          <p className="schema-Text">Schema mapping</p>
          <div className="schema-mapping-header">
            <span className="attribute-header">Attribute</span>
            <span className="csv-column-header">CSV column</span>
          </div>
          <div className="schema-mapping h-25rem overflow-auto">
            {Object.keys(attributeMapping).map(
              key =>
                attributeMapping[key] !== 'Do not import' && (
                  <div key={key} className="mapping-row">
                    <div className="fleximg-2">
                      <img
                        alt="check image"
                        src={CheckImg}
                        style={{ marginRight: '10px' }}
                      />
                      <img
                        alt="csv image"
                        src={CsvImg}
                        style={{ marginRight: '8px' }}
                      />

                      <span className="attribute-label position-relative">
                        {
                          attributeOptions.find(attr => attr.key === key)
                            .display
                        }
                      </span>
                    </div>
                    <span className="csv-column-label">
                      {attributeMapping[key].replace(/"/g, '')}
                    </span>
                  </div>
                ),
            )}
          </div>
          <div className="flex justify-end w-full mt-20">
            <button
              className="prim-sm-btn"
              style={{
                background: '#EBEDF1',
                color: 'black',
                marginRight: '10px',
              }}
              onClick={() => {
                setIsReviewModalVisible(false)
                setIsMappingModalVisible(true)
              }}
            >
              Back
            </button>
            <button className="prim-sm-btn" onClick={handleImport}>
              {importOrganizationMutation.isLoading ? <Loader /> : 'Continue'}
            </button>
          </div>
        </div>
      </Modal>
      
      <Modal
        visible={isResultModalVisible}
        onCancel={() => setIsResultModalVisible(false)}
        footer={null}
        className="completeModal"
      >
        <div className="popup-header-2">
          <h2>Complete import</h2>
          <img
            src={Cancel}
            alt="close button"
            onClick={() => {
              setIsResultModalVisible(false)
            }}
          />
        </div>
        <div className="d-flex mt-20">
          <div className="recordFill">
            <div className="flex">
              <img src={warningImg} alt="warningImg" />
              <span className="failedText">{apiResponse?.stats?.invalid}</span>
            </div>
            <span className="text">Records failed</span>
          </div>
          <div className="recordFill" style={{ marginLeft: '10px' }}>
            <div className="flex">
              <img src={plusImg} alt="plusImg" />
              <span className="SuccessText">{apiResponse?.stats?.valid}</span>
            </div>
            <span className="text">Records will be added</span>
          </div>
        </div>
        <div className="flex justify-end w-full mt-20">
          {apiResponse?.stats?.invalid > 0 && (
            <>
              <button
                className="prim-sm-btn"
                style={{
                  background: 'transparent',
                  border: '1px solid #2BA7CA',
                  color: '#2BA7CA',
                  marginRight: '10px',
                }}
                onClick={CancelImport}
              >
                Cancel
              </button>
              <button
                className="prim-sm-btn"
                style={{
                  background: 'transparent',
                  border: '1px solid #2BA7CA',
                  color: '#2BA7CA',
                  marginRight: '10px',
                }}
                onClick={() => {
                  setIsLogsModalVisible(true)
                }}
              >
                View logs
              </button>
            </>
          )}
          <button className="prim-sm-btn" onClick={handleComplete}>
            {confirmImportMutation.isLoading ? <Loader /> : 'Complete'}
          </button>
        </div>
      </Modal>

      <Modal visible={isLogsModalVisible} footer={null} className="logsModal">
        <div className="popup-header-2">
          <h2>Logs</h2>
          <img
            src={Cancel}
            alt="close button"
            onClick={() => {
              setIsLogsModalVisible(false)
            }}
          />
        </div>

        <div className="schema-mapping h-25rem overflow-auto">
          <table>
            <thead>
              <tr>
                <th colSpan={1}></th>
                <th>Row</th>
                <th colSpan={10}></th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {apiResponse?.logs.map((log, index) => (
                <tr key={index}>
                  <td colSpan={1}></td>
                  <td style={{ textAlign: 'center' }} className="errorMessage">
                    {log.row + 1}
                  </td>
                  <td colSpan={10}></td>
                  <td className="errorMessage">{log.msg}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end w-full mt-20">
          <button
            className="prim-sm-btn"
            style={{
              background: 'transparent',
              border: '1px solid #2BA7CA',
              color: '#2BA7CA',
              marginRight: '10px',
            }}
            onClick={() => {
              setIsLogsModalVisible(false)
            }}
          >
            Back
          </button>
          <button className="prim-sm-btn" onClick={downloadCSV}>
            Download
          </button>
        </div>
      </Modal>
        </>
       

      
    )
}
export default ImportOrganization
