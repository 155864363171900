import React, { useEffect, useState } from 'react'
import { Button, Modal, Select, Input, Space } from 'antd'
import axios from 'axios'
import Cancel from 'assets/Images/close.svg'
import './Style/newsStyle.scss'
import 'antd/dist/antd.css'
import { unpublishNews, getNews } from 'api/News'
import { useMutation, useQuery } from '@tanstack/react-query'

const { TextArea } = Input

interface UnpublishModalProps {
  parms: string
  newsid: string
  visible: boolean
  onClose: () => void
  onUnpublish: () => void
  reason: string
  comments: string
  setReason: (value: string) => void
  setComments: (value: string) => void
}

const UnpublishModal: React.FC<UnpublishModalProps> = ({
  parms,
  newsid,
  visible,
  onClose,
  onUnpublish,
  reason,
  comments,
  setReason,
  setComments,
}) => {
  // State for options
  const [options, setOptions] = useState([
    { value: 'Content is spam', label: 'Content is spam' },
    {
      value: 'Misleading title or content',
      label: 'Misleading title or content',
    },
    {
      value: 'Promotes harmful misinformation',
      label: 'Promotes harmful misinformation',
    },
    { value: 'other', label: 'Other' },
  ])

  const handleUnpublish = async () => {
    changeMutation.mutate({
      id: newsid,
      payload: {
        status: 'unPublished',
        unPublishDetails: {
          reason: reason,
          additionalComments: comments,
        },
      },
    })
  }
  const {
    data: newsData,
    isLoading: newsLoading,
    isFetching: newsFetching,
    refetch: newsRefetch,
  } = useQuery<any>({
    queryKey: ['all-news'],
    queryFn: () => getNews(parms),
    enabled: false,
  })
  // useEffect(() => {
  //   newsRefetch()
  // }, [parms])
  const changeMutation = useMutation({
    mutationFn: async ({ id, payload }: any) => {
      return await unpublishNews(id, payload)
    },
    onSuccess: resp => {
      if (resp) {
        newsRefetch()
        onClose()
      }
    },
  })
  return (
    <Modal visible={visible} onCancel={onClose} footer={null}>
      <div className="popup-header">
        <h2 className="importText ">
          <b>Unpublish news</b>
        </h2>
        <img src={Cancel} alt="close button" onClick={onClose} />
      </div>
      <p className="Note-text">
        Are you sure you want to unpublish the news? Kindly provide a brief
        explanation to help the organization understand the reason for this
        decision and facilitate potential republishing.
      </p>
      <p className="reason-text mt-20">Reason</p>

      <Select
        placeholder="Optional"
        optionFilterProp="label"
        value={reason || undefined}
        onChange={value => setReason(value as string)}
        style={{
          width: '100%',
          height: '40px',
          marginBottom: 16,
          marginTop: 10,
        }}
        options={options}
      />

      <TextArea
        placeholder="Enter some additional comments..."
        value={comments}
        onChange={e => setComments(e.target.value)}
        rows={4}
      />
      <div className="flex justify-end w-full mt-20">
        <Space size={20}>
          <button
            className="prim-sm-btn"
            style={{ background: '#EBEDF1', color: 'black' }}
            onClick={onClose}
          >
            No, keep it
          </button>

          <button
            style={{ backgroundColor: '#FF6961' }}
            className="prim-sm-btn"
            onClick={handleUnpublish}
          >
            Yes,cancel
          </button>
        </Space>
      </div>
    </Modal>
  )
}

export default UnpublishModal
