import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { createDropInIntent } from 'api/Events'
import { useLocation } from 'react-router-dom'
import { forceRefetch } from 'helper/utility'
import { successMessage } from 'api/global'
import { myStore } from 'state/eventStore'
import PaymentMethod from 'pages/Settings/PaymentMethod/PaymentMethod'
import Payments from 'pages/Events/Profiles/Payments'
import Loader from 'helper/Loader'
const CheckOutWithOutAccount = ({
  amount,
  mail,
  checkInId,
  closeModal,
}: any) => {
  const location = useLocation()
  const currentEvent: any = location.state
  const queryClient = new QueryClient()
  const stripe = useStripe()
  const elements: any = useElements()
  const setTag = myStore(store => store.setTag)
  const getSecret = useMutation({
    mutationFn: async ({ body }: any) => {
      return await createDropInIntent(body)
    },
    onSuccess: () => {
      successMessage('Dropped-out successfully')
      closeModal()
      setTag(true)
      forceRefetch('getAttendieStatus', queryClient)
    },
  })
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (!stripe || !elements) {
      return
    }

    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      .then(function (result) {
        if (result.error) {
        } else {
          const body = {
            amount: amount,
            paymentMethodId: result.paymentMethod.id,
            email: mail,
            checkInId: checkInId,
            checkOutOrganizerId: currentEvent.organizerIds[0],
          }
          getSecret.mutate({ body })
        }
      })
  }
  const cardElementOptions = {
    hidePostalCode: true,
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-20px mb-20px">
        <CardElement options={cardElementOptions} />
        {/* <PaymentMethod /> */}
        {/* <Card/> */}
        {/* <Payments /> */}
      </div>
      <button
        type="submit"
        disabled={!stripe || !elements}
        className="prim-btn mt-20px w-full mb-20px"
      >
        {getSecret.isLoading ? <Loader /> : `Pay $${amount}`}
      </button>
    </form>
  )
}

export default CheckOutWithOutAccount
