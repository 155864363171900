import React, { useRef } from 'react'
import ReactQuill from 'react-quill'
import { modules, formats, customValues } from './EditorTollbar'
import 'react-quill/dist/quill.snow.css'
import './style.scss'

interface QuillInputProps {
  text: string
  setText: React.Dispatch<React.SetStateAction<string>>
}

const QuillInput: React.FC<QuillInputProps> = ({ text, setText }) => {
  const quillRef = useRef<ReactQuill | null>(null)

  const handleChange = (value: string) => {
    setText(value)
  }

  const insertCustomValue = (value: string) => {
    const quillEditor = quillRef.current?.getEditor()

    if (quillEditor) {
      quillEditor.focus()
      const range = quillEditor.getSelection()

      if (range) {
        quillEditor.insertText(range.index, value)
      } else {
        quillEditor.insertText(quillEditor.getLength() - 1, value)
      }
    }
  }

  return (
    <div className="editor-input">
      <ReactQuill
        ref={quillRef}
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />

      <div className="flex align-center gap-10px main-wrappper-values mt-20px scroller pb-10px">
        {customValues.map((item, index) => {
          return (
            <div
              key={index}
              className="custom-value-param pointer"
              onClick={() => insertCustomValue(item.value)}
            >
              <p className="text-15 w-500">{item.name}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default QuillInput
