import { Form, Button, Row, Input, Col, DatePicker } from 'antd'
import { Layout } from 'antd'
import facebookIcon from '../../../../assets/Images/facebook.svg'
import twitter from '../../../../assets/Images/twitter.svg'
import moment from 'moment'
import youtube from '../../../../assets/Images/youtube.svg'
import tiktok from '../../../../assets/Images/tiktok.svg'
import instagram from '../../../../assets/Images/instagram.svg'
import snapIcon from '../../../../assets/Images/snapIcon.svg'
import rightArrowIcon from '../../../../assets/Images/rightarrowicon.svg'

const OrganizationDetails = ({ onSubmit, data, handleBack }: any) => {
  var formSchema = {
    about: data?.about || '',
    establishedOn: moment(data?.establishedOn) || null,
    website: data?.website || '',
    facebook: data?.social?.facebook || '',
    twitter: data?.social?.twitter || '',
    snapchat: data?.social?.snapchat || '',
    tiktok: data?.social?.tiktok || '',
    youtube: data?.social?.youtube || '',
    linkedIn: data?.social?.linkedIn || '',
    insta: data?.social?.insta || '',
  }

  const onFinish = (values: any) => {
    let newData = {
      social: {
        facebook: values?.facebook,
        twitter: values?.twitter,
        insta: values?.insta,
        youtube: values?.youtube,
        tiktok: values?.tiktok,
        snapchat: values?.snapchat,
        linkedIn: values?.linkedIn,
      },
      about: values.about,
      establishedOn: moment(values.establishedOn).format('YYYY-MM-DD'),
      website: values.website,
    }
    onSubmit({
      ...data,
      ...newData,
    })
  }

  return (
    <Layout>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        onFinish={onFinish}
        layout="vertical"
      >
        <div className="modal-h-element ">
          <Row>
            <Col span={24}>
              <label htmlFor="" className="input-label">
                About
              </label>
              <Form.Item name="about">
                <Input.TextArea
                  rows={3}
                  name="about"
                  placeholder="Background, services, etc"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Established on
              </label>
              <Form.Item name="establishedOn">
                <DatePicker className="date-picker" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Website
              </label>
              <Form.Item
                name="website"
                rules={[
                  {
                    required: true,
                    message: 'please input your website!',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" name="website" />
              </Form.Item>
            </Col>
          </Row>
          <label className="input-label">Socials</label>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={facebookIcon}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="facebook">
                <Input placeholder="Facebook profile URL" name="facebook" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={twitter}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="twitter">
                <Input placeholder="Twitter profile URL" name="twitter" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={instagram}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="insta">
                <Input placeholder="instagram profile URL" name="instagram" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={youtube}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="youtube">
                <Input placeholder="youtube profile URL" name="youtube" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={snapIcon}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="snapchat">
                <Input placeholder="snapIcon profile URL" name="snapIcon" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="align-items-center mb-16">
            <Col span={2}>
              <div>
                <img alt="img" src={tiktok}></img>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name="tiktok">
                <Input placeholder="tiktok profile URL" name="tiktok" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="stepper-buttons">
          <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
            <span className="mr-10 d-inline-block">
              <img src={rightArrowIcon} alt="right arrow" />
            </span>
            Back
          </h5>
          <Button type="primary" className="stepper-button" htmlType="submit">
            Create
          </Button>
        </div>
      </Form>
    </Layout>
  )
}
export default OrganizationDetails
