import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/layout";
import DemoGauge from "./Gauge";
import Events from "./Events";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import BasicInfo from "./BasicInfo";
import Availablity from "./Availablity";
import Reviews from "./Reviews";
import ArrowCircleRight from "../../../../assets/Images/ArrowCircleRight.svg";
import backSvg from "../../../../assets/Images/back.svg";
import {} from "./Gauge";
import actions from "../../../../Redux/mentors/action";

const { TabPane } = Tabs;

const onChange = (key: string) => {
  console.log(key);
};
const { Text } = Typography;

const CoachProfile = ({ mentorsData, mentor }: any) => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const [viewDetails, setViewDetails] = useState(false);
  let data = location.state;
  let profileID = JSON.parse(data);

  useEffect(() => {
    dispatch({
      type: actions.GET_MENTOR,
      payload: null,
      id: profileID,
    });
  }, [profileID]);

  console.log("mentor is", mentor);

  return (
    <AppLayout>
      <div className="kid-profile">
        <Row>
          <Col xl={{ span: 8 }} xs={{ span: 24 }}>
            <Card className="card coach_profile_card">
              <div className="coach_profile_header">
                <div style={{ marginBottom: "20px" }}>
                  <Row align="middle" gutter={20}>
                    <Col span={6}>
                      <Avatar src={mentor?.photo?.post} size={80}></Avatar>
                    </Col>
                    <Col span={18}>
                      <Space direction="vertical" size={2}>
                        <h3 className="b1">{mentor?.firstName}</h3>
                        <span className="subtitle">
                          {`${moment(mentor?.dob).format(
                            "DD/MMMM/YYYY"
                          )} (${moment().diff(
                            mentor?.dob,
                            "years",
                            false
                          )} years old)`}
                        </span>
                      </Space>
                    </Col>
                  </Row>
                </div>
                <Button
                  shape="round"
                  className="secondary  edit-profile-change-pass-btns br-8"
                >
                  <h4>
                    <b className="b2">Change password</b>
                  </h4>
                </Button>
                <Button
                  shape="round"
                  className="primary  edit-profile-btns br-8"
                >
                  <h4>
                    <b className="b2 text-white">Edit profile</b>
                  </h4>
                </Button>
              </div>
              <Divider />
              <Row justify="space-between" gutter={10}>
                <Col span={8}>
                  <Card className="grey-card coach-profile-amt-detail">
                    <Row justify="center">
                      <h6 className="mb-0">
                        <b className="b1">$30k</b>
                      </h6>
                    </Row>
                    <span className="subtitle2">Total Earned</span>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card className="grey-card coach-profile-amt-detail">
                    <Row justify="center">
                      <h6 className="mb-0">
                        <b className="b1">2</b>
                      </h6>
                    </Row>
                    <span className="subtitle2">Jobs Worked</span>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card className="grey-card coach-profile-amt-detail">
                    <Row justify="center">
                      <h6 className="mb-0">
                        <b className="b1">24hr</b>
                      </h6>
                    </Row>
                    <span className="subtitle2">Response Time</span>
                  </Card>
                </Col>
              </Row>
              <Divider />
              {viewDetails ? (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row justify="space-between" align="middle">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewDetails(false);
                      }}
                    >
                      <img alt="img" src={backSvg}></img>
                    </span>
                    <h4>
                      <b>Total Participation</b>
                    </h4>
                    <h4>
                      <b>50:25</b>
                    </h4>
                  </Row>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Card
                      className="grey-card"
                      style={{
                        margin: "0px",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <Text>Educational</Text>
                          </Row>
                          <Row>
                            <Progress
                              strokeColor="#4D53BF"
                              percent={70}
                              size="small"
                              strokeWidth={3}
                              format={(percent) => <></>}
                            />
                          </Row>
                        </Col>
                        <Col span={2}>
                          <img alt="img" src={ArrowCircleRight}></img>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      className="grey-card"
                      style={{
                        margin: "0px",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <Text>Camps</Text>
                          </Row>
                          <Row>
                            <Progress
                              strokeColor="#0091FF"
                              percent={70}
                              size="small"
                              strokeWidth={3}
                              format={(percent) => <></>}
                            />
                          </Row>
                        </Col>
                        <Col span={2}>
                          <img alt="img" src={ArrowCircleRight}></img>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      className="grey-card"
                      style={{
                        margin: "0px",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <Text>Sports</Text>
                          </Row>
                          <Row>
                            <Progress
                              strokeColor="#32C5FF"
                              percent={70}
                              size="small"
                              strokeWidth={3}
                              format={(percent) => <></>}
                            />
                          </Row>
                        </Col>
                        <Col span={2}>
                          <img alt="img" src={ArrowCircleRight}></img>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      className="grey-card"
                      style={{
                        margin: "0px",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <Text>Spiritual</Text>
                          </Row>
                          <Row>
                            <Progress
                              strokeColor="#44D7B6"
                              percent={70}
                              size="small"
                              strokeWidth={3}
                              format={(percent) => <></>}
                            />
                          </Row>
                        </Col>
                        <Col span={2}>
                          <img alt="img" src={ArrowCircleRight}></img>
                        </Col>
                      </Row>
                    </Card>
                    <Card
                      className="grey-card"
                      style={{
                        margin: "0px",
                        borderRadius: "10px",
                        padding: "2px",
                      }}
                    >
                      <Row>
                        <Col span={22}>
                          <Row>
                            <Text>Other</Text>
                          </Row>
                          <Row>
                            <Progress
                              strokeColor="#96EDCC"
                              percent={70}
                              size="small"
                              strokeWidth={3}
                              format={(percent) => <></>}
                            />
                          </Row>
                        </Col>
                        <Col span={2}>
                          <img alt="img" src={ArrowCircleRight}></img>
                        </Col>
                      </Row>
                    </Card>
                  </Space>
                </Space>
              ) : (
                <div>
                  <Row justify="space-between">
                    <h4>
                      <b className="b1">Activity</b>
                    </h4>
                    <h4>
                      <b className="b1">Today - 12 Jan 2021</b>
                    </h4>
                  </Row>
                  <Row justify="center" className="mt-40">
                    <DemoGauge setViewDetails={setViewDetails} />
                  </Row>
                </div>
              )}
            </Card>
          </Col>
          <Col xl={{ span: 16 }} xs={{ span: 24 }}>
            <div style={{ paddingTop: "58px" }}>
              <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                style={{ width: "100%" }}
              >
                <TabPane tab="Basic Info" key="1">
                  <BasicInfo mentor={mentor} />
                </TabPane>
                <TabPane tab="Availability" key="2">
                  <Availablity />
                </TabPane>
                <TabPane tab="Events" key="3">
                  <Events />
                </TabPane>
                <TabPane tab="Reviews" key="4">
                  <Reviews />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
};
const mapStateToProps = (state: any) => ({
  mentorsData: state.mentors.mentorsData,
  mentor: state.mentors.mentor,
});

export default connect(mapStateToProps)(CoachProfile);
