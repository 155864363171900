import NotificationSetting from "./NotificationSetting";

const Notification = ({ match }: any) => {
  return (
    <>
      <h1 className="general-s-heading">Notifications</h1>

      <NotificationSetting />
    </>
  );
};
export default Notification;
