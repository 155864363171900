import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Form,
  Input,
  Select,
  Avatar,
  Upload,
  UploadFile,
  UploadProps,
  Image,
} from 'antd'
// import type { GetProp, } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { checkInParticipant, checkOutParticipant } from 'api/Events'
import { successMessage } from 'api/global'
import Spinner from 'components/Spinner'
import PhoneField from 'helper/PhoneField'
import userAvatarSvg from '../../../../../../../assets/Images/userAvatar.svg'
import { useDispatch, connect } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { myStore } from 'state/eventStore'
import { Attendees, SingleFamily, TicketOrder } from 'pages/Events/types'
import ParticpantInfo from '../ParticpantInfo'
import Checkout from './Checkout'
import { getKidAuthorizedAdults } from 'api/family'
import Loader from 'helper/Loader'
import FileUploader from '../../../../../../../helper/FileUploader'
import ImgCrop from 'antd-img-crop'
import FormItem from 'antd/es/form/FormItem'
import uploadactions from '../../../../../../../Redux/FileUpload/action'
import { PlusOutlined } from '@ant-design/icons'
import { ArrowLeft, ArrowRight, XCircle } from '@phosphor-icons/react'
import PhoneInput from 'react-phone-input-2'
interface ChceckInOut {
  name: any
  fileData: any
  participant: Attendees
  closeModel: () => void
  type: 'Check-in' | 'Check-out' | 'drop-out' | 'approve' | 'Drop-in'
  ticketsData: {
    isLoading: boolean
    data: TicketOrder | undefined
  }
  familyData: {
    isLoading: boolean
    data: SingleFamily | undefined
  }
  dropInModel?: any
  // fileData: any
  // name: any
}
const CheckInOutParticpant: React.FC<ChceckInOut> = ({
  fileData,
  name,
  participant,
  closeModel,
  type,
  ticketsData,
  familyData,
  dropInModel,
  // fileData,
  // name,
}) => {
  const [phone, setPhone] = useState<any>({
    dialCode: '',
    isoCode: '',
    number: '',
  })
  // const [fileList, setFileList] = useState<UploadFile[]>([])
  const form = Form.useForm()[0]
  const location = useLocation()
  const event: any = location.state
  const setTag = myStore(store => store.setTag)
  const [checkoutModel, setCheckoutModel] = useState(false)
  const [formData, setData] = useState()

  const closeRefetch = () => {
    closeModel()
    setTag(true)
    dropInModel && dropInModel()
  }
  const { data: adults } = useQuery(['getKidAuthorizeAdult'], () =>
    getKidAuthorizedAdults(participant._id),
  )
  const mutation = useMutation({
    mutationFn: async ({ body }: any) => {
      return await checkInParticipant(body)
    },
    onSuccess: () => {
      successMessage(`Participant ${type.toLowerCase()} successfully`)
      closeRefetch()
    },
  })
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    'guardian',
  )
  const [touched, setTouched] = useState<boolean>(false)
  const [allMembers, setMembers] = useState<any>()

  const onChange = (e: CheckboxChangeEvent) => {
    e.target.checked && setSelectedValue(e.target.name)
  }
  const [avatar, setAvatar] = useState()
  useEffect(() => {
    if (!familyData.data || !selectedValue) {
      return
    } else {
      let members: any = []
      if (selectedValue === 'guardian') {
        members = familyData && [...familyData.data?.guardians]
      }

      if (selectedValue === 'adults') {
        members = adults && [adults]
      }
      setMembers(members)
    }
  }, [selectedValue, familyData])

  const memberList = useMemo(
    () =>
      allMembers?.length > 0
        ? allMembers.map((data: any, index: number) => ({
            key: index,
            label: data.firstName,
            value: data._id,
          }))
        : [{ label: 'No data found', value: 'no data' }],
    [allMembers],
  )

  const onFinish = values => {
    // console.log('type', type)
    const body = {
      ...values,
      photoId: fileData?._id || undefined,
      // checkInOrganizerId: event.organizerIds[0],
      // checkInAdminId: localStorage.getItem('adminId'),
      eventId: event._id,
      familyId: participant.familyId,
      admissionTicketId:
        ticketsData?.data?.ticket?.ticketId || ticketsData?.data?._id,
      additionalTicketIds:
        ticketsData.data?.ticket?.additionalTicket[0]?.ticketId,
    }

    if (type === 'Check-out') {
      body.checkOutAdminId = localStorage.getItem('adminId')
      body.checkInId = participant.checkInId
      if (phone.number) {
        body.pickUpPersonPhoneNumber = phone
      } else {
        body.pickUpPersonPhoneNumber = undefined
      }
      setData(body)
      setCheckoutModel(true)
    }

    if (type === 'Check-in') {
      body.checkInAdminId = localStorage.getItem('adminId')
      const propertyName =
        participant.type.toLowerCase() === 'guardian'
          ? 'guardianId'
          : participant.type === 'authorizedAdult'
          ? 'authorizedAdultId'
          : participant.type.toLowerCase() === 'dropinattendee'
          ? 'dropInAttendeeId'
          : `${participant.type.toLowerCase()}Id`

      body[propertyName] = participant._id

      if (participant.type.toLowerCase() === 'dropinattendee') {
        body.dropIn = true
        body.pickUpGuardianId = undefined
        // Include dropIn: true if participant type is 'dropinattendee'
      }
      if (phone.number) {
        body.pickUpPersonPhoneNumber = phone
      } else {
        body.pickUpPersonPhoneNumber = undefined
      }
      mutation.mutateAsync({
        body,
      })
    }
    if (type === 'Drop-in') {
      const propertyName =
        participant?.type?.toLowerCase() === 'guardian'
          ? 'guardianId'
          : participant?.type === 'authorizedAdult'
          ? 'authorizedAdultId'
          : participant?.participantType?.toLowerCase() === 'dropinattendee'
          ? 'dropInAttendeeId'
          : participant?.participantType?.toLowerCase() === 'kid'
          ? 'kidId'
          : participant?.participantType === 'authorizedAdult'
          ? 'authorizedAdultId'
          : participant?.isType === 'dropIn'
          ? `${participant?.role}Id`
          : `${participant?.type?.toLowerCase()}Id`

      body[propertyName] = participant._id
      if (participant.isType === 'dropIn') {
        body.additionalTicketIds = []
        body.admissionTicketId = undefined
        body.dropIn = true
      } else if (
        participant?.type?.toLowerCase() === 'dropinattendee' &&
        participant?.participantType.toLowerCase() === 'dropinattendee'
      ) {
        body.dropIn = true
        body.pickUpGuardianId = undefined
        body.admissionTicketId = undefined
        body.additionalTicketIds = []
      } else if (
        participant?.type?.toLowerCase() === 'dropinattendee' &&
        participant?.participantType === 'kid'
      ) {
        body.dropIn = true
        body.additionalTicketIds = []
        body.admissionTicketId = undefined
      } else if (
        participant?.type?.toLowerCase() === 'dropinattendee' &&
        participant?.participantType === 'authorizedAdult'
      ) {
        body.dropIn = true
        body.additionalTicketIds = []
        body.admissionTicketId = undefined
      }

      if (phone.number) {
        body.pickUpPersonPhoneNumber = phone
      } else {
        body.pickUpPersonPhoneNumber = undefined
      }
      mutation.mutateAsync({
        body,
      })
    }
  }
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const dispatch = useDispatch()
  const onChangefile: UploadProps['onChange'] = (fileList: any) => {
    const uploadButton = (
      <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
    )
    if (fileList.fileList.length === 0) {
      setFileList([])
    } else if (fileList.fileList.length > 0) {
      if (fileList.file.status === 'uploading') {
        fileList.file.status = 'done'
      }
      if (fileList.file.status === 'done') {
        const fileObj = new FormData()
        fileObj.append('file', fileList.file.originFileObj)
        setFileList(fileList.fileList)
        const newFile = {
          title: name,
          description: 'img',
          altText: 'img',
          sourceType: 'user_profile',
        }

        dispatch({
          type: uploadactions.REQUEST_FILE_UPLOAD,
          payload: fileObj,
          newFile: newFile,
        })
      }
    }
  }

  type FileType = Parameters<UploadProps['beforeUpload']>[0]
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
    })

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewOpen(true)
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList)

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )
  // <<Previous code of phone number>>
  // const onHandlePhone = value => {
  //   setPhone(value)
  //   // setValid(validatePhoneNumber(value?.number))
  //   setTouched(true)
  // }
  // const validatePhoneNumber = (_, phoneNumber: any) => {
  //   const phoneRegex = /^\d{9,}$/
  //   const isValid = phoneRegex.test(phoneNumber)
  //   if (!isValid) {
  //     return Promise.reject('Enter valid phone number')
  //   }
  //   return Promise.resolve()
  // }
  const onHandlePhone = value => {
    const phoneObject = {
      dialCode: value.dialCode,
      isoCode: value.isoCode,
      number: value.number,
    }
    if (phoneObject.number) {
      setPhone(phoneObject)
    } else {
      setPhone({})
    }

    if (!touched) {
      setTouched(true)
    }
  }
  const validatePhoneNumber = (_, phoneNumber) => {
    const phoneRegex = /^\d{9,}$/
    const combinedError =
      (!phoneNumber && 'Phone number is required') ||
      (!phoneRegex.test(phoneNumber) && 'Enter a valid phone number')

    if (combinedError) {
      return Promise.reject(combinedError)
    }

    return Promise.resolve()
  }
  return (
    <div className="mt-20px grid-60-40 h-35rem overflow-auto ">
      <div className="pr-30px ">
        <>
          {/* <h4 >
                {type == 'Check-out'
                  ? '`Who `is picking up?'
                  : type === 'Check-in'
                  ? 'Who is arriving?'
                  : `Who ${type}?`}
              </h4> */}

          {participant?.participantType !== 'dropInAttendee' &&
            participant.role !== 'dropInAttendee' && (
              <>
                <h4>
                  Who {type == 'Check-out' ? 'is' : 'will'}{' '}
                  {type == 'Check-in'
                    ? 'Pick up'
                    : type == 'Check-out'
                    ? 'picking up'
                    : type}
                  ?
                </h4>
                <div className="flex-center mt-20px">
                  <Checkbox
                    onChange={onChange}
                    name="guardian"
                    checked={selectedValue === 'guardian'}
                    className="cutom-checkbox"
                  >
                    Guardian
                  </Checkbox>
                  <Checkbox
                    onChange={onChange}
                    name="adults"
                    checked={selectedValue === 'adults'}
                    className="cutom-checkbox"
                  >
                    Authorized Adults
                  </Checkbox>
                  <Checkbox
                    onChange={onChange}
                    name="someone"
                    checked={selectedValue === 'someone'}
                    className="cutom-checkbox"
                  >
                    Someone else
                  </Checkbox>
                </div>
              </>
            )}
        </>
        {/* {participant.type !== 'authorizedAdult' &&
          participant.type !== 'guardian' && (
            <>
              <h4>
                Who {type == 'Check-out' ? 'is' : 'will'}{' '}
                {type == 'Check-in'
                  ? 'Pick up'
                  : type == 'Check-out'
                  ? 'picking up'
                  : type}
                ?
              </h4>
              <div className="flex-center mt-20px">
                <Checkbox
                  onChange={onChange}
                  name="guardian"
                  checked={selectedValue === 'guardian'}
                  className="cutom-checkbox"
                >
                  Guardian
                </Checkbox>
                <Checkbox
                  onChange={onChange}
                  name="adults"
                  checked={selectedValue === 'adults'}
                  className="cutom-checkbox"
                >
                  Authorized Adults
                </Checkbox>
                <Checkbox
                  onChange={onChange}
                  name="someone"
                  checked={selectedValue === 'someone'}
                  className="cutom-checkbox"
                >
                  Someone else
                </Checkbox>
              </div>
            </>
          )} */}
        {allMembers?.length < 1 && selectedValue !== 'someone' ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <Form form={form} onFinish={onFinish}>
            {selectedValue !== 'someone' ? (
              <div className="mt-20px">
                {participant.participantType !== 'dropInAttendee' &&
                  participant.role !== 'dropInAttendee' && (
                    <>
                      <label htmlFor="">
                        {selectedValue === 'guardian'
                          ? 'Guardian'
                          : 'Authorized adults'}
                      </label>

                      <Form.Item
                        name={
                          selectedValue === 'guardian'
                            ? 'pickUpGuardianId'
                            : selectedValue === 'adults'
                            ? 'pickUpAdultId'
                            : ''
                        }
                        rules={[
                          {
                            required: true,
                            message: `Please select ${selectedValue}`,
                          },
                        ]}
                        className="pointer selector-input"
                      >
                        <Select
                          options={memberList}
                          disabled={memberList === undefined && false}
                          className="select-input-field mt-10 cursor-pointer select-member"
                        />
                      </Form.Item>
                    </>
                  )}
              </div>
            ) : (
              <div>
                <label className="mt-20px">Name</label>
                <Form.Item name="pickUpPersonName">
                  <Input className="my-input mt-10px " />
                </Form.Item>

                <label className="mt-20px">Phone Number</label>
                <Form.Item
                  className="customPhoneCodeCheckIn mt-10px"
                  name="pickUpPersonPhoneNumber"
                  required
                  rules={[{ validator: validatePhoneNumber }]}
                >
                  <PhoneInput
                    country={'us'}
                    value={phone}
                    onChange={(value, data: any, event, formattedValue) => {
                      onHandlePhone({
                        dialCode: data?.dialCode,
                        isoCode: data.countryCode,
                        number: value,
                      })
                    }}
                    inputProps={{ required: true }}
                  />
                </Form.Item>

                <label className="mt-20px">Upload a photo</label>
                <Form.Item name="photoId">
                  <>
                    <Upload
                      className="mt-10px fullWidthUpload"
                      action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={onChangefile}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: visible => setPreviewOpen(visible),
                        }}
                        src={previewImage}
                      />
                    )}
                  </>
                  {/* <ImgCrop rotationSlider>
                    <Upload
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChangefile}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && '+ Upload'}
                    </Upload>
                  </ImgCrop> */}
                </Form.Item>
              </div>
            )}
            {/* <label className="mt-20px">Make a photo</label>
          <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
          <Form.Item name="photoId">
            <FileUploader setAvatar={setAvatar} name="guardian" form={form} />
          </Form.Item> */}

            <label className="mt-20px">Notes</label>

            <Form.Item name="checkInNotes">
              <Input className="notes-inp mt-10px" />
            </Form.Item>
            <div className="flex justify-end">
              <button className="form-btn pointer">
                {mutation.isLoading ? <Loader /> : type}
              </button>
            </div>
          </Form>
        )}
      </div>
      {type == 'Check-out' && checkoutModel ? (
        <Checkout
          open={checkoutModel}
          setOpen={setCheckoutModel}
          participant={participant}
          body={formData}
          closePrev={closeRefetch}
        />
      ) : (
        <></>
      )}
      <ParticpantInfo
        type={type}
        participant={participant}
        familyData={familyData}
        ticketData={ticketsData}
      />
    </div>
  )
}
const mapStateToProps = (state: any) => ({
  fileData: state.fileUpload.fileData,
  loading: state.fileUpload.loading,
})
export default connect(mapStateToProps)(CheckInOutParticpant)
