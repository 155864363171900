import React from 'react'
import { Button } from 'antd'
import iconRecurring from '../../../../assets/Images/recurring-icon.svg'
import iconOnetime from '../../../../assets/Images/onetime-icon.svg'
import iconFlexible from '../../../../assets/Images/flxible-icon.svg'
import { convertToLocal } from 'helper/utility'
import { MentorRequestResp } from 'pages/JobRequest/types'
import {
  daysOfWeek,
  convertMinutesToLocalTime,
  capitalizeFirstLetter,
} from 'helper/utility'
import moment from 'moment'

interface TimeAndFrequency {
  sessionType: string
  data: MentorRequestResp
}

const TimeAndFrequency: React.FC<any> = ({ sessionType, data }) => {
  const {
    oneTimeSessionSchedule,
    flexibleSessionSchedule,
    recurringSessionSchedule,
    startDate,
    endDate,
  } = data || {}

  const { startTime, endTime } = oneTimeSessionSchedule || {}
  const {
    startDate: flexibleStartDate,
    endDate: flexibleEndDate,
    restrictions,
  } = flexibleSessionSchedule || {}
  const { date: recurringDate, ...recurringRest } =
    recurringSessionSchedule || {}

  // recurring days formatting
  const formatTimeRange = (start: number, end: number): string => {
    const startTime = convertMinutesToLocalTime(start)
    const endTime = convertMinutesToLocalTime(end)
    return `${startTime} - ${endTime}`
  }

  const groupByTime = (schedule: any) => {
    if (!schedule) {
      return {}
    }

    const groupedSchedule: any = {}
    schedule.forEach((item: any) => {
      const key = formatTimeRange(item.slots[0].start, item.slots[0].end)
      if (!groupedSchedule[key]) {
        groupedSchedule[key] = { days: new Set(), items: [] }
      }
      groupedSchedule[key].days.add(moment(item.date).format('ddd'))
      groupedSchedule[key].items.push(item)
    })
    Object.keys(groupedSchedule).forEach(key => {
      groupedSchedule[key].days = Array.from(groupedSchedule[key].days).sort()
    })
    return groupedSchedule
  }

  const groupedSchedule = groupByTime(recurringSessionSchedule || [])

  return (
    <>
      <div className="d-flex justify-between align-center">
        <h1 className="text-20 w-600 line-0">Time and frequency</h1>
        <Button className="nav-btn br-8 px-30 w-600">Edit</Button>
      </div>
      {sessionType === 'one-time' ? (
        <>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Frequency</h3>
            <div className="d-flex align-center">
              <img src={iconOnetime} alt="icon-time" />
              <p className="ml-5px">One time</p>
            </div>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Date</h3>
            <p className="text-14">{`${convertToLocal(startTime)}`}</p>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Time</h3>
            <p className="text-14">{`${convertToLocal(
              startTime,
              'hh:mm A',
            )} - ${convertToLocal(endTime, 'hh:mm A')}`}</p>
          </div>
        </>
      ) : sessionType === 'flexible' ? (
        <>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Frequency</h3>
            <div className="d-flex align-center">
              <img src={iconFlexible} alt="icon-time" />
              <p className="ml-5px">Flexible</p>
            </div>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Start date</h3>
            <p className="text-14">{convertToLocal(flexibleStartDate)}</p>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">End date</h3>
            <p className="text-14">{convertToLocal(flexibleEndDate)}</p>
          </div>
          <div className="d-flex flex-column mt-20px">
            <h3 className="text-14 w-600 line-0">Restrictions details</h3>
            <p className="text-14 mt-10px">{restrictions}</p>
          </div>
        </>
      ) : sessionType === 'recurring' ? (
        <>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Frequency</h3>
            <div className="d-flex align-center">
              <img src={iconRecurring} alt="icon-time" />
              <p className="ml-5px">Recurring</p>
            </div>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">Start date</h3>
            <p className="text-14">{convertToLocal(startDate)}</p>
          </div>
          <div className="d-flex justify-between align-center  mt-20px">
            <h3 className="text-14 w-600 line-0">End date</h3>
            <p className="text-14">{convertToLocal(endDate)}</p>
          </div>
          <div className="d-flex justify-between align-start  mt-20px">
            <h3 className="text-14 w-600 line-0">Time</h3>
            <p className="text-14">
              {Object.keys(groupedSchedule).map((timeRange, index) => (
                <div key={index}>
                  {`${groupedSchedule[timeRange].days.join(
                    ', ',
                  )}(${timeRange})`}
                </div>
              ))}
            </p>
          </div>
        </>
      ) : null}
    </>
  )
}

export default TimeAndFrequency
