const actions = {
  GET_RECENT_CHECK_IN: 'GET_RECENT_CHECK_IN',
  GET_EVENT_REQUEST: 'GET_EVENT_REQUEST',
  GET_EVENT_REQUEST_SUCCESS: 'GET_EVENT_REQUEST_SUCCESS',
  GET_EVENT_REQUEST_FAILURE: 'GET_EVENT_REQUEST_FAILURE',
  GET_MENTOR_EVENTS: 'GET_MENTOR_EVENTS',
  GET_MENTOR_EVENTS_SUCCESS: 'GET_MENTOR_EVENTS_SUCCESS',
  GET_MENTOR_EVENTS_FAILURE: 'GET_MENTOR_EVENTS_FAILURE',
  ADD_EVENTS_DATA: 'ADD_EVENTS_DATA',
  ADD_EVENTS_DATA_SUCCESS: 'ADD_EVENTS_DATA_SUCCESS',
  ADD_EVENTS_DATA_FAILURE: 'ADD_EVENTS_DATA_FAILURE',
  EVENTS_MODAL_VISIBLE: 'EVENTS_MODAL_VISIBLE',
  CURRENT_MENTOR_DATA: 'CURRENT_MENTOR_DATA',
  DELTE_MENTOR_DATA: 'DELETE_MENTOR_DATA',
  GET_EVENT_CATEGORIES: 'GET_EVENT_CATEGORIES',
  GET_EVENT_CATEGORIES_SUCCESS: 'GET_EVENT_CATEGORIES_SUCCESS',
  GET_EVENT_CATEGORIES_FAILURE: 'GET_EVENT_CATEGORIES_FAILURE',
  CURRENT_EVENT_STEPPER_STEP: 'CURRENT_EVENT_STEPPER_STEP',
  ADD_EVENTS_DATA__STEP_TWO_SUCCESS: 'ADD_EVENTS_DATA__STEP_TWO_SUCCESS',
  ADD_EVENTS_DATA__STEP_TWO_FAILURE: 'ADD_EVENTS_DATA__STEP_TWO_FAILURE',
  ADD_EVENTS_DATA__STEP_THREE_SUCCESS: 'ADD_EVENTS_DATA__STEP_THREE_SUCCESS',
  ADD_EVENTS_DATA__STEP_THREE_FAILURE: 'ADD_EVENTS_DATA__STEP_THREE_FAILURE',
  SET_ADD_ITEM_BRING_DATA: 'SET_ADD_ITEM_BRING_DATA',
  SET_ADD_FAQ_DATA: 'SET_ADD_FAQ_DATA',
  SET_ADD_REQ_DOCUMENT_DATA: 'SET_ADD_REQ_DOCUMENT_DATA',
  GET_EVENT_DETAILS_REQUEST: 'GET_EVENT_DETAILS_REQUEST',
  GET_EVENT_DETAILS_REQUEST_SUCCESS: 'GET_EVENT_DETAILS_REQUEST_SUCCESS',
  GET_EVENT_DETAILS_REQUEST_FAILURE: 'GET_EVENT_DETAILS_REQUEST_FAILURE',
  DISABLE_ADMISSION_TICKET: 'DISABLE_ADMISSION_TICKET',
  DISABLE_ADMISSION_TICKET_SUCCESS: 'DISABLE_ADMISSION_TICKET_SUCCESS',
  DISABLE_ADMISSION_TICKET_FAILURE: 'DISABLE_ADMISSION_TICKET_FAILURE',
  APPROVE_PARTICIPANTS: 'APPROVE_PARTICIPANTS',
  GET_EVENT_ORGANIZATION_REQUEST: 'GET_EVENT_ORGANIZATION_REQUEST',
  GET_EVENT_ORGANIZATION_REQUEST_SUCCESS:
    'GET_EVENT_ORGANIZATION_REQUEST_SUCCESS',
  GET_EVENT_ORGANIZATION_REQUEST_FAILURE:
    'GET_EVENT_ORGANIZATION_REQUEST_FAILURE',

  modalVisible: (payload = false) => ({
    type: actions.EVENTS_MODAL_VISIBLE,
    payload,
  }),

  getEventRequest: params => ({
    type: actions.GET_EVENT_REQUEST,
    params,
  }),

  getEventRequestSuccess: payload => ({
    type: actions.GET_EVENT_REQUEST_SUCCESS,
    payload,
  }),

  getEventRequestFailure: () => ({
    type: actions.GET_EVENT_REQUEST_FAILURE,
  }),

  getMentorEvents: params => ({
    type: actions.GET_MENTOR_EVENTS,
    params,
  }),

  getMentorEventsSuccess: payload => ({
    type: actions.GET_MENTOR_EVENTS_SUCCESS,
    payload,
  }),

  getMentorEventsFailure: () => ({
    type: actions.GET_MENTOR_EVENTS_FAILURE,
  }),

  getEventDetailRequest: payload => ({
    type: actions.GET_EVENT_DETAILS_REQUEST,
    payload,
  }),

  getEventDetailRequestSuccess: payload => ({
    type: actions.GET_EVENT_DETAILS_REQUEST_SUCCESS,
    payload,
  }),

  getEventDetailRequestFailure: () => ({
    type: actions.GET_EVENT_DETAILS_REQUEST_FAILURE,
  }),

  getEventOrganizationRequest: eventId => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST,
    eventId,
  }),

  getEventOrganizationRequestSuccess: payload => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST_SUCCESS,
    payload,
  }),

  getEventOrganizationRequestFailure: () => ({
    type: actions.GET_EVENT_ORGANIZATION_REQUEST_FAILURE,
  }),

  getEventCategoriesRequest: () => ({
    type: actions.GET_EVENT_CATEGORIES,
  }),

  getEventCategoriesSuccess: payload => ({
    type: actions.GET_EVENT_CATEGORIES_SUCCESS,
    payload,
  }),

  getEventCategoriesFailure: () => ({
    type: actions.GET_EVENT_CATEGORIES_FAILURE,
  }),

  updateCurrentEventStepperStep: payload => ({
    type: actions.CURRENT_EVENT_STEPPER_STEP,
    payload,
  }),

  createEvent: payload => ({
    type: actions.ADD_EVENTS_DATA,
    payload,
  }),

  createEventSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_SUCCESS,
    payload,
  }),

  createEventForStepTwo: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_TWO,
    payload,
  }),

  createEventForStepTwoSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA__STEP_TWO_SUCCESS,
    payload,
  }),

  createEventForStepTwoFailure: () => ({
    type: actions.ADD_EVENTS_DATA__STEP_TWO_FAILURE,
  }),

  createEventForStepThreeSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA__STEP_THREE_SUCCESS,
    payload,
  }),

  createEventForStepThreeFailure: () => ({
    type: actions.ADD_EVENTS_DATA__STEP_THREE_FAILURE,
  }),

  createEventForStepFive: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE,
    payload,
  }),

  createEventForStepFiveSuccess: payload => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE_SUCCESS,
    payload,
  }),

  createEventForStepFiveFailure: () => ({
    type: actions.ADD_EVENTS_DATA_STEP_FIVE_FAILURE,
  }),

  setAddItemBringData: payload => ({
    type: actions.SET_ADD_ITEM_BRING_DATA,
    payload,
  }),

  setAddFaqData: payload => ({
    type: actions.SET_ADD_FAQ_DATA,
    payload,
  }),

  setAddReqDocumentData: payload => ({
    type: actions.SET_ADD_REQ_DOCUMENT_DATA,
    payload,
  }),

  deleteScheduledEventActivity: payload => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY,
    payload,
  }),

  deleteScheduledEventActivitySuccess: payload => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY_SUCCESS,
    payload,
  }),

  deleteScheduledEventActivityFailure: () => ({
    type: actions.DELETE_SCHEDULED_EVENT_ACTIVITY_FAILURE,
  }),
  getapproveParticipants: () => ({
    type: actions.APPROVE_PARTICIPANTS,
  }),
  getRecentCheckin: payload => ({
    type: actions.GET_RECENT_CHECK_IN,
    payload: payload,
  }),
}

export default actions
