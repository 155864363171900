import React, { useEffect, useState } from 'react'
import { creationEventStore } from 'state/eventCreationStore'
import { Plus } from '@phosphor-icons/react'
import { Empty } from 'antd'
import SupplyModal from './Modals/SupplyModal'
import AlertModal from 'helper/AlertModal'
import './style/styles.scss'
import { List, Trash, PencilLine } from '@phosphor-icons/react'
import { CustomActivityResponse, InventoryResponse } from 'types/events'
import { useRemoveSupplyFromActivity } from 'api/EventCreation/CreationStep2'
import AddSupplyForm from './Modals/AddSupplyForm'

interface Step3Props {
  handleBack: () => void
  handleNext: () => void
}

const Step3: React.FC<Step3Props> = ({ handleBack, handleNext }) => {
  const customActivity = creationEventStore(store => store.customActivity)
  const setCustomActivity = creationEventStore(store => store.setCustomActivity)
  const [openSupplyModal, setOpenSupplyModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [supplyFormVisible, setSupplyformVisible] = useState<boolean>(false)
  const [supplyDetail, setSupplyDetail] = useState<InventoryResponse>()
  const activity = creationEventStore(store => store.customActivity)

  const alertModalData = {
    name: 'Supply',
    heading: 'Delete the supply',
    message1: `Are you sure you want to delete the supply?`,
  }

  const { mutate: remvoeSupplyMutate, isLoading: removeSupplyLoading } =
    useRemoveSupplyFromActivity()

  const handleDelete = (item: InventoryResponse) => {
    setShowAlert(true)
    setSupplyDetail(item)
  }

  const hanldeEdit = (item: InventoryResponse) => {
    setSupplyDetail(item)
    setSupplyformVisible(true)
  }

  const onYesClick = () => {
    remvoeSupplyMutate(
      { activityId: activity._id, supplyId: supplyDetail._id },
      {
        onSuccess: data => {
          setCustomActivity(data)
          setShowAlert(false)
        },
      },
    )
  }

  return (
    <>
      <div>
        <div>
          <h3 className="text-20px w-600">Supplies</h3>
        </div>
        <div
          className={`main-inventory-container ${
            customActivity.supplies.length === 0 &&
            'no-inventory-main-contianer'
          }`}
        >
          {customActivity.supplies.length === 0 ? (
            <div className="no-supplies-height flex flex-col align-center justify-center h-100p">
              <Empty />
              <h3>No supplies found</h3>
            </div>
          ) : (
            <div className="h-18rem mt-10px overflow-auto-y overflow-hidden-x scrooler">
              {customActivity.supplies.map((supply: InventoryResponse) => {
                return (
                  <div
                    key={supply._id}
                    className="flex align-center justify-between mt-10px"
                  >
                    <div className="flex gap-10px align-center">
                      <List size={24} color="#000" />
                      <p>{supply.name}</p>
                    </div>
                    <div className="flex gap-20px align-center">
                      <PencilLine
                        size={22}
                        color="#000"
                        className="pointer"
                        onClick={() => {
                          hanldeEdit(supply)
                        }}
                      />
                      <Trash
                        className="pointer"
                        size={22}
                        color="#FF6961"
                        onClick={() => handleDelete(supply)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <div
            className="flex justify-center mb-10px"
            onClick={() => setOpenSupplyModal(true)}
          >
            <button className="big-btn gap-10px">
              <Plus size={18} color="#2ba7ca" />
              Add Supply
            </button>
          </div>
        </div>
        <div className="mt-10px flex align-center justify-between">
          <button className="create-activity-btn" onClick={handleBack}>
            Back
          </button>
          <button className="confirm-btn" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      {showAlert && (
        <AlertModal
          isModalVisible={showAlert}
          setIsModalVisible={setShowAlert}
          data={alertModalData}
          onYesClick={onYesClick}
          loading={removeSupplyLoading}
        />
      )}
      {openSupplyModal && (
        <SupplyModal
          modalOpen={openSupplyModal}
          setModalOpen={setOpenSupplyModal}
        />
      )}
      {supplyFormVisible && (
        <AddSupplyForm
          modalOpen={supplyFormVisible}
          modalClose={setSupplyformVisible}
          data={supplyDetail}
        />
      )}
    </>
  )
}

export default Step3
