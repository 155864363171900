import React, { useEffect, useState } from 'react'
import { useGetTicketColors } from 'api/EventCreation/CreationStep4'
import ButtonSpinner from 'components/global/ButtonSpinner'
import { Check } from '@phosphor-icons/react'

interface TicketColorsProps {
  ticketColor: string
  setTicketColor: React.Dispatch<React.SetStateAction<string>>
}

const TicketColors: React.FC<TicketColorsProps> = ({
  ticketColor,
  setTicketColor,
}) => {
  const [defaultColor, setDefaultColor] = useState<string>()
  const { data, isLoading, isFetching, refetch } = useGetTicketColors()

  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (data) {
      if (!ticketColor) {
        const preselectedColor = data.find(item => item.isDefault)?.code
        setDefaultColor(preselectedColor)
        setTicketColor(preselectedColor)
      } else {
        setDefaultColor(ticketColor)
      }
    }
  }, [data, ticketColor])

  return (
    <>
      {isLoading || isFetching ? (
        <ButtonSpinner size={20} color="#2ba7ca" />
      ) : (
        <div className="flex flex-wrap align-center gap-10px mb-15px">
          {data.map(item => {
            return (
              <div
                key={item.code}
                className="flex align-center justify-center w-30px h-30px radius-rounded"
                style={{ background: `#${item.code}` }}
                onClick={() => setTicketColor(item.code)}
              >
                {item.code === defaultColor ? (
                  <Check size={16} color="#000" />
                ) : null}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default TicketColors
