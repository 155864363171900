import { Select } from "antd";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import actions from "../Redux/Relations/action";

const RelationsList = ({ data, relationsList, form, relationId }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: actions.GET_RELATIONS,
    });
  }, []);

  let filtredData = relationsList.filter((listItem: any) => {
    return listItem._id === relationId;
  });

  return (
    <div>
      <Select
        className="h-3 txt-position"
        placeholder={filtredData[0]?.name}
        onChange={(v, item: any) => {
          form.setFieldsValue({
            relationId: item.key,
          });
        }}
      >
        {relationsList.map((relation: any) => (
          <Select.Option key={relation._id} value={relation.name}>
            {relation.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  relationsList: state.relations.relationsList,
});
export default connect(mapStateToProps)(RelationsList);
