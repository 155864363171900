import { Row, Col, Form, DatePicker, TimePicker, Button, Space } from "antd";
import { useEffect, useState } from "react";
import plusCircle from "../../../../../assets/Images/PlusCircle.svg";
import deleteSvg from "../../../../../assets/Images/delete_red.svg";
import ClipboardCopy from "../../../../../helper/ClipboardCopy";
import moment from "moment";
interface TimerItem {
  day: string;
}
const RecurringSession = ({ option, setdata }: any) => {
  const [scheduleDays, setScheduleDays] = useState<any>([
    {
      day: "sunday",
      selected: false,
    },
    {
      day: "monday",
      selected: false,
    },
    {
      day: "tuesday",
      selected: false,
    },
    {
      day: "wednesday",
      selected: false,
    },
    {
      day: "thursday",
      selected: false,
    },
    {
      day: "friday",
      selected: false,
    },
    {
      day: "saturday",
      selected: false,
    },
  ]);
  const convertTimeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const [newTimer, setNewTimer] = useState<TimerItem[]>([]);

  const toggleDaySelected = (value: any) => {
    let updatedArray = scheduleDays.map((sch: any) => {
      if (sch.day === value.day) {
        return {
          day: value.day,
          selected: !value.selected,
        };
      } else {
        return sch;
      }
    });
    setScheduleDays(updatedArray);
  };
  const addTime = (day: string) => {
    setNewTimer((s): any => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };

  const removeTimer = (index: any) => {
    let dataInput = newTimer.splice(1, 1);
    setNewTimer(dataInput);
  };

  const [recurringSessionSchedule, setrecurringSessionSchedule] = useState<any>(
    {
      startDate: null,
      endDate: null,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    }
  );

  const handleDateChange = (date: any, dateField: any) => {
    const formattedDate = moment(date).toISOString();

    if (dateField === "recurringStartDate") {
      setrecurringSessionSchedule((prevDates: any) => ({
        ...prevDates,
        startDate: formattedDate,
      }));
    } else if (dateField === "recurringEndDate") {
      setrecurringSessionSchedule((prevDates: any) => ({
        ...prevDates,
        endDate: formattedDate,
      }));
    }
  };
  const [startTimeValue, setStartTimeValue] = useState<any>();
  const [endTimeValue, setEndTimeValue] = useState<any>();
  const [day, setDay] = useState("monday");

  const handleAddTime = (
    day:
      | "monday"
      | "tuesday"
      | "wednesday"
      | "thursday"
      | "friday"
      | "saturday"
      | "sunday",
    timeString: string,
    type: string
  ) => {
    if (type === "start") {
      setStartTimeValue(convertTimeToMinutes(timeString));
      setDay(day);
    }
    if (type === "end") {
      setEndTimeValue(convertTimeToMinutes(timeString));
      setDay(day);
    }
  };
  useEffect(() => {
    if (startTimeValue && endTimeValue) {
      setrecurringSessionSchedule((prevState: any) => {
        const newState = { ...prevState };
        newState?.[day].push({ start: startTimeValue, end: endTimeValue });
        return newState;
      });
      setStartTimeValue(null);
      setEndTimeValue(null);
      setdata(recurringSessionSchedule);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeValue, endTimeValue]);
  return (
    <div>
      {option === "recurring" && (
        <Row gutter={[10, 0]}>
          <Col>
            <label className="input-label">Date & Time</label>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  name="recurringStartDate"
                  rules={[
                    {
                      required: true,
                      message: "Start date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="Start Date"
                    onChange={(date) =>
                      handleDateChange(date, "recurringStartDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="recurringEndDate"
                  rules={[{ required: true, message: "End date is required" }]}
                >
                  <DatePicker
                    className="w-100 form-control-field"
                    placeholder="End Date"
                    onChange={(date) =>
                      handleDateChange(date, "recurringEndDate")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <label className="input-label">Schedule</label>
            <Space size={20} className="w-100 mb-20">
              {scheduleDays.map((value: any) => {
                return (
                  <Button
                    className={
                      value.selected ? "bg-dark text-white" : "bg-secondary"
                    }
                    size="large"
                    shape="circle"
                    onClick={() => {
                      toggleDaySelected(value);
                    }}
                  >
                    {value.day.charAt(0).toUpperCase()}
                  </Button>
                );
              })}
            </Space>
          </Col>
          <Col>
            {scheduleDays.map((sch: any) => {
              if (sch.selected === true) {
                return (
                  <Row gutter={[0, 20]} className="mb-20">
                    <Col span={18}>
                      <Row>
                        <Col span={12}>
                          <h5 className="b1">
                            {sch.day.charAt(0).toUpperCase() + sch.day.slice(1)}
                          </h5>{" "}
                        </Col>
                        <Col span={4} offset={8}>
                          <Space size={20}>
                            <img
                              src={plusCircle}
                              alt="add timing"
                              className="pointer"
                              onClick={() => addTime(sch.day)}
                            ></img>
                            <ClipboardCopy
                              copyText={moment
                                .utc()
                                .startOf("day")
                                .add("8am", "minutes")
                                .format("hh:mm A")}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Col>

                    {newTimer.map((item: any, i, index: any) => {
                      return (
                        <Col span={22} key={item?.key}>
                          <Row gutter={15}>
                            <Col span={8}>
                              <TimePicker
                                className="w-100 form-control-field"
                                placeholder="Start Time"
                                use12Hours={true}
                                onChange={(time, timeString) => {
                                  handleAddTime(sch.day, timeString, "start");
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              <TimePicker
                                className="w-100 form-control-field"
                                placeholder="End Time"
                                use12Hours={true}
                                onChange={(time, timeString) => {
                                  handleAddTime(sch.day, timeString, "end");
                                }}
                              />
                            </Col>
                            <Col span={6} className="align-self-center">
                              <img
                                className="pointer"
                                src={deleteSvg}
                                onClick={() => removeTimer(item.key)}
                                style={{ width: "20px" }}
                                alt="delete"
                              ></img>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </Row>
                );
              }
            })}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default RecurringSession;
