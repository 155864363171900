import { Button, Modal } from 'antd'
import Cancel from '../../../assets/Images/close.svg'
import { useState } from 'react'
import GuardiansModal from './Guardian/GuardiansModal'
import FamilyModal from '../../../pages/Modals/FamilyModal/FamilyModal'
import AdultsModal from '../../../pages/Modals/FamilyModal/Adults/AdultsModal'
import Kid from './Kid'
import Adult from './Adults'

interface addMemberInterface {
  open: boolean
  setOpen: (arg: boolean) => void
  familyId: string
}
const AddFamilyMembers = ({ open, setOpen, familyId }: addMemberInterface) => {
  const [guardiansModalVisible, setGuardiansModalVisible] = useState(false)
  const [adultsModalVisible, setAdultsModalVisible] = useState(false)
  const [kidModalVisible, setkidModalVisible] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        width={600}
        className="popup-modal"
      >
        <div className="w-full">
          <div className="popup-header">
            <div></div>
            <img
              src={Cancel}
              alt="close button pointer"
              onClick={handleCancel}
            />
          </div>

          <div className="flex flex-col justify-center align-center pb-40px">
            <h3 className="mb-25 mt-10">
              <b className="b1 Montserrat">
                Who would you like to add to the family?
              </b>
            </h3>
            <Button
              className="large_add_button w-325 w-500"
              onClick={() => {
                setGuardiansModalVisible(true)
              }}
            >
              Guardians
            </Button>
            <Button
              className="large_add_button w-325 mt-20px w-500"
              onClick={() => {
                setkidModalVisible(true)
              }}
            >
              Child
            </Button>

            <Button
              className="large_add_button w-325 mt-20px w-500"
              onClick={() => {
                setAdultsModalVisible(true)
              }}
            >
              Authorized Adults
            </Button>
          </div>
        </div>
      </Modal>
      {guardiansModalVisible && (
        <GuardiansModal
          familyId={familyId}
          isModalVisible={guardiansModalVisible}
          setModalVisible={setGuardiansModalVisible}
        />
      )}
      {kidModalVisible && (
        // <FamilyModal
        //   familyId={familyId}
        //   isModalVisible={kidModalVisible}
        //   setModalVisible={setkidModalVisible}
        // />
        <Kid
          isModalVisible={kidModalVisible}
          setModalVisible={setkidModalVisible}
          familyId={familyId}
        />
      )}
      {adultsModalVisible && (
        <AdultsModal
          isModalVisible={adultsModalVisible}
          setModalVisible={setAdultsModalVisible}
          familyId={familyId}
        />
        // <Adult familyId={familyId} />
      )}
    </div>
  )
}
export default AddFamilyMembers
