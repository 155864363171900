import { Avatar, Col, Row, Select } from 'antd'
import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { useHistory } from 'react-router'
import { Option } from 'antd/lib/mentions'

import { useQuery } from '@tanstack/react-query'
import { getFamilyHistory } from '../../../../../api/family'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './style.scss'
import Spinner from '../../../../../components/Spinner'
import { RequestParms } from '../../../../../types/globalTypes'
import { Pagination } from 'antd'

const History = ({ familyId }: any) => {
  const [parms, setParms] = useState<RequestParms>({
    page: 1,
    limit: 10,
  })
  let initPaginationInfo: any = {
    limit: 10,
    page: 1,
    sort: {
      field: 'organizationName',
      order: 'asc',
    },
    showSizeChanger: true,
    pageSizeOptions: ['10', '20'],
  }
  const [paginationInfo, setPaginationInfo] = useState(initPaginationInfo)
  const history = useHistory()

  const { isLoading, data } = useQuery(['getNotes', familyId, parms], () =>
    getFamilyHistory(familyId, parms),
  )
  const match = (text: any) => {
    const data = text.startsWith('**[v](family)')
    return data
  }

  const renderData = (
    img: any,
    firstName: any,
    lastName: any,
    type: string,
    id: string,
  ) => {
    return (
      <div
        className="flex align-center pointer"
        onClick={() => {
          history.push(`/${type}/${id}`)
        }}
      >
        {img ? (
          <div className="mr-20">
            <Avatar size={45} src={img}>
              M
            </Avatar>
          </div>
        ) : (
          <></>
        )}
        <span>
          {firstName} {lastName}
        </span>
      </div>
    )
  }
  const handleSearchChange = (e: any) => {
    // setParms({ ...parms, searchQuery: e });
  }
  const debounced = debounce(handleSearchChange, 300)
  const PaginationChanger = (pageNumber: number, pageSize: number) => {
    setPaginationInfo({
      ...paginationInfo,
      page: pageNumber,
      limit: pageSize,
    })
    setParms({ ...parms, page: pageNumber, limit: pageSize })
  }
  const handleChangeSortName = (value: any) => {
    setParms({ ...parms, sort: value })
  }
  return (
    <Row gutter={10} className="bg-grey container padding-top-10">
      <Col span={24}>
        <div className="profile_container">
          <h2 className="b1 h6">History</h2>
          <div className="mt-30px w-50p flex">
            <div className="search-input-box">
              <input
                type="search"
                className="search-input-group"
                placeholder="Search"
                onChange={e => {
                  e.preventDefault()
                  debounced(e.target.value)
                }}
              />
              <SearchOutlined />
            </div>
            <Select
              className="custom-select-field"
              defaultValue="firstname"
              placeholder="sort by"
              allowClear
              onChange={handleChangeSortName}
            >
              <Option value="email">Sort by: Email</Option>
              <Option value="-createdat">Sort by: Created at</Option>
            </Select>
          </div>
          {isLoading ? (
            <div className="flex justify-center align-center mt-30">
              <Spinner />
            </div>
          ) : (
            <div>
              {data?.records.map((post: any, index: any) => {
                return (
                  <div className="history mt-20px">
                    <ReactMarkdown
                      children={post.text}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        a: ({ href }: { children: any; href?: string }) => {
                          return (
                            <div>
                              {href === 'authrizedAdult' ? (
                                renderData(
                                  post?.props?.authrizedAdult?.v?.photo?.post,
                                  post?.props?.authrizedAdult?.v?.firstName,
                                  post?.props?.authrizedAdult?.v?.lastName,
                                  'kid-profile',
                                  post?.props?.authrizedAdult?.v?.id,
                                )
                              ) : href === 'family' ? (
                                match(post.text) ? (
                                  renderData(
                                    post?.props?.family?.v?.photo?.post,
                                    post?.props?.family?.v?.name,
                                    '',
                                    'family-profile',
                                    post?.props?.family?.v?.id,
                                  )
                                ) : (
                                  renderData(
                                    '',
                                    post?.props?.family?.v?.name,
                                    '',
                                    'family-profile',
                                    post?.props?.family?.v?.id,
                                  )
                                )
                              ) : href === 'guardian' ? (
                                renderData(
                                  '',
                                  post?.props?.guardian?.v?.firstName,
                                  post?.props?.guardian?.v?.lastName,
                                  'parent-profile',
                                  post?.props?.guardian?.v?.id,
                                )
                              ) : href === 'relation' ? (
                                post?.props?.relation?.v ? (
                                  renderData(
                                    post?.props?.family?.v?.photo?.post,
                                    post?.props?.authrizedAdult?.v?.firstName,
                                    '',
                                    '',
                                    '',
                                  )
                                ) : (
                                  <h4 className="ml-10">relation</h4>
                                )
                              ) : href === 'kid' ? (
                                renderData(
                                  post?.props?.kid?.v?.photo
                                    ? post?.props?.kid?.v?.photo?.post
                                    : 'dummy',
                                  post?.props?.kid?.v?.firstName,
                                  post?.props?.kid?.v?.lastName,
                                  'kid-profile',
                                  post?.props?.kid?.v?.id,
                                )
                              ) : href === 'admin' ? (
                                renderData(
                                  post?.props?.family?.v?.photo?.post,
                                  'admin',
                                  '',
                                  '',
                                  '',
                                )
                              ) : href === 'organization' ? (
                                renderData(
                                  '',
                                  post?.props?.organization?.v?.name,
                                  '',
                                  'organization-profile',
                                  post?.props?.organization?.v?.id,
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          )
                        },
                      }}
                    />
                  </div>
                )
              })}
              <div className="mt-40px flex justify-center align-center align-center">
                <Pagination
                  defaultCurrent={paginationInfo.page}
                  total={data?.count}
                  onChange={PaginationChanger}
                />
              </div>
            </div>
          )}
          {}
        </div>
      </Col>
    </Row>
  )
}
export default History
