import React, { useEffect, useState } from 'react'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { GetFamily, getKidAuthorizedAdults, getRecentCheckIn } from 'api/family'
import GetAvatar from 'components/global/GetAvatar'
import Spinner from 'components/Spinner'
import { getUserName } from 'helper/utility'
import {
  Attendees,
  PurchasedAdditionalTicket,
  SingleFamily,
  TicketOrder,
} from 'pages/Events/types'
import { Person } from 'types/globalTypes'
import arrow from 'assets/Images/ticket-arrow.png'
import ticketIco from 'assets/Images/admTicket.svg'
import childIcon from 'assets/Images/childIcon.png'
import PhoneIcon from 'assets/Images/PhoneIcon.png'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { convertToUtc } from 'helper/utility'
import { useDispatch } from 'react-redux'
import actions from 'Redux/Events/action'
interface ParticipantInfoProps {
  type: 'Check-in' | 'Check-out' | 'drop-out' | 'approve' | 'Drop-in'
  participant: Attendees
  familyData: {
    isLoading: boolean
    data: SingleFamily | undefined
  }
  ticketData: {
    isLoading: boolean
    data: TicketOrder | undefined
  }
}

const ParticipantInfo: React.FC<ParticipantInfoProps> = ({
  type,
  participant,
  familyData,
  ticketData,
}) => {
  const [showItem, setShowItem] = useState(0)

  const currentDate = moment().startOf('day')
  const [selectedDate, setSelectedDate] = useState<Date | any>(currentDate)
  const { id }: any = useParams()

  const parms = {
    memberId: participant?.id,
    date: convertToUtc(selectedDate),
    eventId: id,
  }
  const downIcon = (
    <CaretDown
      color="#2BA7CA"
      style={{
        position: 'relative',
        top: '4px',
        marginLeft: '8px',
      }}
    />
  )
  const upIcon = (
    <CaretUp
      color="#2BA7CA"
      style={{
        position: 'relative',
        top: '4px',
        marginLeft: '8px',
      }}
    />
  )
  const { data: adults } = useQuery(['getKidAuthorizeAdult'], () =>
    getKidAuthorizedAdults(participant._id),
  )

  // const { data: adult } = useQuery(['getRecentCheckIn'], () =>
  //   getRecentCheckIn(parms),
  // )
  const recentCheckIn = useQuery({
    queryKey: ['jol;jj'],
    queryFn: () => getRecentCheckIn(parms),
  })

  const renderPerson = (index: number, guardian: Person) => (
    <div
      key={index}
      className="flex-center mb-20px"
      style={{ marginTop: index > 0 ? '10px' : '20px' }}
    >
      <GetAvatar person={guardian} />
      <h4 className="text-14px ml-10px">{getUserName(guardian)}</h4>
    </div>
  )
  const renderPersonAdult = (index: number, adult: Person) => (
    <div
      key={index}
      className="flex-center mb-20px"
      style={{ marginTop: index > 0 ? '10px' : '20px' }}
    >
      <GetAvatar person={adult} />
      <h4 className="text-14px ml-10px">{getUserName(adult)}</h4>
    </div>
  )

  return (
    <div className="grey px-20px pt-30px radius-8 ">
      <div className="bg-white radius-8 p-15px particpant-details">
        <h2 className="text-16 w-600">Participant details</h2>
        <div className="flex-center mt-20px">
          <GetAvatar person={participant} />
          <h3 className="text-14px w-500 ml-10px">
            {getUserName(participant)}
          </h3>
        </div>
        {participant.participantType !== 'dropInAttendee' &&
        participant.role !== 'dropInAttendee' ? (
          <h3
            className="prim-txt mt-10px text-underline w-500 text-16 pointer"
            onClick={() =>
              setShowItem(showItem === 0 || showItem === 2 ? 1 : 0)
            }
          >
            Show Guardians
            {showItem === 0 ? downIcon : showItem === 1 ? upIcon : downIcon}
          </h3>
        ) : (
          <div className="greytxt mt-10px text-underline w-500 text-16 pointer">
            {participant.role == 'dropInAttendee'
              ? participant.role
              : participant?.type}
          </div>
        )}

        {showItem === 1 && (
          <>
            {familyData.isLoading ? (
              <Spinner />
            ) : (
              <>
                {familyData.data &&
                  familyData.data.guardians.map((guardian, index) =>
                    renderPerson(index, guardian),
                  )}
              </>
            )}
          </>
        )}
        {participant.type !== 'authorizedAdult' &&
          participant.type !== 'guardian' &&
          participant.participantType !== 'dropInAttendee' &&
          participant.role !== 'dropInAttendee' && (
            <>
              <h3
                className="prim-txt mt-10px text-underline w-500 text-16 pointer mt-10"
                onClick={() =>
                  setShowItem(showItem === 0 || showItem === 1 ? 2 : 0)
                }
              >
                Show Authorized Adults
                {showItem === 0 ? downIcon : showItem === 2 ? upIcon : downIcon}
              </h3>
              {showItem === 2 && (
                <>
                  {familyData.isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      {(adults ?? []).length === 0 ? (
                        <div>No authorized adults assigned</div>
                      ) : (
                        <div>
                          {adults.map((adult: Person, index: number) =>
                            renderPerson(index, adult),
                          )}
                        </div>
                      )}
                      {/* {adults !== null
                        ? renderPerson(0, adults)
                        : 'No authorized adults assigned to this kid'} */}
                      {/* {adults &&
                        adults?.map((adult: Person, index: number) =>
                          renderPerson(index, adult),
                        )} */}

                      {/* {adults &&
                        adults.data((adult: Person, index: number) =>
                          renderPerson(index, adult),
                        )} */}
                    </>
                  )}
                </>
              )}
            </>
          )}
      </div>
      <div className="bg-white radius-8 p-15px mt-20px">
        <h2 className="text-16 w-600">Ticket details</h2>
        {ticketData.isLoading ? (
          <Spinner />
        ) : (
          // <div>
          //   {participant.type != 'dropInAttendee' && (
          //     <div>
          //       <div className="border-btm py-20px">
          //         <div className="flex-center justify-between">
          //           <div className="flex-center ">
          //             <img src={ticketIco} alt="ticket" />
          //             <h2 className="text-14 w-600 ml-10px">
          //               {ticketData?.data?.ticket.name}
          //             </h2>
          //           </div>

          //           <div className="flex-center">
          //             <h3 className="text-12 w-500 mr-10px">
          //               ${ticketData?.data?.ticket.price}
          //             </h3>
          //           </div>
          //         </div>
          //         {ticketData?.data?.ticket.additionalTicket &&
          //           ticketData?.data?.ticket.additionalTicket.map(
          //             (
          //               additionalTicket: PurchasedAdditionalTicket,
          //               index: number,
          //             ) => {
          //               return (
          //                 <div
          //                   className="flex-center justify-between mt-10px"
          //                   key={index}
          //                 >
          //                   <div className="flex-center">
          //                     <img src={arrow} alt="ticket" />
          //                     <h2 className="text-14 w-600 ml-10px">
          //                       {additionalTicket.name}
          //                     </h2>
          //                   </div>
          //                   <div className="flex-center">
          //                     <h3 className="text-12 w-500 mr-10px">
          //                       ${additionalTicket.price}
          //                     </h3>
          //                   </div>
          //                 </div>
          //               )
          //             },
          //           )}
          //       </div>
          //       <div className="flex-center justify-between py-15px">
          //         <h2 className="text-16">Total Cost</h2>
          //         <h4 className="w-700 text-18">
          //           ${ticketData?.data?.totalCost}
          //         </h4>
          //       </div>
          //     </div>
          //   ): (
          //     <div>hello</div>
          //   )}
          // </div>
          <div>
            <div>
              {participant?.type !== 'dropInAttendee' &&
              participant?.isType !== 'dropIn' ? (
                <div>
                  <div className="border-btm py-20px">
                    <div className="flex-center justify-between">
                      <div className="flex-center ">
                        <img src={ticketIco} alt="ticket" />
                        <h2 className="text-14 w-600 ml-10px">
                          {ticketData?.data?.ticket.name}
                        </h2>
                      </div>

                      <div className="flex-center">
                        <h3 className="text-12 w-500 mr-10px">
                          ${ticketData?.data?.ticket.price}
                        </h3>
                      </div>
                    </div>
                    {ticketData?.data?.ticket.additionalTicket &&
                      ticketData?.data?.ticket.additionalTicket.map(
                        (
                          additionalTicket: PurchasedAdditionalTicket,
                          index: number,
                        ) => (
                          <div
                            className="flex-center justify-between mt-10px"
                            key={index}
                          >
                            <div className="flex-center">
                              <img src={arrow} alt="ticket" />
                              <h2 className="text-14 w-600 ml-10px">
                                {additionalTicket.name}
                              </h2>
                            </div>
                            <div className="flex-center">
                              <h3 className="text-12 w-500 mr-10px">
                                ${additionalTicket.price}
                              </h3>
                            </div>
                          </div>
                        ),
                      )}
                  </div>
                  <div className="flex-center justify-between py-15px">
                    <h2 className="text-16">Total Cost</h2>
                    <h4 className="w-700 text-18">
                      ${ticketData?.data?.totalCost}
                    </h4>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex-center justify-between py-15px">
                    <h2 className="text-16">
                      Drop in (per {ticketData?.data?.priceUnit})
                    </h2>
                    <h4 className="w-700 text-18">
                      ${ticketData?.data?.price}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {participant?.checkInStatus !== 'pending' &&
      participant?.type != 'dropInAttendee' &&
      participant.isType !== 'dropIn' ? (
        <div className="bg-white radius-8 p-15px mt-20px mb-10px">
          <h2 className="text-16 w-600">Who will pick up?</h2>
          {ticketData.isLoading ? (
            <Spinner></Spinner>
          ) : (
            <div className=" py-20px">
              <div className="flex-center justify-between">
                <div className="flex-center ">
                  <img src={childIcon} alt="child" />
                  <h2 className="text-14 w-600 ml-10px">
                    {recentCheckIn.isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {/* {recentCheckIn?.data.length !== 0
                          ? `${
                              recentCheckIn?.data?.[0]?.pickUpGuadian
                                ?.firstName ?? ''
                            } ${
                              recentCheckIn?.data?.[0]?.pickUpGuadian
                                ?.lastName ?? ''
                            }`
                          ? recentCheckIn?.data?.[0].pickUpGuadian == null ? `${
                              recentCheckIn?.data?.[0]?.checkInGuardian
                                ?.firstName ?? ''
                            } ${
                              recentCheckIn?.data?.[0]?.checkInGuardian
                                ?.lastName ?? ''
                            }`  : null} */}
                        {recentCheckIn?.data.length !== 0
                          ? recentCheckIn?.data?.[0]?.pickUpPersonName !== null
                            ? recentCheckIn?.data?.[0]?.pickUpPersonName
                            : recentCheckIn?.data?.[0]?.pickUpGuadian !== null
                            ? `${
                                recentCheckIn?.data?.[0]?.pickUpGuadian
                                  ?.firstName ?? ''
                              } ${
                                recentCheckIn?.data?.[0]?.pickUpGuadian
                                  ?.lastName ?? ''
                              }`
                            : `${
                                recentCheckIn?.data?.[0]?.checkInGuardian
                                  ?.firstName ?? ''
                              } ${
                                recentCheckIn?.data?.[0]?.checkInGuardian
                                  ?.lastName ?? ''
                              }`
                          : null}
                      </>
                    )}
                  </h2>
                </div>
              </div>
              <div className="flex-center justify-between py-15px">
                <div className="flex-center ">
                  <img src={PhoneIcon} alt="Phone" />
                  <h2 className="text-14 w-600 ml-10px">
                    {recentCheckIn.isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        {/* my previous code */}
                        {/* {recentCheckIn?.data.length !== 0
                          ? recentCheckIn?.data?.[0].pickUpGuadian !== null
                            ? `${recentCheckIn?.data?.[0]?.pickUpGuadian?.phone?.number}`
                            : recentCheckIn?.data?.[0]?.checkInGuardian !== null
                            ? `${recentCheckIn?.data?.[0]?.checkInGuardian?.phone?.number}`
                            : null
                          : null} */}
                        {recentCheckIn?.data.length !== 0
                          ? recentCheckIn?.data?.[0].pickUpGuadian !== null
                            ? `${recentCheckIn?.data?.[0]?.pickUpGuadian?.phone?.number}`
                            : recentCheckIn?.data?.[0].checkInGuardian !== null
                            ? `${recentCheckIn?.data?.[0]?.checkInGuardian?.phone?.number}`
                            : recentCheckIn?.data?.[0].pickUpPersonPhoneNumber
                                ?.number
                          : null}

                        {/* {recentCheckIn?.data.length !== 0
                          ? `${recentCheckIn?.data?.[0].pickUpGuadian?.phone?.number}`
                          : null} */}
                      </>
                    )}
                    {/* {`${recentCheckIn?.data?.[0].checkInOrganizer?.phone?.number}`} */}
                  </h2>
                </div>
              </div>
              {recentCheckIn?.data?.[0]?.checkInNotes ? (
                <>
                  <div className="flex-center justify-between py-15px">
                    <h2 className="text-16">Notes</h2>
                  </div>
                  <div className="flex-center justify-between">
                    <h2 className="text-14">
                      {recentCheckIn.isLoading ? (
                        <Spinner />
                      ) : (
                        <>{recentCheckIn?.data?.[0]?.checkInNotes}</>
                      )}
                    </h2>
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ParticipantInfo
