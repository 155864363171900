import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Input, Col, Avatar, Select } from 'antd'
import { Layout } from 'antd'
import userAvatarSvg from '../../../../assets/Images/userAvatar.svg'
import FileUploader from '../../../../helper/FileUploader'
import AllergyList from '../../../../helper/AllergyList'
import RelationsList from '../../../../helper/RelationsList'
import PhoneField from '../../../../helper/PhoneField'

const PersonalDetails = ({ handleNext, data }: any) => {
  const [form] = Form.useForm()
  const [avatar, setAvatar] = useState()
  const [allergies, setAllergies] = useState(data?.allergies || [])

  useEffect(() => {
    console.log('data28736:', data)
  }, [])

  const formSchema = {
    photoId: data?.photoId || '',
    relationId: data?.relation?._id || data?.relationId || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    phone: data?.phone || '',
    allergies: data?.allergies || '',
    medicalNotes: data?.medicalNotes || '',
  }
  const onFinish = (values: any) => {
    let allergyData = allergies.map((alergy: any) => {
      return {
        _id: alergy._id || alergy.key,
        name: alergy.name || alergy.value,
      }
    })

    handleNext({
      ...values,
      isEmergencyContact: true,
      allergies: allergyData,
    })
  }

  return (
    <Layout>
      <Form
        name="nest-messages"
        initialValues={formSchema}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        autoComplete="off"
      >
        <div className="modal-h-element">
          <Row gutter={24} align="middle">
            <Col span={4} className="mb-16">
              <Avatar size={80} src={avatar ? avatar : userAvatarSvg}></Avatar>
            </Col>
            <Col span={18} className="mb-16">
              <Form.Item name="photoId" rules={[{ required: true }]}>
                <FileUploader
                  setAvatar={setAvatar}
                  name="adult"
                  form={form}
                  fileId={data?.photoId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Relationship
              </label>
              <Form.Item
                className="custom-select-field"
                name="relationId"
                rules={[{ required: true }]}
              >
                <RelationsList
                  data={data?.relation}
                  relationId={formSchema.relationId}
                  form={form}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                FirstName
              </label>
              <Form.Item
                colon={false}
                name="firstName"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input placeholder="FirstName" required name="firstname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                Last Name
              </label>
              <Form.Item
                colon={false}
                name="lastName"
                rules={[{ required: true, message: 'Lastname is Required' }]}
              >
                <Input placeholder="Last Name" name="lastname" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label required-input">
                Email
              </label>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Please enter valid email' },
                ]}
              >
                <Input placeholder="example@gmail.com" name="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                Phone number
              </label>
              <Form.Item name="phone" required>
                <PhoneField form={form} data={data?.phone} />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={24}>
          <Col span={12}>
            <label htmlFor="" className="input-label required-input">
              Home address
            </label>
            <Form.Item  >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row> */}
          <Row>
            <Col span={12}>
              <label htmlFor="" className="input-label ">
                Allergies
              </label>
              <Form.Item
                name="allergies"
                className="form-control allergies-field"
              >
                <AllergyList
                  data={data?.allergies}
                  setAllergies={setAllergies}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <label htmlFor="" className="input-label">
                Medical Notes
              </label>
              <Form.Item name="medicalNotes">
                <Input placeholder="Ex.: Asthma, Lactose intolerant" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="stepperNextButton">
          <Button type="primary" className="stepper-button" htmlType="submit">
            Next
          </Button>
        </div>
      </Form>
    </Layout>
  )
}

export default PersonalDetails
