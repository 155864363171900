import { Button, Col, Form, Input, Layout, message, Row, Space } from 'antd'
import React, { useState } from 'react'
import checker from '../../../../assets/Images/checker.svg'
import active_checker from '../../../../assets/Images/active_checker.svg'
import rightArrowIcon from '../../../../assets/Images/rightarrowicon.svg'
import actions from '../../../../Redux/Organizer/action'
import { useDispatch } from 'react-redux'

const CreatePassword = ({
  handleNext,
  data,
  handleBack,
  isChangingPassword,
  currentOrganizer,
  onPasswordChange,
}: any) => {
  const dispatch = useDispatch()
  const [conditions, setConditions] = useState({
    password: '',
    passwordLength: false,
    containsNumbers: false,
    isUpperCase: false,
  })

  const handleChange = (e: any) => {
    let targetValue = e.target.value.replace(/\s/g, '')
    let matches = targetValue.match(/\d+/g)
    let isUpperCaseMatches = targetValue.match(/[A-Z]/)
    setConditions({
      ...conditions,
      password: targetValue,
      passwordLength: targetValue.length > 7 ? true : false,
      containsNumbers: matches != null ? true : false,
      isUpperCase: isUpperCaseMatches != null ? true : false,
    })
  }

  // const onFinish = (values: any) => {
  //   if (values.password2 !== values.password) {
  //     message.error('password is not matching')
  //   } else {
  //     if (isChangingPassword) {
  //       console.log('ischangingpassword', isChangingPassword, currentOrganizer)
  //       dispatch({
  //         type: actions.CHANGE_ORGANIZER_PASSWORD,
  //         payload: {
  //           password: values.password,
  //           firebaseId: currentOrganizer.firebaseId,
  //         },
  //       })
  //       dispatch({
  //         type: actions.CLOSE_ORGANIZER_CHANGE_PASSWORD,
  //       })
  //     } else {
  //       handleNext({
  //         ...data,
  //         password: values.password2,
  //       })
  //     }
  //   }
  // }
  const onFinish = (values: any) => {
    if (values.password2 !== values.password) {
      message.error('password is not matching')
    } else {
      if (isChangingPassword) {
        onPasswordChange(values.password)
      } else {
        handleNext({
          ...data,
          password: values.password2,
        })
      }
    }
  }
  return (
    <Layout>
      <div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <div className="modal-h-element">
            <Row gutter={24}>
              <Col span={24} className=" mb-0">
                <label htmlFor="" className="input-label required-input">
                  Password
                </label>
                <Form.Item
                  className="password-field"
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input.Password
                    onChange={e => {
                      handleChange(e)
                    }}
                    placeholder="New password"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} className="mb-0">
                <label htmlFor="" className="input-label required-input">
                  Confirm Password
                </label>
                <Form.Item
                  name="password2"
                  className="password-field"
                  rules={[
                    { required: true, message: 'Confirm password is required' },
                  ]}
                >
                  <Input.Password placeholder="Re-enter new password" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Space direction="vertical" className="password-radio-org">
                <Space>
                  {conditions.passwordLength ? (
                    <img alt="img" src={active_checker}></img>
                  ) : (
                    <img alt="img" src={checker}></img>
                  )}
                  8 characters
                </Space>
                <Space>
                  {conditions.containsNumbers ? (
                    <img alt="img" src={active_checker}></img>
                  ) : (
                    <img alt="img" src={checker}></img>
                  )}
                  1 number
                </Space>
                <Space>
                  {conditions.isUpperCase ? (
                    <img alt="img" src={active_checker}></img>
                  ) : (
                    <img alt="img" src={checker}></img>
                  )}
                  1 capital letter
                </Space>
              </Space>
            </Row>
          </div>
          <Row justify="space-between" align="middle">
            {!isChangingPassword && (
              <h5 className="stepperBackh5" onClick={() => handleBack(data)}>
                <span className="mr-10 d-inline-block">
                  <img src={rightArrowIcon} alt="icon right" />
                </span>
                Back
              </h5>
            )}
            <Button className="stepper-button" htmlType="submit">
              Save
            </Button>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}
export default CreatePassword
