import {combineReducers} from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from "connected-react-router";
import reducers from "./rootReducers";
import rootSaga from "./rootSagas";
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({...reducers, router: connectRouter(history)})
const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
    sagaMiddleware,
    routeMiddleware
  ],
})
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export {store, history}
export default store
