import React, { useState } from 'react'
import { Modal } from 'antd'
import { Layout } from 'antd'
import { Steps, message } from 'antd'
import Basicinfo from './Screens/Basicinfo'
import Skills from './Screens/Skills'
const { Step } = Steps

const MentorsModel = ({
  modalVisible,
  closeModal,
  refetchData,
  editdata,
  removeData,
}: any) => {
  const [current, setCurrent] = React.useState(0)
  const [data, setData] = useState()

  const next = (childData: any) => {
    setData(childData)
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }
  const handleOk = () => {
    closeModal(false)
    removeData(undefined)
  }
  const handleCancel = () => {
    closeModal(false)
    removeData(undefined)
  }
  const steps = [
    {
      title: 'Personel details',
      content: <Basicinfo data={editdata} />,
    },
    {
      title: 'Skills',
      content: <Skills data={editdata} />,
    },
  ]
  return (
    <Layout>
      <Modal
        className="organization_model"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        footer={null}
        width={600}
      >
        <div className="model-header">
          {editdata !== undefined ? (
            <h4> Edit Mentor </h4>
          ) : (
            <h4> New Mentor </h4>
          )}
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} className="stepper" title={item.title} />
            ))}
          </Steps>
        </div>
        <div>
          {current === 0 && <Basicinfo data={editdata} handleNext={next} />}
          {current === 1 && (
            <Skills
              childData={data}
              editData={editdata}
              handleNext={next}
              handleBack={prev}
              close={closeModal}
              refetc={refetchData}
            />
          )}
        </div>
      </Modal>
    </Layout>
  )
}
export default MentorsModel
