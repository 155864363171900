import { Avatar, Card, Col, Progress, Row, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import Text from "antd/lib/typography/Text";
import { useState } from "react";
import starSvg from "../../../../assets/Images/Star.svg";
import smallstarSvg from "../../../../assets/Images/Star9.svg";
const Reviews = () => {
  const [profile] = useState([
    {
      name: "Hugh Jackman",
      star: 3,
      date: "19 Dec 2020",
    },
    {
      name: "Hugh Jackman",
      star: 2,
      date: "19 Dec 2020",
    },
    {
      name: "Hugh Jackman",
      star: 4,
      date: "19 Dec 2020",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
    },
    {
      name: "Hugh Jackman",
      star: 5,
      date: "19 Dec 2020",
    },
  ]);
  const [innerStar] = useState(5);
  return (
    <div className="container padding-top-40">
      <Row gutter={24}>
        <Col span={12}>
          {profile.map((profileItem: any) => {
            return (
              <Card className="review-card">
                <Row justify="space-between">
                  <Meta
                    avatar={
                      <Avatar
                        size={36}
                        src="https://joeschmoe.io/api/v1/random"
                      />
                    }
                    title={<b className="b1">{profileItem.name}</b>}
                    description={[...Array(profileItem.star)].map((e, i) => (
                      <img alt="" src={starSvg}></img>
                    ))}
                  />
                  <Text type="secondary">{profileItem.date}</Text>
                </Row>
              </Card>
            );
          })}
        </Col>
        <Col span={12}>
          <Row>
            <Col xs={2} sm={4} md={6} lg={8} xl={10} >
              <Space direction="vertical" size={1}>
                <h1 style={{ fontSize: "40px", marginBottom: "0px" }}>
                  <b className="b1">4.8</b>
                </h1>
                <h5 style={{ marginTop: "opx" }}>Out of 5</h5>
              </Space>
            </Col>
            <Col xs={2} sm={4} md={6} lg={8} xl={10}>
            <div>
              {[...Array(5)].map((e, i) => (
                <Space direction="vertical" size={1}>
                  {[...Array(innerStar - i)].map((e, i) => (
                    <img alt="" src={smallstarSvg}></img>
                  ))}
                </Space>
              ))}
            </div>
            </Col>
            <div style={{ width: 170 }}>
              <Space direction="vertical" size={1} style={{ width: "100%" }}>
                <Progress
                  size="small"
                  strokeWidth={3}
                  strokeColor="#F2C94C"
                  percent={70}
                  format={(percent) => <></>}
                />
                <Progress
                  size="small"
                  strokeWidth={3}
                  strokeColor="#F2C94C"
                  percent={50}
                  format={(percent) => <></>}
                ></Progress>
                <Progress
                  size="small"
                  strokeWidth={3}
                  strokeColor="#F2C94C"
                  percent={30}
                  format={(percent) => <></>}
                ></Progress>
                <Progress
                  size="small"
                  strokeWidth={3}
                  strokeColor="#F2C94C"
                  percent={20}
                  format={(percent) => <></>}
                ></Progress>
                <Progress
                  size="small"
                  strokeWidth={3}
                  strokeColor="#F2C94C"
                  percent={10}
                  format={(percent) => <></>}
                ></Progress>
              </Space>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Reviews;