import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import ProfileHeader from 'components/Header/ProfileHeader'

const AdultProfileHeader = ({ data, refetch }: any) => {
  const [edit, setShowEdit] = useState(false)

  const changeMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      // return await updateGuardianProfile(params, payload);
    },
  })
  const deletMutation = useMutation({
    mutationFn: async ({ params, payload }: any) => {
      // return await updateGuardianProfile(params, payload);
    },
  })

  return (
    <div>
      <ProfileHeader
        data={data}
        refetch={refetch}
        name={'authorized-adult'}
        updateStatus={changeMutation}
        deleteMutation={deletMutation}
        showEdit={setShowEdit}
      />
      {edit && <h1>as</h1>}
    </div>
  )
}

export default AdultProfileHeader
