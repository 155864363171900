import { Avatar } from 'antd'
import { useState } from 'react'
import { NotesType } from '../types'
import { getNameSignature } from 'helper/utility'
import moment from 'moment'
import { capitalizeFirstLetter } from 'helper/utility'
interface NotesRecpPropsType {
  recepData: NotesType[]
  setNote: (note: NotesType) => void
}

const Recepient: React.FC<NotesRecpPropsType> = ({ recepData, setNote }) => {
  const [clickNote, setClickedNote] = useState<number>(0)
  return (
    <div className="bg-white px-15px my-15px w-full ">
      <div className="h-full">
        {recepData?.map((recep: NotesType, index: number) => {
          return (
            <div
              className={
                clickNote === index
                  ? 'flex border-btm mt-15px px-15px pb-15px person-active pointer align-center'
                  : 'flex border-btm mt-15px px-15px pb-15px pointer align-center'
              }
              onClick={() => {
                setNote(recep)
                setClickedNote(index)
              }}
            >
              <div>
                <Avatar
                  size={52}
                  src={recep?.createdBy?.photo?.post}
                  alt="profile"
                >
                  {getNameSignature(
                    recep?.createdByType === 'organizer'
                      ? recep?.createdBy?.firstName
                      : recep?.createdByAdmin?.firstName,
                  )}
                </Avatar>
              </div>
              <div className="ml-10px">
                <h4 className="text-16 w-600">
                  {recep?.createdByType === 'organizer'
                    ? capitalizeFirstLetter(recep?.createdBy?.firstName)
                    : capitalizeFirstLetter(recep?.createdByAdmin?.firstName) +
                      ' ' +
                      capitalizeFirstLetter(recep?.createdByAdmin?.lastName)}
                </h4>
                <h4 className="text-12 grey-txt">
                  {recep?.type} {' . '}
                  {moment(recep?.createdAt).fromNow()},
                  {moment(recep?.createdAt).format('hh:mm A')}
                </h4>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Recepient
