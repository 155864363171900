import { connect } from "react-redux";
import BigCustomCalender from "../../../../helper/Calendar/Eventscalendar/BigCustomCalender";

const CalendarViewOrg = ({ events, organizationData }: any) => {
  console.log("eventsData 12", events);

  return (
    <>
      <div>
        <BigCustomCalender events={events} />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  events: state.events.events,
  modalVisible: state.events.ismodelVisible,
  organizationData: state.organization.organizationData,
});
export default connect(mapStateToProps)(CalendarViewOrg);