import AppLayout from '../../components/layout/layout'
import { Checkbox, Col, DatePicker, Divider, Dropdown, Row } from 'antd'
import type { SorterResult } from 'antd/es/table/interface'
import { Table } from 'antd'
import { useEffect, useRef, useState } from 'react'

import AreaPlot from '../../helper/Charts/AreaGraph/AreaPlot'
import { getAdminAnalytics } from 'api/Analytics'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/Spinner'
import Down from 'helper/Icons/DownIcon'
import moment from 'moment'
import { convertToLocal } from 'helper/utility'
import usePagination from 'helper/usePagination'

const Analytics = () => {
  const [open, setOpen] = useState(0)

  const [parms, setParms] = useState<any>({
    page: 1,
    limit: 10,
    orderBy: 'desc',
  })
  const {
    isLoading,
    data: AnalyticData,
    refetch,
  } = useQuery(['getAdminAnalytics', parms], () => getAdminAnalytics(parms), {
    enabled: false,
  })
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({})
  const [selectedMonths, setSelectedMonths] = useState<Date[]>([])
  const currentMonth = moment().format('MMM YYYY')
  const previousMonth = moment().clone().subtract(1, 'month').format('MMM YYYY')
  const handleMonthSelect = (month: Date) => {
    if (selectedMonths.length === 0) {
      setSelectedMonths([month])
    } else if (selectedMonths.length === 1) {
      const start = selectedMonths[0]
      const end = month
      setSelectedMonths([start, end])
    } else if (selectedMonths.length === 2) {
      setSelectedMonths([month])
    }
  }

  const [checkedItems, setCheckedItems] = useState([])
  const [paginationInfo, onTableChange] = usePagination({
    totalCount: AnalyticData?.count || 0,
    currentPage: parms.page,
    setParams: setParms,
  })

  const formatMonth = (date: Date) => {
    return moment(date).format('MMM YY')
  }
  const DateText =
    selectedMonths?.length > 1
      ? formatMonth(selectedMonths[0]) + ' - ' + formatMonth(selectedMonths[1])
      : previousMonth + ' - ' + currentMonth

  const [categories, setCategories] = useState()
  const clear = (item: string, setFn?: any) => {
    if (item in parms) {
      const { [item]: _, ...updatedParms } = parms
      setParms(updatedParms)
    }
    setFn && setFn('')
  }

  const DateSelector = [
    {
      key: '1',
      label: (
        <div>
          <DatePicker
            picker="month"
            placeholder="Start Month"
            onChange={(dateString: any) => {
              const selectedMonth = new Date(dateString)
              selectedMonth.setDate(1)
              selectedMonth.setHours(0, 0, 0, 0)
              handleMonthSelect(selectedMonth)
            }}
          />
          <DatePicker
            picker="month"
            placeholder="End Month"
            className="ml-20px"
            onChange={(dateString: any) => {
              const MonthEnd = new Date(dateString)
              MonthEnd.setDate(1)
              MonthEnd.setHours(0, 0, 0, 0)
              handleMonthSelect(MonthEnd)
            }}
          />
        </div>
      ),
    },
    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              clear('startDate')
              clear('endDate')
              setSelectedMonths([])
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              selectedMonths?.length > 1 &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  startDate: selectedMonths[0],
                  endDate: selectedMonths[1],
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  const CategoriesCheckBoxes = [
    {
      label: 'Camp',
      value: '63286df7f0e6031bafcb6e80',
    },
    {
      label: 'Field Trip',
      value: '63286e03721c1a5feed95274',
    },
    {
      label: 'Course',
      value: '63286e08f05b4cb149ca380d',
    },
    {
      label: 'Club',
      value: '63286e0dcc70349899c6d3b8',
    },
    {
      label: 'Community',
      value: '63286e42bab7a941518ce6b2',
    },
    {
      label: 'Childcare',
      value: '63286e49b6dfacfd5c6f6def',
    },
    {
      label: 'Preschool',
      value: '63286e4fe0f9a8912cb2f633',
    },
    {
      label: 'Afterschool',
      value: '63286e543fb9d509a583ff20',
    },
    {
      label: 'Homeschool',
      value: '63286e5d33b446524e24294e',
    },
    {
      label: 'Sport',
      value: '6462004486ef5486e902d225',
    },
    {
      label: 'Other',
      value: '63286e66bd76daa24d9097e5',
    },
  ]
  const Categories = [
    {
      key: 'checkboxes',
      label: (
        <Checkbox.Group
          options={CategoriesCheckBoxes}
          className="dropdwn checkbox"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onChange={(val: any) => setCategories(val)}
          value={categories}
        />
      ),
    },

    {
      key: 'btns',
      label: (
        <div className="flex justify-between mx-15px">
          <button
            className="clr-btn"
            onClick={() => {
              // clear('categoryIds')
              setOpen(0)
              setCheckedItems([])
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              setOpen(0)
              const data = checkedItems[0] && checkedItems

              data &&
                setParms((prevParms: any) => ({
                  ...prevParms,
                  categoryIds: data,
                  page: 1,
                }))
            }}
            className="aply-btn"
          >
            Apply
          </button>
        </div>
      ),
    },
  ]
  useEffect(() => {
    refetch()
  }, [parms])
  const columns = [
    {
      title: 'Date',
      dataIndex: 'lastTicketPurchaseDateTime',
      key: 'date',
      sorter: (a: any, b: any) => a.date.length - b.date.length,
      sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      render: (data: any) => {
        if (!data) {
          return <h4 className="text-14 w-500">No tickets purchased yet</h4>
        }

        return (
          <div>
            <div>
              <h2 className="text-14 w-500">
                {convertToLocal(data, 'MMM,DD YYYY')}
              </h2>
              <h3 className="text-10 grey-txt ">
                {convertToLocal(data, 'HH MM A')}
              </h3>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
    },
    {
      title: 'Organization Name',
      dataIndex: 'organizationName',
      key: 'age',
      sorter: (a: any, b: any) => a.age.length - b.age.length,
      sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
    },
    {
      title: 'Revenue',
      dataIndex: 'totalRevenue',
      key: 'address',
      sorter: (a: any, b: any) => a.address.length - b.address.length,
      sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
    },
  ]
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const handleDocumentClick = (e: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(0)
    }
  }
  const handleDropdownClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  return (
    <AppLayout>
      <div className="container page-header analytics-page-container">
        <Row>
          <Col xl={{ span: 12 }} xs={{ span: 24 }} className="mb-26">
            <div>
              <h2>Analytics</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <AreaPlot />
          </Col>
        </Row>
        <div style={{ paddingTop: '30px' }}>
          <Divider />
          <div
            className="flex justify-end mb-20px"
            ref={dropdownRef}
            onClick={handleDropdownClick}
          >
            <Dropdown
              menu={{
                items: Categories,
              }}
              placement="bottomLeft"
              arrow={false}
              className="mr-10px "
              open={open === 3}
              overlayStyle={{ width: '300px' }}
            >
              <button
                className={` ${
                  open === 3 ? 'filter-btn-click ' : 'filter-btn'
                } w-500`}
                onClick={() => setOpen(3)}
              >
                Category
                <Down />
              </button>
            </Dropdown>
            <Dropdown
              menu={{
                items: DateSelector,
              }}
              placement="bottomLeft"
              arrow={false}
              className="ml-20px mr-10px"
              open={open === 2}
            >
              <button
                className={` ${
                  open === 2 ? 'filter-btn-click ' : 'filter-btn'
                } w-500`}
                onClick={() => setOpen(2)}
              >
                {DateText}
                <Down />
              </button>
            </Dropdown>
          </div>

          <Row style={{ overflow: 'auto hidden', width: '100%' }}>
            {isLoading ? (
              <div className="flex justify-center align-center h-[80vh] w-full">
                <Spinner />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={AnalyticData?.records}
                style={{ width: '100%' }}
                pagination={paginationInfo}
                onChange={onTableChange}
              />
            )}
          </Row>
        </div>
      </div>
    </AppLayout>
  )
}
export default Analytics
