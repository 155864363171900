import { Modal } from "antd";
import React, { useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";

interface deactivate {
  id: string;
  notvisible: any;
}
const Deactivate: React.FC<deactivate> = ({ id, notvisible }) => {
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useDispatch();

  const onFinish = (event: any) => {
    event.preventDefault();
    dispatch({
      type: actions.DEACTIVATE_ADMIN_REQUEST,
      payload: {
        firebaseId: id,
      },
    });
    console.log(id);
    notvisible(false);
  };
  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };

  return (
    <div className="changeModal">
      <Modal visible={open} onCancel={handleCancel} className="deModal">
        <div className="deactivate">
          <h2>Deactivate account</h2>
          <p>
            Are you sure you want to deactivate your account? This action cannot
            be undone.
          </p>
          <div className="popup-footer defooter">
            <button className="btn-sec pointer" onClick={handleCancel}>
              Cancel
            </button>
            <button className=" btn-red pointer" onClick={onFinish}>
              Yes, deactivate
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Deactivate;
