import { Modal, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import Cancel from "../../../assets/Images/close.svg";
import { useDispatch } from "react-redux";
import actions from "../../../Redux/Admin/action";

interface ChangePasswordProps {
  id: string;
  notvisible: any;
}
const ChangePassword: React.FC<ChangePasswordProps> = ({ id, notvisible }) => {
  const [open, setOpen] = useState<boolean>(true);
  const openNotification = () => {
    notification.open({
      message: "Password don't match",
      description: "Please double check your password",
    });
  };
  const [formData, setFormData] = useState({
    currentPass: "",
    newPass: "",
    confirmPass: "",
  });

  const dispatch = useDispatch();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onFinish = (event: any) => {
    event.preventDefault();
    console.log(formData.confirmPass + "from");
    formData.newPass !== "" && formData.newPass === formData.confirmPass
      ? dispatch({
          type: actions.CHANGE_ADMIN_PASSWORD_REQUEST,
          payload: {
            password: formData.confirmPass,
            firebaseId: id,
          },
        })
      : openNotification();
    formData.newPass === formData.confirmPass && formData.newPass !== "" ? (
      notvisible(false)
    ) : (
      <></>
    );
  };
  const handleCancel = () => {
    setOpen(false);
    notvisible(null);
  };

  return (
    <Modal
      visible={open}
      onOk={onFinish}
      onCancel={handleCancel}
      className="popup-modal"
    >
      <div className="">
        <div className="popup-header">
          <h2>Change password</h2>
          <img
            src={Cancel}
            alt="close button"
            onClick={handleCancel}
            className="pointer"
          />
        </div>
        <form name="changePassword" onSubmit={onFinish}>
          <div className="inputGroup">
            <label className="inp-label">Current password</label>
            <Form.Item
              name="currentPass"
              className="my-input-box"
              rules={[
                {
                  required: true,
                  message: "Please enter your current password",
                },
              ]}
            >
              <Input.Password
                name="currentPass"
                placeholder="Please enter your current password"
                onChange={handleInputChange}
                value={formData.currentPass}
              />
            </Form.Item>
          </div>
          <div className="inputGroup">
            <label className="inp-label">New password</label>
            <Form.Item
              name="newPass"
              className="my-input-box"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password",
                  len: 8,
                },
              ]}
            >
              <Input.Password
                name="newPass"
                placeholder="Enter your new password"
                onChange={handleInputChange}
                value={formData.newPass}
              />
            </Form.Item>
          </div>
          <div className="inputGroup">
            <label className="inp-label">Confirm password</label>
            <Form.Item
              name="confirmPass"
              className="my-input-box"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password",
                  len: 8,
                },
              ]}
            >
              <Input.Password
                name="confirmPass"
                placeholder="Please confirm your password"
                onChange={handleInputChange}
                value={formData.confirmPass}
              />
            </Form.Item>
          </div>
          <div className="popup-footer">
            <button className="btn-sec pointer" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btn-prim pointer" type="submit" value="Submit">
              Change password
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default ChangePassword;
