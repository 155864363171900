import { getFamilyEvents } from '../../../../../api/Events'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import GlobalEventComponent from 'components/Events/Events'
const Events = ({ familyId }: any) => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  })
  const { isLoading, data, refetch } = useQuery(
    ['getEvents', familyId, params],
    () => getFamilyEvents(familyId, params),
  )
  useEffect(() => {
    refetch()
  }, [params])
  return (
    <div className="bg-grey  padding-top-10">
      <div className="bg-white p-30px">
        <GlobalEventComponent
          data={data}
          isLoading={isLoading}
          parms={params}
          setParms={setParams}
        />
      </div>
    </div>
  )
}
export default Events
